import React, {useState} from 'react';
import {
    Button,
    Card,
    Form,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import 'tinymce/skins/ui/oxide/skin.css';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ComposeAttachment from './ComposeAttachment';
import TinymceEditor from 'components/common/TinymceEditor';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {api} from "../../../../utils/api";
import {useParams} from "react-router-dom";

const Compose = ({setShowForm, title, bodyClassName, fetch, setLoading}) => {
    const {register, handleSubmit, setValue} = useForm();

    const [attachments, setAttachments] = useState([]);

    const {id} = useParams()

    const handleAddAttachment = files => {
        if (files.length === 0) return;
        const fileArray = [];
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            const {name, size, type, path} = file;

            const newFile = {};
            reader.onloadend = () => {
                newFile['file'] = reader.result
            };
            newFile['data'] = {
                id: name + 1 + Date.now(),
                name,
                size,
                type,
                path
            }
            fileArray.push(newFile);
            reader.readAsDataURL(file);
        });
        setAttachments([...attachments, ...fileArray]);
    };

    const handleDetachAttachment = id => {
        setAttachments(attachments.filter(attachment => attachment?.data?.id !== id));
    };

    const onSubmitData = async data => {
        setLoading(true)
        await api.post(`/support/reply/`, {...data, ticket: id, attachments: attachments})
            .then(res => {
                toast.success("Message added successfully.", {theme: "colored"})
                setAttachments([]);
                fetch()
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setLoading(false)
    };

    return (
        <Card
            as={Form}
            onSubmit={handleSubmit(onSubmitData)}
            className={bodyClassName}
        >
            <Card.Header className="bg-body-tertiary">
                <h5 className="mb-0">{title ? title : 'New message'}</h5>
            </Card.Header>
            <Card.Body className="p-0">
                <div className="border border-y-0 border-200">
                    <Form.Control
                        type="text"
                        placeholder="Subject"
                        className="border-0 outline-none px-x1 rounded-0"
                        {...register('subject')}
                    />
                </div>
                <div className="border border-0 border-200">
                    <TinymceEditor
                        handleChange={newValue => setValue('text', newValue)}
                    />
                </div>
                {attachments.length > 0 && (
                    <div className="bg-body-tertiary px-x1 py-3">
                        <Flex direction="column" inline>
                            {attachments?.map(attachment => (
                                <ComposeAttachment
                                    {...attachment?.data}
                                    key={attachment?.data?.id}
                                    handleDetachAttachment={handleDetachAttachment}
                                />
                            ))}
                        </Flex>
                    </div>
                )}
            </Card.Body>
            <Card.Footer>
                <Flex alignItems="center" justifyContent="between">
                    <Flex>
                        <Button
                            type="submit"
                            variant="primary"
                            size="sm"
                            className="px-4 px-sm-5 me-2"
                        >
                            Send
                        </Button>
                        <Form.Group controlId="email-attachment">
                            <Form.Control
                                type="file"
                                multiple
                                className="d-none"
                                onChange={({target}) => handleAddAttachment(target.files)}
                            />
                            <OverlayTrigger
                                overlay={
                                    <Tooltip style={{position: 'fixed'}} id="previousTooltip">
                                        Attach files
                                    </Tooltip>
                                }
                            >
                                <div>
                                    <IconButton
                                        as={Form.Label}
                                        className="me-2 mb-0"
                                        variant="tertiary"
                                        size="sm"
                                        icon="paperclip"
                                        iconClassName="fs-1"
                                        transform="down-2"
                                    />
                                </div>
                            </OverlayTrigger>
                        </Form.Group>
                        <Form.Group controlId="email-image">
                            <Form.Control
                                type="file"
                                className="d-none"
                                accept="image/*"
                                multiple
                                onChange={({target}) => handleAddAttachment(target.files)}
                            />
                            <OverlayTrigger
                                overlay={
                                    <Tooltip style={{position: 'fixed'}} id="previousTooltip">
                                        Attach images
                                    </Tooltip>
                                }
                            >
                                <div>
                                    <IconButton
                                        as={Form.Label}
                                        className="me-2 mb-0"
                                        variant="tertiary"
                                        size="sm"
                                        icon="image"
                                        iconClassName="fs-1"
                                        transform="down-2"
                                    />
                                </div>
                            </OverlayTrigger>
                        </Form.Group>
                    </Flex>
                    <Flex>
                        <OverlayTrigger
                            overlay={
                                <Tooltip style={{position: 'fixed'}} id="previousTooltip">
                                    Delete
                                </Tooltip>
                            }
                        >
                            <div>
                                <IconButton
                                    className="ms-2 mb-0"
                                    variant="tertiary"
                                    size="sm"
                                    icon="trash"
                                    onClick={() => {
                                        setAttachments([]);
                                        setShowForm && setShowForm(false);
                                    }}
                                />
                            </div>
                        </OverlayTrigger>
                    </Flex>
                </Flex>
            </Card.Footer>
        </Card>
    );
};

Compose.propTypes = {
    setShowForm: PropTypes.func,
    title: PropTypes.string,
    bodyClassName: PropTypes.string
};

export default Compose;
