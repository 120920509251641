import {formatDateTime, withPermission} from "../../../helpers/utils";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import IconButton from "../../../components/common/IconButton";
import CSRFToken from "../../../helpers/CSRFToken";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import SimpleBarReact from "simplebar-react";

const TransactionDetail = () => {
    const [transactionDetails, setTransactionDetails] = useState({});
    const [loading, setLoading] = useState(false);

    const {id} = useParams();
    const navigate = useNavigate()

    const getTransaction = async () => {
        setLoading(true);
        await api.get(`/transaction/transaction/${id}/`).then(async res => {
            await api.get(`/${res?.data?.provider_name}/transaction/${id}/`).then(res => setTransactionDetails(res?.data));
        })
        setLoading(false)
    }

    useEffect(() => {
        getTransaction()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={"border"} variant={"primary"}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className={"d-print-none"}>
                <Card.Header>
                    <Flex justifyContent={"between"} alignItems={"center"}>
                        <Flex justifyContent={"between"} alignItems={"center"}>
                            <IconButton
                                onClick={() => navigate(-1)}
                                variant="falcon-default"
                                size="sm"
                                icon="arrow-left"
                            />
                            <h5 className={"ms-2"}>#{transactionDetails?.code}</h5>
                        </Flex>
                        <Flex justifyContent={"between"} alignItems={"center"}>
                            <CSRFToken/>
                            <SoftBadge
                                pill
                                className={"me-2"}
                                bg={classNames({
                                    warning: transactionDetails?.status === "pending",
                                    danger: transactionDetails?.status === "declined",
                                    success: transactionDetails?.status === "approved",
                                })}
                            >
                                {transactionDetails.status_display}
                            </SoftBadge>
                            {transactionDetails?.status === "declined" &&
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="sync-alt"
                                    iconAlign="middle"
                                    className="d-none d-sm-block"
                                >
								<span className="d-none d-xl-inline-block ms-1">
              Reprocess payment
            </span>
                                </IconButton>
                            }
                            {/*<IconButton*/}
                            {/*    variant="falcon-default"*/}
                            {/*    size="sm"*/}
                            {/*    icon="arrow-down"*/}
                            {/*    className="me-1 mb-2 mb-sm-0"*/}
                            {/*    iconClassName="me-1"*/}
                            {/*>*/}
                            {/*    Download (.pdf)*/}
                            {/*</IconButton>*/}
                            {transactionDetails?.status === "approved" &&
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="print"
                                    iconClassName="me-1"
                                    className="ms-2 me-1 mb-2 mb-sm-0"
                                    onClick={() => window.print()}
                                >
                                    Print
                                </IconButton>
                            }
                            {/*<IconButton*/}
                            {/*    variant="falcon-success"*/}
                            {/*    size="sm"*/}
                            {/*    icon="dollar-sign"*/}
                            {/*    className="mb-2 mb-sm-0"*/}
                            {/*>*/}
                            {/*    Receive Payment*/}
                            {/*</IconButton>*/}
                        </Flex>
                    </Flex>
                </Card.Header>
            </Card>
            <Row className={"mt-3"}>
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <h5>Payment Details</h5>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <strong>Amount
                                    Paid:</strong> {transactionDetails?.amount} {transactionDetails?.currency}<br/>
                                <strong>Payment Method:</strong> {transactionDetails?.provider}<br/>
                                <strong>Date:</strong> {formatDateTime(transactionDetails?.date)}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <h5>User Details</h5>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <strong>Name:</strong> {transactionDetails?.user_data?.full_name}<br/>
                                <strong>Email:</strong> {transactionDetails?.user_data?.email}<br/>
                                <strong>Phone:</strong> {transactionDetails?.user_data?.number}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                {transactionDetails?.errors?.length > 0 &&
                    <Col>
                        <Card className={"mt-3"}>
                            <Card.Header>
                                <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                    <h5 className={"text-500 text-muted"}>Errors information</h5>
                                    <SoftBadge pill bg={"danger"}>
                                        {transactionDetails?.status_code}
                                    </SoftBadge>
                                </Flex>
                            </Card.Header>
                            <Card.Body>
                                <SimpleBarReact>
                                    <Table responsive className="p-2 mb-2">
                                        <thead>
                                        <tr>
                                            <th className={"text-center"}>Amount</th>
                                            <th className={"text-center"}>Token</th>
                                            <th className={"text-center"}>From</th>
                                            <th className={"text-center"}>Method</th>
                                            <th className={"text-center"}>Type</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {transactionDetails?.errors?.map((error, index) => (
                                            <tr key={index}>
                                                <td className={"text-center"}>{error?.amount / 1000}</td>
                                                <td className={"text-center"}>{error?.token}</td>
                                                <td className={"text-center"}>{error?.from}</td>
                                                <td className={"text-center"}>{error?.method}</td>
                                                <td className={"text-center"}>{error?.type}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </SimpleBarReact>
                            </Card.Body>
                        </Card>
                    </Col>
                }
            </Row>
        </Fragment>
    )
}

export default withPermission(TransactionDetail, "transaction.view_transaction")