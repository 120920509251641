import {formatDateTime, withPermission} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {api} from "../../../../utils/api";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import IconButton from "../../../../components/common/IconButton";
import Avatar from "../../../../components/common/Avatar";
import paths from "../../../../routes/paths";
import SimpleBarReact from "simplebar-react";
import SoftBadge from "../../../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const OrganizerDetail = ({t, i18n}) => {
    const [organizer, setOrganizer] = useState({});
    const [loading, setLoading] = useState(false);

    const {id} = useParams();
    const navigate = useNavigate()

    const fetchData = async () => {
        setLoading(true);
        await api.get(`/organizer/organizer/${id}/`).then(res => setOrganizer(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className={"d-print-none mb-3"}>
                <Card.Header className="d-flex flex-between-center">
                    <Flex alignItems={"center"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                        <h5 className={"ms-2"}>{organizer?.user_fullname}</h5>
                    </Flex>
                    <Flex alignItems={"center"}>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="pencil-alt"
                            className={"ms-2"}
                            transform="shrink-3"
                            onClick={() => navigate(paths.editOrganizers.replace(":id", id))}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Edit</span>
                        </IconButton>
                    </Flex>
                </Card.Header>
            </Card>
            <Row className={"g-3"}>
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <h5>Basic Information</h5>
                        </Card.Header>
                        <Card.Body>
                            <Link to={`/members/member/${organizer?.member}/edit`}>
                                <Flex alignItems="center">
                                    {organizer?.avatar ? (
                                        <Avatar
                                            src={organizer?.avatar}
                                            rounded={'circle'}
                                            size="4xl"
                                            className="me-2"
                                        />
                                    ) : (
                                        <Avatar
                                            size="4xl"
                                            name={organizer?.user_fullname}
                                            rounded={'circle'}
                                            className="me-2"
                                        />
                                    )}
                                    <div className="flex-1">
                                        <h6
                                            className="mb-0 fs-0"
                                            style={{
                                                width: '200px',
                                                wordWrap: 'break-word',
                                                wordBreak: 'break-all',
                                                whiteSpace: 'pre-wrap'
                                            }}
                                        >
                                            {organizer?.user_fullname}
                                        </h6>
                                        <p>{organizer?.role}</p>
                                    </div>
                                </Flex>
                            </Link>
                            <Link to={`/activities/activity/${organizer?.organization}/detail`} className={"mt-3"}>
                                <Flex alignItems="center">
                                    {organizer?.activity_logo ? (
                                        <Avatar
                                            src={organizer?.activity_logo}
                                            rounded={'circle'}
                                            size="4xl"
                                            className="me-2"
                                        />
                                    ) : (
                                        <Avatar
                                            size="4xl"
                                            name={organizer?.activity_name}
                                            rounded={'circle'}
                                            className="me-2"
                                        />
                                    )}
                                    <div className="flex-1">
                                        <h6
                                            className="mb-0 fs-0"
                                            style={{
                                                width: '200px',
                                                wordWrap: 'break-word',
                                                wordBreak: 'break-all',
                                                whiteSpace: 'pre-wrap'
                                            }}
                                        >
                                            {organizer?.activity_name}
                                        </h6>
                                    </div>
                                </Flex>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                <Card>
                        <Card.Header>
                            <h5>Event Information</h5>
                        </Card.Header>
                        <Card.Body className={"mb-2"}>
                            <Link to={paths.eventDetail.replace(":id", organizer?.event_data?.id)}>{organizer?.event_data?.title} - {organizer?.event_data?.subtitle}</Link>
                            <p className={"text-muted"}>{organizer?.event_data?.type_display}</p>
                            <div dangerouslySetInnerHTML={{__html: organizer?.event_data?.description}}></div>
                            <div className={"text-muted"}>{formatDateTime(organizer?.event_data?.created_at)}</div>
                            <div>{organizer?.event_data?.address?.formatted_address}</div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <h5>Responsibilities</h5>
                        </Card.Header>
                        <Card.Body>
                            <SimpleBarReact>
                                <Table responsive className="border-bottom">
                                    <thead data-bs-theme="light">
                                    <tr className="bg-primary dark__bg-1000 alert alert-primary">
                                        <th className="text-white border-0 text-center text-white-emphasis">Start Date</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">
                                            End Date
                                        </th>
                                        <th className="text-white border-0 text-center text-white-emphasis">Deadline</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">Description</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">Priority</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">Completed</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">Tasks</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">Created at</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {organizer?.responsibility_set?.map((r, index) => (
                                        <tr key={index}>
                                            <td className="align-middle text-center">{formatDateTime(r?.start)}</td>
                                            <td className="align-middle text-center">{formatDateTime(r?.end)}</td>
                                            <td className="align-middle text-center">{formatDateTime(r?.deadline)}</td>
                                            <td className="align-middle text-center text-truncate" dangerouslySetInnerHTML={{__html: r?.description}}></td>
                                            <td className="align-middle text-center">{r?.priority_display}</td>
                                            <td className="align-middle text-center">
                                                <SoftBadge pill bg={classNames({
                                                    success: r?.completed,
                                                    danger: !r?.completed
                                                })}>
                                                    {r?.completed ? "Completed" : "Not Completed"}
                                                    <FontAwesomeIcon icon={classNames({
                                                        ban: !r?.completed,
                                                        check: r?.completed
                                                    })} className={"ms-2"}/>
                                                </SoftBadge>
                                            </td>
                                            <td className="align-middle text-center">
                                                <Link to={paths.eventTasks.replace(":id", organizer?.event)}>{r?.tasks_count}</Link>
                                            </td>
                                            <td className="align-middle text-center">{formatDateTime(r?.created_at)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default withPermission(withTranslation(["organizer", "common"])(OrganizerDetail), "organizer.view_organizer")