import Flex from "../../../components/common/Flex";
import {ListGroup, Spinner} from "react-bootstrap";
import CSRFToken from "../../../helpers/CSRFToken";
import React, {useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import ConnectionItem from "./ConnectionItem";

const ConnectionsList = ({showDate}) => {
    const [connections, setConnections] = useState([])
    const [loading, setLoading] = useState(false)

    let query = useQuery()

    const getConnections = async (q) => {
        setLoading(true)
        await api.get(`/account/socialaccounts/?${q.toString()}`).then(res => {
            setConnections(res?.data?.results)
        })
        setLoading(false)
    }

    const handleDelete = async (e, id) => {
        const {isConfirmed} = await Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/account/socialaccounts/${id}/`).then(() => {
                toast.success(
                    `Connection successfully deleted.`,
                    {
                        theme: 'colored'
                    }
                );
                getConnections(query)
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        getConnections(query)
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <ListGroup>
            <CSRFToken/>
            {connections?.map(connection =>
                <ConnectionItem item={connection} handleDelete={handleDelete} showDate={showDate}  />
            )}
        </ListGroup>
    )
}

export default ConnectionsList