import React, {useState} from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import CSRFToken from "../../../helpers/CSRFToken";
import Flex from "../../../components/common/Flex";

const InvoiceUploadModal = ({isOpen, onClose, onUpload, entryId, type, loading}) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = (e) => {
        e.preventDefault()
        onUpload(file, entryId, type);
    };

    if (isOpen !== entryId) return null;

    return (
        <Modal show={isOpen === entryId} size={"lg"} centered onHide={onClose}>
            <Modal.Header closeButton>
                <h5>Upload Invoice</h5>
            </Modal.Header>
            <Modal.Body>
                {loading ?
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                    :
                    <Form>
                        <CSRFToken/>
                        <Form.Group>
                            <Form.Label>File</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                    </Form>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={onClose}>Cancel</Button>
                <Button variant={"primary"} onClick={handleUpload}>Upload</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default InvoiceUploadModal