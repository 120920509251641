import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Row, Col, Spinner} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {Link} from 'react-router-dom';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {api} from "../../../../utils/api";
import useQuery from "../../../../hooks/useQuery";
import Flex from "../../../../components/common/Flex";
import {debounce} from "lodash";
import {useAppContext} from "../../../../providers/AppProvider";

const columns = [
	{
		accessor: 'name',
		Header: 'Event',
		Cell: rowData => (
			<Link to={`/events/${rowData.row.original.id}/detail`} className="text-primary fw-semi-bold">
				{rowData.row.original.name}
			</Link>
		)
	},
	{
		accessor: 'registered',
		Header: 'Members Registered',
		headerProps: {
			className: 'text-end'
		},
		cellProps: {
			className: 'text-end'
		}
	},
	{
		accessor: 'avg_registration',
		Header: 'Avg Registration',
		headerProps: {
			className: 'text-end'
		},
		cellProps: {
			className: 'text-end'
		},
		Cell: rowData => <p>{rowData.row.original.avg_registration}%</p>
	}
];

const EventTrends = ({perPage = 8}) => {
	const [tableData, setTableData] = useState([])
	const [loading, setLoading] = useState(false)

	const {
		setConfig
	} = useAppContext()

	let query = useQuery()

	const getTableData = async (q) => {
		setLoading(true)
		await api.get(`/analytics/eventtrends/?${q.toString()}`).then(res => setTableData(res?.data))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		setLoading(false)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(getTableData, 600), []);

	useEffect(() => {
		getTableData(query)
		// eslint-disable-next-line
	}, []);

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={tableData}
			sortable
			pagination
			perPage={perPage}
		>
			<Card className="h-100">
				<Card.Header>
					<Row className="flex-between-center">
						<Col xs="auto" sm={6} lg={7}>
							<h6 className="mb-0 text-nowrap py-2 py-xl-0">
								Event Trends
							</h6>
						</Col>
						<Col xs="auto" sm={6} lg={5}>
							<AdvanceTableSearchBox table={tableData} fetch={delayedLoadItems}/>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								className: 'fs--1 mb-0 overflow-hidden'
							}}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTableFooter
						rowCount={tableData.length}
						table
						rowInfo
						navButtons
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
};

EventTrends.propTypes = {
	perPage: PropTypes.number
};

export default EventTrends;
