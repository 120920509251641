import Notification from 'components/notification/Notification';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import { getNotifications, markAllAsRead as marker } from './actions';
import AdvanceTablePagination from '../../components/common/advance-table/AdvanceTablePagination';
import useQuery from '../../hooks/useQuery';
import { api } from '../../utils/api';
import {withPermission} from '../../helpers/utils';
import paths from "../../routes/paths";
import {useAppContext} from "../../providers/AppProvider";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);

  const {
    setConfig
  } = useAppContext()
  const navigate = useNavigate()
  let query = useQuery();

  const fetchNotications = async q => {
    getNotifications(q)
      .then(res => {
        setNotifications(res?.results);
        setCount(res?.count);
      })
      .catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", false);
			});
  };

  const handleClick = async (e, id) => {
    await api
      .patch(`/notification/notification/${id}/`, { read: true })
      .catch(() => {});
    await fetchNotications(query);
  };

  const markAllAsRead = async () => {
    await marker();
    await fetchNotications(query);
  };

  useEffect(() => {
    fetchNotications(query);
    // eslint-disable-next-line
    }, []);

  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col sm="auto">
            <h5 className="mb-1 mb-md-0">Your Notifications</h5>
          </Col>
          <Col sm="auto" className="fs--1">
            <Link
              to="#!"
              className="font-sans-serif ms-2 ms-sm-3"
              onClick={markAllAsRead}
            >
              Mark all as read
            </Link>
            <div className="font-sans-serif ms-2 ms-sm-3"></div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {notifications.map(notification => (
          <Notification
            {...notification}
            key={notification.id}
            handleClick={handleClick}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <AdvanceTablePagination
          length={notifications?.length}
          query={query}
          count={count}
          fetch={fetchNotications}
          itemsPerPage={20}
        />
      </Card.Footer>
    </Card>
  );
};

export default withPermission(Notifications, "notification.view_notification");
