import {withPermission} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppContext} from "../../../../providers/AppProvider";
import {api} from "../../../../utils/api";
import paths from "../../../../routes/paths";
import {toast} from "react-toastify";
import Flex from "../../../../components/common/Flex";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import CSRFToken from "../../../../helpers/CSRFToken";
import {Field, Formik} from "formik";
import Select from "react-select";
import FormError from "../../../errors/FormError";
import StarRatings from "react-star-ratings/build/star-ratings";
import IconButton from "../../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import TinymceEditor from "../../../../components/common/TinymceEditor";

const TestimonialAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [authors, setAuthors] = useState([])
    const [selectedAuthor, setSelectedAuthor] = useState(null)
    const [rating, setRating] = useState(0)
    const [formData, setFormData] = useState({
        author: '',
        description: '',
    });

    const navigate = useNavigate();
    const {id} = useParams();
    const {
        setConfig
    } = useAppContext()

    const getTestimonial = async () => {
        setLoading(true)
        await api.get(`/testimonial/${id}/`)
            .then(res => {
                setFormData({
                    ...res?.data,
                });
                setSelectedAuthor({label: res?.data?.author_full_name, value: res?.data?.author})
                setRating(res?.data?.rating)
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    }

    const getAuthors = async () => {
        await api.get("/account/user/").then(res => {
            setAuthors(res?.data?.results)
        }).catch(() => {
        })
    }

    useEffect(() => {
        getAuthors()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id) getTestimonial();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e, values) => {
        setLoading(true);
        e.preventDefault();
        if (id) {
            await api
                .patch(`/testimonial/${id}/`, values)
                .then(res => {
                    toast.success(`${t('title')} ${t('updateSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.testimonial);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(t('error', {ns: "common"}), {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        } else {
            await api
                .post(`/testimonial/`, values)
                .then(res => {
                    toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.testimonial);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(t('error', {ns: "common"}), {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        }
        setLoading(false)
    }

    let authorOptions = authors?.map(user => ({label: user?.full_name, value: user?.id}))

    authorOptions = [
        {label: "-------------------------", value: ""},
        ...authorOptions
    ]

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <Form>
                        <CSRFToken/>
                        <Row className="g-3">
                            <Col lg={12}>
                                <Card>
                                    <Card.Header>
                                        <h5>{t('basic')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>{t('fields.author')} <span
                                                            className={"text-danger"}>*</span></Form.Label>
                                                        <Field name={"author"}>
                                                            {({field}) => (
                                                                <Select
                                                                    options={authorOptions}
                                                                    value={selectedAuthor}
                                                                    onChange={newValue => {
                                                                        setSelectedAuthor(newValue)
                                                                        setFieldValue(field.name, newValue.value)
                                                                    }}
                                                                    classNamePrefix={"react-select"}
                                                                    placeholder={`${t('select', {ns: "common"})} ${t('fields.author')}`}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors?.author}/>
                                                    </Form.Group>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.rating')}</Form.Label>
                                                    <Field name={"rating"}>
                                                        {({field}) => (
                                                            <Row>
                                                                <StarRatings
                                                                    rating={rating}
                                                                    changeRating={(newRating) => {
                                                                        setRating(newRating)
                                                                        setFieldValue(field.name, newRating)
                                                                    }}
                                                                    starRatedColor="yellow"
                                                                    numberOfStars={6}
                                                                    name='rating'
                                                                />
                                                            </Row>
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.rating}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.description')} <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name="description">
                                                        {({field}) => (
                                                            <TinymceEditor
                                                                value={field.value}
                                                                handleChange={newValue => setFieldValue(field.name, newValue)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.description}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card>
                                    <Card.Footer>
                                        <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                            <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                                            <IconButton
                                                icon={faSave}
                                                onClick={e => handleSubmit(e, values)}
                                            >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                                            </IconButton>
                                        </Flex>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default withPermission(withTranslation(["testimonials", "common"])(TestimonialAddEdit), ["testimonial.add_testimonial", "testimonial.change_testimonial"]);