import React, {useState} from "react";
import {Card, Form, Nav, ProgressBar} from "react-bootstrap";
import classNames from "classnames";
import Flex from "../../../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ActivityForm from "./ActivityForm";
import {faBuilding, faColumns} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../components/common/IconButton";
import {useAppContext} from "../../../../providers/AppProvider";
import FileForm from "./FileForm";
import ColumnMappingForm from "./ColumnMappingForm";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";
import CSRFToken from "../../../../helpers/CSRFToken";
import {useNavigate} from "react-router-dom";
import paths from "../../../../routes/paths";
import {withPermission} from "../../../../helpers/utils";

const MemberImporter = () => {
	const [step, setStep] = useState(1);
	const [fileColumns, setFileColumns] = useState([]);
	const [file, setFile] = useState(null)
	const [selectedActivity, setSelectedActivity] = useState(null);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		activity: "",
		file: null,
		mapping: []
	})

	const { isRTL } = useAppContext();
	const navigate = useNavigate()

	const navItems = [
		{
			icon: faBuilding,
			label: 'Activity'
		},
		{
			icon: 'file',
			label: 'File'
		},
		{
			icon: faColumns,
			label: 'Map fields'
		},
	];

	const handleNavs = targetStep => {
		setStep(targetStep);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true)
		await api.post("/member/import-member/", formData)
			.then(res => {
				toast.success("You have successfully imported the file", {theme: "colored"})
				navigate(paths.membersImportDetail.replace(":id", res?.data?.id))
			})
			.catch(err => {
				toast.error("An error has occurred.", {theme: "colored"})
			})
		setLoading(false)
	}

	return (
		<Card
			as={Form}
			noValidate
			className="theme-wizard mb-5"
		>
			<Card.Header
				className={'bg-body-tertiary px-4 py-3'}
			>
				<Nav className="justify-content-center" variant={"pills"}>
					{navItems.map((item, index) => (
						<NavItemPill
							key={item.label}
							index={index + 1}
							step={step}
							handleNavs={handleNavs}
							icon={item.icon}
							label={item.label}
						/>
					))}
				</Nav>
			</Card.Header>
			<ProgressBar now={step * 33.333333333333334} style={{height: 2}}/>
			<Card.Body className="fw-normal px-md-6 py-4">
				<CSRFToken />
				{step === 1 && (
					<ActivityForm formData={formData} setFormData={setFormData} selectedActivity={selectedActivity} setSelectedActivity={setSelectedActivity} />
				)}
				{step === 2 && (
					<FileForm formData={formData} setFormData={setFormData} setFileColumns={setFileColumns} file={file} setFile={setFile}/>
				)}
				{step === 3 && (
					<ColumnMappingForm formData={formData} setFormData={setFormData} fileColumns={fileColumns} loading={loading} />
				)}
			</Card.Body>
			<Card.Footer
          className={classNames('px-md-6 bg-body-tertiary d-flex')}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semibold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="button"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
            onClick={(e) => {
							if (step !== 3)
                setStep(step + 1);
							else handleSubmit(e)
            }}
          >
            Next
          </IconButton>
        </Card.Footer>
		</Card>
	)
}

const NavItemPill = ({index, step, handleNavs, icon, label}) => {
	return (
		<Nav.Item>
			<Nav.Link
				className={classNames('fw-semibold', {
					done: step > index,
					active: step === index
				})}
				onClick={() => {
					handleNavs(index)
				}}
			>
				<Flex alignItems="center" justifyContent="center">
					<FontAwesomeIcon icon={icon}/>
					<span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
				</Flex>
			</Nav.Link>
		</Nav.Item>
	);
};

export default withPermission(MemberImporter, "member.add_memberimportfile")