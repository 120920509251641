import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {useNavigate} from "react-router-dom";
import paths from "../../../routes/paths";
import {api} from "../../../utils/api";
import {debounce} from "lodash";
import Flex from "../../../components/common/Flex";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatDateTime, withPermission} from "../../../helpers/utils";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../../BaseTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";

const Contracts = ({t, i18n}) => {
	const [contracts, setContracts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

	const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();
	const {hasPermission} = usePermissions()

	const navigate = useNavigate();

	const fetchContracts = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);

		// Cancel the previous request if it exists
		await api.get(`/contract/?${q.toString()}`)
			.then(res => {
				setContracts(res?.data?.results);
				setCount(res?.data?.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLoading(false);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchContracts, 600), []);

	useEffect(() => {
		fetchContracts(query);
		setLength(contracts.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

	const columns = [
		{
			accessor: 'name',
			Header: t('fields.name'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'name' && sortingDirection,
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
		},
		{
			accessor: 'is_signed',
			Header: t('fields.is_signed'),
			canSort: true,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 fs-0'
			},
			sortingDirection: sortingField === 'is_signed' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {is_signed} = rowData.row.original;
				return (
					<SoftBadge
						pill
						bg={classNames({
							success: is_signed,
							warning: !is_signed
						})}
						className="d-flex flex-center"
					>
						<p className="mb-0">
							{is_signed && 'Signed'}
							{!is_signed && 'Not Signed'}
						</p>
						<FontAwesomeIcon
							icon={classNames({
								check: is_signed,
								ban: !is_signed
							})}
							transform="shrink-2"
							className="ms-1"
						/>
					</SoftBadge>
				);
			}
		},
		{
			accessor: 'created_at',
			Header: t('fields.created_at', {ns: "common"}),
			canSort: true,
			sortingDirection: sortingField === 'created_at' && sortingDirection,
			isSorted: false,
			headerProps: {
				className: 'text-center pe-3'
			},
			cellProps: {
				className: 'text-center fs-0 py-2'
			},
			Cell: rowData => `${formatDateTime(rowData.row.original.created_at)}`
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {id, name} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: t('sure', {ns: "common"}),
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						cancelButtonText: t('cancel', {ns: "common"}),
						confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/contract/${id}/`).then(() => {
							toast.success(`${t('title')} ${name} ${t('deleteSuccess', {ns: "common"})}`, {
								theme: 'colored'
							});
							fetchContracts(query)
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("contract.view_contract") &&
								<Dropdown.Item
									onClick={() => navigate(`/management/contract/${id}/detail`)}
								>
									{t('detail', {ns: "common"})}
								</Dropdown.Item>
							}
							{hasPermission("contract.change_contract") &&
								<Dropdown.Item
									onClick={() => navigate(`/management/contract/${id}/edit`)}
								>
									{t('edit', {ns: "common"})}
								</Dropdown.Item>
							}
							{hasPermission("contract.delete_contract") &&
								<>
									<Dropdown.Divider as="div"/>
									<Dropdown.Item
										onClick={e => handleDelete(e)}
										className="text-danger"
									>
										{t('delete', {ns: "common"})}
									</Dropdown.Item>
								</>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	];

	const contractList = contracts?.map(contract => ({
		id: contract.id,
		name: contract.name,
		activity: contract.activity,
		activity_logo: contract.activity_logo,
		activity_name: contract.activity_name,
		is_signed: contract.is_signed,
		created_at: contract.created_at,
	}));

	const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={contractList}
			selection
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"contract.add_contract"}
						creationPath={paths.contractCreate}
						title={"contract"}
						deletePermission={"contract.delete_contract"}
						exportPermission={"contract.add_contractexportfile"}
						data={contractList}
						setLoading={setLoading}
						deleteLink={"/contract/delete/contract/"}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchContracts}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
}

export default withPermission(withTranslation(["contract", "common"])(Contracts), "contract.view_contract")