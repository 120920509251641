import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Modal, Spinner} from "react-bootstrap";
import {useAppContext} from "../../../providers/AppProvider";
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";
import Flex from "../../../components/common/Flex";
import {Ticket} from "../../Ticket";
import {useTranslation} from "react-i18next";

const EventTicketModal = ({open, setOpen}) => {
	const [loading, setLoading] = useState(false)
	const [ticket, setTicket] = useState({})
	const [data, setData] = useState([])
	const [user, setUser] = useState({})

	const {id} = useParams()
	const {t} = useTranslation(["events", "common"])
	let query = useQuery()
	const {
		config: {isDark}
	} = useAppContext();

	const getTicket = async () => {
		await api.get(`/event/event/${id}/`).then(res => setTicket(res?.data?.ticket_options))
	}

	const getUser = async () => {
		await api.get("/account/current_user/").then(res => setUser(res?.data))
	}

	const getRegister = async () => {
		setLoading(true)
		query.set("event", id)
		await api.get(`/event/register/?${query.toString()}`).then(res => setData(res?.data?.results[0]))
		setLoading(false)
	}

	useEffect(() => {
		getRegister()
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getTicket()
		// eslint-disable-next-line
	}, [open]);

	useEffect(() => {
		getUser()
	}, []);

	return (
		<Modal centered size={"xl"} show={open} onHide={() => setOpen(!open)}>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-x1 border-bottom-0"
			>
				<h5>{ticket?.title}</h5>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<Ticket ticket={ticket} elements={ticket?.ticket_elements} background={ticket?.background_url} preview={false} data={data} user={user} t={t} />
				)}
			</Modal.Body>
		</Modal>
	)
}

export default EventTicketModal