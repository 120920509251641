import React, {Fragment, useState} from "react";
import {Card, Col, Dropdown, DropdownButton, Form, Row, Table} from "react-bootstrap";
import {Field, FieldArray} from "formik";
import FormErrors from "../../../errors/FormErrors";
import {format} from "date-fns";
import DatePicker from 'react-datepicker';
import Flex from "../../../../components/common/Flex";
import IconButton from "../../../../components/common/IconButton";
import FormError from "../../../errors/FormError";
import TinymceEditor from "../../../../components/common/TinymceEditor";

const CodeOfConductContent = ({errors, values, setFieldValue}) => {
    const [date, setDate] = useState(values?.effective_date)

    const renderNewRow = (row, i, arrayHelpers) => {
        return (
            <tr key={i}>
                {row.type === "link" && (
                    <td>
                        <Field
                            name={`documents.${i}.link`}
                        >
                            {({field, form}) => (
                                <Form.Control
                                    placeholder={"Link"}
                                    type={"link"}
                                    name={`Link`}
                                    errors={errors}
                                    formGroupProps={{
                                        className: 'mb-3'
                                    }}
                                    onChange={e => {
                                        setFieldValue(
                                            field.name,
                                            e.target.value
                                        );
                                    }}
                                    value={field.value}
                                />
                            )}
                        </Field>
                        <FormErrors
                            errors={errors?.documents}
                            error={'link'}
                            index={i}
                        />
                    </td>
                )}
                {row.type === "document" && (
                    <td>
                        <Field
                            name={`documents.${i}.document`}
                        >
                            {({field, form}) => (
                                <Form.Control
                                    type={"file"}
                                    name={`documents.${i}.document`}
                                    errors={errors}
                                    formGroupProps={{
                                        className: 'mb-3'
                                    }}
                                    onChange={e => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                setFieldValue(field.name, reader.result);
                                            };
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                />
                            )}
                        </Field>
                        <FormErrors
                            errors={errors?.documents}
                            error={'document'}
                            index={i}
                        />
                    </td>
                )}
                <td>
                    <Flex justifyContent="center" alignItems="center">
                        <IconButton
                            variant="falcon-default"
                            icon="trash"
                            size="sm"
                            onClick={() => {
                                arrayHelpers.remove(i)
                            }}
                        />
                    </Flex>
                </td>
            </tr>
        )
    };

    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <h5 className="text-muted">Code of Conduct:</h5>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Scope:</Form.Label>
                                <Field name={`scope`}>
                                    {({field}) => (
                                        <Form.Control
                                            type="text"
                                            name={`scope`}
                                            placeholder={"Scope"}
                                            errors={errors}
                                            formGroupProps={{
                                                className: 'mb-3'
                                            }}
                                            onChange={e => {
                                                setFieldValue(
                                                    field.name,
                                                    e.target.value
                                                );
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                </Field>
                                <Form.Text>Define the scope or applicability of the code of conduct, such as whether it
                                    applies to all members, employees, volunteers, etc.</Form.Text>
                                <FormError error={errors?.scope}/>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Effective Date:</Form.Label>
                                <Field name={`effective_date`}>
                                    {({field}) => (
                                        <Row>
                                            <DatePicker
                                                selected={date}
                                                onChange={date => {
                                                    setDate(date);
                                                    setFieldValue(
                                                        field.name,
                                                        format(date, 'yyyy-MM-dd')
                                                    );
                                                }}
                                                formatWeekDay={day => day.slice(0, 3)}
                                                className="form-control"
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="MM-DD-YYYY"
                                            />
                                        </Row>
                                    )}
                                </Field>
                                <Form.Text>Indicates when this code of conduct becomes effective</Form.Text>
                                <FormError error={errors?.effective_date}/>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Acknowledgment:</Form.Label>
                                <Field name={`acknowledgment_required`}>
                                    {({field}) => (
                                        <Form.Switch
                                            label={"Acknowledgment Required"}
                                            errors={errors}
                                            formGroupProps={{
                                                className: 'mb-3'
                                            }}
                                            onChange={e => {
                                                setFieldValue(
                                                    field.name,
                                                    e.target.checked
                                                );
                                            }}
                                            checked={field.value}
                                        />
                                    )}
                                </Field>
                                <FormError error={errors?.acknowledgment_required}/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Content:</Form.Label>
                                <Field name={`content`}>
                                    {({field}) => (
                                        <TinymceEditor
                                            value={field.value}
                                            handleChange={newValue => setFieldValue(field.name, newValue)}
                                        />
                                    )}
                                </Field>
                                <FormError error={errors?.content}/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Purpose:</Form.Label>
                                <Field name={`purpose`}>
                                    {({field}) => (
                                        <TinymceEditor
                                            value={field.value}
                                            handleChange={newValue => setFieldValue(field.name, newValue)}
                                        />
                                    )}
                                </Field>
                                <FormError error={errors?.purpose}/>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Label>Related Documents:</Form.Label>
                            <Table
                                responsive
                                className="border-bottom"
                                size={'sm'}
                            >
                                <thead>
                                <tr>
                                    <td></td>
                                    <td className={'p-0'}></td>
                                </tr>
                                </thead>
                                <FieldArray
                                    name={`documents`}
                                    render={arrayHelpers => (
                                        <>
                                            <tbody>
                                            {values?.documents && values?.documents?.length > 0
                                                ? values?.documents?.map(
                                                    (document, i) => (
                                                        renderNewRow(document, i, arrayHelpers)
                                                    )
                                                )
                                                : ''}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td>
                                                    <DropdownButton size={"sm"} variant="falcon-default"
                                                                    title="Add a reference"
                                                                    id="bg-vertical-dropdown-3"
                                                                    onSelect={(eventKey) => {
                                                                        arrayHelpers.insert(
                                                                            values?.documents?.length,
                                                                            {
                                                                                type: eventKey,
                                                                                link: "",
                                                                                document: ""
                                                                            }
                                                                        );
                                                                    }}>
                                                        <Dropdown.Item eventKey="link">Link</Dropdown.Item>
                                                        <Dropdown.Item eventKey="document">Document</Dropdown.Item>
                                                    </DropdownButton>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </>
                                    )}
                                />
                            </Table>
                            <FormError error={errors?.documents}/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className={"mt-3"}>
                <Card.Header>
                    <h5 className="text-muted">Enforcement Procedures:</h5>
                </Card.Header>
                <Card.Body>
                    <Row className={"mt-3"}>
                        <Col lg={6}>
                            <Form.Label>Reporting Mechanisms:</Form.Label>
                            <Table
                                responsive
                                className="border-bottom"
                                size={'sm'}
                            >
                                <thead>
                                <tr>
                                    <td></td>
                                    <td className={'p-0'}></td>
                                </tr>
                                </thead>
                                <FieldArray
                                    name={`enforcementprocedure.reportingmechanism`}
                                    render={arrayHelpers => (
                                        <>
                                            <tbody>
                                            {values?.enforcementprocedure?.reportingmechanism && values?.enforcementprocedure?.reportingmechanism?.length > 0
                                                ? values?.enforcementprocedure?.reportingmechanism?.map(
                                                    (field, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <Field
                                                                    name={`enforcementprocedure.reportingmechanism.${i}.name`}
                                                                >
                                                                    {({field, form}) => (
                                                                        <Form.Control
                                                                            placeholder={"Name"}
                                                                            type={"text"}
                                                                            name={`enforcementprocedure.reportingmechanism.${i}.name`}
                                                                            errors={errors}
                                                                            formGroupProps={{
                                                                                className: 'mb-3'
                                                                            }}
                                                                            onChange={e => {
                                                                                setFieldValue(
                                                                                    field.name,
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </td>
                                                            <td>
                                                                <Flex justifyContent="center" alignItems="center">
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        icon="trash"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(i)
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                                : ''}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td>
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                        transform="shrink-3"
                                                        className={"mt-3"}
                                                        onClick={() => {
                                                            arrayHelpers.insert(
                                                                values?.enforcementprocedure?.reportingmechanism?.length,
                                                                ''
                                                            );
                                                        }}
                                                    >
                                              <span className="d-none d-sm-inline-block ms-1">
                                                Add a mechanism
                                              </span>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </>
                                    )}
                                />
                            </Table>
                        </Col>
                        <Col lg={6}>
                            <Form.Label>Investigation Processes:</Form.Label>
                            <Table
                                responsive
                                className="border-bottom"
                                size={'sm'}
                            >
                                <thead>
                                <tr>
                                    <td></td>
                                    <td className={'p-0'}></td>
                                </tr>
                                </thead>
                                <FieldArray
                                    name={`enforcementprocedure.investigationprocess`}
                                    render={arrayHelpers => (
                                        <>
                                            <tbody>
                                            {values?.enforcementprocedure?.investigationprocess && values?.enforcementprocedure?.investigationprocess?.length > 0
                                                ? values?.enforcementprocedure?.investigationprocess?.map(
                                                    (field, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <Field
                                                                    name={`enforcementprocedure.investigationprocess.${i}.name`}
                                                                >
                                                                    {({field, form}) => (
                                                                        <Form.Control
                                                                            placeholder={"Name"}
                                                                            type={"text"}
                                                                            name={`enforcementprocedure.investigationprocess.${i}.name`}
                                                                            errors={errors}
                                                                            formGroupProps={{
                                                                                className: 'mb-3'
                                                                            }}
                                                                            onChange={e => {
                                                                                setFieldValue(
                                                                                    field.name,
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </td>
                                                            <td>
                                                                <Flex justifyContent="center" alignItems="center">
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        icon="trash"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(i)
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                                : ''}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td>
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                        transform="shrink-3"
                                                        className={"mt-3"}
                                                        onClick={() => {
                                                            arrayHelpers.insert(
                                                                values?.enforcementprocedure?.investigationprocess?.length,
                                                                ''
                                                            );
                                                        }}
                                                    >
                                              <span className="d-none d-sm-inline-block ms-1">
                                                Add a process
                                              </span>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </>
                                    )}
                                />
                            </Table>
                        </Col>
                        <Col lg={12}>
                            <Form.Label>Disciplinary Actions:</Form.Label>
                            <Table
                                responsive
                                className="border-bottom"
                                size={'sm'}
                            >
                                <thead>
                                <tr>
                                    <td></td>
                                    <td className={'p-0'}></td>
                                </tr>
                                </thead>
                                <FieldArray
                                    name={`enforcementprocedure.disciplinaryactions`}
                                    render={arrayHelpers => (
                                        <>
                                            <tbody>
                                            {values?.enforcementprocedure?.disciplinaryactions && values?.enforcementprocedure?.disciplinaryactions?.length > 0
                                                ? values?.enforcementprocedure?.disciplinaryactions?.map(
                                                    (field, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <Field
                                                                    name={`enforcementprocedure.disciplinaryactions.${i}.name`}
                                                                >
                                                                    {({field, form}) => (
                                                                        <Form.Control
                                                                            placeholder={"Name"}
                                                                            type={"text"}
                                                                            name={`enforcementprocedure.disciplinaryactions.${i}.name`}
                                                                            errors={errors}
                                                                            formGroupProps={{
                                                                                className: 'mb-3'
                                                                            }}
                                                                            onChange={e => {
                                                                                setFieldValue(
                                                                                    field.name,
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </td>
                                                            <td>
                                                                <Flex justifyContent="center" alignItems="center">
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        icon="trash"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(i)
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                                : ''}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td>
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                        transform="shrink-3"
                                                        className={"mt-3"}
                                                        onClick={() => {
                                                            arrayHelpers.insert(
                                                                values?.enforcementprocedure?.disciplinaryactions?.length,
                                                                ''
                                                            );
                                                        }}
                                                    >
                                              <span className="d-none d-sm-inline-block ms-1">
                                                Add an action
                                              </span>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </>
                                    )}
                                />
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default CodeOfConductContent