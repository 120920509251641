import React, { useCallback, useEffect, useState } from 'react';
import useQuery from '../../hooks/useQuery';
import { Link, useNavigate } from 'react-router-dom';
import Flex from '../../components/common/Flex';
import Swal from 'sweetalert2';
import { api } from '../../utils/api';
import { toast } from 'react-toastify';
import CardDropdown from '../../components/common/CardDropdown';
import { Card, Dropdown, Spinner } from 'react-bootstrap';
import { getReports } from './actions/actions';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import { debounce } from 'lodash';
import {withPermission} from '../../helpers/utils';
import { useAppContext } from '../../providers/AppProvider';
import paths from "../../routes/paths";
import BaseTableHeader from "../BaseTableHeader";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
  const [sortingDirection, setSortingDirection] = useState('desc');
  const [count, setCount] = useState(0);
  const [length, setLength] = useState(0);
  const query = useQuery();

  const {
    config: { isAuthenticated },
    setConfig
  } = useAppContext();

  const navigate = useNavigate();

  const fetchReports = async query => {
    setLoading(true);
    const sortingParam = `${
      sortingDirection === 'desc' ? '-' : ''
    }${sortingField}`;

    query.set('ordering', sortingParam);

    // Cancel the previous request if it exists
    getReports(query)
      .then(res => {
        setReports(res?.results);
        setCount(res?.count);
      })
      .catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedLoadItems = useCallback(debounce(fetchReports, 600), []);

  useEffect(() => {
    fetchReports(query);
    setLength(reports.length);
    navigate(`?${query.toString()}`);
    // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      canSort: true,
      isSorted: true,
      sortingDirection: sortingField === 'title' && sortingDirection,
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { title, id } = rowData.row.original;
        return (
          <>
            <Link to={`/reports/${id}/detail`}>
              <Flex alignItems="center">
                <div className="flex-1">
                  <h6
                    className="mb-0 fs-0"
                    style={{
                      width: '200px',
                      wordWrap: 'break-word',
                      wordBreak: 'break-all',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {title}
                  </h6>
                </div>
              </Flex>
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      canSort: true,
      sortingDirection: sortingField === 'type' && sortingDirection,
      isSorted: false,
      cellProps: {
        className: 'py-2'
      },
      headerProps: { className: 'pe-3' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id, title } = rowData.row.original;
        const handleDelete = async e => {
          const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
          if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/report/report/${id}/`).then(() => {
              toast.success(`Report ${title} successfully deleted.`, {
                theme: 'colored'
              });
              getReports(query)
                .then(res => {
                  setReports(res?.results);
                  setCount(res.data?.count);
                })
                .catch(() => {});
            });
            setLoading(false);
          }
        };
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item onClick={() => navigate(`/reports/${id}/edit`)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                onClick={e => handleDelete(e)}
                className="text-danger"
              >
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const userList = reports.map(report => ({
    id: report.id,
    title: report.title,
    activity: report.activity,
    activity_name: report.activity_name,
    type: report.type
  }));

  const handleSort = column => {
    if (column.canSort) {
      const { id } = column;
      const isDescending = sortingField === id && sortingDirection === 'desc';

      // Update the sorting field and direction
      setSortingField(id);
      setSortingDirection(isDescending ? 'asc' : 'desc');

      // Modify the sorting properties of the column
      column.isSorted = true;
      column.isSortedDesc = isDescending;
      column.sortingDirection = isDescending ? 'asc' : 'desc';
    }
  };
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={userList}
      selection
      pagination
      perPage={20}
    >
      <Card className="mb-3">
        <Card.Header>
          <BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"report.add_report"}
						creationPath={paths.reportsCreate}
						title={"report"}
						deletePermission={"report.delete_report"}
						exportPermission={"report.add_reportexportfile"}
					/>
        </Card.Header>
        <Card.Body className="p-0">
          {loading ? (
            <Flex justifyContent="center" className="p-2 mb-2">
              <Spinner animation={'border'} variant={'primary'} />
            </Flex>
          ) : (
            <AdvanceTable
              handleSort={handleSort}
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              headers={columns}
            />
          )}
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination
            query={query}
            fetch={fetchReports}
            count={count}
            length={length}
            itemsPerPage={20}
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default withPermission(Reports, "report.view_report");
