import {withPermission} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import {useNavigate, useParams} from "react-router-dom";
import {useAppContext} from "../../../../providers/AppProvider";
import {api} from "../../../../utils/api";
import paths from "../../../../routes/paths";
import {toast} from "react-toastify";
import CSRFToken from "../../../../helpers/CSRFToken";
import {Field, Formik} from "formik";
import Select from "react-select";
import FormError from "../../../errors/FormError";
import IconButton from "../../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import CustomDateInput from "../../../../components/common/CustomDateInput";
import TinymceEditor from "../../../../components/common/TinymceEditor";

const PeriodicTaskAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [activities, setActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState(null)
    const [tasks, setTasks] = useState([])
    const [intervals, setIntervals] = useState([])
    const [selectedInterval, setSelectedInterval] = useState(null)
    const [solars, setSolars] = useState([])
    const [selectedSolar, setSelectedSolar] = useState(null)
    const [clocked, setClocked] = useState([])
    const [selectedClocked, setSelectedClocked] = useState(null)
    const [crontabs, setCrontabs] = useState([])
    const [selectedCrontab, setSelectedCrontab] = useState(null)
    const [date, setDate] = useState(null)
    const [expiryDate, setExpiryDate] = useState(null)
    const [formData, setFormData] = useState({
        description: '',
        periodic_task: {
            schedule: "interval",
            enabled: true
        },
        activity: ""
    });

    const navigate = useNavigate();
    const {id} = useParams();
    const {
        config: {group},
        setConfig
    } = useAppContext()

    const getTask = async () => {
        setLoading(true)
        await api.get(`/activity/periodic-tasks/${id}/`)
            .then(res => {
                setFormData({
                    ...res?.data,
                    periodic_task: {
                        schedule: res?.data?.periodic_task?.schedule_str,
                        description: res?.data?.periodic_task?.description,
                        args: res?.data?.periodic_task?.args,
                        kwargs: res?.data?.periodic_task?.kwargs,
                        name: res?.data?.periodic_task?.name,
                        task: res?.data?.periodic_task?.task,
                        enabled: res?.data?.periodic_task?.enabled,
                        one_off: res?.data?.periodic_task?.one_off,
                        start_time: res?.data?.periodic_task?.start_time,
                        expires: res?.data?.periodic_task?.expires,
                        expire_seconds: res?.data?.periodic_task?.expire_seconds,
                        interval: res?.data?.periodic_task?.interval_data?.id,
                        solar: res?.data?.periodic_task?.solar_data?.id,
                        clocked: res?.data?.periodic_task?.clocked_data?.id,
                        crontab: res?.data?.periodic_task?.crontab_data?.id,
                    }
                });
                setSelectedActivity({label: res?.data?.activity_name, value: res?.data?.activity})
                setSelectedInterval(res?.data?.periodic_task?.schedule_str === "interval" ? {
                    label: res?.data?.periodic_task?.interval_data?.display_name,
                    value: res?.data?.periodic_task?.interval_data?.id
                } : null)
                setSelectedSolar(res?.data?.periodic_task?.schedule_str === "solar" ? {
                    label: res?.data?.periodic_task?.solar_data?.display_name,
                    value: res?.data?.periodic_task?.solar_data?.id
                } : null)
                setSelectedClocked(res?.data?.periodic_task?.schedule_str === "clocked" ? {
                    label: res?.data?.periodic_task?.clocked_data?.display_name,
                    value: res?.data?.periodic_task?.clocked_data?.id
                } : null)
                setSelectedCrontab(res?.data?.periodic_task?.schedule_str === "crontab" ? {
                    label: res?.data?.periodic_task?.crontab_data?.display_name,
                    value: res?.data?.periodic_task?.crontab_data?.id
                } : null)
                setDate(res?.data?.periodic_task?.start_time ? new Date(res?.data?.periodic_task?.start_time) : null)
                setExpiryDate(res?.data?.periodic_task?.expires ? new Date(res?.data?.periodic_task?.expires) : null)
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    }

    const getTasks = async () => {
        await api.get("/utils/tasks/").then(res => setTasks(res?.data))
            .catch(() => {
            })
    }

    const getIntervals = async () => {
        await api.get("/utils/interval/").then(res => setIntervals(res?.data?.results))
            .catch(() => {
            })
    }

    const getSolars = async () => {
        await api.get("/utils/solar/").then(res => setSolars(res?.data?.results))
            .catch(() => {
            })
    }

    const getClocked = async () => {
        await api.get("/utils/clocked/").then(res => setClocked(res?.data?.results))
            .catch(() => {
            })
    }

    const getCrontabs = async () => {
        await api.get("/utils/crontab/").then(res => setCrontabs(res?.data?.results))
            .catch(() => {
            })
    }

    const getActivities = async () => {
        await api.get("/activity/activity/").then(res => {
            setActivities(res?.data?.results)
        }).catch(() => {
        })
    }

    useEffect(() => {
        getTasks()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getIntervals()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getSolars()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getClocked()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getCrontabs()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getActivities()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id) getTask();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e, values) => {
        setLoading(true);
        console.log(values)
        e.preventDefault();
        if (id) {
            await api
                .put(`/activity/periodic-tasks/${id}/`, values)
                .then(res => {
                    toast.success(`${t('title')} ${t('updateSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.periodic);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(t('error', {ns: "common"}), {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        } else {
            await api
                .post(`/activity/periodic-tasks/`, values)
                .then(res => {
                    toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.periodic);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(t('error', {ns: "common"}), {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        }
        setLoading(false)
    }

    let activityOptions = activities?.map(activity => ({label: activity?.name, value: activity?.id}))

    activityOptions = [
        {label: "-------------------------", value: ""},
        ...activityOptions
    ]

    let intervalOptions = intervals?.map(interval => ({label: interval?.display_name, value: interval?.id}))

    intervalOptions = [
        {label: "-------------------------", value: ""},
        ...intervalOptions
    ]

    let solarOptions = solars?.map(solar => ({label: solar?.display_name, value: solar?.id}))

    solarOptions = [
        {label: "-------------------------", value: ""},
        ...solarOptions
    ]

    let clockedOptions = clocked?.map(clocked => ({label: clocked?.display_name, value: clocked?.id}))

    clockedOptions = [
        {label: "-------------------------", value: ""},
        ...clockedOptions
    ]

    let crontabOptions = crontabs?.map(crontab => ({label: crontab?.display_name, value: crontab?.id}))

    crontabOptions = [
        {label: "-------------------------", value: ""},
        ...crontabOptions
    ]

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <Form>
                        <CSRFToken/>
                        <Row className="g-3">
                            <Col lg={6}>
                                <Card>
                                    <Card.Header>
                                        <h5>{t('basic')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        {group?.indexOf(
                                            process.env.REACT_APP_ADMIN_GROUP_NAME
                                        ) !== -1 && (
                                            <Form.Group>
                                                <Form.Label>{t('fields.activity')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"activity"}>
                                                    {({field}) => (
                                                        <Select
                                                            options={activityOptions}
                                                            value={selectedActivity}
                                                            onChange={newValue => {
                                                                setSelectedActivity(newValue)
                                                                setFieldValue(field.name, newValue.value)
                                                            }}
                                                            classNamePrefix={"react-select"}
                                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.activity')}`}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.activity}/>
                                            </Form.Group>
                                        )}
                                        <Form.Group>
                                            <Form.Label>{t('fields.description')}</Form.Label>
                                            <Field name="periodic_task.description">
                                                {({field}) => (
                                                    <TinymceEditor
                                                        value={field.value}
                                                        handleChange={newValue => setFieldValue(field.name, newValue)}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.periodic_task?.description}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>{t('execution')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Label>{t('fields.expires')}</Form.Label>
                                            <Field name={"periodic_task.expires"}>
                                                {({field}) => (
                                                    <Row>
                                                        <DatePicker
                                                            selected={expiryDate}
                                                            onChange={date => {
                                                                setExpiryDate(date);
                                                                setFieldValue(
                                                                    field.name,
                                                                    new Date(date).toISOString()
                                                                );
                                                            }}
                                                            className="form-control"
                                                            placeholderText={t('periodic_task.expires')}
                                                            showTimeSelect
                                                            fixedHeight
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            customInput={
                                                                <CustomDateInput
                                                                    formControlProps={{
                                                                        placeholder: 'MM/dd/yyyy HH:mm',
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </Row>
                                                )}
                                            </Field>
                                            <FormError error={errors?.periodic_task?.expires}/>
                                            <Form.Text>{t('fields.expiresDesc')}</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('fields.expire_seconds')}</Form.Label>
                                            <Field name={"periodic_task.expire_seconds"}>
                                                {({field}) => (
                                                    <Form.Control
                                                        type={"number"}
                                                        placeholder={t('fields.expire_seconds')}
                                                        value={field.value}
                                                        onChange={e => setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.periodic_task?.expire_seconds}/>
                                            <Form.Text>{t('fields.expires_seconds_desc')}</Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card>
                                    <Card.Header>
                                        <h5>{t("task")}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Label>{t('fields.name')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"periodic_task.name"}>
                                                {({field}) => (
                                                    <Form.Control
                                                        name={"periodic_task.name"}
                                                        type={'text'}
                                                        placeholder={t('fields.name')}
                                                        value={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.periodic_task?.name}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('fields.periodic_task')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"periodic_task.task"}>
                                                {({field}) => (
                                                    <Form.Select
                                                        name={"periodic_task.task"}
                                                        value={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.value);
                                                        }}
                                                    >
                                                        <option>{t('select', {ns: "common"})} {t('fields.periodic_task')}</option>
                                                        {tasks?.map(task => (
                                                            <option value={task?.name}>{task?.display_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                )}
                                            </Field>
                                            <FormError error={errors?.periodic_task?.task}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Field name={"periodic_task.enabled"}>
                                                {({field}) => (
                                                    <Form.Switch
                                                        name={"periodic_task.enabled"}
                                                        label={t('fields.enabled')}
                                                        className={"mt-2"}
                                                        checked={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.checked);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.periodic_task?.task}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <div className={"sticky-sidebar"}>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h5>{t('period')}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                                    <Field name={"periodic_task.schedule"}>
                                                        {({field}) => (
                                                            <Form.Check
                                                                label={t('fields.interval')}
                                                                name={"periodic_task.schedule"}
                                                                type={"radio"}
                                                                value={"interval"}
                                                                checked={field.value === "interval"}
                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field name={"periodic_task.schedule"}>
                                                        {({field}) => (
                                                            <Form.Check
                                                                label={t('fields.crontab')}
                                                                name={"periodic_task.schedule"}
                                                                type={"radio"}
                                                                value={"crontab"}
                                                                checked={field.value === "crontab"}
                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field name={"periodic_task.schedule"}>
                                                        {({field}) => (
                                                            <Form.Check
                                                                label={t('fields.solar')}
                                                                name={"periodic_task.schedule"}
                                                                type={"radio"}
                                                                value={"solar"}
                                                                checked={field.value === "solar"}
                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field name={"periodic_task.schedule"}>
                                                        {({field}) => (
                                                            <Form.Check
                                                                label={t('fields.clocked')}
                                                                name={"periodic_task.schedule"}
                                                                type={"radio"}
                                                                value={"clocked"}
                                                                checked={field.value === "clocked"}
                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                </Flex>
                                            </Form.Group>
                                            {values?.periodic_task?.schedule === "interval" &&
                                                <Form.Group>
                                                    <Field name={"periodic_task.interval"}>
                                                        {({field}) => (
                                                            <Select
                                                                options={intervalOptions}
                                                                value={selectedInterval}
                                                                onChange={newValue => {
                                                                    setSelectedInterval(newValue)
                                                                    setFieldValue(field.name, newValue.value)
                                                                }}
                                                                classNamePrefix={"react-select"}
                                                                placeholder={`${t('select', {ns: "common"})} ${t('fields.interval')}`}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.periodic_task?.interval}/>
                                                </Form.Group>
                                            }
                                            {values?.periodic_task?.schedule === "solar" &&
                                                <Form.Group>
                                                    <Field name={"periodic_task.solar"}>
                                                        {({field}) => (
                                                            <Select
                                                                options={solarOptions}
                                                                value={selectedSolar}
                                                                onChange={newValue => {
                                                                    setSelectedSolar(newValue)
                                                                    setFieldValue(field.name, newValue.value)
                                                                }}
                                                                classNamePrefix={"react-select"}
                                                                placeholder={`${t('select', {ns: "common"})} ${t('fields.solar')}`}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.periodic_task?.solar}/>
                                                </Form.Group>
                                            }
                                            {values?.periodic_task?.schedule === "clocked" &&
                                                <Form.Group>
                                                    <Field name={"periodic_task.clocked"}>
                                                        {({field}) => (
                                                            <Select
                                                                options={clockedOptions}
                                                                value={selectedClocked}
                                                                onChange={newValue => {
                                                                    setSelectedClocked(newValue)
                                                                    setFieldValue(field.name, newValue.value)
                                                                }}
                                                                classNamePrefix={"react-select"}
                                                                placeholder={`${t('select', {ns: "common"})} ${t('fields.clocked')}`}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.periodic_task?.clocked}/>
                                                </Form.Group>
                                            }
                                            {values?.periodic_task?.schedule === "crontab" &&
                                                <Form.Group>
                                                    <Field name={"periodic_task.crontab"}>
                                                        {({field}) => (
                                                            <Select
                                                                options={crontabOptions}
                                                                value={selectedCrontab}
                                                                onChange={newValue => {
                                                                    setSelectedCrontab(newValue)
                                                                    setFieldValue(field.name, newValue.value)
                                                                }}
                                                                classNamePrefix={"react-select"}
                                                                placeholder={`${t('select', {ns: "common"})} ${t('fields.crontab')}`}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.periodic_task?.crontab}/>
                                                </Form.Group>
                                            }
                                            <Form.Group>
                                                <Form.Label>{t('fields.start_time')}</Form.Label>
                                                <Field name={"periodic_task.start_time"}>
                                                    {({field}) => (
                                                        <Row>
                                                            <DatePicker
                                                                selected={date}
                                                                onChange={date => {
                                                                    setDate(date);
                                                                    setFieldValue(
                                                                        field.name,
                                                                        new Date(date).toISOString()
                                                                    );
                                                                }}
                                                                className="form-control"
                                                                placeholderText={t('periodic_task.start_time')}
                                                                showTimeSelect
                                                                fixedHeight
                                                                dateFormat="MM/dd/yyyy HH:mm"
                                                                customInput={
                                                                    <CustomDateInput
                                                                        formControlProps={{
                                                                            placeholder: 'MM/dd/yyyy HH:mm',
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Row>
                                                    )}
                                                </Field>
                                                <FormError error={errors?.periodic_task?.start_time}/>
                                                <Form.Text>{t('fields.start_time_desc')}</Form.Text>
                                            </Form.Group>
                                            <Form.Group>
                                                <Field name={"periodic_task.one_off"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            name={"periodic_task.one_off"}
                                                            label={t('fields.one_off')}
                                                            className={"mt-2"}
                                                            value={field.value}
                                                            onChange={e => {
                                                                setFieldValue(field.name, e.target.checked);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.periodic_task?.one_off}/>
                                                <Form.Text>
                                                    {t('fields.one_off_desc')}
                                                </Form.Text>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h5>{t('arguments')}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Form.Label>{t('fields.args')}</Form.Label>
                                                <Field name={"periodic_task.args"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"periodic_task.args"}
                                                            as={"textarea"}
                                                            placeholder={"[]"}
                                                            value={field.value}
                                                            onChange={e => {
                                                                setFieldValue(field.name, e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.periodic_task?.args}/>
                                                <Form.Text>
                                                    {t('fields.args_desc')}
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{t('fields.kwargs')}</Form.Label>
                                                <Field name={"periodic_task.kwargs"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"periodic_task.kwargs"}
                                                            as={"textarea"}
                                                            placeholder={"{}"}
                                                            value={field.value}
                                                            onChange={e => {
                                                                setFieldValue(field.name, e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.periodic_task?.kwargs}/>
                                                <Form.Text>
                                                    {t('fields.kwargs_desc')}
                                                </Form.Text>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Card>
                                    <Card.Footer>
                                        <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                            <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                                            <IconButton
                                                icon={faSave}
                                                onClick={e => handleSubmit(e, values)}
                                            >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                                            </IconButton>
                                        </Flex>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default withPermission(withTranslation(["periodics", "common"])(PeriodicTaskAddEdit), ["activity.add_activityperiodictask", "activity.change_activityperiodictask"]);