import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Form} from 'react-bootstrap';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProfileBannerHeader = ({avatar, coverSrc, className, setFormData, formData, detail}) => {
	const [cover, setCover] = useState(coverSrc);  // Start with default eventBanner
	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setCover(reader.result);
				setFormData({...formData, cover: file})
			};
			reader.readAsDataURL(file);
		}
	};
	return (
		<Card.Header
			className={classNames(className, 'position-relative min-vh-25 mb-7 cover-image overflow-visible')}
		>
			<Background image={cover} className="rounded-3 rounded-bottom-0"/>
			{!detail &&
				<Form.Group controlId="formFile">
					<Form.Label className="cover-image-file-input">
						<FontAwesomeIcon icon="camera" className="me-2"/>
						<span>Upload cover photo</span>
					</Form.Label>
					<Form.Control type="file" className="d-none" accept={"image/*"}
					              onChange={handleImageChange}/>
				</Form.Group>
			}
			<Avatar
				size="5xl"
				className="avatar-profile"
				src={avatar}
				mediaClass="img-thumbnail shadow-sm"
			/>
		</Card.Header>
	);
};

const ProfileBannerBody = ({children}) => {
	return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner = ({children}) => {
	return <Card className="mb-3">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
	avatar: PropTypes.string.isRequired,
	coverSrc: PropTypes.string.isRequired,
	className: PropTypes.string
};

ProfileBannerBody.propTypes = {
	children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
	children: PropTypes.node.isRequired
};

export default ProfileBanner;
