import {withPermission} from "../../../../helpers/utils";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../../utils/api";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import IconButton from "../../../../components/common/IconButton";
import CodeOfConductCard from "../cards/CodeOfConductCard";
import {usePermissions} from "../../../../providers/PermissionsProvider";

const RuleDetail = () => {
	const [loading, setLoading] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);
	const [formData, setFormData] = useState({
		title: '',
		rule_type: '',
		activity: '',
		status: '',
		content: "",
		effective_date: "",
		scope: "",
		purpose: "",
		acknowledgment_required: "",
		enforcementprocedure: {
			disciplinaryactions: [],
			investigationprocess: [],
			reportingmechanism: [],
		},
		documents: [],
	});

	const {id} = useParams();
	const navigate = useNavigate()
	const {hasPermission} = usePermissions()

	const getRule = async () => {
		setLoading(true);
		await api
			.get(`/rule/rule/${id}/`)
			.then(async res => {
				setSelectedActivity({
					value: res?.data?.activity,
					label: res?.data?.activity_name
				});
				setFormData({
					...res?.data
				});
				if (res?.data?.rule_type === 'code_of_conduct') {
					await api.get(`/rule/codeofconduct/${id}/`).then(rule => {
						const updatedDocuments = rule?.data?.document_set?.map(document => ({
							link: document?.link,
							type: document?.type,
							document_url: document?.document_url
						}));
						const updatedActions = rule?.data?.enforcement?.actions?.map(action => ({
							name: action?.name,
						}))
						const updatedReportings = rule?.data?.enforcement?.reportings?.map(reporting => ({
							name: reporting?.name,
						}))
						const updatedInvestigations = rule?.data?.enforcement?.investigations?.map(investigation => ({
							name: investigation?.name,
						}))
						setFormData({
							...rule?.data,
							documents: updatedDocuments,
							enforcementprocedure: {
								investigationprocess: updatedInvestigations,
								reportingmechanism: updatedReportings,
								disciplinaryactions: updatedActions
							}
						});
					});
				}
			})
			.catch(err => {
				if (err?.response?.status === 404) navigate('/errors/404');
			});
		setLoading(false);
	};

	useEffect(() => {
		getRule()
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card className="mb-3 d-print-none">
						<Card.Body>
							<Row>
								<Col md>
									<h5 className="mb-2 mb-md-0">{formData?.title}</h5>
								</Col>
								<Col xs="auto">
									<IconButton
										variant="falcon-default"
										size="sm"
										icon="print"
										iconClassName="me-1"
										className="me-1 mb-2 mb-sm-0"
										onClick={() => window.print()}
									>
										Print
									</IconButton>
									{hasPermission("rule.change_rule") &&
									<IconButton
										variant="falcon-primary"
										size="sm"
										icon="edit"
										iconClassName="me-1"
										className="me-1 mb-2 mb-sm-0"
										onClick={() => navigate(`/management/rule/${id}/edit`)}
									>
										Edit
									</IconButton>
									}
								</Col>
							</Row>
						</Card.Body>
					</Card>
					{formData?.rule_type === "code_of_conduct" &&
						<CodeOfConductCard data={formData} activity={selectedActivity?.label}/>}
				</Col>
			</Row>
		</Fragment>
	)
}

export default withPermission(RuleDetail, "rule.view_rule");
