import Flex from 'components/common/Flex';
import React, {useEffect, useState} from 'react';
import {Card, Form} from 'react-bootstrap';
import {api} from '../../../utils/api';
import useQuery from '../../../hooks/useQuery';
import {Field} from 'formik';
import Select from 'react-select';
import FormError from '../../errors/FormError';
import {useAppContext} from '../../../providers/AppProvider';
import CreatableSelect from "react-select/creatable";

const EventOtherInfo = ({values, setFieldValue, errors, t}) => {
    const [activities, setActivities] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState(values?.tags_names ? values?.tags_names : []);
    const [selectedActivity, setSelectedActivity] = useState(
        values?.activity
            ? {value: values?.activity, label: values?.activity_name}
            : null
    );

    const {config: {group}} = useAppContext();

    let query = useQuery();

    const getActivities = async () => {
        return await api.get(`/activity/activity/?${query.toString()}`);
    };

    const getTags = async () => {
        return await api.get(`/event/tag/?${query.toString()}`);
    };

    useEffect(() => {
        getActivities()
            .then(res => setActivities(res?.data?.results))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getTags()
            .then(res => setTags(res?.data?.results))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, []);

    let activitiesOptions = activities?.map(activity => ({
        value: activity.id,
        label: activity.name
    }));

    activitiesOptions = [
        {value: '', label: '--------------------------'},
        ...activitiesOptions
    ];

    const tagOptions = [
        ...tags?.map(tag => ({value: tag.id, label: tag.name}))
    ];
    return (
        <Card>
            <Card.Header as="h5">{t('fields.other.title')}</Card.Header>
            <Card.Body className="bg-light">
                {group?.indexOf(
                    process.env.REACT_APP_ADMIN_GROUP_NAME
                ) !== -1 && (
                    <Form.Group className="mb-3">
                        <Flex className="flex-between-center">
                            <Form.Label>{t('fields.other.activity')}</Form.Label>
                        </Flex>
                        <Field name={`activity`}>
                            {({field}) => (
                                <Select
                                    name={`activity`}
                                    options={activitiesOptions}
                                    value={selectedActivity}
                                    classNamePrefix={'react-select'}
                                    placeholder={`${t('select', {ns: "common"})} ${t('fields.other.activity')}`}
                                    onChange={value => {
                                        setSelectedActivity(value);
                                        setFieldValue(field.name, value.value);
                                    }}
                                />
                            )}
                        </Field>
                        <FormError error={errors.activity}/>
                    </Form.Group>
                )}

                <Form.Group className="mb-3">
                    <Form.Label>{t('fields.other.type.title')} <span className={"text-danger"}>*</span></Form.Label>
                    <Field name={`type`}>
                        {({field}) => (
                            <Form.Select
                                value={field.value}
                                onChange={e => setFieldValue(field.name, e.target.value)}
                            >
                                <option value="">{t('select', {ns: "common"})} {t('fields.other.type.title')}</option>
                                <option value="training">{t('fields.other.type.training')}</option>
                                <option value="concert">{t('fields.other.type.concert')}</option>
                                <option value="conference">{t('fields.other.type.conference')}</option>
                                <option value="convention">{t('fields.other.type.convention')}</option>
                                <option value="dinner">{t('fields.other.type.dinner')}</option>
                                <option value="fair">{t('fields.other.type.fair')}</option>
                            </Form.Select>
                        )}
                    </Field>
                </Form.Group>
                <FormError error={errors.type}/>

                <Form.Group className="mb-3">
                    <Form.Label>{t('fields.other.topic.title')} <span className={"text-danger"}>*</span></Form.Label>
                    <Field name={`topic`}>
                        {({field}) => (
                            <Form.Select
                                value={field.value}
                                onChange={e => setFieldValue(field.name, e.target.value)}
                            >
                                <option value="">{t('select', {ns: "common"})} {t('fields.other.topic.title')}</option>
                                <option value="auto">{t('fields.other.topic.auto')}</option>
                                <option value="business">{t('fields.other.topic.business')}</option>
                                <option value="charity">{t('fields.other.topic.charity')}</option>
                                <option value="community">{t('fields.other.topic.community')}</option>
                                <option value="family">{t('fields.other.topic.family')}</option>
                                <option value="fashion">{t('fields.other.topic.fashion')}</option>
                                <option value="media">{t('fields.other.topic.media')}</option>
                                <option value="food">{t('fields.other.topic.food')}</option>
                                <option value="politics">{t('fields.other.topic.politics')}</option>
                            </Form.Select>
                        )}
                    </Field>
                </Form.Group>
                <FormError error={errors.topic}/>

                <Form.Group className="mb-3">
                    <Flex alignItems="center" justifyContent="between">
                        <Form.Label>{t('fields.other.tags')}</Form.Label>
                    </Flex>
                    <Field name={`tags`}>
                        {({field}) => (
                            <CreatableSelect
                                value={selectedTags}
                                closeMenuOnSelect={false}
                                isMulti
                                isClearable
                                options={tagOptions}
                                classNamePrefix={'react-select'}
                                placeholder={`${t('select', {ns: "common"})} ${t('fields.other.tags')}`}
                                onInputChange={e => {
                                    query.set('search', e);
                                    getTags()
                                }}
                                formatCreateLabel={inputValue => `${t('create', {ns: "common"})} ${inputValue}`}
                                onChange={async value => {
                                    console.log(value)
                                    for (const v1 of value.filter(v => v.__isNew__)) {
                                        await api.post("/event/tag/", {name: v1.label})
                                            .then(res => {
                                                setSelectedTags(prevState => [
                                                    ...prevState,
                                                    {label: res?.data?.name, value: res?.data?.id}
                                                ])
                                                setFieldValue(
                                                    field.name,
                                                    res?.data?.id
                                                );
                                            })
                                            .catch(() => {
                                            })
                                    }
                                    setSelectedTags(value);
                                    setFieldValue(
                                        field.name,
                                        value.filter(v => !v.__isNew__).map(tag => tag.value)
                                    );
                                }}
                            />
                        )}
                    </Field>
                </Form.Group>
                <FormError error={errors.tags}/>

                <div className="border-dashed border-bottom my-3"></div>
                <h6>{t('fields.other.privacy.title')} <span className={"text-danger"}>*</span></h6>
                <Field name={`public`}>
                    {({field}) => (
                        <>
                            <Form.Switch
                                checked={field.value}
                                name={`public`}
                                label={values?.public ? t('fields.other.privacy.public') : t('fields.other.privacy.private')}
                                onChange={e => setFieldValue(field.name, e.target.checked)}
                            />
                            {values?.public ? (
                                <div className="form-text mt-0">
                                    {t('fields.other.privacy.accessPublic')}
                                </div>
                            ) : (
                                <div className="form-text mt-0">
                                    {t('fields.other.privacy.accessPrivate')}.
                                </div>
                            )}
                        </>
                    )}
                </Field>
                <FormError error={errors.public}/>
            </Card.Body>
        </Card>
    );
};

export default EventOtherInfo;
