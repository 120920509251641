import GoogleMap from 'components/map/GoogleMap';
import React from 'react';
import {useAppContext} from '../../../providers/AppProvider';

const MapDark = ({location, address}) => {
	return (
		<GoogleMap
			initialCenter={{
				lat: location?.lat,
				lng: location?.lng
			}}
			children={address}
			className="vh-50 rounded-soft mt-5"
			mapContainerStyle={{
				width: '100%',
				height: '100%'
			}}
		/>
	);
};
const MapLight = ({location, address}) => {
	return (
		<GoogleMap
			initialCenter={{
				lat: location?.lat,
				lng: location?.lng
			}}
			children={address}
			className="vh-50 rounded-soft mt-5"
			mapContainerStyle={{
				width: '100%',
				height: '100%'
			}}
		/>
	);
};
const EventDetailsGmap = ({location, address}) => {
	const {
		config: {isDark}
	} = useAppContext();

	return <>{isDark ? <MapDark location={location} address={address}/> :
		<MapLight location={location} address={address}/>}</>;
};

export default EventDetailsGmap;
