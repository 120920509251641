import React, {useEffect, useState} from 'react';
import PageHeader from 'components/common/PageHeader';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import PricingDefaultHeader from './PricingDefaultHeader';
import PricingDefaultCard from './PricingDefaultCard';
import {api} from "../../utils/api";

const PricingDefault = () => {
  const [loading, setLoading] = useState(false);
  const [pricingData, setPricingData] = useState([]);
  const [monthly, setMonthly] = useState(true);

  const getMemberships = async () => {
    setLoading(true)
    await api.get("/membership/membership/").then((response) => {
      setPricingData(response?.data?.results)
    })
    setLoading(false)
  }

  useEffect(() => {
    getMemberships()
  }, []);

  return (
    <>
      <PageHeader
        title="For teams of all sizes, in the cloud"
        description="Get the power, control, and customization you need to manage your <br class='d-none d-md-block' /> team’s and organization’s projects."
        className="mb-3"
        titleTag="h2"
      >
        <Link className="btn btn-sm btn-link ps-0" to="#!">
          Have questions? Chat with us
        </Link>
      </PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-0">
            <PricingDefaultHeader monthly={monthly} setMonthly={setMonthly} />
            {loading ? (
              <Col xs={12} className="py-4">
                <Spinner
                  className="position-absolute start-50"
                  animation="grow"
                />
              </Col>
            ) : (
              pricingData?.map(pricing => (
                <PricingDefaultCard key={pricing.id} pricing={pricing} monthly={monthly} />
              ))
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default PricingDefault;
