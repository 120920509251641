import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Processes = ({t, i18n}) => (
  <Section className={'bg-white'} id={'offers'}>
    <SectionHeader title={t('offers.title')} subtitle="" />
    {isIterableArray(processList) &&
      processList.map((process, index) => (
        <Process
          key={process.color}
          isFirst={index === 0}
          index={index}
          {...process}
          t={t}
          i18n={i18n}
        />
      ))}
  </Section>
);

export default Processes;
