import React, {useCallback, useEffect, useState} from 'react';
import {Card, Col, Offcanvas, Row, Spinner} from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AllTicketsHeader from './AllTicketsHeader';
import TicketFilteringForm from './TicketFilteringForm';
import {useBreakpoints} from 'hooks/useBreakpoints';
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import Flex from "../../../components/common/Flex";
import SoftBadge from "../../../components/common/SoftBadge";
import Avatar from "../../../components/common/Avatar";
import Priority from "../../../components/app/support-desk/tickets-layout/Priority";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import classNames from "classnames";
import AgentSelect from "./AgentSelect";
import {withPermission} from "../../../helpers/utils";

const TableView = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);

    const query = useQuery();
    const {breakpoints} = useBreakpoints();
    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchTickets = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);
        await api.get(`/support/tickets/?${q.toString()}`)
            .then(res => {
                setData(res?.data?.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchTickets, 600), []);

    useEffect(() => {
        fetchTickets(query);
        setLength(data?.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'client',
            Header: 'Client',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'client' && sortingDirection,
            headerProps: {className: 'ps-2 text-900', style: {height: '46px'}},
            cellProps: {
                className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
            },
            Cell: ({row: {original}}) => {
                const {client, client_full_name, client_avatar} = original;
                return (
                    <Flex alignItems="center" className="position-relative py-1">
                        {client_avatar ? (
                            <Avatar src={client_avatar} size="xl" className="me-2"/>
                        ) : (
                            <Avatar size="xl" name={client_full_name} className="me-2"/>
                        )}
                        <h6 className="mb-0">
                            <Link to={paths.usersDetail.replace(":id", client)} className="stretched-link text-900">
                                {client_full_name}
                            </Link>
                        </h6>
                    </Flex>
                );
            }
        },
        {
            accessor: 'subject',
            Header: 'Subject',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'subject' && sortingDirection,
            headerProps: {
                style: {minWidth: '14.625rem'},
                className: 'text-900'
            },
            cellProps: {
                className: 'py-2 pe-4'
            },
            Cell: ({row: {original}}) => {
                const {subject, id} = original;
                return (
                    <Link to={paths.detailSupportTicket.replace(":id", id)} className="fw-semibold">
                        {subject}
                    </Link>
                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'status' && sortingDirection,
            headerProps: {className: 'text-900'},
            cellProps: {
                className: 'fs-9 pe-4'
            },
            Cell: ({row: {original}}) => {
                const {status, status_display} = original;
                return (
                    <SoftBadge bg={classNames({
                        success: status === "recent",
                        danger: status === "overdue",
                        warning: status === "remaining",
                        info: status === "responded",
                        secondary: status === "closed",
                    })} className="me-2">
                        {status_display}
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'priority',
            Header: 'Priority',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'priority' && sortingDirection,
            headerProps: {className: 'text-900'},
            cellProps: {
                className: 'pe-4'
            },
            Cell: ({row: {original}}) => {
                const {priority, priority_display} = original;
                return (
                    <Priority
                        title={priority_display}
                        color={classNames({
                            "#e63757": priority === "urgent",
                            "#F68F57": priority === "high",
                            "#2A7BE4": priority === "medium",
                            "#00D27B": priority === "low",
                        })}
                        data={classNames({
                            "100": priority === "urgent",
                            "75": priority === "high",
                            "50": priority === "medium",
                            "25": priority === "low",
                        })}
                    />
                );
            }
        },
        {
            accessor: 'agent',
            Header: 'Agent',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'agent' && sortingDirection,
            headerProps: {className: 'text-900'},
            Cell: ({row: {original}}) => {
                const {agent, id} = original;
                return <AgentSelect agent={agent} setLoading={setLoading} fetch={fetchTickets} id={id}/>;
            }
        }
    ];

    const ticketList = data?.map(ticket => ({
        id: ticket.id,
        client: ticket.client,
        client_full_name: ticket.client_full_name,
        client_avatar: ticket.client_avatar,
        subject: ticket.subject,
        status: ticket.status,
        status_display: ticket.status_display,
        priority: ticket.priority,
        priority_display: ticket.priority_display,
        agent: ticket.agent,
        agent_name: ticket.agent_name,
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <Row className="gx-3">
            <Col xxl={10} xl={9}>
                <AdvanceTableWrapper
                    columns={columns}
                    data={ticketList}
                    selection
                    pagination
                    perPage={20}
                >

                    <Card>
                        <Card.Header className="border-bottom border-200 px-0">
                            <AllTicketsHeader table layout="table-view" handleShow={handleShow}
                                              fetchData={delayedLoadItems}/>
                        </Card.Header>
                        <Card.Body className="p-0">
                            {loading ? (
                                <Flex justifyContent="center" className="p-2 mb-2">
                                    <Spinner animation={'border'} variant={'primary'}/>
                                </Flex>
                            ) : (
                                <AdvanceTable
                                    headerClassName="bg-body-tertiary align-middle"
                                    rowClassName="btn-reveal-trigger align-middle"
                                    tableProps={{
                                        size: 'sm',
                                        className: 'fs--1 mb-0 overflow-hidden'
                                    }}
                                    handleSort={handleSort}
                                    table
                                    headers={columns}
                                />
                            )}
                        </Card.Body>
                        <Card.Footer>
                            <AdvanceTablePagination
                                count={count}
                                fetch={fetchTickets}
                                query={query}
                                length={length}
                                itemsPerPage={20}
                            />
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            </Col>
            <Col xxl={2} xl={3}>
                {breakpoints.down('xl') ? (
                    <Offcanvas
                        show={show}
                        onHide={handleClose}
                        placement="end"
                        className="dark__bg-card-dark"
                    >
                        <Offcanvas.Header closeButton className="bg-body-tertiary">
                            <h6 className="fs-9 mb-0 fw-semibold">Filter</h6>
                        </Offcanvas.Header>
                        <TicketFilteringForm setLoading={setLoading} fetch={fetchTickets}/>
                    </Offcanvas>
                ) : (
                    <TicketFilteringForm setLoading={setLoading} fetch={fetchTickets}/>
                )}
            </Col>
        </Row>
    );
};

export default withPermission(TableView, "support.view_supportticket");
