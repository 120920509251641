import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, Spinner} from 'react-bootstrap';
import CSRFToken from '../../helpers/CSRFToken';
import Flex from "../../components/common/Flex";
import FormError from "../errors/FormError";
import {api} from "../../utils/api";
import paths from "../../routes/paths";

const ForgetPasswordForm = ({t, i18n}) => {
	// State
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState({})

	// Handler
	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true)
		if (email) {
			await api.post("/dj-rest-auth/password/reset/", {email: email})
				.then((res) => {
					toast.success(`${t('emailSent', {ns: 'common'})} ${email} ${t('withPasswordLink', {ns: 'common'})}`, {
						theme: 'colored'
					});
				})
				.catch(error => {
          toast.error(`${t('error', {ns: "common"})}`, {
						theme: 'colored'
					});
					setErrors(error?.response?.data)
				})
		}
		setLoading(false)
	};

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Form className="mt-4" onSubmit={handleSubmit}>
			<CSRFToken/>
			<Form.Group className="mb-3">
				<Form.Control
					placeholder={t('fields.email')}
					value={email}
					name="email"
					onChange={({target}) => setEmail(target.value)}
					type="email"
				/>
				<FormError error={errors?.email}/>
			</Form.Group>

			<Form.Group className="mb-3">
				<Button className="w-100" type="submit" disabled={!email}>
					{t('forget.btnSend')}
				</Button>
			</Form.Group>

			<Link className="fs--1 text-600" to={paths.splitLogin}>
				<span className="d-inline-block ms-1"> &larr;</span>
				{t('forget.loginReturn')}
			</Link>
		</Form>
	);
};

ForgetPasswordForm.propTypes = {
	layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = {layout: 'simple'};

export default ForgetPasswordForm;
