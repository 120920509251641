import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import {useNavigate} from "react-router-dom";

const TicketFilteringForm = ({fetch, setLoading}) => {
    const [agents, setAgents] = useState([])
    const query = useQuery()
    const navigate = useNavigate()

    const getAgents = async () => {
        query.set("is_staff", "true")
        await api.get(`/account/user/?${query.toString()}`).then(res => setAgents(res?.data?.results))
    }

    useEffect(() => {
        getAgents()
        // eslint-disable-next-line
    }, []);

    const handleFilter = e => {
        navigate(`?${query.toString()}`)
        fetch(query)
    }

    return (
        <Card className="shadow-none shadow-show-xl scrollbar">
            <Card.Header className="bg-body-tertiary d-none d-xl-block">
                <h6 className="mb-0">Filter</h6>
            </Card.Header>
            <Card.Body>
                <Form>
                    <div className="mb-2 mt-n2">
                        <Form.Label className="mb-1 fs-10">Priority</Form.Label>
                        <Form.Select size="sm" defaultValue={query.get("priority")} onChange={e => query.set("priority", e.target.value)}>
                            <option value={""}>None</option>
                            <option value={"urgent"}>Urgent</option>
                            <option value={"high"}>High</option>
                            <option value={"medium"}>Medium</option>
                            <option value={"low"}>Low</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <Form.Label className="mb-1 mt-2 fs-10">Status</Form.Label>
                        <Form.Select size="sm" defaultValue={query.get("status")} onChange={e => query.set("status", e.target.value)}>
                            <option value={""}>None</option>
                            <option value={"recent"}>Recent</option>
                            <option value={"overdue"}>Overdue</option>
                            <option value={"closed"}>Closed</option>
                            <option value={"remaining"}>Remaining</option>
                            <option value={"responded"}>Responded</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <Form.Label className="mb-1 mt-2 fs-10">Agent</Form.Label>
                        <Form.Select size="sm" defaultValue={query.get("agent")} onChange={e => query.set("agent", e.target.value)}>
                            <option value={""}>None</option>
                            {agents?.map(item => (
                                <option key={item?.id} value={item?.id}>{item?.full_name}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <Form.Label className="mb-1 mt-2 fs-10">Type</Form.Label>
                        <Form.Select size="sm" defaultValue={query.get("type")} onChange={e => query.set("type", e.target.value)}>
                            <option value={""}>None</option>
                            <option value={"question"}>Question</option>
                            <option value={"incident"}>Incident</option>
                            <option value={"problem"}>Problem</option>
                            <option value={"feature"}>Feature Request</option>
                            <option value={"refund"}>Refund</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <Form.Label className="mb-1 mt-2 fs-10">Category</Form.Label>
                        <Form.Select size="sm" defaultValue={query.get("category")} onChange={e => query.set("category", e.target.value)}>
                            <option value={""}>None</option>
                            <option value={"general"}>General Issue</option>
                            <option value={"maintenance"}>Maintenance</option>
                            <option value={"engineering"}>Engineering</option>
                            <option value={"accounts"}>Accounts</option>
                            <option value={"feedback"}>Feedback</option>
                            <option value={"support"}>Support</option>
                            <option value={"test"}>Test Helpdesk</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <Form.Label className="mb-1 mt-2 fs-10">Tag</Form.Label>
                        <Form.Select size="sm" defaultValue={query.get("tag")} onChange={e => query.set("tag", e.target.value)}>
                            <option value={""}>None</option>
                            <option value={"sales"}>Sales</option>
                            <option value={"request"}>Request</option>
                            <option value={"complaint"}>Complaint</option>
                            <option value={"feedback"}>Feedback</option>
                            <option value={"support"}>Support</option>
                        </Form.Select>
                    </div>
                </Form>
            </Card.Body>
            <Card.Footer className="border-top border-200 py-x1">
                <Button varient="primary" className="w-100" onClick={handleFilter}>
                    Update
                </Button>
            </Card.Footer>
        </Card>
    );
};

export default TicketFilteringForm;
