import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import Flex from "../../../components/common/Flex";
import {formatDateTime, withPermission} from "../../../helpers/utils";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import Avatar from "../../../components/common/Avatar";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SoftBadge from "../../../components/common/SoftBadge";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import {debounce} from "lodash";
import {useAppContext} from "../../../providers/AppProvider";
import paths from "../../../routes/paths";
import {usePermissions} from "../../../providers/PermissionsProvider";
import BaseTableHeader from "../../BaseTableHeader";

const Requests = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortingField, setSortingField] = useState("created_at"); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState("desc");
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig,
    } = useAppContext();
    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchRequests = async (query) => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === "desc" ? "-" : ""
        }${sortingField}`;

        query.set("ordering", sortingParam);
        await api
            .get(`/join/join/?${query.toString()}`)
            .then((res) => {
                setRequests(res?.data?.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    };

    // eslint-disable-next-line
    const delayedLoadItems = useCallback(debounce(fetchRequests, 600), []);

    useEffect(() => {
        fetchRequests(query);
        setLength(requests?.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: "user",
            Header: "User",
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === "user" && sortingDirection,
            headerProps: {className: "pe-1"},
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const {user_full_name, user_avatar, user} = rowData.row.original;
                return (
                    <>
                        <Link to={`/accounts/user/${user}/detail`}>
                            <Flex alignItems="center">
                                {user_avatar ? (
                                    <Avatar
                                        src={user_avatar}
                                        rounded={"circle"}
                                        size="4xl"
                                        className="me-2"
                                    />
                                ) : (
                                    <Avatar
                                        size="4xl"
                                        name={user_full_name}
                                        rounded={"circle"}
                                        className="me-2"
                                    />
                                )}
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: "200px",
                                            wordWrap: "break-word",
                                            wordBreak: "break-all",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    >
                                        {user_full_name}
                                    </h6>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            },
        },
        {
            headerProps: {className: "pe-1"},
            cellProps: {
                className: "py-2",
            },
            accessor: "status",
            Header: "Status",
            canSort: true,
            sortingDirection: sortingField === "status" && sortingDirection,
            isSorted: false,
            Cell: (rowData) => {
                const {status, status_display} = rowData.row.original;
                return (
                    <>
                        <SoftBadge
                            pill
                            bg={classNames({
                                success: status === "accepted",
                                warning: status === "pending",
                                secondary: status === "draft",
                                danger: status === "rejected",
                            })}
                            className="d-flex flex-center"
                        >
                            <p className="mb-0">{status_display}</p>
                            <FontAwesomeIcon
                                icon={classNames({
                                    check: status === "accepted",
                                    clock: status === "pending",
                                    paperclip: status === "draft",
                                    ban: status === "rejected",
                                })}
                                transform="shrink-2"
                                className="ms-1"
                            />
                        </SoftBadge>
                    </>
                );
            },
        },
        {
            accessor: "created_at",
            Header: "Requested At",
            canSort: true,
            headerProps: {className: "pe-1 text-center"},
            cellProps: {
                className: "py-2 fs-0 text-center",
            },
            sortingDirection: sortingField === "created_at" && sortingDirection,
            isSorted: false,
            Cell: (rowData) => <>{formatDateTime(rowData.row.original.created_at)}</>,
        },
        {
            accessor: "none",
            Header: "",
            disableSortBy: true,
            cellProps: {
                className: "text-end",
            },
            Cell: (rowData) => {
                const {id} = rowData.row.original;
                const handleDelete = async (e) => {
                    const {isConfirmed} = await Swal.fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/join/join/${id}/`).then(() => {
                            toast.success(`Request successfully deleted.`, {
                                theme: "colored",
                            });
                            fetchRequests(query)
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("join.view_join") &&
                                <Dropdown.Item onClick={() => navigate(paths.requestDetail.replace(":id", id))}>
                                    Detail
                                </Dropdown.Item>
                            }
                            <Dropdown.Divider as="div"/>
                            {hasPermission("join.delete_join") &&
                                <Dropdown.Item
                                    onClick={(e) => handleDelete(e)}
                                    className="text-danger"
                                >
                                    Delete
                                </Dropdown.Item>
                            }
                        </div>
                    </CardDropdown>
                );
            },
        },
    ];

    const requestList = requests.map((user) => ({
        id: user.id,
        user_full_name: user.user_full_name,
        status: user.status,
        activity: user.activity,
        activity_name: user.activity_name,
        status_display: user.status_display,
        user_avatar: user.user_avatar,
        created_at: user.created_at,
        user: user.user,
    }));

    const handleSort = (column) => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === "desc";

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? "asc" : "desc");

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? "asc" : "desc";
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={requestList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"join.add_join"}
                        title={"request"}
                        deletePermission={"join.delete_join"}
                        exportPermission={"join.add_joinexportfile"}
                        data={requestList}
                        setLoading={setLoading}
                        deleteLink={"/join/delete/join/"}
                        exportLink={"/join/export/join/"}
                        fields={["user__username", "status", "acknowledgment", "created_at", "updated_at"]}
                        exportsPath={paths.requestExports}
                        ns={"requests"}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={"border"} variant={"primary"}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchRequests}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default withPermission(Requests, "join.view_join");
