import React, {useEffect, useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import EventDetailAside from './EventDetailAside';
import EventDetailContent from './EventDetailContent';
import EventDetailHeader from './EventDetailHeader';
import {useNavigate, useParams} from 'react-router-dom';
import {api} from '../../../utils/api';
import paths from "../../../routes/paths";
import {useAppContext} from "../../../providers/AppProvider";
import Flex from "../../../components/common/Flex";
import Section from "../../../components/common/Section";

export const EventDetail = () => {
    const [event, setEvent] = useState({});
    const [loading, setLoading] = useState(false)
    const {id} = useParams();
    const navigate = useNavigate();

    const {
        setConfig
    } = useAppContext()

    const getEvent = async () => {
        setLoading(true)
        await api.get(`/event/event/${id}/`)
            .then(res => setEvent(res?.data))
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false)
    };

    useEffect(() => {
        getEvent()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Section>
            <EventDetailHeader event={event} getEvent={getEvent} setLoading={setLoading}/>
            <Row className="g-3">
                <Col lg={8}>
                    <EventDetailContent event={event}/>
                </Col>
                <Col lg={4}>
                    <EventDetailAside event={event} location={event?.address ? JSON.parse(event?.address) : ""}/>
                </Col>
            </Row>

        </Section>
    );
};
