import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import {formatDay, formatMonth} from "../../../../helpers/utils";

const Event = ({ details, isLast, t }) => {
  const {
    id,
    start_date,
    title,
    activity_name,
    activity,
    badge = {},
    place,
    full_address,
    duration,
    interested
  } = details;
  return (
    <Flex>
      <Calendar month={formatMonth(start_date)} day={formatDay(start_date)} />
      <div className="flex-1 position-relative ps-3">
        <h6 className="fs-9 mb-0">
          <Link to={paths.eventDetail.replace(":id", id)}>
            <span className="me-1">{title}</span>
            {badge.title && (
              <span className={`badge badge-${badge.type} rounded-pill `}>
                {badge.title}
              </span>
            )}
          </Link>
        </h6>
        <p className="mb-1">
          {t('by')}
          <Link to={paths.activityDetail.replace(":id", activity)} className="text-700 ps-1">
            {activity_name}
          </Link>
        </p>

        {duration && <p className="text-1000 mb-0">{t('duration')}: {duration}</p>}
        {interested && <p className="text-1000 mb-0">{interested}</p>}
        {full_address && <p className="text-1000 mb-0">{t('location')}: {full_address}</p>}

        <p className="mb-0">{t('place')}: {place}</p>
        {!isLast && <div className="border-dashed border-bottom my-3"></div>}
      </div>
    </Flex>
  );
};

Event.propTypes = {
  details: PropTypes.shape({
    calendar: PropTypes.shape(Calendar.propTypes),
    title: PropTypes.string.isRequired,
    organizer: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    location: PropTypes.string,
    duration: PropTypes.string,
    interested: PropTypes.string,
    badge: PropTypes.object
  }),
  isLast: PropTypes.bool
};

export default Event;
