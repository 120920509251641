import React, {useCallback, useEffect, useState} from 'react';
import {Card, Dropdown, Spinner} from 'react-bootstrap';
import Flex from '../../../components/common/Flex';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../../components/common/advance-table/AdvanceTableWrapper';
import useQuery from '../../../hooks/useQuery';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {api} from '../../../utils/api';
import {toast} from 'react-toastify';
import CardDropdown from '../../../components/common/CardDropdown';
import {getGroups} from '../actions/GroupsPermissions';
import {debounce} from 'chart.js/helpers';
import {withPermission} from '../../../helpers/utils';
import {useAppContext} from '../../../providers/AppProvider';
import {usePermissions} from "../../../providers/PermissionsProvider";
import paths from "../../../routes/paths";
import BaseTableHeader from "../../BaseTableHeader";
import {withTranslation} from "react-i18next";

const Groups = ({t, i18n}) => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('name'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();

    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchGroups = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);

        // Cancel the previous request if it exists
        getGroups(q)
            .then(res => {
                setGroups(res?.results);
                setCount(res?.count);
            })
            .catch(error => {
                if (error?.response?.status === 403) {
                    setConfig('isAuthenticated', !isAuthenticated);
                }
            });
        setLength(groups.length);
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchGroups, 600), []);

    useEffect(() => {
        fetchGroups(query);
        setLength(groups.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'name',
            Header: t('fields.name'),
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            }
        },
        {
            accessor: 'permissions',
            canSort: true,
            sortingDirection: sortingField === 'permissions' && sortingDirection,
            isSorted: false,
            Header: t('fields.permissions'),
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'fs-0 py-2'
            },
            Cell: rowData => (
                <ul>
                    {rowData.row.original.permission_names?.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            )
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id, name} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: "common"}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: t('cancel', {ns: "common"}),
                        confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/account/group/${id}/`).then(() => {
                            toast.success(`${t('title')} ${name} ${t('deleteSuccess', {ns: "common"})}`, {
                                theme: 'colored'
                            });
                            getGroups(query)
                                .then(res => {
                                    setGroups(res?.results);
                                    setCount(res?.count);
                                })
                                .catch(() => {
                                });
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("auth.change_group") &&
                                <Dropdown.Item
                                    onClick={() => navigate(`/accounts/group/${id}/edit`)}
                                >
                                    {t('edit', {ns: "common"})}
                                </Dropdown.Item>
                            }
                            <Dropdown.Divider as="div"/>
                            {hasPermission("auth.delete_group") &&
                                <Dropdown.Item
                                    onClick={e => handleDelete(e)}
                                    className="text-danger"
                                >
                                    {t('delete', {ns: "common"})}
                                </Dropdown.Item>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const userList = groups.map(group => ({
        id: group.id,
        name: group.name,
        permission_names: group.permission_names,
        permission: group.permission
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };
    return (
        <AdvanceTableWrapper
            columns={columns}
            data={userList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        data={userList}
                        addPermission={"auth.add_group"}
                        creationPath={paths.groupsAdd}
                        title={"group"}
                        deletePermission={"auth.delete_group"}
                        exportPermission={"accounts.add_groupexportfile"}
                        exportLink={"/account/export/group/"}
                        deleteLink={"/account/delete/group/"}
                        fields={["name", "permissions"]}
                        setLoading={setLoading}
                        exportsPath={paths.groupsExport}
                        ns={"groups"}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchGroups}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default withPermission(withTranslation(["groups", "common"])(Groups), "auth.view_group")