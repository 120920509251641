import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const CaliforniaResidents = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="caresidents" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">California Residents</h5>
			</Card.Header>
			<Card.Body>
				<h6>California Consumer Privacy Act (CCPA)</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal Information we
					collect and how we use it, the categories of sources from whom we collect Personal Information, and the
					third parties with whom we share it, which we have explained above.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					We are also required to communicate information about rights California residents have under California law.
					You may exercise the following rights:
				</p>
				<ul type="1">
					<li>
						Right to Know and Access. You may submit a verifiable request for information regarding the: (1)
						categories of Personal Information we collect, use, or share; (2) purposes for which categories of
						Personal Information are collected or used by us; (3) categories of sources from which we collect
						Personal Information; and (4) specific pieces of Personal Information we have collected about you.
					</li>
					<li>
						Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.
					</li>
					<li>
						Right to Delete. You may submit a verifiable request to close your account and we will delete Personal
						Information about you that we have collected.
					</li>
					<li>
						Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
					</li>
				</ul>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
					please contact us.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					We do not sell the Personal Information of our users.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					For more information about these rights, please contact us.
				</p>
				<hr className="my-4"/>
				<h6>California Online Privacy Protection Act (CalOPPA)</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					CalOPPA requires us to disclose categories of Personal Information we collect and how we use it, the
					categories of sources from whom we collect Personal Information, and the third parties with whom we share
					it, which we have explained above.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					CalOPPA users have the following rights:
				</p>
				<ul type="1">
					<li>
						Right to Know and Access. You may submit a verifiable request for information regarding the: (1)
						categories of Personal Information we collect, use, or share; (2) purposes for which categories of
						Personal Information are collected or used by us; (3) categories of sources from which we collect
						Personal Information; and (4) specific pieces of Personal Information we have collected about you.
					</li>
					<li>
						Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.
					</li>
					<li>
						Right to Delete. You may submit a verifiable request to close your account and we will delete Personal
						Information about you that we have collected.
					</li>
					<li>
						Right to request that a business that sells a consumer's personal data, not sell the consumer's personal
						data.
					</li>
				</ul>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					If you make a request, we have one month to respond to you. If you would like to exercise any of these
					rights, please contact us.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					We do not sell the Personal Information of our users.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					For more information about these rights, please contact us.
				</p>
			</Card.Body>
		</Card>
	)
})

export default CaliforniaResidents