import React from 'react';
import PageHeader from 'components/common/PageHeader';
import {Link} from "react-router-dom";
import paths from "../../../../routes/paths";

const BillingHeader = ({
  membership_details,
  ...rest
}) => {

  return (
    <PageHeader
      title={"You're in the " + membership_details?.name + ' Plan'}
      description={membership_details?.desc}
      // className="mb-3"
      {...rest}
    >
      <Link to={paths.pricingList}>Compare plans</Link>
    </PageHeader>
  );
};

export default BillingHeader;
