import Section from "../../components/common/Section";
import SectionHeader from "./SectionHeader";
import React, {useEffect, useState} from "react";
import {Card, Col, Image, Row} from "react-bootstrap";
import {api} from "../../utils/api";
import {Link} from "react-router-dom";
import paths from "../../routes/paths";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {formatDateTime} from "../../helpers/utils";

const Chronicles = ({t, i18n}) => {
    const [posts, setPosts] = useState([])

    const fetchPosts = async () => {
        await api.get(`/blog/post/`).then((res) => {
            setPosts(res?.data?.results)
        })
    }

    useEffect(() => {
        fetchPosts()
        // eslint-disable-next-line
    }, []);

    return (
        <Section className="py-0 overflow-hidden light mt-3" position="center" overlay fluid>
            <SectionHeader title={t("blog.title")} subtitle={''}/>
            <Row className="mt-6 mb-6">
                {posts?.slice(0, 8)?.map((post, index) => (
                    <Col lg={3}>
                        <Card style={{
                            borderRadius: "20px"
                        }} key={index}>
                            <Card.Header className={"position-relative rounded-top overflow-visible"}>
                                <Link
                                    to={paths.blogLandingDetail.replace(":id", post?.id)}
                                    className="d-block h-sm-100"
                                    key={index}
                                >
                                    <Image
                                        src={post?.photo_url}
                                        style={{
                                            maxHeight: "250px"
                                        }}
                                        className={classNames('h-100 w-100 fit-cover img-thumbnail')}
                                        alt={post?.title}
                                    />
                                </Link>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Link to={paths.blogLandingDetail.replace(":id", post?.id)}>{post?.title}</Link>
                                    </Col>
                                    <Col>
                                        <p className={"text-muted float-end"}>
                                            <FontAwesomeIcon icon={faClock} className={"me-2"} />
                                            {formatDateTime(post?.date_posted)}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <div dangerouslySetInnerHTML={{__html: post?.content.slice(0, 500)}}></div>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Section>
    )
}

export default Chronicles;