import React, {useRef} from 'react';
import PageHeader from 'components/common/PageHeader';
import {Card, Col, Nav, Row} from 'react-bootstrap';
import Liability from './Liability';
import useScrollSpy from 'react-use-scrollspy';
import Definitions from "./Definitions";
import Agreement from "./Agreement";
import Service from "./Service";
import Updates from "./Updates";
import Disclaimer from "./Disclaimer";
import Contact from "./Contact";
import GeneralTerms from "./GeneralTerms";
import License from "./License";
import Section from "../../components/common/Section";

const TermsAndConditions = () => {
	const sectionRefs = [
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null)
	];

	const activeSection = useScrollSpy({
		sectionElementRefs: sectionRefs,
		offsetPx: -150
	});

	return (
		<Section fluid>
			<PageHeader
				title="Terms & Conditions"
				className="mb-3"
			/>
			<Row className="g-0">
				<Col lg={8} className="pe-lg-2 order-1 order-lg-0">
					<GeneralTerms ref={sectionRefs[0]}/>
					<License ref={sectionRefs[1]}/>
					<Definitions ref={sectionRefs[2]}/>
					<Agreement ref={sectionRefs[3]}/>
					<Service ref={sectionRefs[4]}/>
					<Updates ref={sectionRefs[5]}/>
					<Liability ref={sectionRefs[6]}/>
					<Disclaimer ref={sectionRefs[7]}/>
					<Contact ref={sectionRefs[8]}/>
				</Col>
				<Col lg={4} className="ps-lg-2 mb-3">
					<div className="sticky-sidebar">
						<Card className="sticky-top">
							<Card.Header className="border-bottom">
								<h6 className="mb-0 fs-9">On this page</h6>
							</Card.Header>
							<Card.Body>
								<div
									id="terms-sidebar"
									className="terms-sidebar nav flex-column fs-10"
								>
									<Nav.Item>
										<Nav.Link
											href="#general"
											className="nav-link px-0 py-1"
											active={activeSection === 0}
										>
											General Terms
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#license"
											className="nav-link px-0 py-1"
											active={activeSection === 1}
										>
											License
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#definitions"
											className="nav-link px-0 py-1"
											active={activeSection === 2}
										>
											Definitions and key terms
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#agreement"
											className="nav-link px-0 py-1"
											active={activeSection === 3}
										>
											Agreement
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#service"
											className="nav-link px-0 py-1"
											active={activeSection === 4}
										>
											Services
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#updates"
											className="nav-link px-0 py-1"
											active={activeSection === 5}
										>
											Updates
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#liability"
											className="nav-link px-0 py-1"
											active={activeSection === 6}
										>
											Liability
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#disclaimer"
											className="nav-link px-0 py-1"
											active={activeSection === 7}
										>
											Disclaimer
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#contact"
											className="nav-link px-0 py-1"
											active={activeSection === 8}
										>
											Contact Us
										</Nav.Link>
									</Nav.Item>
								</div>
							</Card.Body>
						</Card>
					</div>
				</Col>
			</Row>
		</Section>
	);
};

export default TermsAndConditions;
