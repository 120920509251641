import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Spinner} from 'react-bootstrap';
import StatsChart from './StatsChart';
import classNames from 'classnames';
import {api} from "../../../../utils/api";
import Flex from "../../../../components/common/Flex";
import {useAppContext} from "../../../../providers/AppProvider";

const Stats = () => {
	const [completedEvents, setCompletedEvents] = useState({})
	const [eventValue, setEventValue] = useState({})
	const [participationRate, setParticipationRate] = useState({})
	const [loading, setLoading] = useState(false)

	const {
		setConfig
	} = useAppContext()

	const getStats = async () => {
		setLoading(true)
		await api.get(`/analytics/stats/completedevents/`).then(res => setCompletedEvents(res?.data))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		await api.get(`/analytics/stats/eventvalue/`).then(res => setEventValue(res?.data))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		await api.get(`/analytics/stats/participationrate/`).then(res => setParticipationRate(res?.data))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		setLoading(false)
	}

	let stats = [
		completedEvents,
		eventValue,
		participationRate
	]

	useEffect(() => {
		getStats()
		// eslint-disable-next-line
	}, []);

	return (
		<Card>
			<Card.Body className="py-5 py-sm-3">
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<Row className="g-5 g-sm-0">
						{stats?.map((stat, index) => (
							<Col sm={4} key={stat.title}>
								<div
									className={classNames({
										'border-sm-end border-300': index !== 2
									})}
								>
									<div className="text-center">
										<h6 className="text-700">{stat.title}</h6>
										<h3 className="fw-normal text-700">{stat.value}</h3>
									</div>
									<StatsChart data={stat.chart_data} grid={stat.grid}/>
								</div>
							</Col>
						))}
					</Row>
				)}
			</Card.Body>
		</Card>
	);
};

export default Stats;
