import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row, Spinner, Table} from 'react-bootstrap';
import Flex from '../../../components/common/Flex';
import {Field, FieldArray} from 'formik';
import FormError from '../../errors/FormError';
import {getMembers} from '../../member/actions/Member';
import useQuery from '../../../hooks/useQuery';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import FormErrors from '../../errors/FormErrors';
import IconButton from '../../../components/common/IconButton';
import TinymceEditor from "../../../components/common/TinymceEditor";

const MeetingReport = ({values, setFieldValue, errors}) => {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState(
        values?.members
            ? values?.members?.map((member, index) => ({
                value: member,
                label: values?.members_names[index]
            }))
            : null
    );
    const [date, setDate] = useState(
        values?.date ? new Date(values?.date) : null
    );

    let query = useQuery();

    useEffect(() => {
        setLoading(true);
        query.set('activity', values?.activity);
        getMembers(query).then(res => setMembers(res?.results));
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    let memberOptions = members?.map(member => ({
        label: member.full_name,
        value: member.id
    }));

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <>
            <Col xs={6}>
                <div className={'sticky-sidebar'}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0 text-muted">Meeting Details</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Subject:</Form.Label>
                                        <Field name="subject">
                                            {({field}) => (
                                                <Form.Control
                                                    placeholder="Subject"
                                                    value={field.value}
                                                    onChange={e => {
                                                        setFieldValue(field.name, e.target.value);
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <FormError error={errors.subject}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Location:</Form.Label>
                                        <Field name="location">
                                            {({field}) => (
                                                <Form.Control
                                                    placeholder="Location"
                                                    value={field.value}
                                                    onChange={e => {
                                                        setFieldValue(field.name, e.target.value);
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <FormError error={errors.location}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Members:</Form.Label>
                                        <Field name="members">
                                            {({field}) => (
                                                <Select
                                                    options={memberOptions}
                                                    placeholder="Select members"
                                                    classNamePrefix="react-select"
                                                    value={selectedMembers}
                                                    onChange={value => {
                                                        setFieldValue(
                                                            field.name,
                                                            value.map(val => val.value)
                                                        );
                                                        setSelectedMembers(value);
                                                    }}
                                                    isMulti
                                                />
                                            )}
                                        </Field>
                                        <FormError error={errors.members}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Date:</Form.Label>
                                        <Field name="date">
                                            {({field}) => (
                                                <Row>
                                                    <DatePicker
                                                        selected={date}
                                                        onChange={date => {
                                                            setDate(date);
                                                            setFieldValue(
                                                                field.name,
                                                                new Date(date).toISOString()
                                                            );
                                                        }}
                                                        formatWeekDay={day => day.slice(0, 3)}
                                                        className="form-control"
                                                        placeholderText="Select Date & Time"
                                                        timeIntervals={5}
                                                        dateFormat="MM/dd/yyyy h:mm aa"
                                                        showTimeSelect
                                                        fixedHeight
                                                    />
                                                </Row>
                                            )}
                                        </Field>
                                        <FormError error={errors.date}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Label>Description:</Form.Label>
                                <Field name="description">
                                    {({field}) => (
                                        <TinymceEditor
                                            value={field.value}
                                            handleChange={newValue => setFieldValue(field.name, newValue)}
                                        />
                                    )}
                                </Field>
                                <FormError error={errors.description}/>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
            <Col xs={6}>
                <Card>
                    <Card.Header>
                        <h5 className="mb-0 text-muted">Action Plans</h5>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive className="border-bottom" size={'sm'}>
                            <thead>
                            <tr>
                                <td>Action</td>
                                <td>Assignee</td>
                                <td className={'p-0'}></td>
                            </tr>
                            </thead>
                            <FieldArray
                                name={'actionplans'}
                                render={arrayHelpers => (
                                    <>
                                        <tbody>
                                        {values?.actionplans && values?.actionplans.length > 0
                                            ? values?.actionplans?.map((field, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Field name={`actionplans.${index}.action`}>
                                                            {({field, form}) => (
                                                                <Form.Control
                                                                    placeholder={'Action'}
                                                                    name={`actionplans.${index}.action`}
                                                                    errors={errors}
                                                                    formGroupProps={{className: 'mb-3'}}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            field.name,
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    value={field.value}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormErrors
                                                            errors={errors?.actionplans}
                                                            error={'action'}
                                                            index={index}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Field name={`actionplans.${index}.assigned`}>
                                                            {({field, form}) => (
                                                                <Form.Select
                                                                    placeholder={'Assignee'}
                                                                    name={`actionplans.${index}.assigned`}
                                                                    errors={errors}
                                                                    formGroupProps={{className: 'mb-3'}}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            field.name,
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    value={field.value}
                                                                >
                                                                    <option value={''}>Assignee</option>
                                                                    {memberOptions?.map(member => (
                                                                        <option value={member.value}>
                                                                            {member.label}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            )}
                                                        </Field>
                                                        <FormErrors
                                                            errors={errors?.actionplans}
                                                            error={'assigned'}
                                                            index={index}
                                                        />
                                                    </td>
                                                    <td className={'me-0 pe-0'}>
                                                        <Flex
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            className={'mt-1'}
                                                        >
                                                            <IconButton
                                                                variant="falcon-default"
                                                                icon="trash"
                                                                size={'sm'}
                                                                iconClassName={'ms-1 text-danger'}
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }}
                                                            >
                                                                {' '}
                                                            </IconButton>
                                                        </Flex>
                                                    </td>
                                                </tr>
                                            ))
                                            : ''}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td>
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="plus"
                                                    transform="shrink-3"
                                                    onClick={() => {
                                                        arrayHelpers.insert(
                                                            values?.actionplans.length + 1,
                                                            ''
                                                        );
                                                    }}
                                                >
                            <span className="d-none d-sm-inline-block ms-1">
                              Add item
                            </span>
                                                </IconButton>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </>
                                )}
                            />
                        </Table>
                        <FormError error={errors.actionplans}/>
                    </Card.Body>
                </Card>
                <Card className={'mt-3'}>
                    <Card.Header>
                        <h5 className="mb-0 text-muted">Agenda</h5>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive className="border-bottom" size={'sm'}>
                            <thead>
                            <tr>
                                <td>Title</td>
                                <td className={'p-0'}></td>
                            </tr>
                            </thead>
                            <FieldArray
                                name={'agendas'}
                                render={arrayHelpers => (
                                    <>
                                        <tbody>
                                        {values?.agendas && values?.agendas.length > 0
                                            ? values?.agendas?.map((field, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Field name={`agendas.${index}.title`}>
                                                            {({field, form}) => (
                                                                <Form.Control
                                                                    placeholder={'Title'}
                                                                    name={`expenses.${index}.title`}
                                                                    errors={errors}
                                                                    formGroupProps={{className: 'mb-3'}}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            field.name,
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    value={field.value}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormErrors
                                                            errors={errors?.agendas}
                                                            error={'title'}
                                                            index={index}
                                                        />
                                                    </td>
                                                    <td className={'me-0 pe-0'}>
                                                        <Flex
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            className={'mt-1'}
                                                        >
                                                            <IconButton
                                                                variant="falcon-default"
                                                                icon="trash"
                                                                size={'sm'}
                                                                iconClassName={'ms-1 text-danger'}
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }}
                                                            >
                                                                {' '}
                                                            </IconButton>
                                                        </Flex>
                                                    </td>
                                                </tr>
                                            ))
                                            : ''}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td>
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="plus"
                                                    transform="shrink-3"
                                                    onClick={() => {
                                                        arrayHelpers.insert(values?.agendas.length, '');
                                                    }}
                                                >
                            <span className="d-none d-sm-inline-block ms-1">
                              Add item
                            </span>
                                                </IconButton>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </>
                                )}
                            />
                        </Table>
                        <FormError error={errors.agendas}/>
                    </Card.Body>
                </Card>
                <Card className={'mt-3'}>
                    <Card.Header>
                        <h5 className="mb-0 text-muted">Results</h5>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive className="border-bottom" size={'sm'}>
                            <thead>
                            <tr>
                                <td>Name</td>
                                <td className={'p-0'}></td>
                            </tr>
                            </thead>
                            <FieldArray
                                name={'results'}
                                render={arrayHelpers => (
                                    <>
                                        <tbody>
                                        {values?.results && values?.results.length > 0
                                            ? values?.results?.map((field, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Field name={`results.${index}.name`}>
                                                            {({field, form}) => (
                                                                <Form.Control
                                                                    placeholder={'Name'}
                                                                    name={`results.${index}.name`}
                                                                    errors={errors}
                                                                    formGroupProps={{className: 'mb-3'}}
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            field.name,
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    value={field.value}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormErrors
                                                            errors={errors?.results}
                                                            error={'name'}
                                                            index={index}
                                                        />
                                                    </td>
                                                    <td className={'me-0 pe-0'}>
                                                        <Flex
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            className={'mt-1'}
                                                        >
                                                            <IconButton
                                                                variant="falcon-default"
                                                                icon="trash"
                                                                size={'sm'}
                                                                iconClassName={'ms-1 text-danger'}
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }}
                                                            >
                                                                {' '}
                                                            </IconButton>
                                                        </Flex>
                                                    </td>
                                                </tr>
                                            ))
                                            : ''}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td>
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="plus"
                                                    transform="shrink-3"
                                                    onClick={() => {
                                                        arrayHelpers.insert(values?.results.length, '');
                                                    }}
                                                >
                            <span className="d-none d-sm-inline-block ms-1">
                              Add item
                            </span>
                                                </IconButton>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </>
                                )}
                            />
                        </Table>
                        <FormError error={errors.results}/>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default MeetingReport;
