import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import {Card, Dropdown} from 'react-bootstrap';
import {v4 as uuid} from 'uuid';
import {getSize} from "../../../helpers/utils";
import IconButton from "../../../components/common/IconButton";
import FalconCardHeader from "../../../components/common/FalconCardHeader";

const EventUpload = ({setFieldValue, values, id, onRemove}) => {
    const [files, setFiles] = useState(values?.images || []);

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.png', '.jpg']
        },
        onDrop: acceptedFiles => {
            const fileReaders = acceptedFiles.map(file => {
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    reader.onloadend = () => {
                        resolve({
                            id: uuid(),
                            image: {
                                base64: reader.result,
                                path: file.path,
                                size: file.size,
                                preview: URL.createObjectURL(file),
                            },
                            data: {
                                base64: reader.result,
                                path: file.path,
                                size: file.size,
                                preview: URL.createObjectURL(file),
                            }
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(fileReaders).then(newFiles => {
                setFiles(prevFiles => {
                    const updatedFiles = [...prevFiles, ...newFiles];
                    setFieldValue('images', updatedFiles);
                    return updatedFiles;
                });
            }).catch(error => {
                console.error("Error reading files: ", error);
            });
        }
    });

    const handleRemove = path => {
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter(file => file?.data?.path !== path);
            setFieldValue('images', updatedFiles);
            return updatedFiles;
        });
    };

    return (
        <Card className="mb-3 mt-3">
            <FalconCardHeader
                light
                title={"Upload Photos"}
                titleTag="h5"
                className="py-2"
                endEl={<IconButton
                    variant="falcon-default"
                    icon="trash"
                    size={'sm'}
                    iconClassName={'ms-1 text-danger'}
                    onClick={() => onRemove("images")}
                >
                    {' '}
                </IconButton>}
            />
            <Card.Body className="bg-body-tertiary">
                <div {...getRootProps({className: 'dropzone-area py-6'})}>
                    <input name="uploadedFiles" {...getInputProps()} />
                    <Flex justifyContent="center">
                        <img src={cloudUpload} alt="" width={25} className="me-2"/>
                        <p className="fs-9 mb-0 text-700">Drop your images here</p>
                    </Flex>
                </div>
                <div>
                    {files?.map(file => (
                        <Flex
                            alignItems="center"
                            className="py-3 border-bottom btn-reveal-trigger"
                            key={file?.data?.path}
                        >
                            <img
                                className="rounded"
                                width={40}
                                height={40}
                                src={file?.data?.preview || file?.image_url}
                                alt={file?.data?.path || file?.image_url}
                            />

                            <Flex
                                justifyContent="between"
                                alignItems="center"
                                className="ms-3 flex-1"
                            >
                                <div>
                                    <h6>{file?.data?.path}</h6>
                                    <Flex className="position-relative" alignItems="center">
                                        <p className="mb-0 fs-10 text-400 line-height-1">
                                            <strong>{getSize(file?.data?.size)}</strong>
                                        </p>
                                    </Flex>
                                </div>
                            </Flex>
                            <CardDropdown>
                                <div className="py-2">
                                    <Dropdown.Item
                                        className="text-danger"
                                        onClick={() => handleRemove(file?.data?.path)}
                                    >
                                        Remove
                                    </Dropdown.Item>
                                </div>
                            </CardDropdown>
                        </Flex>
                    ))}
                </div>
            </Card.Body>
        </Card>
    );
};

EventUpload.propTypes = {
    setValue: PropTypes.func
};

export default EventUpload;
