import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import Follower from './Follower';
import useQuery from '../../../hooks/useQuery';
import { api } from '../../../utils/api';
import AdvanceTableSearchBox from '../../../components/common/advance-table/AdvanceTableSearchBox';
import { debounce } from 'lodash';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import Flex from '../../../components/common/Flex';
import { useParams } from 'react-router-dom';
import {withPermission} from "../../../helpers/utils";

const Followers = () => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  let query = useQuery();
  const { id } = useParams();

  const getFollowers = async q => {
    setLoading(true);
    if (id) {
      await api.get(`/account/follower/${id}/`).then(res => {
        setFollowers(res?.data?.results);
        setCount(res?.data?.count);
      });
    } else {
      await api.get(`/account/followers/?${q.toString()}`).then(res => {
        setFollowers(res?.data?.results);
        setCount(res?.data?.count);
      });
    }

    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedLoadItems = useCallback(debounce(getFollowers, 600), []);

  useEffect(() => {
    getFollowers(query);
    // eslint-disable-next-line
    }, []);

  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="align-items-center g-1">
          <Col className="pe-0">
            <h5 className="mb-0">Followers ({count}) </h5>
          </Col>
          <Col>
            <Row className="g-2">
              <Col>
                <AdvanceTableSearchBox fetch={delayedLoadItems} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
        {loading ? (
          <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'} />
          </Flex>
        ) : (
          <Row className="gx-0 gy-1 text-center">
            {followers?.map(follower => (
              <Col key={follower?.id} xs={6} md={4} lg={3} xxl={2}>
                <Follower follower={follower} />
              </Col>
            ))}
          </Row>
        )}
      </Card.Body>
      <Card.Footer>
        <AdvanceTablePagination
          count={count}
          fetch={getFollowers}
          query={query}
          length={followers?.length}
          itemsPerPage={20}
        />
      </Card.Footer>
    </Card>
  );
};

export default withPermission(Followers, "accounts.view_userfollow");
