import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

const Follower = ({ follower }) => {
  return (
    <div className="bg-white dark__bg-1100 p-3 h-100">
      <a href={`/accounts/user/${follower?.follower}/detail`}>
        <Image
          thumbnail
          fluid
          roundedCircle
          className="mb-3 shadow-sm"
          src={follower?.user_avatar}
          width={100}
        />
      </a>
      <h6 className="mb-1">
        <a href={`/accounts/user/${follower?.follower}/detail`}>
          {follower?.user_full_name}
        </a>
      </h6>
      <p className="fs--2 mb-1 text-700">{follower?.user_heading}</p>
    </div>
  );
};

Follower.propTypes = {
  follower: PropTypes.shape({
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    institution: PropTypes.string
  })
};

export default Follower;
