import {formatDateTime, withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../../BaseTableHeader";
import Flex from "../../../components/common/Flex";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";

const Newsletters = ({t, i18n}) => {
    const [newsletters, setNewsletters] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

    const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();

    const {hasPermission} = usePermissions()

	const navigate = useNavigate();

    const fetchNewsletters = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);
		await api.get(`/newsletter/?${q.toString()}`)
			.then(res => {
				setNewsletters(res?.data?.results);
				setCount(res?.data?.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLength(newsletters.length);
		setLoading(false);
	}

    // eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchNewsletters, 600), []);

	useEffect(() => {
		fetchNewsletters(query);
		setLength(newsletters.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

    const columns = [
		{
			accessor: 'title',
			Header: t('fields.title'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'title' && sortingDirection,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 text-center'
			},
			Cell: rowData => <Link to={paths.newsletterEdit.replace(":id", rowData.row.original.id)}>{rowData.row.original.title}</Link>
		},
		{
			accessor: 'sent',
			Header: t('fields.sent'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'sent' && sortingDirection,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 text-center'
			},
			Cell: (rowData) => {
				const {sent} = rowData.row.original;
				return (
					<>
						<SoftBadge
							pill
							bg={classNames({
								success: sent,
								danger: !sent,
							})}
							className="d-flex flex-center"
						>
							<p className="mb-0">{sent ? t('fields.sent') : t('fields.not_sent')}</p>
							<FontAwesomeIcon
								icon={classNames({
									check: sent,
									ban: !sent,
								})}
								transform="shrink-2"
								className="ms-1"
							/>
						</SoftBadge>
					</>
				);
			},
		},
		{
			accessor: 'created_at',
			Header: t('fields.created_at', {ns: "common"}),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'created_at' && sortingDirection,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 text-center'
			},
			Cell: rowData => formatDateTime(rowData.row.original.created_at)
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {id, title} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: t('sure', {ns: "common"}),
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
						cancelButtonText: t('cancel', {ns: "common"})
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/newsletter/${id}/`).then(() => {
							toast.success(
								`${t('title')} ${title} ${t('deleteSuccess', {ns: "common"})}`,
								{
									theme: 'colored'
								}
							);
							fetchNewsletters(query)
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("newsletter.change_newsletter") &&
								<Dropdown.Item onClick={() => navigate(paths.newsletterEdit.replace(":id", id))}>
									{t('edit', {ns: "common"})}
								</Dropdown.Item>
							}
							<Dropdown.Divider as="div"/>
							{hasPermission("newsletter.delete_newsletter") &&
								<Dropdown.Item
									onClick={e => handleDelete(e)}
									className="text-danger"
								>
									{t('delete', {ns: "common"})}
								</Dropdown.Item>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	]

    const newsletterList = newsletters?.map(newsletter => ({
		id: newsletter.id,
		title: newsletter.title,
		sent: newsletter.sent,
		created_at: newsletter.created_at,
	}));

    const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

    return (
		<AdvanceTableWrapper
			columns={columns}
			data={newsletterList}
			selection
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"newsletter.add_newsletter"}
						creationPath={paths.newsletterCreate}
						title={"newsletter"}
						deletePermission={"newsletter.delete_newsletter"}
						deleteLink={"/newsletter/delete/newsletter/"}
						data={newsletterList}
						setLoading={setLoading}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchNewsletters}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
}

export default withPermission(withTranslation(["newsletter", "common"])(Newsletters), "newsletter.view_newsletter")
