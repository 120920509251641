import React, {Fragment, useState} from "react";
import {Card, CardHeader, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "components/common/Flex";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import IconButton from "components/common/IconButton";
import {api} from "utils/api";
import CSRFToken from "helpers/CSRFToken";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import paths from "../../routes/paths";
import FormError from "../errors/FormError";
import TinymceEditor from "../../components/common/TinymceEditor";

const NewTimeline = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        title: "",
        version: "",
        note: ""
    });
    const navigate = useNavigate()

    const handleFieldChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        await api.post("/release_note/release/", formData)
            .then((res) => {
                toast.success("Release successfully added.", {theme: "colored"});
                navigate(paths.timeline)
            })
            .catch((err) => {
                toast.error("An error has occurred.", {theme: "colored"});
                setErrors(err?.response?.data)
            });
        setLoading(false)
    };

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={"border"} variant={"primary"}/>
        </Flex>
    ) : (
        <Fragment>
            <Form>
                <CSRFToken/>
                <Row className={"g-3 mt-1 mb-3"}>
                    <Col xs={12}>
                        <Card>
                            <Card.Header
                                as={Flex}
                                justifyContent="between"
                                alignItems="center"
                            >
                                <h5 className="mb-0 text-muted">New Release</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group>
                                            <Form.Label>Title:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="title"
                                                placeholder="Title"
                                                formGroupProps={{
                                                    className: "mb-3",
                                                }}
                                                onChange={handleFieldChange}
                                                value={formData.title}
                                            />
                                            <FormError error={errors?.title}/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group>
                                            <Form.Label>Version:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="version"
                                                placeholder="Version"
                                                formGroupProps={{
                                                    className: "mb-3",
                                                }}
                                                onChange={handleFieldChange}
                                                value={formData.version}
                                            />
                                            <FormError error={errors?.version}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <h5 className="mb-0 text-muted">Notes</h5>
                            </Card.Header>
                            <Card.Body className="bg-light pb-0">
                                <Form.Group>
                                    <TinymceEditor
                                        value={formData.note}
                                        handleChange={newValue => setFormData({
                                            ...formData,
                                            note: newValue,
                                        })}
                                    />
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer className={"mt-2"}></Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Card className={"mt-3"}>
                    <CardHeader>
                        <Flex justifyContent={"end"} wrap={"wrap"}>
                            <IconButton icon={faSave} onClick={(e) => handleSubmit(e)}>
                                <span className="d-none d-sm-inline-block ms-1">Save</span>
                            </IconButton>
                        </Flex>
                    </CardHeader>
                </Card>
            </Form>
        </Fragment>
    );
};

export default NewTimeline;
