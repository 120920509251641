import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import SoftBadge from '../../../components/common/SoftBadge';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CardDropdown from '../../../components/common/CardDropdown';
import {Card, Dropdown, Spinner} from 'react-bootstrap';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../../components/common/advance-table/AdvanceTableWrapper';
import useQuery from '../../../hooks/useQuery';
import Flex from '../../../components/common/Flex';
import Avatar from '../../../components/common/Avatar';
import {formatDateTime, withPermission} from '../../../helpers/utils';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {api} from '../../../utils/api';
import {debounce} from 'chart.js/helpers';
import {getActivities} from '../actions/Activity';
import {useAppContext} from '../../../providers/AppProvider';
import paths from "../../../routes/paths";
import {usePermissions} from "../../../providers/PermissionsProvider";
import BaseTableHeader from "../../BaseTableHeader";
import {withTranslation} from "react-i18next";

const Activities = ({t, i18n}) => {
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {setConfig} = useAppContext();

    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchActivities = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);

        // Cancel the previous request if it exists
        getActivities(q)
            .then(res => {
                setActivities(res?.results);
                setCount(res?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLength(activities.length);
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchActivities, 600), []);

    useEffect(() => {
        fetchActivities(query);
        setLength(activities.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'name',
            Header: t('fields.name'),
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'name' && sortingDirection,
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const {name, logo, id} = rowData.row.original;
                return (
                    <>
                        <Link to={`/activities/activity/${id}/detail`}>
                            <Flex alignItems="center">
                                {logo ? (
                                    <Avatar
                                        src={logo}
                                        rounded={'circle'}
                                        size="4xl"
                                        className="me-2"
                                    />
                                ) : (
                                    <Avatar
                                        size="4xl"
                                        name={name}
                                        rounded={'circle'}
                                        className="me-2"
                                    />
                                )}
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: '200px',
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {name}
                                    </h6>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            }
        },
        {
            accessor: 'email',
            Header: t('fields.email'),
            canSort: true,
            sortingDirection: sortingField === 'email' && sortingDirection,
            isSorted: false,
            cellProps: {
                className: 'py-2'
            },
            headerProps: {className: 'pe-3'},
            Cell: rowData => {
                const {email} = rowData.row.original;
                return <a href={`mailto:${email}`}>{email}</a>;
            }
        },
        {
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            accessor: 'type',
            Header: t('fields.type.title'),
            canSort: true,
            sortingDirection: sortingField === 'type' && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {type} = rowData.row.original;
                return (
                    <>
                        <p className="mb-0 text-500">{type}</p>
                    </>
                );
            }
        },
        {
            accessor: 'active',
            Header: t('fields.status'),
            canSort: true,
            headerProps: {className: 'pe-1 text-center'},
            cellProps: {
                className: 'py-2 fs-0'
            },
            sortingDirection: sortingField === 'active' && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {active} = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: active,
                            warning: !active
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {active && 'Active'}
                            {!active && 'Inactive'}
                        </p>
                        <FontAwesomeIcon
                            icon={classNames({
                                check: active,
                                ban: !active
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'address',
            Header: t('fields.address'),
            headerProps: {className: 'pe-1 text-center'},
            cellProps: {
                className: 'py-2 fs-0 text-center'
            }
        },
        {
            accessor: 'created_at',
            Header: t('fields.created_at', {ns: "common"}),
            canSort: true,
            sortingDirection: sortingField === 'created_at' && sortingDirection,
            isSorted: false,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => `${formatDateTime(rowData.row.original.created_at)}`
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id, name} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: "common"}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: t('cancel', {ns: "common"}),
                        confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/activity/activity/${id}/`).then(() => {
                            toast.success(`${t('title')} ${name} ${t('deleteSuccess', {ns: "common"})}`, {
                                theme: 'colored'
                            });
                            getActivities(query)
                                .then(res => {
                                    setActivities(res?.results);
                                    setCount(res.data?.count);
                                })
                                .catch(() => {
                                });
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("activity.change_activity") &&
                                <Dropdown.Item
                                    onClick={() => navigate(`/activities/activity/${id}/edit`)}
                                >
                                    {t('edit', {ns: "common"})}
                                </Dropdown.Item>
                            }
                            <Dropdown.Divider as="div"/>
                            {hasPermission("activity.delete_activity") &&
                                <Dropdown.Item
                                    onClick={e => handleDelete(e)}
                                    className="text-danger"
                                >
                                    {t('delete', {ns: "common"})}
                                </Dropdown.Item>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const userList = activities.map(user => ({
        id: user.id,
        name: user.name,
        address: user.address,
        logo: user.logo_url,
        activity_name: user.activity_name,
        email: user.email,
        type: user.type,
        created_at: user.created_at,
        active: user.active
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={userList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"activity.add_activity"}
                        creationPath={paths.activityCreate}
                        title={"activity"}
                        deletePermission={"activity.delete_activity"}
                        exportPermission={"activity.add_activityexportfile"}
                        data={userList}
                        deleteLink={"/activity/delete/activity/"}
                        exportLink={"/activity/export/activity/"}
                        setLoading={setLoading}
                        exportsPath={paths.activityExports}
                        ns={"activity"}
                        fields={["name", "slug", "phone", "email", "address", "delegation__name", "country__country", "city__name", "type.title", "description", "identifier", "rib", "responsible", "active", "created_at"]}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchActivities}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default withPermission(withTranslation(["activity", "common"])(Activities), "activity.view_activity")