import FalconCardHeader from 'components/common/FalconCardHeader';
import React, {useState} from 'react';
import {Button, Card, Form, Spinner} from 'react-bootstrap';
import Flex from '../../../components/common/Flex';
import CSRFToken from '../../../helpers/CSRFToken';
import FormError from '../../errors/FormError';
import {api} from '../../../utils/api';
import {toast} from 'react-toastify';
import {useAppContext} from '../../../providers/AppProvider';

const ChangePassword = ({user, getUser}) => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState({
		old_password: '',
		new_password: '',
		confirm_new_password: ''
	});

	const {setConfig} = useAppContext();

	const handleChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = e => {
		setLoading(true);
		e.preventDefault();
		api
			.put(`/account/change-password/${user?.id}/`, formData)
			.then(res => {
				toast.success(
					'Password successfully updated. You need to re-login to access your account.',
					{theme: 'colored'}
				);
				setConfig('isAuthenticated', false);
			})
			.catch(err => {
				setErrors(err?.response?.data);
				toast.error('An error has occurred.', {theme: 'colored'});
			});
		setLoading(false);
	};

	const handleSetPassword = async () => {
		setLoading(true)
		await api.post("/account/password/set/", formData)
			.then(res => {
				toast.success(res?.data?.detail, {theme: "colored"})
				setConfig('isAuthenticated', false);
			})
			.catch(error => {
				setErrors(error?.response?.data);
				toast.error('An error has occurred.', {theme: 'colored'});
			})
		setLoading(false)
	}

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Card className="mb-3">
			<FalconCardHeader title={user?.has_usable_password ? "Change Password" : "Set a Password"}/>
			<Card.Body className="bg-light">
				{user?.has_usable_password ?
					<Form>
						<CSRFToken/>
						<Form.Group className="mb-3" controlId="oldPassword">
							<Form.Label>Old Password</Form.Label>
							<Form.Control
								type="password"
								value={formData.old_password}
								name="old_password"
								onChange={handleChange}
							/>
							<FormError error={errors?.old_password}/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="newPassword">
							<Form.Label>New Password</Form.Label>
							<Form.Control
								type="password"
								value={formData.new_password}
								name="new_password"
								onChange={handleChange}
							/>
							<FormError error={errors?.new_password}/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="confirmPassword">
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type="password"
								value={formData.confirm_new_password}
								name="confirm_new_password"
								onChange={handleChange}
							/>
							<FormError error={errors?.confirm_new_password}/>
						</Form.Group>
						<Button className="w-100" type="submit" onClick={handleSubmit}>
							Update Password
						</Button>
					</Form>
					:
					<Form>
						<CSRFToken/>
						<Form.Group className="mb-3" controlId="password1">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								value={formData.password1}
								name="password1"
								onChange={handleChange}
							/>
							<FormError error={errors?.password1}/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="password2">
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type="password"
								value={formData.password2}
								name="password2"
								onChange={handleChange}
							/>
							<FormError error={errors?.password2}/>
						</Form.Group>
						<Button className="w-100" type="submit" onClick={handleSetPassword}>
							Set a Password
						</Button>
					</Form>
				}
			</Card.Body>
		</Card>
	);
};

export default ChangePassword;
