import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import paths from "../../../routes/paths";
import {Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";

const Payment = () => {
	const [loading, setLoading] = useState(false);

	const {id} = useParams();
	const navigate = useNavigate();

	const checkPayment = async () => {
		setLoading(true)
		await api.get("/account/setcsrf/").then(res => {
			api.defaults.headers.common['X-CSRFToken'] = res?.data?.csrftoken
		})
		await api.post("/paymee/check/", {id: id})
			.then(res => {
				toast.success("Payment has been processed successfully.", {theme: "colored"})
				navigate(paths.billingDetail.replace(":id", id));
			})
			.catch(err => {
				toast.error("An error has occurred.", {theme: "colored"})
			})
		setLoading(false)
	}

	useEffect(() => {
		checkPayment()
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex justifyContent="center" alignItems={"center"} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : ""
}

export default Payment