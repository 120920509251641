import React from "react";
import VerifyAccountContent from "../VerifyAccountContent";
import bgImg from "../../../assets/img/generic/16.jpg";
import AuthSplitLayout from "../../../layouts/AuthSplitLayout";
import {withTranslation} from "react-i18next";

const VerifyAccount = ({t, i18n}) => (
	<AuthSplitLayout bgProps={{image: bgImg, position: '50% 30%'}}>
		<div className="text-center">
			<VerifyAccountContent email="xyz@abc.com" layout="split" titleTag="h3" t={t} i18n={i18n} />
		</div>
	</AuthSplitLayout>
);

export default withTranslation(["authentication", "common"])(VerifyAccount);