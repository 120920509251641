import React, {useEffect, useState} from "react";
import {ButtonGroup, Card, Col, Dropdown, DropdownButton, Form, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {api} from "../../../utils/api";
import Flex from "../../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ActivityProfileContent from "./contents/ActivityProfileContent";
import PlanManagementContent from "./contents/PlanManagementContent";
import PaymentMethodsContent from "./contents/PaymentMethodsContent";
import MembershipFeesContent from "./contents/MembershipFeesContent";
import QuestionSettings from "./contents/QuestionSettings";
import ContractContent from "./contents/ContractContent";
import BudgetManagementContent from "./contents/BudgetManagementContent";
import {usePermissions} from "../../../providers/PermissionsProvider";
import AffiliateProgramContent from "./contents/AffiliateProgramContent";
import OfficersManagementContent from "./contents/OfficersManagementContent";

const ActivitySettings = () => {
	const [key, setKey] = useState('profile');
	const [activity, setActivity] = useState({});
	const [loading, setLoading] = useState(false);
	const [tabs, setTabs] = useState([
		{
			key: "profile",
			title: <span>Profile</span>,
			content: <ActivityProfileContent />,
			active: true,
			permission: "activity.view_activity"
		},
		{
			key: "plan",
			title: <span>Plan Management</span>,
			content: <PlanManagementContent />,
			active: true,
			permission: "billing.view_billing"
		},
		{
			key: "budget",
			title: <span>Budget Management</span>,
			content: <BudgetManagementContent />,
			active: true,
			permission: "budget.view_activitybudget"
		},
		{
			key: "officers",
			title: <span>Officers Management</span>,
			content: <OfficersManagementContent />,
			active: true,
			permission: "officer.view_officer"
		},
		{
			key: "affiliate",
			title: <span>Affiliate Program</span>,
			content: <AffiliateProgramContent />,
			active: true,
			permission: "affiliate.view_affiliate"
		},
		{
			key: "payments",
			title: <span>Payment Methods</span>,
			content: <PaymentMethodsContent />,
			active: false,
			permission: "payment.view_payment"
		},
		{
			key: "membership",
			title: <span>Membership Fees</span>,
			content: <MembershipFeesContent />,
			active: false,
			permission: "activity.view_activity"
		},
		{
			key: "questions",
			title: <span>Questions Settings</span>,
			content: <QuestionSettings />,
			active: false,
			permission: "activity.view_activity"
		},
		{
			key: "contract",
			title: <span>Your contract</span>,
			content: <ContractContent />,
			active: false,
			permission: "contract.view_contract"
		}
	])

	const {hasPermission} = usePermissions()

	const fetchData = async () => {
		setLoading(true)
		await api.get(`/account/current_user/`).then(async (res) => {
			await api.get(`/activity/activity/${res.data.activity}/`).then(async (res) => {
				setActivity(res?.data)
			})
		})
		setLoading(false)
	}

	useEffect(() => {
		fetchData()
	}, []);

	const handleRemove = tabKey => {
		setTabs((prevTabs) =>
			prevTabs.map((tab) =>
				tab.key === tabKey ? {...tab, active: !tab.active} : tab
			));
	}

	const handleChange = (e, key) => {
		setTabs((prevTabs) =>
			prevTabs.map((tab) =>
				tab.key === key ? {...tab, active: !tab.active} : tab
			)
		);
	}

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Card>
			<Card.Header className={"d-print-none"}>
				<h5>{activity?.name}</h5>
				<h6 className={"text-muted"}>{activity?.type_display} Settings</h6>
			</Card.Header>
			<Row>
				<Col lg={11}>
					<Tabs
						activeKey={key}
						onSelect={(k) => setKey(k)}
						fill
					>
						{tabs?.filter(tab => tab.active).map(tab => hasPermission(tab?.permission) && (
							<Tab eventKey={tab?.key}
							     key={tab.key}
							     title={
								     <Flex justifyContent={"between"} alignItems={"center"}>
									     {tab.title}{" "}
									     {tabs.length > 1 && (
										     <FontAwesomeIcon
											     icon={"times"}
											     onClick={() => handleRemove(tab.key)}
											     style={{cursor: "pointer", marginLeft: "5px"}}
										     />
									     )}
								     </Flex>
							     }
							     className='border p-3'>
								{tab?.content}
							</Tab>
						))}
					</Tabs>
				</Col>
				<Col lg={1}>
					<DropdownButton
						as={ButtonGroup}
						key={"add"}
						title={<FontAwesomeIcon icon={"plus"}/>}
						variant={"outline-primary d-print-none"}
						className='me-2 mb-2 mt-2'
						size={"sm"}
						autoClose="outside"
					>
						{tabs?.map(tab => hasPermission(tab?.permission) && (
							<Dropdown.Item eventKey="1" as={"div"}>
								<Form.Switch
									label={tab?.title}
									checked={tab?.active}
									onChange={(e) => handleChange(e, tab?.key)}
								/>
							</Dropdown.Item>
						))}
					</DropdownButton>
				</Col>
			</Row>
		</Card>
	)
}

export default ActivitySettings