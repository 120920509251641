import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Form, Image, Row, Spinner, Table} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import {Link} from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import FalconLink from 'components/common/FalconLink';
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import {useAppContext} from "../../../providers/AppProvider";
import SoftBadge from "../../../components/common/SoftBadge";
import {formatDateTime, formatNumberCurrency} from "../../../helpers/utils";
import paths from "../../../routes/paths";

const TransactionItem = ({
                             summary: {id, img, title, subtitle, status, amount, date},
                             isLast,
                             currencyData
                         }) => {
    return (
        <tr className={classNames({'border-0': isLast})} key={id}>
            <td
                className={classNames('align-middle ps-0 text-nowrap', {
                    'border-0': isLast
                })}
            >
                <Flex alignItems="center" className="position-relative">
                    <Image src={img} alt={title} className="me-2" width={30}/>
                    <div className="flex-1">
                        <Link to={paths.transactionDetail.replace(":id", id)} className="stretched-link">
                            <h6 className="mb-0">{title}</h6>
                        </Link>
                        <p className="mb-0">{subtitle}</p>
                    </div>
                </Flex>
            </td>
            <td
                className={classNames('align-middle px-4', {'border-0': isLast})}
                style={{width: '1%'}}
            >
                <SoftBadge
                    bg={classNames({
                        success: status === 'Approved',
                        warning: status === 'Pending',
                        danger: status === 'Rejected'
                    })}
                    className="fs-10 w-100"
                >
                    {status}
                </SoftBadge>
            </td>
            <td
                className={classNames('align-middle px-4 text-end text-nowrap', {
                    'border-0': isLast
                })}
                style={{width: '1%'}}
            >
                <h6 className="mb-0">{formatNumberCurrency(amount, currencyData)}</h6>
                <p className="fs-11 mb-0">{formatDateTime(date)}</p>
            </td>
        </tr>
    );
};

const TransactionSummary = ({data: transactions}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [range, setRange] = useState("last_7_days")
    const [currencyData, setCurrencyData] = useState({})

    let query = useQuery()
    const {
        config: {currency}
    } = useAppContext()

    const getCurrencyData = async () => {
        setLoading(true)
        await api.get(`/currency/${currency}/`).then(res => setCurrencyData(res?.data))
        setLoading(false)
    }

    const getData = async () => {
        setLoading(true)
        query.set("time_range", range)
        await api.get(`/finance/transactionsummary/?${query.toString()}`).then(res => setData(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        getCurrencyData()
        // eslint-disable-next-line
    }, [currency]);

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [currency, range]);

    return (
        <Card className="overflow-hidden">
            <FalconCardHeader
                title="Transaction Summary"
                titleTag="h6"
                className="py-2"
                light
                endEl={<div className={"my-2"}></div>}
            />
            <Card.Body className="py-0">
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <SimpleBarReact>
                        <Table className="table-dashboard mb-0 fs-10">
                            <tbody>
                            {data?.map((item, index) => (
                                <TransactionItem
                                    currencyData={currencyData}
                                    key={item.id}
                                    index={index}
                                    isLast={index === data?.length - 1}
                                    summary={item}
                                />
                            ))}
                            </tbody>
                        </Table>
                    </SimpleBarReact>
                )}
            </Card.Body>
            <Card.Footer className="bg-body-tertiary py-2">
                <Row className="flex-between-center">
                    <Col xs="auto">
                        <Form.Select size="sm" value={range} onChange={e => setRange(e.target.value)}>
                            <option value="last_7_days">Last 7 days</option>
                            <option value="last_month">Last Month</option>
                            <option value="last_year">Last Year</option>
                        </Form.Select>
                    </Col>
                    <Col xs="auto">
                        <FalconLink title="View All" to={paths.transactions} className="px-0 fw-medium"/>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

TransactionItem.propTypes = {
    summary: PropTypes.shape({
        img: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        date: PropTypes.string.isRequired
    }),
    isLast: PropTypes.bool.isRequired
};

TransactionSummary.propTypes = {
    data: PropTypes.arrayOf(TransactionItem.propTypes.summary)
};

export default TransactionSummary;
