/* eslint-disable react/prop-types */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useState} from 'react';
import {Button, FormControl, InputGroup} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import useQuery from "../../../hooks/useQuery";

const AdvanceTableSearchBox = ({
                                   placeholder = 'Search...',
                                   className,
                                   fetch
                               }) => {
    let query = useQuery()
    const [value, setValue] = useState(query.get("search") || "");

    const navigate = useNavigate();


    const onChange = (value) => {
        query.set("search", value)
        fetch(query)
        navigate(`?${query.toString()}`)
    }

    return (
        <InputGroup className={classNames(className, 'position-relative')}>
            <FormControl
                value={value || ''}
                onChange={({target: {value}}) => {
                    setValue(value);
                    onChange(value);
                }}
                size="sm"
                id="search"
                placeholder={placeholder}
                type="search"
                className="shadow-none"
            />
            <Button
                size="sm"
                variant="outline-secondary"
                className="border-300 hover-border-secondary"
                onClick={() => {
                    query.set("search", value)
                    fetch(query)
                    navigate(`?${query.toString()}`)
                }}
            >
                <FontAwesomeIcon icon="search" className="fs--1"/>
            </Button>
        </InputGroup>
    );
};

export default AdvanceTableSearchBox;