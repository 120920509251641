import React, { useEffect, useState } from 'react';
import ProfileBanner from './Banner';
import ProfileIntro from './ProfileIntro';
import { Col, Row, Spinner } from 'react-bootstrap';
import Associations from 'components/pages/asscociations/Associations';
import Followers from './Followers';
import Experiences from './Experiences';
import Education from './Education';
import Events from './Events';
import Photos from './Photos';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../actions/Users';
import Flex from '../../../components/common/Flex';
import { useAppContext } from '../../../providers/AppProvider';
import paths from "../../../routes/paths";
import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";

const Profile = ({t, i18n}) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [settings, setSettings] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    config: { isAuthenticated },
    setConfig
  } = useAppContext();

  const fetchUser = async () => {
    setLoading(true);
    getUser(id)
      .then(res => {
        setUser(res);
        setSettings(res?.settings);
      })
      .catch((error) => {
        if (error?.response?.status === 404) navigate(paths.error404);
        if (error?.response?.status === 500) navigate(paths.error500);
        if (error?.response?.status === 403)
          setConfig("isAuthenticated", !isAuthenticated);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
    }, []);

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={'border'} variant={'primary'} />
    </Flex>
  ) : (
    <>
      <ProfileBanner user={user} fetchUser={fetchUser} settings={settings} setLoading={setLoading} t={t} />
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <ProfileIntro intro={user?.introduction} t={t}/>
          <Associations
            associations={user?.member_in?.slice(0, 4)}
            colBreakpoints={{ sm: 6 }}
            t={t}
          />
          <Photos photos={user?.photos_set} t={t} />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            {settings?.show_experience && (
              <Experiences experiences={user?.experience_set} t={t} />
            )}
            <Education educationData={user?.education_set} t={t} />
            <Events
              className="mb-3"
              cardTitle={t('titles.event', {ns: "common"})}
              events={user?.event_register?.slice(0, 3)}
              t={t}
            />
          </div>
        </Col>
      </Row>
      {settings?.show_followers && (
        <Followers
          totalFollowers={user?.followed_by?.length}
          followers={user?.followed_by?.slice(0, 12)}
          id={user?.id}
          t={t}
        />
      )}
    </>
  );
};

export default withPermission(withTranslation(["users", "common"])(Profile), "accounts.view_user");
