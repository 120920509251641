import {withPermission} from "../../../../helpers/utils";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../../hooks/useQuery";
import {useParams} from "react-router-dom";
import {api} from "../../../../utils/api";
import {debounce} from "lodash";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import AdvanceTableSearchBox from "../../../../components/common/advance-table/AdvanceTableSearchBox";
import Flex from "../../../../components/common/Flex";
import AdvanceTablePagination from "../../../../components/common/advance-table/AdvanceTablePagination";
import Member from "../DetailActivity/Member";

const ActivityMembers = () => {
	const [members, setMembers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [count, setCount] = useState(0);

	let query = useQuery();
	const {id} = useParams();

	const getMembers = async (q) => {
		setLoading(true)
		await api.get(`/member/member/?${q.toString()}`).then(res => {
			setMembers(res?.data?.results);
			setCount(res?.data?.count);
		});
		setLoading(false)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(getMembers, 600), []);

	useEffect(() => {
		query.set("activity", id)
		getMembers(query);
		// eslint-disable-next-line
	}, []);

	return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="align-items-center g-1">
          <Col className="pe-0">
            <h5 className="mb-0">Members ({count}) </h5>
          </Col>
          <Col>
            <Row className="g-2">
              <Col>
                <AdvanceTableSearchBox fetch={delayedLoadItems} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
        {loading ? (
          <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'} />
          </Flex>
        ) : (
          <Row className="gx-0 gy-1 text-center">
            {members?.map(member => (
              <Col key={member?.id} xs={6} md={4} lg={3} xxl={2}>
                <Member member={member} />
              </Col>
            ))}
          </Row>
        )}
      </Card.Body>
      <Card.Footer>
        <AdvanceTablePagination
          count={count}
          fetch={getMembers}
          query={query}
          length={members?.length}
          itemsPerPage={20}
        />
      </Card.Footer>
    </Card>
  );
}

export default withPermission(ActivityMembers, "member.view_member")