import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import TopicPopularityChart from './TopicPopularityChart';
import useQuery from "../../../../hooks/useQuery";
import {api} from "../../../../utils/api";
import Flex from "../../../../components/common/Flex";
import {useAppContext} from "../../../../providers/AppProvider";

function TopicPopularity() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [duration, setDuration] = useState("last_7_days")

	const {
		setConfig
	} = useAppContext()

	let query = useQuery()

	const getData = async () => {
		setLoading(true)
		query.set("time_range", duration)
		await api.get(`/analytics/topicpopularity/?${query.toString()}`).then(res => setData(res?.data))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		setLoading(false)
	}

	useEffect(() => {
		getData()
		// eslint-disable-next-line
	}, [duration]);

	return (
		<Card className="h-100">
			<FalconCardHeader
				title="Topic Popularity"
				titleTag="h6"
				className="py-2"
				light
			/>
			<Card.Body className="pb-0">
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<TopicPopularityChart data={data} duration={duration}/>
				)}
			</Card.Body>

			<Card.Footer className="bg-light py-2">
				<Row className="g-0 flex-between-center">
					<Col xs="auto">
						<Form.Select size="sm" value={duration} onChange={(e) => setDuration(e.target.value)}>
							<option value={"last_7_days"}>Last 7 days</option>
							<option value={"last_month"}>Last Month</option>
							<option value={"last_year"}>Last Year</option>
						</Form.Select>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	);
}

export default TopicPopularity;
