import { useEffect } from 'react';
import useQuery from '../../../hooks/useQuery';
import { api } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../providers/AppProvider';
import paths from "../../../routes/paths";

export const Callback = () => {
  const navigate = useNavigate();
  const { setConfig } = useAppContext();
  let query = useQuery();

  const data = {
    code: query.get('code')
  };

  const calendlyAuth = async () => {
    await api.get('/account/setcsrf/').then(res => {
      api.defaults.headers.common['X-CSRFToken'] = res?.data?.csrftoken;
    });
    await api
      .post(`/dj-rest-auth/calendly/connect/`, data)
      .then(async res => {
        setConfig('isAuthenticated', true);
        await api.get('/dj-rest-auth/user/').then(r => {
          setConfig('group', r?.data?.groups_name);
          setConfig('point', r?.data?.user_points);
          setConfig('avatar', r?.data?.url_path);
        });
        navigate(paths.userConnections);
        toast.success('Successfully connected with calendly');
      })
      .catch(() => {});
    // .finally(() => window.close())
  };

  useEffect(() => {
    if (query.get('code')) calendlyAuth();
    // eslint-disable-next-line
    }, [query]);
};
