import Section from "../../components/common/Section";
import SectionHeader from "./SectionHeader";
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {api} from "../../utils/api";
import Event from "../events/Event";
import className from "classnames";
import paths from "../../routes/paths";
import {Link} from "react-router-dom";
import useQuery from "../../hooks/useQuery";

const Events = ({t, i18n}) => {
	const [events, setEvents] = useState([])
	let query = useQuery()

	const getEvents = async () => {
		query.set("public", true)
		await api.get(`/event/event/?${query.toString()}`).then(res => setEvents(res?.data.results))
	}

	useEffect(() => {
		getEvents()
		// eslint-disable-next-line
	}, []);

	return (
		<Section className="py-0 overflow-hidden light" position="center" overlay fluid>
			<SectionHeader title={t("events.title")} subtitle={''}/>
			<Row className="mt-6 mb-6">
				{events?.slice(0, 4).map((event, index) => (
					<Col lg={3}
					     className={className({'mt-6 mt-lg-0': index > 0})}
					     key={index}
					>
						<Event details={event} isLast={event.length === 3} link={paths.eventDetailLanding.replace(":id", event?.id)} />
					</Col>
				))}
			</Row>
			<Row className={"justify-content-center text-center mb-6"}>
				<Link
					to={paths.eventsLanding}
					size="sm"
					variant="outline-primary"
				>
					{t('show', {ns: "common"})} {t('more', {ns: "common"})}
				</Link>
			</Row>
		</Section>
	)
}

export default Events