import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import {Card, Ratio, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import ReactYoutubePlayer from "react-player/youtube";

const TutorialDetail = ({t, i18n}) => {
    const [tutorial, setTutorial] = useState({});
    const [loading, setLoading] = useState(false);

    const {id} = useParams();

    const fetchTutorialDetails = async () => {
        setLoading(true);
        await api.get(`/tutorial/${id}/`).then(res => setTutorial(res.data))
        setLoading(false)
    }

    useEffect(() => {
        fetchTutorialDetails()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header>
                    {tutorial?.link &&
                        <ReactYoutubePlayer
                            url={tutorial?.link}
                            controls={true}
                            className="react-player"
                        />
                    }
                    {tutorial?.file &&
                        <Ratio aspectRatio="16x9">
                            <iframe
                                src={tutorial?.file}
                                allowFullScreen={true}
                                title={tutorial.name}
                            />
                        </Ratio>
                    }
                </Card.Header>
                <Card.Body>
                    <h5>{tutorial.name}</h5>
                    <div dangerouslySetInnerHTML={{__html: tutorial?.description}}></div>
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default withTranslation(["tutorials", "common"])(TutorialDetail)
