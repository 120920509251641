import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';
import {Link} from "react-router-dom";
import paths from "../../routes/paths";
import {withTranslation} from "react-i18next";

const Footer = ({ t, i18n }) => (
  <footer className="footer d-print-none">
    <Row className="justify-content-between text-center fs-10 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          {t('main.thankYou')} Nexameet{' '}
          <br className="d-sm-none" /> &copy;{' '} {new Date().getFullYear()}
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">
          <Link to={paths.privacyPolicy}>{t('main.privacyPolicy')}</Link> - <Link to={paths.termsConditions}>{t('main.termsConditions')}</Link> - v{version}
        </p>
      </Col>
    </Row>
  </footer>
);

export default withTranslation("footer")(Footer);
