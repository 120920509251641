import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import {messages} from 'data/support-desk/ticketsPreviewData';
import React from 'react';
import {
    Card,
    Col,
    OverlayTrigger,
    Row,
    Tooltip
} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useState} from 'react';
import Compose from 'components/app/email/compose/Compose';
import paths from 'routes/paths';
import {formatDateTime} from "../../../helpers/utils";

const File = ({icon, title, action, actionIcon, className, file}) => {
    return (
        <div
            className={classNames(
                'border p-2 rounded-3 d-flex bg-white dark__bg-1000 fs-10',
                className
            )}
        >
            <FontAwesomeIcon icon={icon} className="fs-1"/>
            <span className="ms-2 me-3">{title}</span>
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip style={{position: 'fixed'}}>{action}</Tooltip>}
            >
                <a href={file} download target={"_blank"} rel={"noreferrer"} className="text-300 ms-auto">
                    <FontAwesomeIcon icon={actionIcon}/>
                </a>
            </OverlayTrigger>
        </div>
    );
};

File.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    title: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    actionIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        .isRequired,
    className: PropTypes.string
};

const Mail = ({message, index}) => {
    return (
        <div>
            {/* mail header */}
            <div className="d-md-flex d-xl-inline-block d-xxl-flex align-items-center justify-content-between mb-x1">
                <Flex alignItems="center" className="gap-2">
                    <Link to={paths.usersDetail.replace(":id", message?.user)}>
                        <Avatar src={message?.user_data?.url_path} size="2xl"/>
                    </Link>
                    <p className="mb-0">
                        <Link
                            to={paths.usersDetail.replace(":id", message?.user)}
                            className="fw-semibold mb-0 text-800"
                        >
                            {message?.user_data?.full_name}
                        </Link>
                        <a
                            className="mb-0 fs-10 d-block text-500"
                            href={`mailto:${message?.user_data?.email}`}
                        >
                            {message?.user_data?.email}
                        </a>
                    </p>
                </Flex>
                <p className="mb-0 fs-11 fs-sm-10 fw-semibold mt-2 mt-md-0 mt-xl-2 mt-xxl-0 ms-5">
                    {formatDateTime(message?.created_at)}
                    <FontAwesomeIcon icon="star" className="ms-2 text-warning"/>
                </p>
            </div>
            {/* mail body */}
            <div
                className={
                    index !== 0 && index !== messages.length - 1
                        ? 'mb-5 pb-5 border-bottom'
                        : ''
                }
            >
                <h6 className="mb-3 fw-semibold text-1000">{message.subject}</h6>
                <p dangerouslySetInnerHTML={{__html: message?.text}}></p>
                {message?.attachment_set?.length > 0 && (
                    <div className="p-x1 bg-body-tertiary rounded-3 mt-3">
                        <div className="d-inline-flex flex-column">
                            {message?.attachment_set?.map(file => (
                                <File
                                    action={"Preview"}
                                    title={file?.data?.name}
                                    actionIcon="eye"
                                    className="mb-2"
                                    icon={file?.data?.type?.includes("image") ? ['far', 'image'] : "file-archive"}
                                    file={file?.file_url}
                                />
                            ))}
                        </div>
                        <hr className="my-x1"/>
                        <Row className="flex-between-center gx-4 gy-2">
                            <Col xs="auto">
                                <p className="fs-10 text-1000 mb-sm-0 font-sans-serif fw-medium mb-0">
                                    <FontAwesomeIcon icon="link" className="me-2"/>{message?.attachment_set?.length} files
                                    attached
                                </p>
                            </Col>
                            {/*<Col xs="auto">*/}
                            {/*    <IconButton*/}
                            {/*        icon="file-download"*/}
                            {/*        variant="falcon-default"*/}
                            {/*        size="sm"*/}
                            {/*    >*/}
                            {/*        <span className="ms-1">Download all</span>*/}
                            {/*    </IconButton>*/}
                            {/*</Col>*/}
                        </Row>
                    </div>
                )}
            </div>
        </div>
    );
};

Mail.propTypes = {
    message: PropTypes.shape({
        img: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
        subject: PropTypes.string.isRequired,
        salutation: PropTypes.string.isRequired,
        conclusion: PropTypes.string.isRequired,
        sender: PropTypes.string.isRequired,
        closing: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        attached: PropTypes.bool.isRequired
    }),
    index: PropTypes.number
};

const Conversation = ({data, fetch, setLoading}) => {
    const [showForm, setShowForm] = useState(false);
    return (
        <Card className="mt-3">
            <Card.Header className="bg-body-tertiary">
                <h5 className="mb-0">
                    <FontAwesomeIcon icon="envelope" className="me-2"/>
                    {data?.subject}
                </h5>
            </Card.Header>
            <Card.Body>
                {data?.messages?.map((message, index) => (
                    <Mail key={index} message={message} index={index}/>
                ))}
            </Card.Body>
            {showForm && (
                <Compose
                    setShowForm={setShowForm}
                    title="Reply"
                    bodyClassName="shadow-none"
                    setLoading={setLoading}
                    fetch={fetch}
                />
            )}
            {!showForm && (
                <Card.Footer className="bg-body-tertiary">
                    <IconButton
                        size="sm"
                        variant="falcon-default"
                        icon="reply"
                        onClick={() => setShowForm(true)}
                    >
                        <span className="d-none d-sm-inline-block ms-1">Reply</span>
                    </IconButton>
                </Card.Footer>
            )}
        </Card>
    );
};

export default Conversation;
