import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const Affiliate = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="affiliate" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Corporate Affiliate</h5>
			</Card.Header>
			<Card.Body>
				<h6>Sale of Business</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer
					of
					all or substantially all of the assets of nexameet or any of its Corporate Affiliates (as defined herein), or
					that
					portion of nexameet or any of its Corporate Affiliates to which the Service relates, or in the event that we
					discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization
					or
					similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.
				</p>
				<hr className="my-4"/>
				<h6>Affiliates</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We may disclose information (including personal information) about you to our Corporate Affiliates. For
					purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or indirectly
					controls, is controlled by or is under common control with nexameet, whether by ownership or otherwise. Any
					information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate
					Affiliates in accordance with the terms of this Privacy Policy.
				</p>
			</Card.Body>
		</Card>
	)
})

export default Affiliate