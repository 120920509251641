import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CardDropdown from '../../../components/common/CardDropdown';
import {Card, Dropdown, Spinner} from 'react-bootstrap';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../../components/common/advance-table/AdvanceTableWrapper';
import useQuery from '../../../hooks/useQuery';
import Flex from '../../../components/common/Flex';
import Avatar from '../../../components/common/Avatar';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {api} from '../../../utils/api';
import {debounce} from 'chart.js/helpers';
import {getOrganizers} from '../actions/Organizer';
import {withPermission} from '../../../helpers/utils';
import {useAppContext} from '../../../providers/AppProvider';
import paths from "../../../routes/paths";
import {usePermissions} from "../../../providers/PermissionsProvider";
import BaseTableHeader from "../../BaseTableHeader";

const Organizers = () => {
	const [organizers, setOrganizers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

	const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();

	const {hasPermission} = usePermissions()

	const navigate = useNavigate();

	const fetchOrganizers = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);

		// Cancel the previous request if it exists
		getOrganizers(q)
			.then(res => {
				setOrganizers(res?.results);
				setCount(res.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLength(organizers.length);
		setLoading(false);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchOrganizers, 600), []);

	useEffect(() => {
		fetchOrganizers(query);
		setLength(organizers.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

	const columns = [
		{
			accessor: 'member',
			Header: 'Member',
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'member' && sortingDirection,
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const {user_fullname, avatar, member} = rowData.row.original;
				return (
					<>
						<Link to={`/members/member/${member}/edit`}>
							<Flex alignItems="center">
								{avatar ? (
									<Avatar
										src={avatar}
										rounded={'circle'}
										size="4xl"
										className="me-2"
									/>
								) : (
									<Avatar
										size="4xl"
										name={user_fullname}
										rounded={'circle'}
										className="me-2"
									/>
								)}
								<div className="flex-1">
									<h6
										className="mb-0 fs-0"
										style={{
											width: '200px',
											wordWrap: 'break-word',
											wordBreak: 'break-all',
											whiteSpace: 'pre-wrap'
										}}
									>
										{user_fullname}
									</h6>
								</div>
							</Flex>
						</Link>
					</>
				);
			}
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'event',
			Header: 'Event',
			canSort: true,
			sortingDirection: sortingField === 'event' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {event_title, event} = rowData.row.original;
				return (
					<>
						<Link to={`/events/${event}/detail`}>
							<Flex alignItems="center">
								<div className="flex-1">
									<h6
										className="mb-0 fs-0"
										style={{
											width: '200px',
											wordWrap: 'break-word',
											wordBreak: 'break-all',
											whiteSpace: 'pre-wrap'
										}}
									>
										{event_title}
									</h6>
								</div>
							</Flex>
						</Link>
					</>
				);
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {id, user_fullname} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: 'Are you sure?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, delete it!'
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/organizer/organizer/${id}/`).then(() => {
							toast.success(
								`Organizer ${user_fullname} successfully deleted.`,
								{
									theme: 'colored'
								}
							);
							getOrganizers(query)
								.then(res => {
									setOrganizers(res?.results);
									setCount(res.data?.count);
								})
								.catch(() => {
								});
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("organizer.view_organizer") &&
								<Dropdown.Item onClick={() => navigate(paths.detailOrganizers.replace(":id", id))}>
									Detail
								</Dropdown.Item>
							}
							{hasPermission("organizer.change_organizer") &&
								<Dropdown.Item onClick={() => navigate(paths.editOrganizers.replace(":id", id))}>
									Edit
								</Dropdown.Item>
							}
							<Dropdown.Divider as="div"/>
							{hasPermission("organizer.delete_organizer") &&
								<Dropdown.Item
									onClick={e => handleDelete(e)}
									className="text-danger"
								>
									Delete
								</Dropdown.Item>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	];

	const organizerList = organizers.map(organizer => ({
		id: organizer.id,
		user_fullname: organizer.user_fullname,
		activity_logo: organizer.activity_logo,
		activity_name: organizer.activity_name,
		avatar: organizer.avatar,
		event_title: organizer.event_title,
		member: organizer.member,
		organization: organizer.organization,
		event: organizer.event
	}));

	const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={organizerList}
			selection
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"organizer.add_organizer"}
						creationPath={paths.createOrganizers}
						title={"organizer"}
						deletePermission={"organizer.delete_organizer"}
						exportPermission={"organizer.add_organizerexportfile"}
						data={organizerList}
						setLoading={setLoading}
						deleteLink={"/organizer/delete/organizer/"}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchOrganizers}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
};

export default withPermission(Organizers, "organizer.view_organizer")