import React, {useState} from 'react';
import eventBanner from 'assets/img/generic/13.jpg';
import {Card, Row, Col, Button} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import {Link, useNavigate} from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import {formatMonth} from '../../../helpers/utils';
import paths from "../../../routes/paths";
import EventRegistrationModal from "./EventRegistrationModal";
import EventShareModal from "./EventShareModal";
import {usePermissions} from "../../../providers/PermissionsProvider";
import Swal from "sweetalert2";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import EventTicketModal from "./EventTicketModal";
import {useAppContext} from "../../../providers/AppProvider";

const EventDetailHeader = ({event, getEvent, setLoading}) => {
	const [registrationModalOpen, setRegistrationModalOpen] = useState(false)
	const [ticketModalOpen, setTicketModalOpen] = useState(false)
	const [shareModalOpen, setShareModalOpen] = useState(false)
	const {hasPermission} = usePermissions()

	const navigate = useNavigate();
	const {
		config: {isAuthenticated},
	} = useAppContext()

	const eventInfo = {
		title: event.title,
		eventDate: {
			day: new Date(event.start_date).getDate(),
			month: formatMonth(event.start_date)
		},
		author: event.activity_name,
		regFee: event?.ticket_options?.option_set?.map(option => option.price + ' ')
	};

	const handleDelete = async e => {
		const {isConfirmed} = await Swal.fire({
			title: 'Are you sure?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		});
		if (isConfirmed) {
			e.preventDefault();
			setLoading(true);
			await api.delete(`/event/event/${event?.id}/`).then(() => {
				toast.success(`Event ${event?.title} successfully deleted.`, {
					theme: 'colored'
				});
				navigate(paths.events)
			});
			setLoading(false);
		}
	}

	return (
		<Card className="p-0 mb-3">
			{!!eventBanner && (
				<img
					className="card-img-top"
					src={event?.cover_url ? event?.cover_url : eventBanner}
					alt=""
				/>
			)}
			<Card.Body className="overflow-hidden">
				<Row className="flex-center">
					<Col>
						<Flex>
							<Calendar {...eventInfo.eventDate} />
							<div className="fs--1 ms-2 flex-1">
								<h5 className="fs-0 text-capitalize">{eventInfo.title}</h5>
								<p className="mb-0 text-capitalize">
									by
									<Link className=" ms-1 " to={paths.activityDetail.replace(":id", event?.activity)}>
										{eventInfo.author}
									</Link>
								</p>
								<span className="fs-0 text-warning fw-semi-bold">
                  {eventInfo.regFee}
                </span>
							</div>
						</Flex>
					</Col>
					<Col md="auto" className="mt-4 mt-md-0">
						<Flex>
							{hasPermission('event.delete_event') && (
								<IconButton
									className="me-2"
									variant="danger"
									size="sm"
									icon="trash"
									iconClassName="text-white"
									onClick={(e) => handleDelete(e)}
								>
									Delete
								</IconButton>
							)}
							{hasPermission('event.change_event') && (
								<IconButton
									className="me-2"
									variant="falcon-default"
									size="sm"
									icon="edit"
									iconClassName="text-primary"
									onClick={() => navigate(`/events/${event.id}/edit`)}
								>
									Edit
								</IconButton>
							)}
							{event?.is_registered ?
								<Button
									variant="falcon-primary"
									size="sm"
									className="px-4 px-sm-5"
									onClick={() => setTicketModalOpen(!ticketModalOpen)}
								>
									Registered
								</Button> :
								<Button
									variant="falcon-primary"
									size="sm"
									className="px-4 px-sm-5"
									onClick={() => !isAuthenticated ? navigate(paths.splitLogin + "?next=" + paths.eventDetail.replace(":id", event?.id)) : setRegistrationModalOpen(!registrationModalOpen)}
								>
									Register
								</Button>
							}
						</Flex>
					</Col>
				</Row>
			</Card.Body>
			<EventShareModal event={event} setOpen={setShareModalOpen} open={shareModalOpen} getEvent={getEvent}/>
			{event?.is_registered ? (
				<EventTicketModal open={ticketModalOpen} setOpen={setTicketModalOpen} ticket={event?.activity_data?.ticket}/>
			) : (
				<EventRegistrationModal
					open={registrationModalOpen}
					setOpen={setRegistrationModalOpen}
					ticket={event?.ticket_options}
					registration={event?.registration_data}
					getEvent={getEvent}
				/>
			)}
		</Card>
	);
};

export default EventDetailHeader;
