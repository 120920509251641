import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, Table} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {convertPrice, formatNumberCurrency} from 'helpers/utils';
import paths from 'routes/paths';
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";

const OrderSummary = ({membership, promo, payableTotal, currencyData, ...rest}) => {
    const [taxes, setTaxes] = useState([]);
    const {
        config: {currency}
    } = useAppContext()
    const query = useQuery()
    const [price, setPrice] = useState(0)

    useEffect(() => {
        const fetchTaxes = async () => {
            const taxPromises = membership?.taxes_data?.map(async tax => {
                const convertedPrice = tax?.is_flat ? await convertPrice(tax?.value, tax?.currency, currency) : 0;
                return {...tax, convertedPrice};
            });
            const resolvedTaxes = await Promise.all(taxPromises);
            setTaxes(resolvedTaxes);
        };

        if (membership?.taxes_data) {
            fetchTaxes();
        }
    }, [membership, currency]);

    useEffect(() => {
        const fetchInfo = async () => {
            const convertedPrice = await convertPrice(query.get("plan") === "month" ?
                membership.monthly_price : membership?.yearly_price, membership?.currency, currency)
            setPrice(formatNumberCurrency(convertedPrice, currencyData))
        };

        fetchInfo();
        // eslint-disable-next-line
    }, [membership, currencyData]);

    return (
        <Card {...rest}>
            <Card.Header className="bg-body-tertiary btn-reveal-trigger d-flex flex-between-center">
                <h5 className="mb-0">Order Summary</h5>
                <Link
                    to={paths.pricingList}
                    className="btn btn-link btn-sm btn-reveal text-600"
                >
                    <FontAwesomeIcon icon="pencil-alt"/>
                </Link>
            </Card.Header>
            <Card.Body>
                <Table borderless className="fs-10 mb-0">
                    <tbody>
                    <tr key={membership.id} className="border-bottom">
                        <th
                            className={classNames('ps-0', {})}
                        >
                            {membership.name} x {query.get("plan")}
                            <div className="text-400 fw-normal fs-11">
                                {membership?.feature_set?.map(feature => feature.name).join(', ')}
                            </div>
                        </th>
                        <th
                            className={classNames('pe-0 text-end', {})}
                        >
                            {price}
                        </th>
                    </tr>
                    {taxes &&
                        taxes?.map(tax => (
                            <tr key={tax.id}>
                                <th className="ps-0 pb-0">{tax.name}</th>
                                <th className="pe-0 text-end pb-0">
                                    {tax.is_flat ? formatNumberCurrency(tax.convertedPrice, currencyData) : `${tax.percentage}%`}
                                </th>
                            </tr>
                        ))
                    }
                    {promo && (
                        <tr className="border-bottom">
                            <th className="ps-0 pb-0">
                                Coupon:
                            </th>
                            <th className="pe-0 text-end">
                                -{promo}%
                            </th>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between bg-body-tertiary">
                <div className="fw-semibold">Payable Total</div>
                <div className="fw-bold">{formatNumberCurrency(payableTotal, currencyData)}</div>
            </Card.Footer>
        </Card>
    )
        ;
};

export default OrderSummary;
