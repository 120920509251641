import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const License = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="license" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">License</h5>
			</Card.Header>
			<Card.Body>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Nexameet grants you a revocable, non-exclusive, non-transferable, limited license to download, install and
					use the website strictly in accordance with the terms of this Agreement.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					These Terms & Conditions are a contract between you and nexameet (referred to in these Terms &
					Conditions as "nexameet", "us", "we" or "our"), the provider of the nexameet website and the services
					accessible from the nexameet website (which are collectively referred to in these Terms & Conditions as the
					"Nexameet Service").
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms &
					Conditions, please do not use the nexameet Service. In these Terms & Conditions, "you" refers both to you
					as an individual and to the entity you represent. If you violate any of these Terms & Conditions, we reserve
					the right to cancel your account or block access to your account without notice.
				</p>
			</Card.Body>
		</Card>
	)
})

export default License