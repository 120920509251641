import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import paymee from 'assets/img/logos/paymee-logo.png';
import konnect from 'assets/img/logos/logo_konnect.svg';
import shield from 'assets/img/icons/shield.png';
import PropTypes from 'prop-types';
import {
	Button,
	Card,
	Col,
	Form,
	Row, Spinner
} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import useQuery from "../../../hooks/useQuery";
import Flex from "../../../components/common/Flex";
import {toast} from "react-toastify";
import CSRFToken from "../../../helpers/CSRFToken";
import {useAppContext} from "../../../providers/AppProvider";
import {formatNumberCurrency} from "../../../helpers/utils";

const PaymentMethod = ({payableTotal, membership, currencyData}) => {
	const [loading, setLoading] = useState(false)
	const [paymentMethods, setPaymentMethods] = useState([])
	const [method, setMethod] = useState('konnect');
	const query = useQuery()
	const {
        config: {currency}
    } = useAppContext()
	const [formData, setFormData] = useState({
		method: "",
		membership: membership?.id,
		status: "pending",
		plan: query.get("plan") === "month" ? "monthly" : "annual",
		description: `Payment of ${membership?.name} with plan ${query.get("plan")}`,
		full_refund_if_not_received: false,
		full_or_partial_refund_if_not_as_described: false,
		provider: "",
		currency: currency,
		errors: [],
		path: "billing"
	})

	const navigate = useNavigate()

	const getPaymentMethods = async () => {
		await api.get("/payment/").then(res => {
			setPaymentMethods(res?.data?.results)
			setFormData({...formData, method: res?.data?.results[0]?.id})
		})
	}

	const handleCheckboxChange = e => {
		setFormData({...formData, [e.target.name]: e.target.checked})
	}

	const handleSubmit = async e => {
		e.preventDefault()
		setLoading(true)
		if (method === "card") {
			await api.post("/checkout/", {...formData, type: method, currency: currency, amount: payableTotal})
				.then(res => {
					toast.success("Payment successfully processed!", {theme: "colored"})
					navigate(paths.billingDetail.replace(":id", res.data.id))
				})
				.catch(err => {
					toast.error("An error has occurred.", {theme: "colored"})
				})
		} else if (method === "paymee") {
			await api.post("/paymee/paymee/", {...formData, type: "provider", provider: 3, currency: currency, amount: payableTotal})
				.then(res => {
					toast.success("Payment successfully processed!", {theme: "colored"})
					window.open(res?.data?.payment_url)
				})
				.catch(err => {
					toast.error("An error has occurred.", {theme: "colored"})
				})
		}
		else if (method === "konnect"){
			await api.post("/konnect/konnect/", {...formData, type: "provider", provider: 1, currency: currency, amount: payableTotal})
				.then(res => {
					toast.success("Payment successfully processed!", {theme: "colored"})
					window.open(res?.data?.pay_url)
				})
				.catch(err => {
					toast.error("An error has occurred.", {theme: "colored"})
				})
		}
		setLoading(false)
	};

	useEffect(() => {
		getPaymentMethods()
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex justifyContent="center" alignItems={"center"} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Card>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0">Payment Method</h5>
			</Card.Header>
			<Card.Body>
				<CSRFToken/>
				{/*<Form.Check type="radio" id="card" className="mb-0 form-check">*/}
				{/*	<Form.Check.Input*/}
				{/*		type="radio"*/}
				{/*		onChange={e => setMethod(e.target.id)}*/}
				{/*		name="payment-method"*/}
				{/*		defaultChecked*/}
				{/*	/>*/}
				{/*	<Form.Check.Label className="mb-2 fs-2">Credit Card</Form.Check.Label>*/}
				{/*</Form.Check>*/}
				{method === "card" &&
					<Form>
						<Row className="gx-0 ps-2 mb-4">
							{paymentMethods?.map((item, index) => (
								<Col key={item.id} md={6} className="mb-3 mb-md-0">
									<Form.Check
										type="radio"
										id={`payment-${item.id}`}
										className="mb-0 form-check radio-select"
									>
										<Form.Check.Input
											type="radio"
											name="paymentMethod"
											defaultChecked={index === 0}
											onChange={e => setFormData({...formData, method: item?.id})}
										/>
										<Form.Check.Label className="mb-0 fw-bold d-block">
											XXXX XXXX XXXX {item?.number?.substring(15, 19)}
											<span className="radio-select-content">
                    <span>
                      {item.expiry_month}/{item.expiry_year} <br/> XXX{' '}
	                    <span className="d-block mb-0 pt-2">{item.full_address}</span>
                    </span>
                  </span>
										</Form.Check.Label>
									</Form.Check>
								</Col>
							))}
						</Row>
					</Form>
				}
				{/*<Form.Check*/}
				{/*	type="radio"*/}
				{/*	id="paymee"*/}
				{/*	className="d-flex align-items-center"*/}
				{/*>*/}
				{/*	<Form.Check.Input*/}
				{/*		type="radio"*/}
				{/*		onChange={e => {*/}
				{/*			setMethod(e.target.id)*/}
				{/*			setFormData({...formData, provider: process.env.DEFAULT_PAYMEE_PROVIDER})*/}
				{/*		}}*/}
				{/*		name="payment-method"*/}
				{/*	/>*/}
				{/*	<Form.Check.Label className="mb-0 ms-2">*/}
				{/*		<img src={paymee} height={50} alt="card payment options"/>*/}
				{/*	</Form.Check.Label>*/}
				{/*</Form.Check>*/}
				<Form.Check
					type="radio"
					id="konnect"
					onChange={e => {
						setMethod(e.target.id)
						setFormData({...formData, provider: process.env.DEFAULT_KONNECT_PROVIDER})
					}}
					className="d-flex align-items-center"
				>
					<Form.Check.Input
						type="radio"
						onChange={e => setMethod(e.target.id)}
						name="payment-method"
					/>
					<Form.Check.Label className="mb-0 ms-2">
						<img src={konnect} height={30} alt="card payment options"/>
					</Form.Check.Label>
				</Form.Check>
				<div className="border-dashed border-bottom my-5"></div>
				<Row>
					<Col
						md={7}
						xl={12}
						xxl={7}
						className="px-md-3 mb-xxl-0 position-relative"
					>
						<div className="d-flex">
							<img
								src={shield}
								alt="protection"
								width="60"
								height="60"
								className="me-3"
							/>
							<div className="flex-1">
								<h5 className="mb-2">Buyer Protection</h5>
								<Form.Check id="full-refund" className="mb-0 form-check">
									<Form.Check.Input
										name={"full_refund_if_not_received"}
										onChange={handleCheckboxChange}
										className="mb-0"
										type="checkbox"
										defaultChecked
										checked={formData.full_refund_if_not_received}
									/>
									<Form.Check.Label className="mb-0">
										<strong>Full Refund</strong> If you don't{' '}
										<br className="d-none d-md-block d-lg-none"/> receive your
										order
									</Form.Check.Label>
								</Form.Check>
								<Form.Check id="partial-refund" className="form-check">
									<Form.Check.Input
										name={"full_or_partial_refund_if_not_as_described"}
										onChange={handleCheckboxChange}
										className="mb-0"
										type="checkbox"
										checked={formData.full_or_partial_refund_if_not_as_described}
										defaultChecked
									/>
									<Form.Check.Label className="mb-0">
										<strong>Full or Partial Refund,</strong> If the product is
										not as described in details
									</Form.Check.Label>
								</Form.Check>
								<Link to="#!" className="fs-10 ms-3 ps-2">
									Learn More
									<FontAwesomeIcon
										icon="caret-right"
										className="ms-1"
										transform="down-2"
									/>
								</Link>
							</div>
						</div>
						<div className="vertical-line d-none d-md-block d-xl-none d-xxl-block"></div>
					</Col>
					<Col
						md={5}
						xl={12}
						xxl={5}
						className="ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start"
					>
						<div className="border-dashed border-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>
						<div className="fs-2 fw-semibold">
							All Total: <span className="text-primary">{formatNumberCurrency(payableTotal, currencyData)}</span>
						</div>
						<Button
							variant="success"
							className="mt-3 px-5"
							type="submit"
							onClick={handleSubmit}
						>
							Confirm &amp; Pay
						</Button>
						<p className="fs-10 mt-3 mb-0">
							By clicking <strong>Confirm &amp; Pay </strong> button you agree
							to the <Link to={paths.privacyPolicy}>Terms</Link> &amp; <Link
							to={paths.termsConditions}>Conditions</Link>
						</p>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

PaymentMethod.propTypes = {
	payableTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default PaymentMethod;
