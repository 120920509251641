import React, {useEffect, useState} from 'react';
import {Form, Nav, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import CSRFToken from "../../../helpers/CSRFToken";
import DatePicker from 'react-datepicker';
import CustomDateInput from "../../../components/common/CustomDateInput";
import {api} from "../../../utils/api";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import useQuery from "../../../hooks/useQuery";

const ModalSidebar = ({task, getTask, setShow, getKanbanStatus}) => {
    const [startDate, setStartDate] = useState(task?.start_date ? new Date(task?.start_date) : null)
    const [endDate, setEndDate] = useState(task?.end_date ? new Date(task?.end_date) : null)
    const [deadline, setDeadline] = useState(task?.deadline ? new Date(task?.deadline) : null)
    const [responsibilities, setResponsibilities] = useState([])

    const {t} = useTranslation(["events", "common"])
    const query = useQuery()

    const getResponsibilities = async () => {
        await api.get("/organizer/responsibility/").then(res => setResponsibilities(res?.data?.results))
    }

    useEffect(() => {
        getResponsibilities()
        // eslint-disable-next-line
    }, []);

    const RemoveCard = async (e) => {
        const {isConfirmed} = await Swal.fire({
            title: t('sure', {ns: "common"}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
            cancelButtonText: t('cancel', {ns: "common"})
        });
        if (isConfirmed) {
            e.preventDefault();
            await api.delete(`/task/tasks/${task?.id}/`).then(() => {
                toast.success(`Task ${t('deleteSuccess', {ns: "common"})}`, {
                    theme: 'colored'
                });
                setShow(false)
                getKanbanStatus(query)
            });
        }
    };
    return (
        <>
            <h6 className="mt-5 mt-lg-0">Add To Card</h6>
            <Form>
                <CSRFToken/>
                <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <Row>
                        <DatePicker
                            selected={startDate}
                            onChange={async newDate => {
                                setStartDate(newDate)
                                await api.patch(`/task/tasks/${task.id}/`, {start_date: new Date(newDate).toISOString()})
                                    .then(res => getTask())
                            }}
                            showTimeSelect
                            dateFormat="MM/dd/yyyy h:mm aa"
                            fixedHeight
                            className='form-control'
                            customInput={
                                <CustomDateInput
                                    formControlProps={{
                                        placeholder: 'MM/dd/yyyy h:mm aa',
                                    }}
                                />
                            }
                        />
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Row>
                        <DatePicker
                            selected={endDate}
                            onChange={async newDate => {
                                setEndDate(newDate)
                                await api.patch(`/task/tasks/${task.id}/`, {end_date: new Date(newDate).toISOString()})
                                    .then(res => getTask())
                            }}
                            showTimeSelect
                            dateFormat="MM/dd/yyyy h:mm aa"
                            fixedHeight
                            className='form-control'
                            customInput={
                                <CustomDateInput
                                    formControlProps={{
                                        placeholder: 'MM/dd/yyyy h:mm aa',
                                    }}
                                />
                            }
                        />
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Deadline</Form.Label>
                    <Row>
                        <DatePicker
                            selected={deadline}
                            onChange={async newDate => {
                                setDeadline(newDate)
                                await api.patch(`/task/tasks/${task.id}/`, {deadline: new Date(newDate).toISOString()})
                                    .then(res => getTask())
                            }}
                            showTimeSelect
                            dateFormat="MM/dd/yyyy h:mm aa"
                            fixedHeight
                            className='form-control'
                            customInput={
                                <CustomDateInput
                                    formControlProps={{
                                        placeholder: 'MM/dd/yyyy h:mm aa',
                                    }}
                                />
                            }
                        />
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Priority</Form.Label>
                    <Form.Select value={task?.priority} onChange={async (e) => {
                        await api.patch(`/task/tasks/${task.id}/`, {priority: e.target.value})
                            .then(res => getTask())
                    }}>
                        <option value={""}>----------------------</option>
                        <option value={"1"}>Low</option>
                        <option value={"2"}>Medium</option>
                        <option value={"3"}>High</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Responsibility</Form.Label>
                    <Form.Select value={task?.responsibility} onChange={async (e) => {
                        await api.patch(`/task/tasks/${task.id}/`, {responsibility: e.target.value})
                            .then(res => getTask())
                    }}>
                        <option value={""}>----------------------</option>
                        {responsibilities?.map(r => (
                            <option value={r?.id} dangerouslySetInnerHTML={{__html: `${r?.description} - ${r?.priority_display}`}}></option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Form>

            <h6 className="mt-3">Actions</h6>
            <Nav key={"Remove"} className="flex-lg-column fs-10">
                <Nav.Item className="me-2 me-lg-0">
                    <Nav.Link as={Link} onClick={RemoveCard} to="#!" className="nav-link-card-details">
                        <FontAwesomeIcon icon={"trash-alt"} className="me-2"/>
                        Remove
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </>
    );
};

export default ModalSidebar;
