import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import googleMapStyles from 'helpers/googleMapStyles';
import {
	GoogleMap as ReactGoogleMap,
	Marker,
	InfoWindow,
	useJsApiLoader
} from '@react-google-maps/api';
import mapMarker from '../../../src/assets/img/icons/map-marker.png';
import {useAppContext} from 'providers/AppProvider';
import {Spinner} from "react-bootstrap";
import Flex from "../common/Flex";

const GoogleMap = ({
	                   mapStyle,
	                   initialCenter,
	                   darkStyle,
	                   className,
	                   children,
	                   onLoad,
	                   mapContainerStyle,
	                   extras,
	                   ...rest
                   }) => {
	const {isLoaded} = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		id: "map"
	});

	const {
		config: {isDark}
	} = useAppContext();

	const [showInfoWindow, setShowInfoWindow] = useState(false);
	const [mapStyles, setMapStyles] = useState(mapStyle);

	const options = {
		mapTypeControl: true,
		streetViewControl: true,
		fullscreenControl: true,
		styles: googleMapStyles[mapStyles]
	};

	useEffect(() => {
		if (darkStyle && isDark) setMapStyles(darkStyle);
		else setMapStyles(mapStyle);
	}, [darkStyle, isDark, mapStyle]);

	if (!isLoaded) return (
		<Flex justifyContent="center" alignItems={"center"} className="p-2 mb-2">
			<Spinner animation={"border"} variant={"primary"}/>
		</Flex>
	);

	return (
		<div className={`h-100 ${className}`} {...rest}>
			<ReactGoogleMap
				zoom={18}
				options={options}
				center={initialCenter}
				mapContainerStyle={mapContainerStyle}
				id={"map"}
				onLoad={onLoad}
			>
				{extras}
				<Marker
					onClick={() => setShowInfoWindow(!showInfoWindow)}
					position={initialCenter}
					icon={mapMarker}
				>
					{children && showInfoWindow ? (
						<InfoWindow
							position={initialCenter}
							onCloseClick={() => setShowInfoWindow(false)}
						>
							<div>{children}</div>
						</InfoWindow>
					) : null}
				</Marker>
			</ReactGoogleMap>
		</div>
	);
};

GoogleMap.propTypes = {
	mapStyle: PropTypes.oneOf([
		'Default',
		'Gray',
		'Midnight',
		'Hopper',
		'Beard',
		'AssassianCreed',
		'SubtleGray',
		'Tripitty',
		'Cobalt'
	]),
	darkStyle: PropTypes.oneOf([
		'Default',
		'Gray',
		'Midnight',
		'Hopper',
		'Beard',
		'AssassianCreed',
		'SubtleGray',
		'Tripitty',
		'Cobalt'
	]),
	className: PropTypes.string,
	children: PropTypes.node,
	initialCenter: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number
	})
};

export default GoogleMap;
