import React, {useEffect, useState} from 'react';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import {api} from "../../../utils/api";
import {formatDateTime} from "../../../helpers/utils";
import useQuery from "../../../hooks/useQuery";

const ModalCommentContent = ({taskComments, task, getTask, getKanbanStatus}) => {
  const [currentUser, setCurrentUser] = useState({});
  const [formData, setFormData] = useState({
    task: "",
    user: "",
    text: ""
  });

  const query = useQuery()

  const getCurrentUser = async () => {
    await api.get("/account/current_user/").then(res => setCurrentUser(res?.data));
  }

  useEffect(() => {
    getCurrentUser()
    // eslint-disable-next-line
  }, []);

  const handleFieldChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  }

  const handleAddComment = async (e) => {
    e.preventDefault()
    await api.post("/task/comments/", {...formData, user: currentUser.id, task: task})
        .then(res => {
          getTask()
          getKanbanStatus(query)
        })
  };
  return (
    <>
      <Flex>
        <Avatar src={currentUser?.url_path} className="me-2" size="l" />
        <div className="flex-1 fs-10">
          <div className="position-relative border rounded mb-3">
            <Form>
              <Form.Control
                as="textarea"
                className="border-0 rounded-bottom-0 resize-none"
                rows={3}
                onChange={handleFieldChange}
                name={"text"}
                value={formData.text}
              />
              <Flex
                justifyContent="between"
                alignItems="center"
                className="bg-body-tertiary rounded-bottom p-2 mt-1"
              >
                <Button size="sm" color="primary" type="submit" onClick={handleAddComment}>
                  Save
                </Button>
              </Flex>
            </Form>
          </div>
        </div>
      </Flex>

      {taskComments?.map(comment => (
        <Flex key={comment?.id} className="mb-3">
          <Link to={paths.usersDetail.replace(":id", comment?.user)}>
            <Avatar src={comment?.user_avatar} size="l" />
          </Link>
          <div className="flex-1 ms-2 fs-10">
            <p className="mb-1 bg-200 rounded-3 p-2">
              <Link to={paths.usersDetail.replace(":id", comment?.user)} className="fw-semibold">
                {comment?.user_full_name}
              </Link>{' '}
              {comment?.text}
            </p>
            {formatDateTime(comment?.created_at)}
          </div>
        </Flex>
      ))}
    </>
  );
};

export default ModalCommentContent;
