import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
const RequestInfo = ({ join }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">User Information</h5>
            <h6 className="mb-2">{join?.user_details?.full_name}</h6>
            <p className="mb-1 fs--1">{join?.user_details?.address_names}</p>
            <p className="mb-0 fs--1">
              <strong>
                Email:{' '}
                <a href={`mailto:${join?.user_details?.email}`}>
                  {join?.user_details?.email}
                </a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Phone:{' '}
                <a href={`tel:${join?.user_details?.number}`}>
                  {join?.user_details?.number}
                </a>
              </strong>
            </p>
          </Col>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Activity Information</h5>
            <h6 className="mb-2">{join?.activity_details?.name}</h6>
            <p className="mb-0 fs--1">{join?.activity_details?.address}</p>
            <div className="text-500 fs--1">
              {join?.activity_details?.type_display}
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="d-flex">
              <img
                src={join?.activity_details?.logo_url}
                width="200"
                height="150"
                className=""
                alt={join?.activity_details?.slug}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

RequestInfo.propTypes = {};

export default RequestInfo;
