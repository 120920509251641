import {formatDateTime, withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import PageHeader from "../../../components/common/PageHeader";
import classNames from "classnames";
import {api} from "../../../utils/api";
import {Link, useNavigate, useParams} from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import paths from "../../../routes/paths";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import SoftBadge from "../../../components/common/SoftBadge";
import AdvanceTableSearchBox from "../../../components/common/advance-table/AdvanceTableSearchBox";
import {debounce} from "lodash";

const UserHistory = ({t, i18n}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0)
    const [length, setLength] = useState(0)

    const {id} = useParams()
    const query = useQuery()
    const navigate = useNavigate()

    query.set("actor", id)
    query.set("content_type__model", "user")
    query.set("page_size", "50")

    const fetchData = async (q) => {
        setLoading(true)
        await api.get(`/utils/history/?${q.toString()}`).then(res => {
            setData(res?.data?.results)
            setCount(res?.data?.count)
        })
        setLength(data?.length);
        setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchData, 600), []);

    useEffect(() => {
        fetchData(query)
        setLength(data?.length)
        navigate(`?${query.toString()}`)
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <PageHeader
                title={t('titles.history', {ns: "common"})}
                className="mb-3"
            />
            <Card className="mb-3">
                <Card.Body className="px-sm-4 px-md-8 px-lg-6 px-xxl-8">
                    <Flex justifyContent={"center"} alignItems={"center"} wrap={"wrap"}>
                        <AdvanceTableSearchBox fetch={delayedLoadItems} placeholder={t('search', {ns: "common"})} />
                    </Flex>
                    <div className="timeline-vertical">
                        {data?.map((item, index) => {
                            const {actor, actor_full_name, timestamp, changes, action_display, object_repr} = item;
                            const filteredChanges = Object.keys(changes).filter(
                                (key) => key !== 'password' && key !== 'password_reset_tokens' && key !== 'follower' && key !== 'following' && key !== 'company_contact_person'
                            ).reduce((obj, key) => {
                                obj[key] = changes[key];
                                return obj;
                            }, {});
                            return (
                                <div
                                    key={index}
                                    className={classNames('timeline-item', {
                                        'timeline-item-start': index % 2 === 0,
                                        'timeline-item-end': index % 2 !== 0
                                    })}
                                >
                                    <div className="timeline-icon icon-item icon-item-sm text-primary border-300">

                                    </div>
                                    <Row
                                        className={` ${
                                            index % 2 === 0
                                                ? 'timeline-item-start'
                                                : 'timeline-item-end'
                                        }`}
                                    >
                                        <Col lg={6} className="timeline-item-time">
                                            <div>
                                                <p className="fs-10 mb-0 fw-semibold"> {formatDateTime(timestamp)}</p>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="timeline-item-content">
                                                <div className="timeline-item-card">
                                                    <Flex justifyContent={"between"} wrap={"wrap"} className={"mb-2"}>
                                                        <h5 className="mb-2">
                                                            <Link
                                                                to={paths.usersDetail.replace(":id", actor)}>{actor_full_name}</Link>
                                                        </h5>
                                                        <SoftBadge className="mb-2" pill>
                                                            {action_display} {object_repr}
                                                        </SoftBadge>
                                                    </Flex>
                                                    <ul className="fs-10 mb-0">
                                                        {Object.keys(filteredChanges)?.map((item, index) => (
                                                            <li key={index}>
                                                                {(item === 'last_login' || item === 'created_at' || item === 'updated_at' || item === 'date_joined')
                                                                    ? `${t(`fields.${item}`)}: ${formatDateTime(filteredChanges[item][0])} → ${formatDateTime(filteredChanges[item][1])}`
                                                                    : `${t(`fields.${item}`)}: ${filteredChanges[item][0]} → ${filteredChanges[item][1]}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}
                    </div>
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchData}
                        count={count}
                        length={length}
                        itemsPerPage={50}
                    />
                </Card.Footer>
            </Card>
        </Fragment>
    )
}

export default withPermission(withTranslation(["users", "common"])(UserHistory), "auditlog.view_logentry")