import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Row} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import classNames from 'classnames';
import {Field} from "formik";

const EventCustomFieldItem = ({
                                  name,
                                  type,
                                  value,
                                  options,
                                  index,
                                  setFieldValue,
                              }) => {
    const [date, setDate] = useState(value);
    const [time, setTime] = useState(value);

    // eslint-disable-next-line no-lone-blocks
    {
        switch (type) {
            case 'number':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.value`}>
                            {({field}) => (
                                <Form.Control
                                    type="number"
                                    name={`custom_fields.${index}.value`}
                                    placeholder={`Enter ${name} ...`}
                                    value={field.value}
                                    onChange={({target}) => setFieldValue(field.name, target.value)}
                                />
                            )}
                        </Field>

                    </Form.Group>
                );
            case 'password':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.value`}>
                            {({field}) => (
                                <Form.Control
                                    type="password"
                                    name={`custom_fields.${index}.value`}
                                    placeholder={`Enter ${name} ...`}
                                    value={field.value}
                                    onChange={({target}) => setFieldValue(`custom_fields.${index}.value`, target.value)}
                                />
                            )}
                        </Field>
                    </Form.Group>
                );

            case 'email':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.value`}>
                            {({field}) => (
                                <Form.Control
                                    type="email"
                                    name={`custom_fields.${index}.value`}
                                    placeholder={`Enter ${name} ...`}
                                    value={field.value}
                                    onChange={({target}) => setFieldValue(`custom_fields.${index}.value`, target.value)}
                                />
                            )}
                        </Field>
                    </Form.Group>
                );

            case 'checkboxes':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                    >
                        <Form.Label>{name}</Form.Label>

                        {options.map((option, key) => (
                            <Field name={`custom_fields.${index}.value`}>
                                {({field}) => (
                                    <Form.Check id={`customCheckbox${key}`} key={key}>
                                        <Form.Check.Input
                                            checked={field.value === option}
                                            type="checkbox"
                                            onChange={({target}) => setFieldValue(`custom_fields.${index}.value`, target.value)}
                                        />
                                        <Form.Check.Label className="mb-0">{option}</Form.Check.Label>
                                    </Form.Check>
                                )}
                            </Field>
                        ))}
                    </Form.Group>
                );
            case 'radio':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                    >
                        <Form.Label>{name}</Form.Label>

                        {options.map((option, key) => (
                            <Field name={`custom_fields.${index}.value`}>
                                {({field}) => (
                                    <Form.Check id={`customCheckbox${key}`} key={key}>
                                        <Form.Check.Input
                                            checked={field.value === option}
                                            type="radio"
                                            onChange={({target}) => setFieldValue(`custom_fields.${index}.value`, target.value)}
                                        />
                                        <Form.Check.Label className="mb-0">{option}</Form.Check.Label>
                                    </Form.Check>
                                )}
                            </Field>
                        ))}
                    </Form.Group>
                );

            case 'select':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.value`}>
                            {({field}) => (
                                <Form.Select
                                    aria-label="Default select example"
                                    value={field.value}
                                    onChange={({target}) => setFieldValue(`custom_fields.${index}.value`, target.value)}
                                >
                                    {options?.map((option, key) => (
                                        <option key={key} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Select>
                            )}
                        </Field>
                    </Form.Group>
                );

            case 'textarea':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                        controlId="ControlTextarea"
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.value`}>
                            {({field}) => (
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={field.value}
                                    placeholder={`Enter ${name} ...`}
                                    onChange={({target}) => setFieldValue(`custom_fields.${index}.value`, target.value)}
                                />
                            )}
                        </Field>
                    </Form.Group>
                );

            case 'file':
                return (
                    <Form.Group
                        controlId="formFileSm"
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.file`}>
                            {({field}) => (
                                <Form.Control type="file"
                                              onChange={({target}) => {
                                                  const file = target.files[0];
                                                  if (file) {
                                                      const reader = new FileReader();
                                                      reader.onloadend = () => {
                                                          setFieldValue(`custom_fields.${index}.file`, reader.result)
                                                      };
                                                      reader.readAsDataURL(file);  // Read the file as Data URL to get the base64 encoded string
                                                  }
                                              }}
                                />
                            )}
                        </Field>
                    </Form.Group>
                );

            case 'time':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                        controlId="startDate"
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.value`}>
                            {({field}) => (
                                <Row>
                                    <DatePicker
                                        selected={time}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm"
                                        onChange={newDate => {
                                            setTime(newDate);
                                            setFieldValue(`custom_fields.${index}.value`, newDate);
                                        }}
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'H:i'
                                                }}
                                            />
                                        }
                                    />
                                </Row>

                            )}
                        </Field>
                    </Form.Group>
                );
            case 'date':
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                        controlId="startDate"
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.value`}>
                            {({field}) => (
                                <Row>
                                    <DatePicker
                                        selected={date}
                                        onChange={newDate => {
                                            setDate(newDate);
                                            setFieldValue(`custom_fields.${index}.value`, newDate);
                                        }}
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'd/m/y'
                                                }}
                                            />
                                        }
                                    />
                                </Row>
                            )}
                        </Field>
                    </Form.Group>
                );

            default:
                return (
                    <Form.Group
                        className={classNames('position-relative hover-actions-trigger', {
                            'mt-3': index !== 0
                        })}
                    >
                        <Form.Label>{name}</Form.Label>
                        <Field name={`custom_fields.${index}.value`}>
                            {({field}) => (
                                <Form.Control
                                    type="text"
                                    name={`name${index}`}
                                    placeholder={`Enter ${name} ...`}
                                    value={field.value}
                                    onChange={({target}) => setFieldValue(`custom_fields.${index}.value`, target.value)}
                                />
                            )}
                        </Field>
                    </Form.Group>
                );
        }
    }
};

EventCustomFieldItem.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.array,
    index: PropTypes.number,
    register: PropTypes.func,
    setValue: PropTypes.func,
    id: PropTypes.string,
    handleRemove: PropTypes.func
};

export default EventCustomFieldItem;
