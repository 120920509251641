import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Flex from '../../../../components/common/Flex';
import {Card, Spinner} from 'react-bootstrap';
import {api} from '../../../../utils/api';
import SoftBadge from '../../../../components/common/SoftBadge';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../../components/common/PageHeader';
import RequestInfo from './RequestInfo';
import AnsweredQuestions from './AnsweredQuestions';
import {formatDateTime, withPermission} from '../../../../helpers/utils';
import paths from "../../../../routes/paths";
import {useAppContext} from "../../../../providers/AppProvider";
import IconButton from "../../../../components/common/IconButton";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import RequestQuestionsModal from "./RequestQuestionsModal";
import {toast} from "react-toastify";
import CSRFToken from "../../../../helpers/CSRFToken";

const RequestDetail = () => {
	const [loading, setLoading] = useState(false);
	const [join, setJoin] = useState({});
	const [activity, setActivity] = useState({})
	const [questionModal, setQuestionModal] = useState(false);

	const {
		config: {isSuperUser},
		setConfig
	} = useAppContext()

	const {id} = useParams();
	const navigate = useNavigate();

	const getJoinRequest = async () => {
		setLoading(true);
		await api
			.get(`/join/join/${id}/`)
			.then(res => {
				setJoin(res?.data)
				setActivity(res?.data?.activity_details)
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", false);
			});
		setLoading(false);
	};

	useEffect(() => {
		getJoinRequest();
		// eslint-disable-next-line
	}, []);

	const statusMapping = status => {
		switch (status) {
			case 'Accepted':
				return (
					<SoftBadge pill bg="success" className="fs--2">
						{' '}
						{status}
						<FontAwesomeIcon
							icon="check"
							className="ms-1"
							transform="shrink-2"
						/>
					</SoftBadge>
				);
			case 'Pending':
				return (
					<SoftBadge pill bg="warning" className="fs--2">
						{' '}
						{status}
						<FontAwesomeIcon
							icon="clock"
							className="ms-1"
							transform="shrink-2"
						/>
					</SoftBadge>
				);
			case 'Rejected':
				return (
					<SoftBadge pill bg="danger" className="fs--2">
						{' '}
						{status}
						<FontAwesomeIcon icon="ban" className="ms-1" transform="shrink-2"/>
					</SoftBadge>
				);
			default:
				return (
					<SoftBadge pill bg="secondary" className="fs--2">
						{' '}
						{status}
						<FontAwesomeIcon
							icon="paperclip"
							className="ms-1"
							transform="shrink-2"
						/>
					</SoftBadge>
				);
		}
	};

	const ApproveRequest = async (e) => {
		e.preventDefault();
		setLoading(true)
		await api.patch(`/join/join/${id}/`, {status: "accepted"})
			.then(() => {
				toast.success("Request successfully accepted.", {theme: "colored"})
				getJoinRequest()
			})
			.catch(() => {
				toast.error("Request failed", {theme: "colored"})
			})
		setLoading(false)
	}

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Fragment>
			<Card className={"mb-3"}>
				<Card.Header className="d-flex flex-between-center">
					<Flex justifyContent={"start"} alignItems={"center"}>
						<IconButton
							onClick={() => navigate(-1)}
							variant="falcon-default"
							size="sm"
							icon="arrow-left"
						/>
					</Flex>
					<Flex justifyContent={"end"} alignItems={"center"}>
						{(!activity?.is_owned && join?.answers?.length <= 0) &&
							<IconButton
								size={"sm"}
								className="me-2"
								variant={"falcon-secondary"}
								icon={faCheckCircle}
								onClick={() => setQuestionModal(!questionModal)}
							>
								<span className="d-none d-sm-inline-block ms-1">Answer the questions</span>
							</IconButton>
						}
						{((activity?.is_owned || isSuperUser) && join?.status !== "accepted") &&
							<IconButton
								size={"sm"}
								variant={"falcon-success"}
								icon={faCheckCircle}
								onClick={(e) => ApproveRequest(e)}
							>
								<CSRFToken />
								<span className="d-none d-sm-inline-block ms-1">Approve</span>
							</IconButton>
						}
					</Flex>
				</Card.Header>
			</Card>
			<PageHeader
				title={`Request Details: #${join?.id}`}
				titleTag="h5"
				className="mb-3"
			>
				<p className="fs--1 mt-1">{formatDateTime(join?.created_at)}</p>
				<div>
					<strong className="me-2">Status: </strong>

					{statusMapping(join?.status_display)}
				</div>
			</PageHeader>
			<RequestInfo join={join}/>
			<AnsweredQuestions answers={join?.answers}/>
			<RequestQuestionsModal open={questionModal} setOpen={setQuestionModal} activity={activity} getJoinRequest={getJoinRequest}/>
		</Fragment>
	);
};

export default withPermission(RequestDetail, "join.view_join");
