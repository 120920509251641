import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse } from 'react-bootstrap';

const ProfileIntro = ({ intro, t }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <h5 className="mb-0">{t('fields.intro')}</h5>
      </Card.Header>

      <Card.Body className="text-justify text-1000">
        <div
          dangerouslySetInnerHTML={{
            __html: intro?.slice(0, 1000)
          }}
        />
        <Collapse in={collapsed}>
          <div
            dangerouslySetInnerHTML={{
              __html: intro?.slice(1000)
            }}
          />
        </Collapse>
      </Card.Body>

      <Card.Footer className="bg-light p-0 border-top d-grid">
        <Button
          variant="link"
          // className="me-2 mb-1"
          onClick={() => setCollapsed(!collapsed)}
        >
            {t('show', {ns: "common"})} {collapsed ? t('less', {ns: "common"}) : t('more', {ns: "common"})}
          <FontAwesomeIcon
            icon="chevron-down"
            className="ms-2 fs--2"
            transform={collapsed ? 'rotate-180' : ''}
          />
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ProfileIntro;
