import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Flex from "../../../../components/common/Flex";
import SimpleBarReact from "simplebar-react";
import {Field, Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import FormError from "../../../errors/FormError";
import konnect from "../../../../assets/img/logos/logo_konnect.svg";
import paymee from 'assets/img/logos/paymee-logo.png';
import shield from "../../../../assets/img/icons/shield.png";
import {Link} from "react-router-dom";
import paths from "../../../../routes/paths";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";
import {useAppContext} from "../../../../providers/AppProvider";

const MemberPaymentModal = ({show, setShow, membership, providers}) => {
    const [method, setMethod] = useState("konnect")
    const [payableTotal, setPayableTotal] = useState(0)
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        option: [],
        status: "pending",
        full_refund_if_not_received: false,
        full_or_partial_refund_if_not_as_described: false,
        provider: "",
        path: "transaction"
    })

    const {
        config: {currency}
    } = useAppContext()

    const handleCheckboxChange = e => {
        setFormData({...formData, [e.target.name]: e.target.checked})
    }

    useEffect(() => {
        setFormData({...formData, amount: payableTotal, currency: currency})
        // eslint-disable-next-line
    }, [payableTotal, currency]);

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        if (method === "konnect")
            await api.post("/konnect/transaction/", formData)
                .then(res => {
                    toast.success("Payment successfully processed!", {theme: "colored"})
                    window.open(res?.data?.pay_url)
                })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        else if (method === "paymee")
            await api.post("/paymee/transaction/", formData)
                .then(res => {
                    toast.success("Payment successfully processed!", {theme: "colored"})
                    window.open(res?.data?.payment_url)
                })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                })
        setLoading(false)
    };

    return (
        <Modal show={show} onHide={() => setShow(!show)} centered size={"lg"}>
            <Modal.Header closeButton>
                <h5>Membership Payment</h5>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                        {({values, setFieldValue}) => (
                            <>
                                <div className="mt-4 fs-10">
                                    <SimpleBarReact>
                                        <Table striped className="border-bottom">
                                            <thead data-bs-theme="light">
                                            <tr className="bg-primary dark__bg-1000">
                                                <th className="text-white border-0 text-end"></th>
                                                <th className="text-white border-0">Option Name</th>
                                                <th className="text-white border-0 text-center">Price</th>
                                                <th className="text-white border-0 text-center">Default</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {membership?.membership_options?.map((option, index) => (
                                                <tr key={index}>
                                                    <td className="align-middle text-center">
                                                        <Field name={`option.${index}`}>
                                                            {({field}) => (
                                                                <Form.Switch
                                                                    type="checkbox"
                                                                    name={`option.${index}`}
                                                                    value={option?.id}
                                                                    checked={formData.option.includes(option?.id)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setFormData({
                                                                                ...formData,
                                                                                option: [...formData.option, parseInt(e.target.value)]
                                                                            })
                                                                            setPayableTotal(prevState => prevState + option?.amount)
                                                                        } else {
                                                                            setFormData({
                                                                                ...formData,
                                                                                option: formData.option.filter(o => o !== parseInt(e.target.value))
                                                                            })
                                                                            setPayableTotal(prevState => prevState - option?.amount)
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td className="align-middle">
                                                        <h6 className="mb-0 text-nowrap">{option?.name}</h6>
                                                        <p
                                                            className="mb-0 fs--1">{option?.default && "This is a default option and you must check it."}</p>
                                                    </td>
                                                    <td className="align-middle text-center">{option?.amount} {currency}</td>
                                                    <td className="align-middle text-center">
                                                        {option?.default ? <FontAwesomeIcon icon={faCheck}/> :
                                                            <FontAwesomeIcon icon={faTimes}/>}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </SimpleBarReact>
                                    <FormError error={errors?.option}/>
                                </div>
                                {providers?.map((provider, index) => (
                                    <Form.Check
                                        type="radio"
                                        key={index}
                                        id={provider?.provider}
                                        onChange={e => {
                                            setMethod(e.target.id)
                                            setFormData({...formData, provider: provider?.id})
                                        }}
                                        className="d-flex align-items-center mt-3"
                                    >
                                        <Form.Check.Input
                                            type="radio"
                                            id={provider?.provider}
                                            checked={method === provider?.provider}
                                            onChange={e => {
                                                setMethod(e.target.id)
                                                setFormData({...formData, provider: provider?.id})
                                            }}
                                            name="payment-method"
                                        />
                                        <Form.Check.Label className="mb-0 ms-2">
                                            <img src={provider?.provider === "konnect" ? konnect : paymee} height={50}
                                                 alt="card payment options"/>
                                        </Form.Check.Label>
                                    </Form.Check>
                                ))}
                                <div className="border-dashed border-bottom my-5"></div>
                                <Row>
                                    <Col
                                        md={7}
                                        xl={12}
                                        xxl={7}
                                        className="px-md-3 mb-xxl-0 position-relative"
                                    >
                                        <div className="d-flex">
                                            <img
                                                src={shield}
                                                alt="protection"
                                                width="60"
                                                height="60"
                                                className="me-3"
                                            />
                                            <div className="flex-1">
                                                <h5 className="mb-2">Buyer Protection</h5>
                                                <Form.Check id="full-refund" className="mb-0 form-check">
                                                    <Form.Check.Input
                                                        name={"full_refund_if_not_received"}
                                                        onChange={handleCheckboxChange}
                                                        className="mb-0"
                                                        type="checkbox"
                                                        defaultChecked
                                                        checked={formData.full_refund_if_not_received}
                                                    />
                                                    <Form.Check.Label className="mb-0">
                                                        <strong>Full Refund</strong> If you don't{' '}
                                                        <br className="d-none d-md-block d-lg-none"/> receive your
                                                        order
                                                    </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check id="partial-refund" className="form-check">
                                                    <Form.Check.Input
                                                        name={"full_or_partial_refund_if_not_as_described"}
                                                        onChange={handleCheckboxChange}
                                                        className="mb-0"
                                                        type="checkbox"
                                                        checked={formData.full_or_partial_refund_if_not_as_described}
                                                        defaultChecked
                                                    />
                                                    <Form.Check.Label className="mb-0">
                                                        <strong>Full or Partial Refund,</strong> If the product is
                                                        not as described in details
                                                    </Form.Check.Label>
                                                </Form.Check>
                                                <Link to="#!" className="fs-10 ms-3 ps-2">
                                                    Learn More
                                                    <FontAwesomeIcon
                                                        icon="caret-right"
                                                        className="ms-1"
                                                        transform="down-2"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="vertical-line d-none d-md-block d-xl-none d-xxl-block"></div>
                                    </Col>
                                    <Col
                                        md={5}
                                        xl={12}
                                        xxl={5}
                                        className="ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start"
                                    >
                                        <div
                                            className="border-dashed border-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>
                                        <div className="fs-2 fw-semibold">
                                            All Total: <span
                                            className="text-primary">{payableTotal} {currency}</span>
                                        </div>
                                        <Button
                                            variant="success"
                                            className="mt-3 px-5"
                                            type="submit"
                                            onClick={handleSubmit}
                                        >
                                            Confirm &amp; Pay
                                        </Button>
                                        <p className="fs-10 mt-3 mb-0">
                                            By clicking <strong>Confirm &amp; Pay </strong> button you agree
                                            to the <Link to={paths.privacyPolicy}>Terms</Link> &amp; <Link
                                            to={paths.termsConditions}>Conditions</Link>
                                        </p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Formik>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default MemberPaymentModal