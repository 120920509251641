import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import SoftBadge from '../../../components/common/SoftBadge';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CardDropdown from '../../../components/common/CardDropdown';
import {Card, Dropdown, Spinner} from 'react-bootstrap';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../../components/common/advance-table/AdvanceTableWrapper';
import useQuery from '../../../hooks/useQuery';
import {getUsers} from '../actions/Users';
import Flex from '../../../components/common/Flex';
import Avatar from '../../../components/common/Avatar';
import {formatDateTime, withPermission} from '../../../helpers/utils';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {api} from '../../../utils/api';
import {debounce} from 'chart.js/helpers';
import {useAppContext} from "../../../providers/AppProvider";
import paths from "../../../routes/paths";
import {usePermissions} from "../../../providers/PermissionsProvider";
import BaseTableHeader from "../../BaseTableHeader";
import {useTranslation} from "react-i18next";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('date_joined'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {t} = useTranslation(["users", "common"])

    const {
        setConfig
    } = useAppContext()
    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchUsers = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);

        // Cancel the previous request if it exists
        getUsers(q)
            .then(res => {
                setUsers(res?.results);
                setCount(res?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLength(users.length);
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchUsers, 600), []);

    useEffect(() => {
        fetchUsers(query);
        setLength(users.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'name',
            Header: t('fields.name'),
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const {name, avatar, id, birth_date} = rowData.row.original;
                return (
                    <>
                        <Link to={`/accounts/user/${id}/detail`}>
                            <Flex alignItems="center">
                                {avatar ? (
                                    <Avatar
                                        src={avatar}
                                        rounded={'circle'}
                                        size="4xl"
                                        className="me-2"
                                    />
                                ) : (
                                    <Avatar
                                        size="4xl"
                                        name={name}
                                        rounded={'circle'}
                                        className="me-2"
                                    />
                                )}
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: '200px',
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {name}
                                    </h6>
                                    <div style={{color: 'black'}}>{birth_date}</div>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            }
        },
        {
            accessor: 'username',
            Header: t('fields.username'),
            canSort: true,
            cellProps: {
                className: 'py-2'
            },
            sortingDirection: sortingField === 'username' && sortingDirection,
            isSorted: false,
            headerProps: {className: 'pe-3'}
        },
        {
            accessor: 'email',
            Header: t('fields.email'),
            canSort: true,
            sortingDirection: sortingField === 'email' && sortingDirection,
            isSorted: false,
            cellProps: {
                className: 'py-2'
            },
            headerProps: {className: 'pe-3'},
            Cell: rowData => {
                const {email} = rowData.row.original;
                return <a href={`mailto:${email}`}>{email}</a>;
            }
        },
        {
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            accessor: 'number',
            Header: t('fields.phone'),
            canSort: true,
            sortingDirection: sortingField === 'number' && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {number} = rowData.row.original;
                return (
                    <>
                        <p className="mb-0 text-500">{number}</p>
                    </>
                );
            }
        },
        {
            accessor: 'is_active',
            Header: t('fields.status.title'),
            canSort: true,
            headerProps: {className: 'pe-1 text-center'},
            cellProps: {
                className: 'py-2 fs-0'
            },
            sortingDirection: sortingField === 'is_active' && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {is_active} = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: is_active,
                            warning: !is_active
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {is_active && t('fields.status.active')}
                            {!is_active && t('fields.status.inactive')}
                        </p>
                        <FontAwesomeIcon
                            icon={classNames({
                                check: is_active,
                                ban: !is_active
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'date_joined',
            Header: t('fields.date'),
            canSort: true,
            sortingDirection: sortingField === 'date_joined' && sortingDirection,
            isSorted: false,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => `${formatDateTime(rowData.row.original.date_joined)}`
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id, username, is_active} = rowData.row.original;
                const deactivateUser = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: "common"}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: t('cancel', {ns: "common"}),
                        confirmButtonText: is_active
                            ? `${t('yes', {ns: "common"})}, ${t('deactivate', {ns: "common"})}`
                            : `${t('yes', {ns: "common"})}, ${t('activate', {ns: "common"})}`
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api
                            .patch(`/account/user/${id}/`, {
                                is_active: !is_active
                            })
                            .then(() => {
                                toast.success(
                                    `User ${username} successfully ${
                                        is_active ? 'deactivated' : 'activated'
                                    }.`,
                                    {
                                        theme: 'colored'
                                    }
                                );
                                getUsers(query)
                                    .then(res => {
                                        setUsers(res?.results);
                                        setCount(res?.count);
                                    })
                                    .catch(() => {
                                    });
                            });
                        setLoading(false);
                    }
                };
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: "common"}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: t('cancel', {ns: "common"}),
                        confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/account/user/${id}/`).then(() => {
                            toast.success(`${t('title')} ${username} ${t('deleteSuccess', {ns: "common"})}.`, {
                                theme: 'colored'
                            });
                            getUsers(query)
                                .then(res => {
                                    setUsers(res?.results);
                                    setCount(res?.count);
                                })
                                .catch(() => {
                                });
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("accounts.change_user") &&
                                <>
                                    <Dropdown.Item
                                        onClick={() => navigate(`/accounts/user/${id}/edit`)}
                                    >
                                        {t('edit', {ns: "common"})}
                                    </Dropdown.Item>
                                    <Dropdown.Divider as="div"/>
                                    <Dropdown.Item
                                        onClick={e => deactivateUser(e)}
                                        className="text-danger"
                                    >
                                        {is_active ? t('deactivate', {ns: "common"}) : t('activate', {ns: "common"})}
                                    </Dropdown.Item>
                                </>
                            }
                            {hasPermission("accounts.delete_user") &&
                                <Dropdown.Item
                                    onClick={e => handleDelete(e)}
                                    className="text-danger"
                                >
                                    {t('delete', {ns: "common"})}
                                </Dropdown.Item>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const userList = users.map(user => ({
        id: user.id,
        name: user.full_name,
        birth_date: user.birth_date,
        activity: user.activity,
        activity_name: user.activity_name,
        email: user.email,
        username: user.username,
        number: user.number,
        is_active: user.is_active,
        avatar: user.url_path,
        groups_names: user.groups_name,
        groups: user.groups,
        date_joined: user.date_joined
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={userList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        data={userList}
                        count={count}
                        addPermission={"accounts.add_user"}
                        creationPath={paths.usersAdd}
                        title={"user"}
                        fields={["username", "first_name", "last_name", "email", "number", "is_active", "created_at", "birth_date"]}
                        deletePermission={"accounts.delete_user"}
                        exportPermission={"accounts.add_userexportfile"}
                        exportsPath={paths.usersExport}
                        exportLink={"/account/export/user/"}
                        deleteLink={"/account/delete/user/"}
                        setLoading={setLoading}
                        ns={"users"}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchUsers}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default withPermission(Users, "accounts.view_user")