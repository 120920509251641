import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Greetings from './Greetings';
import TeamProgress from './TeamProgress';
import Discussion from './Discussion';
import ProjectStatistics from './project-statistics/ProjectStatistics';
import Statistics from './statistics/Statistics';
import ReportForThisWeek from './report-for-this-week/ReportForThisWeek';
import MemberInfo from './MemberInfo';
import RunningProjects from './RunningProjects';
import ProjectLocation from './project-location/ProjectLocation';
import {
  markers,
  greetingItems,
  runningProjects
} from 'data/dashboard/projectManagement';
import {withPermission} from "../../../helpers/utils";
import CSRFToken from "../../../helpers/CSRFToken";
import {useAppContext} from "../../../providers/AppProvider";
import {withTranslation} from "react-i18next";

const ProjectManagement = ({t, i18n}) => {
  const {
    config: {isAuthenticated}
  } = useAppContext()


  return isAuthenticated && (
    <>
      <CSRFToken />
      <Row className="g-3 mb-3">
        <Col xxl={6} lg={12}>
          <Greetings data={greetingItems} t={t} />
        </Col>
        <Col xxl={3} md={6}>
          <TeamProgress t={t} />
        </Col>
        <Col xxl={3} md={6}>
          <Discussion />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={6} lg={12}>
          <Row>
            <Col lg={12}>
              <Statistics />
            </Col>
            <Col lg={12}>
              <ReportForThisWeek />
            </Col>
          </Row>
        </Col>
        <Col xxl={{ span: 4, order: 1 }} lg={6}>
          <ProjectLocation data={markers} />
        </Col>
        <Col xxl={6} lg={6}>
          <ProjectStatistics />
        </Col>
        <Col xxl={{ span: 8, order: 2 }}>
          <MemberInfo />
        </Col>
        <Col xxl={{ span: 12, order: 3 }}>
          <RunningProjects data={runningProjects} />
        </Col>
      </Row>
    </>
  );
};

export default withPermission(withTranslation(["dashboard", "common"])(ProjectManagement), "dashboard.view_dashboard");
