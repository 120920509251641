import {b64toBlob, isIterableArray, withPermission} from "../../../../helpers/utils";
import React, {Fragment, useEffect, useState} from "react";
import useQuery from "../../../../hooks/useQuery";
import {useNavigate, useParams} from "react-router-dom";
import {getCities, getCountries, getDelegations} from "../../../address/actions/Address";
import paths from "../../../../routes/paths";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import {Field, Formik} from "formik";
import CSRFToken from "../../../../helpers/CSRFToken";
import Avatar from "../../../../components/common/Avatar";
import FalconDropzone from "../../../../components/common/FalconDropzone";
import cloudUpload from "../../../../assets/img/icons/cloud-upload.svg";
import FormError from "../../../errors/FormError";
import avatarImg from "../../../../assets/img/team/avatar.png";
import Select from "react-select";
import IconButton from "../../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";
import {useAppContext} from "../../../../providers/AppProvider";
import {getUsers} from "../../../account/actions/Users";
import TinymceEditor from "../../../../components/common/TinymceEditor";

const CompaniesAddEdit = () => {
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [pageCountry, setPageCountry] = useState(1);
    const [pageDelegation, setPageDelegation] = useState(1);
    const [pageCity, setPageCity] = useState(1);
    const [delegations, setDelegations] = useState([]);
    const [selectedDelegation, setSelectedDelegation] = useState(null);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [errors, setErrors] = useState({});
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [userPage, setUserPage] = useState(1);
    const [preferences, setPreferences] = useState([]);
    const [processes, setProcesses] = useState([]);
    const [selectedPreferences, setSelectedPreferences] = useState(null)
    const [selectedProcesses, setSelectedProcesses] = useState(null)
    const [formData, setFormData] = useState({
        address: '',
        country: '',
        delegation: '',
        city: '',
        name: '',
        logo: null,
        description: '',
        website: '',
        phone: '',
        email: ''
    });
    const [logo, setLogo] = useState([
        {src: avatarImg}
    ]);

    const query = useQuery();
    const navigate = useNavigate();
    const {id} = useParams();
    const {
        setConfig
    } = useAppContext()

    const getCompany = async () => {
        setLoading(true)
        await api.get(`/company/${id}/`)
            .then(res => {
                setFormData({
                    ...res?.data,
                    sponsorship_preferences: res?.data?.sponsorship_preferences_data?.map(p => p?.id),
                    approval_process: res?.data?.approval_process_data?.map(p => p?.id),
                });
                setLogo([{src: res?.data?.logo_url}]);
                setSelectedCountry({
                    value: res?.data?.country,
                    label: res?.data?.country_name
                });
                setSelectedDelegation({
                    value: res?.data?.delegation,
                    label: res?.data?.delegation_name
                });
                setSelectedCity({
                    value: res?.data?.city,
                    label: res?.data?.city_name
                })
                setSelectedUser({
                    value: res?.data?.contact_person,
                    label: res?.data?.full_name
                })
                setSelectedPreferences(res?.data?.sponsorship_preferences_data?.map(p => ({
                    value: p?.id,
                    label: p?.name
                })))
                setSelectedProcesses(res?.data?.approval_process_data?.map(p => ({
                    value: p?.id,
                    label: p?.name
                })))
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    }

    const getCurrencies = async () => {
        return await api.get('/currency/');
    };

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageCountry.toString());
        getCountries(query)
            .then(res => setCountries([...countries, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageCountry]);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageDelegation.toString());
        getDelegations(query)
            .then(res => setDelegations([...delegations, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageDelegation]);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageCity.toString());
        getCities(query)
            .then(res => setCities([...cities, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageCity]);

    useEffect(() => {
        setLoading(true)
        query.set("page", userPage.toString())
        getUsers(query)
            .then(res => setUsers([...users, ...res?.results]))
            .catch(() => {
            })
        setLoading(false)
        // eslint-disable-next-line
    }, [userPage]);

    useEffect(() => {
        const fetchOptions = async () => {
            const preferencesResponse = await api.get('/company/sponsorship-preferences/');
            const processResponse = await api.get('/company/approval-process/');
            setPreferences(preferencesResponse.data.results);
            setProcesses(processResponse.data.results);
        };
        fetchOptions();
    }, []);

    useEffect(() => {
        getCurrencies()
            .then(res => setCurrencies(res?.data?.results))
            .catch(() => {
            });
    }, []);

    useEffect(() => {
        if (id) getCompany();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (logo[0].base64) {
            const block = logo[0].base64.split(";");
            // Get the content type of the image
            const contentType = block[0].split(":")[1];
            // get the real base64 content of the file
            const realData = block[1].split(",")[1];

            // Convert it to a blob to upload
            const blob = b64toBlob(realData, contentType);

            // Create a SponsoringFile object
            const file = new File([blob], logo[0].path, {
                type: logo[0].type,
            });
            setFormData({...formData, logo: file})
        }
    }, [formData, logo]);

    let countryOptions = countries?.map(country => ({
        value: country.id,
        label: country.country
    }));

    countryOptions = [
        {value: '', label: '----------------------------'},
        ...countryOptions
    ];

    let delegationOptions = delegations?.map(state => ({
        value: state.id,
        label: state.name
    }));

    delegationOptions = [
        {value: '', label: '----------------------------'},
        ...delegationOptions
    ];

    let cityOptions = cities?.map(city => ({value: city.id, label: city.name}));

    cityOptions = [
        {value: '', label: '----------------------------'},
        ...cityOptions
    ];

    let userOptions = [
        {value: '', label: '--------------------'},
        ...users?.map(user => ({value: user.id, label: user.full_name}))
    ];

    let preferenceOptions = [
        ...preferences?.map(p => ({value: p.id, label: p.name}))
    ];

    let processOptions = [
        ...processes?.map(p => ({value: p.id, label: p.name}))
    ];

    const handleSubmit = async (e, values) => {
        setLoading(true);
        e.preventDefault();
        if (id) {
            await api
                .patch(`/company/${id}/`, values)
                .then(res => {
                    toast.success(`Company successfully updated.`, {theme: 'colored'});
                    navigate(paths.companies);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(`An error has occurred.`, {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        } else {
            await api
                .post(`/company/`, values)
                .then(res => {
                    toast.success(`Company successfully created.`, {theme: 'colored'});
                    navigate(paths.companies);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(`An error has occurred.`, {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        }
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <Form>
                        <CSRFToken/>
                        <Row className="g-3">
                            <Col lg={6}>
                                <div className={"sticky-sidebar"}>
                                    <Card>
                                        <Card.Header>
                                            <h5>Basic information</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Row className="mb-3">
                                                    <Col md="auto">
                                                        <Avatar
                                                            size="4xl"
                                                            src={
                                                                isIterableArray(logo)
                                                                    ? logo[0]?.base64 || logo[0]?.src
                                                                    : ''
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md>
                                                        <Field name="avatar">
                                                            {() => (
                                                                <FalconDropzone
                                                                    files={logo}
                                                                    onChange={files => {
                                                                        setLogo(files);
                                                                        setFieldValue('logo', files[0]);
                                                                    }}
                                                                    multiple={false}
                                                                    accept="image/*"
                                                                    placeholder={
                                                                        <>
                                                                            <Flex justifyContent="center">
                                                                                <img
                                                                                    src={cloudUpload}
                                                                                    alt=""
                                                                                    width={25}
                                                                                    className="me-2"
                                                                                />
                                                                                <p className="fs-0 mb-0 text-700">
                                                                                    Upload your logo
                                                                                </p>
                                                                            </Flex>
                                                                            <p className="mb-0 w-75 mx-auto text-400">
                                                                                Upload a 300x300 jpg image with
                                                                                a maximum size of 400KB
                                                                            </p>
                                                                        </>
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors.logo}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group>
                                                        <Form.Label>Name <span
                                                            className={"text-danger"}>*</span></Form.Label>
                                                        <Field name={"name"}>
                                                            {({field}) => (
                                                                <Form.Control
                                                                    name={"name"}
                                                                    type={'text'}
                                                                    placeholder={'Name'}
                                                                    value={field.value}
                                                                    onChange={e => {
                                                                        setFieldValue(field.name, e.target.value);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors?.name}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group>
                                                        <Form.Label>Website</Form.Label>
                                                        <Field name={"website"}>
                                                            {({field}) => (
                                                                <Form.Control
                                                                    name={"website"}
                                                                    type={'url'}
                                                                    placeholder={'Website'}
                                                                    value={field.value}
                                                                    onChange={e => {
                                                                        setFieldValue(field.name, e.target.value);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors?.website}/>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className={"mt-3"}>
                                                <Form.Group>
                                                    <Form.Label>Description <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name="description">
                                                        {({field}) => (
                                                            <TinymceEditor
                                                                value={field.value}
                                                                handleChange={newValue => setFieldValue(field.name, newValue)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.description}/>
                                                </Form.Group>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>Contact information</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Email</Form.Label>
                                                    <Field name={"email"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                name={"email"}
                                                                type={'text'}
                                                                placeholder={'Email'}
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.email}/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Phone</Form.Label>
                                                    <Field name={"phone"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                name={"phone"}
                                                                type={'number'}
                                                                placeholder={'Phone'}
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.phone}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>Address information</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Label>Address</Form.Label>
                                            <Field name={'address'}>
                                                {({field}) => (
                                                    <Form.Control
                                                        name={"address"}
                                                        type={'text'}
                                                        placeholder={'Address'}
                                                        value={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.address}/>
                                        </Form.Group>
                                        <Row>
                                            <Col lg={4}>
                                                <Form.Group>
                                                    <Form.Label>Country</Form.Label>
                                                    <Field name={'country'}>
                                                        {({field}) => (
                                                            <Select
                                                                options={countryOptions}
                                                                name={'country'}
                                                                classNamePrefix={'react-select'}
                                                                value={selectedCountry}
                                                                placeholder={'Select country...'}
                                                                onChange={value => {
                                                                    setSelectedCountry(value);
                                                                    setFieldValue(field.name, value.value);
                                                                    query.set('country_id', value.value);
                                                                    getDelegations(query).then(r =>
                                                                        setDelegations(r?.results)
                                                                    );
                                                                }}
                                                                onMenuScrollToBottom={() => {
                                                                    setPageCountry(prevState => prevState + 1);
                                                                }}
                                                                onInputChange={newValue => {
                                                                    query.set('search', newValue);
                                                                    getCountries(query).then(r => setCountries(r?.results));
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.country}/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Group>
                                                    <Form.Label>State:</Form.Label>
                                                    <Field name={'delegation'}>
                                                        {({field}) => (
                                                            <Select
                                                                options={delegationOptions}
                                                                value={selectedDelegation}
                                                                name={'delegation'}
                                                                classNamePrefix="react-select"
                                                                onChange={value => {
                                                                    setSelectedDelegation(value);
                                                                    setFieldValue(field.name, value.value);
                                                                    query.set('state_id', value.value);
                                                                    getCities(query).then(r => setCities(r?.results));
                                                                }}
                                                                placeholder={'Select state'}
                                                                onMenuScrollToBottom={() => {
                                                                    setPageDelegation(page => page + 1);
                                                                }}
                                                                onInputChange={e => {
                                                                    query.set('search', e);
                                                                    getDelegations(query).then(r =>
                                                                        setDelegations(r?.results)
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.delegation}/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Group>
                                                    <Form.Label>City:</Form.Label>
                                                    <Field name={'city'}>
                                                        {({field}) => (
                                                            <Select
                                                                options={cityOptions}
                                                                value={selectedCity}
                                                                name={'city'}
                                                                classNamePrefix="react-select"
                                                                onChange={value => {
                                                                    setSelectedCity(value);
                                                                    setFieldValue(field.name, value.value);
                                                                }}
                                                                placeholder={'Select city'}
                                                                onMenuScrollToBottom={() => {
                                                                    setPageCity(page => page + 1);
                                                                }}
                                                                onInputChange={e => {
                                                                    query.set('search', e);
                                                                    getCities(query).then(r => setCities(r?.results));
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.city}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>Sponsoring Budget</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Total Sponsoring Budget</Form.Label>
                                                    <Field name={'total_sponsoring_budget'}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                type={"number"}
                                                                value={field.value}
                                                                step="0.01"
                                                                placeholder={"Total Sponsoring Budget"}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value)
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.total_sponsoring_budget}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Allocated Budget</Form.Label>
                                                    <Field name={'allocated_budget'}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                type={"number"}
                                                                value={field.value}
                                                                step="0.01"
                                                                placeholder={"Allocated Budget"}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value)
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.allocated_budget}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group>
                                            <Form.Label>Currency</Form.Label>
                                            <Field name={'currency'}>
                                                {({field}) => (
                                                    <Form.Select
                                                        value={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.value);
                                                        }}
                                                    >
                                                        <option value={''}>Select currency</option>
                                                        {currencies?.map(currency => (
                                                            <option value={currency.code}>
                                                                {currency.name} - {currency.symbol}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                )}
                                            </Field>
                                            <FormError error={errors.currency}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>Sponsorship Settings</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Contact Person for Sponsorship:</Form.Label>
                                                    <Field name={'contact_person'}>
                                                        {({field}) => (
                                                            <Select
                                                                options={userOptions}
                                                                value={selectedUser}
                                                                name={'contact_person'}
                                                                classNamePrefix="react-select"
                                                                onChange={value => {
                                                                    setSelectedUser(value);
                                                                    setFieldValue(field.name, value.value);
                                                                }}
                                                                placeholder={'Select user'}
                                                                onMenuScrollToBottom={() => {
                                                                    setUserPage(page => page + 1);
                                                                }}
                                                                onInputChange={e => {
                                                                    query.set('search', e);
                                                                    getUsers(query).then(r => setUsers(r?.results));
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.contact_person}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Sponsorship Policy Document</Form.Label>
                                                    <Field name={'policy_document'}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                type={"file"}
                                                                onChange={e => {
                                                                    const file = e.target.files[0];
                                                                    if (file) {
                                                                        const reader = new FileReader();
                                                                        reader.onloadend = () => {
                                                                            setFieldValue("policy_document", reader.result);
                                                                        };
                                                                        reader.readAsDataURL(file);  // Read the file as Data URL to get the base64 encoded string
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.policy_document}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group>
                                            <Form.Label>Sponsorship Preferences:</Form.Label>
                                            <Field name={'sponsorship_preferences'}>
                                                {({field}) => (
                                                    <Select
                                                        options={preferenceOptions}
                                                        value={selectedPreferences}
                                                        name={'sponsorship_preferences'}
                                                        isMulti
                                                        classNamePrefix="react-select"
                                                        onChange={value => {
                                                            setSelectedPreferences(value);
                                                            setFieldValue(field.name, value?.map(v => v.value));
                                                        }}
                                                        placeholder={'Select preferences'}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors.sponsorship_preferences}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Sponsorship Approval Process:</Form.Label>
                                            <Field name={'approval_process'}>
                                                {({field}) => (
                                                    <Select
                                                        options={processOptions}
                                                        value={selectedProcesses}
                                                        name={'approval_process'}
                                                        isMulti
                                                        classNamePrefix="react-select"
                                                        onChange={value => {
                                                            setSelectedProcesses(value);
                                                            setFieldValue(field.name, value?.map(v => v.value));
                                                        }}
                                                        placeholder={'Select processes'}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors.approval_process}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card>
                                    <Card.Footer>
                                        <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                            <p className={"text-danger"}>* Mandatory field</p>
                                            <IconButton
                                                icon={faSave}
                                                onClick={e => handleSubmit(e, values)}
                                            >
                        <span className="d-none d-sm-inline-block ms-1">
                          Save
                        </span>
                                            </IconButton>
                                        </Flex>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default withPermission(CompaniesAddEdit, "company.add_company")