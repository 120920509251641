import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import RealTimeUsers from './real-time-users/RealTimeUsers';
import Audience from './audience/Audience';
import ConnectCard from './ConnectCard';
import EventsPerfomance from './events-perfomance/EventsPerfomance';
import SponsorContributions from './sponsor-contributions/SponsorContributions';
import TopicPopularity from './topic-popularity/TopicPopularity';
import Stats from './stats/Stats';
import EventTrends from './event-trends/EventTrends';
import {withPermission} from '../../../helpers/utils';
import JoiningRequests from './joining-requests/JoiningRequests';
import { useAppContext } from '../../../providers/AppProvider';
import paths from "../../../routes/paths";

const Analytics = () => {
  const {
    config: { group }
  } = useAppContext();
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={8}>
          <Audience group={group} className="mb-3" />
          <ConnectCard />
        </Col>
        <Col md={12} xxl={4}>
          <RealTimeUsers />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7}>
          <Row className="align-items-stretch h-100">
            <Col xs={12} className="mb-3">
              <JoiningRequests className="mb-3 h-100" />
            </Col>
            <Col xs={12}>
              <Stats />
            </Col>
          </Row>
        </Col>
        <Col lg={5}>
          <EventsPerfomance />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={12} xxl={12}>
          <EventTrends />
        </Col>
      </Row>

      <Row className="g-3">
        <Col lg={5} xxl={6}>
          <SponsorContributions />
        </Col>
        <Col lg={7} xxl={6}>
          <TopicPopularity />
        </Col>
      </Row>
    </>
  );
};

Analytics.propTypes = {};

export default withPermission(Analytics, "analytics.view_analytics");
