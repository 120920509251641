import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Spinner} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import SoftBadge from "../../../components/common/SoftBadge";
import {api} from "../../../utils/api";
import Flex from "../../../components/common/Flex";

const SaasConversion = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const getData = async () => {
        setLoading(true)
        await api.get("/finance/newmembers/").then(res => setData(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <Card className="h-100">
            <Card.Body>
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <Row className="flex-between-center">
                        <Col className="d-md-flex d-lg-block flex-between-center">
                            <h6 className="mb-md-0 mb-lg-2">New Members</h6>
                            <SoftBadge bg="primary" pill>
                                <FontAwesomeIcon icon={`caret-${data?.percent > 0 ? 'up' : 'down'}`}/> {data?.percent}%
                            </SoftBadge>
                        </Col>
                        <Col xs="auto">
                            <h4 className="fs-3 fw-normal text-primary">
                                <CountUp
                                    start={0}
                                    end={data?.value}
                                    duration={2.75}
                                />
                            </h4>
                        </Col>
                    </Row>
                )}
            </Card.Body>
        </Card>
    );
};

export default SaasConversion;
