import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useState } from 'react';
import { Button, Card, Collapse } from 'react-bootstrap';
import EducationItem from '../EducationItem';
import EducationForm from './EducationForm';
import { useNavigate } from 'react-router-dom';
import paths from "../../../routes/paths";

const EducationSettings = ({ educationData, getUser }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  return (
    <Card className="mt-3">
      <FalconCardHeader
        title="Education"
        endEl={
          <Button
            onClick={() => navigate(paths.userEducations)}
            size="sm"
            variant="link"
            className="p-0"
          >
            Show All
          </Button>
        }
      />
      <Card.Body className="fs--1 bg-light">
        <div>
          <Flex
            alignItems="center"
            className="mb-4 text-primary cursor-pointer fs-0"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <span className="circle-dashed">
              <FontAwesomeIcon icon="plus" />
            </span>
            <span className="ms-3">Add new education</span>
          </Flex>
          <Collapse in={collapsed}>
            <div>
              <EducationForm getUser={getUser} />
              <div className="border-dashed border-bottom my-3" />
            </div>
          </Collapse>
        </div>
        {educationData?.slice(0, 3)?.map((item, index) => (
          <EducationItem key={item.id} details={item} isLast={index === 2} />
        ))}
      </Card.Body>
    </Card>
  );
};

export default EducationSettings;
