import React, {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field} from "formik";
import DatePicker from 'react-datepicker';
import CustomDateInput from "../../../../components/common/CustomDateInput";
import TinymceEditor from "../../../../components/common/TinymceEditor";
import FormError from "../../../errors/FormError";

const OrganizerResponsibilityItem = ({
                                         index,
                                         title,
                                         value,
                                         handleRemove,
                                         setFieldValue,
                                         values,
                                         errors,
                                         t
                                     }) => {
    const [startDate, setStartDate] = useState(values?.responsibilities[index]?.start ? new Date(values?.responsibilities[index]?.start) : null)
    const [endDate, setEndDate] = useState(values?.responsibilities[index]?.end ? new Date(values?.responsibilities[index]?.end) : null)
    const [deadline, setDeadline] = useState(values?.responsibilities[index]?.deadline ? new Date(values?.responsibilities[index]?.deadline) : null)

    return (
        <div className="bg-white border dark__bg-100 p-3 position-relative rounded-1 mb-2">
            <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
                <Button
                    size="sm"
                    variant="link"
                    className="p-0"
                    onClick={() => handleRemove(index)}
                >
                    <FontAwesomeIcon className="text-danger" icon="times-circle"/>
                </Button>
            </div>

            <Row className="gx-2 gy-3">
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Start Date <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`responsibilities.${index}.start`}>
                            {({field}) => (
                                <Row>
                                    <DatePicker
                                        selected={startDate}
                                        defaultValue=""
                                        onChange={newDate => {
                                            setStartDate(newDate)
                                            setFieldValue(field.name, new Date(newDate).toISOString())
                                        }}
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        fixedHeight
                                        className='form-control'
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'MM/dd/yyyy h:mm aa',
                                                }}
                                            />
                                        }
                                    />
                                </Row>
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>End Date <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`responsibilities.${index}.end`}>
                            {({field}) => (
                                <Row>
                                    <DatePicker
                                        selected={endDate}
                                        defaultValue=""
                                        onChange={newDate => {
                                            setEndDate(newDate)
                                            setFieldValue(field.name, new Date(newDate).toISOString())
                                        }}
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        fixedHeight
                                        className='form-control'
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'MM/dd/yyyy h:mm aa',
                                                }}
                                            />
                                        }
                                    />
                                </Row>
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Deadline <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`responsibilities.${index}.deadline`}>
                            {({field}) => (
                                <Row>
                                    <DatePicker
                                        selected={deadline}
                                        defaultValue=""
                                        onChange={newDate => {
                                            setDeadline(newDate)
                                            setFieldValue(field.name, new Date(newDate).toISOString())
                                        }}
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        fixedHeight
                                        className='form-control'
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'MM/dd/yyyy h:mm aa',
                                                }}
                                            />
                                        }
                                    />
                                </Row>
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Priority <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`responsibilities.${index}.priority`}>
                            {({field}) => (
                                <Form.Select value={field.value}
                                             onChange={(e) => setFieldValue(field.name, e.target.value)}>
                                    <option value={""}>---------------------------</option>
                                    <option value={"1"}>Low</option>
                                    <option value={"2"}>Medium</option>
                                    <option value={"3"}>High</option>
                                </Form.Select>
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Description <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`responsibilities.${index}.description`}>
                            {({field}) => (
                                <TinymceEditor
                                    value={field.value}
                                    handleChange={newValue => setFieldValue(field.name, newValue)}
                                    height={"450"}
                                />
                            )}
                        </Field>
                        <FormError error={errors?.description}/>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    )
}

export default OrganizerResponsibilityItem