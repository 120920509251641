import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import eventBanner from 'assets/img/generic/13.jpg';
import React, {useState} from 'react';
import {Card, Form, Image} from 'react-bootstrap';
import FormError from "../../errors/FormError";

const EventBanner = ({values, setFieldValue, errors, t}) => {
    const [cover, setCover] = useState(values?.cover_url ? values?.cover_url : eventBanner);  // Start with default eventBanner

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCover(reader.result);  // Set the base64 encoded string to show the preview
                setFieldValue("cover", reader.result);
            };
            reader.readAsDataURL(file);  // Read the file as Data URL to get the base64 encoded string
        }
    };


    return (
        <Card className="cover-image">
            <Image src={cover} alt="..." className="card-img-top"/>
            <Form.Group controlId="formFile">
                <Form.Label className="cover-image-file-input">
                    <FontAwesomeIcon icon="camera" className="me-2"/>
                    <span>{t('fields.cover')} <span className={"text-danger"}>*</span></span>
                </Form.Label>
                <Form.Control type="file" className="d-none" accept={"image/*"}
                              onChange={handleImageChange}/>
            </Form.Group>
            <FormError error={errors.cover} />
        </Card>
    );
};

export default EventBanner;
