import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CardDropdown from '../../../../components/common/CardDropdown';
import {Card, Dropdown, Spinner} from 'react-bootstrap';
import AdvanceTable from '../../../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../../../components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../../../components/common/advance-table/AdvanceTableWrapper';
import useQuery from '../../../../hooks/useQuery';
import Flex from '../../../../components/common/Flex';
import Avatar from '../../../../components/common/Avatar';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {api} from '../../../../utils/api';
import {debounce} from 'chart.js/helpers';
import {getSponsors} from '../../actions/Sponsor';
import {formatDateTime, withPermission} from '../../../../helpers/utils';
import {useAppContext} from '../../../../providers/AppProvider';
import paths from "../../../../routes/paths";
import {usePermissions} from "../../../../providers/PermissionsProvider";
import BaseTableHeader from "../../../BaseTableHeader";

const Sponsors = () => {
	const [sponsors, setSponsors] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

	const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();
	const {hasPermission} = usePermissions()

	const navigate = useNavigate();

	const fetchSponsors = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);

		// Cancel the previous request if it exists
		getSponsors(q)
			.then(res => {
				setSponsors(res?.results);
				setCount(res.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLength(sponsors.length);
		setLoading(false);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchSponsors, 600), []);

	useEffect(() => {
		fetchSponsors(query);
		setLength(sponsors.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

	const columns = [
		{
			accessor: 'company',
			Header: 'Company',
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'company' && sortingDirection,
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const {company_name, company_logo, company} = rowData.row.original;
				return (
					<>
						<Link to={`/management/companies/${company}/edit`}>
							<Flex alignItems="center">
								{company_logo ? (
									<Avatar
										src={company_logo}
										rounded={'circle'}
										size="4xl"
										className="me-2"
									/>
								) : (
									<Avatar
										size="4xl"
										name={company_name}
										rounded={'circle'}
										className="me-2"
									/>
								)}
								<div className="flex-1">
									<h6
										className="mb-0 fs-0"
										style={{
											width: '200px',
											wordWrap: 'break-word',
											wordBreak: 'break-all',
											whiteSpace: 'pre-wrap'
										}}
									>
										{company_name}
									</h6>
								</div>
							</Flex>
						</Link>
					</>
				);
			}
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'event',
			Header: 'Event',
			canSort: true,
			sortingDirection: sortingField === 'event' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {event_title, event} = rowData.row.original;
				return (
					<>
						<Link to={`/events/${event}/detail`}>
							<Flex alignItems="center">
								<div className="flex-1">
									<h6
										className="mb-0 fs-0"
										style={{
											width: '200px',
											wordWrap: 'break-word',
											wordBreak: 'break-all',
											whiteSpace: 'pre-wrap'
										}}
									>
										{event_title}
									</h6>
								</div>
							</Flex>
						</Link>
					</>
				);
			}
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'level',
			Header: 'Level',
			canSort: true,
			sortingDirection: sortingField === 'level' && sortingDirection,
			isSorted: false
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'type',
			Header: 'Type',
			canSort: true,
			sortingDirection: sortingField === 'type' && sortingDirection,
			isSorted: false
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'start_date',
			Header: 'Start',
			canSort: true,
			sortingDirection: sortingField === 'start_date' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {start_date} = rowData.row.original;
				return <>{formatDateTime(start_date)}</>;
			}
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'end_date',
			Header: 'End',
			canSort: true,
			sortingDirection: sortingField === 'end_date' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {end_date} = rowData.row.original;
				return <>{formatDateTime(end_date)}</>;
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {id, company_name} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: 'Are you sure?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, delete it!'
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/sponsor/sponsor/${id}/`).then(() => {
							toast.success(`Sponsor ${company_name} successfully deleted.`, {
								theme: 'colored'
							});
							getSponsors(query)
								.then(res => {
									setSponsors(res?.results);
									setCount(res.data?.count);
								})
								.catch(() => {
								});
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("sponsor.view_sponsor") &&
								<Dropdown.Item onClick={() => navigate(paths.detailSponsors.replace(":id", id))}>
									Detail
								</Dropdown.Item>
							}
							{hasPermission("sponsor.change_sponsor") &&
								<Dropdown.Item onClick={() => navigate(paths.editSponsors.replace(":id", id))}>
									Edit
								</Dropdown.Item>
							}
							<Dropdown.Divider as="div"/>
							{hasPermission("sponsor.delete_sponsor") &&
								<Dropdown.Item
									onClick={e => handleDelete(e)}
									className="text-danger"
								>
									Delete
								</Dropdown.Item>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	];

	const sponsorList = sponsors.map(sponsor => ({
		id: sponsor.id,
		company_logo: sponsor.company_logo,
		company_name: sponsor.company_name,
		activity_logo: sponsor.activity_logo,
		activity_name: sponsor.activity_name,
		event_title: sponsor.event_title,
		company: sponsor.company,
		activity: sponsor.activity,
		event: sponsor.event,
		level: sponsor.level_display,
		type: sponsor.type_display,
		start_date: sponsor.start_date,
		end_date: sponsor.end_date
	}));

	const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={sponsorList}
			selection
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"sponsor.add_sponsor"}
						creationPath={paths.createSponsors}
						title={"sponsor"}
						deletePermission={"sponsor.delete_sponsor"}
						exportPermission={"sponsor.add_sponsorexportfile"}
						data={sponsorList}
						setLoading={setLoading}
						deleteLink={"/sponsor/delete/sponsor/"}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchSponsors}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
};

export default withPermission(Sponsors, "sponsor.view_sponsor")