import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTicketAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import SimpleBarReact from "simplebar-react";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Flex from "../../../components/common/Flex";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {useAppContext} from "../../../providers/AppProvider";
import CSRFToken from "../../../helpers/CSRFToken";
import {Field, Formik} from "formik";
import FormError from "../../errors/FormError";
import EventCustomFieldItem from "./EventCustomFieldItem";
import FormErrors from "../../errors/FormErrors";

const EventRegistrationModal = ({open, setOpen, ticket, getEvent, registration}) => {
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState({})

	const {id} = useParams()

	const [formData, setFormData] = useState({
		event: id,
		option: [],
		answers: [],
		menus: []
	})

	const {
		config: {isDark}
	} = useAppContext();

	const handleChange = (e, type, name, id) => {
		switch (type) {

			default:
				setFormData({
					...formData,
					answers: [
						...formData.answers,
						{
							answer: e.target.value.toString(),
							question: id,
						}
					]
				})
		}
	}

	const handleRegister = async (e) => {
		setLoading(true)
		await api.post("/event/register/", formData)
			.then(res => {
				toast.success("You have successfully registered to this event.", {theme: "colored"})
				setOpen(!open)
				getEvent()
			})
			.catch(error => {
				setFormData(formData)
				toast.error("An error has occurred.", {theme: "colored"})
				setErrors(error?.response?.data)
			})
		setLoading(false)
	}

	const removeDuplicatesAndSetLastValues = (arr) => {
		const map = {};
		arr.forEach(item => {
			map[item.question] = item;
		});
		return Object.values(map);
	};

	useEffect(() => {
		setFormData({
			...formData,
			answers: registration?.questions_data?.map(r => ({question: r?.id, answer: ""}))
		})
		// eslint-disable-next-line
	}, [registration?.questions_data]);

	useEffect(() => {
		setFormData({
			...formData,
			answers: removeDuplicatesAndSetLastValues(formData.answers)
		})
	}, [formData]);

	return (
		<Modal centered size={"xl"} fullscreen={registration?.type === "external"} show={open} onHide={() => setOpen(!open)}>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-x1 border-bottom-0"
			>
				<Flex justifyContent={"center"} alignItems={"center"}>
					<div>
						<h5>
							<FontAwesomeIcon
								icon={faTicketAlt}
								className={classNames("me-2", {
									"text-warning": ticket?.type === "paid",
									"text-primary": ticket?.type === "free",
									"text-success": ticket?.type === "donation",
								})}
							/>
							{ticket?.type_display}
						</h5>
					</div>
					<SoftBadge pill bg={ticket?.variable ? "primary" : "success"} className={"ms-2"}>
						{ticket?.variable ? "Variable" : "Not variable"}
					</SoftBadge>
				</Flex>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<Formik initialValues={formData} onSubmit={values => console.log(values)}>
						{({values, setFieldValue}) => (
							<>
								<div className="mt-4 fs-10">
									<SimpleBarReact>
										<Table striped className="border-bottom">
											<thead data-bs-theme="light">
											<tr className="bg-primary dark__bg-1000">
												<th className="text-white border-0 text-end"></th>
												<th className="text-white border-0">Option Name</th>
												<th className="text-white border-0 text-center">Price</th>
												<th className="text-white border-0 text-center">Default</th>
											</tr>
											</thead>
											<tbody>
											{ticket?.option_set?.map((option, index) => (
												<tr key={index}>
													<td className="align-middle text-center">
														<Field name={`option.${index}`}>
															{({field}) => (
																<Form.Switch
																	type="checkbox"
																	name={`option.${index}`}
																	value={option?.id}
																	checked={formData.option.includes(option?.id)}
																	onChange={(e) => {
																		if (e.target.checked)
																			setFormData({
																				...formData,
																				option: [...formData.option, parseInt(e.target.value)]
																			})
																		else
																			setFormData({
																				...formData,
																				option: formData.option.filter(o => o !== parseInt(e.target.value))
																			})
																	}}
																/>
															)}
														</Field>
													</td>
													<td className="align-middle">
														<h6 className="mb-0 text-nowrap">{option?.name}</h6>
														<p
															className="mb-0 fs--1">{option?.checked && "This is a default option and you must check it."}</p>
													</td>
													<td className="align-middle text-center">{option?.price} {option?.currency}</td>
													<td className="align-middle text-center">
														{option?.checked ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/>}
													</td>
												</tr>
											))}
											</tbody>
										</Table>
									</SimpleBarReact>
									<FormError error={errors?.option}/>
								</div>
								{registration?.type === "standard" ? (
									<Row>
										<Col lg={6}>
											<h6>Questions:</h6>
											<Form>
												<CSRFToken/>
												{registration?.questions_data?.map((question, index) => (
													<>
														<EventCustomFieldItem
															type={question?.type}
															name={question?.name}
															options={question?.options?.split(',')}
															id={question?.id}
															required={question?.required}
															index={index}
															onChange={(e) => handleChange(e, question?.type, question?.name, question?.id)}
														/>
														<FormErrors errors={errors?.answers} error={"answer"} index={index}/>
													</>
												))}
												<FormErrors errors={errors?.answers}/>
											</Form>
										</Col>
										<Col lg={6}>
											<h6>Menu:</h6>
											<Form>
												<CSRFToken/>
												{registration?.menus_data?.map((menu, index) => (
													<>
														<Form.Switch
															label={menu?.name}
															type="checkbox"
															value={menu?.id}
															className={"mb-2"}
															onChange={(e) => {
																if (e.target.checked)
																	setFormData({
																		...formData,
																		menus: [...formData.menus, {menu: e.target.value}]
																	})
																else
																	setFormData({
																		...formData,
																		menus: formData.menus.filter(o => o.menu !== e.target.value)
																	})
															}}
														/>
													</>
												))}
												<FormError error={errors?.menus}/>
											</Form>
										</Col>
									</Row>
								) : registration?.type === "external" ? (
									<>
										<p></p>
										<iframe title={registration?.type} src={registration?.url} width={"100%"} height={"100%"} />
									</>
								) : (
									<></>
								)}
							</>
						)}
					</Formik>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					as={Link}
					size="sm"
					onClick={handleRegister}
					variant="outline-primary"
				>
					Register
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default EventRegistrationModal;
