import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import JoiningRequestsChart from './JoiningRequestsChart';
import classNames from 'classnames';
import {api} from "../../../../utils/api";
import useQuery from "../../../../hooks/useQuery";
import Flex from "../../../../components/common/Flex";
import {useAppContext} from "../../../../providers/AppProvider";

const JoiningRequests = ({...rest}) => {
	const [stats, setStats] = useState([]);
	const [chatData, setChartData] = useState({})
	const [loading, setLoading] = useState(false)
	const [duration, setDuration] = useState("last_7_days")

	const {
		setConfig
	} = useAppContext()

	let query = useQuery()

	const getJoiningRequest = async () => {
		setLoading(true)
		query.set("time_range", duration)
		await api.get(`/analytics/joiningrequests/?${query.toString()}`).then(res => {
			setStats(res?.data?.stats)
			setChartData(res?.data?.chart)
		})
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		setLoading(false)
	}

	useEffect(() => {
		getJoiningRequest()
		// eslint-disable-next-line
	}, [duration]);

	return (
		<Card {...rest}>
			<FalconCardHeader
				title="Joining Requests"
				titleTag="h6"
				className="py-2"
				light
			/>
			<Card.Body>
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<Row>
						<Col md="auto" className="mt-md-0">
							<Row className="flex-md-column justify-content-between h-md-100 ms-0">
								{stats.map((stat, index) => (
									<Col
										key={stat.label}
										className={classNames('pt-3', {
											'border-end border-md-end-0 border-md-bottom': index !== 2,
											'pt-md-4': index !== 0
										})}
									>
										<h6 className="fs--2 text-7">
											<FontAwesomeIcon
												icon="circle"
												className={`text-${stat.color} me-2`}
											/>
											{stat.label}
										</h6>
										<h5 className="text-700 fs-0">
											{stat.value.toLocaleString()}
										</h5>
									</Col>
								))}
							</Row>
						</Col>
						<Col md="auto" className="echart-active-users-report-container">
							<JoiningRequestsChart data={chatData} duration={duration}/>
						</Col>
					</Row>
				)}
			</Card.Body>

			<Card.Footer className="bg-light py-2">
				<Row className="g-0 flex-between-center">
					<Col xs="auto">
						<Form.Select size="sm" className="me-2" value={duration} onChange={(e) => setDuration(e.target.value)}>
							<option value={"last_7_days"}>Last 7 days</option>
							<option value={"last_month"}>Last Month</option>
							<option value={"last_year"}>Last Year</option>
						</Form.Select>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	);
};

export default JoiningRequests;
