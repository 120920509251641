import envelope from "../../assets/img/icons/spot-illustrations/16.png";
import {Button} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import useQuery from "../../hooks/useQuery";

const VerifyMailContent = ({layout, titleTag: TitleTag, t, i18n}) => {
	const [email, setEmail] = useState("")

	let query = useQuery()
	const navigate = useNavigate()

	useEffect(() => {
		if (query.has("email")) {
			setEmail(query.get("email"))
			query.delete("email")
			navigate(`${query.toString()}`)
		}
		// eslint-disable-next-line
	}, [query]);

	useEffect(() => {
		if (!query.has("next")) query.set("next", "/")
	}, [query]);

	return (
		<>
			<img
				className="d-block mx-auto mb-4"
				src={envelope}
				alt="sent"
				width={100}
			/>
			<TitleTag>{t('verifyMail.title')}</TitleTag>
			<p>
				{t('verifyMail.mailSent')} <strong>{email}</strong>. {t('verifyMail.subtitle')}
			</p>
			<Button
				as={Link}
				color="primary"
				size="sm"
				className="mt-3"
				to={`/authentication/${layout}/login?${query.toString()}`}
			>
				<FontAwesomeIcon
					icon="chevron-left"
					transform="shrink-4 down-1"
					className="me-1"
				/>
				{t('verifyMail.loginReturn')}
			</Button>
		</>
	)
}

VerifyMailContent.propTypes = {
	email: PropTypes.string.isRequired,
	layout: PropTypes.string,
	titleTag: PropTypes.string
};

VerifyMailContent.defaultProps = {layout: 'simple', titleTag: 'h4'};

export default VerifyMailContent