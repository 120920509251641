import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import {formatDateTime, withPermission} from "../../../helpers/utils";
import Flex from "../../../components/common/Flex";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../../BaseTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import {useTranslation} from "react-i18next";
import {usePermissions} from "../../../providers/PermissionsProvider";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Rules = () => {
	const [rules, setRules] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

	const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();

	const {t} = useTranslation(["rule", "common"])
	const navigate = useNavigate();
	const {hasPermission} = usePermissions()

	const fetchRules = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);
		await api.get(`/rule/rule/?${q.toString()}`)
			.then(res => {
				setRules(res?.data?.results);
				setCount(res?.data?.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLoading(false);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchRules, 600), []);

	useEffect(() => {
		fetchRules(query);
		setLength(rules.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

	const columns = [
		{
			accessor: 'title',
			Header: t('fields.title'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'title' && sortingDirection,
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const {title, id} = rowData.row.original;
				return (
					<>
						<Link to={`/management/rule/${id}/detail`}>
							<Flex alignItems="center">
								<div className="flex-1">
									<h6
										className="mb-0 fs-0"
										style={{
											width: '200px',
											wordWrap: 'break-word',
											wordBreak: 'break-all',
											whiteSpace: 'pre-wrap'
										}}
									>
										{title}
									</h6>
								</div>
							</Flex>
						</Link>
					</>
				);
			}
		},
		{
			accessor: 'rule_type',
			Header: t('fields.type.title'),
			canSort: true,
			sortingDirection: sortingField === 'rule_type' && sortingDirection,
			isSorted: false,
			cellProps: {
				className: 'py-2'
			},
			headerProps: {className: 'pe-3'}
		},
		{
			accessor: 'status',
			Header: t('fields.status.title'),
			canSort: true,
			sortingDirection: sortingField === 'status' && sortingDirection,
			isSorted: false,
			cellProps: {
				className: 'py-2'
			},
			headerProps: {className: 'pe-3'},
			Cell: (rowData) => {
				const {status, status_display} = rowData.row.original;
				return (
					<>
						<SoftBadge
							pill
							bg={classNames({
								success: status === "active",
								secondary: status === "draft",
								danger: status === "archived",
							})}
							className="d-flex flex-center"
						>
							<p className="mb-0">{status_display}</p>
							<FontAwesomeIcon
								icon={classNames({
									check: status === "active",
									paperclip: status === "draft",
									ban: status === "archived",
								})}
								transform="shrink-2"
								className="ms-1"
							/>
						</SoftBadge>
					</>
				);
			},
		},
		{
			accessor: 'created_at',
			Header: t('fields.createdAt'),
			canSort: true,
			sortingDirection: sortingField === 'created_at' && sortingDirection,
			isSorted: false,
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => formatDateTime(rowData.row.original.created_at),
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {id, title} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: t('sure', {ns: "common"}),
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						cancelButtonText: t('cancel', {ns: "common"}),
						confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/rule/rule/${id}/`).then(() => {
							toast.success(`${t('title')} ${title} ${t('deleteSuccess', {ns: "common"})}`, {
								theme: 'colored'
							});
							fetchRules(query)
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("rule.change_rule") &&
								<Dropdown.Item onClick={() => navigate(`/management/rule/${id}/edit`)}>
									Edit
								</Dropdown.Item>
							}
							<Dropdown.Divider as="div"/>
							{hasPermission("rule.delete_rule") &&
								<Dropdown.Item
									onClick={e => handleDelete(e)}
									className="text-danger"
								>
									Delete
								</Dropdown.Item>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	];

	const ruleList = rules?.map(rule => ({
		id: rule.id,
		title: rule.title,
		activity: rule.activity,
		activity_name: rule.activity_name,
		rule_type: rule.rule_type_display,
		status: rule.status,
		status_display: rule.status_display,
		created_at: rule.created_at,
	}));

	const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={ruleList}
			selection
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"rule.add_rule"}
						creationPath={paths.ruleCreate}
						title={"rule"}
						deletePermission={"rule.delete_rule"}
						exportPermission={"rule.add_ruleexportfile"}
						setLoading={setLoading}
						data={ruleList}
						deleteLink={"/rule/delete/rule/"}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchRules}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
}

export default withPermission(Rules, "rule.view_rule")