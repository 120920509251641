import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {formatDateTime, withPermission} from "../../../helpers/utils";
import {Link, useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import IconButton from "../../../components/common/IconButton";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt, faChartPie,
    faExclamationTriangle,
    faReply, faThumbtack,
    faUpload,
    faUser, faUsers,
    faUserSecret
} from "@fortawesome/free-solid-svg-icons";
import SimpleBarReact from "simplebar-react";

const EnoticeDetail = () => {
    const [loading, setLoading] = useState(false);
    const [enotice, setEnotice] = useState({});
    const [data, setData] = useState({})

    const ws = useMemo(() => new WebSocket(window.location.protocol === "https:" ? "wss://api.nexameet.com/ws/update_enotice/" : "ws://localhost:8000/ws/update_enotice/"), []);
    const {id} = useParams();
    const navigate = useNavigate()
    const {hasPermission} = usePermissions()

    const getEnotice = async () => {
        setLoading(true);
        await api.get(`/enotice/enotice/${id}/`).then((res) => {
            setEnotice(res.data);
        })
            .catch(err => {
                if (err?.response.status === 404) {
                    navigate(paths.error404)
                }
            })
        setLoading(false)
    }

    useEffect(() => {
        getEnotice()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        ws.onmessage = function (event) {
            setData(JSON.parse(event.data));
        };
    }, [ws]);

    const resendEnotice = async (e) => {
        e.preventDefault();
        setLoading(true);
        await api.post(`/enotice/resend/enotice/`, {id: id})
            .then(res => {
                toast.success("Enotice successfully resent.", {theme: "colored"})
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setLoading(false)
    }

    const handleDelete = async e => {
        const {isConfirmed} = await Swal.fire({
            title: "Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, Delete it`,
            cancelButtonText: "Cancel"
        });
        if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/enotice/enotice/${id}/`).then(() => {
                toast.success(`Enotice successfully deleted.`, {
                    theme: 'colored'
                });
                navigate(paths.enotice)
            });
            setLoading(false);
        }
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header className="d-flex flex-between-center">
                    <Flex>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                        <h5 className={"ms-2"}>{enotice?.subject}</h5>
                    </Flex>
                    <Flex>
                        {enotice?.status !== "sent" &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="sync-alt"
                                transform="shrink-2"
                                iconAlign="middle"
                                className="ms-2"
                                onClick={resendEnotice}
                            >
                                <span className="d-none d-md-inline-block ms-1">Resend</span>
                            </IconButton>
                        }
                        {hasPermission('enotice.change_enotice') &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="edit"
                                transform="shrink-2"
                                iconAlign="middle"
                                className="ms-2 d-none d-sm-block"
                                onClick={() => navigate(paths.enoticeEdit.replace(":id", id))}
                            >
                                <span className="d-none d-md-inline-block ms-1">Edit</span>
                            </IconButton>
                        }
                        {hasPermission('enotice.delete_enotice') &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="trash-alt"
                                transform="shrink-2"
                                iconAlign="middle"
                                className="ms-2 d-none d-sm-block"
                                onClick={handleDelete}
                            >
                                <span className="d-none d-md-inline-block ms-1">Delete</span>
                            </IconButton>
                        }
                    </Flex>
                </Card.Header>
            </Card>
            <Row>
                <Col>
                    <Card className={"mt-3"}>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <FontAwesomeIcon icon={faUser}/>
                                    <span
                                        className={"ms-2"}>From: {enotice?.from_data?.from_name} {`<${enotice?.from_data?.from_email}>`}</span>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                                    <span
                                        className={"ms-2"}>Priority: {enotice?.priority_display}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FontAwesomeIcon icon={faReply}/>
                                    <span
                                        className={"ms-2"}>Reply To: {enotice?.from_data?.reply_to_name} {`<${enotice?.from_data?.reply_to_email}>`}</span>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                    <span
                                        className={"ms-2"}>Send By: {formatDateTime(enotice?.send_by)}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FontAwesomeIcon icon={faUserSecret}/>
                                    <span
                                        className={"ms-2"}>Private: {enotice?.private ? "Yes" : "No"}</span>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faThumbtack}/>
                                    <span
                                        className={"ms-2"}>Reminder By: {enotice?.reminder_by ? formatDateTime(enotice?.reminder_by) : "N/A"}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FontAwesomeIcon icon={faUpload}/>
                                    <span
                                        className={"ms-2"}>Submitter: <Link
                                        to={paths.usersDetail.replace(":id", enotice?.user)}>{enotice?.user_fullname}</Link></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span>Created: {formatDateTime(enotice?.created_at)}</span>
                                </Col>
                                <Col>
                                    <span>Updated: {formatDateTime(enotice?.updated_at)}</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5><FontAwesomeIcon icon={faUsers} className={"me-2"}/> Recipients</h5>
                        </Card.Header>
                        <Card.Body>
                            <ul>
                                <li>{enotice?.receipient_data?.unit_name}</li>
                            </ul>
                            <strong>{enotice?.receipient_data?.type_display}</strong>
                            <ul>
                                <li>Active: {enotice?.receipient_data?.status?.user_active ? "Yes" : "No"}</li>
                                <li>Inactive: {enotice?.receipient_data?.status?.user_inactive ? "Yes" : "No"}</li>
                                <li>Paid: {enotice?.receipient_data?.status?.paid ? "Yes" : "No"}</li>
                                <li>Unpaid: {enotice?.receipient_data?.status?.unpaid ? "Yes" : "No"}</li>
                            </ul>
                            <strong>Memberships</strong>
                            <ul>
                                {enotice?.receipient_data?.membership_names?.map((membership) => (
                                    <li>{membership}</li>
                                ))}
                            </ul>
                            <SimpleBarReact>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {enotice?.recipients?.map(recipient => (
                                        <tr>
                                            <td>{recipient?.username}</td>
                                            <td><Link
                                                to={paths.usersDetail.replace(":id", recipient?.id)}>{recipient?.full_name}</Link>
                                            </td>
                                            <td>{recipient?.email}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5><FontAwesomeIcon icon={faChartPie} className={"me-2"}/> Statistics</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                <tbody>
                                <tr>
                                    <td>Sent</td>
                                    <td className={"text-end"}>{enotice?.recipients?.length}</td>
                                </tr>
                                <tr>
                                    <td>Delivered</td>
                                    <td className={"text-end"}>{enotice?.recipients?.length - enotice?.error_logs?.length}</td>
                                </tr>
                                <tr>
                                    <td>Started At</td>
                                    <td className={"text-end"}>{formatDateTime(data?.started_at ? data?.started_at : enotice?.started_at)}</td>
                                </tr>
                                <tr>
                                    <td>Finished At</td>
                                    <td className={"text-end"}>{formatDateTime(data?.finished_at ? data?.finished_at : enotice?.finished_at)}</td>
                                </tr>
                                <tr>
                                    <td>Duration</td>
                                    <td className={"text-end"}>{data?.duration ? data?.duration : enotice?.duration}s</td>
                                </tr>
                                <tr>
                                    <td>Percentage</td>
                                    <td className={"text-end"}>{data?.percentage ? data?.percentage : enotice?.percentage}%</td>
                                </tr>
                                <tr>
                                    <td>Attempts</td>
                                    <td className={"text-end"}>{enotice?.send_attempts}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td className={"text-end"}>{enotice?.status_display}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default withPermission(EnoticeDetail, "enotice.view_enotice")