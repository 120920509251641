import {Card} from "react-bootstrap";
import React from "react";
import {sanitizeHtml} from "../../../../helpers/utils";

const CodeOfConductCard = ({data, activity}) => {

	return (
		<Card>
			<Card.Header>
				<h5 className="mb-0 text-center">{data?.title}</h5>
				<h6 className={"text-center mt-3"}>{activity}</h6>
			</Card.Header>
			<Card.Body>
				<p>
					{sanitizeHtml(`
                            This Workplace Code of Conduct (“Code”) establishes the principles and expectations for professional conduct
                            and ethical behavior for ${data?.scope} of ${activity}. By adhering to this Code, ${data?.purpose}.
                        `)}
				</p>
				<ol>
					<li>
						<p className={"fw-semi-bold"}>Scope and applicability</p>
						<p>This Code applies to {data?.scope} of {data?.activity_name}, regardless of their position or
							location.</p>
						<p>Contractors and temporary staff are also expected to adhere to this Code while working for or on behalf
							of the company.</p>
					</li>
					<li>
						<p className={"fw-semi-bold"}>Core values and principles</p>
						{sanitizeHtml(data?.content)}
					</li>
					<li className={"mt-3"}>
						<p className={"fw-semi-bold"}>Disciplinary Actions</p>
						<p>
							{data?.scope} are expected to conduct themselves in a professional manner at all times. This includes
							{data?.enforcement?.actions?.map((action, index) => <> {action?.name} {index !== data?.enforcementprocedure?.disciplinaryactions?.length - 1 && ","}</>)}.
						</p>
					</li>
					<li className={"mt-3"}>
						<p className={"fw-semi-bold"}>Investigation Process</p>
						{data?.enforcement?.investigations?.map((action, index) => <p>{action?.name}</p>)}
					</li>
					<li className={"mt-3"}>
						<p className={"fw-semi-bold"}>Reporting mechanisms</p>
						{data?.enforcement?.reportings?.map((action, index) => <p>{action?.name}</p>)}
					</li>
					<li className={"mt-3"}>
						<p className={"fw-semi-bold"}>Related documents</p>
						{data?.document_set?.map((action, index) => action?.type === "link" ?
							<a href={action.link} key={index} target={"_blank"} rel={"noreferrer"}>{action.link}<br /></a> :
							<a href={action?.document_url} key={index} target={"_blank"} rel={"noreferrer"}>Document<br/></a>)}
					</li>
				</ol>
			</Card.Body>
		</Card>
	)
}

export default CodeOfConductCard