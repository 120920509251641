import React from 'react';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import paths from "../../../routes/paths";
import EventShareButtons from "./EventShareButtons";

const EventDetailContent = ({event, t}) => {
	return (
		<Card>
			<Card.Body>
				<p dangerouslySetInnerHTML={{
					__html: event?.description
				}}/>
				<h5 className="fs-0 mt-5 mb-2 ">{t('fields.other.tags')} </h5>
				{event?.tags?.map(tag => (
					<Link
						to={paths.events + `?tag=${tag}`}
						className="badge border link-secondary text-decoration-none me-1"
					>
						{tag}
					</Link>
				))}
				<h5 className="fs-0 mt-5 mb-2 ">{t('share')} </h5>
				<EventShareButtons event={event} />
			</Card.Body>
		</Card>
	);
};

export default EventDetailContent;
