import {Modal} from "react-bootstrap";
import React from "react";
import {useAppContext} from "../../../providers/AppProvider";
import EventDetailsGmap from "./EventDetailsGmap";

const ModalMap = ({open, setOpen, location}) => {
	const {
		config: {isDark}
	} = useAppContext();

	return (
		<Modal centered size={"xl"} show={open} onHide={() => setOpen(!open)}>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-x1 border-bottom-0"
			>
				<h5>
					Map
				</h5>
			</Modal.Header>
			<Modal.Body>
				<EventDetailsGmap location={location?.geometry?.location} address={location?.formatted_address} />
			</Modal.Body>
		</Modal>
	)
}

export default ModalMap