import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Experience from '../Experience';
import ExperienceForm from './ExperienceForm';
import { useNavigate } from 'react-router-dom';
import paths from "../../../routes/paths";

const ExperiencesSettings = ({ experiences, getUser }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  return (
    <Card className="mt-3">
      <FalconCardHeader
        title="Experiences"
        endEl={
          <Button
            onClick={() => navigate(paths.userExperiences)}
            size="sm"
            variant="link"
            className="p-0"
          >
            Show All
          </Button>
        }
      />
      <Card.Body className="fs--1 bg-light">
        <ExperienceForm
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          getUser={getUser}
        />
        {experiences?.slice(0, 3)?.map((experience, index) => (
          <Experience
            key={experience.id}
            experience={experience}
            isLast={index === 2}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default ExperiencesSettings;
