import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const GoverningLaw = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="law" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Governing Law</h5>
			</Card.Header>
			<Card.Body>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					This Privacy Policy is governed by the laws of Tunisia without regard to its conflict of laws provision. You
					consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between
					the
					parties under or in connection with this Privacy Policy except for those individuals who may have rights to
					make claims under Privacy Shield, or the Swiss-US framework.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					The laws of Tunisia, excluding its conflicts of law rules, shall govern this Agreement and your use of the
					website. Your use of the website may also be subject to other local, state, national, or international laws.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					By using nexameet or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not
					agree to this Privacy Policy, you should not engage with our website, or use our services. Continued use of
					the website, direct engagement with us, or following the posting of changes to this Privacy Policy that do not
					significantly affect the use or disclosure of your personal information will mean that you accept those
					changes.
				</p>
			</Card.Body>
		</Card>
	)
})

export default GoverningLaw