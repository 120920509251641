import React, {useEffect, useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import EventDetailAside from './EventDetailAside';
import EventDetailContent from './EventDetailContent';
import EventDetailHeader from './EventDetailHeader';
import {useNavigate, useParams} from 'react-router-dom';
import {api} from '../../../utils/api';
import {withPermission} from '../../../helpers/utils';
import paths from "../../../routes/paths";
import {useAppContext} from "../../../providers/AppProvider";
import EventDetailNotes from "./EventDetailNotes";
import Flex from "../../../components/common/Flex";
import {withTranslation} from "react-i18next";

const EventDetail = ({t, i18n}) => {
    const [event, setEvent] = useState({});
    const [loading, setLoading] = useState(false)
    const {id} = useParams();
    const navigate = useNavigate();

    const {
        config: {currency},
        setConfig
    } = useAppContext()

    const getEvent = async () => {
        setLoading(true)
        await api.get(`/event/event/${id}/`)
            .then(res => setEvent(res?.data))
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false)
    };

    useEffect(() => {
        getEvent()
        // eslint-disable-next-line
    }, [currency]);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <>
            <EventDetailHeader event={event} getEvent={getEvent} setLoading={setLoading} t={t}/>
            <Row className="g-3">
                <Col lg={8}>
                    <EventDetailContent event={event} t={t}/>
                    <EventDetailNotes event={event} t={t}/>
                </Col>
                <Col lg={4}>
                    <EventDetailAside event={event} location={event?.address ? event?.address : ""} t={t}/>
                </Col>
            </Row>

        </>
    );
};

export default withPermission(withTranslation(["events", "common"])(EventDetail), "event.view_event");
