import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link, useNavigate} from "react-router-dom";
import {Button, Card, Col, Dropdown, Form, Modal, Row} from "react-bootstrap";
import SimpleBarReact from "simplebar-react";
import Avatar from "components/common/Avatar";
import {useEffect} from "react";
import {quickLinks} from "data/quickLinks";
import {useAppContext} from "../../../providers/AppProvider";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {useTranslation} from "react-i18next";
import {
	BarcodeFormat,
	BrowserQRCodeReader,
} from "@zxing/library";
import {DecodeHintType} from "react-zxing";
import {toast} from "react-toastify";
import {api} from "../../../utils/api";

const NineDotMenu = () => {
	const [show, setShow] = useState(null);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			window.innerWidth < 1200 && setShow(false);
		});
	}, []);

	return (
		<Dropdown navbar={true} as="li" className={"d-none d-sm-inline-block"} show={show} onToggle={() => setShow(!show)}>
			<Dropdown.Toggle
				bsPrefix="toggle"
				as={Link}
				to="#!"
				className="nav-link px-2 nine-dots"
			>
				{/* <span className="nine-dots"></span> */}
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="37"
					viewBox="0 0 16 16"
					fill="none"
				>
					<circle cx="2" cy="2" r="2" fill="#6C6E71"></circle>
					<circle cx="2" cy="8" r="2" fill="#6C6E71"></circle>
					<circle cx="2" cy="14" r="2" fill="#6C6E71"></circle>
					<circle cx="8" cy="8" r="2" fill="#6C6E71"></circle>
					<circle cx="8" cy="14" r="2" fill="#6C6E71"></circle>
					<circle cx="14" cy="8" r="2" fill="#6C6E71"></circle>
					<circle cx="14" cy="14" r="2" fill="#6C6E71"></circle>
					<circle cx="8" cy="2" r="2" fill="#6C6E71"></circle>
					<circle cx="14" cy="2" r="2" fill="#6C6E71"></circle>
				</svg>
			</Dropdown.Toggle>

			<Dropdown.Menu
				className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg"
				show={show}
			>
				<Card className="dropdown-menu-end shadow-none">
					<SimpleBarReact className="nav-quick-links">
						<Card.Body>
							<Row className="text-center g-0">
								{quickLinks.map((item, index) => (
									<QuickLinkItem key={index} {...item} {...index} />
								))}
							</Row>
						</Card.Body>
					</SimpleBarReact>
				</Card>
			</Dropdown.Menu>
		</Dropdown>
	);
};

const QuickLinkItem = ({avatar, avatarText, img, title, link, hr, groups, permission, type}) => {
	const [selectedDeviceId, setSelectedDeviceId] = useState("");
	const [code, setCode] = useState("");
	const [videoInputDevices, setVideoInputDevices] = useState([]);
	const [scanModalOpen, setScanModalOpen] = useState(false)
	const {
		config: {group, isDark}
	} = useAppContext()

	const {t} = useTranslation("topbar")
	const navigate = useNavigate()
	const {hasPermission} = usePermissions()

	const handleClick = (e, type) => {
		if (type === "scan") {
			setScanModalOpen(true)
		}
	}

	const hints = new Map();
	const formats = [
		BarcodeFormat.CODE_128,
		BarcodeFormat.DATA_MATRIX,
		BarcodeFormat.QR_CODE,
		BarcodeFormat.EAN_8,
		BarcodeFormat.AZTEC,
		BarcodeFormat.CODABAR,
		BarcodeFormat.CODE_39,
		BarcodeFormat.CODE_93,
		BarcodeFormat.EAN_13,
		BarcodeFormat.UPC_E,
		BarcodeFormat.UPC_A,
		BarcodeFormat.UPC_EAN_EXTENSION,
		BarcodeFormat.MAXICODE,
		BarcodeFormat.ITF,
		BarcodeFormat.PDF_417,
		BarcodeFormat.RSS_14
	];

	hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
	hints.set(DecodeHintType.TRY_HARDER, true);

	const codeReader = new BrowserQRCodeReader();

	const sourceSelect = document.getElementById("sourceSelect");

	function setupDevices(videoInputDevices) {
		// selects first device
		setSelectedDeviceId(videoInputDevices[0].deviceId);

		// setup devices dropdown
		if (videoInputDevices.length >= 1) {
			setVideoInputDevices(videoInputDevices)
		}
	}

	function resetClick() {
		codeReader.reset();
		setCode("");
	}

	function decodeContinuously(selectedDeviceId) {
		codeReader.decodeFromInputVideoDeviceContinuously(
			selectedDeviceId,
			"videoAttendance",
			async (result, err) => {
				if (result) {
					setCode(result.text);
					await api.post(`/attendance/attendance/`, result.text)
						.then(res => {
							toast.success("User attendance successfully recorded.", {theme: "colored"})
						})
						.catch(() => {
							toast.error("An error has occurred.", {theme: "colored"});
						})
				}

				if (err) {
					setCode("");
					console.log(err)
				}
			}
		)
	}

	useEffect(() => {
		codeReader
			.getVideoInputDevices()
			.then(videoInputDevices => {
				setupDevices(videoInputDevices);
			})
			.catch(err => {
				toast.error(err, {theme: "colored"})
			});
		// eslint-disable-next-line
	}, []);

	useEffect(
		deviceId => {
			if (scanModalOpen) decodeContinuously(selectedDeviceId);
		},
		// eslint-disable-next-line
		[scanModalOpen, selectedDeviceId]
	);

	return (
		<>
			{hr ? (
				<Col xs={12}>
					<hr className="my-3 mx-n3 text-200"/>
				</Col>
			) : (groups.some(name => group?.indexOf(name) !== -1) && hasPermission(permission)) && (
				<Col xs={4}>
					<Link
						to={"#!"}
						className="d-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none"
						rel="noopener noreferrer"
						onClick={(e) => {
							link ? navigate(link, {replace: true}) : handleClick(e, type)
						}}
					>
						{avatar && <Avatar src={avatar} size="2xl"/>}
						{avatarText && (
							<Avatar
								isExact
								name={avatarText}
								size="2xl"
								mediaClass="fs-2 bg-soft-primary text-primary"
							/>
						)}
						{img && <img src={img} width={40} height={40} alt={""}/>}
						<p
							className={classNames(
								"mb-0 fw-medium text-800 text-truncate fs--2",
								{
									"pt-1": img,
								}
							)}
						>
							{t(title)}
						</p>
					</Link>
				</Col>
			)}
			<Modal size={"lg"} show={scanModalOpen} onHide={() => {
				setScanModalOpen(!scanModalOpen)
				codeReader.stopContinuousDecode()
			}} centered>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-x1 border-bottom-0"
				>
					<h5>
						Scan a barcode
					</h5>
				</Modal.Header>
				<Modal.Body>
					<div id="sourceSelectPanel">
						<Form.Group>
							<Form.Label>Change video source:</Form.Label>
							<Form.Select
								id="sourceSelect"
								onChange={() => setSelectedDeviceId(sourceSelect.value)}
							>
								{videoInputDevices.map(element => (
									<option key={element.deviceId} value={element.deviceId}>{element.label}</option>
								))}
							</Form.Select>
						</Form.Group>
					</div>

					<div>
						<div
							style={{
								position: "absolute",
								top: "40%",
								left: "10%",
								width: "80%",
								height: "20%",
								backgroundSize: "cover",
								border: "2px solid white",
								pointerEvents: "none", // make sure the child element doesn't interfere with the scanner
							}}
						/>

						<video id="videoAttendance" width={"100%"} className={"mt-3"}/>
					</div>

					<label>Result:</label>
					<pre>
                <code id="result">{code}</code>
            </pre>
				</Modal.Body>
				<Modal.Footer>
					<Button variant={"secondary"} id="resetButton" onClick={() => {
						resetClick()
						setScanModalOpen(!scanModalOpen)
						codeReader.stopContinuousDecode()
						codeReader.stopAsyncDecode()
					}}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

QuickLinkItem.propTypes = {
	avatar: PropTypes.string,
	avatarText: PropTypes.string,
	img: PropTypes.string,
	title: PropTypes.string,
	link: PropTypes.string,
	hr: PropTypes.bool,
};

export default NineDotMenu;
