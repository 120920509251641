import React from 'react';
import bgImg from 'assets/img/generic/17.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import ForgetPasswordForm from "../ForgetPasswordForm";
import {withTranslation} from "react-i18next";

const ForgetPassword = ({t, i18n}) => {
  return (
    <AuthSplitLayout
      bgProps={{ image: bgImg, position: '50% 76%', overlay: true }}
    >
      <div className="text-center">
        <h4 className="mb-0"> {t('forget.title')}</h4>
        <small>{t('forget.subtitle')}</small>
        <ForgetPasswordForm layout="split" t={t} i18n={i18n} />
      </div>
    </AuthSplitLayout>
  );
};

export default withTranslation(["authentication", "common"])(ForgetPassword);
