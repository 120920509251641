import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {Card, Spinner} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import {Link} from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';
import paths from 'routes/paths';
import {api} from "../../utils/api";

const ActiveUsers = ({end = 5, ...rest}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getData = async () => {
        setLoading(true)
        await api.get("/finance/activeusers/members/").then(res => setData(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <Card {...rest}>
            <FalconCardHeader
                light
                title="Active Members"
                titleTag="h6"
                className="py-2"
            />
            <Card.Body className="py-2">
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    data?.slice(0, end).map(({id, ...rest}, index) => (
                        <ActiveUser {...rest} id={id} key={id} isLast={index === data?.length - 1}/>
                    ))
                )}
            </Card.Body>
            <FalconCardFooterLink
                title="All active members"
                to={paths.members}
                size="sm"
            />
        </Card>
    );
};

const ActiveUser = ({id, name, avatar, role, isLast, online}) => (
    <Flex
        className={classNames('align-items-center position-relative', {
            'mb-3': !isLast
        })}
    >
        <Avatar src={avatar} className={`status-${online ? 'online' : 'offline'}`}/>
        <div className="ms-3">
            <h6 className="mb-0 fw-semibold">
                <Link className="text-900 stretched-link" to={paths.membersDetail.replace(":id", id)}>
                    {name}
                </Link>
            </h6>
            <p className="text-500 fs-11 mb-0">{role}</p>
        </div>
    </Flex>
);

ActiveUser.propTypes = {
    name: PropTypes.string.isRequired,
    avatar: PropTypes.object,
    role: PropTypes.string,
    isLast: PropTypes.bool
};

ActiveUsers.propTypes = {
    end: PropTypes.number
};

export default ActiveUsers;
