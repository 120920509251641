import React, {Fragment, useEffect, useState} from "react";
import {Alert, Card, Spinner} from "react-bootstrap";
import {api} from "utils/api";
import Flex from "../../components/common/Flex";
import PageHeader from "../../components/common/PageHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Logs from "../../components/documentation/change-log/Logs";
import {Link} from "react-router-dom";
import paths from "../../routes/paths";
import {usePermissions} from "../../providers/PermissionsProvider";

const ChangeLog = () => {
	const [logs, setLogs] = useState([]);
	const [loading, setLoading] = useState(false);

	const {hasPermission} = usePermissions()

	const fetchLogs = async () => {
		setLoading(true)
		await api.get("/release_note/release/")
			.then(res => setLogs(res?.data?.results))
		setLoading(false)
	}

	useEffect(() => {
		fetchLogs()
	}, []);

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={"border"} variant={"primary"}/>
		</Flex>
	) : (
		<Fragment>
			<PageHeader title="Changelog" className="mb-3">
				{hasPermission('release_note.add_release') &&
					<Link to={paths.timelineCreate}>Add new</Link>
				}
			</PageHeader>

			<Card className="mb-3">
				<Card.Body>
					<Alert variant="warning" className="p-4 mb-0">
						<Flex>
							<FontAwesomeIcon icon="exclamation-triangle" className="fs-3"/>
							<div className="ms-3 flex-1">
								<h4 className="alert-heading">Important alert!</h4>
								<p>
									If you don't see the updates reflected, try re-logging into your account or clearing your browser
									cache and
									cookies. If you still encounter any issues with nexameet, feel free to contact us
									at{' '} <a href="mailto:support@nexameet.com">support@nexameet.com</a>.
								</p>
							</div>
						</Flex>
					</Alert>
				</Card.Body>
			</Card>

			{logs?.map((logs, index) => (
				<Logs {...logs} index={index} key={index}/>
			))}
		</Fragment>
	)
};

export default ChangeLog;
