import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {useAppContext} from "../../../providers/AppProvider";
import React, {useEffect, useState} from "react";
import CSRFToken from "../../../helpers/CSRFToken";
import Flex from "../../../components/common/Flex";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import FormError from "../../errors/FormError";
import {useParams} from "react-router-dom";

const ModalCalendar = ({open, setOpen}) => {
    const [selectedCalendar, setSelectedCalendar] = useState("internal");
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [event, setEvent] = useState({})
    const [formData, setFormData] = useState({
        title: event?.title,
        start: event?.start_date,
        end: event?.end_date,
        color: "",
        description: event?.description,
        label: "",
        event: event?.id
    })

    const {
        config: {isDark}
    } = useAppContext();
    const {id} = useParams()

    const fetchEvent = async () => {
        setLoading(true)
        await api.get(`/event/event/${id}/`).then(res => {
            setEvent(res?.data)
            setFormData({
                title: res?.data?.title,
                start: res?.data?.start_date,
                end: res?.data?.end_date,
                color: "",
                description: res?.data?.description,
                label: "",
                event: res?.data?.id
            })
        })
        setLoading(false)
    }

    const handleAddToCalendar = async () => {
        setLoading(true)
        if (selectedCalendar === "internal") {
            api.post("/schedule/schedule/", formData)
                .then(res => {
                    toast.success("Event successfully added to your internal calendar.", {theme: "colored"})
                    setOpen(!open);
                })
                .catch(error => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(error?.response?.data)
                })
        } else if (selectedCalendar === "external") {
            api.post("/schedule/create_external_schedule/", formData)
                .then(res => {
                    toast.success("Event successfully added to your external calendar.", {theme: "colored"})
                    setOpen(!open);
                })
                .catch(error => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(error?.response?.data)
                })
        } else if (selectedCalendar === "both") {
            api.post("/schedule/schedule/", formData)
                .then(res => {
                    toast.success("Event successfully added to your internal calendar.", {theme: "colored"})
                    setOpen(!open);
                })
                .catch(error => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(error?.response?.data)
                })
            api.post("/schedule/create_external_schedule/", formData)
                .then(res => {
                    toast.success("Event successfully added to your external calendar.", {theme: "colored"})
                    setOpen(!open);
                })
                .catch(error => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(error?.response?.data)
                })
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchEvent()
        // eslint-disable-next-line
    }, []);


    return (
        <Modal centered size={"lg"} show={open} onHide={() => setOpen(!open)}>
            <Modal.Header
                closeButton
                closeVariant={isDark ? 'white' : undefined}
                className="bg-light px-x1 border-bottom-0"
            >
                <h5>
                    Calendar Options
                </h5>
            </Modal.Header>
            <Modal.Body>
                {loading ?
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                    :
                    <Form>
                        <CSRFToken/>
                        <Form.Group>
                            <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                <Form.Check
                                    type="radio"
                                    id="internalCalendar"
                                    label="Internal Calendar"
                                    checked={selectedCalendar === "internal"}
                                    onChange={() => setSelectedCalendar("internal")}
                                />
                                <Form.Check
                                    type="radio"
                                    id="externalCalendar"
                                    label="External Calendar"
                                    checked={selectedCalendar === "external"}
                                    onChange={() => setSelectedCalendar("external")}
                                />
                                <Form.Check
                                    type="radio"
                                    id="bothCalendar"
                                    label="Both"
                                    checked={selectedCalendar === "both"}
                                    onChange={() => setSelectedCalendar("both")}
                                />
                            </Flex>
                        </Form.Group>
                        {(selectedCalendar === "internal" || selectedCalendar === "both") ? (
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Color</Form.Label>
                                        <Form.Control
                                            type="color"
                                            name={`color`}
                                            placeholder="Color"
                                            value={formData.color}
                                            onChange={(e) => setFormData({...formData, color: e.target.value})}
                                        />
                                        <FormError error={errors?.color}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Label</Form.Label>
                                        <Form.Select value={formData.label}
                                                     onChange={(e) => setFormData({
                                                         ...formData,
                                                         label: e.target.value
                                                     })}>
                                            <option value="">Select a label</option>
                                            <option value="business">Business</option>
                                            <option value="important">Important</option>
                                            <option value="personal">Personal</option>
                                            <option value="must_attend">Must Attend</option>
                                        </Form.Select>
                                        <FormError error={errors?.label}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        ) : (
                            <p>Nexameet's use and transfer to any other app of information received from Google APIs will adhere to <a href={"https://developers.google.com/terms/api-services-user-data-policy"} rel={"noreferrer"} target={"_blank"}>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
                        )}
                        <FormError error={errors?.event}/>
                    </Form>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpen(!open)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAddToCalendar}>
                    Add to Calendar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCalendar