import {withPermission} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useAppContext} from "../../../../providers/AppProvider";
import {useNavigate, useParams} from "react-router-dom";
import useQuery from "../../../../hooks/useQuery";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";
import paths from "../../../../routes/paths";
import {getActivities} from "../../../activity/actions/Activity";
import Flex from "../../../../components/common/Flex";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import {Field, Formik} from "formik";
import CSRFToken from "../../../../helpers/CSRFToken";
import Select from "react-select";
import FormError from "../../../errors/FormError";
import CardHeader from "react-bootstrap/CardHeader";
import IconButton from "../../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const ContractAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [activities, setActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        signature: '',
        is_signed: '',
        tampon: '',
        activity: "",
    });

    const {
        config: {group}
    } = useAppContext();

    const {id} = useParams();
    const activityQuery = useQuery();
    const navigate = useNavigate();

    const getContract = async () => {
        setLoading(true);
        await api
            .get(`/contract/${id}/`)
            .then(async res => {
                setSelectedActivity({
                    value: res?.data?.activity,
                    label: res?.data?.activity_name
                });
                setFormData({
                    ...res?.data
                });
            })
            .catch(err => {
                if (err?.response?.status === 404) navigate('/errors/404');
            });
        setLoading(false);
    };

    const handleSubmit = async (e, values) => {
        setLoading(true);
        e.preventDefault();
        if (id)
            api
                .patch(`/contract/${id}/`, values, {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                    }
                })
                .then(res => {
                    toast.success(`Contract ${res?.data?.name} updated successfully.`);
                    navigate(paths.contractDetail.replace(":id", res?.data?.id));
                })
                .catch(err => {
                    setFormData(values);
                    toast.error(`An error has occurred.`);
                    setErrors(err?.response?.data);
                });
        else
            api
                .post('/contract/', values, {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                    }
                })
                .then(res => {
                    toast.success(`Contract ${res?.data?.title} created successfully.`);
                    navigate(paths.contractDetail.replace(':id', res?.data?.id));
                })
                .catch(err => {
                    setFormData(values);
                    toast.error(`An error has occurred.`);
                    setErrors(err?.response?.data);
                });
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        activityQuery.set('page_size', '50');
        activityQuery.set('page', currentPage.toString());
        getActivities(activityQuery)
            // eslint-disable-next-line no-unsafe-optional-chaining
            .then(res => setActivities([...activities, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        if (id) getContract();
        // eslint-disable-next-line
    }, []);

    let activityOptions = activities.map(activity => ({
        label: activity.name,
        value: activity.id
    }));

    activityOptions = [
        {label: '---------------------------', value: ''},
        ...activityOptions
    ];

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Formik initialValues={formData} onSubmit={values => console.log(values)}>
            {({values, setFieldValue}) => (
                <>
                    <Form>
                        <CSRFToken/>
                        <Row className={'g-3 mt-1 mb-3'}>
                            <Col xxl={12} xl={12}>
                                <Row className="g-3">
                                    <Col xs={12}>
                                        <Card>
                                            <Card.Header>
                                                <h5 className="mb-0 text-muted">Basic Information</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                {group.includes(
                                                    process.env.REACT_APP_ADMIN_GROUP_NAME
                                                ) && (
                                                    <Form.Group>
                                                        <Form.Label>Activity:</Form.Label>
                                                        <Field name="activity">
                                                            {({field}) => (
                                                                <Select
                                                                    name="activity"
                                                                    options={activityOptions}
                                                                    onMenuScrollToBottom={e => {
                                                                        console.log(e);
                                                                        setCurrentPage(page => page + 1);
                                                                    }}
                                                                    onInputChange={e => {
                                                                        activityQuery.set('search', e);
                                                                        getActivities(activityQuery).catch(
                                                                            () => {
                                                                            }
                                                                        );
                                                                    }}
                                                                    placeholder="Select activity"
                                                                    classNamePrefix="react-select"
                                                                    value={selectedActivity}
                                                                    onChange={value => {
                                                                        setFieldValue(field.name, value.value);
                                                                        setSelectedActivity(value);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors.activity}/>
                                                    </Form.Group>
                                                )}
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label>Name:</Form.Label>
                                                            <Field name="name">
                                                                {({field}) => (
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="name"
                                                                        placeholder="Name"
                                                                        errors={errors}
                                                                        formGroupProps={{
                                                                            className: 'mb-3'
                                                                        }}
                                                                        onChange={e => {
                                                                            setFieldValue(field.name, e.target.value);
                                                                        }}
                                                                        value={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.name}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label>Signature:</Form.Label>
                                                            <Field name="signature">
                                                                {({field}) => (
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="signature"
                                                                        placeholder="Signature"
                                                                        errors={errors}
                                                                        formGroupProps={{
                                                                            className: 'mb-3'
                                                                        }}
                                                                        onChange={e => {
                                                                            setFieldValue(field.name, e.target.value);
                                                                        }}
                                                                        value={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.signature}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label>Buffer:</Form.Label>
                                                            <Field name="tampon">
                                                                {({field}) => (
                                                                    <Form.Control
                                                                        type="file"
                                                                        name="tampon"
                                                                        errors={errors}
                                                                        formGroupProps={{
                                                                            className: 'mb-3'
                                                                        }}
                                                                        onChange={e => {
                                                                            setFieldValue(field.name, e.target.files[0]);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.tampon}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label>Signed:</Form.Label>
                                                            <Field name="is_signed">
                                                                {({field}) => (
                                                                    <Form.Switch
                                                                        name="is_signed"
                                                                        errors={errors}
                                                                        label={"Signed"}
                                                                        onChange={e => {
                                                                            setFieldValue(field.name, e.target.checked);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.is_signed}/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <Card>
                        <CardHeader>
                            <Flex justifyContent={'end'} wrap={'wrap'}>
                                <IconButton
                                    icon={faSave}
                                    onClick={e => handleSubmit(e, values)}
                                >
                                    <span className="d-none d-sm-inline-block ms-1">Save</span>
                                </IconButton>
                            </Flex>
                        </CardHeader>
                    </Card>
                </>
            )}
        </Formik>
    )
}

export default withPermission(withTranslation(["contract", "common"])(ContractAddEdit), ["contract.add_contract", "contract.change_contract"])