import React, {Fragment, useEffect, useState} from "react";
import {Col, Form, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import SimpleBarReact from "simplebar-react";

const ColumnMappingForm = ({formData, setFormData, fileColumns, loading}) => {
    const [mappedColumns, setMappedColumns] = useState([]);
    const handleMappingChange = (dbField, e) => {
        setMappedColumns(prevState => (
            [
                ...prevState,
                {
                    header_field: e.target.value,
                    event_field: dbField.value,
                }
            ]
        ));
    }

    const dbFields = [
        {value: "title", label: "Title"},
        {value: "subtitle", label: "Sub title"},
        {value: "start_date", label: "Start Date"},
        {value: "end_date", label: "End Date"},
        {value: "venue", label: "Venue"},
        {value: "address", label: "Address"},
        {value: "description", label: "Description"},
        {value: "type", label: "Type"},
        {value: "topic", label: "Topic"},
        {value: "public", label: "Public"},
        {value: "color", label: "Color"},
    ];

    useEffect(() => {
        setFormData({...formData, mapping: mappedColumns});
        // eslint-disable-next-line
    }, [mappedColumns]);

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Form className="needs-validation">
                        <SimpleBarReact>
                            <Table>
                                <tbody>
                                {dbFields?.map((dbField, index) => (
                                    <tr form key={dbField} justifyContent={"between"} alignItems={"center"}
                                        className={"mb-3"}>
                                        <td>
                                            <Form.Label>{dbField.label}</Form.Label>
                                        </td>
                                        <td>
                                            <FontAwesomeIcon icon={faArrowRight}/>
                                        </td>
                                        <td>
                                            <Form.Select type="select" onChange={e => handleMappingChange(dbField, e)}
                                                         value={mappedColumns[index]?.header_field}>
                                                <option value="">Selectionner une colonne</option>
                                                {fileColumns.map(column => (
                                                    <option key={column} value={column}>
                                                        {column}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </SimpleBarReact>

                    </Form>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ColumnMappingForm