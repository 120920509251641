import Events from 'components/pages/user/profile/Events';
import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {formatDateTime} from "../../../helpers/utils";
import {api} from "../../../utils/api";
import CSRFToken from "../../../helpers/CSRFToken";
import Flex from "../../../components/common/Flex";
import ModalCalendar from "./ModalCalendar";
import ModalMap from "./ModalMap";
import {useTranslation} from "react-i18next";

const EventDetailAside = ({event, location}) => {
	const [relatedEvents, setRelatedEvents] = useState([])
	const [loading, setLoading] = useState(false)
	const [modalCalendar, setModalCalendar] = useState(false)
	const [modalMap, setModalMap] = useState(false)

	const {t} = useTranslation(["events", "common"]);

	const getRelatedEvents = async () => {
		setLoading(true)
		await api.post("/event/related_events/", {type: event?.type})
			.then(res => setRelatedEvents(res?.data))
			.catch(error => {})
		setLoading(false)
	}

	useEffect(() => {
		getRelatedEvents()
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={"border"} variant={"primary"}/>
		</Flex>
	) : (
		<div className="sticky-sidebar">
			<CSRFToken/>
			<Card className="mb-3 fs--1">
				<Card.Body>
					<h6>{t('date_time')}</h6>
					<p className="mb-1">
						{formatDateTime(event?.start_date)} – <br/>
						{formatDateTime(event?.end_date)}
					</p>
					<Link to="#!" onClick={() => setModalCalendar(!modalCalendar)}>{t('calendarAdd')}</Link>
					<h6 className="mt-4">{t('location')}</h6>
					<div className="mb-1">
						{location?.formatted_address}
						<br/>
						{event?.delegation_name}
						<br/>
						{event?.city_name}
						<br/>
						{event?.country_name}
					</div>
					<Link to="#!" onClick={() => setModalMap(!modalMap)}>{t('see', {ns: "common"})} {t('map')}</Link>
				</Card.Body>
			</Card>
			<ModalMap setOpen={setModalMap} open={modalMap} location={location} />
			<ModalCalendar open={modalCalendar} setOpen={setModalCalendar} event={event} />
			<Events cardTitle={t('mayLike')} events={relatedEvents?.slice(0, 3)} t={t}/>
		</div>
	);
};

export default EventDetailAside;
