import envelope from "../../assets/img/icons/spot-illustrations/16.png";
import {Button, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import useQuery from "../../hooks/useQuery";
import Flex from "../../components/common/Flex";
import {api} from "../../utils/api";
import {toast} from "react-toastify";
import CSRFToken from "../../helpers/CSRFToken";

const VerifyAccountContent = ({layout, titleTag: TitleTag, t, i18n}) => {
	const [loading, setLoading] = useState(false)
	const [verified, setVerified] = useState(false)

	let query = useQuery()

	const verifyEmail = async () => {
		setLoading(true)
		await api.post("/dj-rest-auth/account-confirm-email/", {key: query.get("key")})
			.then((res) => {
				toast.success(t('verify.titleSuccess'), {theme: "colored"})
				setVerified(true)
			})
			.catch((error) => {
				toast.error(t('error', {ns: "common"}), {theme: "colored"})
			})
		setLoading(false)
	}

	useEffect(() => {
		verifyEmail()
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<>
			<CSRFToken />
			<img
				className="d-block mx-auto mb-4"
				src={envelope}
				alt="sent"
				width={100}
			/>
			<TitleTag>{verified ? t('verify.titleSuccess') : t('verify.titleError')}</TitleTag>
			<p>
				{verified ? t('verify.subtitleSuccess') : t('verify.subtitleError')}
			</p>
			<Button
				as={Link}
				color="primary"
				size="sm"
				className="mt-3"
				to={`/authentication/${layout}/login`}
			>
				<FontAwesomeIcon
					icon="chevron-left"
					transform="shrink-4 down-1"
					className="me-1"
				/>
				{t('verify.loginReturn')}
			</Button>
		</>
	)
}

export default VerifyAccountContent