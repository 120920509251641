import React, {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field} from "formik";
import DatePicker from 'react-datepicker';
import CustomDateInput from "../../../../components/common/CustomDateInput";
import TinymceEditor from "../../../../components/common/TinymceEditor";
import FormError from "../../../errors/FormError";

const OrganizerCertificationItem = ({
                                        index,
                                        title,
                                        value,
                                        handleRemove,
                                        setFieldValue,
                                        values,
                                        errors,
                                        t
                                    }) => {
    const [issueDate, setIssueDate] = useState(values?.certifications[index]?.issue_date ? new Date(values?.certifications[index]?.issue_date) : null)
    const [expirationDate, setExpirationDate] = useState(values?.certifications[index]?.expiration_date ? new Date(values?.certifications[index]?.expiration_date) : null)

    return (
        <div className="bg-white border dark__bg-100 p-3 position-relative rounded-1 mb-2">
            <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
                <Button
                    size="sm"
                    variant="link"
                    className="p-0"
                    onClick={() => handleRemove(index)}
                >
                    <FontAwesomeIcon className="text-danger" icon="times-circle"/>
                </Button>
            </div>

            <Row className="gx-2 gy-3">
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Name <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.name`}>
                            {({field}) => (
                                <Form.Control
                                    type={"text"}
                                    name={`certifications.${index}.name`}
                                    placeholder={"Name"}
                                    onChange={e => {
                                        setFieldValue(field.name, e.target.value)
                                    }}
                                    value={field.value}
                                />
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Issuing <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.issuing`}>
                            {({field}) => (
                                <Form.Control
                                    type={"text"}
                                    name={`certifications.${index}.issuing`}
                                    placeholder={"Issuing"}
                                    onChange={e => {
                                        setFieldValue(field.name, e.target.value)
                                    }}
                                    value={field.value}
                                />
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Issue Date <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.issue_date`}>
                            {({field}) => (
                                <Row>
                                    <DatePicker
                                        selected={issueDate}
                                        defaultValue=""
                                        onChange={newDate => {
                                            setIssueDate(newDate)
                                            setFieldValue(field.name, new Date(newDate).toISOString())
                                        }}
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        fixedHeight
                                        className='form-control'
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'MM/dd/yyyy h:mm aa',
                                                }}
                                            />
                                        }
                                    />
                                </Row>
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Expiration Date <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.expiration_date`}>
                            {({field}) => (
                                <Row>
                                    <DatePicker
                                        selected={expirationDate}
                                        defaultValue=""
                                        onChange={newDate => {
                                            setExpirationDate(newDate)
                                            setFieldValue(field.name, new Date(newDate).toISOString())
                                        }}
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        fixedHeight
                                        className='form-control'
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'MM/dd/yyyy h:mm aa',
                                                }}
                                            />
                                        }
                                    />
                                </Row>
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Number <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.number`}>
                            {({field}) => (
                                <Form.Control
                                    type={"text"}
                                    name={`certifications.${index}.number`}
                                    placeholder={"Number"}
                                    onChange={e => {
                                        setFieldValue(field.name, e.target.value)
                                    }}
                                    value={field.value}
                                />
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Evidence <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.evidence`}>
                            {({field}) => (
                                <Form.Control
                                    type={"file"}
                                    name={`certifications.${index}.evidence`}
                                    onChange={e => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                setFieldValue(field.name, reader.result);
                                            };
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                />
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Type <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.type`}>
                            {({field}) => (
                                <Form.Select value={field.value}
                                             onChange={(e) => setFieldValue(field.name, e.target.value)}>
                                    <option value={""}>---------------------------</option>
                                    <option value={"professional"}>Professional</option>
                                    <option value={"technical"}>Technical</option>
                                    <option value={"safety"}>Safety</option>
                                </Form.Select>
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Renewal <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.renewal`}>
                            {({field}) => (
                                <Form.Switch
                                    label={"Renewal"}
                                    checked={field.value}
                                    onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                />
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Description <span className={"text-danger"}>*</span></Form.Label>
                        <Field name={`certifications.${index}.description`}>
                            {({field}) => (
                                <TinymceEditor
                                    value={field.value}
                                    handleChange={newValue => setFieldValue(field.name, newValue)}
                                    height={"280"}
                                />
                            )}
                        </Field>
                        <FormError error={errors?.description}/>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    )
}

export default OrganizerCertificationItem