import React, {useEffect, useState} from 'react';
import {CloseButton, Col, Modal, Row, Dropdown, Spinner, Button, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ModalMediaContent from './ModalMediaContent';
import GroupMember from './GroupMember';
import ModalLabelContent from './ModalLabelContent';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalAttachmentContent from './ModalAttachmentContent';
import ModalCommentContent from './ModalCommentContent';
import ModalActivityContent from './ModalActivityContent';
import ModalSidebar from './ModalSidebar';
import {api} from "../../../utils/api";
import Flex from "../../../components/common/Flex";
import paths from "../../../routes/paths";
import TinymceEditor from "../../../components/common/TinymceEditor";
import IconButton from "../../../components/common/IconButton";
import useQuery from "../../../hooks/useQuery";

const KanbanModal = ({show, setShow, id, getKanbanStatus}) => {
    const [task, setTask] = useState({})
    const [loading, setLoading] = useState(false);
    const [descriptionData, setDescriptionData] = useState("")
    const [openEditor, setOpenEditor] = useState(false)

    const query = useQuery()

    const getTask = async () => {
        setLoading(true)
        await api.get(`/task/tasks/${id}/`).then(res => setTask(res?.data))
        setLoading(false)
    }

    const handleClose = () => {
        setShow(!id)
    };

    useEffect(() => {
        getTask()
        // eslint-disable-next-line
    }, []);

    const AddDescription = async (e) => {
        e.preventDefault()
        await api.patch(`/task/tasks/${id}/`, {description: descriptionData}).then((res) => {
            getTask()
            setOpenEditor(false)
            getKanbanStatus(query)
        })
    };

    const UploadFile = async (e) => {
        e.preventDefault()
        await api.patch(`/task/tasks/${id}/`, {attachment: e.target.files[0]}, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            getTask()
            getKanbanStatus(query)
        })
    };
    return (
        <Modal
            show={show === id}
            size="xl"
            onHide={handleClose}
            contentClassName="border-0"
            dialogClassName="mt-6"
        >
            {/*{kanbanModal.modalContent.image && (*/}
            {/*  <div className="position-relative overflow-hidden py-8">*/}
            {/*    <Background*/}
            {/*      image={kanbanModal.modalContent.image.url}*/}
            {/*      className="rounded-top-lg"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
            <div className="position-absolute top-0 end-0 mt-3 me-3 z-1">
                <CloseButton
                    onClick={handleClose}
                    className="btn btn-sm btn-circle d-flex flex-center transition-base"
                />
            </div>
            <Modal.Body className="p-0">
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <>
                        <div className="bg-body-tertiary rounded-top-lg px-4 py-3">
                            <h4 className="mb-1">{task?.title}</h4>
                            <p className="fs-11 mb-0">
                                Added by{' '}
                                <Link to={paths.usersDetail.replace(":id", task?.user)}
                                      className="text-600 fw-semibold">
                                    {task?.user_full_name}
                                </Link>
                            </p>
                        </div>
                        <div className="p-4">
                            <Row>
                                <Col lg={8}>
                                    <ModalMediaContent title="Reviewers" icon="user">
                                        <GroupMember
                                            users={task?.organizer_set}
                                            addMember
                                            showMember={6}
                                            avatarSize="xl"
                                            task={id}
                                            getTask={getTask}
                                            getKanbanStatus={getKanbanStatus}
                                        />
                                    </ModalMediaContent>

                                    <ModalMediaContent title="Labels" icon="tag">
                                        <ModalLabelContent getKanbanStatus={getKanbanStatus} taskLabels={task?.label_set} task={id} getTask={getTask}/>
                                    </ModalMediaContent>

                                    <ModalMediaContent
                                        title="Description"
                                        icon="align-left"
                                        headingClass="d-flex justify-content-between"
                                        headingContent={
                                            <Flex>
                                                {!task?.description &&
                                                    <IconButton icon={"plus"} size={"sm"} variant={"falcon-default"}
                                                                onClick={() => setOpenEditor(true)}/>}
                                                <IconButton icon={"edit"} size={"sm"} variant={"falcon-default"}
                                                            className={"ms-2"} onClick={() => {
                                                    setOpenEditor(true)
                                                    setDescriptionData(task?.description)
                                                }}/>
                                            </Flex>
                                        }
                                    >
                                        {(task?.description && !openEditor) ? (
                                            <>
                                                <p className="text-word-break fs-10"
                                                   dangerouslySetInnerHTML={{__html: task?.description}}/>
                                            </>
                                        ) : openEditor && (
                                            <>
                                                <TinymceEditor
                                                    value={descriptionData}
                                                    handleChange={newValue => setDescriptionData(newValue)}
                                                />
                                                <Button size="sm" color="primary" className={"mt-3"}
                                                        onClick={AddDescription}>
                                                    Save
                                                </Button>
                                            </>
                                        )}
                                    </ModalMediaContent>

                                    <ModalMediaContent
                                        title="Attachments"
                                        icon="paperclip"
                                        headingClass="d-flex justify-content-between"
                                        headingContent={
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="falcon-default"
                                                    size="sm"
                                                    className="dropdown-caret-none fs-11"
                                                >
                                                    <FontAwesomeIcon icon="plus"/>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as={"button"}>
                                                        <Form.Group controlId="file-attachment">
                                                            <Form.Label
                                                                className="link-600 px-2 transition-base rounded cursor-pointer mb-0">
                                                                <FontAwesomeIcon icon="paperclip"/> Computer
                                                            </Form.Label>
                                                            <Form.Control type="file" className="d-none" onChange={UploadFile}/>
                                                        </Form.Group>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        }
                                    >
                                        <ModalAttachmentContent getKanbanStatus={getKanbanStatus} taskAttachments={task?.attachment_set} task={id}
                                                                getTask={getTask}/>
                                    </ModalMediaContent>

                                    <ModalMediaContent
                                        title="Comments"
                                        icon={['far', 'comment']}
                                        headingClass="mb-3"
                                    >
                                        <ModalCommentContent getKanbanStatus={getKanbanStatus} taskComments={task?.comment_set} task={id}
                                                                getTask={getTask}/>
                                    </ModalMediaContent>
                                    <ModalMediaContent
                                        title="Activity"
                                        icon="list-ul"
                                        headingClass="mb-3"
                                        isHr={false}
                                    >
                                        <ModalActivityContent taskActivities={task?.activity_set} task={id}
                                                                getTask={getTask}/>
                                    </ModalMediaContent>
                                </Col>
                                <Col lg={4}>
                                    <ModalSidebar task={task} getTask={getTask} setShow={setShow} getKanbanStatus={getKanbanStatus} />
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default KanbanModal;
