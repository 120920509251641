import {withTranslation} from "react-i18next";
import {withPermission} from "../../../helpers/utils";
import React, {Fragment, useEffect, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import useQuery from "../../../hooks/useQuery";
import {useNavigate, useParams} from "react-router-dom";
import {useAppContext} from "../../../providers/AppProvider";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import CSRFToken from "../../../helpers/CSRFToken";
import {Field, Formik} from "formik";
import Select from "react-select";
import FormError from "../../errors/FormError";
import {getActivities} from "../../activity/actions/Activity";
import IconButton from "../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const ProviderAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [activities, setActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState(null)
    const [page, setPage] = useState(1)
    const [formData, setFormData] = useState({
        activity: "",
        name: "",
        provider: "",
        active: true,
        default: false,
        wallet_key: "",
        api_key: "",
        token: "",
    });

    const query = useQuery()
    const navigate = useNavigate();
    const {id} = useParams();

    const {
        config: {group},
        setConfig
    } = useAppContext()

    const getProvider = async () => {
        setLoading(true)
        await api.get(`/transaction/provider/${id}/`)
            .then(async res => {
                setSelectedActivity({label: res?.data?.activity_name, value: res?.data?.activity})
                if (res?.data?.provider === "konnect") {
                    await api.get(`/transaction/konnect/${id}/`)
                        .then(res =>
                            setFormData({
                                ...res?.data,
                            })
                        )
                }
                else if (res?.data?.provider === "paymee") {
                    await api.get(`/transaction/paymee/${id}/`)
                        .then(res =>
                            setFormData({
                                ...res?.data,
                            })
                        )
                }
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    }

    const fetchActivities = async (q) => {
        query.set("page_size", "50")
        query.set("page", page.toString())
        getActivities(q).then(res => setActivities([...activities, ...res?.results]))
    }

    useEffect(() => {
        fetchActivities(query)
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (id) getProvider();
        // eslint-disable-next-line
    }, []);

    let activityOptions = activities?.map(activity => ({label: activity?.name, value: activity?.id}))

    activityOptions = [
        {label: "-------------------------", value: ""},
        ...activityOptions
    ]

    const handleSubmit = async (e, values) => {
        setLoading(true);
        e.preventDefault();
        if (id) {
            await api
                .patch(`/transaction/${values?.provider}/${id}/`, values)
                .then(res => {
                    toast.success(`Provider successfully updated.`, {theme: 'colored'});
                    navigate(paths.provider);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(`An error has occurred.`, {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        } else {
            await api
                .post(`/transaction/${values?.provider}/`, values)
                .then(res => {
                    toast.success(`Provider successfully created.`, {theme: 'colored'});
                    navigate(paths.provider);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(`An error has occurred.`, {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        }
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <Form>
                        <CSRFToken/>
                        <Row className="g-3">
                            <Col lg={12}>
                                <Card>
                                    <Card.Header>
                                        <h5>Basic Information</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        {group?.indexOf(
                                            process.env.REACT_APP_ADMIN_GROUP_NAME
                                        ) !== -1 && (
                                            <Form.Group>
                                                <Form.Label>Activity</Form.Label>
                                                <Field name={"activity"}>
                                                    {({field}) => (
                                                        <Select
                                                            options={activityOptions}
                                                            value={selectedActivity}
                                                            onChange={newValue => {
                                                                setSelectedActivity(newValue)
                                                                setFieldValue(field.name, newValue.value)
                                                            }}
                                                            classNamePrefix={"react-select"}
                                                            placeholder={`Select activity`}
                                                            onInputChange={newValue => {
                                                                query.set("search", newValue)
                                                                getActivities(query).then(res => setActivities(res?.results))
                                                            }}
                                                            onMenuScrollToBottom={event => {
                                                                setPage(prevState => prevState + 1)
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.activity}/>
                                            </Form.Group>
                                        )}
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Name <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"name"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                name={"name"}
                                                                type={'text'}
                                                                placeholder={'Name'}
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.name}/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Provider <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"provider"}>
                                                        {({field}) => (
                                                            <Form.Select
                                                                name={"provider"}
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            >
                                                                <option value={""}>Select Provider</option>
                                                                <option value={"konnect"}>Konnect</option>
                                                                <option value={"paymee"}>Paymee</option>
                                                            </Form.Select>
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.provider}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className={"mt-3"}>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Field name={"active"}>
                                                        {({field}) => (
                                                            <Form.Switch
                                                                name={"active"}
                                                                label={"Active"}
                                                                checked={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.checked);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.active}/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Field name={"default"}>
                                                        {({field}) => (
                                                            <Form.Switch
                                                                name={"default"}
                                                                label={"Default"}
                                                                checked={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.checked);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.default}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {values?.provider &&
                            <Row>
                                <Col lg={12}>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h5 className="mb-0 text-muted">{values?.provider === "konnect" ? "Konnect" : "Paymee"}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            {values?.provider === "konnect" ? (
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label>Wallet key <span
                                                                className={"text-danger"}>*</span></Form.Label>
                                                            <Field name={"wallet_key"}>
                                                                {({field}) => (
                                                                    <Form.Control
                                                                        name={"wallet_key"}
                                                                        type={'text'}
                                                                        placeholder={'Wallet key'}
                                                                        value={field.value}
                                                                        onChange={e => {
                                                                            setFieldValue(field.name, e.target.value);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors?.wallet_key}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label>API key <span
                                                                className={"text-danger"}>*</span></Form.Label>
                                                            <Field name={"api_key"}>
                                                                {({field}) => (
                                                                    <Form.Control
                                                                        name={"api_key"}
                                                                        type={'text'}
                                                                        placeholder={'API key'}
                                                                        value={field.value}
                                                                        onChange={e => {
                                                                            setFieldValue(field.name, e.target.value);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors?.api_key}/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row>
                                                    <Col lg={12}>
                                                        <Form.Group>
                                                            <Form.Label>Token <span
                                                                className={"text-danger"}>*</span></Form.Label>
                                                            <Field name={"token"}>
                                                                {({field}) => (
                                                                    <Form.Control
                                                                        name={"token"}
                                                                        type={'text'}
                                                                        placeholder={'Token'}
                                                                        value={field.value}
                                                                        onChange={e => {
                                                                            setFieldValue(field.name, e.target.value);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors?.token}/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col lg={12}>
                                <Card className={"mt-3"}>
                                    <Card.Footer>
                                        <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                            <p className={"text-danger"}>* Mandatory field</p>
                                            <IconButton
                                                icon={faSave}
                                                onClick={e => handleSubmit(e, values)}
                                            >
                        <span className="d-none d-sm-inline-block ms-1">
                          Save
                        </span>
                                            </IconButton>
                                        </Flex>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default withPermission(withTranslation(["providers", "common"])(ProviderAddEdit), ["transaction.add_transactionprovider", "transaction.change_transactionprovider"]);