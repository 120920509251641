import React, { Fragment, useEffect, useState } from 'react';
import PageHeader from './PageHeader';
import { useParams } from 'react-router-dom';
import { api } from '../../../utils/api';
import { Spinner } from 'react-bootstrap';
import Flex from '../../../components/common/Flex';
import EventReport from './EventReport';
import MeetReport from './MeetReport';
import {withPermission} from '../../../helpers/utils';

const ReportDetail = () => {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({});

  const { id } = useParams();

  const getReport = async () => {
    setLoading(true);
    await api.get(`/report/report/${id}/`).then(async res => {
      if (res?.data?.type === 'event')
        await api
          .get(`/report/eventreport/${id}/`)
          .then(rep => setReport(rep?.data));
      else if (res?.data?.type === 'meeting')
        await api
          .get(`/report/meetreport/${id}/`)
          .then(rep => setReport(rep?.data));
    });
    setLoading(false);
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line
    }, []);

  return loading ? (
    <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
      <Spinner animation={'border'} variant={'primary'} />
    </Flex>
  ) : (
    <Fragment>
      <PageHeader report={report} />
      {report?.type === 'event' ? (
        <EventReport report={report} />
      ) : report?.type === 'meeting' ? (
        <MeetReport report={report} />
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default withPermission(ReportDetail, "report.view_report");
