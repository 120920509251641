import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useQuery from "../../../../hooks/useQuery";
import {api} from "../../../../utils/api";
import SoftBadge from "../../../../components/common/SoftBadge";
import {capitalize, formatDateTime} from "../../../../helpers/utils";
import AdvanceTableWrapper from "../../../../components/common/advance-table/AdvanceTableWrapper";
import Flex from "../../../../components/common/Flex";
import AdvanceTable from "../../../../components/common/advance-table/AdvanceTable";
import {Link} from "react-router-dom";
import paths from "../../../../routes/paths";

const TransactionsHistory = () => {
  const [billings, setBillings] = useState([]);
  const [loading, setLoading] = useState(false);

  let query = useQuery();

  const getBillings = async () => {
    setLoading(true);
    query.set('page_size', '10');
    await api.get(`/billing/billing/?${query.toString()}`).then(res => {
      setBillings(res?.data?.results);
    });
    setLoading(false);
  };

  useEffect(() => {
    getBillings();
    // eslint-disable-next-line
    }, []);

  const columns = [
    {
      accessor: 'code',
      Header: 'Code',
      headerProps: {
        className: 'text-center pe-3'
      },
      cellProps: {
        className: 'text-center fs-0 py-2'
      },
      Cell: rowData => <Link to={paths.billingDetail.replace(":id", rowData.row.original.id)}>{rowData.row.original.code}</Link>
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      headerProps: {
        className: 'text-center pe-3'
      },
      cellProps: {
        className: 'text-center fs-0 py-2'
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: {
        className: 'text-center pe-3'
      },
      cellProps: {
        className: 'text-center fs-0 py-2'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: status === 'approved',
              warning: status === 'pending',
              danger: status === 'declined'
            })}
            className="d-flex flex-center"
          >
            <p className="mb-0">
              {status === 'approved' && 'Approved'}
              {status === 'pending' && 'Pending'}
              {status === 'declined' && 'Declined'}
            </p>
            <FontAwesomeIcon
              icon={classNames({
                check: status === 'approved',
                ban: status === 'declined',
                clock: status === 'pending'
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      headerProps: {
        className: 'text-center pe-3'
      },
      cellProps: {
        className: 'text-center fs-0 py-2'
      },
      Cell: rowData => {
        const { type } = rowData.row.original;
        return <p>{capitalize(type)}</p>;
      }
    },
    {
      accessor: 'date',
      Header: 'Date',
      headerProps: {
        className: 'text-center pe-3'
      },
      cellProps: {
        className: 'text-center fs-0 py-2'
      },
      Cell: rowData => {
        const { date } = rowData.row.original;
        return <p>{formatDateTime(date)}</p>;
      }
    }
  ];

  const billingList = billings?.map(billing => ({
    id: billing?.id,
    code: billing?.code,
    amount: billing?.amount,
    status: billing?.status,
    type: billing?.type,
    date: billing?.date
  }));

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={billingList}
      pagination
      perPage={10}
    >
      {loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
          <Spinner animation={'border'} variant={'primary'} />
        </Flex>
      ) : (
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            size: 'sm',
            striped: true,
            className: 'fs--1 mb-3 overflow-hidden'
          }}
          headers={columns}
        />
      )}
    </AdvanceTableWrapper>
  );
};

export default TransactionsHistory;
