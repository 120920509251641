import React, {Fragment, useEffect, useState} from "react";
import {usePermissions} from "../../../../providers/PermissionsProvider";
import useQuery from "../../../../hooks/useQuery";
import Flex from "../../../../components/common/Flex";
import {Button, Card, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import IconButton from "../../../../components/common/IconButton";
import SimpleBarReact from "simplebar-react";
import {api} from "../../../../utils/api";
import {Link} from "react-router-dom";
import paths from "../../../../routes/paths";
import CSRFToken from "../../../../helpers/CSRFToken";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import OfficerModal from "./OfficerModal";
import OfficerTermModal from "./OfficerTermModal";

const OfficersManagementContent = () => {
    const [loading, setLoading] = useState(false);
    const [positions, setPositions] = useState([]);
    const [positionModal, setPositionModal] = useState(false);
    const [positionModalLoading, setPositionModalLoading] = useState(false);
    const [officerModal, setOfficerModal] = useState(null)
    const [officerTermModal, setOfficerTermModal] = useState(null)
    const [positionFormData, setPositionFormData] = useState({
        name: "",
        description: "",
    });

    const {hasPermission} = usePermissions()
    const query = useQuery()

    const getPositions = async () => {
        setLoading(true);
        await api.get(`/officer/position/?${query.toString()}`).then(res => setPositions(res?.data?.results));
        setLoading(false)
    }

    useEffect(() => {
        getPositions()
        // eslint-disable-next-line
    }, []);

    const handleAddPosition = async e => {
        e.preventDefault();
        setPositionModalLoading(true)
        await api.post("/officer/position/", positionFormData)
            .then(res => {
                toast.success("Position added successfully.", {theme: "colored"})
                setPositionModal(false)
                getPositions()
            })
        setPositionModalLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header className="d-flex justify-content-end">
                    {hasPermission('officer.add_position') &&
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-2"
                            iconAlign="middle"
                            onClick={() => setPositionModal(true)}
                        >
                            <span className="d-none d-md-inline-block ms-1">Position</span>
                        </IconButton>
                    }
                </Card.Header>
            </Card>
            <Row className={"mt-3"}>
                <Col>
                    <Card className={"mt-3"}>
                        <Card.Body>
                            <SimpleBarReact>
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Position</th>
                                        <th scope="col">Officers</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {positions?.map(position => (
                                        <tr>
                                            <td>
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="plus"
                                                    transform="shrink-2"
                                                    iconAlign="middle"
                                                    onClick={() => setOfficerModal(position?.id)}
                                                />
                                                <OfficerModal open={officerModal} setOpen={setOfficerModal} id={position?.id} name={position?.name} fetch={getPositions}/>
                                            </td>
                                            <td>{position?.name}</td>
                                            <td>
                                                {position?.officers?.map(officer => new Date(officer?.term_end_date) > new Date() && (
                                                    <>
                                                        <IconButton
                                                            variant="falcon-default"
                                                            size="sm"
                                                            icon="edit"
                                                            transform="shrink-2"
                                                            iconAlign="middle"
                                                            className={"me-2"}
                                                            onClick={() => setOfficerTermModal(officer?.id)}
                                                        />
                                                        <Link
                                                            to={paths.usersDetail.replace(":id", officer?.user)}>{officer?.user_full_name}</Link>
                                                        <OfficerTermModal name={officer?.user_full_name} id={officer?.id} open={officerTermModal} setOpen={setOfficerTermModal} fetch={getPositions} />
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal size={"lg"} centered show={positionModal} onHide={() => setPositionModal(!positionModal)}>
                <Modal.Header closeButton>
                    <h5>Add Position</h5>
                </Modal.Header>
                <Modal.Body>
                    {positionModalLoading ? (
                        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <Form>
                            <CSRFToken/>
                            <Form.Group>
                                <Form.Label>Name <span className={"text-danger"}>*</span></Form.Label>
                                <Form.Control
                                    type={"text"}
                                    name={"name"}
                                    placeholder={"Name"}
                                    onChange={(e) => {
                                        setPositionFormData({...positionFormData, name: e.target.value});
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as={"textarea"}
                                    name={"description"}
                                    placeholder={"Description"}
                                    onChange={(e) => {
                                        setPositionFormData({...positionFormData, description: e.target.value})
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setPositionModal(!positionModal)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddPosition}>
                        Save <FontAwesomeIcon icon={faSave} className="ms-2"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default OfficersManagementContent
