import BaseExportTable from "../../BaseExportTable";
import React from "react";
import {withTranslation} from "react-i18next";
import paths from "../../../routes/paths";
import {withPermission} from "../../../helpers/utils";

const ImportFiles = ({t, i18n}) => {
	return <BaseExportTable t={t} i18n={i18n} title={"eventimport"} url={"/event/import-event/"}
							creationPath={paths.importEvent} addPermission={"event.add_eventimportfile"}
							detailsPath={paths.importDetail}
	/>
}

export default withTranslation("common")(withPermission(ImportFiles, "event.view_eventimportfile"));