import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FalconCardHeader from "../../../components/common/FalconCardHeader";
import IconButton from "../../../components/common/IconButton";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field, FieldArray} from "formik";
import CustomDateInput from "../../../components/common/CustomDateInput";
import DatePicker from 'react-datepicker';

const EventResource = ({t, errors, values, setFieldValue, handleRemove}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null)
    return (
        <Card className="mb-3 mt-3">
            <FalconCardHeader
                light
                title={"Resources"}
                titleTag="h5"
                className="py-2"
                endEl={<IconButton
                    variant="falcon-default"
                    icon="trash"
                    size={'sm'}
                    iconClassName={'ms-1 text-danger'}
                    onClick={() => handleRemove("resources")}
                >
                    {' '}
                </IconButton>}
            />
            <Card.Body>
                <FieldArray name={"resources"}
                            render={arrayHelpers => (
                                <>
                                    {values?.resources?.length > 0 ? (
                                        values?.resources?.map((field, index) => (
                                                <div
                                                    className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
                                                    <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
                                                        <Button
                                                            size="sm"
                                                            variant="link"
                                                            className="p-0"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            <FontAwesomeIcon className="text-danger" icon="times-circle"/>
                                                        </Button>
                                                    </div>

                                                    <Row className="gx-2 gy-3">
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>Name <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Field name={`resources.${index}.name`}>
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            type="text"
                                                                            name={`resources.${index}.name`}
                                                                            placeholder={"Name"}
                                                                            value={field.value}
                                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>Capacity <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Field name={`resources.${index}.capacity`}>
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            type="number"
                                                                            name={`resources.${index}.capacity`}
                                                                            placeholder={"Capacity"}
                                                                            value={field.value}
                                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>Start Date <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Row>
                                                                    <Field name={`resources.${index}.start_date`}>
                                                                        {({field}) => (
                                                                            <DatePicker
                                                                                selected={values?.resources[index]?.start_date ? new Date(values?.resources[index]?.start_date) : startDate}
                                                                                defaultValue=""
                                                                                name={`resources.${index}.start_date`}
                                                                                onChange={newDate => {
                                                                                    setStartDate(newDate)
                                                                                    setFieldValue(field.name, new Date(newDate).toISOString())
                                                                                }}
                                                                                showTimeSelect
                                                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                                                fixedHeight
                                                                                className='form-control'
                                                                                customInput={
                                                                                    <CustomDateInput
                                                                                        formControlProps={{
                                                                                            placeholder: 'MM/dd/yyyy h:mm aa',
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>End Date <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Row>
                                                                    <Field name={`resources.${index}.end_date`}>
                                                                        {({field}) => (
                                                                            <DatePicker
                                                                                selected={values?.resources[index]?.end_date ? new Date(values?.resources[index]?.end_date) : endDate}
                                                                                defaultValue=""
                                                                                name={`resources.${index}.end_date`}
                                                                                onChange={newDate => {
                                                                                    setEndDate(newDate)
                                                                                    setFieldValue(field.name, new Date(newDate).toISOString())
                                                                                }}
                                                                                showTimeSelect
                                                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                                                fixedHeight
                                                                                className='form-control'
                                                                                customInput={
                                                                                    <CustomDateInput
                                                                                        formControlProps={{
                                                                                            placeholder: 'MM/dd/yyyy h:mm aa',
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        )) : (
                                        <></>
                                    )}
                                    <IconButton
                                        onClick={() => arrayHelpers.insert(values?.resources?.length, '')}
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                    >
                                        {t('addItem', {ns: "common"})}
                                    </IconButton>
                                </>
                            )}
                />
            </Card.Body>
        </Card>
    )
}

export default EventResource