import { api } from '../../../utils/api';

export const getAddresses = async query => {
  return (await api.get(`/address/address/?${query.toString()}`)).data;
};

export const getCountries = async query => {
  return (await api.get(`/address/country/?${query.toString()}`)).data;
};

export const getDelegations = async query => {
  return (await api.get(`/address/delegation/?${query.toString()}`)).data;
};

export const getCities = async query => {
  return (await api.get(`/address/city/?${query.toString()}`)).data;
};
