import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { settings } from 'config';
import { getColor, getItemFromStore } from 'helpers/utils';
import useToggleStyle from 'hooks/useToggleStyle';
import { configReducer } from 'reducers/configReducer';

export const AppContext = createContext(settings);

const AppProvider = ({ children }) => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    isActivity: getItemFromStore('isActivity', settings.isActivity),
    isCompany: getItemFromStore('isCompany', settings.isCompany),
    isStaff: getItemFromStore('isStaff', settings.isStaff),
    lang: getItemFromStore('lang', settings.lang),
    group: getItemFromStore('group', settings.group),
    permissions: getItemFromStore('permissions', settings.permissions),
    point: getItemFromStore('point', settings.point),
    token: getItemFromStore('token', settings.token),
    avatar: getItemFromStore('avatar', settings.avatar),
    activities_joined: getItemFromStore(
      'activities_joined',
      settings.activities_joined
    ),
    activity: getItemFromStore('activity', settings.activity),
    company: getItemFromStore('company', settings.company),
    affiliate: getItemFromStore('affiliate', settings.affiliate),
    isAuthenticated: getItemFromStore(
      'isAuthenticated',
      settings.isAuthenticated
    ),
    isSuperUser: getItemFromStore("isSuperUser", settings.isSuperUser),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    settings: getItemFromStore('settings', settings.settings),
    currency: getItemFromStore('currency', settings.currency),
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
    openAuthModal: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'isActivity',
          'isCompany',
          'isSuperUser',
          'isStaff',
          'lang',
          'group',
          'permissions',
          'point',
          'token',
          'avatar',
          'activities_joined',
          'activity',
          'company',
          'affiliate',
          'isAuthenticated',
          'navbarPosition',
          'disabledNavbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
          'settings',
          'currency',
          'showBurgerMenu',
          'showSettingPanel',
          'navbarCollapsed',
          'openAuthModal'
        ].includes(key)
      }
    });
  };
  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    config.lang,
    config.group,
    configDispatch
  );

  useEffect(() => {
    const isDark =
      config.theme === 'auto'
        ? window.matchMedia('(prefers-color-scheme: dark)').matches
        : config.theme === 'dark';

    setConfig('isDark', isDark);
  }, [config.theme]);

  const changeTheme = theme => {
    const isDark =
      theme === 'auto'
        ? window.matchMedia('(prefers-color-scheme: dark)').matches
        : theme === 'dark';

    document.documentElement.setAttribute(
      'data-bs-theme',
      isDark ? 'dark' : 'light'
    );

    setConfig('theme', theme);
    setConfig('isDark', isDark);
  };

  const getThemeColor = name => getColor(name);

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark
            ? getThemeColor('dark')
            : getThemeColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider
      value={{ config, setConfig, configDispatch, changeTheme, getThemeColor }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = { children: PropTypes.node };

export const useAppContext = () => useContext(AppContext);

export default AppProvider;
