import React from 'react';
import PropTypes from 'prop-types';
import {Col, Button} from 'react-bootstrap';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SoftBadge from "../../components/common/SoftBadge";
import paths from "../../routes/paths";

const PricingDefaultCard = ({pricing, monthly}) => {

    return (
        <Col
            lg={4}
            className={classNames('border-top', {
                'dark__bg-1000 px-4 px-lg-0': pricing?.feature_set?.length > 0
            })}
            style={{backgroundColor: pricing?.feature_set?.length > 0 && 'rgba(115, 255, 236, 0.18)'}}
        >
            <div className="h100">
                <div className="text-center p-4">
                    <h3 className="fw-normal my-0">
                        {pricing?.name}
                        {!monthly && <SoftBadge pill bg={"success"}
                                                className={"fs--1 ms-2"}>Save {pricing?.yearly_saving}%</SoftBadge>}
                    </h3>
                    <p className="mt-3">{pricing?.desc}</p>
                    <h2 className="fw-medium my-4">
                        {monthly ? pricing?.monthly_price : pricing?.yearly_price} {pricing?.currency}
                        <small className="fs-10 text-700">/ {monthly ? "month" : "year"}</small>
                    </h2>
                    <Button
                        as={Link}
                        variant={pricing?.feature_set?.length > 0 ? 'primary' : 'outline-primary'}
                        to={`${paths.membershipCheckout.replace(":id", pricing?.id)}?plan=${monthly ? "month" : "year"}`}
                    >
                        Get {pricing?.name}
                    </Button>
                </div>
                <hr className="border-bottom-0 m-0"/>
                <div className="text-start px-sm-4 py-4">
                    <ul className="list-unstyled mt-3">
                        {pricing?.feature_set?.map(feature => (
                            <li className="py-1" key={feature.id}>
                                <FontAwesomeIcon icon="check" className="me-2 text-success"/>{' '}
                                {feature.name}{' '}
                                {feature.tag && (
                                    <SoftBadge pill bg={feature.tag.variant}>
                                        {feature.tag.text}
                                    </SoftBadge>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Col>
    );
};

PricingDefaultCard.propTypes = {
    pricing: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        url: PropTypes.string,
        buttonText: PropTypes.string,
        isFeatured: PropTypes.bool,
        featureTitle: PropTypes.string,
        features: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                tag: PropTypes.shape({
                    label: PropTypes.string,
                    type: PropTypes.string
                })
            })
        )
    })
};

export default PricingDefaultCard;
