import {api} from "../../../../utils/api";
import React, {Fragment, useEffect, useState} from "react";
import {usePermissions} from "../../../../providers/PermissionsProvider";
import {toast} from "react-toastify";
import Flex from "../../../../components/common/Flex";
import {Button, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import SoftBadge from "../../../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "../../../../components/common/IconButton";
import {faEdit, faSignature} from "@fortawesome/free-solid-svg-icons";
import paths from "../../../../routes/paths";
import {formatDateTime} from "../../../../helpers/utils";
import Avatar from "../../../../components/common/Avatar";
import CSRFToken from "../../../../helpers/CSRFToken";
import {useNavigate} from "react-router-dom";

const ContractContent = () => {
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState(null);
    const [signModalOpen, setSignModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        signature: "",
        tampon: null,
        is_signed: true
    })

    const {hasPermission} = usePermissions()
    const navigate = useNavigate()

    const fetchData = async () => {
        setLoading(true)
        await api.get(`/account/current_user/`).then(async (res) => {
            await api.get(`/activity/activity/${res.data.activity}/`).then(async (res) => {
                setContract(res?.data?.contract)
            })
        })
        setLoading(false)
    }

    const handleFieldChange = (e) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        await api.patch(`/contract/${contract?.id}/`, formData, {
            headers: {
                "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
            }
        })
            .then(res => {
                toast.success("Contract successfully signed!", {theme: "colored"});
                setSignModalOpen(false)
                fetchData()
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, []);

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className="mb-3 d-print-none">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <Flex>
                                <h5 className="mb-2 mb-md-0 me-2">Contract #{contract?.id}</h5>
                                <SoftBadge
                                    pill
                                    bg={classNames({
                                        success: contract?.is_signed,
                                        warning: !contract?.is_signed
                                    })}
                                    className="d-flex flex-center"
                                >
                                    <p className="mb-0">
                                        {contract?.is_signed && 'Signed'}
                                        {!contract?.is_signed && 'Not Signed'}
                                    </p>
                                    <FontAwesomeIcon
                                        icon={classNames({
                                            check: contract?.is_signed,
                                            ban: !contract?.is_signed
                                        })}
                                        transform="shrink-2"
                                        className="ms-1"
                                    />
                                </SoftBadge>
                            </Flex>

                        </Col>
                        <Col xs="auto">
                            {!contract?.is_signed &&
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon={faSignature}
                                    iconClassName="me-1"
                                    className="me-1 mb-2 mb-sm-0"
                                    onClick={() => setSignModalOpen(true)}
                                >
                                    Sign
                                </IconButton>
                            }
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="eye"
                                iconClassName="me-1"
                                className="me-1 mb-2 mb-sm-0"
                                onClick={() => navigate(paths.contractDetail.replace(":id", contract?.id))}
                            >
                                View
                            </IconButton>
                            {hasPermission("contract.change_contract") &&
                                <IconButton
                                    variant="primary"
                                    size="sm"
                                    icon={faEdit}
                                    iconClassName="me-1"
                                    className="me-1 mb-2 mb-sm-0"
                                    onClick={() => navigate(paths.contractEdit.replace(":id", contract?.id))}
                                >
                                    Edit
                                </IconButton>
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className={"d-print-block"}>
                <Card.Body id={"contract-body"}>
                    <h5 className={"text-center text-uppercase text-decoration-underline fw-bold"}>{contract?.name}</h5>
                    <p className={"fw-bold text-decoration-underline my-4"}>Between the undersigned hereinafter
                        designated:</p>
                    <p className={"fw-bold my-4"}>Firstly</p>
                    <p className={"my-4"}><span className={"fw-bold"}>Nexameet</span> company under Tunisian law.
                        Represented by
                        its manager Mr. Ghassen BOUGACHA.</p>
                    <p className={"my-4"}>And</p>
                    <p className={"fw-bold my-4"}>On the other hand</p>
                    <div className={"fw-bold my-4"}>
                        <p>Tax Number/Unique Identifier : {contract?.activity_identifier}</p>
                        <p>Social reason: {contract?.activity_name}</p>
                        <p>Address : {contract.activity_address}</p>
                        <p>Bank RIB : {contract?.activity_rib}</p>
                    </div>
                    <p className={"my-4"}>Hereinafter “<span className={"fw-bold"}>Organizer</span>”</p>
                    <p className={"fw-bold text-decoration-underline my-4"}>The following is previously stated:</p>
                    <p>
                        <span className={"fw-bold"}>Nexameet</span>, Company under Tunisian law and <span
                        className={"fw-bold"}>Organizer</span>, have jointly decided
                        agreement to establish this agreement in order to specify the conditions of their
                        collaboration.
                    </p>
                    <p className={"my-4"}>This exposed, it was decided and agreed as follows:</p>
                    <p className={"fw-bold text-decoration-underline my-4"}><span
                        className={"text-uppercase"}>Article</span> 1 :
                        Purpose of the agreement :</p>
                    <ul className={"my-4"}>
                        <li>
                            <span className={"fw-bold"}>Organisateur</span>, entrusted by this contract to nexameet.com
                            brand of
                            Nexameet, event management for the organization.
                        </li>
                        <li>
                            <span className={"fw-bold"}>Organisateur</span>, entrusted by this contract to nexameet.com
                            brand
                            of Nexameet, the exclusive sectoral sale of tickets on its nexameet.com event platform from
                            the signing
                            of this contract.
                        </li>
                        <li>
                            <span className={"fw-bold"}>Organisateur</span>, entrusted by this contract to nexameet.com
                            brand
                            of <span className={"fw-bold"}>Nexameet</span>, the exclusive customizations and management
                            on its
                            nexameet.com event platform from the
                            signing
                            of this contract.
                        </li>
                    </ul>
                    <p className={"fw-bold text-decoration-underline my-4"}><span
                        className={"text-uppercase"}>Article</span> 2 :
                        Organizer's commitments:</p>
                    <ul className={"my-4"}>
                        <li>
                            <span className={"fw-bold"}>Organisateur</span>, hereby certifies having obtained the
                            authorizations
                            necessary for local and national authorities to establish the
                            representation and is committed, also to respecting the timing and presence
                            artists appearing on the official program of the event which is part
                            entirety of this contract.
                        </li>
                        <li>
                            <span className={"fw-bold"}>Organisateur</span>, undertakes not to sell or sign a contract
                            with a company
                            competing with <span className={"fw-bold"}>Nexameet</span> which offers the event
                            management.
                        </li>
                        <li>
                            <span className={"fw-bold"}>Organisateur</span>, undertakes to announce nexameet.com
                            in <span
                            className={"fw-bold"}>all media
							communication</span> planned for the promotion of its event (Social networks,
                            flyers, urban displays, radio/TV spots, etc.) as the official event management partner.
                        </li>
                    </ul>
                    <p className={"fw-bold text-decoration-underline my-4"}><span
                        className={"text-uppercase"}>Article</span> 3 :
                        The commitments of Nexameet consist of:</p>
                    <ul className={"my-4"}>
                        <li>
                            <span className={"fw-bold"}>Nexameet</span> undertakes to market the events mentioned above
                            via
                            its <span className={"fw-bold"}>Nexameet</span> ticketing platform
                        </li>
                        <li>
                            <span className={"fw-bold"}>Nexameet</span> undertakes to carry out all
                            operations
                            public distribution agreed with the organizer.
                        </li>
                        <li>
                            <span className={"fw-bold"}>Nexameet</span> undertakes to deliver to
                            the <span
                            className={"fw-bold"}>Organizer</span>
                            access to track sales instantly.
                        </li>
                        <li>
                            <span className={"fw-bold"}>Nexameet</span> is committed and following
                            the request
                            of the <span className={"fw-bold"}>Organizer</span> to send a control team to the site on
                            the day of
                            events for any sale exceeding 300 tickets per event.
                        </li>
                    </ul>
                    <p className={"fw-bold text-decoration-underline my-4"}><span
                        className={"text-uppercase"}>Article</span> 4 :
                        Payment terms:</p>
                    <ul className={"my-4"}>
                        <li>
                            A commission of <span className={"fw-bold"}>10 %</span> excluding VAT will be charged on the
                            price of all
                            tickets sold via the <span className={"fw-bold"}>Nexameet</span> platform and will be
                            retained by
                            <span className={"fw-bold"}> Nexameet</span> upon payment.
                        </li>
                        <li>
                            Each assistance agent for access control will be invoiced 70 Dt excluding tax and excluding
                            travel costs
                            (No travel costs for greater Tunis)
                        </li>
                        <li>
                            <span className={"fw-bold"}> 1.5 dt</span> will be charged on each invitation or physical
                            ticket generated
                            via the
                            <span className={"fw-bold"}> Nexameet</span> platform at the request of the Organizer.
                        </li>
                    </ul>
                    <p className={"fw-bold text-decoration-underline my-4"}><span
                        className={"text-uppercase"}>Article</span> 5 :
                        Renewal and Cancellation:</p>
                    <p className={"my-4"}>
                        In the event of cancellation of the event, the Organizer undertakes to ensure the
                        reimbursement of all customers and to clearly explain that Nexameet is only a simple
                        service in order to avoid any confusion between service and organizer. <br/>
                        <span className={"fw-bold text-decoration-underline"}>Important:</span> In case of cancellation
                        of the event
                        Nexameet
                        retains 4% excluding tax from the price of tickets sold via the Nexameet platform. Organizer
                        undertakes to ensure the payment of these 4% excluding tax which represents the costs incurred
                        by
                        Nexameet upon receipt of the various Ticket payments.
                    </p>
                    <p>
                        This agreement takes effect upon signature. It is concluded for a period of
                        three (3) years.
                    </p>
                    <p>
                        This agreement may be canceled by one of the parties by deactivating the organization from the
                        Nexameet
                        platform.
                    </p>
                    <p>
                        Failure to respect the commitments (Article 2 and Article 3) of an “Organizer” party
                        or another “Nexameet” stipulates the immediate cessation of sales.
                    </p>
                    <p className={"fw-bold text-decoration-underline my-4"}><span
                        className={"text-uppercase"}>Article</span> 6 :
                        Force Majeure:</p>
                    <p>
                        The existence and content of this contract may be interrupted in CASE OF FORCE MAJEURE in light
                        of article
                        No. 283 of the Code of Obligations and Contracts.
                    </p>
                    <p className={"fw-bold text-decoration-underline my-4"}><span
                        className={"text-uppercase"}>Article</span> 7 :
                        Confidentiality:</p>
                    <p>
                        The existence and content of this contract are confidential between the parties.
                        Any disclosure outside legal cases may result in its immediate termination,
                        in addition to all damages.
                    </p>
                    <p className={"fw-bold text-decoration-underline my-4"}><span
                        className={"text-uppercase"}>Article</span> 8 :
                        Clause attributing territorial jurisdiction:</p>
                    <p>
                        Any dispute arising from the execution or non-execution hereof will be under the jurisdiction of
                        the court
                        of first instance of Tunis.
                    </p>
                    <p>
                        Contract made in Tunis in one copy, on {formatDateTime(contract?.created_at)}
                    </p>
                    <Flex justifyContent={"between"}>
                        <p>
                            <span className={"fw-bold"}>Read and approved</span> <br/>
                            <span>For <span className={"fw-bold"}>Nexameet</span></span> <br/>
                            <span>M. Ghassen Bougacha</span>
                        </p>
                        <p>
                            <span className={"fw-bold"}>Read and approved</span> <br/>
                            <span>For <span className={"fw-bold"}>Organizer</span></span> <br/>
                            <span>{contract?.signature}</span> <br/>
                            {contract?.tampon &&
                                <Avatar src={contract?.tampon} size={"4xl"}/>
                            }
                        </p>
                    </Flex>
                </Card.Body>
            </Card>

            <Modal centered size={"lg"} show={signModalOpen} onHide={() => setSignModalOpen(!signModalOpen)}>
                <Modal.Header closeButton>
                    <h5>I have read and accept the terms of this contract</h5>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <CSRFToken/>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Signature</Form.Label>
                                    <Form.Control
                                        name={"signature"}
                                        value={formData.signature}
                                        onChange={handleFieldChange}
                                        placeholder={"Signature"}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Buffer</Form.Label>
                                    <Form.Control
                                        type={"file"}
                                        name={"tampon"}
                                        src={formData.tampon}
                                        onChange={(e) => setFormData({...formData, tampon: e.target.files[0]})}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Sign <FontAwesomeIcon icon={faSignature} className="ms-2"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default ContractContent