import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LinePayment from './line-payment/LinePayment';
import {
  transactionSummary
} from 'data/dashboard/saas';
import SaasActiveUser from './SaasActiveUser';
import SaasRevenue from './SaasRevenue';
import SaasConversion from './SaasConversion';
import TransactionSummary from './TransactionSummary';
import GrossRevenue from './gross-revenue/GrossRevenue';
import ActiveUsers from "../ActiveUsers";

const Saas = () => {
  return (
    <>
      <Row className="g-3">
        <Col xxl={9}>
          <LinePayment />
        </Col>
        <Col>
          <Row className="g-3">
            <Col md={4} xxl={12}>
              <SaasActiveUser />
            </Col>
            <Col md={4} xxl={12}>
              <SaasRevenue />
            </Col>
            <Col md={4} xxl={12}>
              <SaasConversion />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <Row className="g-3 mt-1">
            <Col lg={4}>
              <ActiveUsers end={7} />
            </Col>
            <Col lg={8}>
              <GrossRevenue />
            </Col>
          </Row>
        </Col>
      </Row>
      <TransactionSummary data={transactionSummary} />
    </>
  );
};

export default Saas;
