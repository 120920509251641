import BaseExportTable from "../../../BaseExportTable";
import React from "react";
import {withTranslation} from "react-i18next";
import paths from "../../../../routes/paths";
import {withPermission} from "../../../../helpers/utils";

const MemberImportFiles = ({t, i18n}) => {
	return <BaseExportTable t={t} i18n={i18n} title={"memberimport"} url={"/member/import-member/"}
							creationPath={paths.importMember} addPermission={"member.add_memberimportfile"}
							detailsPath={paths.membersImportDetail}
	/>
}

export default withTranslation("common")(withPermission(MemberImportFiles, "member.view_memberimportfile"));