import FalconCardHeader from 'components/common/FalconCardHeader';
import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap';
import Avatar, {AvatarGroup} from 'components/common/Avatar';
import Statistics from './Statistics';
import ProjectTable from './ProjectTable';
import {api} from "../../../../utils/api";
import Flex from "../../../../components/common/Flex";

const ProjectStatistics = () => {
	const [loading, setLoading] = useState(false)
	const [progressBar, setProgressBar] = useState([])
	const [tableData, setTableData] = useState([])
	const [members, setMembers] = useState([])

	const getData = async () => {
		setLoading(true)
		await api.get("/dashboard/activitystatistics/progress/").then(res => {
			setProgressBar(res?.data?.progress)
			setTableData(res?.data?.table)
			setMembers(res?.data?.members)
		})
		setLoading(false)
	}

	useEffect(() => {
		getData()
		// eslint-disable-next-line
	}, []);

	return (
		<Card className="h-100">
			<FalconCardHeader
				title="Activity Statistics"
				titleTag="h6"
			/>
			{loading ? (
				<Flex justifyContent="center" className="p-2 mb-2">
					<Spinner animation={'border'} variant={'primary'}/>
				</Flex>
			) : (
				<>
					<Card.Body className="pt-0">
						<Statistics data={progressBar}/>

						<p className="fs--1 mb-2 mt-3">Members</p>
						<AvatarGroup dense>
							{members?.map(({img, name, id}) => {
								return (
									<Avatar
										src={img && img}
										key={id}
										name={name && name}
										isExact
										size="2xl"
										className="border border-3 rounded-circle border-light"
									/>
								);
							})}
						</AvatarGroup>

						<ProjectTable data={tableData}/>
					</Card.Body>
				</>
			)}
		</Card>
	);
};

export default ProjectStatistics;
