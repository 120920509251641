import useQuery from "../../../../../hooks/useQuery";
import {api} from "../../../../../utils/api";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import paths from "../../../../../routes/paths";

const TransactionKonnectWebhook = () => {
    const query = useQuery()
    const navigate = useNavigate()

    const verifyPayment = async () => {
        await api.get(`konnect/transaction/webhook/?${query.toString()}`).then((response) => {
            navigate(paths.transactionDetail.replace(":id", response.data.id))
        })
            .catch(() => {})
    }

    useEffect(() => {
        verifyPayment()
        // eslint-disable-next-line
    }, []);
}

export default TransactionKonnectWebhook