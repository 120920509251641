import {Card} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from "react";
import {api} from "../../../utils/api";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import useQuery from "../../../hooks/useQuery";
import SocialAuthButtons from "../SocialAuthButtons";
import ConnectionsList from "./ConnectionsList";
import {withPermission} from "../../../helpers/utils";

const Connections = ({showDate, showSocialButtons}) => {
    const [connections, setConnections] = useState([])
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)

    let query = useQuery()

    const getConnections = async (q) => {
        setLoading(true)
        await api.get(`/account/socialaccounts/?${q.toString()}`).then(res => {
            setConnections(res?.data?.results)
            setCount(res?.data?.count)
        })
        setLoading(false)
    }

    useEffect(() => {
        getConnections(query)
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <h5>Social Account Connections</h5>
                </Card.Header>
                <Card.Body>
                    <ConnectionsList showDate={true} loading={loading}/>
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        count={count}
                        fetch={getConnections}
                        itemsPerPage={10}
                        query={query}
                        length={connections?.length}
                    />
                </Card.Footer>
            </Card>
            {showSocialButtons &&
                <Card className={"mt-3"}>
                    <Card.Header>
                        <h5>Add a 3rd Party Account</h5>
                    </Card.Header>
                    <Card.Body>
                        <SocialAuthButtons type={"connect"}/>
                    </Card.Body>
                </Card>
            }
        </Fragment>
    )
}

export default withPermission(Connections, "socialaccount.view_socialaccount")