import {withPermission} from "../../../helpers/utils";
import React, {Fragment, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import CSRFToken from "../../../helpers/CSRFToken";
import FormError from "../../errors/FormError";
import TinymceEditor from "../../../components/common/TinymceEditor";
import IconButton from "../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import paths from "../../../routes/paths";
import {useNavigate} from "react-router-dom";

const TicketCreate = () => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        subject: "",
        status: "recent",
        priority: "high",
        group: "unassigned",
        type: "question",
        category: "general",
        tag: "support",
        message: ""
    })

    const navigate = useNavigate()

    const handleFieldChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async e => {
        setLoading(true)
        await api.post("/support/tickets/", formData)
            .then(res => {
                toast.success("Ticket added successfully.", {theme: "colored"})
                navigate(paths.detailSupportTicket.replace(":id", res?.data?.id))
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
                setErrors(err?.response?.data)
            })
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Form>
                <CSRFToken/>
                <Row className={"g-3"}>
                    <Col lg={6}>
                        <Card>
                            <Card.Header>
                                <h5>Basic Information</h5>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label>Subject <span className={"text-danger"}>*</span></Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        name={"subject"}
                                        placeholder={"Subject"}
                                        value={formData.subject}
                                        onChange={handleFieldChange}
                                    />
                                    <FormError error={errors?.subject}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Status <span className={"text-danger"}>*</span></Form.Label>
                                    <Form.Select
                                        name={"status"}
                                        value={formData.status}
                                        onChange={handleFieldChange}
                                    >
                                        <option value={""}>Select status</option>
                                        <option value={"recent"}>Recent</option>
                                        <option value={"overdue"}>Overdue</option>
                                        <option value={"closed"}>Closed</option>
                                        <option value={"remaining"}>Remaining</option>
                                        <option value={"responded"}>Responded</option>
                                    </Form.Select>
                                    <FormError error={errors?.status}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Priority <span className={"text-danger"}>*</span></Form.Label>
                                    <Form.Select
                                        name={"priority"}
                                        value={formData.priority}
                                        onChange={handleFieldChange}
                                    >
                                        <option value={""}>Select priority</option>
                                        <option value={"urgent"}>Urgent</option>
                                        <option value={"high"}>High</option>
                                        <option value={"medium"}>Medium</option>
                                        <option value={"low"}>Low</option>
                                    </Form.Select>
                                    <FormError error={errors?.priority}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Group <span className={"text-danger"}>*</span></Form.Label>
                                    <Form.Select
                                        name={"group"}
                                        value={formData.group}
                                        onChange={handleFieldChange}
                                    >
                                        <option value={""}>Select group</option>
                                        <option value={"billing"}>Billing</option>
                                        <option value={"support"}>Customer Support</option>
                                        <option value={"enhancement"}>Enhancement</option>
                                        <option value={"unassigned"}>Unassigned</option>
                                    </Form.Select>
                                    <FormError error={errors?.group}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Type <span className={"text-danger"}>*</span></Form.Label>
                                    <Form.Select
                                        name={"type"}
                                        value={formData.type}
                                        onChange={handleFieldChange}
                                    >
                                        <option value={""}>Select type</option>
                                        <option value={"question"}>Question</option>
                                        <option value={"incident"}>Incident</option>
                                        <option value={"problem"}>Problem</option>
                                        <option value={"feature"}>Feature Request</option>
                                        <option value={"refund"}>Refund</option>
                                    </Form.Select>
                                    <FormError error={errors?.type}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Category <span className={"text-danger"}>*</span></Form.Label>
                                    <Form.Select
                                        name={"category"}
                                        value={formData.category}
                                        onChange={handleFieldChange}
                                    >
                                        <option value={""}>Select category</option>
                                        <option value={"general"}>General Issue</option>
                                        <option value={"maintenance"}>Maintenance</option>
                                        <option value={"engineering"}>Engineering</option>
                                        <option value={"accounts"}>Accounts</option>
                                        <option value={"feedback"}>Feedback</option>
                                        <option value={"support"}>Support</option>
                                        <option value={"test"}>Test Helpdesk</option>
                                    </Form.Select>
                                    <FormError error={errors?.category}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Tag <span className={"text-danger"}>*</span></Form.Label>
                                    <Form.Select
                                        name={"tag"}
                                        value={formData.tag}
                                        onChange={handleFieldChange}
                                    >
                                        <option value={""}>Select tag</option>
                                        <option value={"sales"}>Sales</option>
                                        <option value={"request"}>Request</option>
                                        <option value={"complaint"}>Complaint</option>
                                        <option value={"feedback"}>Feedback</option>
                                        <option value={"support"}>Support</option>
                                    </Form.Select>
                                    <FormError error={errors?.tag}/>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <Card.Header>
                                <h5>Message</h5>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label>Message <span className={"text-danger"}>*</span></Form.Label>
                                    <TinymceEditor
                                        height={"49vh"}
                                        value={formData.message}
                                        handleChange={newValue => setFormData({...formData, message: newValue})}
                                    />
                                    <FormError error={errors?.message}/>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Footer>
                                <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                    <p className={"text-danger"}>* Mandatory Field</p>
                                    <IconButton
                                        icon={faSave}
                                        onClick={e => handleSubmit(e)}
                                    >
                        <span className="d-none d-sm-inline-block ms-1">
                          Save
                        </span>
                                    </IconButton>
                                </Flex>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}

export default withPermission(TicketCreate, "support.add_supportticket")
