import React from 'react';
import {Card, Row, Col, Form} from 'react-bootstrap';
import {useState} from 'react';
import {useEffect} from 'react';
import IconButton from 'components/common/IconButton';

import EventCustomFieldItem from './EventCustomFieldItem';
import {FieldArray, useFormikContext} from "formik";
import FalconCardHeader from "../../../components/common/FalconCardHeader";

const EventCustomField = ({t, errors, handleRemove}) => {
    const [name, setName] = useState('');
    const [type, setType] = useState('text');
    const [options, setOptions] = useState('');
    const [hasOptions, setHasOptions] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const {values, setFieldValue} = useFormikContext();

    useEffect(() => {
        setHasOptions(
            type === 'select' || type === 'checkboxes' || type === 'radio'
        );
    }, [type]);

    useEffect(() => {
        let disableState = true;
        if (!hasOptions && name.length) {
            disableState = false;
        }
        if (hasOptions && options.length && name.length) {
            disableState = false;
        }
        setDisabled(disableState);
    }, [name, type, hasOptions, options]);

    const handleSubmit = (arrayHelpers) => {
        if (hasOptions) {
            const updatedOptions = options.split(',');
            arrayHelpers.push({
                name,
                type,
                options: updatedOptions,
                value: ''
            });
        } else {
            arrayHelpers.push({
                name,
                type,
                options: [],
                value: ''
            });
        }

        setName('');
        setType('text');
        setOptions('');
        setHasOptions(false);
        setDisabled(true);
    };

    return (
        <FieldArray
            name="custom_fields"
            render={arrayHelpers => (
                <Card className={"mt-3"}>
                    <FalconCardHeader
                        light
                        title={"Custom Fields"}
                        titleTag="h5"
                        className="py-2"
                        endEl={<IconButton
                            variant="falcon-default"
                            icon="trash"
                            size={'sm'}
                            iconClassName={'ms-1 text-danger'}
                            onClick={() => handleRemove("custom_fields")}
                        >
                            {' '}
                        </IconButton>}
                    />
                    {values?.custom_fields?.length > 0 && (
                        <Card.Body>
                            {values?.custom_fields?.map((customField, index) => (
                                <EventCustomFieldItem
                                    {...customField}
                                    index={index}
                                    setFieldValue={setFieldValue}
                                    key={customField.id}
                                    handleRemove={() => arrayHelpers.remove(index)}
                                />
                            ))}
                        </Card.Body>
                    )}
                    <Card.Body className="bg-body-tertiary">
                        <div className="bg-body-emphasis border p-3 position-relative rounded-1 mb-2">
                            <Row className="gx-2 gy-3">
                                <Col md="6">
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            placeholder="Name (e.g. T-shirt)"
                                            value={name}
                                            onChange={({target}) => setName(target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Select
                                            size="sm"
                                            aria-label="Default select example"
                                            value={type}
                                            onChange={({target}) => setType(target.value)}
                                        >
                                            <option value="text">Text</option>
                                            <option value="number">Number</option>
                                            <option value="email">Email</option>
                                            <option value="password">Password</option>
                                            <option value="date">Date</option>
                                            <option value="time">Time</option>
                                            <option value="url">Url</option>
                                            <option value="checkboxes">Checkboxes</option>
                                            <option value="radio">Radio Buttons</option>
                                            <option value="textarea">Textarea</option>
                                            <option value="select">Select</option>
                                            <option value="file">File</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {hasOptions && (
                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>Field Options</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={options}
                                                onChange={({target}) => setOptions(target.value)}
                                            />
                                            <Form.Text className="text-warning fs-10">
                                                * Separate your options with comma
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                )}
                                <Col>
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        disabled={disabled}
                                        transform="shrink-3"
                                        onClick={() => handleSubmit(arrayHelpers)}
                                    >
                                        Add Item
                                    </IconButton>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Card>
            )}
        />
    );
};

export default EventCustomField;
