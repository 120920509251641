import React, {Fragment, useEffect, useRef, useState} from "react";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import Avatar from "../../../../components/common/Avatar";
import {isIterableArray} from "../../../../helpers/utils";
import avatarImg from "../../../../assets/img/team/avatar.png";
import {Field, Formik} from "formik";
import FalconDropzone from "../../../../components/common/FalconDropzone";
import Flex from "../../../../components/common/Flex";
import cloudUpload from "../../../../assets/img/icons/cloud-upload.svg";
import FormError from "../../../errors/FormError";
import {useTranslation} from "react-i18next";
import {api} from "../../../../utils/api";
import Select from "react-select";
import {getCities, getCountries, getDelegations} from "../../../address/actions/Address";
import useQuery from "../../../../hooks/useQuery";
import {Editor} from "@tinymce/tinymce-react";
import IconButton from "../../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import CSRFToken from "../../../../helpers/CSRFToken";

const ActivityProfileContent = () => {
	const [avatar, setAvatar] = useState([{src: avatarImg}]);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState({})
	const [selectedType, setSelectedType] = useState(null);
	const [countries, setCountries] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [pageCountry, setPageCountry] = useState(1);
	const [pageDelegation, setPageDelegation] = useState(1);
	const [pageCity, setPageCity] = useState(1);
	const [delegations, setDelegations] = useState([]);
	const [selectedDelegation, setSelectedDelegation] = useState(null);
	const [cities, setCities] = useState([]);
	const [selectedCity, setSelectedCity] = useState(null);

	const {t} = useTranslation(['activity', 'common']);
	let query = useQuery()
	const editorRef = useRef(null);

	const fetchData = async () => {
		setLoading(true)
		await api.get(`/account/current_user/`).then(async (res) => {
			await api.get(`/activity/activity/${res.data.activity}/`).then(async (res) => {
				setFormData({
					id: res.data.id,
					name: res.data.name,
					email: res.data.email,
					description: res.data.description,
					phone: res.data.phone,
					address: res.data.address,
					identifier: res.data.identifier,
					responsible: res.data.responsible,
					rib: res.data.rib,
					slug: res.data.slug,
				})
				setAvatar([
					{src: res?.data?.logo_url ? res?.data?.logo_url : avatarImg}
				]);
				setSelectedType({
					value: res?.data?.type,
					label: res?.data?.type_display
				});
				setSelectedCountry({
					value: res?.data?.country,
					label: res?.data?.country_name
				});
				setSelectedDelegation({
					value: res?.data?.delegation,
					label: res?.data?.delegation_name
				});
				setSelectedCity({value: res?.data?.city, label: res?.data?.city_name});
			})
		})
		setLoading(false)
	}

	const handleSubmit = async (e, values) => {
		e.preventDefault();
		setLoading(true);
		await api
			.patch(`/activity/activity/${formData?.id}/`, values)
			.then(res => {
				toast.success(`${t('title')} ${res?.data?.name} ${t('updateSuccess', {ns: "common"})}`, {
					theme: 'colored'
				});
			})
			.catch(error => {
				setErrors(error?.response?.data);
				setFormData(values);
				toast.error(`${t('error', {ns: "common"})}`, {theme: 'colored'});
			});
		setLoading(false);
	};

	useEffect(() => {
		fetchData()
	}, []);

	useEffect(() => {
		query.set('page_size', '50');
		query.set('page', pageCountry.toString());
		getCountries(query)
			.then(res => setCountries([...countries, ...res?.results]))
			.catch(() => {
			});
		// eslint-disable-next-line
	}, [pageCountry]);

	useEffect(() => {
		query.set('page_size', '50');
		query.set('page', pageDelegation.toString());
		getDelegations(query)
			.then(res => setDelegations([...delegations, ...res?.results]))
			.catch(() => {
			});
		// eslint-disable-next-line
	}, [pageDelegation]);

	useEffect(() => {
		query.set('page_size', '50');
		query.set('page', pageCity.toString());
		getCities(query)
			.then(res => setCities([...cities, ...res?.results]))
			.catch(() => {
			});
		// eslint-disable-next-line
	}, [pageCity]);

	const activityTypes = [
		{value: 'club', label: t('fields.type.club')},
		{value: 'org', label: t('fields.type.organization')},
		{value: 'com', label: t('fields.type.company')}
	];

	let countryOptions = countries?.map(country => ({
		value: country.id,
		label: country.country
	}));

	countryOptions = [
		{value: '', label: '----------------------------'},
		...countryOptions
	];

	let delegationOptions = delegations?.map(state => ({
		value: state.id,
		label: state.name
	}));

	delegationOptions = [
		{value: '', label: '----------------------------'},
		...delegationOptions
	];

	let cityOptions = cities?.map(city => ({value: city.id, label: city.name}));

	cityOptions = [
		{value: '', label: '----------------------------'},
		...cityOptions
	];

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Fragment>
			<h6>Details that apply across your events and venues</h6>
			<Formik initialValues={formData} onSubmit={values => console.log(values)}>
				{({values, setFieldValue}) => (
					<Form>
						<CSRFToken />
						<Row>
							<Col md="auto">
								<Avatar
									size="4xl"
									src={
										isIterableArray(avatar)
											? avatar[0]?.base64 || avatar[0]?.src
											: ''
									}
								/>
							</Col>
							<Col md>
								<Field name="logo">
									{() => (
										<FalconDropzone
											files={avatar}
											onChange={files => {
												setAvatar(files);
												setFieldValue('logo', files[0]);
											}}
											multiple={false}
											accept="image/*"
											placeholder={
												<>
													<Flex justifyContent="center">
														<img
															src={cloudUpload}
															alt=""
															width={25}
															className="me-2"
														/>
														<p className="fs-0 mb-0 text-700">
															{t('fields.upload')}
														</p>
													</Flex>
													<p className="mb-0 w-75 mx-auto text-400">
														{t('fields.uploadDesc')}
													</p>
												</>
											}
										/>
									)}
								</Field>
								<FormError error={errors.logo}/>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label>{t('fields.name')}:</Form.Label>
									<Field name="name">
										{({field}) => (
											<Form.Control
												type="text"
												name="name"
												placeholder={t('fields.name')}
												errors={errors}
												formGroupProps={{
													className: 'mb-3'
												}}
												onChange={e => {
													setFieldValue(
														field.name,
														e.target.value
													);
												}}
												value={field.value}
											/>
										)}
									</Field>
									<FormError error={errors.name}/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>{t('fields.email')}:</Form.Label>
									<Field name="email">
										{({field}) => (
											<Form.Control
												type="email"
												label={t('fields.email')}
												name="email"
												placeholder={t('fields.email')}
												errors={errors}
												formGroupProps={{
													className: 'mb-3'
												}}
												onChange={e => {
													setFieldValue(
														field.name,
														e.target.value
													);
												}}
												value={field.value}
											/>
										)}
									</Field>
									<FormError error={errors.email}/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>{t('fields.phone')}:</Form.Label>
									<Field name="phone">
										{({field}) => (
											<Form.Control
												type="number"
												label={t('fields.phone')}
												name="phone"
												placeholder={t('fields.phone')}
												errors={errors}
												formGroupProps={{
													className: 'mb-3'
												}}
												onChange={e => {
													setFieldValue(
														field.name,
														e.target.value
													);
												}}
												value={field.value}
											/>
										)}
									</Field>
									<FormError error={errors.phone}/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>{t('fields.identifier')}:</Form.Label>
									<Field name="identifier">
										{({field}) => (
											<Form.Control
												label={t('fields.identifier')}
												name="identifier"
												placeholder={t('fields.identifier')}
												errors={errors}
												formGroupProps={{
													className: 'mb-3'
												}}
												onChange={e => {
													setFieldValue(
														field.name,
														e.target.value
													);
												}}
												value={field.value}
											/>
										)}
									</Field>
									<FormError error={errors.identifier}/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label>{t('fields.rib')}:</Form.Label>
									<Field name="rib">
										{({field}) => (
											<Form.Control
												label={t('fields.rib')}
												name="rib"
												placeholder={t('fields.rib')}
												errors={errors}
												formGroupProps={{
													className: 'mb-3'
												}}
												onChange={e => {
													setFieldValue(
														field.name,
														e.target.value
													);
												}}
												value={field.value}
											/>
										)}
									</Field>
									<FormError error={errors.rib}/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>{t('fields.responsible')}:</Form.Label>
									<Field name="responsible">
										{({field}) => (
											<Form.Control
												label={t('fields.responsible')}
												name="responsible"
												placeholder={t('fields.responsible')}
												errors={errors}
												formGroupProps={{
													className: 'mb-3'
												}}
												onChange={e => {
													setFieldValue(
														field.name,
														e.target.value
													);
												}}
												value={field.value}
											/>
										)}
									</Field>
									<FormError error={errors.responsible}/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label>{t('fields.slug')}:</Form.Label>
									<Field name="slug">
										{({field}) => (
											<Form.Control
												type="text"
												label={t('fields.slug')}
												name="slug"
												placeholder={t('fields.slug')}
												errors={errors}
												formGroupProps={{
													className: 'mb-3'
												}}
												onChange={e => {
													setFieldValue(
														field.name,
														e.target.value
													);
												}}
												value={field.value}
											/>
										)}
									</Field>
									<p className={'text-muted fs--1'}>
										{t('fields.slugDesc')}
									</p>
									<FormError error={errors.slug}/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>{t('fields.type.title')}:</Form.Label>
									<Field name="type">
										{({field}) => (
											<Select
												options={activityTypes}
												value={selectedType}
												classNamePrefix="react-select"
												onChange={value => {
													setSelectedType(value);
													setFieldValue(
														field.name,
														value.value
													);
												}}
												placeholder={`${t('select', {ns: "common"})} ${t('fields.type.title')}`}
											/>
										)}
									</Field>
									<FormError error={errors.type}/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Group>
								<Field name="address">
									{({field}) => (
										<Form.Control
											label={t('fields.address')}
											name="address"
											errors={errors}
											formGroupProps={{
												className: 'mb-3'
											}}
											onChange={e =>
												setFieldValue(field.name, e.target.value)
											}
											placeholder={t('fields.address')}
											value={field.value}
										/>
									)}
								</Field>
								<FormError error={errors.address}/>
							</Form.Group>
						</Row>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>{t('fields.country')}:</Form.Label>
									<Field name="country">
										{({field}) => (
											<Select
												options={countryOptions}
												value={selectedCountry}
												classNamePrefix="react-select"
												onChange={value => {
													setSelectedCountry(value);
													setFieldValue(field.name, value.value);
													query.set('country_id', value.value);
													getDelegations(query).then(r =>
														setDelegations(r?.results)
													);
												}}
												placeholder={`${t('select', {ns: "common"})} ${t('fields.country')}`}
												onMenuScrollToBottom={() => {
													setPageCountry(page => page + 1);
												}}
												onInputChange={e => {
													query.set('search', e);
													getCountries(query).then(r =>
														setCountries(r?.results)
													);
												}}
											/>
										)}
									</Field>
									<FormError error={errors.country}/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>{t('fields.state')}:</Form.Label>
									<Field name="delegation">
										{({field}) => (
											<Select
												options={delegationOptions}
												value={selectedDelegation}
												classNamePrefix="react-select"
												onChange={value => {
													setSelectedDelegation(value);
													setFieldValue(field.name, value.value);
													query.set('state_id', value.value);
													getCities(query).then(r =>
														setCities(r?.results)
													);
												}}
												placeholder={`${t('select', {ns: "common"})} ${t('fields.state')}`}
												onMenuScrollToBottom={() => {
													setPageDelegation(page => page + 1);
												}}
												onInputChange={e => {
													query.set('search', e);
													getDelegations(query).then(r =>
														setDelegations(r?.results)
													);
												}}
											/>
										)}
									</Field>
									<FormError error={errors.delegation}/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>{t('fields.city')}:</Form.Label>
									<Field name="city">
										{({field}) => (
											<Select
												options={cityOptions}
												value={selectedCity}
												classNamePrefix="react-select"
												onChange={value => {
													setSelectedCity(value);
													setFieldValue(field.name, value.value);
												}}
												placeholder={`${t('select', {ns: "common"})} ${t('fields.city')}`}
												onMenuScrollToBottom={() => {
													setPageCity(page => page + 1);
												}}
												onInputChange={e => {
													query.set('search', e);
													getCities(query).then(r =>
														setCities(r?.results)
													);
												}}
											/>
										)}
									</Field>
									<FormError error={errors.delegation}/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Group>
								<Form.Label>Description:</Form.Label>
								<Field name="description">
									{({field}) => (
										<Editor
											apiKey={process.env.REACT_APP_TINY_API_KEY}
											onInit={(evt, editor) =>
												(editorRef.current = editor)
											}
											initialValue={field.value}
											init={{
												height: 500,
												menubar: false,
												plugins: [
													'advlist autolink lists link image charmap print preview anchor',
													'searchreplace visualblocks code fullscreen',
													'insertdatetime media table paste code help wordcount'
												],
												toolbar:
													'undo redo | formatselect | ' +
													'bold italic backcolor | alignleft aligncenter ' +
													'alignright alignjustify | bullist numlist outdent indent | ' +
													'removeformat | help',
												content_style:
													'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
											}}
											onChange={() => {
												setFieldValue(
													field.name,
													editorRef.current.getContent()
												);
											}}
										/>
									)}
								</Field>
								<FormError error={errors.description}/>
							</Form.Group>
						</Row>
						<Row>
							<Flex justifyContent={'end'} wrap={'wrap'} className={"mt-3"}>
								<IconButton
									icon={faSave}
									onClick={e => handleSubmit(e, values)}
								>
                      <span className="d-none d-sm-inline-block ms-1">
                        {t('save', {ns: "common"})}
                      </span>
								</IconButton>
							</Flex>
						</Row>
					</Form>
				)}
			</Formik>
		</Fragment>
	)
}

export default ActivityProfileContent