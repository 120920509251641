import React, {useCallback, useEffect, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import Event from './Event';
import Flex from 'components/common/Flex';
import IconButton from '../../components/common/IconButton';
import {useNavigate} from 'react-router-dom';
import {getEvents} from './actions/Events';
import useQuery from '../../hooks/useQuery';
import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from '../../components/common/advance-table/AdvanceTablePagination';
import {debounce} from 'lodash';
import {withPermission} from '../../helpers/utils';
import {useAppContext} from '../../providers/AppProvider';
import paths from "../../routes/paths";
import {usePermissions} from "../../providers/PermissionsProvider";
import {useTranslation} from "react-i18next";
import {faFileImport} from "@fortawesome/free-solid-svg-icons";

const EventList = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();

    const {t} = useTranslation(['events', 'common']);

    const {hasPermission} = usePermissions()

    const navigate = useNavigate();
    const query = useQuery();

    const fetchEvents = async q => {
        setLoading(true);

        // Cancel the previous request if it exists
        getEvents(q)
            .then(res => {
                setEvents(res?.results);
                setCount(res?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLength(events.length);
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchEvents, 600), []);

    useEffect(() => {
        fetchEvents(query);
        setLength(events?.length);
        // eslint-disable-next-line
    }, []);

    return (
        <Card loading={loading}>
            <Card.Header
                as={Flex}
                justifyContent="between"
                alignItems="center"
                className="bg-light"
            >
                <h5 className="mb-0"> {t('titles.event', {ns: "common"})}</h5>
                <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox fetch={delayedLoadItems} placeholder={t('search', {ns: "common"})}/>
                </Col>
                <Col xs={"auto"}>
                    {hasPermission("event.add_event") &&
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                            className="ms-2"
                            onClick={() => navigate(paths.createEvent)}
                        >
                            <span className="d-none d-sm-inline-block ms-1">{t('new', {ns: "common"})}</span>
                        </IconButton>
                    }
                    {hasPermission('event.view_eventimportfile') &&
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon={faFileImport}
                            transform="shrink-3"
                            className="ms-2"
                            onClick={() => navigate(paths.importList)}
                        >
                            <span className="d-none d-sm-inline-block ms-1">{t('import', {ns: "common"})}</span>
                        </IconButton>
                    }
                </Col>
            </Card.Header>
            <Card.Body className="fs--1">
                <Row>
                    {events?.map((event, index) => (
                        <Col key={event.id} md={6} className="h-100">
                            <Event details={event} isLast={index === events.length - 1} t={t}/>
                        </Col>
                    ))}
                </Row>
            </Card.Body>
            <Card.Footer>
                <AdvanceTablePagination
                    query={query}
                    fetch={fetchEvents}
                    count={count}
                    length={length}
                    itemsPerPage={20}
                />
            </Card.Footer>
        </Card>
    );
};

export default withPermission(EventList, "event.view_event");
