import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import paths from "../../../routes/paths";

const EventFooter = ({values, setFormData, setLoading, setErrors, edit, id, t}) => {
  const navigate = useNavigate()
  const handleSubmit = async () => {
    setLoading(true)
    setFormData(values);
    console.log(values)
    if (edit){
      await api.patch(`/event/event/${id}/`, values)
        .then((res) => {
          toast.success(`${t('title')} ${t('updateSuccess', {ns: "common"})}`, {theme: "colored"})
          navigate(paths.eventDetail.replace(":id", res?.data?.id))
        })
        .catch((err) => {
          setErrors(err?.response?.data)
          toast.error(t('error', {ns: "common"}), {theme: "colored"})
        })
    } else {
      await api.post('/event/event/', values)
        .then((res) => {
          toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: "colored"})
          navigate(paths.eventDetail.replace(":id", res?.data?.id))
        })
        .catch((err) => {
          setErrors(err?.response?.data)
          toast.error(t('error', {ns: "common"}), {theme: "colored"})
        })
    }
    setLoading(false)
  }
  return (
    <Card className={"mt-3"}>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">{t('nice')}</h5>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              type="button"
              onClick={handleSubmit}
            >
              {t('save', {ns: "common"})}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EventFooter;
