import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import Section from "../../../components/common/Section";
import eventBanner from "../../../assets/img/generic/13.jpg";

const BlogDetail = () => {
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(false);

    const {id} = useParams();

    const fetchPost = async () => {
        setLoading(true);
        await api.get(`/blog/post/${id}/`).then((res) => {
            setPost(res.data);
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchPost()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Section>
            <Card className="p-0 mb-3">
                <Card.Header>
                    <img
                        className="card-img-top"
                        src={post?.photo_url ? post?.photo_url : eventBanner}
                        alt={post?.title}
                    />
                </Card.Header>
                <Card.Body>
                    <Row>
                        <h3>{post?.title}</h3>
                    </Row>
                    <Row>
                        <p className={"text-muted"}>{post?.author_name}</p>
                    </Row>
                    <Row>
                        <Col>
                            <div dangerouslySetInnerHTML={{__html: post?.content}}></div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Section>
    )
}

export default BlogDetail