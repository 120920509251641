import {Button, Form, Modal, Spinner} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {useAppContext} from "../../../providers/AppProvider";
import CSRFToken from "../../../helpers/CSRFToken";
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";
import Select from "react-select";
import FormError from "../../errors/FormError";
import {Editor} from "@tinymce/tinymce-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Flex from "../../../components/common/Flex";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

const EventShareModal = ({open, setOpen, event}) => {
	const [categories, setCategories] = useState([])
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [pageCategory, setPageCategory] = useState(1);
	const [categoryForm, setCategoryForm] = useState(false)
	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const [loading, setLoading] = useState(false)
	const [categoryFormData, setCategoryFormData] = useState({
		categoryName: ""
	})
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState({
		event: event?.id,
		activity: event?.activity,
		title: event?.title,
		description: event?.description,
		category: ""
	})

	const {
		config: {isDark}
	} = useAppContext();

	const {id} = useParams()
	const editorRef = useRef(null)
	const query = useQuery();

	const getEvent = async () => {
		api.get(`/event/event/${id}/`).then(res => setFormData({...formData,
			title: res?.data?.title,
			event: res?.data?.id,
			activity: res?.data?.activity,
			description: res?.data?.description
		}))
	}

	const noOptionsMessage = ({inputValue}) => {
		const handleAddNew = () => {
			setIsSelectOpen(false);
			setCategoryForm(true);
			setSelectedCategory('');
			setCategoryFormData({...categoryFormData, categoryName: inputValue});
		};

		return (
			<>
				<div onClick={handleAddNew} style={{cursor: 'pointer'}}>
					Add new
				</div>
			</>
		);
	};

	const handleChange = (value) => {
		setSelectedCategory(value);
		setFormData({...formData, category: value.value});
		setCategoryFormData({...categoryFormData, categoryName: value.label});
		setCategoryForm(false); // Close the Category form when an option is selected
	};

	const getCategories = async () => {
		query.set('page_size', '50');
		query.set('page', pageCategory.toString());
		await api.get(`/feed/category/?${query.toString()}`).then(res => setCategories([...categories, ...res?.data?.results]))
	}

	useEffect(() => {
		getEvent()
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getCategories()
		// eslint-disable-next-line
	}, [pageCategory]);

	let categoryOptions = categories?.map(category => ({
		value: category?.id,
		label: category?.name
	}));

	categoryOptions = [
		{value: '', label: '----------------------------'},
		...categoryOptions
	];

	const handleFieldChange = ({target}) => {
		setFormData({...formData, [target.name]: target.value})
	}

	const handleSubmit = async (e) => {
		setLoading(true)
		await api.post("/feed/postevent/", {...formData, ...categoryFormData})
			.then(res => {
				toast.success("Event successfully shared.", {theme: "colored"})
				setOpen(!open)
				setFormData({
					title: event?.title,
					description: event?.description,
					category: ""
				})
			})
			.catch(error => {
				setErrors(error?.response?.data)
				toast.error("An error has occurred.", {theme: "colored"})
			})
		setLoading(false)
	}

	return (
		<Modal centered size={"lg"} show={open} onHide={() => setOpen(!open)}>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-x1 border-bottom-0"
			>
				<h5>
					Share Options
				</h5>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<Form>
						<CSRFToken/>
						<Form.Group>
							<Form.Label>Title</Form.Label>
							<Form.Control
								name={"title"}
								placeholder={"Title"}
								value={formData.title}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Category</Form.Label>
							<Select
								options={categoryOptions}
								value={selectedCategory}
								classNamePrefix="react-select"
								onChange={handleChange}
								noOptionsMessage={noOptionsMessage}
								onMenuOpen={() => setIsSelectOpen(true)}
								onMenuClose={() => setIsSelectOpen(false)}
								menuIsOpen={isSelectOpen}
								placeholder={'Select category'}
								onMenuScrollToBottom={() => {
									setPageCategory(page => page + 1);
								}}
								onInputChange={e => {
									query.set('search', e);
									getCategories()
								}}
							/>
							<FormError error={errors.category}/>
						</Form.Group>
						{categoryForm &&
							<Form.Group>
								<Form.Label>Category Name</Form.Label>
								<Form.Control
									name={"categoryName"}
									placeholder={"Category Name"}
									value={categoryFormData.categoryName}
									onChange={(e) => setCategoryFormData({...categoryFormData, categoryName: e.target.value})}
								/>
							</Form.Group>
						}
						<Form.Group>
							<Form.Label>Description</Form.Label>
							<Editor
								apiKey={process.env.REACT_APP_TINY_API_KEY}
								onInit={(evt, editor) => editorRef.current = editor}
								initialValue={formData.description}
								init={{
									height: 300,
									menubar: false,
									plugins: [
										'advlist autolink lists link image charmap print preview anchor',
										'searchreplace visualblocks code fullscreen',
										'insertdatetime media table paste code help wordcount'
									],
									toolbar: 'undo redo | formatselect | ' +
										'bold italic backcolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
								}}
								onChange={() => {
									setFormData({...formData, description: editorRef.current.getContent()})
								}}
							/>
						</Form.Group>
					</Form>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => setOpen(!open)}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSubmit}>
					Share to Feed <FontAwesomeIcon icon="paper-plane" className="ms-2"/>
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EventShareModal