import {withTranslation} from "react-i18next";
import {withPermission} from "../../../../helpers/utils";
import React, {Fragment, useEffect, useState} from "react";
import {Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import {Field, FieldArray, Formik} from "formik";
import CSRFToken from "../../../../helpers/CSRFToken";
import {api} from "../../../../utils/api";
import FormError from "../../../errors/FormError";
import FormErrors from "../../../errors/FormErrors";
import IconButton from "../../../../components/common/IconButton";
import {getPermissions} from "../../../account/actions/GroupsPermissions";
import useQuery from "../../../../hooks/useQuery";
import Select from "react-select";
import SimpleBarReact from "simplebar-react";
import CardHeader from "react-bootstrap/CardHeader";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import paths from "../../../../routes/paths";
import {useNavigate, useParams} from "react-router-dom";

const MembershipAddEdit = ({t, i18n}) => {
	const [loading, setLoading] = useState(false);
	const [currencies, setCurrencies] = useState([]);
	const [taxes, setTaxes] = useState([])
	const [selectedTaxes, setSelectedTaxes] = useState([])
	const [errors, setErrors] = useState({});
	const [permissions, setPermissions] = useState([])
	const [selectedPermissions, setSelectedPermissions] = useState([])
	const [permissionsPage, setPermissionsPage] = useState(1)
	const [formData, setFormData] = useState({
		name: "",
		slug: "",
		desc: "",
		membership_type: "",
		monthly_price: "",
		yearly_price: "",
		yearly_saving: "",
		currency: "",
		taxes: [],
		features: []
	})

	let query = useQuery()
	const navigate = useNavigate()
	const {id} = useParams();

	const getMembership = async () => {
		setLoading(true)
		await api.get(`/membership/membership/${id}/`)
			.then(res => {
				const membershipData = res.data;
				setFormData({
					...membershipData,
					features: membershipData.feature_set.map((feature) => ({
						name: feature.name,
						tag: feature.tag,
						permissions: feature.permissions_list.map((permission) => permission.id),
					})),
				});
				const initialSelectedPermissions = membershipData.feature_set.map((feature) => [
					...feature.permissions_list.map((permission) => ({label: permission.name, value: permission.id}))
				]);
				setSelectedPermissions(initialSelectedPermissions);
				setSelectedTaxes(membershipData?.taxes_data?.map(tax => ({label: tax?.name, value: tax?.id})))
			})
		setLoading(false)
	}

	const getCurrencies = async () => {
		await api.get("/currency/").then(res => setCurrencies(res?.data?.results))
	}

	const getTaxes = async () => {
		await api.get("/tax/").then(res => setTaxes(res?.data?.results))
	}

	const fetchPermissions = () => {
		query.set("page_size", "50")
		query.set("page", permissionsPage.toString())
		getPermissions(query)
			.then(res => setPermissions([...permissions, ...res?.results]))
			.catch(() => {
			})
	}

	useEffect(() => {
		getCurrencies()
	}, []);

	useEffect(() => {
		getTaxes()
	}, []);

	useEffect(() => {
		if (id) getMembership()
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchPermissions()
		// eslint-disable-next-line
	}, [permissionsPage]);

	const handleSubmit = async (e, values) => {
		e.preventDefault()
		setLoading(true)
		if (id) {
			await api.patch(`/membership/membership/${id}/`, values)
				.then(res => {
					toast.success("Membership updated successfully.", {theme: "colored"})
					navigate(paths.membershipList)
				})
				.catch(error => {
					setFormData(values)
					toast.error("An error has occurred.", {theme: "colored"})
					setErrors(error?.response?.data)
				})
		} else {
			await api.post("/membership/membership/", values)
				.then(res => {
					toast.success("Membership added successfully.", {theme: "colored"})
					navigate(paths.membershipList)
				})
				.catch(error => {
					setFormData(values)
					toast.error("An error has occurred.", {theme: "colored"})
					setErrors(error?.response?.data)
				})
		}
		setLoading(false)
	}

	let permissionsOptions = permissions?.map(permission => (
		{label: permission?.name, value: permission?.id}
	))

	let taxOptions = taxes?.map(tax => (
		{label: tax?.name, value: tax?.id}
	))

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Fragment>
			<Formik initialValues={formData} onSubmit={values => console.log(values)}>
				{({values, setFieldValue}) => (
					<Form>
						<CSRFToken/>
						<Card>
							<Card.Header>
								<h5>Basic information</h5>
							</Card.Header>
							<Card.Body>
								<Row>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Name</Form.Label>
											<Field name={"name"}>
												{({field}) => (
													<Form.Control
														name={"name"}
														type={'text'}
														placeholder={'Name'}
														value={field.value}
														onChange={e => {
															setFieldValue(field.name, e.target.value);
														}}
													/>
												)}
											</Field>
											<FormError error={errors?.name}/>
										</Form.Group>
									</Col>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Slug</Form.Label>
											<Field name={"slug"}>
												{({field}) => (
													<Form.Control
														name={"slug"}
														type={'text'}
														placeholder={'Slug'}
														value={field.value}
														onChange={e => {
															setFieldValue(field.name, e.target.value);
														}}
													/>
												)}
											</Field>
											<FormError error={errors?.slug}/>
										</Form.Group>
									</Col>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Type</Form.Label>
											<Field name={"membership_type"}>
												{({field}) => (
													<Form.Select
														name={"membership_type"}
														type={'text'}
														placeholder={'Type'}
														value={field.value}
														onChange={e => {
															setFieldValue(field.name, e.target.value);
														}}
													>
														<option value={""}>Membership Type</option>
														<option value={"Premium"}>Premium</option>
														<option value={"Free"}>Free</option>
													</Form.Select>
												)}
											</Field>
											<FormError error={errors?.membership_type}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Monthly price</Form.Label>
											<Field name={"monthly_price"}>
												{({field}) => (
													<Form.Control
														name={"monthly_price"}
														type={'number'}
														placeholder={'Monthly price'}
														value={field.value}
														onChange={e => {
															setFieldValue(field.name, e.target.value);
														}}
													/>
												)}
											</Field>
											<FormError error={errors?.monthly_price}/>
										</Form.Group>
									</Col>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Yearly price</Form.Label>
											<Field name={"yearly_price"}>
												{({field}) => (
													<Form.Control
														name={"yearly_price"}
														type={'number'}
														placeholder={'Yearly price'}
														value={field.value}
														onChange={e => {
															setFieldValue(field.name, e.target.value);
														}}
													/>
												)}
											</Field>
											<FormError error={errors?.yearly_price}/>
										</Form.Group>
									</Col>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Yearly saving</Form.Label>
											<Field name={"yearly_saving"}>
												{({field}) => (
													<Form.Control
														name={"yearly_saving"}
														type={'number'}
														placeholder={'Yearly saving'}
														value={field.value}
														onChange={e => {
															setFieldValue(field.name, e.target.value);
														}}
													/>
												)}
											</Field>
											<FormError error={errors?.yearly_saving}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Currency</Form.Label>
											<Field name={"currency"}>
												{({field}) => (
													<Form.Select
														name={"currency"}
														type={'text'}
														placeholder={'Type'}
														value={field.value}
														onChange={e => {
															setFieldValue(field.name, e.target.value);
														}}
													>
														<option value={""}>Currency</option>
														{currencies?.map(currency => (
															<option value={currency?.code}
															        selected={currency?.is_default}>{currency.name} - {currency.symbol}</option>
														))}
													</Form.Select>
												)}
											</Field>
											<FormError error={errors?.currency}/>
										</Form.Group>
									</Col>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Description</Form.Label>
											<Field name={"desc"}>
												{({field}) => (
													<Form.Control
														name={"desc"}
														type={"text"}
														placeholder={'Description'}
														value={field.value}
														onChange={e => {
															setFieldValue(field.name, e.target.value);
														}}
													/>
												)}
											</Field>
											<FormError error={errors?.desc}/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>Taxes</Form.Label>
											<Field name={"taxes"}>
												{({field}) => (
													<Select
														options={taxOptions}
														value={selectedTaxes}
														isMulti
														onChange={(newValue) => {
															setFieldValue(field.name, newValue?.map(v => v.value))
															setSelectedTaxes(newValue);
														}}
														placeholder={"Select taxes"}
														classNamePrefix={"react-select"}
													/>
												)}
											</Field>
											<FormError error={errors?.taxes}/>
										</Form.Group>
									</Col>
								</Row>
							</Card.Body>
						</Card>
						<Card className={"mt-3"}>
							<Card.Header>
								<h5>Features information</h5>
							</Card.Header>
							<Card.Body>
								<SimpleBarReact>
									<Table responsive className="border-bottom" size={'sm'}>
										<thead>
										<tr>
											<td>Name</td>
											<td>Tag</td>
											<td>Permissions</td>
											<td className={'p-0'}></td>
										</tr>
										</thead>
										<FieldArray
											name={'features'}
											render={arrayHelpers => (
												<>
													<tbody>
													{values?.features && values?.features.length > 0
														? values?.features?.map((field, index) => (
															<tr key={index}>
																<td>
																	<Field name={`features.${index}.name`}>
																		{({field, form}) => (
																			<Form.Control
																				placeholder={'Name'}
																				name={`features.${index}.name`}
																				errors={errors}
																				formGroupProps={{
																					className: 'mb-3'
																				}}
																				onChange={e => {
																					setFieldValue(
																						field.name,
																						e.target.value
																					);
																				}}
																				value={field.value}
																			/>
																		)}
																	</Field>
																	<FormErrors
																		errors={errors?.features}
																		error={'name'}
																		index={index}
																	/>
																</td>
																<td>
																	<Row>
																		<Col>
																			<Field name={`features.${index}.tag.variant`}>
																				{({field, form}) => (
																					<Form.Select
																						name={`features.${index}.tag.variant`}
																						errors={errors}
																						formGroupProps={{
																							className: 'mb-3'
																						}}
																						onChange={e => {
																							setFieldValue(
																								field.name,
																								e.target.value
																							);
																						}}
																						value={field.value}
																					>
																						<option value={""}>Variant</option>
																						<option value={"primary"}>Primary</option>
																						<option value={"secondary"}>Secondary</option>
																						<option value={"info"}>Info</option>
																						<option value={"success"}>Success</option>
																						<option value={"warning"}>Warning</option>
																					</Form.Select>
																				)}
																			</Field>
																		</Col>
																		<Col>
																			<Field name={`features.${index}.tag.text`}>
																				{({field, form}) => (
																					<Form.Control
																						type={"text"}
																						name={`features.${index}.tag.text`}
																						errors={errors}
																						placeholder={"Text"}
																						formGroupProps={{
																							className: 'mb-3'
																						}}
																						onChange={e => {
																							setFieldValue(
																								field.name,
																								e.target.value
																							);
																						}}
																						value={field.value}
																					/>
																				)}
																			</Field>
																		</Col>
																	</Row>
																	<FormErrors
																		errors={errors?.features}
																		error={'tag'}
																		index={index}
																	/>
																</td>
																<td>
																	<Field name={`features.${index}.permissions`}>
																		{({field, form}) => (
																			<Select
																				options={permissionsOptions}
																				value={selectedPermissions[index]}
																				onChange={value => {
																					const updatedSelectedPermissions = [...selectedPermissions];
																					updatedSelectedPermissions[index] = value;
																					setSelectedPermissions(updatedSelectedPermissions);
																					setFieldValue(field.name, value.map(v => v.value));
																				}}
																				onInputChange={(value) => {
																					query.set("search", value)
																					getPermissions(query).then(r => setPermissions(r?.results))
																						.catch(() => {
																						})
																				}}
																				onMenuScrollToBottom={() => {
																					setPermissionsPage(prevState => prevState + 1)
																				}}
																				classNamePrefix={"react-select"}
																				isMulti
																				menuPortalTarget={document.body}
																				style={{maxWidth: "300px"}}
																				styles={{
																					menuPortal: base => ({...base, zIndex: 9999}),
																				}}
																			/>
																		)}
																	</Field>
																	<FormErrors
																		errors={errors?.features}
																		error={'permissions'}
																		index={index}
																	/>
																</td>
																<td className={'me-0 pe-0'}>
																	<Flex
																		justifyContent={'center'}
																		alignItems={'center'}
																		className={'mt-1'}
																	>
																		<IconButton
																			variant="falcon-default"
																			icon="trash"
																			size={'sm'}
																			iconClassName={'ms-1 text-danger'}
																			onClick={() => {
																				arrayHelpers.remove(index);
																			}}
																		>
																			{' '}
																		</IconButton>
																	</Flex>
																</td>
															</tr>
														))
														: ''}
													</tbody>
													<tfoot>
													<tr>
														<td>
															<IconButton
																variant="falcon-default"
																size="sm"
																icon="plus"
																transform="shrink-3"
																onClick={() => {
																	arrayHelpers.insert(
																		values?.features.length,
																		''
																	);
																}}
															>
                                    <span className="d-none d-sm-inline-block ms-1">
                                      Add item
                                    </span>
															</IconButton>
														</td>
													</tr>
													</tfoot>
												</>
											)}
										/>
									</Table>
								</SimpleBarReact>
								<FormError error={errors?.features}/>
							</Card.Body>
						</Card>
						<Card className={"mt-3"}>
							<CardHeader>
								<Flex justifyContent={'end'} wrap={'wrap'}>
									<IconButton
										icon={faSave}
										onClick={e => handleSubmit(e, values)}
									>
                      <span className="d-none d-sm-inline-block ms-1">
                        {t('save', {ns: "common"})}
                      </span>
									</IconButton>
								</Flex>
							</CardHeader>
						</Card>
					</Form>
				)}
			</Formik>
		</Fragment>
	)
}

export default withTranslation(["membership", "common"])(withPermission(MembershipAddEdit, ["membership.add_membership", "membership.change_membership"]))