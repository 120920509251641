import React, {useCallback, useEffect, useState} from 'react';
import useQuery from '../../hooks/useQuery';
import {Link, useNavigate} from 'react-router-dom';
import {api} from '../../utils/api';
import {debounce} from 'lodash';
import Flex from '../../components/common/Flex';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import CardDropdown from '../../components/common/CardDropdown';
import {Card, Dropdown, Spinner} from 'react-bootstrap';
import SoftBadge from '../../components/common/SoftBadge';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {formatDateTime, withPermission} from '../../helpers/utils';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../components/common/advance-table/AdvanceTablePagination';
import {useAppContext} from '../../providers/AppProvider';
import paths from "../../routes/paths";
import {usePermissions} from "../../providers/PermissionsProvider";
import BaseTableHeader from "../BaseTableHeader";

const BillingList = () => {
    const [billings, setBillings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('date'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);

    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();
    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchBillings = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);

        // Cancel the previous request if it exists
        await api
            .get(`/billing/billing/?${q.toString()}`)
            .then(res => {
                setBillings(res?.data.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchBillings, 600), []);

    useEffect(() => {
        fetchBillings(query);
        setLength(billings.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'code',
            Header: 'Code',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'code' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => <Link
                to={paths.billingDetail.replace(":id", rowData.row.original.id)}>{rowData.row.original.code}</Link>
        },
        {
            accessor: 'amount',
            Header: 'Amount',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'amount' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'status' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => {
                const {status} = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: status === 'approved',
                            warning: status === 'pending',
                            danger: status === 'rejected'
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {status === 'approved' && 'Approved'}
                            {status === 'pending' && 'Pending'}
                            {status === 'rejected' && 'Rejected'}
                        </p>
                        <FontAwesomeIcon
                            icon={classNames({
                                check: status === 'approved',
                                ban: status === 'rejected',
                                clock: status === 'pending'
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'type',
            Header: 'Type',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'type' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => {
                const {type_display} = rowData.row.original;
                return <p>{type_display}</p>;
            }
        },
        {
            accessor: 'date',
            Header: 'Date',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'date' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => {
                const {date} = rowData.row.original;
                return <p>{formatDateTime(date)}</p>;
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id, code} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: 'Are you sure?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/billing/billing/${id}/`).then(() => {
                            toast.success(`Billing ${code} successfully deleted.`, {
                                theme: 'colored'
                            });
                            fetchBillings(query)
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("billing.view_billing") &&
                                <Dropdown.Item onClick={() => navigate(`/billing/${id}/detail`)}>
                                    Detail
                                </Dropdown.Item>
                            }
                            {hasPermission("billing.delete_billing") &&
                                <>
                                    <Dropdown.Divider as="div"/>
                                    <Dropdown.Item
                                        onClick={e => handleDelete(e)}
                                        className="text-danger"
                                    >
                                        Delete
                                    </Dropdown.Item>
                                </>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const billingList = billings?.map(billing => ({
        id: billing?.id,
        code: billing?.code,
        amount: billing?.amount,
        status: billing?.status,
        type: billing?.type,
        type_display: billing?.type_display,
        date: billing?.date
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={billingList}
            pagination
            selection
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"billing.add_billing"}
                        creationPath={paths.billing}
                        title={"billing"}
                        deletePermission={"billing.delete_billing"}
                        exportPermission={"billing.add_billingexportfile"}
                        data={billingList}
                        exportLink={"/billing/export/billing/"}
                        deleteLink={"/billing/delete/billing/"}
                        setLoading={setLoading}
                        fields={["code", "type", "amount", "date", "status", "currency__code", "description", "membership__name", "plan",
                            "provider__name", "path"]}
                        exportsPath={paths.billingExports}
                        ns={"billing"}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchBillings}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default withPermission(BillingList, "billing.view_billing");
