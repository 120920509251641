import {formatDateTime, withPermission} from "../../../../helpers/utils";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../../utils/api";
import {Button, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import IconButton from "../../../../components/common/IconButton";
import {Ticket} from "../../../Ticket";
import {withTranslation} from "react-i18next";
import SoftBadge from "../../../../components/common/SoftBadge";
import IconAlert from "../../../../components/common/IconAlert";
import paths from "../../../../routes/paths";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faWrench} from "@fortawesome/free-solid-svg-icons";
import CSRFToken from "../../../../helpers/CSRFToken";
import DatePicker from 'react-datepicker';
import CustomDateInput from "../../../../components/common/CustomDateInput";
import TinymceEditor from "../../../../components/common/TinymceEditor";
import {toast} from "react-toastify";

const AttendanceDetail = ({t, i18n}) => {
    const [attendance, setAttendance] = useState({});
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false)
    const [formData, setFormData] = useState({
        check_in_time: "",
        check_out_time: "",
        confirmation_details: "",
        status: "",
        resume: null,
    })
    const [startDate, setStartDate] = useState(formData?.check_in_time ? new Date(formData?.check_in_time) : null)
    const [endDate, setEndDate] = useState(formData?.check_out_time ? new Date(formData?.check_out_time) : null)

    const {id} = useParams();
    const navigate = useNavigate()

    const getRegistration = async () => {
        setLoading(true);
        await api.get(`/attendance/attendance/${id}/`).then(res => {
            setAttendance(res?.data)
            setFormData({
                check_in_time: res?.data?.check_in_time,
                check_out_time: res?.data?.check_out_time,
                confirmation_details: res?.data?.confirmation_details,
                resume: null,
            })
        });
        setLoading(false)
    }

    const handleSubmit = async e => {
        setModalLoading(true)
        await api.patch(`/attendance/attendance/${id}/`, formData, {
            headers: {"Content-Type": "multipart/form-data"}
        })
            .then(res => {
                toast.success("Attendee successfully updated", {theme: "colored"})
                setSettingsModal(!settingsModal)
                getRegistration()
            })
            .catch(err => {
                toast.error("An error has occurred", {theme: "colored"})
            })
        setModalLoading(false)
    }

    useEffect(() => {
        getRegistration()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header className="d-flex flex-between-center">
                    <Flex alignItems={"center"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                    </Flex>
                    <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                        <SoftBadge pill bg={classNames({
                            success: attendance?.status === "A",
                            primary: attendance?.status === "R",
                            warning: attendance?.status === "P",
                            danger: attendance?.status === "N",
                        })}>
                            {attendance?.status_display}
                            <FontAwesomeIcon
                                icon={classNames({
                                    check: attendance?.status === "A",
                                    "thumbs-up": attendance?.status === "R",
                                    clock: attendance?.status === "P",
                                    ban: attendance?.status === "N",
                                })} className={"ms-2"}
                            />
                        </SoftBadge>
                        <IconButton
                            onClick={() => setSettingsModal(true)}
                            variant="falcon-default"
                            size="sm"
                            icon={faWrench}
                            className={"ms-2"}
                        >
                            <span className={"d-none d-sm-inline-block"}>Settings</span>
                        </IconButton>
                    </Flex>
                </Card.Header>
            </Card>
            <Row>
                <Col>
                    <IconAlert show variant={classNames({
                        success: attendance?.transaction_details?.status === "approved",
                        warning: attendance?.transaction_details?.status === "pending",
                        danger: attendance?.transaction_details?.status === "declined",
                    })} className={"mt-3"} dismissible>
                        <p>The Payment status of this registrant
                            is {attendance?.transaction_details?.status_display}</p>
                        <div className="d-flex justify-content-end">
                            <Button
                                className={"me-2"}
                                onClick={() => navigate(paths.transactionDetail.replace(":id", attendance?.transaction_details?.id))}
                                variant={classNames({
                                    "outline-success": attendance?.transaction_details?.status === "approved",
                                    "outline-warning": attendance?.transaction_details?.status === "pending",
                                    "outline-danger": attendance?.transaction_details?.status === "declined",
                                })}
                            >
                                Check it out
                            </Button>
                        </div>
                    </IconAlert>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5>User Details</h5>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <strong>Name:</strong> {attendance?.user_data?.full_name} <br/>
                                <strong>Email:</strong> {attendance?.user_data?.email} <br/>
                                <strong>Phone:</strong> {attendance?.user_data?.number} <br/>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5>Event Details</h5>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <strong>Event Name:</strong> {attendance?.event_data?.title} <br/>
                                <strong>Date:</strong> {formatDateTime(attendance?.event_data?.start_date)} <br/>
                                <strong>Location:</strong> {attendance?.event_data?.address?.formatted_address} <br/>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5>{attendance?.event_data?.ticket_options?.title}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Ticket
                                ticket={attendance?.event_data?.ticket_options}
                                elements={attendance?.event_data?.ticket_options?.ticket_elements}
                                background={attendance?.event_data?.ticket_options?.background_url}
                                preview={false}
                                data={attendance?.event_data?.register_data}
                                user={attendance?.user_data}
                                t={t}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal centered show={settingsModal} onHide={() => setSettingsModal(!settingsModal)} size={"lg"}>
                <Modal.Header closeButton>
                    <h5>Settings</h5>
                </Modal.Header>
                <Modal.Body>
                    {modalLoading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <Form>
                            <CSRFToken/>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Check in</Form.Label>
                                        <Row>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={newDate => {
                                                    setStartDate(newDate)
                                                    setFormData({
                                                        ...formData,
                                                        check_in_time: new Date(newDate).toISOString()
                                                    })
                                                }}
                                                showTimeSelect
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                fixedHeight
                                                className='form-control'
                                                customInput={
                                                    <CustomDateInput
                                                        formControlProps={{
                                                            placeholder: 'MM/dd/yyyy h:mm aa',
                                                        }}
                                                    />
                                                }
                                            />
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Check out</Form.Label>
                                        <Row>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={newDate => {
                                                    setEndDate(newDate)
                                                    setFormData({
                                                        ...formData,
                                                        check_out_time: new Date(newDate).toISOString()
                                                    })
                                                }}
                                                showTimeSelect
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                fixedHeight
                                                className='form-control'
                                                customInput={
                                                    <CustomDateInput
                                                        formControlProps={{
                                                            placeholder: 'MM/dd/yyyy h:mm aa',
                                                        }}
                                                    />
                                                }
                                            />
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Resume</Form.Label>
                                        <Form.Control
                                            type={"file"}
                                            onChange={(e) => setFormData({...formData, resume: e.target.files[0]})}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            value={formData.status}
                                            onChange={(e) => setFormData({...formData, status: e.target.value})}
                                        >
                                            <option value={""}>Select Status</option>
                                            <option value={"A"}>Attended</option>
                                            <option value={"R"}>RSVP</option>
                                            <option value={"P"}>Pending</option>
                                            <option value={"N"}>Not Present</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Label>Confirmation details</Form.Label>
                                        <TinymceEditor
                                            value={formData.confirmation_details}
                                            handleChange={newValue => setFormData({
                                                ...formData,
                                                confirmation_details: newValue
                                            })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSettingsModal(!settingsModal)}>
                        {t('cancel', {ns: "common"})}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {t('save', {ns: "common"})} <FontAwesomeIcon icon={faSave} className="ms-2"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default withPermission(withTranslation(["events", "common"])(AttendanceDetail), "attendance.view_attendance");