import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row, Spinner, Table} from 'react-bootstrap';
import Flex from '../../../../../components/common/Flex';
import useQuery from '../../../../../hooks/useQuery';
import {getActivities} from '../../../../activity/actions/Activity';
import {getEvents} from '../../../../events/actions/Events';
import Select from 'react-select';
import IconButton from '../../../../../components/common/IconButton';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import FormError from '../../../../errors/FormError';
import {useNavigate, useParams} from 'react-router-dom';
import {api} from '../../../../../utils/api';
import {toast} from 'react-toastify';
import {Field, FieldArray, Formik} from 'formik';
import {getCompanies} from '../../../companies/actions/Company';
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomDateInput from '../../../../../components/common/CustomDateInput';
import FormErrors from '../../../../errors/FormErrors';
import {fileToBase64, withPermission} from '../../../../../helpers/utils';
import CSRFToken from '../../../../../helpers/CSRFToken';
import paths from "../../../../../routes/paths";
import {useAppContext} from "../../../../../providers/AppProvider";
import TinymceEditor from "../../../../../components/common/TinymceEditor";

const SponsorsAddEdit = () => {
    const [currencies, setCurrencies] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyPage, setCompanyPage] = useState(1);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [activities, setActivities] = useState([]);
    const [activityPage, setActivityPage] = useState(1);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [events, setEvents] = useState([]);
    const [eventPage, setEventPage] = useState(1);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [formData, setFormData] = useState({
        company: null,
        activity: null,
        event: '',
        amount: '',
        end_date: '',
        level: '',
        note: '',
        start_date: '',
        type: '',
        materials: [],
        rights: [],
        metrics: []
    });

    const {
        config: {isActivity, isCompany},
        setConfig
    } = useAppContext()

    let companyQuery = useQuery();
    let activityQuery = useQuery();
    let eventQuery = useQuery();
    const {id} = useParams();
    const navigate = useNavigate();

    companyQuery.set('page_size', '50');
    activityQuery.set('page_size', '50');
    eventQuery.set('page_size', '50');

    const getSponsor = async () => {
        setLoading(true);
        await api
            .get(`/sponsor/sponsor/${id}/`)
            .then(res => {
                const updatedMaterials = res?.data?.material_set?.map(material => ({
                    material_type: material?.material_type,
                    name: material?.name,
                    upload_date: material?.upload_date
                }));
                const updatedRights = res?.data?.right_set?.map(right => ({
                    name: right?.name
                }));
                const updatedMetrics = res?.data?.eventmetric_set?.map(metric => ({
                    metric_name: metric?.metric_name,
                    value: metric?.value
                }));
                setFormData({
                    ...res?.data,
                    materials: updatedMaterials,
                    rights: updatedRights,
                    metrics: updatedMetrics
                });
                setSelectedCompany({
                    value: res?.data?.company,
                    label: res?.data?.company_name
                });
                setSelectedActivity({
                    value: res?.data?.organization,
                    label: res?.data?.activity_name
                });
                setSelectedEvent({
                    value: res?.data?.event,
                    label: res?.data?.event_title
                });
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    };

    const getCurrencies = async () => {
        return await api.get('/currency/');
    };

    useEffect(() => {
        setLoading(true);
        companyQuery.set('page', companyPage.toString());
        getCompanies(companyQuery)
            .then(res => setCompanies([...companies, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [companyPage]);

    useEffect(() => {
        setLoading(true);
        activityQuery.set('page', activityPage.toString());
        getActivities(activityQuery)
            .then(res => setActivities([...activities, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [activityPage]);

    useEffect(() => {
        setLoading(true);
        eventQuery.set('page', eventPage.toString());
        getEvents(eventQuery)
            .then(res => setEvents([...events, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [eventPage]);

    useEffect(() => {
        getCurrencies()
            .then(res => setCurrencies(res?.data?.results))
            .catch(() => {
            });
    }, []);

    useEffect(() => {
        if (id) getSponsor();
        // eslint-disable-next-line
    }, []);

    let companyOptions = [
        {value: '', label: '--------------------'},
        ...companies?.map(company => ({value: company.id, label: company.name}))
    ];

    let activityOptions = [
        {value: '', label: '--------------------'},
        ...activities?.map(activity => ({
            value: activity.id,
            label: activity.name
        }))
    ];

    let eventOptions = [
        {value: '', label: '--------------------'},
        ...events?.map(event => ({value: event.id, label: event.title}))
    ];

    const metricsOptions = [
        {value: 'brand_awareness', label: 'Brand Awareness'},
        {value: 'lead_generation', label: 'Lead Generation'},
        {value: 'sales_increase', label: 'Sales Increase'},
        {value: 'customer_acquisition', label: 'Customer Acquisition'},
        {value: 'media_exposure', label: 'Media Exposure'},
        {value: 'event_attendance', label: 'Event Attendance'},
        {value: 'social_media_engagement', label: 'Social Media Engagement'},
        {value: 'website_traffic', label: 'Website Traffic'},
        {value: 'email_signups', label: 'Email Sign-ups'},
        {value: 'customer_retention', label: 'Customer Retention'},
        {value: 'sponsorship_renewal_rate', label: 'Sponsorship Renewal Rate'},
        {value: 'content_downloads', label: 'Content Downloads'},
        {value: 'press_mentions', label: 'Press Mentions'},
        {value: 'customer_feedback', label: 'Customer Feedback/Satisfaction'},
        {value: 'influencer_mentions', label: 'Influencer Mentions'},
        {value: 'product_trials', label: 'Product Trials/Demos'},
        {value: 'app_downloads', label: 'App Downloads'},
        {value: 'survey_completions', label: 'Survey Completions'},
        {value: 'video_views', label: 'Video Views'},
        {value: 'brand_sentiment', label: 'Brand Sentiment'},
    ];

    const handleSubmit = async (e, values) => {
        setLoading(true);
        e.preventDefault();
        if (id) {
            await api
                .patch(`/sponsor/sponsor/${id}/`, values)
                .then(res => {
                    toast.success(`Sponsor successfully updated.`, {theme: 'colored'});
                    navigate(paths.detailSponsors.replace(":id", res?.data?.id));
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(`An error has occurred.`, {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        } else {
            await api
                .post(`/sponsor/sponsor/`, values)
                .then(res => {
                    toast.success(`Sponsor successfully created.`, {theme: 'colored'});
                    navigate(paths.detailSponsors.replace(":id", res?.data?.id));
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(`An error has occurred.`, {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        }
        setLoading(false);
    };

    return loading ? (
        <>
            <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
                <Spinner animation={'border'} variant={'primary'}/>
            </Flex>
        </>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <Form>
                        <CSRFToken/>
                        <Row className="g-3">
                            <Col lg={6}>
                                <div className={'sticky-sidebar'}>
                                    <Card className={'mt-3'}>
                                        <Card.Header>
                                            <h5>Sponsor information</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                {!isCompany &&
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Company</Form.Label>
                                                            <Field name={'company'}>
                                                                {({field}) => (
                                                                    <Select
                                                                        options={companyOptions}
                                                                        classNamePrefix={'react-select'}
                                                                        value={selectedCompany}
                                                                        placeholder={'Select company...'}
                                                                        onChange={value => {
                                                                            setSelectedCompany(value);
                                                                            setFormData({
                                                                                ...formData,
                                                                                company: value.value
                                                                            });
                                                                            setFieldValue(field.name, value.value);
                                                                        }}
                                                                        onMenuScrollToBottom={() => {
                                                                            setCompanyPage(prevState => prevState + 1);
                                                                        }}
                                                                        onInputChange={newValue => {
                                                                            companyQuery.set('search', newValue);
                                                                            getCompanies(companyQuery)
                                                                                .then(res => setCompanies(res?.results))
                                                                                .catch(() => {
                                                                                });
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Form.Group>
                                                        <FormError error={errors.company}/>
                                                    </Col>
                                                }
                                                {!isActivity &&
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Activity</Form.Label>
                                                            <Field name={'activity'}>
                                                                {({field}) => (
                                                                    <Select
                                                                        classNamePrefix={'react-select'}
                                                                        options={activityOptions}
                                                                        value={selectedActivity}
                                                                        placeholder={'Select activity...'}
                                                                        onChange={value => {
                                                                            setSelectedActivity(value);
                                                                            setFormData({
                                                                                ...formData,
                                                                                activity: value.value
                                                                            });
                                                                            setFieldValue(field.name, value.value);
                                                                            eventQuery.set('activity', value.value);
                                                                            getEvents(eventQuery)
                                                                                .then(res => setEvents(res?.results))
                                                                                .catch(() => {
                                                                                });
                                                                        }}
                                                                        onMenuScrollToBottom={() => {
                                                                            setActivityPage(prevState => prevState + 1);
                                                                        }}
                                                                        onInputChange={newValue => {
                                                                            activityQuery.set('search', newValue);
                                                                            getActivities(activityQuery)
                                                                                .then(res => setActivities(res?.results))
                                                                                .catch(() => {
                                                                                });
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Form.Group>
                                                        <FormError error={errors.activity}/>
                                                    </Col>
                                                }

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Event</Form.Label>
                                                        <Field name={'event'}>
                                                            {({field}) => (
                                                                <Select
                                                                    classNamePrefix={'react-select'}
                                                                    options={eventOptions}
                                                                    value={selectedEvent}
                                                                    placeholder={'Select event...'}
                                                                    onChange={value => {
                                                                        setSelectedEvent(value);
                                                                        setFormData({
                                                                            ...formData,
                                                                            event: value.value
                                                                        });
                                                                        setFieldValue(field.name, value.value);
                                                                    }}
                                                                    onMenuScrollToBottom={() => {
                                                                        setEventPage(prevState => prevState + 1);
                                                                    }}
                                                                    onInputChange={newValue => {
                                                                        eventQuery.set('search', newValue);
                                                                        getEvents(eventQuery)
                                                                            .then(res => setEvents(res?.results))
                                                                            .catch(() => {
                                                                            });
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Form.Group>
                                                    <FormError error={errors.event}/>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <Card className={'mt-3'}>
                                        <Card.Header>
                                            <h5>Notes <span className={"text-danger"}>*</span></h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Field name="note">
                                                    {({field}) => (
                                                        <TinymceEditor
                                                            value={field.value}
                                                            handleChange={newValue => setFieldValue(field.name, newValue)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors.note}/>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <Card className={'mt-3'}>
                                    <Card.Header>
                                        <h5>Sponsorship information</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Type <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={'type'}>
                                                        {({field}) => (
                                                            <Form.Select
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            >
                                                                <option value={''}>
                                                                    Select sponsorship type
                                                                </option>
                                                                <option value={'gold'}>Gold</option>
                                                                <option value={'silver'}>Silver</option>
                                                                <option value={'bronze'}>Bronze</option>
                                                                <option value={'in_kind'}>In kind</option>
                                                            </Form.Select>
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <FormError error={errors.type}/>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Level <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={'level'}>
                                                        {({field}) => (
                                                            <Form.Select
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            >
                                                                <option value={''}>
                                                                    Select sponsorship level
                                                                </option>
                                                                <option value={'platinum'}>Platinum</option>
                                                                <option value={'gold'}>Gold</option>
                                                                <option value={'silver'}>Silver</option>
                                                                <option value={'bronze'}>Bronze</option>
                                                            </Form.Select>
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <FormError error={errors.level}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Start Date <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Row>
                                                        <Field name="start_date">
                                                            {({field}) => (
                                                                <DatePicker
                                                                    selected={
                                                                        values?.start_date
                                                                            ? new Date(values?.start_date)
                                                                            : startDate
                                                                    }
                                                                    name="start_date"
                                                                    onChange={date => {
                                                                        setStartDate(date);
                                                                        setFieldValue(
                                                                            field.name,
                                                                            new Date(date).toISOString()
                                                                        );
                                                                    }}
                                                                    showTimeSelect
                                                                    formatWeekDay={day => day.slice(0, 3)}
                                                                    className="form-control"
                                                                    dateFormat="MM-dd-yyyy HH:mm"
                                                                    placeholderText="MM-DD-YYYY HH:mm"
                                                                />
                                                            )}
                                                        </Field>
                                                    </Row>
                                                </Form.Group>
                                                <FormError error={errors.start_date}/>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>End Date <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Row>
                                                        <Field name="end_date">
                                                            {({field}) => (
                                                                <DatePicker
                                                                    name="end_date"
                                                                    selected={
                                                                        values?.end_date
                                                                            ? new Date(values?.end_date)
                                                                            : endDate
                                                                    }
                                                                    onChange={date => {
                                                                        setEndDate(date);
                                                                        setFieldValue(
                                                                            field.name,
                                                                            new Date(date).toISOString()
                                                                        );
                                                                    }}
                                                                    showTimeSelect
                                                                    formatWeekDay={day => day.slice(0, 3)}
                                                                    className="form-control"
                                                                    dateFormat="MM-dd-yyyy HH:mm"
                                                                    placeholderText="MM-DD-YYYY HH:mm"
                                                                />
                                                            )}
                                                        </Field>
                                                    </Row>
                                                </Form.Group>
                                                <FormError error={errors.end_date}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Amount <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={'amount'}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                type={'number'}
                                                                name={'amount'}
                                                                placeholder={'Amount'}
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <FormError error={errors.amount}/>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Currency <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={'currency'}>
                                                        {({field}) => (
                                                            <Form.Select
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            >
                                                                <option value={''}>Select currency</option>
                                                                {currencies?.map(currency => (
                                                                    <option value={currency.code}>
                                                                        {currency.name} - {currency.symbol}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <FormError error={errors.currency}/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className={'mt-3'}>
                                    <Card.Header>
                                        <h5>Materials</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <FieldArray
                                            name={'materials'}
                                            render={arrayHelpers => (
                                                <>
                                                    {values.materials && values.materials.length > 0
                                                        ? values.materials.map((field, index) => (
                                                            <>
                                                                <div
                                                                    className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
                                                                    <div
                                                                        className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
                                                                        <Button
                                                                            size="sm"
                                                                            variant="link"
                                                                            className="p-0"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(index)
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                className="text-danger"
                                                                                icon="times-circle"
                                                                            />
                                                                        </Button>
                                                                    </div>

                                                                    <Row className="gx-2 gy-3">
                                                                        <Col md="6">
                                                                            <Form.Group>
                                                                                <Form.Label>Title</Form.Label>
                                                                                <Field
                                                                                    name={`materials.${index}.name`}
                                                                                >
                                                                                    {({field}) => (
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            name={`schedules.${index}.name`}
                                                                                            placeholder="Name"
                                                                                            value={field.value}
                                                                                            onChange={e =>
                                                                                                setFieldValue(
                                                                                                    field.name,
                                                                                                    e.target.value
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </Form.Group>
                                                                            <FormErrors
                                                                                errors={errors?.materials}
                                                                                error={'name'}
                                                                                index={index}
                                                                            />
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Form.Group>
                                                                                <Form.Label>Date</Form.Label>
                                                                                <Row>
                                                                                    <Field
                                                                                        name={`materials.${index}.upload_date`}
                                                                                    >
                                                                                        {({field}) => (
                                                                                            <DatePicker
                                                                                                selected={
                                                                                                    values?.materials[index]
                                                                                                        ?.upload_date
                                                                                                        ? new Date(
                                                                                                            values?.materials[
                                                                                                                index
                                                                                                                ]?.upload_date
                                                                                                        )
                                                                                                        : null
                                                                                                }
                                                                                                defaultValue=""
                                                                                                name={`materials.${index}.upload_date`}
                                                                                                onChange={newDate => {
                                                                                                    setFieldValue(
                                                                                                        field.name,
                                                                                                        new Date(
                                                                                                            newDate
                                                                                                        ).toISOString()
                                                                                                    );
                                                                                                }}
                                                                                                dateFormat="MM/dd/yyyy"
                                                                                                fixedHeight
                                                                                                className="form-control"
                                                                                                customInput={
                                                                                                    <CustomDateInput
                                                                                                        formControlProps={{
                                                                                                            placeholder:
                                                                                                                'MM/dd/yyyy'
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                </Row>
                                                                            </Form.Group>
                                                                            <FormErrors
                                                                                errors={errors?.materials}
                                                                                error={'upload_date'}
                                                                                index={index}
                                                                            />
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Form.Group>
                                                                                <Form.Label>File</Form.Label>
                                                                                <Field
                                                                                    name={`materials.${index}.file`}
                                                                                >
                                                                                    {({field}) => (
                                                                                        <Form.Control
                                                                                            type="file"
                                                                                            name={`materials.${index}.file`}
                                                                                            onChange={e => {
                                                                                                const file =
                                                                                                    e.target.files[0];
                                                                                                if (file) {
                                                                                                    fileToBase64(
                                                                                                        file,
                                                                                                        base64String => {
                                                                                                            // Set the Base64 string in your Formik values
                                                                                                            setFieldValue(
                                                                                                                `materials.${index}.file`,
                                                                                                                base64String
                                                                                                            );
                                                                                                        }
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </Form.Group>
                                                                            <FormErrors
                                                                                errors={errors?.materials}
                                                                                error={'file'}
                                                                                index={index}
                                                                            />
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Form.Group>
                                                                                <Form.Label>Type</Form.Label>
                                                                                <Field
                                                                                    name={`materials.${index}.material_type`}
                                                                                >
                                                                                    {({field}) => (
                                                                                        <Form.Select
                                                                                            value={field.value}
                                                                                            onChange={e =>
                                                                                                setFieldValue(
                                                                                                    field.name,
                                                                                                    e.target.value
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <option value="">
                                                                                                Select a type
                                                                                            </option>
                                                                                            <option value="website">
                                                                                                Website
                                                                                            </option>
                                                                                            <option value="banners">
                                                                                                Banners
                                                                                            </option>
                                                                                            <option value="brochures">
                                                                                                Brochures
                                                                                            </option>
                                                                                            <option value="branding">
                                                                                                Branding
                                                                                            </option>
                                                                                            <option value="video">
                                                                                                Video
                                                                                            </option>
                                                                                            <option value="other">
                                                                                                Other
                                                                                            </option>
                                                                                        </Form.Select>
                                                                                    )}
                                                                                </Field>
                                                                            </Form.Group>
                                                                            <FormErrors
                                                                                errors={errors?.materials}
                                                                                error={'material_type'}
                                                                                index={index}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </>
                                                        ))
                                                        : ''}
                                                    <IconButton
                                                        onClick={() =>
                                                            arrayHelpers.insert(values.materials.length, '')
                                                        }
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                        transform="shrink-3"
                                                    >
                                                        Add Item
                                                    </IconButton>
                                                </>
                                            )}
                                        />
                                        <FormError error={errors.materials}/>
                                    </Card.Body>
                                </Card>
                                <Card className={'mt-3'}>
                                    <Card.Header>
                                        <h5>Rights</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Table responsive className="border-bottom" size={'sm'}>
                                            <thead>
                                            <tr>
                                                <td>Name</td>
                                                <td className={'p-0'}></td>
                                            </tr>
                                            </thead>
                                            <FieldArray
                                                name={'rights'}
                                                render={arrayHelpers => (
                                                    <>
                                                        <tbody>
                                                        {values?.rights && values?.rights.length > 0
                                                            ? values?.rights?.map((field, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Field name={`rights.${index}.name`}>
                                                                            {({field, form}) => (
                                                                                <Form.Control
                                                                                    placeholder={'Name'}
                                                                                    name={`rights.${index}.name`}
                                                                                    errors={errors}
                                                                                    formGroupProps={{
                                                                                        className: 'mb-3'
                                                                                    }}
                                                                                    onChange={e => {
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            e.target.value
                                                                                        );
                                                                                    }}
                                                                                    value={field.value}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormErrors
                                                                            errors={errors?.rights}
                                                                            error={'name'}
                                                                            index={index}
                                                                        />
                                                                    </td>
                                                                    <td className={'me-0 pe-0'}>
                                                                        <Flex
                                                                            justifyContent={'center'}
                                                                            alignItems={'center'}
                                                                            className={'mt-1'}
                                                                        >
                                                                            <IconButton
                                                                                variant="falcon-default"
                                                                                icon="trash"
                                                                                size={'sm'}
                                                                                iconClassName={'ms-1 text-danger'}
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(index);
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                            </IconButton>
                                                                        </Flex>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : ''}
                                                        </tbody>
                                                        <tfoot>
                                                        <tr>
                                                            <td>
                                                                <IconButton
                                                                    variant="falcon-default"
                                                                    size="sm"
                                                                    icon="plus"
                                                                    transform="shrink-3"
                                                                    onClick={() => {
                                                                        arrayHelpers.insert(
                                                                            values?.rights.length,
                                                                            ''
                                                                        );
                                                                    }}
                                                                >
                                    <span className="d-none d-sm-inline-block ms-1">
                                      Add item
                                    </span>
                                                                </IconButton>
                                                            </td>
                                                        </tr>
                                                        </tfoot>
                                                    </>
                                                )}
                                            />
                                        </Table>
                                        <FormError error={errors.rights}/>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-3">
                                    <Card.Header as="h5">ROI Metrics</Card.Header>
                                    <Card.Body>
                                        <FieldArray
                                            name="metrics"
                                            render={arrayHelpers => (
                                                <div>
                                                    {values?.metrics?.map((metric, index) => (
                                                        <Row key={index} className="mb-3">
                                                            <Col md={6}>
                                                                <Form.Group controlId={`metrics.${index}.metric_name`}>
                                                                    <Form.Label>Metric</Form.Label>
                                                                    <Field name={`metrics.${index}.metric_name`}>
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                as="select"
                                                                                name={`metrics.${index}.metric_name`}
                                                                                value={field.value}
                                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                            >
                                                                                {metricsOptions.map(option => (
                                                                                    <option key={option.value}
                                                                                            value={option.value}>
                                                                                        {option.label}
                                                                                    </option>
                                                                                ))}
                                                                            </Form.Control>
                                                                        )}
                                                                    </Field>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={4}>
                                                                <Form.Group controlId={`metrics.${index}.value`}>
                                                                    <Form.Label>Value</Form.Label>
                                                                    <Field name={`metrics.${index}.value`}>
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                type="number"
                                                                                name={`metrics.${index}.value`}
                                                                                value={field.value}
                                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={2} className="d-flex align-items-end">
                                                                <IconButton
                                                                    variant="falcon-default"
                                                                    icon="trash"
                                                                    size={'sm'}
                                                                    className={"mb-1"}
                                                                    iconClassName={'ms-1 text-danger'}
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                >
                                                                    {' '}
                                                                </IconButton>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                        transform="shrink-3"
                                                        onClick={() => arrayHelpers.push({
                                                            metric_name: '',
                                                            value: ''
                                                        })}
                                                    >
                                    <span className="d-none d-sm-inline-block ms-1">
                                      Add metric
                                    </span>
                                                    </IconButton>
                                                </div>
                                            )}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card>
                                    <Card.Footer>
                                        <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                            <p className={"text-danger"}>* Mandatory field</p>
                                            <IconButton
                                                icon={faSave}
                                                onClick={e => handleSubmit(e, values)}
                                            >
                        <span className="d-none d-sm-inline-block ms-1">
                          Save
                        </span>
                                            </IconButton>
                                        </Flex>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
};

export default withPermission(SponsorsAddEdit, "sponsor.add_sponsor");
