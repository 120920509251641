import {b64toBlob, isIterableArray, withPermission} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import avatarImg from "../../../../assets/img/team/avatar.png";
import {useNavigate, useParams} from "react-router-dom";
import {useAppContext} from "../../../../providers/AppProvider";
import {api} from "../../../../utils/api";
import paths from "../../../../routes/paths";
import {toast} from "react-toastify";
import Flex from "../../../../components/common/Flex";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import CSRFToken from "../../../../helpers/CSRFToken";
import {Field, Formik} from "formik";
import FormError from "../../../errors/FormError";
import Avatar from "../../../../components/common/Avatar";
import FalconDropzone from "../../../../components/common/FalconDropzone";
import cloudUpload from "../../../../assets/img/icons/cloud-upload.svg";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import IconButton from "../../../../components/common/IconButton";
import DatePicker from 'react-datepicker';
import {faSave} from "@fortawesome/free-solid-svg-icons";
import CustomDateInput from "../../../../components/common/CustomDateInput";
import TinymceEditor from "../../../../components/common/TinymceEditor";

const PostAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [authors, setAuthors] = useState([])
    const [selectedAuthor, setSelectedAuthor] = useState(null)
    const [date, setDate] = useState(null)
    const [formData, setFormData] = useState({
        photo: null,
        title: '',
        content: '',
        date_posted: '',
        category: '',
    });
    const [photo, setPhoto] = useState([
        {src: avatarImg}
    ]);
    const navigate = useNavigate();
    const {id} = useParams();
    const {
        config: {group},
        setConfig
    } = useAppContext()

    const getPost = async () => {
        setLoading(true)
        await api.get(`/blog/post/${id}/`)
            .then(res => {
                setFormData({
                    ...res?.data,
                });
                setPhoto([{src: res?.data?.photo_url}]);
                setSelectedCategory({label: res?.data?.category_name, value: res?.data?.category})
                setSelectedAuthor({label: res?.data?.author_name, value: res?.data?.author})
                setDate(new Date(res?.data?.date_posted))
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    }

    const getCategories = async () => {
        await api.get("/blog/category/").then(res => {
            setCategories(res?.data?.results)
        })
    }

    const getAuthors = async () => {
        await api.get("/account/user/").then(res => {
            setAuthors(res?.data?.results)
        }).catch(() => {
        })
    }

    useEffect(() => {
        getCategories()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getAuthors()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id) getPost();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (photo[0].base64) {
            const block = photo[0].base64.split(";");
            // Get the content type of the image
            const contentType = block[0].split(":")[1];
            // get the real base64 content of the file
            const realData = block[1].split(",")[1];

            // Convert it to a blob to upload
            const blob = b64toBlob(realData, contentType);

            // Create a SponsoringFile object
            const file = new File([blob], photo[0].path, {
                type: photo[0].type,
            });
            setFormData({...formData, photo: file})
        }
    }, [formData, photo]);

    const handleSubmit = async (e, values) => {
        setLoading(true);
        e.preventDefault();
        if (id) {
            await api
                .patch(`/blog/post/${id}/`, values)
                .then(res => {
                    toast.success(`${t('title')} ${t('updateSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.blog);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(t('error', {ns: "common"}), {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        } else {
            await api
                .post(`/blog/post/`, values)
                .then(res => {
                    toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.blog);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(t('error', {ns: "common"}), {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        }
        setLoading(false)
    }

    let categoryOptions = categories?.map(category => ({label: category?.name, value: category?.id}))

    categoryOptions = [
        {label: "-------------------------", value: ""},
        ...categoryOptions
    ]

    let authorOptions = authors?.map(user => ({label: user?.full_name, value: user?.id}))

    authorOptions = [
        {label: "-------------------------", value: ""},
        ...authorOptions
    ]

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <Form>
                        <CSRFToken/>
                        <Row className="g-3">
                            <Col lg={6}>
                                <Card>
                                    <Card.Header>
                                        <h5>{t('basic')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group>
                                            <Row className="mb-3">
                                                <Col md="auto">
                                                    <Avatar
                                                        size="4xl"
                                                        src={
                                                            isIterableArray(photo)
                                                                ? photo[0]?.base64 || photo[0]?.src
                                                                : ''
                                                        }
                                                    />
                                                </Col>
                                                <Col md>
                                                    <Field name="photo">
                                                        {() => (
                                                            <FalconDropzone
                                                                files={photo}
                                                                onChange={files => {
                                                                    setPhoto(files);
                                                                    setFieldValue('photo', files[0]);
                                                                }}
                                                                multiple={false}
                                                                accept="image/*"
                                                                placeholder={
                                                                    <>
                                                                        <Flex justifyContent="center">
                                                                            <img
                                                                                src={cloudUpload}
                                                                                alt=""
                                                                                width={25}
                                                                                className="me-2"
                                                                            />
                                                                            <p className="fs-0 mb-0 text-700">
                                                                                {t('upload')}
                                                                            </p>
                                                                        </Flex>
                                                                        <p className="mb-0 w-75 mx-auto text-400">
                                                                            {t('uploadDesc')}
                                                                        </p>
                                                                    </>
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.photo}/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('fields.title')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"title"}>
                                                {({field}) => (
                                                    <Form.Control
                                                        name={"title"}
                                                        type={'text'}
                                                        placeholder={t('fields.title')}
                                                        value={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.title}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('fields.category')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"category"}>
                                                {({field}) => (
                                                    <CreatableSelect
                                                        options={categoryOptions}
                                                        value={selectedCategory}
                                                        onChange={async newValue => {
                                                            setSelectedCategory(newValue)
                                                            if (newValue.__isNew__) {
                                                                await api.post("/blog/category/", {name: newValue.label})
                                                                    .then(res => setFieldValue(field.name, res?.data?.id))
                                                            } else {
                                                                setFieldValue(field.name, newValue.value)
                                                            }
                                                        }}
                                                        classNamePrefix={"react-select"}
                                                        placeholder={`${t('select', {ns: "common"})} ${t('fields.category')}`}
                                                        formatCreateLabel={inputValue => `${t('create', {ns: "common"})} ${inputValue}`}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.category}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>{t('settings')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.status')}</Form.Label>
                                                    <Field name={"status"}>
                                                        {({field}) => (
                                                            <Form.Select
                                                                name={"status"}
                                                                value={field.value}
                                                                onChange={e => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                }}
                                                            >
                                                                <option>{t('select', {ns: "common"})} {t('fields.status')}</option>
                                                                <option value={"draft"}>{t('fields.draft')}</option>
                                                                <option
                                                                    value={"published"}>{t('fields.published')}</option>
                                                            </Form.Select>
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.status}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className={'mb-3'}>
                                                    <Row>
                                                        <Form.Label>{t('fields.date_posted')}:</Form.Label>
                                                    </Row>
                                                    <Row>
                                                        <Field name="date_posted">
                                                            {({field}) => (
                                                                <DatePicker
                                                                    selected={date}
                                                                    onChange={date => {
                                                                        setDate(date);
                                                                        setFieldValue(
                                                                            field.name,
                                                                            new Date(date).toISOString()
                                                                        );
                                                                    }}
                                                                    className="form-control"
                                                                    placeholderText={t('fields.date_posted')}
                                                                    showTimeSelect
                                                                    fixedHeight
                                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                                    customInput={
                                                                        <CustomDateInput
                                                                            formControlProps={{
                                                                                placeholder: 'MM/dd/yyyy HH:mm',
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </Row>
                                                    <FormError error={errors.date_posted}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {group?.indexOf(
                                            process.env.REACT_APP_ADMIN_GROUP_NAME
                                        ) !== -1 && (
                                            <Form.Group>
                                                <Form.Label>{t('fields.author')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"author"}>
                                                    {({field}) => (
                                                        <Select
                                                            options={authorOptions}
                                                            value={selectedAuthor}
                                                            onChange={newValue => {
                                                                setSelectedAuthor(newValue)
                                                                setFieldValue(field.name, newValue.value)
                                                            }}
                                                            classNamePrefix={"react-select"}
                                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.author')}`}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.author}/>
                                            </Form.Group>
                                        )}

                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card>
                                    <Card.Header>
                                        <h5>{t('fields.content')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Label>{t('fields.content')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name="content">
                                                {({field}) => (
                                                    <TinymceEditor
                                                        value={field.value}
                                                        handleChange={newValue => setFieldValue(field.name, newValue)}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors.content}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card>
                                    <Card.Footer>
                                        <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                            <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                                            <IconButton
                                                icon={faSave}
                                                onClick={e => handleSubmit(e, values)}
                                            >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                                            </IconButton>
                                        </Flex>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default withPermission(withTranslation(["posts", "common"])(PostAddEdit), ["blog.add_blogpost", "blog.change_blogpost"])