import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import Section from 'components/common/Section';
import Slider from 'react-slick';
import {isIterableArray} from 'helpers/utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SectionHeader from './SectionHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuoteLeft} from '@fortawesome/free-solid-svg-icons';
import Divider from '../../components/common/Divider';
import Avatar from '../../components/common/Avatar';
import {api} from "../../utils/api";

const TestimonialItem = ({description, author_full_name, author_avatar}) => {
    return (
        <div className="px-5 px-sm-6">
            <p className="fs-sm-0 fs-md-1 fst-italic text-secondary" dangerouslySetInnerHTML={{__html: description}}></p>

            <Avatar
                size={'3xl'}
                rounded={'circle'}
                className="w-auto mx-auto mt-3"
                src={author_avatar}
                alt=""
                height={40}
                width={40}
            />
            <p className="fs-0 text-600">{author_full_name}</p>
        </div>
    );
};

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
};

const Testimonial = ({t, i18n}) => {
    const [testimonials, setTestimonials] = useState([]);

    const getTestimonials = async () => {
        await api.get("/testimonial/").then(res => setTestimonials(res?.data.results));
    }

    useEffect(() => {
        getTestimonials()
    }, []);

    return (
        <Section className="text-center bg-200" id={'testimonials'}>
            <div
                style={{
                    position: 'absolute',
                    width: '570px',
                    height: '474px',
                    left: '1000px',
                    bottom: '60px',
                    background: 'rgba(180, 50, 151, 0.3)',
                    filter: 'blur(110.15px)',
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    width: '570px',
                    height: '474px',
                    left: '-200px',
                    top: '100px',
                    background: 'rgba(44, 123, 229, 0.3)',
                    filter: 'blur(110.15px)'
                }}
            ></div>
            <FontAwesomeIcon icon={faQuoteLeft} className={'text-primary fs-4'}/>
            <SectionHeader title={t('testimonials')} subtitle=""/>
            <Divider className={'text-primary'}/>
            <Row className="justify-content-center">
                <Col xs={10} lg={9} xl={8}>
                    <Slider {...settings}>
                        {isIterableArray(testimonials) &&
                            testimonials?.map((testimonial, index) => (
                                <TestimonialItem {...testimonial} key={index}/>
                            ))}
                    </Slider>
                </Col>
            </Row>
        </Section>
    );
}


TestimonialItem.propTypes = {
    description: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    companyImage: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired
};

export default Testimonial;
