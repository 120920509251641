import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {BarChart} from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {tooltipFormatter} from 'helpers/echart-utils';
import {getColor, getPastDates, rgbaColor} from 'helpers/utils';
import React from 'react';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    BarChart,
    CanvasRenderer,
    LegendComponent
]);

const getOptions = (data, duration) => ({
    color: [
        getColor('primary'),
        rgbaColor(getColor('primary'), 0.8),
        rgbaColor(getColor('primary'), 0.6),
        rgbaColor(getColor('primary'), 0.4),
        rgbaColor(getColor('primary'), 0.2),
        rgbaColor(getColor('secondary'), 0.2),
        rgbaColor(getColor('secondary'), 0.4),
        rgbaColor(getColor('secondary'), 0.6),
        rgbaColor(getColor('secondary'), 0.8),
    ],
    legend: {
        data: ['Auto, Boat & Air', 'Business & Professional', 'Charity & Causes', 'Community & Culture', 'Family & Education',
            'Fashion & Beauty', 'Film, Media & Entertainment', 'Food & Drink', 'Government & Politics'],
        left: 5,
        itemWidth: 10,
        itemHeight: 10,
        borderRadius: 0,
        icon: 'circle',
        inactiveColor: getColor('gray-400'),
        textStyle: {color: getColor('gray-700')},
        itemGap: 20
    },
    xAxis: {
        type: 'category',
        data: getPastDates(duration === "last_7_days" ? 7 : duration === "last_month" ? 30 : 365).map(date => dayjs(date).format('DD MMM, YYYY')),
        axisLine: {
            show: false
        },
        splitLine: {
            lineStyle: {
                color: getColor('gray-200')
            }
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            color: getColor('gray-600'),
            formatter: value => dayjs(value).format('ddd')
        }
    },
    yAxis: {
        type: 'value',
        position: 'right',
        splitLine: {
            lineStyle: {
                color: getColor('gray-200')
            }
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: true,
            color: getColor('gray-600'),
            margin: 15
        }
    },
    tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        axisPointer: {
            type: 'none'
        },
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: {color: getColor('gray-700')},
        borderWidth: 1,
        transitionDuration: 0,
        formatter: tooltipFormatter
    },

    series: data,

    grid: {
        containLabel: true,
        right: '5px',
        left: 0,
        bottom: 0,
        top: '15%'
    }
});

const TopicPopularityChart = ({data, duration}) => {
    return (
        <ReactEChartsCore
            echarts={echarts}
            option={getOptions(data, duration)}
            style={{height: '21.875rem'}}
        />
    );
};

export default TopicPopularityChart;
