import React, {useEffect, useState} from 'react';
import {Card, Form} from 'react-bootstrap';
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";
import {useNavigate} from "react-router-dom";

const AttendanceFilteringForm = ({fetch, event}) => {
	const [users, setUsers] = useState([]);

	let query = useQuery()
	const navigate = useNavigate();

	const getUsers = async () => {
		await api.get("/account/user/").then((response) => {
			setUsers(response?.data?.results)
		})
	}

	const handleFilter = (e) => {
		if (e.target.name === "check_in_time") {
			query.delete("check_in_time")
			query.delete("check_in_time__lt")
			query.delete("check_in_time__gt")
			switch (e.target.value) {
				case "at_time":
					query.set("check_in_time", event?.start_date);
					break;
				case "early":
					query.set("check_in_time__lt", event?.start_date);
					break;
				case "late":
					query.set("check_in_time__gt", event?.start_date);
					break;
				default:
					query.set("check_in_time", event?.start_date)
			}
		} else if (e.target.name === "check_out_time") {
			query.delete("check_out_time")
			query.delete("check_out_time__lt")
			query.delete("check_out_time__gt")
			switch (e.target.value) {
				case "at_time":
					query.set("check_out_time", event?.end_date);
					break;
				case "early":
					query.set("check_out_time__lt", event?.end_date);
					break;
				case "late":
					query.set("check_out_time__gt", event?.end_date);
					break;
				default:
					query.set("check_out_time", event?.end_date)
			}
		} else {
			query.set(e.target.name, e.target.value);
		}
		navigate(`?${query.toString()}`)
		fetch(query)
	}

	useEffect(() => {
		getUsers()
	}, []);

	return (
		<Card className="shadow-none shadow-show-xl scrollbar">
			<Card.Header className="bg-body-tertiary d-none d-xl-block">
				<h6 className="mb-0">Filter</h6>
			</Card.Header>
			<Card.Body>
				<Form>
					<div className="mb-2 mt-n2">
						<Form.Label className="mb-1 fs-10">User</Form.Label>
						<Form.Select size="sm" name={"user"} onChange={handleFilter}>
							<option value={""}>---------------------</option>
							{users?.map((user) => (
								<option value={user?.id}>{user?.full_name}</option>
							))}
						</Form.Select>
					</div>
					<div className="mb-2">
						<Form.Label className="mb-1 mt-2 fs-10">Status</Form.Label>
						<Form.Select size="sm" name={"status"} onChange={handleFilter}>
							<option value={""}>---------------------</option>
							<option value={"A"}>Attended</option>
							<option value={"R"}>RSVP</option>
							<option value={"P"}>Pending</option>
							<option value={"N"}>Not Present</option>
						</Form.Select>
					</div>
					<div className="mb-2">
						<Form.Label className="mb-1 mt-2 fs-10">Check in time</Form.Label>
						<Form.Select defaultValue="Billing" size="sm" name={"check_in_time"} onChange={handleFilter}>
							<option value={""}>---------------------</option>
							<option value={"at_time"}>At time</option>
							<option value={"early"}>Early</option>
							<option value={"late"}>Late</option>
						</Form.Select>
					</div>
					<div className="mb-2">
						<Form.Label className="mb-1 mt-2 fs-10">Check out time</Form.Label>
						<Form.Select size="sm" name={"check_out_time"} onChange={handleFilter}>
							<option value={""}>---------------------</option>
							<option value={"at_time"}>At time</option>
							<option value={"early"}>Early</option>
							<option value={"late"}>After time</option>
						</Form.Select>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export default AttendanceFilteringForm;
