import React, {Fragment, useEffect, useState} from "react";
import {api} from "../../../../utils/api";
import useQuery from "../../../../hooks/useQuery";
import {useAppContext} from "../../../../providers/AppProvider";
import {Button, Card, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import IconButton from "../../../../components/common/IconButton";
import {usePermissions} from "../../../../providers/PermissionsProvider";
import CSRFToken from "../../../../helpers/CSRFToken";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import SimpleBarReact from "simplebar-react";
import {formatDate} from "../../../../helpers/utils";
import FormError from "../../../errors/FormError";
import {format} from "date-fns";
import DatePicker from 'react-datepicker';
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

const BudgetManagementContent = () => {
    const [loading, setLoading] = useState(false);
    const [budgetModal, setBudgetModal] = useState("");
    const [newBudgetModal, setNewBudgetModal] = useState(false);
    const [budgets, setBudgets] = useState([]);
    const [selectedBudget, setSelectedBudget] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [incomes, setIncomes] = useState([])
    const [expenses, setExpenses] = useState([])
    const [expenseIncomeModal, setExpenseIncomeModal] = useState(false)
    const [type, setType] = useState("income");
    const [modalLoading, setModalLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [date, setDate] = useState(null);
    const [formData, setFormData] = useState({
        date: "",
        amount: "",
        category: "",
    })
    const [budgetFormData, setBudgetFormData] = useState({
        name: "",
        total_budget: "",
        allocated_budget: "",
        currency: "",
    });
    const [budget, setBudget] = useState({});
    const {
        config: {activity}
    } = useAppContext()

    const {t} = useTranslation("common")
    const {hasPermission} = usePermissions()
    const query = useQuery()

    const fetchBudgets = async () => {
        setLoading(true);
        query.set("activity", activity);
        await api.get(`/budget/budget/?${query.toString()}`).then(res => {
            setBudgets(res?.data?.results)
        })
            .catch(() => {
            })
        setLoading(false);
    }

    const fetchCurrencies = async () => {
        setLoading(true);
        await api.get(`/currency/?${query.toString()}`).then(res => {
            setCurrencies(res?.data?.results)
        })
            .catch(() => {
            })
        setLoading(false);
    }

    const fetchBudget = async () => {
        setLoading(true);
        await api.get(`/budget/budget/${selectedBudget}/`).then(res => {
            setBudget(res?.data)
        })
            .catch(() => {
            })
        setLoading(false);
    }

    const fetchIncomes = async () => {
        setLoading(true);
        query.set("budget", selectedBudget)
        await api.get(`/budget/income/?${query.toString()}`).then(res => setIncomes(res?.data?.results))
        .catch(() => {
            })
        setLoading(false)
    }

    const fetchExpenses = async () => {
        setLoading(true);
        query.set("budget", selectedBudget)
        await api.get(`/budget/expense/?${query.toString()}`).then(res => setExpenses(res?.data?.results))
        .catch(() => {
            })
        setLoading(false)
    }

    const handleBudgetSubmit = async () => {
        setLoading(true)
        await api.patch(`/budget/budget/${selectedBudget}/`, budgetFormData)
            .then(res => {
                toast.success("Budget modified successfully.", {theme: "colored"})
                setBudgetModal(false)
                fetchBudgets()
                fetchBudget()
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"});
            })
        setLoading(false)
    }

    const handleAddBudget = async e => {
        setLoading(true)
        await api.post(`/budget/budget/`, budgetFormData)
            .then(res => {
                toast.success("Budget added successfully.", {theme: "colored"})
                setNewBudgetModal(false)
                fetchBudgets()
                fetchBudget()
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"});
            })
    }

    const handleSubmit = async e => {
        setModalLoading(true)
        await api.post(`/budget/${type}/`, {...formData, budget: selectedBudget})
            .then(res => {
                toast.success(`${type} successfully added.`, {theme: "colored"})
                setExpenseIncomeModal(false)
                setFormData({
                    date: "",
                    amount: "",
                    currency: "",
                    category: "",
                    budget: selectedBudget
                })
                setDate(null)
                fetchBudgets()
                fetchBudget()
                fetchIncomes()
                fetchExpenses()
            })
            .catch(error => {
                toast.error("An error has occurred.", {theme: "colored"})
                setErrors(error?.response?.data)
            })
        setModalLoading(false)
    }

    const handleFieldChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        fetchBudgets()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchCurrencies()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchBudget()
        // eslint-disable-next-line
    }, [selectedBudget]);

    useEffect(() => {
        fetchIncomes()
        // eslint-disable-next-line
    }, [selectedBudget]);

    useEffect(() => {
        fetchExpenses()
        // eslint-disable-next-line
    }, [selectedBudget]);

    useEffect(() => {
        setBudgetFormData({
            name: budget?.name,
            allocated_budget: budget?.allocated_budget,
            total_budget: budget?.total_budget,
            currency: budget?.currency,
        })
    }, [budget]);

    const deleteIncome = async (id) => {
        const {isConfirmed} = await Swal.fire({
            title: t('sure', {ns: "common"}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
            cancelButtonText: t('cancel', {ns: "common"})
        });
        if (isConfirmed) {
            setLoading(true);
            await api.delete(`/budget/income/${id}/`).then(() => {
                toast.success(`Income Successfully deleted.`, {
                    theme: 'colored'
                });
                fetchBudgets()
                fetchBudget()
                fetchIncomes()
            })
            .catch(() => {
            })
            setLoading(false);
        }
    };
    const deleteExpense = async (id) => {
        const {isConfirmed} = await Swal.fire({
            title: t('sure', {ns: "common"}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
            cancelButtonText: t('cancel', {ns: "common"})
        });
        if (isConfirmed) {
            setLoading(true);
            await api.delete(`/budget/expense/${id}/`).then(() => {
                toast.success(`Expense Successfully deleted.`, {
                    theme: 'colored'
                });
                fetchBudgets()
                fetchBudget()
                fetchExpenses()
            })
            .catch(() => {
            })
            setLoading(false);
        }
    };
    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <Form.Select
                                size={"sm"}
                                name={"budget"}
                                onChange={(e) => setSelectedBudget(e.target.value)}
                                value={selectedBudget}
                            >
                                <option value={""}>Select Budget</option>
                                {budgets?.map(budget => (
                                    <option value={budget?.id}>{budget?.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={"auto"}>
                            {(hasPermission('budget.change_activitybudget') && selectedBudget) ?
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="edit"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="ms-2"
                                    onClick={() => {
                                        setBudgetFormData({
                                            name: budget?.name,
                                            allocated_budget: budget?.allocated_budget,
                                            total_budget: budget?.total_budget,
                                            currency: budget?.currency,
                                        })
                                        setBudgetModal(selectedBudget)
                                    }}
                                >
                                    <span className="d-none d-md-inline-block ms-1">Modify Budget</span>
                                </IconButton> : ""
                            }
                            {hasPermission('budget.add_activitybudget') &&
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="ms-2"
                                    onClick={() => {
                                        setBudgetFormData({
                                            name: "",
                                            currency: "",
                                            total_budget: "",
                                            allocated_budget: ""
                                        })
                                        setNewBudgetModal(true)
                                    }}
                                >
                                    <span className="d-none d-md-inline-block ms-1">Budget</span>
                                </IconButton>
                            }
                            {hasPermission('budget.add_activityexpense') &&
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="ms-2"
                                    onClick={() => {
                                        setExpenseIncomeModal(true)
                                        setType("expense")
                                    }}
                                >
                                    <span className="d-none d-md-inline-block ms-1">Expense</span>
                                </IconButton>
                            }
                            {hasPermission('budget.add_activityincome') &&
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="ms-2"
                                    onClick={() => {
                                        setExpenseIncomeModal(true)
                                        setType("income")
                                    }}
                                >
                                    <span className="d-none d-md-inline-block ms-1">Income</span>
                                </IconButton>
                            }
                        </Col>
                    </Row>
                </Card.Header>
            </Card>
            <Row className={"mt-3"}>
                <Col>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5 className="mb-0">Total budget</h5>
                        </Card.Header>
                        <Card.Body>
                            <h4 className={"text-success"}>{budget?.total_budget} {budget?.currency}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5 className="mb-0">Allocated budget</h5>
                        </Card.Header>
                        <Card.Body>
                            <h4 className={"text-primary"}>{budget?.allocated_budget} {budget?.currency}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5 className="mb-0">Remaining budget</h5>
                        </Card.Header>
                        <Card.Body>
                            <h4 className={"text-danger"}>{budget?.remaining_budget} {budget?.currency}</h4>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h4>Income Details</h4>
                        </Card.Header>
                        <Card.Body>
                            <SimpleBarReact>
                                <Table striped className="border-bottom">
                                    <thead data-bs-theme="light">
                                    <tr className="bg-primary dark__bg-1000 alert alert-primary">
                                        <th className="text-white border-0 text-white-emphasis">Date</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">
                                            Category
                                        </th>
                                        <th className="text-white border-0 text-end text-white-emphasis">Amount</th>
                                        <th className="text-white border-0 text-end text-white-emphasis"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {incomes?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">{formatDate(item?.created_at)}</td>
                                            <td className="align-middle text-center">{item?.category_display}</td>
                                            <td className="align-middle text-end">{item?.amount}</td>
                                            <td className="align-middle text-end">
                                                <IconButton
                                                    icon={"trash-alt"}
                                                    variant="falcon-default"
                                                    size="sm"
                                                    iconClassName={"text-danger"}
                                                    onClick={() => deleteIncome(item?.id)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h4>Expense Details</h4>
                        </Card.Header>
                        <Card.Body>
                            <SimpleBarReact>
                                <Table striped className="border-bottom">
                                    <thead data-bs-theme="light">
                                    <tr className="bg-primary dark__bg-1000 alert alert-primary">
                                        <th className="text-white border-0 text-white-emphasis">Date</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">
                                            Category
                                        </th>
                                        <th className="text-white border-0 text-end text-white-emphasis">Amount</th>
                                        <th className="text-white border-0 text-end text-white-emphasis"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {expenses?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">{formatDate(item?.created_at)}</td>
                                            <td className="align-middle text-center">{item?.category_display}</td>
                                            <td className="align-middle text-end">{item?.amount}</td>
                                            <td className="align-middle text-end">
                                                <IconButton
                                                    icon={"trash-alt"}
                                                    variant="falcon-default"
                                                    size="sm"
                                                    iconClassName={"text-danger"}
                                                    onClick={() => deleteExpense(item?.id)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal centered size={"lg"} show={budgetModal === selectedBudget}
                   onHide={() => setBudgetModal(!budgetModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>Budget</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <CSRFToken/>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={budgetFormData?.name}
                                onChange={(e) => setBudgetFormData({...budgetFormData, name: e.target.value})}
                                placeholder={"Name"}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Total budget</Form.Label>
                            <Form.Control
                                type="number"
                                value={budgetFormData?.total_budget}
                                onChange={(e) => setBudgetFormData({...budgetFormData, total_budget: e.target.value})}
                                placeholder={"Total budget"}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Allocated budget</Form.Label>
                            <Form.Control
                                type="number"
                                value={budgetFormData?.allocated_budget}
                                onChange={(e) => setBudgetFormData({
                                    ...budgetFormData,
                                    allocated_budget: e.target.value
                                })}
                                placeholder={"Allocated budget"}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Currency</Form.Label>
                            <Form.Select
                                value={budgetFormData?.currency}
                                onChange={(e) => setBudgetFormData({
                                    ...budgetFormData,
                                    currency: e.target.value
                                })}
                            >
                                <option>Select Currency</option>
                                {currencies?.map(currency => (
                                    <option value={currency?.code}>{currency?.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setBudgetModal(!budgetModal)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleBudgetSubmit}>
                        Save <FontAwesomeIcon icon={faSave} className="ms-2"/>
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered size={"lg"} show={newBudgetModal} onHide={() => setNewBudgetModal(!newBudgetModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>New Budget</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <CSRFToken/>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={budgetFormData?.name}
                                onChange={(e) => setBudgetFormData({...budgetFormData, name: e.target.value})}
                                placeholder={"Name"}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Total budget</Form.Label>
                            <Form.Control
                                type="number"
                                value={budgetFormData?.total_budget}
                                onChange={(e) => setBudgetFormData({...budgetFormData, total_budget: e.target.value})}
                                placeholder={"Total budget"}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Allocated budget</Form.Label>
                            <Form.Control
                                type="number"
                                value={budgetFormData?.allocated_budget}
                                onChange={(e) => setBudgetFormData({
                                    ...budgetFormData,
                                    allocated_budget: e.target.value
                                })}
                                placeholder={"Allocated budget"}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Currency</Form.Label>
                            <Form.Select
                                value={budgetFormData?.currency}
                                onChange={(e) => setBudgetFormData({
                                    ...budgetFormData,
                                    currency: e.target.value
                                })}
                            >
                                <option>Select Currency</option>
                                {currencies?.map(currency => (
                                    <option value={currency?.code}>{currency?.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setNewBudgetModal(!newBudgetModal)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddBudget}>
                        Save <FontAwesomeIcon icon={faSave} className="ms-2"/>
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size={"lg"} centered show={expenseIncomeModal}
                   onHide={() => setExpenseIncomeModal(!expenseIncomeModal)}>
                <Modal.Header closeButton>
                    <h5>Add {type}</h5>
                </Modal.Header>
                <Modal.Body>
                    {modalLoading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <Form>
                            <CSRFToken/>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control
                                            type={"number"}
                                            value={formData.amount}
                                            onChange={handleFieldChange}
                                            placeholder={"Amount"}
                                            name={"amount"}
                                        />
                                        <FormError error={errors?.amount}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Date</Form.Label>
                                        <Row>
                                            <DatePicker
                                                selected={date}
                                                onChange={date => {
                                                    setDate(date);
                                                    setFormData({
                                                        ...formData,
                                                        date: format(date, 'yyyy-MM-dd')
                                                    });
                                                }}
                                                formatWeekDay={day => day.slice(0, 3)}
                                                className="form-control"
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="MM-DD-YYYY"
                                            />
                                        </Row>
                                        <FormError error={errors?.date}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Select
                                            value={formData.category}
                                            onChange={handleFieldChange}
                                            name={"category"}
                                        >
                                            <option>Select category</option>
                                            {type === "income" ? (
                                                <>
                                                    <option value={"sponsorship"}>Sponsorship</option>
                                                    <option value={"ticket_sale"}>Ticket Sale</option>
                                                    <option value={"membership_fee"}>Membership Fee</option>
                                                    <option value={"donation"}>Donation</option>
                                                    <option value={"grant"}>Grant</option>
                                                    <option value={"other"}>Other</option>
                                                </>
                                            ) : (
                                                <>
                                                    <option value={"venue"}>Venue</option>
                                                    <option value={"logistics"}>Logistics</option>
                                                    <option value={"catering"}>Catering</option>
                                                    <option value={"entertainment"}>Entertainment</option>
                                                    <option value={"marketing"}>Marketing</option>
                                                    <option value={"salary"}>Salary</option>
                                                    <option value={"technology"}>Technology</option>
                                                    <option value={"miscellaneous"}>Miscellaneous</option>
                                                    <option value={"other"}>Other</option>
                                                </>
                                            )}
                                        </Form.Select>
                                        <FormError error={errors?.category}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton
                        icon={faSave}
                        onClick={e => handleSubmit(e)}
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                          Save
                        </span>
                    </IconButton>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default BudgetManagementContent