import {Button, Card, Form, ListGroup, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import Flex from "../../../components/common/Flex";
import {Link, useParams} from "react-router-dom"
import CSRFToken from "../../../helpers/CSRFToken";
import {toast} from "react-toastify";
import FormError from "../../errors/FormError";
import Avatar from "../../../components/common/Avatar";
import {formatDateTime} from "../../../helpers/utils";
import {usePermissions} from "../../../providers/PermissionsProvider";

const EventDetailNotes = ({event, t}) => {
	const {id} = useParams()
	const [notes, setNotes] = useState([])
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState({})
	const [formData, setFormData] = useState({
		event: id,
		text: ""
	})

	let query = useQuery()
	const {hasPermission} = usePermissions()

	const getNotes = async () => {
		setLoading(true)
		query.set("event", id)
		await api.get(`/event/notes/?${query.toString()}`).then(res => setNotes(res?.data?.results))
		setLoading(false)
	}

	const handleFieldChange = ({target}) => {
		setFormData({...formData, [target.name]: target.value})
	}

	const handleAddNote = async () => {
		setLoading(true)
		await api.post("/event/notes/", formData)
			.then(res => {
				toast.success("Note successfully added.", {theme: "colored"})
				getNotes()
			})
			.catch(error => {
				toast.error("An error has occurred.", {theme: "colored"})
				setErrors(error?.response?.data)
			})
		setLoading(false)
	}

	useEffect(() => {
		getNotes()
		// eslint-disable-next-line
	}, []);

	return (
		<Card className={"mt-3"}>
			<Card.Header>
				<h5>{t('fields.notes')}</h5>
			</Card.Header>
			<Card.Body>
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={"border"} variant={"primary"}/>
					</Flex>
				) : (
					<div>
						{hasPermission("event.view_eventnote") &&
							<ListGroup>
								{notes?.map(note => (
									<ListGroup.Item className='p-3 p-sm-4' data-bs-theme="light" as={Link} to='#!' action>
										<Flex justifyContent="between">
											<h5 className="mb-1">
												<Flex justifyContent={"between"} alignItems={"center"}>
													<Avatar src={note?.user_avatar} className={"me-2"}/>
													{note?.user_full_name}
												</Flex>
											</h5>

											<small>{formatDateTime(note?.created_at)}</small>
										</Flex>
										<p className="mb-1">
											{note?.text}
										</p>
									</ListGroup.Item>
								))}
							</ListGroup>
						}
						{hasPermission("event.add_eventnote") &&
							<Form>
								<CSRFToken/>
								<Form.Group>
									<Form.Label>{t('fields.note')}:</Form.Label>
									<textarea rows={3} className={"form-control"} onChange={handleFieldChange} name={"text"}></textarea>
									<FormError error={errors?.text}/>
								</Form.Group>
								<Button variant="primary" onClick={handleAddNote} className={"mt-3 float-end"}>
									{t('add', {ns: "common"})}
								</Button>
							</Form>
						}
					</div>
				)}
			</Card.Body>
		</Card>
	)
}

export default EventDetailNotes