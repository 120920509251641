import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import Flex from '../../../../components/common/Flex';
import useQuery from '../../../../hooks/useQuery';
import {getActivities} from '../../../activity/actions/Activity';
import {getEvents} from '../../../events/actions/Events';
import Select from 'react-select';
import IconButton from '../../../../components/common/IconButton';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import FormError from '../../../errors/FormError';
import {useNavigate, useParams} from 'react-router-dom';
import {api} from '../../../../utils/api';
import {toast} from 'react-toastify';
import CSRFToken from '../../../../helpers/CSRFToken';
import {withPermission} from '../../../../helpers/utils';
import {useAppContext} from '../../../../providers/AppProvider';
import paths from "../../../../routes/paths";
import {getMembers} from "../../../member/actions/Member";
import {Field, FieldArray, Formik} from "formik";
import OrganizerResponsibilityItem from "./OrganizerResponsibilityItem";
import OrganizerCertificationItem from "./OrganizerCertificationItem";

const OrganizersAddEdit = () => {
    const [members, setMembers] = useState([]);
    const [memberPage, setMemberPage] = useState(1);
    const [selectedMember, setSelectedMember] = useState(null);
    const [activities, setActivities] = useState([]);
    const [activityPage, setActivityPage] = useState(1);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [events, setEvents] = useState([]);
    const [eventPage, setEventPage] = useState(1);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        member: '',
        organization: '',
        event: '',
        responsibilities: [],
        certifications: [],
    });

    let userQuery = useQuery();
    let activityQuery = useQuery();
    let eventQuery = useQuery();
    const {id} = useParams();
    const navigate = useNavigate();
    const {
        config: {group},
        setConfig
    } = useAppContext();

    userQuery.set('page_size', '50');
    activityQuery.set('page_size', '50');
    eventQuery.set('page_size', '50');

    const getOrganizer = async () => {
        setLoading(true);
        await api
            .get(`/organizer/organizer/${id}/`)
            .then(res => {
                setFormData({
                    ...res?.data,
                    responsibilities: res?.data?.responsibility_set?.map(r => ({
                        deadline: r?.deadline,
                        end: r?.end,
                        start: r?.start,
                        description: r?.description,
                        priority: r?.priority,
                    })),
                    certifications: res?.data?.certification_set?.map(c => ({
                        name: c?.name,
                        issuing: c?.issuing,
                        issue_date: c?.issue_date,
                        expiration_date: c?.expiration_date,
                        description: c?.description,
                        number: c?.number,
                        type: c?.type,
                        renewal: c?.renewal,
                    }))
                });
                setSelectedMember({
                    value: res?.data?.member,
                    label: res?.data?.user_fullname
                });
                setSelectedActivity({
                    value: res?.data?.organization,
                    label: res?.data?.activity_name
                });
                setSelectedEvent({
                    value: res?.data?.event,
                    label: res?.data?.event_title
                });
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        userQuery.set('page', memberPage.toString());
        getMembers(userQuery)
            .then(res => setMembers([...members, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [memberPage]);

    useEffect(() => {
        setLoading(true);
        activityQuery.set('page', activityPage.toString());
        getActivities(activityQuery)
            .then(res => setActivities([...activities, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [activityPage]);

    useEffect(() => {
        setLoading(true);
        eventQuery.set('page', eventPage.toString());
        getEvents(eventQuery)
            .then(res => setEvents([...events, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [eventPage]);

    useEffect(() => {
        if (id) getOrganizer();
        // eslint-disable-next-line
    }, []);

    let userOptions = [
        {value: '', label: '--------------------'},
        ...members?.map(user => ({value: user.id, label: user.full_name}))
    ];

    let activityOptions = [
        {value: '', label: '--------------------'},
        ...activities?.map(activity => ({
            value: activity.id,
            label: activity.name
        }))
    ];

    let eventOptions = [
        {value: '', label: '--------------------'},
        ...events?.map(event => ({value: event.id, label: event.title}))
    ];

    const handleSubmit = async (e, values) => {
        setLoading(true);
        e.preventDefault();
        if (id) {
            await api
                .patch(`/organizer/organizer/${id}/`, values)
                .then(res => {
                    toast.success(
                        `Organizer ${res?.data?.user_fullname} successfully updated.`,
                        {theme: 'colored'}
                    );
                    navigate(paths.organizers);
                })
                .catch(error => {
                    setFormData(values)
                    toast.error(`An error has occurred.`, {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        } else {
            await api
                .post(`/organizer/organizer/`, values)
                .then(res => {
                    toast.success(
                        `Organizer ${res?.data?.user_fullname} successfully created.`,
                        {theme: 'colored'}
                    );
                    navigate(paths.organizers);
                })
                .catch(error => {
                    setFormData(values)
                    toast.error(`An error has occurred.`, {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        }
        setLoading(false);
    };

    return loading ? (
        <>
            <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
                <Spinner animation={'border'} variant={'primary'}/>
            </Flex>
        </>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <>
                        <Card>
                            <Card.Header>
                                <h5>Organizer information</h5>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <CSRFToken/>
                                    <Row>
                                        <Col
                                            lg={group === process.env.REACT_APP_ADMIN_GROUP_NAME ? 6 : 12}
                                        >
                                            <Form.Group>
                                                <Form.Label>Member <span className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"member"}>
                                                    {({field}) => (
                                                        <Select
                                                            options={userOptions}
                                                            value={selectedMember}
                                                            placeholder={'Select member...'}
                                                            onChange={value => {
                                                                setSelectedMember(value);
                                                                setFieldValue(field.name, value.value)
                                                            }}
                                                            onMenuScrollToBottom={() => {
                                                                setMemberPage(prevState => prevState + 1);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <FormError error={errors.member}/>
                                        </Col>
                                        {group === process.env.REACT_APP_ADMIN_GROUP_NAME && (
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Activity <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"organization"}>
                                                        {({field}) => (
                                                            <Select
                                                                options={activityOptions}
                                                                value={selectedActivity}
                                                                placeholder={'Select activity...'}
                                                                onChange={value => {
                                                                    setSelectedActivity(value);
                                                                    eventQuery.set('activity', value.value);
                                                                    getEvents(eventQuery)
                                                                        .then(res => setEvents(res?.results))
                                                                        .catch(() => {
                                                                        });
                                                                    setFieldValue(field.name, value.value)
                                                                }}
                                                                onMenuScrollToBottom={() => {
                                                                    setActivityPage(prevState => prevState + 1);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <FormError error={errors.organization}/>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Event <span className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"event"}>
                                                    {({field}) => (
                                                        <Select
                                                            options={eventOptions}
                                                            value={selectedEvent}
                                                            placeholder={'Select event...'}
                                                            onChange={value => {
                                                                setSelectedEvent(value);
                                                                setFieldValue(field.name, value.value)
                                                            }}
                                                            onMenuScrollToBottom={() => {
                                                                setEventPage(prevState => prevState + 1);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <FormError error={errors.event}/>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Row className={"mt-3"}>
                            <Col lg={6}>
                                <Card>
                                    <Card.Header>
                                        <h5>Responsibility Information</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <FieldArray name={"responsibilities"}
                                                    render={arrayHelpers => (
                                                        <>
                                                            {values?.responsibilities && values?.responsibilities?.length > 0 ? (
                                                                values?.responsibilities?.map((field, index) => (
                                                                    <OrganizerResponsibilityItem
                                                                        {...field}
                                                                        setFieldValue={setFieldValue}
                                                                        name={field.name}
                                                                        index={index}
                                                                        id={index}
                                                                        values={values}
                                                                        errors={errors}
                                                                        handleRemove={() => arrayHelpers.remove(index)}
                                                                    />
                                                                ))
                                                            ) : ""}
                                                            <IconButton
                                                                onClick={() => arrayHelpers.insert(values?.responsibilities?.length, {
                                                                    priority: "2"
                                                                })}
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="plus"
                                                                transform="shrink-3"
                                                            >
                                                                Add item
                                                            </IconButton>
                                                        </>
                                                    )}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card>
                                    <Card.Header>
                                        <h5>Certification Information</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <FieldArray name={"certifications"}
                                                    render={arrayHelpers => (
                                                        <>
                                                            {values?.certifications && values?.certifications?.length > 0 ? (
                                                                values?.certifications?.map((field, index) => (
                                                                    <OrganizerCertificationItem
                                                                        {...field}
                                                                        setFieldValue={setFieldValue}
                                                                        name={field.name}
                                                                        index={index}
                                                                        id={index}
                                                                        values={values}
                                                                        errors={errors}
                                                                        handleRemove={() => arrayHelpers.remove(index)}
                                                                    />
                                                                ))
                                                            ) : ""}
                                                            <IconButton
                                                                onClick={() => arrayHelpers.insert(values?.certifications?.length, {
                                                                    renewal: false
                                                                })}
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="plus"
                                                                transform="shrink-3"
                                                            >
                                                                Add item
                                                            </IconButton>
                                                        </>
                                                    )}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Card className={"mt-3"}>
                            <Card.Footer>
                                <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                    <p className={"text-danger"}>* Mandatory field</p>
                                    <IconButton icon={faSave} onClick={e => handleSubmit(e, values)}>
                                        <span className="d-none d-sm-inline-block ms-1">Save</span>
                                    </IconButton>
                                </Flex>
                            </Card.Footer>
                        </Card>
                    </>
                )}
            </Formik>
        </Fragment>
    );
};

export default withPermission(OrganizersAddEdit, "organizer.add_organizer");
