import {formatDateTime, withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import Avatar from "../../../components/common/Avatar";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../../BaseTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import StarRatings from "react-star-ratings/build/star-ratings";

const Testimonials = ({t, i18n}) => {
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();

    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchTestimonials = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);
        await api.get(`/testimonial/?${q.toString()}`)
            .then(res => {
                setTestimonials(res?.data?.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLength(testimonials.length);
        setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchTestimonials, 600), []);

    useEffect(() => {
        fetchTestimonials(query);
        setLength(testimonials.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'author',
            Header: t('fields.author'),
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'author' && sortingDirection,
            headerProps: {className: 'pe-1 text-center'},
            cellProps: {
                className: 'py-2 text-center'
            },
            Cell: rowData => {
                const {author_full_name, author_avatar, author} = rowData.row.original;
                return (
                    <>
                        <Link to={`/accounts/user/${author}/detail`}>
                            <Flex alignItems="center">
                                {author_avatar ? (
                                    <Avatar
                                        src={author_avatar}
                                        rounded={'circle'}
                                        size="4xl"
                                        className="me-2"
                                    />
                                ) : (
                                    <Avatar
                                        size="4xl"
                                        name={author_full_name}
                                        rounded={'circle'}
                                        className="me-2"
                                    />
                                )}
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: '200px',
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {author_full_name}
                                    </h6>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            }
        },
        {
            accessor: 'rating',
            Header: t('fields.rating'),
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'rating' && sortingDirection,
            headerProps: {className: 'pe-1 text-center'},
            cellProps: {
                className: 'py-2 text-center'
            },
            Cell: rowData => {
                const {rating} = rowData.row.original
                return (
                    <StarRatings
                        rating={rating}
                        starRatedColor="yellow"
                        numberOfStars={6}
                        name='rating'
                    />
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t('fields.created_at'),
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'created_at' && sortingDirection,
            headerProps: {className: 'pe-1 text-center'},
            cellProps: {
                className: 'py-2 text-center'
            },
            Cell: rowData => formatDateTime(rowData.row.original.created_at)
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: "common"}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
                        cancelButtonText: t('cancel', {ns: "common"})
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/testimonial/${id}/`).then(() => {
                            toast.success(
                                `${t('title')} ${t('deleteSuccess', {ns: "common"})}`,
                                {
                                    theme: 'colored'
                                }
                            );
                            fetchTestimonials(query)
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("testimonial.change_testimonial") &&
                                <Dropdown.Item onClick={() => navigate(paths.testimonialEdit.replace(":id", id))}>
                                    {t('edit', {ns: "common"})}
                                </Dropdown.Item>
                            }
                            <Dropdown.Divider as="div"/>
                            {hasPermission("testimonial.delete_testimonial") &&
                                <Dropdown.Item
                                    onClick={e => handleDelete(e)}
                                    className="text-danger"
                                >
                                    {t('delete', {ns: "common"})}
                                </Dropdown.Item>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ]

    const testimonialList = testimonials?.map(testimonial => ({
        id: testimonial.id,
        author: testimonial.author,
        author_full_name: testimonial.author_full_name,
        author_avatar: testimonial.author_avatar,
        rating: testimonial.rating,
        created_at: testimonial.created_at,
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={testimonialList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"testimonial.add_testimonial"}
                        creationPath={paths.testimonialCreate}
                        title={"testimonial"}
                        deletePermission={"testimonial.delete_testimonial"}
                        exportPermission={"testimonial.add_testimonialexportfile"}
                        deleteLink={"/testimonial/delete/testimonial/"}
                        data={testimonialList}
                        setLoading={setLoading}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchTestimonials}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
}

export default withPermission(withTranslation(["testimonials", "common"])(Testimonials), "testimonial.view_testimonial");