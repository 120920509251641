import React, {useState} from 'react';
import {Button, Card, Form, Modal, Row, Spinner} from 'react-bootstrap';
import image from 'assets/img/icons/spot-illustrations/navbar-vertical.png';
import FalconCloseButton from 'components/common/FalconCloseButton';
import CSRFToken from "../../../helpers/CSRFToken";
import {useTranslation} from "react-i18next";
import StarRatings from "react-star-ratings/build/star-ratings";
import FormError from "../../../pages/errors/FormError";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import Flex from "../../common/Flex";
import {Link} from "react-router-dom";
import TinymceEditor from "../../common/TinymceEditor";

const FeedbackCard = () => {
    const [show, setShow] = useState(true);
    const [modal, setModal] = useState(false);
    const [rating, setRating] = useState(0)
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        rating: rating,
        description: '',
    });

    const {t} = useTranslation(["testimonials", "common"])

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        await api.post("/testimonial/", formData).then(res => {
            toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: "colored"})
            setModal(false)
            setRating(0)
            setFormData({
                rating: 0,
                description: '',
            })
        })
            .catch(err => {
                toast.error(t('error', {ns: "common"}), {theme: "colored"})
                setErrors(err.response?.data);
            })
        setLoading(false)
    }

    return (
        show && (
            <div className="settings my-3">
                <Card className="p-0 rounded-2 position-relative">
                    <div
                        className="position-absolute"
                        style={{right: '6px', top: '3px'}}
                    >
                        <FalconCloseButton
                            size="sm"
                            className="fs--2"
                            noOutline
                            onClick={() => setShow(false)}
                        />
                    </div>
                    <Card.Body className="text-center">
                        <img src={image} alt="" width={80}/>
                        <p className="fs--1 mt-2">
                            {t('loving')}
                        </p>
                        <div className="d-grid gap-2">
                            <Button
                                size="sm"
                                className="btn-primary"
                                onClick={() => setModal(true)}
                            >
                                {t('leave')}
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
                <Modal centered size={"lg"} show={modal} onHide={() => setModal(!modal)}>
                    <Modal.Header closeButton>
                        <h5>{t('leave')}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <Flex justifyContent="center" className="p-2 mb-2">
                                <Spinner animation={'border'} variant={'primary'}/>
                            </Flex>
                        ) : (
                            <Form>
                                <CSRFToken/>
                                <Form.Group>
                                    <Form.Label>{t('fields.rating')}</Form.Label>
                                    <Row>
                                        <StarRatings
                                            rating={rating}
                                            changeRating={(newRating) => {
                                                setRating(newRating)
                                                setFormData({...formData, rating: newRating})
                                            }}
                                            starRatedColor="yellow"
                                            numberOfStars={6}
                                            name='rating'
                                        />
                                    </Row>
                                    <FormError error={errors?.rating}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t('fields.description')} <span
                                        className={"text-danger"}>*</span></Form.Label>
                                    <TinymceEditor
                                        value={formData.description}
                                        handleChange={newValue => setFormData({...formData, description: newValue})}
                                    />
                                    <FormError error={errors.description}/>
                                </Form.Group>
                            </Form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            as={Link}
                            onClick={handleSubmit}
                            variant="primary"
                        >
                            {t('save', {ns: "common"})}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    );
};

export default FeedbackCard;
