import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const Updates = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="updates" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Updates</h5>
			</Card.Header>
			<Card.Body>
				<h6>Changes To Our Terms & Conditions</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					You acknowledge and agree that nexameet may stop (permanently or temporarily) providing the Service (or
					any features within the Service) to you or to users generally at nexameet’s sole discretion, without prior
					notice to you. You may stop using the Service at any time. You do not need to specifically inform nexameet
					when you stop using the Service. You acknowledge and agree that if nexameet disables access to your
					account, you may be prevented from accessing the Service, your account details or any files or other
					materials which is contained in your account.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update the
					Terms & Conditions modification date below.
				</p>
				<hr className="my-4"/>
				<h6>Modifications to Our website</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Nexameet reserves the right to modify, suspend or discontinue, temporarily or permanently, the website or
					any service to which it connects, with or without notice and without liability to you.
				</p>
				<hr className="my-4"/>
				<h6>Updates to Our website</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Nexameet may from time to time provide enhancements or improvements to the features/ functionality of the
					website, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					Updates may modify or delete certain features and/or functionalities of the website. You agree that nexameet
					has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features
					and/or functionalities of the website to you.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					You further agree that all Updates will be (i) deemed to constitute an integral part of the website, and (ii)
					subject to the terms and conditions of this Agreement.
				</p>
			</Card.Body>
		</Card>
	)
})

export default Updates