import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Row, Button} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomDateInput from 'components/common/CustomDateInput';
import {Field} from "formik";

const EventScheduleItem = ({
                               index,
                               title,
                               value,
                               handleRemove,
                               setFieldValue,
                               values,
                               t
                           }) => {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    return (
        <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
            <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
                <Button
                    size="sm"
                    variant="link"
                    className="p-0"
                    onClick={() => handleRemove(index)}
                >
                    <FontAwesomeIcon className="text-danger" icon="times-circle"/>
                </Button>
            </div>

            <Row className="gx-2 gy-3">
                <Col md="12">
                    <Form.Group controlId="scheduleStartTitle">
                        <Form.Label>{t('fields.schedule.scheduleTitle')}</Form.Label>
                        <Field name={`schedules.${index}.title`}>
                            {({field}) => (
                                <Form.Control
                                    type="text"
                                    name={`schedules.${index}.title`}
                                    placeholder={t('fields.schedule.scheduleTitle')}
                                    defaultValue={title}
                                    value={field.value}
                                    onChange={(e) => setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group controlId={`ScheduleStartDate${index}`}>
                        <Form.Label>{t('fields.schedule.start_date')}</Form.Label>
                        <Row>
                            <Field name={`schedules.${index}.start`}>
                                {({field}) => (
                                    <DatePicker
                                        selected={values?.schedules[index]?.start ? new Date(values?.schedules[index]?.start) : startDate}
                                        defaultValue=""
                                        name={`schedules.${index}.start`}
                                        onChange={newDate => {
                                            setStartDate(newDate)
                                            setFieldValue(field.name, new Date(newDate).toISOString())
                                        }}
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        fixedHeight
                                        className='form-control'
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'MM/dd/yyyy h:mm aa',
                                                }}
                                            />
                                        }
                                    />
                                )}
                            </Field>
                        </Row>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group controlId="scheduleEndDate">
                        <Form.Label>{t('fields.schedule.end_date')}</Form.Label>
                        <Row>
                            <Field name={`schedules.${index}.end`}>
                                {({field}) => (
                                    <DatePicker
                                        selected={values?.schedules[index]?.end ? new Date(values?.schedules[index]?.end) : endDate}
                                        defaultValue=""
                                        name={`schedules.${index}.end`}
                                        onChange={newDate => {
                                            setEndDate(newDate)
                                            setFieldValue(field.name, new Date(newDate).toISOString())
                                        }}
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        fixedHeight
                                        className='form-control'
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'MM/dd/yyyy h:mm aa',
                                                }}
                                            />
                                        }
                                    />
                                )}
                            </Field>
                        </Row>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group controlId="scheduleStartColor">
                        <Form.Label>{t('fields.schedule.color')}</Form.Label>
                        <Field name={`schedules.${index}.color`}>
                            {({field}) => (
                                <Form.Control
                                    type="color"
                                    name={`schedules.${index}.color`}
                                    placeholder="Color"
                                    defaultValue={title}
                                    value={field.value}
                                    onChange={(e) => setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col md="6">
                    <Form.Group controlId="scheduleStartLabel">
                        <Form.Label>{t('fields.schedule.label.title')}</Form.Label>
                        <Field name={`schedules.${index}.label`}>
                            {({field}) => (
                                <Form.Select value={field.value}
                                             onChange={(e) => setFieldValue(field.name, e.target.value)}>
                                    <option value="">{t('select', {ns: "common"})} {t('fields.schedule.label.title')}</option>
                                    <option value="business">{t('fields.schedule.label.business')}</option>
                                    <option value="important">{t('fields.schedule.label.important')}</option>
                                    <option value="personal">{t('fields.schedule.label.personal')}</option>
                                    <option value="must_attend">{t('fields.schedule.label.must')}</option>
                                </Form.Select>
                            )}
                        </Field>
                    </Form.Group>
                </Col>
                <Col md="12">
                    <Form.Group controlId="scheduleStartDesc">
                        <Form.Label>{t('fields.schedule.description')}</Form.Label>
                        <Field name={`schedules.${index}.description`}>
                            {({field}) => (
                                <Form.Text
                                    name={`schedules.${index}.description`}
                                    placeholder={t('fields.schedule.description')}
                                    as={"textarea"}
                                    className={"form-control"}
                                    value={field.value}
                                    onChange={(e) => setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
};
export default EventScheduleItem;

EventScheduleItem.propTypes = {
    index: PropTypes.number.isRequired,
    handleRemove: PropTypes.func.isRequired,
};
