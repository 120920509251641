import React, {useCallback, useState} from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";
import CSRFToken from "../../../../helpers/CSRFToken";
import {debounce} from "lodash";

const RequestQuestionsModal = ({open, setOpen, activity, getJoinRequest}) => {
	const [loading, setLoading] = useState(false);
	const [answers, setAnswers] = useState([]);

	const {id} = useParams();

	const onChange = (e, question) => {
		setAnswers([
			...answers,
			{
				question: question,
				answer: e.target.value,
				join: id
			}
		])
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedChangeField = useCallback(debounce(onChange, 600), [])

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true)
		for (const answer of answers) {
			await api.post("/join/answer/", answer)
				.then(res => {
					toast.success("Answer successfully recorded.", {theme: "colored"})
				})
				.catch(error => {
					toast.error("An error has occurred.", {theme: "colored"})
				})
		}
		await api.put(`/join/join/${id}/`, {status: "pending"})
		setAnswers([])
		setOpen(!open)
		getJoinRequest()
		setLoading(false)
	}

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Modal size={"lg"} centered show={open} onHide={() => setOpen(!open)}>
			<Modal.Header closeButton>
				<h5>Questions</h5>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<CSRFToken />
					{activity?.activity_questions?.map((question) => (
						<Form.Group>
							<Form.Label>{question?.name}</Form.Label>
							<Form.Control
								as="textarea"
								placeholder={question?.description}
								onChange={(e) => delayedChangeField(e, question?.id)}
							/>
						</Form.Group>
					))}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={handleSubmit}>
					Save <FontAwesomeIcon icon={faSave} className="ms-2"/>
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default RequestQuestionsModal