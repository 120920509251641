import EventFooter from './EventFooter';
import {Button, Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import EventOtherInfo from './EventOtherInfo';
import EventSchedule from './EventSchedule';
import EventTicket from './EventTicket';
import EventDetails from './EventDetails';
import EventBanner from './EventBanner';
import EventHeader from './EventHeader';
import React, {useState} from 'react'
import {Formik} from 'formik';
import Flex from '../../../components/common/Flex';
import CSRFToken from '../../../helpers/CSRFToken';
import {withPermission} from '../../../helpers/utils';
import RegistrationOptions from "./RegistrationOptions";
import TicketPreviewCard from "../TicketPreviewCard";
import {withTranslation} from "react-i18next";
import EventUpload from "./EventUpload";
import EventCustomField from "./EventCustomField";
import EventSession from "./EventSession";
import {faBook, faCalendarAlt, faListAlt, faTasks, faUpload, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EventSpeaker from "./EventSpeaker";
import EventResource from "./EventResource";

const NewEvent = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [sections, setSections] = useState({
        sessions: false,
        schedules: false,
        images: false,
        custom_fields: false,
        speakers: false,
        resources: false,
    });
    const [formData, setFormData] = useState({
        activity: '',
        cover: '',
        title: '',
        start_date: '',
        end_date: '',
        registration_deadline: '',
        venue: '',
        address: '',
        delegation: '',
        city: '',
        description: '',
        type: '',
        topic: '',
        tags: [],
        public: false,
        ticket: {
            options: [{name: 'Front desks', price: 0.0, currency: 'TND', checked: true}],
            variable: true,
            type: 'paid',
            title: "",
            color: "#272c97",
            elements: [
                {
                    "key": 0,
                    "field": "component",
                    "align": "left",
                    "text": "",
                    "type": "",
                    "component": "column",
                    "data": "activity_logo"
                },
                {
                    "key": 1,
                    "field": "component",
                    "align": "right",
                    "text": "",
                    "type": "",
                    "component": "column",
                    "data": "event_subtitle"
                },
                {
                    "key": 2,
                    "field": "feed",
                    "align": "",
                    "text": "",
                    "size": 2,
                    "type": "",
                    "component": "",
                    "data": ""
                },
                {
                    "key": 3,
                    "field": "component",
                    "align": "left",
                    "text": "From: ",
                    "type": "",
                    "component": "column",
                    "data": "event_start_date"
                },
                {
                    "key": 4,
                    "field": "component",
                    "align": "right",
                    "text": "To: ",
                    "type": "",
                    "component": "column",
                    "data": "event_end_date"
                },
                {
                    "key": 5,
                    "field": "component",
                    "align": "left",
                    "text": "",
                    "type": "",
                    "component": "row",
                    "color": "#583c3c",
                    "data": "event_title"
                },
                {
                    "key": 6,
                    "field": "component",
                    "align": "left",
                    "text": "",
                    "type": "",
                    "component": "column",
                    "data": "full_name"
                },
                {
                    "key": 7,
                    "field": "component",
                    "align": "right",
                    "text": "",
                    "type": "",
                    "component": "column",
                    "data": "registered_at"
                },
                {
                    "key": 8,
                    "field": "barcode",
                    "align": "",
                    "text": "",
                    "type": "qr_code",
                    "data": "event_id"
                }
            ]
        },
        registration: {
            type: "standard",
            start_date: null,
            end_date: null,
            menus: [],
            questions: []
        },
        schedules: [],
        images: [],
        custom_fields: [],
        speakers: [],
        resources: [],
    });

    const handleAddSection = section => {
        setSections(prevSections => ({
            ...prevSections,
            [section]: true,
        }));
    };

    const handleRemove = section => {
        setSections(prevSections => ({
            ...prevSections,
            [section]: false,
        }));
    }

    const onSubmit = async data => {
        console.log(data);
    };

    return loading ? (
        <>
            <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
                <Spinner animation={'border'} variant={'primary'}/>
            </Flex>
        </>
    ) : (
        <Formik initialValues={formData} onSubmit={values => onSubmit(values)}>
            {({values, setFieldValue}) => (
                <Form>
                    <CSRFToken/>
                    <Row className="g-3">
                        <Col xs={12}>
                            <EventHeader
                                values={values}
                                setFormData={setFormData}
                                setLoading={setLoading}
                                setErrors={setErrors}
                                t={t}
                            />
                        </Col>
                        <Col xs={12}>
                            <EventBanner
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                t={t}
                            />
                        </Col>
                        <Col lg={8}>
                            <EventDetails
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                t={t}
                                setErrors={setErrors}
                            />
                            <EventTicket
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                t={t}
                            />
                            {sections.sessions && (
                                <EventSession
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.schedules && (
                                <EventSchedule
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.images && (
                                <EventUpload
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    onRemove={handleRemove}
                                />
                            )}
                            {sections.custom_fields && (
                                <EventCustomField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.speakers && (
                                <EventSpeaker
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.resources && (
                                <EventResource
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            <Card className={"mt-3"}>
                                <Card.Header>
                                    <h5>Add Section</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('sessions')} className={"mb-2 w-100"} variant={"outline-info"}>
                                                <FontAwesomeIcon icon={faTasks} className={"me-2"} /> Add Sessions and Activities
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('schedules')} className={"mb-2 w-100"} variant={"outline-success"}>
                                                <FontAwesomeIcon icon={faCalendarAlt} className={"me-2"} /> Add Schedule
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('images')} className={"mb-2 w-100"} variant={"outline-primary"}>
                                                <FontAwesomeIcon icon={faUpload} className={"me-2"} /> Add Upload Photos
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('custom_fields')} className={"mb-2 w-100"} variant={"outline-warning"}>
                                                <FontAwesomeIcon icon={faListAlt} className={"me-2"} /> Add Custom Fields
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('speakers')} className={"mb-2 w-100"} variant={"outline-danger"}>
                                                <FontAwesomeIcon icon={faUser} className={"me-2"} /> Add Speakers
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('resources')} className={"mb-2 w-100"} variant={"outline-dark"}>
                                                <FontAwesomeIcon icon={faBook} className={"me-2"} /> Add Resources
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <div className={"sticky-sidebar"}>
                                <EventOtherInfo
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                />
                                <RegistrationOptions
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TicketPreviewCard errors={errors} setFieldValue={setFieldValue} values={values} t={t}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EventFooter
                                values={values}
                                setFormData={setFormData}
                                setLoading={setLoading}
                                setErrors={setErrors}
                                t={t}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default withPermission(withTranslation(["events", "common"])(NewEvent), "event.add_event");
