import {formatDateTime, withPermission} from "../../helpers/utils";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../hooks/useQuery";
import {useAppContext} from "../../providers/AppProvider";
import {usePermissions} from "../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../utils/api";
import paths from "../../routes/paths";
import {debounce} from "lodash";
import SoftBadge from "../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../BaseTableHeader";
import Flex from "../../components/common/Flex";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";

const Enotices = () => {
    const [enotices, setEnotices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);

    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();
    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchEnotices = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);

        // Cancel the previous request if it exists
        await api
            .get(`/enotice/enotice/?${q.toString()}`)
            .then(res => {
                setEnotices(res?.data.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchEnotices, 600), []);

    useEffect(() => {
        fetchEnotices(query);
        setLength(enotices.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'subject',
            Header: 'Subject',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'subject' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => <Link
                to={paths.enoticeDetail.replace(":id", rowData.row.original.id)}>{rowData.row.original.subject}</Link>
        },
        {
            accessor: 'send_by',
            Header: 'Send By',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'send_by' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => formatDateTime(rowData.row.original.send_by)
        },
        {
            accessor: 'priority',
            Header: 'Priority',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'status' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => {
                const {priority, priority_display} = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: priority === 'normal',
                            warning: priority === 'high',
                            danger: priority === 'critical'
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {priority_display}
                        </p>
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'status' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => {
                const {status, status_display} = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            secondary: status === 'draft',
                            primary: status === 'submitted',
                            warning: status === 'pending',
                            success: status === 'sent',
                            danger: status === 'failed'
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {status_display}
                        </p>
                        <FontAwesomeIcon
                            icon={classNames({
                                check: status === 'sent',
                                "pencil-alt": status === 'submitted',
                                "paper-clip": status === 'draft',
                                clock: status === 'pending',
                                ban: status === 'failed'
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'created_at',
            Header: 'Created At',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'created_at' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => {
                const {created_at} = rowData.row.original;
                return <p>{formatDateTime(created_at)}</p>;
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id, subject} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: 'Are you sure?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/enotice/enotice/${id}/`).then(() => {
                            toast.success(`Enotice ${subject} successfully deleted.`, {
                                theme: 'colored'
                            });
                            fetchEnotices(query)
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("enotice.change_enotice") &&
                                <Dropdown.Item onClick={() => navigate(paths.enoticeEdit.replace(":id", id))}>
                                    Edit
                                </Dropdown.Item>
                            }
                            {hasPermission("billing.delete_billing") &&
                                <>
                                    <Dropdown.Divider as="div"/>
                                    <Dropdown.Item
                                        onClick={e => handleDelete(e)}
                                        className="text-danger"
                                    >
                                        Delete
                                    </Dropdown.Item>
                                </>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const enoticesList = enotices?.map(enotice => ({
        id: enotice?.id,
        subject: enotice?.subject,
        send_by: enotice?.send_by,
        status: enotice?.status,
        status_display: enotice?.status_display,
        priority: enotice?.priority,
        priority_display: enotice?.priority_display,
        created_at: enotice?.created_at
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={enoticesList}
            pagination
            selection
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"enotice.add_enotice"}
                        creationPath={paths.enoticeCreate}
                        title={"enotice"}
                        deletePermission={"enotice.delete_enotice"}
                        data={enoticesList}
                        exportLink={"/enotice/export/enotice/"}
                        deleteLink={"/enotice/delete/enotice/"}
                        setLoading={setLoading}
                        fields={["subject", "send_by", "reminder_by", "priority", "status", "created_at", "updated_at"]}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchEnotices}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
}

export default withPermission(Enotices, "enotice.view_enotice")