import React from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import calendlyLogo from 'assets/img/logos/Calendly.svg';
import zoomLogo from 'assets/img/logos/zoom.png';

const SocialAuthButtons = ({ type }) => {
  const handleGoogleClick = () => {
    window.location.replace(
      `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${
        type === 'login'
          ? process.env.REACT_APP_GOOGLE_LOGIN_CALLBACK_URL
          : process.env.REACT_APP_GOOGLE_CONNECT_CALLBACK_URL
      }&prompt=consent&response_type=code&client_id=${
        process.env.REACT_APP_GOOGLE_CLIENT_ID
      }&scope=openid%20email%20profile%20https://www.googleapis.com/auth/calendar&access_type=offline`
    );
  };

  const handleZoomClick = () => {
    window.location.replace(
      `https://zoom.us/oauth/authorize?client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&response_type=code&redirect_uri=${
        type === 'login'
          ? process.env.REACT_APP_ZOOM_LOGIN_CALLBACK_URL
          : process.env.REACT_APP_ZOOM_CONNECT_CALLBACK_URL
      }`
    );
  }

  const handleCalendlyClick = () => {
    window.location.replace(
      `https://auth.calendly.com/oauth/authorize?client_id=${process.env.REACT_APP_CALENDLY_CONNECT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_CALENDLY_CONNECT_CALLBACK_URL}`
    );
  };

  return (
    <Form.Group className="mb-0">
      <Row>
        <Col sm={type === 'connect' ? 4 : 12} className="pe-sm-1">
          <Button
            variant=""
            size="sm"
            className="btn-outline-google-plus mt-2 w-100"
            onClick={handleGoogleClick}
          >
            <FontAwesomeIcon
              icon={['fab', 'google-plus-g']}
              transform="grow-8"
              className="me-2"
            />{' '}
            Google
          </Button>
        </Col>
        {type === 'connect' && (
          <Col sm={4} className="pe-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-primary mt-2 w-100"
              onClick={handleCalendlyClick}
            >
              <img src={calendlyLogo} width={32} alt={'calendly logo'} />{' '}
              Calendly
            </Button>
          </Col>
        )}
        <Col sm={type === 'connect' ? 4 : 12} className="pe-sm-1">
          <Button
            variant=""
            size="sm"
            className="btn-outline-primary mt-2 w-100"
            onClick={handleZoomClick}
          >
            <img src={zoomLogo} width={32} alt={'zoom logo'} />{' '}
            Zoom
          </Button>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default SocialAuthButtons;
