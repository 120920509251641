import React, {useRef} from 'react';
import PageHeader from 'components/common/PageHeader';
import {Card, Col, Nav, Row} from 'react-bootstrap';
import Liability from './Liability';
import useScrollSpy from 'react-use-scrollspy';
import Definitions from "./Definitions";
import InformationCollection from "./InformationCollection";
import Affiliate from "./Affiliate";
import GoverningLaw from "./GoverningLaw";
import GDPR from "./GDPR";
import CaliforniaResidents from "./CaliforniaResidents";
import Contact from "./Contact";
import Section from "../../components/common/Section";

const PrivacyPolicy = () => {
	const sectionRefs = [
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null)
	];

	const activeSection = useScrollSpy({
		sectionElementRefs: sectionRefs,
		offsetPx: -150
	});

	return (
		<Section fluid>
			<PageHeader
				title="Privacy policy"
				description="
				Nexameet (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your
				personal information is collected, used, and disclosed by nexameet. This Privacy Policy applies to our website, and its associated
				subdomains (collectively, our “Service”) alongside our application, nexameet. By accessing or using our Service, you signify that you have read,
				understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service."
				className="mb-3"
			/>
			<Row className="g-0">
				<Col lg={8} className="pe-lg-2 order-1 order-lg-0">
					<Definitions ref={sectionRefs[0]}/>
					<InformationCollection ref={sectionRefs[1]}/>
					<Affiliate ref={sectionRefs[2]}/>
					<GoverningLaw ref={sectionRefs[3]}/>
					<Liability ref={sectionRefs[4]}/>
					<GDPR ref={sectionRefs[5]}/>
					<CaliforniaResidents ref={sectionRefs[6]}/>
					<Contact ref={sectionRefs[7]}/>
				</Col>
				<Col lg={4} className="ps-lg-2 mb-3">
					<div className="sticky-sidebar">
						<Card className="sticky-top">
							<Card.Header className="border-bottom">
								<h6 className="mb-0 fs-9">On this page</h6>
							</Card.Header>
							<Card.Body>
								<div
									id="terms-sidebar"
									className="terms-sidebar nav flex-column fs-10"
								>
									<Nav.Item>
										<Nav.Link
											href="#definitions"
											className="nav-link px-0 py-1"
											active={activeSection === 0}
										>
											Definitions and key terms
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#information"
											className="nav-link px-0 py-1"
											active={activeSection === 1}
										>
											Information Collection
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#affiliate"
											className="nav-link px-0 py-1"
											active={activeSection === 2}
										>
											Corporate Affiliate
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#law"
											className="nav-link px-0 py-1"
											active={activeSection === 3}
										>
											Governing Law
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#liability"
											className="nav-link px-0 py-1"
											active={activeSection === 4}
										>
											Liability
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#gdpr"
											className="nav-link px-0 py-1"
											active={activeSection === 5}
										>
											General Data Protection Regulation
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#caresidents"
											className="nav-link px-0 py-1"
											active={activeSection === 6}
										>
											California Residents
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link
											href="#contact"
											className="nav-link px-0 py-1"
											active={activeSection === 7}
										>
											Contact Us
										</Nav.Link>
									</Nav.Item>
								</div>
							</Card.Body>
						</Card>
					</div>
				</Col>
			</Row>
		</Section>
	);
};

export default PrivacyPolicy;
