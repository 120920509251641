import {withPermission} from "../../../../helpers/utils";
import React, {useEffect, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import {Field, Formik} from "formik";
import CSRFToken from "../../../../helpers/CSRFToken";
import IconButton from "../../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";
import paths from "../../../../routes/paths";
import FormError from "../../../errors/FormError";

const CurrencyAddEdit = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        code: "",
        name: "",
        symbol: "",
        factor: "",
        locale: "",
        is_active: true,
        is_base: false,
        is_default: false,
        info: "{}",
    })

    const navigate = useNavigate()
    const {code} = useParams()

    const getCurrency = async () => {
        setLoading(true);
        await api.get(`/currency/${code}/`).then((response) => {
            setFormData(response?.data)
        })
            .catch(err => {

            })
        setLoading(false)
    }

    useEffect(() => {
        getCurrency()
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e, values) => {
        e.preventDefault()
        setLoading(true)
        if (!code) {
            await api.post("/currency/", values)
                .then(res => {
                    toast.success("Currency successfully added.", {theme: "colored"})
                    navigate(paths.currencyList);
                })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        }
        else {
            await api.patch(`/currency/${code}/`, values)
                .then(res => {
                    toast.success("Currency successfully updated.", {theme: "colored"})
                    navigate(paths.currencyList);
                })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        }
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Formik initialValues={formData} onSubmit={values => console.log(values)}>
            {({values, setFieldValue}) => (
                <Form>
                    <CSRFToken/>
                    <Row className="g-3">
                        <Col>
                            <Card>
                                <Card.Header>
                                    <h5>Basic Information</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Code <span className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"code"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"code"}
                                                            type={"text"}
                                                            value={field.value}
                                                            placeholder={"Code"}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.code}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Name <span className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"name"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"name"}
                                                            type={"text"}
                                                            value={field.value}
                                                            placeholder={"Name"}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.name}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Symbol</Form.Label>
                                                <Field name={"symbol"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"symbol"}
                                                            type={"text"}
                                                            value={field.value}
                                                            placeholder={"Symbol"}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.symbol}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className={"mt-3"}>
                                <Card.Header>
                                    <h5>Currency Information</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Factor <span className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"factor"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"factor"}
                                                            type={"number"}
                                                            value={field.value}
                                                            placeholder={"Factor"}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <Form.Text>
                                                    Specifies the currency rate ratio to the base currency.
                                                </Form.Text>
                                                <FormError error={errors?.factor}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Locale <span className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"locale"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"locale"}
                                                            type={"text"}
                                                            value={field.value}
                                                            placeholder={"Locale"}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.locale}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-3"}>
                                        <Col>
                                            <Form.Group>
                                                <Field name={"is_active"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            name={"is_active"}
                                                            checked={field.value}
                                                            onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                            label={"Active"}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.is_active}/>
                                                <Form.Text>
                                                    The currency will be available.
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Field name={"is_base"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            name={"is_base"}
                                                            checked={field.value}
                                                            onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                            label={"Base"}
                                                        />
                                                    )}
                                                </Field>
                                                <Form.Text>
                                                    Make this the base currency against which rate factors are
                                                    calculated.
                                                </Form.Text>
                                                <FormError error={errors?.is_base}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Field name={"is_default"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            name={"is_default"}
                                                            checked={field.value}
                                                            onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                            label={"Default"}
                                                        />
                                                    )}
                                                </Field>
                                                <Form.Text>
                                                    Make this the default user currency.
                                                </Form.Text>
                                                <FormError error={errors?.is_default}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className={"mt-3"}>
                                <Card.Header>
                                    <h5>More Information</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label>Information</Form.Label>
                                        <Field name={"info"}>
                                            {({field}) => (
                                                <Form.Control
                                                    as={"textarea"}
                                                    rows={6}
                                                    name={"info"}
                                                    placeholder={"{}"}
                                                    onChange={e => setFieldValue(field.name, e.target.value)}
                                                    value={field.value}
                                                />
                                            )}
                                        </Field>
                                        <FormError error={errors?.info}/>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card className={"mt-3"}>
                                <Card.Footer>
                                    <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                        <p className={"text-danger"}>* Mandatory Field</p>
                                        <IconButton
                                            icon={faSave}
                                            onClick={e => handleSubmit(e, values)}
                                        >
                        <span className="d-none d-sm-inline-block ms-1">
                          Save
                        </span>
                                        </IconButton>
                                    </Flex>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default withPermission(CurrencyAddEdit, ["currency.add_currency", "currency.change_currency"]);