import React, {Fragment, useEffect, useRef, useState} from 'react';
import useQuery from '../../../../hooks/useQuery';
import {useNavigate} from 'react-router-dom';
import {getUsers} from '../../../account/actions/Users';
import {getActivities} from '../../../activity/actions/Activity';
import {getRoles} from '../../actions/Role';
import {api} from '../../../../utils/api';
import {toast} from 'react-toastify';
import Flex from '../../../../components/common/Flex';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import Select from 'react-select';
import FormError from '../../../errors/FormError';
import CardHeader from 'react-bootstrap/CardHeader';
import IconButton from '../../../../components/common/IconButton';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import CSRFToken from '../../../../helpers/CSRFToken';
import {withPermission} from '../../../../helpers/utils';
import CreatableSelect from "react-select/creatable";
import {getPermissions} from "../../../account/actions/GroupsPermissions";
import {Editor} from "@tinymce/tinymce-react";
import {useAppContext} from "../../../../providers/AppProvider";
import {withTranslation} from "react-i18next";

const NewMember = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [pageUser, setPageUser] = useState(1);
    const [activities, setActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [pageActivity, setPageActivity] = useState(1);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [pageRole, setPageRole] = useState(1);
    const [errors, setErrors] = useState({});
    const [permissions, setPermissions] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const [permissionsPage, setPermissionsPage] = useState(1)
    const [formData, setFormData] = useState({
        user: '',
        activity: '',
        role: '',
        paid: false
    });
    const [roleForm, setRoleForm] = useState(false)
    const [roleFormData, setRoleFormData] = useState({
        name: "",
        activity: "",
        description: "",
        moderator: false,
        permissions: []
    })

    const query = useQuery();
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const {
        config: {isActivity}
    } = useAppContext()

    const fetchPermissions = () => {
        setLoading(true)
        query.set("page_size", "50")
        query.set("page", permissionsPage.toString())
        getPermissions(query)
            .then(res => setPermissions([...permissions, ...res?.results]))
            .catch(() => {
            })
        setLoading(false)
    }

    useEffect(() => {
        fetchPermissions()
        // eslint-disable-next-line
    }, [permissionsPage]);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageUser.toString());
        getUsers(query)
            .then(res => setUsers([...users, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageUser]);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageActivity.toString());
        getActivities(query)
            .then(res => setActivities([...activities, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageActivity]);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageRole.toString());
        getRoles(query)
            .then(res => setRoles([...roles, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageRole]);

    let userOptions = users?.map(user => ({
        value: user.id,
        label: user.full_name
    }));

    userOptions = [
        {value: '', label: '----------------------------'},
        ...userOptions
    ];

    let activityOptions = activities?.map(activity => ({
        value: activity.id,
        label: activity.name
    }));

    activityOptions = [
        {value: '', label: '----------------------------'},
        ...activityOptions
    ];

    let roleOptions = roles?.map(role => ({value: role.id, label: role.name}));

    roleOptions = [
        {value: '', label: '----------------------------'},
        ...roleOptions
    ];

    let permissionsOptions = permissions?.map(permission => (
        {label: permission?.name, value: permission?.id}
    ))

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        if (roleForm) {
            let fd = new FormData()
            fd.append("name", roleFormData.name)
            fd.append("moderator", roleFormData.moderator)
            fd.append("activity", roleFormData.activity)
            fd.append("description", roleFormData.description)
            selectedPermissions?.map(permission => fd.append("permissions", permission.value))
            await api.post(`/member/role/`, fd)
                .then(async res => {
                    await api
                        .post('/member/member/', {...formData, role: res?.data?.id}, {
                            headers: {
                                "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                            }
                        })
                        .then(() => {
                            toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: "colored"});
                            navigate('/members/member');
                        })
                        .catch(error => {
                            setErrors(error?.response?.data);
                            toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"});
                        });
                })
                .catch(err => setErrors(err?.response?.data))
        } else {
            await api
                .post('/member/member/', formData)
                .then(() => {
                    toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: "colored"});
                    navigate('/members/member');
                })
                .catch(error => {
                    setErrors(error?.response?.data);
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"});
                });
        }
        setLoading(false);
    };

    const handleFieldChange = (e) => {
        setRoleFormData({...roleFormData, [e.target.name]: e.target.value})
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header>
                    <h5 className="mb-0 text-muted">{t('fields.basic')}</h5>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <CSRFToken/>
                        <Row>
                            <Col md={isActivity ? 12 : 6}>
                                <Form.Label>{t('fields.user')}: <span className={"text-danger"}>*</span></Form.Label>
                                <Form.Group>
                                    <Select
                                        options={userOptions}
                                        value={selectedUser}
                                        classNamePrefix="react-select"
                                        onChange={value => {
                                            setSelectedUser(value);
                                            setFormData({...formData, user: value.value});
                                            query.set('user', value.value);
                                            getActivities(query)
                                                .then(r => setActivities(r?.results))
                                                .catch(() => {
                                                });
                                        }}
                                        placeholder={`${t('select', {ns: "common"})} ${t('fields.user')}`}
                                        onMenuScrollToBottom={() => {
                                            setPageUser(page => page + 1);
                                        }}
                                        onInputChange={e => {
                                            query.set('search', e);
                                            getUsers(query).then(r => setUsers(r?.results));
                                        }}
                                    />
                                    <FormError error={errors.user}/>
                                </Form.Group>
                            </Col>
                            {!isActivity &&
                                <Col md={6}>
                                    <Form.Label>{t('fields.activity')}:</Form.Label>
                                    <Form.Group>
                                        <Select
                                            options={activityOptions}
                                            value={selectedActivity}
                                            classNamePrefix="react-select"
                                            onChange={value => {
                                                setSelectedActivity(value);
                                                setFormData({...formData, activity: value.value});
                                                query.set('activity', value.value);
                                                getRoles(query)
                                                    .then(r => setRoles(r?.results))
                                                    .catch(() => {
                                                    });
                                            }}
                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.activity')}`}
                                            onMenuScrollToBottom={() => {
                                                setPageActivity(page => page + 1);
                                            }}
                                            onInputChange={e => {
                                                query.set('search', e);
                                                getActivities(query).then(r => setActivities(r?.results));
                                            }}
                                        />
                                        <FormError error={errors.activity}/>
                                    </Form.Group>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Label></Form.Label>
                                <Form.Group className={'d-flex align-items-center'}>
                                    <Form.Switch
                                        label={`${t('fields.membershipPaid')}`}
                                        checked={formData.paid}
                                        onChange={e => {
                                            setFormData({...formData, paid: e.target.checked});
                                        }}
                                    />
                                    <FormError error={errors.paid}/>
                                </Form.Group>
                            </Col>
                            {(selectedActivity?.value || isActivity) &&
                                <Col md={6}>
                                    <Form.Label>{t('fields.role')}: <span className={"text-danger"}>*</span></Form.Label>
                                    <Form.Group>
                                        <CreatableSelect
                                            options={roleOptions}
                                            isClearable
                                            value={selectedRole}
                                            classNamePrefix="react-select"
                                            onChange={value => {
                                                setSelectedRole(value);
                                                setFormData({...formData, role: value.value});
                                                if (value?.__isNew__) {
                                                    setRoleForm(true)
                                                    setRoleFormData({
                                                        ...roleFormData,
                                                        name: value?.label,
                                                        activity: selectedActivity.value
                                                    })
                                                } else {
                                                    setRoleForm(false)
                                                    setRoleFormData({
                                                        name: "",
                                                        activity: "",
                                                        description: "",
                                                        moderator: false,
                                                        permissions: []
                                                    })
                                                }
                                            }}
                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.role')}`}
                                            formatCreateLabel={inputValue => `${t('create', {ns: "common"})} ${inputValue}`}
                                            onMenuScrollToBottom={() => {
                                                setPageRole(page => page + 1);
                                            }}
                                            onInputChange={e => {
                                                query.set('search', e);
                                                getRoles(query).then(r => setRoles(r?.results));
                                            }}
                                        />
                                        <FormError error={errors.role}/>
                                    </Form.Group>
                                </Col>
                            }
                        </Row>
                        <FormError error={errors.member}/>
                    </Form>
                </Card.Body>
            </Card>
            {roleForm &&
                <Row className={'g-3 mt-1 mb-3'}>
                    <Col xxl={6} xl={12}>
                        <Row className="g-3">
                            <Col xs={12}>
                                <Card>
                                    <Card.Header>
                                        <h5 className="mb-0 text-muted">
                                            {t('fields.roleInfo', {ns: "roles"})}
                                        </h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.name', {ns: "roles"})}: <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        placeholder={t('fields.name', {ns: "roles"})}
                                                        formGroupProps={{
                                                            className:
                                                                'mb-3'
                                                        }}
                                                        onChange={handleFieldChange}
                                                        value={roleFormData.name}
                                                    />
                                                </Form.Group>
                                                <FormError error={errors.name}/>
                                            </Col>
                                        </Row>
                                        <Form.Group className={"mt-3"}>
                                            <Form.Switch
                                                checked={roleFormData.moderator}
                                                label={t('fields.moderator', {ns: "roles"})}
                                                onChange={(e) => {
                                                    setRoleFormData({...roleFormData, moderator: e.target.checked})
                                                }}
                                                name={"moderator"}
                                            />
                                        </Form.Group>
                                        <FormError error={errors.moderator}/>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5 className="mb-0 text-muted">
                                            {t('fields.permissions', {ns: "roles"})} <span className={"text-danger"}>*</span>
                                        </h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Select
                                            options={permissionsOptions}
                                            value={selectedPermissions}
                                            onChange={value => {
                                                setSelectedPermissions(value)
                                            }}
                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.permissions', {ns: "roles"})}`}
                                            onInputChange={(value) => {
                                                query.set("search", value)
                                                getPermissions(query).then(r => setPermissions(r?.results))
                                                    .catch(() => {
                                                    })
                                            }}
                                            onMenuScrollToBottom={() => {
                                                setPermissionsPage(prevState => prevState + 1)
                                            }}
                                            classNamePrefix={"react-select"}
                                            isMulti
                                        />
                                        <FormError error={errors.permissions}/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={6} xl={12}>
                        <Card>
                            <Card.Header>
                                <h5 className="mb-0 text-muted">
                                    {t('fields.description', {ns: "roles"})} <span className={"text-danger"}>*</span>
                                </h5>
                            </Card.Header>
                            <Card.Body className="bg-light pb-0">
                                <Form.Group>
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINY_API_KEY}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={roleFormData.description}
                                        init={{
                                            height: 320,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                        onChange={() => {
                                            setRoleFormData({
                                                ...roleFormData,
                                                description: editorRef.current.getContent()
                                            })
                                        }}
                                    />
                                </Form.Group>
                                <FormError error={errors.description}/>
                            </Card.Body>
                            <Card.Footer className={"mt-2"}></Card.Footer>
                        </Card>
                    </Col>
                </Row>
            }
            <Card className={'mt-3'}>
                <CardHeader>
                    <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                        <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                        <IconButton icon={faSave} onClick={e => handleSubmit(e)}>
                            <span className="d-none d-sm-inline-block ms-1">{t('save', {ns: "common"})}</span>
                        </IconButton>
                    </Flex>
                </CardHeader>
            </Card>
        </Fragment>
    );
};

export default withPermission(withTranslation(["members", "roles", "common"])(NewMember), "member.add_member");
