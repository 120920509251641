import React from "react";
import Hero from "./Hero";
import Processes from "./Processes";
// import Testimonial from "./Testimonial";
import Contact from "./Contact";
import {useAppContext} from "../../providers/AppProvider";
import {Navigate} from "react-router-dom";
import {rootPaths} from "../../routes/paths";
import Events from "./Events";
import {withTranslation} from "react-i18next";
import Chronicles from "./Chronicles";
import Testimonial from "./Testimonial";

const Landing = ({t, i18n}) => {
    const {
        config: {isAuthenticated}
    } = useAppContext()

    if (isAuthenticated) return <Navigate to={rootPaths.dashboardRoot} replace/>;

    return (
        <>
            <Hero t={t} i18n={i18n}/>
            <Events t={t} i18n={i18n}/>
            <Processes t={t} i18n={i18n}/>
            <Chronicles t={t} i18n={i18n} />
            <Testimonial t={t} i18n={i18n} />
            <Contact t={t} i18n={i18n}/>
        </>
    );
};

export default withTranslation(["landing", "common"])(Landing);
