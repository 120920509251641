import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CardDropdown from '../../../components/common/CardDropdown';
import {Card, Dropdown, Spinner} from 'react-bootstrap';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../../components/common/advance-table/AdvanceTableWrapper';
import useQuery from '../../../hooks/useQuery';
import Flex from '../../../components/common/Flex';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {api} from '../../../utils/api';
import {debounce} from 'chart.js/helpers';
import {getMembers} from '../actions/Member';
import {formatDateTime, withPermission} from '../../../helpers/utils';
import {useAppContext} from '../../../providers/AppProvider';
import paths from "../../../routes/paths";
import {usePermissions} from "../../../providers/PermissionsProvider";
import BaseTableHeader from "../../BaseTableHeader";
import {withTranslation} from "react-i18next";

const Members = ({t, i18n}) => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('joined_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();
    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchMembers = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);

        // Cancel the previous request if it exists
        getMembers(q)
            .then(res => {
                setMembers(res?.results);
                setCount(res.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchMembers, 600), []);

    useEffect(() => {
        fetchMembers(query);
        setLength(members.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'user',
            Header: t('fields.user'),
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'user' && sortingDirection,
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const {user, id} = rowData.row.original;
                return (
                    <>
                        <Link to={`/members/member/${id}/detail`}>
                            <Flex alignItems="center">
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: '200px',
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {user}
                                    </h6>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            }
        },
        {
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            accessor: 'role',
            Header: t('fields.role'),
            canSort: true,
            sortingDirection: sortingField === 'role' && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {role_name, role} = rowData.row.original;
                return (
                    <>
                        <Link to={`/members/role/${role}/edit`}>
                            <Flex alignItems="center">
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: '200px',
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {role_name}
                                    </h6>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            }
        },
        {
            accessor: 'joined_at',
            Header: t('fields.joinedAt'),
            canSort: true,
            headerProps: {className: 'pe-1 text-center'},
            cellProps: {
                className: 'py-2 fs-0 text-center'
            },
            sortingDirection: sortingField === 'joined_at' && sortingDirection,
            isSorted: false,
            Cell: rowData => <>{formatDateTime(rowData.row.original.joined_at)}</>
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: "common"}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
                        cancelButtonText: `${t('cancel', {ns: "common"})}`
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/member/member/${id}/`).then(() => {
                            toast.success(`${t('title')} ${t('deleteSuccess', {ns: "common"})}.`, {
                                theme: 'colored'
                            });
                            getMembers(query)
                                .then(res => {
                                    setMembers(res?.results);
                                    setCount(res?.count);
                                })
                                .catch(() => {
                                });
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("member.view_member") &&
                                <Dropdown.Item
                                    onClick={() => navigate(`/members/member/${id}/detail`)}
                                >
                                    {t('detail', {ns: "common"})}
                                </Dropdown.Item>
                            }
                            {hasPermission("member.change_member") &&
                                <Dropdown.Item
                                    onClick={() => navigate(`/members/member/${id}/edit`)}
                                >
                                    {t('edit', {ns: "common"})}
                                </Dropdown.Item>
                            }
                            <Dropdown.Divider as="div"/>
                            {hasPermission("member.delete_member") &&
                                <Dropdown.Item
                                    onClick={e => handleDelete(e)}
                                    className="text-danger"
                                >
                                    {t('delete', {ns: "common"})}
                                </Dropdown.Item>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const userList = members.map(address => ({
        id: address.id,
        user: address.full_name,
        activity_name: address.activity_name,
        activity: address.activity,
        role: address.role,
        role_name: address.role_name,
        joined_at: address.joined_at
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={userList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"member.add_member"}
                        creationPath={paths.membersAdd}
                        title={"member"}
                        deletePermission={"member.delete_member"}
                        exportPermission={"member.add_memberexportfile"}
                        data={userList}
                        exportLink={"/member/export/member/"}
                        deleteLink={"/member/delete/member/"}
                        setLoading={setLoading}
                        fields={["user__username", "role__name", "joined_at", "paid"]}
                        exportsPath={paths.membersExports}
                        importPermission={"member.view_memberimportfile"}
                        importPath={paths.membersImports}
                        ns={"members"}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchMembers}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default withPermission(withTranslation(["members", "common"])(Members), "member.view_member")