import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import paths from 'routes/paths';

const Member = ({ member }) => {
  return (
    <div className="bg-white dark__bg-1100 p-3 h-100">
      <Link to={paths.usersDetail.replace(":id", member?.user)}>
        <Image
          thumbnail
          fluid
          roundedCircle
          className="mb-3 shadow-sm"
          src={member?.avatar}
          width={100}
        />
      </Link>
      <h6 className="mb-1">
        <Link to={paths.usersDetail.replace(":id", member?.user)}>{member?.full_name}</Link>
      </h6>
      <p className="fs-11 mb-1">
        <Link className="text-700" to="#!">
          {member?.user_heading}
        </Link>
      </p>
    </div>
  );
};

Member.propTypes = {
  follower: PropTypes.shape({
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    institution: PropTypes.string
  })
};

export default Member;
