import React, {Fragment, useEffect, useState} from "react";
import {api} from "../../../../utils/api";
import paths from "../../../../routes/paths";
import {useAppContext} from "../../../../providers/AppProvider";
import {useNavigate} from "react-router-dom";
import Flex from "../../../../components/common/Flex";
import {Card, Form, Spinner, Table} from "react-bootstrap";
import {Field, FieldArray, Formik} from "formik";
import CSRFToken from "../../../../helpers/CSRFToken";
import SimpleBarReact from "simplebar-react";
import FormErrors from "../../../errors/FormErrors";
import IconButton from "../../../../components/common/IconButton";
import {withTranslation} from "react-i18next";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const QuestionSettings = ({t, i18n}) => {
	const [loading, setLoading] = useState(false);
	const [activity, setActivity] = useState(null)
	const [errors, setErrors] = useState({})
	const [formData, setFormData] = useState({
		questions: []
	})

	const {
		setConfig
	} = useAppContext()
	const navigate = useNavigate();

	const fetchQuestions = async () => {
		setLoading(true)
		await api.get("/activity/question/")
			.then(res => {
				setFormData({
					questions: res?.data?.results?.map((question) => {
						return ({
							name: question?.name,
							description: question?.description
						})
					}),
				})
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", false);
			});
		setLoading(false)
	}

	const fetchData = async () => {
		setLoading(true)
		await api.get(`/account/current_user/`).then(async (res) => {
			await api.get(`/activity/activity/${res.data.activity}/`).then(async (res) => {
				setActivity(res?.data)
			})
		})
		setLoading(false)
	}

	const handleSubmit = async (e, values) => {
		e.preventDefault()
		setLoading(true)
		await api.patch(`/activity/activity/${activity?.id}/`, values)
			.then(res => {
				toast.success("Questions updated successfully.", {theme: "colored"})
				setFormData({
					questions: res?.data?.activity_questions?.map((question) => {
						return ({
							name: question?.name,
							description: question?.description
						})
					}),
				})
			})
			.catch(err => {
				toast.error("An error has occurred.", {theme: "colored"})
				setErrors(err?.response?.data)
			})
		setLoading(false)
	}

	useEffect(() => {
		fetchData()
	}, []);

	useEffect(() => {
		fetchQuestions()
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex
			justifyContent="center"
			alignItems={'center'}
			className="p-2 mb-2"
		>
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Fragment>
			<Formik
				initialValues={formData}
				onSubmit={values => console.log(values)}
			>
				{({values, setFieldValue}) => (
					<>
						<Form>
							<CSRFToken/>
							<Card className={'pb-3 mt-3'}>
								<Card.Header>
									<h5 className="mb-0 text-muted">{t('fields.questions.title')}</h5>
									<div className="form-text mt-0">
										{t('fields.questions.desc')}
									</div>
								</Card.Header>
								<Card.Body className="bg-light pb-0">
									<SimpleBarReact>
										<Table
											responsive
											className="border-bottom"
											size={'sm'}
										>
											<thead>
											<tr>
												<td>{t('fields.questions.name')}</td>
												<td>{t('fields.questions.description')}</td>
												<td className={'p-0'}></td>
											</tr>
											</thead>
											<FieldArray
												name={'questions'}
												render={arrayHelpers => (
													<>
														<tbody>
														{values && values?.questions?.length > 0
															? values?.questions?.map(
																(field, index) => (
																	<tr key={index}>
																		<td>
																			<Field
																				name={`questions.${index}.name`}
																			>
																				{({field, form}) => (
																					<Form.Control
																						placeholder={t('fields.questions.name')}
																						name={`questions.${index}.name`}
																						errors={errors}
																						formGroupProps={{
																							className: 'mb-3'
																						}}
																						onChange={e => {
																							setFieldValue(
																								field.name,
																								e.target.value
																							);
																						}}
																						value={field.value}
																					/>
																				)}
																			</Field>
																			<FormErrors
																				errors={errors?.questions}
																				error={'name'}
																				index={index}
																			/>
																		</td>
																		<td>
																			<Field
																				name={`questions.${index}.description`}
																			>
																				{({field, form}) => (
																					<Form.Control
																						placeholder={
																							t('fields.questions.description')
																						}
																						name={`questions.${index}.description`}
																						errors={errors}
																						formGroupProps={{
																							className: 'mb-3'
																						}}
																						style={{
																							width: '100%'
																						}}
																						onChange={e => {
																							setFieldValue(
																								field.name,
																								e.target.value
																							);
																						}}
																						value={field.value}
																					/>
																				)}
																			</Field>
																			<FormErrors
																				errors={errors?.questions}
																				error={'description'}
																				index={index}
																			/>
																		</td>
																		<td className={'me-0 pe-0'}>
																			<Flex
																				justifyContent={'center'}
																				alignItems={'center'}
																				className={'mt-1'}
																			>
																				<IconButton
																					variant="falcon-default"
																					icon="trash"
																					size={'sm'}
																					iconClassName={'ms-1'}
																					onClick={() => {
																						arrayHelpers.remove(
																							index
																						);
																					}}
																				>
																					{' '}
																				</IconButton>
																			</Flex>
																		</td>
																	</tr>
																)
															)
															: ''}
														</tbody>
														<tfoot>
														<tr>
															<td>
																<IconButton
																	variant="falcon-default"
																	size="sm"
																	icon="plus"
																	transform="shrink-3"
																	onClick={() => {
																		arrayHelpers.insert(
																			values?.questions?.length,
																			''
																		);
																	}}
																>
                                              <span className="d-none d-sm-inline-block ms-1">
                                                {t('addItem', {ns: "common"})}
                                              </span>
																</IconButton>
															</td>
														</tr>
														</tfoot>
													</>
												)}
											/>
										</Table>
										<FormErrors errors={errors.questions}/>
									</SimpleBarReact>
								</Card.Body>
								<Card.Footer>
									<Flex justifyContent={'end'} wrap={'wrap'}>
										<IconButton
											icon={faSave}
											onClick={e => handleSubmit(e, values)}
										>
                      <span className="d-none d-sm-inline-block ms-1">
                        {t('save', {ns: "common"})}
                      </span>
										</IconButton>
									</Flex>
								</Card.Footer>
							</Card>
						</Form>
					</>
				)}
			</Formik>
		</Fragment>
	)
}

export default withTranslation(['activity', 'common'])(QuestionSettings)