import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'pages/authentication/LoginForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/14.jpg';
import Flex from 'components/common/Flex';
import useQuery from "../../../hooks/useQuery";
import paths from "../../../routes/paths";
import {withTranslation} from "react-i18next";

const Login = ({t, i18n}) => {
  let query = useQuery()
  return (
    <AuthSplitLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>{t("login.title")}</h3>
        <p className="mb-0 fs--1">
          <span className="fw-semi-bold">{t('login.newUser')} </span>
          <Link to={`${paths.splitRegister}?${query.toString()}`}>{t('login.createAccount')}</Link>
        </p>
      </Flex>
      <LoginForm layout="split" hasLabel t={t} i18n={i18n} />
    </AuthSplitLayout>
  );
};

export default withTranslation(["authentication", "common"])(Login);
