import paths from "../../routes/paths";

const autoCompleteInitialItem = [
  {
    id: 1,
    url: paths.events,
    breadCrumbTexts: ['Events'],
    catagories: 'suggestedLinks'
  },
  {
    id: 2,
    url: paths.members,
    breadCrumbTexts: ['Members'],
    catagories: 'suggestedLinks'
  },
  {
    id: 3,
    catagories: 'suggestedFilters',
    url: paths.request,
    key: 'requests',
    text: 'All join requests',
    type: 'warning'
  },
  {
    id: 4,
    catagories: 'suggestedFilters',
    url: paths.reports,
    key: 'reports',
    text: 'Latest events reported',
    type: 'success'
  },
  {
    id: 5,
    catagories: 'suggestedFilters',
    url: paths.sponsors,
    key: 'sponsors',
    text: 'Most popular sponsors',
    type: 'info'
  },
  {
    id: 6,
    url: paths.calendar,
    breadCrumbTexts: ['Calendar'],
    catagories: 'suggestedLinks'
  },
];

export default autoCompleteInitialItem;
