import React, {useState, useEffect, useRef} from 'react';
import {Card, Col, Form, Row, Table, Button, Spinner} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import GrossRevenueChart from './GrossRevenueChart';
import useQuery from "../../../../hooks/useQuery";
import {api} from "../../../../utils/api";
import {useAppContext} from "../../../../providers/AppProvider";
import SoftBadge from "../../../../components/common/SoftBadge";
import {grossRevenue} from "../../../../data/dashboard/saas";

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

const GrossRevenue = () => {
    const [selectedMonth, setSelectedMonth] = useState(months[0]);
    const [previousMonth, setPreviousMonth] = useState(months[11]);
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const {
        config: {currency}
    } = useAppContext()

    const chartRef = useRef(null);
    const query = useQuery()

    const getData = async () => {
        setLoading(true)
        query.set("month", months.indexOf(selectedMonth) + 1)
        await api.get(`/finance/revenuetotal/grossrevenue/?${query.toString()}`).then(res => setData(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [currency, selectedMonth]);

    useEffect(() => {
        if (selectedMonth) {
            const monthIndex = months.indexOf(selectedMonth) - 1;
            const prevMon = monthIndex >= 0 ? months[monthIndex] : months[11];

            setPreviousMonth(prevMon);
        }
    }, [selectedMonth]);

    const handleLegend = (event, name) => {
        chartRef.current.getEchartsInstance().dispatchAction({
            type: 'legendToggleSelect',
            name: name
        });
        event.target.closest('button').classList.toggle('opacity-50');
    };

    const grossTableRows = [
        {
            id: 1,
            title: data?.ticket?.title,
            revenue: `${data?.ticket?.value} ${currency}`,
            marketValue: {
                up: data?.ticket?.percent > 0,
                value: data?.ticket?.percent
            }
        },
        {
            id: 2,
            title: data?.membership?.title,
            revenue: `${data?.membership?.value} ${currency}`,
            marketValue: {
                up: data?.membership?.percent > 0,
                value: data?.membership?.percent
            }
        },
    ];

    return (
        <Card className="h-100">
            <Card.Header>
                <Row className="justify-content-between gx-0">
                    <Col xs="auto">
                        <h1 className="fs--1 text-900">Gross revenue</h1>
                        <Flex>
                            <h4 className="text-primary mb-0">{data?.total?.value} {currency}</h4>
                            <div className="ms-3">
                                <SoftBadge pill bg="primary">
                                    <FontAwesomeIcon
                                        icon={`caret-${data?.total?.percent > 0 ? 'up' : 'down'}`}/> {data?.total?.percent}%
                                </SoftBadge>
                            </div>
                        </Flex>
                    </Col>
                    <Col xs="auto">
                        <Form.Select
                            size="sm"
                            className="pe-4"
                            onChange={({target}) => setSelectedMonth(target.value)}
                        >
                            {months.map(mon => (
                                <option key={mon} value={mon}>
                                {mon}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="pt-0 pb-3 h-100">
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <div className="mx-ncard">
                        <Table borderless className="font-sans-serif fw-medium fs--1">
                            <tbody>
                            {grossTableRows.map(row => (
                                <tr key={row.id}>
                                    <td className="pb-2 pt-0">{row.title}</td>
                                    <td className="pb-2 pt-0 text-end" style={{width: '20%'}}>
                                        {row.revenue}
                                    </td>
                                    <td
                                        className="pb-2 pt-0 text-end text-700"
                                        style={{maxWidth: '20%'}}
                                    >
                                        {row.marketValue.value && (
                                            <FontAwesomeIcon
                                                icon={classNames({
                                                    'long-arrow-alt-up': row.marketValue.up,
                                                    'long-arrow-alt-down': !row.marketValue.up
                                                })}
                                                className={classNames('me-1', {
                                                    'text-success': row.marketValue.up,
                                                    'text-danger': !row.marketValue.up
                                                })}
                                            />
                                        )}

                                        {row.marketValue.value ? row.marketValue.value : '-'}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <GrossRevenueChart
                            ref={chartRef}
                            selectedMonth={selectedMonth}
                            previousMonth={previousMonth}
                            currencyData={currency}
                            data={data?.chart ? data?.chart : grossRevenue}
                            className="px-3 h-100"
                            style={{minHeight: '14.375rem'}}
                        />
                    </div>
                )}
            </Card.Body>
            <Card.Footer as={Flex} className="border-top py-2 flex-between-center">
                <Flex>
                    <Button
                        variant="text"
                        size="sm"
                        className="d-flex align-items-center p-0 shadow-none"
                        onClick={event => handleLegend(event, 'currentMonth')}
                    >
                        <FontAwesomeIcon
                            icon="circle"
                            className="text-primary fs-11 me-1"
                        />
                        <span className="text">{selectedMonth}</span>
                    </Button>
                    <Button
                        variant="text"
                        size="sm"
                        className="d-flex align-items-center p-0 shadow-none ms-2"
                        onClick={event => handleLegend(event, 'prevMonth')}
                    >
                        <FontAwesomeIcon icon="circle" className="text-300 fs-11 me-1"/>
                        <span className="text">{previousMonth}</span>
                    </Button>
                </Flex>
            </Card.Footer>
        </Card>
    );
};

export default GrossRevenue;
