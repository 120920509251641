import React, {useEffect, useRef, useState} from 'react';
import {Card, Col, Row, Button, Spinner} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReportForThisWeekChart from './ReportForThisWeekChart';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Flex from 'components/common/Flex';
import paths from "../../../../routes/paths";
import {api} from "../../../../utils/api";
import {useAppContext} from "../../../../providers/AppProvider";

const ReportForThisWeek = () => {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)

	const {
		setConfig
	} = useAppContext()

	const getWeeklyReport = async () => {
		setLoading(true)
		await api.get("/dashboard/weeklyreport/").then(res => setData(res?.data))
			.catch(err => {
				if (err?.response.status === 403) setConfig("isAuthenticated", false)
			})
		setLoading(false)
	}

	useEffect(() => {
		getWeeklyReport()
		// eslint-disable-next-line
	}, []);

	const chartRef = useRef(null);
	const handleLegend = (event, name) => {
		chartRef.current.getEchartsInstance().dispatchAction({
			type: 'legendToggleSelect',
			name: name
		});
		event.target.closest('button').classList.toggle('opacity-50');
	};
	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Card>
			<Card.Header className="pb-0">
				<Row className="flex-between-center gap-2">
					<Col xs="auto">
						<h6 className="mb-0">Events for this week</h6>
					</Col>
					<Col xs="auto" as={Flex}>
						<Button
							size="sm"
							className="d-flex align-items-center p-0  me-3 shadow-none"
							variant="default"
							onClick={event => handleLegend(event, 'This Week')}
						>
							<FontAwesomeIcon
								icon="circle"
								className="text-primary fs--2 me-1"
							/>
							This Week
						</Button>
						<Button
							size="sm"
							className="d-flex align-items-center p-0 shadow-none"
							variant="default"
							onClick={event => handleLegend(event, 'Last Week')}
						>
							<FontAwesomeIcon icon="circle" className="text-300 fs--2 me-1"/>
							Last Week
						</Button>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body className="py-0">
				<ReportForThisWeekChart data={data} ref={chartRef}/>
			</Card.Body>
			<FalconCardFooterLink title="See all events" to={paths.events} size="sm"/>
		</Card>
	);
};

export default ReportForThisWeek;
