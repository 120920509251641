import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import CSRFToken from "../../../../helpers/CSRFToken";
import useQuery from "../../../../hooks/useQuery";
import {api} from "../../../../utils/api";
import Select from "react-select";
import {getUsers} from "../../../account/actions/Users";
import DatePicker from 'react-datepicker';
import CustomDateInput from "../../../../components/common/CustomDateInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import FormError from "../../../errors/FormError";

const OfficerModal = ({open, setOpen, id, name, fetch}) => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [errors, setErrors] = useState({})
    const [officerFormData, setOfficerFormData] = useState({
        user: "",
        position: id,
        term_start_date: "",
        term_end_date: ""
    })

    const query = useQuery();

    const fetchUsers = async () => {
        setLoading(true)
        query.set("page_size", "50")
        query.set("page", page.toString())
        await api.get(`/account/user/?${query.toString()}`).then((res) => {
            setUsers([...users, ...res?.data?.results]);
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchUsers()
        // eslint-disable-next-line
    }, [page]);

    let userOptions = users?.map(user => ({label: user.full_name, value: user.id}));

    userOptions = [
        {label: '--------------------------', value: ""},
        ...userOptions,
    ]

    const handleAddOfficer = async e => {
        setLoading(true)
        await api.post("/officer/officer/", officerFormData).then((res) => {
            toast.success("Officer successfully Added.", {theme: "colored"})
            fetch()
            setOpen(!open)
        })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"});
                setErrors(err?.response?.data)
            })
        setLoading(false)
    }

    return (
        <Modal show={open === id} onHide={() => setOpen(!open)} centered size={"lg"}
               aria-labelledby="form-dialog-title">
            <Modal.Header closeButton>
                <h5 className="mb-1">{name}</h5>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <Form>
                        <CSRFToken/>
                        <Form.Group>
                            <Form.Label>User</Form.Label>
                            <Select
                                options={userOptions}
                                placeholder={"Select User"}
                                value={selectedUser}
                                onChange={newValue => {
                                    setSelectedUser(newValue);
                                    setOfficerFormData({...officerFormData, user: newValue.value})
                                }}
                                onMenuScrollToBottom={() => {
                                    setPage(page => page + 1);
                                }}
                                onInputChange={newValue => {
                                    query.set("search", newValue)
                                    getUsers(query).then(res => setUsers(res?.results))
                                }}
                            />
                            <FormError error={errors?.user} />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Term Start Date</Form.Label>
                                    <Row>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={newDate => {
                                                setStartDate(newDate)
                                                setOfficerFormData({
                                                    ...officerFormData,
                                                    term_start_date: new Date(newDate).toISOString()
                                                })
                                            }}
                                            showTimeSelect
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            fixedHeight
                                            className='form-control'
                                            customInput={
                                                <CustomDateInput
                                                    formControlProps={{
                                                        placeholder: 'MM/dd/yyyy h:mm aa',
                                                    }}
                                                />
                                            }
                                        />
                                    </Row>
                                    <FormError error={errors?.term_start_date} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Term End Date</Form.Label>
                                    <Row>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={newDate => {
                                                setEndDate(newDate)
                                                setOfficerFormData({
                                                    ...officerFormData,
                                                    term_end_date: new Date(newDate).toISOString()
                                                })
                                            }}
                                            showTimeSelect
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            fixedHeight
                                            className='form-control'
                                            customInput={
                                                <CustomDateInput
                                                    formControlProps={{
                                                        placeholder: 'MM/dd/yyyy h:mm aa',
                                                    }}
                                                />
                                            }
                                        />
                                    </Row>
                                    <FormError error={errors?.term_end_date} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpen(!open)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAddOfficer}>
                    Save <FontAwesomeIcon icon={faSave} className="ms-2"/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OfficerModal