export const version = '1.4.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const themeVariants = ['light', 'dark', 'auto'];
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  isAuthenticated: false,
  isActivity: false,
  isCompany: false,
  isSuperUser: false,
  isStaff: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: 'USD',
  point: 0,
  group: [],
  permissions: [],
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  lang: 'fr',
  token: '',
  avatar: '',
  activities_joined: [],
  activity: '',
  company: '',
  affiliate: '',
  settings: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
