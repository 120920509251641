import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Dropdown, Offcanvas, Row, Spinner} from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AllAttendanceHeader from './AllAttendanceHeader';
import AttendanceFilteringForm from './AttendanceFilteringForm';
import {useBreakpoints} from 'hooks/useBreakpoints';
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from 'lodash';
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import Flex from "../../../components/common/Flex";
import {formatDateTime} from "../../../helpers/utils";
import Avatar from "../../../components/common/Avatar";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {useTranslation} from "react-i18next";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

const TableView = ({event}) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();
    const {hasPermission} = usePermissions()
    const {t} = useTranslation(["events", "common"])

    const navigate = useNavigate();

    const {breakpoints} = useBreakpoints();

    const fetchAttendances = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);
        query.set("event", query.get("event"))

        await api.get(`/attendance/attendance/?${q.toString()}`).then(res => {
            setData(res?.data?.results);
            setCount(res?.data?.count);
        })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchAttendances, 600), []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        fetchAttendances(query);
        setLength(data.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'user',
            Header: 'User',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'user' && sortingDirection,
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const {name, avatar, user} = rowData.row.original;
                return (
                    <>
                        <Link to={`/accounts/user/${user}/detail`}>
                            <Flex alignItems="center">
                                {avatar ? (
                                    <Avatar
                                        src={avatar}
                                        rounded={'circle'}
                                        size="4xl"
                                        className="me-2"
                                    />
                                ) : (
                                    <Avatar
                                        size="4xl"
                                        name={name}
                                        rounded={'circle'}
                                        className="me-2"
                                    />
                                )}
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: '200px',
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {name}
                                    </h6>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            canSort: true,
            sortingDirection: sortingField === 'status' && sortingDirection,
            isSorted: false,
            cellProps: {
                className: 'text-center py-2'
            },
            headerProps: {className: 'text-center pe-3'},
            Cell: rowData => {
                const {status, status_display} = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: status === "A",
                            warning: status === "R",
                            danger: status === "N",
                            secondary: status === "P",
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {status_display}
                        </p>
                        <FontAwesomeIcon
                            icon={classNames({
                                check: status === "A",
                                ban: status === "N",
                                clock: status === "R",
                                paperclip: status === "P",
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'check_in_time',
            Header: "Check in",
            canSort: true,
            sortingDirection: sortingField === 'check_in_time' && sortingDirection,
            isSorted: false,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => `${formatDateTime(rowData.row.original.check_in_time)}`
        },
        {
            accessor: 'check_out_time',
            Header: "Check out",
            canSort: true,
            sortingDirection: sortingField === 'check_out_time' && sortingDirection,
            isSorted: false,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => `${formatDateTime(rowData.row.original.check_out_time)}`
        },
        {
            accessor: 'resume',
            Header: "Resume",
            canSort: true,
            sortingDirection: sortingField === 'resume' && sortingDirection,
            isSorted: false,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => {
                const {resume} = rowData.row.original
                return resume && (
                    <Button variant={"outline-danger"} href={resume}>
                        <FontAwesomeIcon icon={faFilePdf}/>
                    </Button>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: "Creation Date",
            canSort: true,
            sortingDirection: sortingField === 'created_at' && sortingDirection,
            isSorted: false,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => `${formatDateTime(rowData.row.original.created_at)}`
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: "common"}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
                        cancelButtonText: `${t('cancel', {ns: "common"})}`
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/attendance/attendance/${id}/`).then(() => {
                            toast.success(`${t('title')} ${t('deleteSuccess', {ns: "common"})}.`, {
                                theme: 'colored'
                            });
                            fetchAttendances(query)
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("attendance.view_attendance") &&
                                <Dropdown.Item
                                    onClick={() => navigate(paths.eventAttendanceDetail.replace(":id", id))}
                                >
                                    {t('detail', {ns: "common"})}
                                </Dropdown.Item>
                            }
                            <Dropdown.Divider as="div"/>
                            {hasPermission("attendance.delete_attendance") &&
                                <Dropdown.Item
                                    onClick={e => handleDelete(e)}
                                    className="text-danger"
                                >
                                    {t('delete', {ns: "common"})}
                                </Dropdown.Item>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const attendanceList = data?.map(attendance => ({
        id: attendance?.id,
        user: attendance?.user,
        status: attendance?.status,
        status_display: attendance?.status_display,
        resume: attendance?.resume,
        check_in_time: attendance?.check_in_time,
        check_out_time: attendance?.check_out_time,
        created_at: attendance?.created_at,
        name: attendance?.user_data?.full_name,
        avatar: attendance?.user_data?.url_path,
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <Row className="gx-3">
            <Col xxl={10} xl={9}>
                <AdvanceTableWrapper
                    columns={columns}
                    data={attendanceList}
                    selection
                    pagination
                    perPage={20}
                >
                    <Card>
                        <Card.Header className="border-bottom border-200 px-0">
                            <AllAttendanceHeader table fetchData={delayedLoadItems} layout="table-view"
                                                 handleShow={handleShow}/>
                        </Card.Header>
                        <Card.Body className="p-0">
                            {loading ? (
                                <Flex justifyContent="center" className="p-2 mb-2">
                                    <Spinner animation={'border'} variant={'primary'}/>
                                </Flex>
                            ) : (
                                <AdvanceTable
                                    handleSort={handleSort}
                                    table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs--1 mb-0 overflow-hidden'
                                    }}
                                    headers={columns}
                                />
                            )}
                        </Card.Body>
                        <Card.Footer>
                            <AdvanceTablePagination
                                query={query}
                                fetch={fetchAttendances}
                                count={count}
                                itemsPerPage={20}
                                length={length}
                            />
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            </Col>
            <Col xxl={2} xl={3}>
                {breakpoints.down('xl') ? (
                    <Offcanvas
                        show={show}
                        onHide={handleClose}
                        placement="end"
                        className="dark__bg-card-dark"
                    >
                        <Offcanvas.Header closeButton className="bg-body-tertiary">
                            <h6 className="fs-9 mb-0 fw-semibold">Filter</h6>
                        </Offcanvas.Header>
                        <AttendanceFilteringForm fetch={fetchAttendances} event={event}/>
                    </Offcanvas>
                ) : (
                    <AttendanceFilteringForm fetch={fetchAttendances} event={event}/>
                )}
            </Col>
        </Row>
    );
};

export default TableView;
