import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, Row, Col} from 'react-bootstrap';
import {api} from '../../utils/api';
import FormError from '../../pages/errors/FormError';
import SocialAuthButtons from './SocialAuthButtons';
import Divider from 'components/common/Divider';
import CSRFToken from '../../helpers/CSRFToken';
import useQuery from "../../hooks/useQuery";
import paths from "../../routes/paths";

const RegistrationForm = ({hasLabel, layout, t, i18n}) => {
	const [errors, setErrors] = useState({});
	// State
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		password1: '',
		password2: '',
		isAccepted: false
	});

	let query = useQuery()
	const navigate = useNavigate()

	useEffect(() => {
		if (!query.has("next")) query.set("next", "/")
	}, [query]);

	// Handler
	const handleSubmit = async e => {
		e.preventDefault();
		await api
			.post('/account/auth/register/', formData)
			.then(res => {
				toast.success(`${res?.data?.detail}`, {
					theme: 'colored'
				});
				navigate(`${paths.splitVerifyMail}?email=${formData.email}`)
			})
			.catch(err => {
				setErrors(err?.response?.data);
				toast.error(`${t('error', {ns: "common"})} ${err.toString()}`, {
					theme: 'colored'
				});
			});
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	return (
		<Form onSubmit={handleSubmit}>
			<CSRFToken/>
			<Form.Group className="mb-3">
				{hasLabel && <Form.Label>{t('fields.username')}</Form.Label>}
				<Form.Control
					placeholder={!hasLabel ? t('fields.username') : ''}
					value={formData.username}
					name="username"
					onChange={handleFieldChange}
					type="text"
				/>
				<FormError error={errors.username}/>
			</Form.Group>

			<Form.Group className="mb-3">
				{hasLabel && <Form.Label>{t('fields.email')}</Form.Label>}
				<Form.Control
					placeholder={!hasLabel ? t('fields.email') : ''}
					value={formData.email}
					name="email"
					onChange={handleFieldChange}
					type="email"
				/>
				<FormError error={errors.email}/>
			</Form.Group>

			<Row className="g-2 mb-3">
				<Form.Group as={Col} sm={6}>
					{hasLabel && <Form.Label>{t('fields.password')}</Form.Label>}
					<Form.Control
						placeholder={!hasLabel ? t('fields.password') : ''}
						value={formData.password1}
						name="password1"
						onChange={handleFieldChange}
						type="password"
					/>
					<FormError error={errors.password1}/>
				</Form.Group>
				<Form.Group as={Col} sm={6}>
					{hasLabel && <Form.Label>{t('fields.passwordConfirm')}</Form.Label>}
					<Form.Control
						placeholder={!hasLabel ? t('fields.passwordConfirm') : ''}
						value={formData.password2}
						name="password2"
						onChange={handleFieldChange}
						type="password"
					/>
					<FormError error={errors.password2}/>
				</Form.Group>
			</Row>

			<Form.Group className="mb-3">
				<Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
					<Form.Check.Input
						type="checkbox"
						name="isAccepted"
						checked={formData.isAccepted}
						onChange={e =>
							setFormData({
								...formData,
								isAccepted: e.target.checked
							})
						}
					/>
					<Form.Check.Label className="form-label">
						{t('accept')} <Link to={paths.termsConditions}>{t('termsConditions', {ns: 'common'})}</Link> {t('and')}{' '}
						<Link to={paths.privacyPolicy}>{t('privacyPolicy', {ns: 'common'})}</Link>
					</Form.Check.Label>
				</Form.Check>
				<FormError error={errors?.isAccepted}/>
			</Form.Group>

			<Form.Group className="mb-4">
				<Button className="w-100" type="submit">
					{t('register.title')}
				</Button>
				<FormError error={errors?.non_field_errors} />
			</Form.Group>
			<Divider>{t('or')} {t('register.title')} {t('with')}</Divider>

			<SocialAuthButtons type={'login'}/>
		</Form>
	);
};

RegistrationForm.propTypes = {
	hasLabel: PropTypes.bool
};

export default RegistrationForm;
