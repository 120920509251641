import React from 'react';
import VerifyMailContent from "../VerifyMailContent";

const VerifyMail = () => (
  <div className="text-center">
    <VerifyMailContent email="support@nexameet.com" />
  </div>
);

export default VerifyMail;
