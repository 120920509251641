import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import invite from "../../../../assets/img/icons/spot-illustrations/41.png";
import IconButton from "../../../../components/common/IconButton";
import {Link} from "react-router-dom";
import {api} from "../../../../utils/api";
import {useAppContext} from "../../../../providers/AppProvider";
import useQuery from "../../../../hooks/useQuery";
import Flex from "../../../../components/common/Flex";
import {v4 as uuid} from "uuid";
import {toast} from "react-toastify";
import CSRFToken from "../../../../helpers/CSRFToken";
import paths from "../../../../routes/paths";

const AffiliateProgramContent = () => {
    const [affiliate, setAffiliate] = useState({})
    const [loading, setLoading] = useState(false);
    const [copyLinkText, setCopyLinkText] = useState(`${window.location.protocol}//${window.location.host}${paths.activityCreate}?ref=${affiliate?.affiliate_id}`);
    const copyRef = useRef(null);

    const {
        config: {activity},
        setConfig
    } = useAppContext()

    const query = useQuery()

    const getAffiliate = async () => {
        setLoading(true)
        query.set("activity", activity);
        await api.get(`/affiliate/affiliate/?${query.toString()}`).then(res => {
            setAffiliate(res?.data?.results[0])
            setCopyLinkText(`${window.location.protocol}//${window.location.host}${paths.activityCreate}?ref=${res?.data?.results[0]?.affiliate_id}`)
        })
        setLoading(false)
    }

    const handleCopy = () => {
        if (copyRef.current) {
            navigator.clipboard.writeText(copyRef.current.value).then(() => {
                toast.success('Link copied to clipboard!');
            }).catch(err => {
                console.error('Could not copy text: ', err);
            });
        }
    };

    useEffect(() => {
        getAffiliate()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <>
            <Card>
                <Card.Body className="text-center py-5">
                    <Row className="justify-content-center">
                        <Col xs={7} md={5}>
                            <img className="img-fluid" src={invite} alt="" width="58%"/>
                        </Col>
                    </Row>
                    {(affiliate && affiliate?.active) ? (
                        <>
                            <h3 className="mt-3 fw-normal fs-7 mt-md-4 fs-md-6">
                                Invite a friend, you will get a discount
                            </h3>
                            <p className="lead mb-5">
                                Invite your friends and start working together in seconds.{' '}
                                <br className="d-none d-md-block"/> Everyone you invite will
                                receive a welcome email.
                            </p>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <Form as={Row} className="g-2">
                                        <Col sm>
                                            <Form.Control
                                                type="text"
                                                className="invitation-link"
                                                defaultValue={copyLinkText}
                                                ref={copyRef}
                                            />
                                        </Col>
                                        <Col sm="auto">
                                            <IconButton
                                                className="d-block w-100 mb-2 mb-xl-0"
                                                iconClassName="me-2"
                                                variant="falcon-default"
                                                icon="link"
                                                onClick={handleCopy}
                                            >
                                                Copy Link
                                            </IconButton>
                                        </Col>
                                    </Form>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <h3 className="mt-3 fw-normal fs-7 mt-md-4 fs-md-6">
                                Join the referral program, you will get a discount
                            </h3>
                            <p className="lead mb-5">
                                Join the program and start working with friends together in seconds.{' '}
                            </p>
                            <Row className="justify-content-center">
                                <Col md={12}>
                                    <Form as={Row} className="g-2">
                                        <CSRFToken/>
                                        <Button variant="primary" className="d-block w-100" onClick={async (e) => {
                                            setLoading(true)
                                            await api.post("/affiliate/affiliate/", {
                                                activity: activity,
                                                affiliate_id: uuid()
                                            })
                                                .then(res => {
                                                    setAffiliate(res?.data)
                                                    setConfig("affiliate", res?.data?.id)
                                                    toast.success("You have successfully joined the program")
                                                })
                                            setLoading(false)
                                        }}>
                                            Join the program
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </>
                    )}
                </Card.Body>
                <Card.Footer className="bg-body-tertiary text-center pt-4">
                    {(affiliate && affiliate?.active) &&
                        <Row className="justify-content-center">
                            <Col xs={11} sm={10}>

                                <p className="mb-2 fs-10">
                                    Once you’ve invited friends, you can{' '}
                                    <Link to="#!">view the status of your referrals</Link>{' '}
                                    <br className="d-none d-lg-block d-xxl-none"/> or visit our{' '}
                                    <Link to="#!">Help Center</Link> if you have any questions.{' '}
                                </p>
                            </Col>
                        </Row>
                    }
                </Card.Footer>
            </Card>
        </>
    );
}

export default AffiliateProgramContent