import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Background from 'components/common/Background';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, CloseButton } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import {formatDateTime} from "../../../helpers/utils";

const AttachmentItem = ({ setImgIndex, attachment, isLast, index }) => {
  const [nestedModal, setNestedModal] = useState(false);

  return (
    <>
      <Flex alignItems="center" className={classNames({ 'mb-3': !isLast })}>
        <div className="bg-attachment me-3">
          {attachment.file ? (
            <>
              {!attachment?.type?.includes('video') ? (
                <div
                  className="cursor-pointer"
                  onClick={() => setImgIndex(index)}
                >
                  <Background image={attachment?.file} rounded />
                </div>
              ) : (
                <>
                  <Link to="#!" onClick={() => setNestedModal(true)}>
                    <Background image={attachment?.file} className="rounded" />
                    <div className="icon-play">
                      <FontAwesomeIcon icon="play" />
                    </div>
                  </Link>
                </>
              )}
            </>
          ) : (
            <span className="text-uppercase fw-bold">{attachment?.type}</span>
          )}
        </div>

        <div className="flex-1 fs-11">
          <h6 className="mb-1">
            {attachment?.file ? (
              <>
                {!attachment?.type?.includes('video') ? (
                  <Link
                    onClick={() => setImgIndex(index)}
                    to="#!"
                    className="text-decoration-none"
                  >
                    {attachment?.name}
                  </Link>
                ) : (
                  <>
                    <Link to={attachment?.file} onClick={() => setNestedModal(true)}>
                      {attachment?.name}
                    </Link>
                  </>
                )}
              </>
            ) : (
              <Link to={attachment?.file} className="text-decoration-none">
                {attachment?.name}
              </Link>
            )}
          </h6>
          <p className="mb-0">Uploaded at {formatDateTime(attachment?.created_at)}</p>
        </div>
      </Flex>

      <Modal
        show={nestedModal}
        size="xl"
        onHide={() => setNestedModal(false)}
        className="video-popup"
        contentClassName="bg-transparent border-0"
        backdropClassName="video-popup-backdrop"
        centered
      >
        <CloseButton
          variant="white"
          onClick={() => setNestedModal(false)}
          className="video-popup-close-btn"
        />
        <Modal.Body className="p-0 rounded overflow-hidden">
          <video
            poster={attachment.file}
            className="d-block"
            width="100%"
            height="100%"
            controls
            autoPlay
          >
            <source src={attachment.file} type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ModalAttachmentContent = ({taskAttachments, task, getTask, getKanbanStatus}) => {

  const images = taskAttachments?.map(item => item.file);

  return (
    <>
      <FalconLightBoxGallery images={images}>
        {setImgIndex => (
          <>
            {taskAttachments?.map((attachment, index) => (
              <AttachmentItem
                setImgIndex={setImgIndex}
                key={attachment.id}
                attachment={attachment}
                index={index}
                isLast={taskAttachments?.length - 1 === index}
              />
            ))}
          </>
        )}
      </FalconLightBoxGallery>
    </>
  );
};

AttachmentItem.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string
  }),
  isLast: PropTypes.bool,
  setImgIndex: PropTypes.func,
  index: PropTypes.number
};

export default ModalAttachmentContent;
