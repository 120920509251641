import React, {useState} from 'react';
import {Card, Spinner} from 'react-bootstrap';
import {formatDateTime} from "../../../helpers/utils";
import CSRFToken from "../../../helpers/CSRFToken";
import Flex from "../../../components/common/Flex";
import ModalCalendar from "./ModalCalendar";
import ModalMap from "./ModalMap";

const EventDetailAside = ({event, location}) => {
	const [loading, ] = useState(false)
	const [modalCalendar, setModalCalendar] = useState(false)
	const [modalMap, setModalMap] = useState(false)

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={"border"} variant={"primary"}/>
		</Flex>
	) : (
		<div className="sticky-sidebar">
			<CSRFToken/>
			<Card className="mb-3 fs--1">
				<Card.Body>
					<h6>Date And Time</h6>
					<p className="mb-1">
						{formatDateTime(event?.start_date)} – <br/>
						{formatDateTime(event?.end_date)}
					</p>
					<h6 className="mt-4">Location</h6>
					<div className="mb-1">
						{location?.formatted_address}
						<br/>
						{event?.delegation_name}
						<br/>
						{event?.city_name}
						<br/>
						{event?.country_name}
					</div>
				</Card.Body>
			</Card>
			<ModalMap setOpen={setModalMap} open={modalMap} event={event} />
			<ModalCalendar open={modalCalendar} setOpen={setModalCalendar} event={event} />
		</div>
	);
};

export default EventDetailAside;
