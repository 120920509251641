import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";
import CSRFToken from "../../../helpers/CSRFToken";

const KanbanColumnHeader = ({ id, title, itemCount, getKanbanStatus }) => {

  const query = useQuery()

  const handleRemoveColumn = async () => {
    await api.delete(`/task/status/${id}/`).then(() => {
      getKanbanStatus(query)
    })
  };

  return (
    <div className="kanban-column-header">
      <h5 className="fs-9 mb-0">
        {title} <span className="text-500">({itemCount})</span>
      </h5>
      <Dropdown align="end" className="font-sans-serif btn-reveal-trigger">
        <Dropdown.Toggle variant="reveal" size="sm" className="py-0 px-2">
          <FontAwesomeIcon icon="ellipsis-h" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="py-0">
          <CSRFToken />
          <Dropdown.Item
            onClick={handleRemoveColumn}
            href="#!"
            className="text-danger"
          >
            Remove
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

KanbanColumnHeader.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default KanbanColumnHeader;
