import React, {useEffect, useState} from "react";
import {DragDropContext, Draggable} from "react-beautiful-dnd";
import {Button, Card, Col, Form, Row, Dropdown} from "react-bootstrap";
import StrictModeDroppable from "../../components/app/kanban/StrictModeDroppable";
import {FieldArray} from "formik";
import Flex from "../../components/common/Flex";
import CardDropdown from "../../components/common/CardDropdown";
import SoftBadge from "../../components/common/SoftBadge";
import IconButton from "../../components/common/IconButton";

const TicketContentsFields = ({values, setFieldValue, errors, t}) => {
	const [draggableData, setDraggableData] = useState([]);
	const [contentForm, setContentForm] = useState({
		key: 0,
		field: "",
		align: "",
		text: "",
		type: ""
	});
	const [contentFormOpen, setContentFormOpen] = useState(false);

	const getColumn = id => {
		return draggableData.find(item => item.id === id);
	};

	const reorder = (array, fromIndex, toIndex) => {
		const newArr = [...array];

		const chosenItem = newArr.splice(fromIndex, 1)[0];
		newArr.splice(toIndex, 0, chosenItem);

		return newArr;
	};

	const move = (source, destination) => {
		const sourceItemsClone = [...getColumn(source.droppableId).contents];
		const destItemsClone = [...getColumn(destination.droppableId).contents];

		const [removedItem] = sourceItemsClone.splice(source.index, 1);
		destItemsClone.splice(destination.index, 0, removedItem);

		return {
			updatedDestItems: destItemsClone,
			updatedSourceItems: sourceItemsClone
		};
	};

	const onDragEnd = result => {
		const {source, destination} = result;

		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const items = getColumn(source.droppableId).contents;
			const reorderedItems = reorder(items, source.index, destination.index);
			const result = draggableData.map(item =>
				item.id === source.droppableId
					? {...item, contents: reorderedItems}
					: item
			);

			setDraggableData(result);
			setFieldValue('ticket.elements', result[0]?.contents)
		} else {
			const sourceColumn = getColumn(source.droppableId);
			const destColumn = getColumn(destination.droppableId);
			const movedItems = move(source, destination);
			const result = draggableData.map(item => {
				return {
					...item,
					contents:
						(item.id === sourceColumn.id && movedItems.updatedSourceItems) ||
						(item.id === destColumn.id && movedItems.updatedDestItems)
				};
			});

			setDraggableData(result);
			setFieldValue('ticket.elements', result[0]?.contents)
		}
	};

	const handleFieldContentChange = (e) => {
		setContentForm({
			...contentForm,
			[e.target.name]: e.target.value,
		});
	};

	const droppableId = `droppable-${0}`; // Unique droppableId for each index

	useEffect(() => {
		setDraggableData([{
			id: droppableId,
			contents: values?.ticket?.elements
		}])
	}, [droppableId, values?.ticket?.elements]);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<StrictModeDroppable droppableId={droppableId} type="DRAG">
				{provided => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
						className="kanban-items-container rounded-2 py-3 mb-3"
					>
						<FieldArray name={`ticket.elements`}>
							{({insert, remove, push}) => (
								<>
									{draggableData[0]?.contents?.length > 0 && draggableData[0]?.contents?.map((item, i) => (
										<>
											<Draggable
												key={item.key}
												draggableId={`drag${item.key}`}
												index={i}
											>
												{provided => (
													<div>
														<Card
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className="mb-3 kanban-item shadow-sm dark__bg-1100"
														>
															<Card.Body style={{backgroundColor: item?.color}}>
																<Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
																	<p className="fs-10 fw-medium font-sans-serif mb-0">
																		{item?.text} {item?.data}
																	</p>
																	<CardDropdown>
																		<div className="py-2">
																			<Dropdown.Item
																				onClick={(e) => remove(i)}
																				className="text-danger"
																			>
																				{t('delete', {ns: "common"})}
																			</Dropdown.Item>
																		</div>
																	</CardDropdown>
																</Flex>
																<SoftBadge bg={"primary"} pill>{item?.field}</SoftBadge>
															</Card.Body>
														</Card>
													</div>
												)}
											</Draggable>
											{provided.placeholder}
										</>
									))}
									{contentFormOpen &&
										<>
											<Form.Select
												className="mb-2"
												onChange={handleFieldContentChange}
												name={"field"}
											>
												<option value={""}>{t('select', {ns: "common"})} {t('fields.ticketPreview.lines.field.title')}</option>
												<option value={"feed"}>{t('fields.ticketPreview.lines.field.feed')}</option>
												<option value={"text"}>{t('fields.ticketPreview.lines.field.text')}</option>
												<option value={"component"}>{t('fields.ticketPreview.lines.field.component')}</option>
												<option value={"barcode"}>{t('fields.ticketPreview.lines.field.barcode')}</option>
											</Form.Select>
											{contentForm.field === "component" &&
												<Flex justifyContent={"between"}>
													<Form.Select
														className="mb-2 me-1"
														onChange={handleFieldContentChange}
														name={"type"}
													>
														<option value={""}>{t('fields.ticketPreview.lines.field.component')}</option>
														<option value={"row"}>{t('fields.ticketPreview.lines.field.row')}</option>
														<option value={"column"}>{t('fields.ticketPreview.lines.field.column')}</option>
													</Form.Select>
													<Form.Control
														className="mb-2"
														onChange={handleFieldContentChange}
														name={"color"}
														type={"color"}
													/>
												</Flex>
											}
											{(contentForm.field === "text" || contentForm.field === "component") &&
												<>
													<Form.Select
														className="mb-2"
														onChange={handleFieldContentChange}
														name={"align"}
													>
														<option value={""}>{t('fields.ticketPreview.lines.field.align')}</option>
														<option value={"left"}>{t('fields.ticketPreview.lines.field.left')}</option>
														<option value={"center"}>{t('fields.ticketPreview.lines.field.center')}</option>
														<option value={"right"}>{t('fields.ticketPreview.lines.field.right')}</option>
													</Form.Select>
													<Form.Control
														as={"textarea"}
														className="mb-2"
														name={"text"}
														placeholder={t('fields.ticketPreview.lines.field.text')}
														onChange={handleFieldContentChange}
													/>
												</>
											}
											{contentForm.field !== "feed" &&
												<Form.Select
													className="mb-2"
													onChange={handleFieldContentChange}
													name={"data"}
												>
													<option value={""}>Data</option>
													<option value={"activity_logo"}>Logo</option>
													<option value={"activity_phone"}>Phone</option>
													<option value={"event_title"}>Event Title</option>
													<option value={"event_id"}>Event Number</option>
													<option value={"event_subtitle"}>Event Subtitle</option>
													<option value={"event_start_date"}>Start Date</option>
													<option value={"event_end_date"}>End Date</option>
													<option value={"event_full_address"}>Place</option>
													<option value={"event_type_display"}>Event Type</option>
													<option value={"full_name"}>Participant Name</option>
													<option value={"registered_at"}>Registration Date</option>
												</Form.Select>
											}
											{contentForm.field === "barcode" &&
												<Form.Select
													className="mb-2"
													onChange={handleFieldContentChange}
													name={"type"}
												>
													<option value={""}>Barcode Type</option>
													<option value={"qr_code"}>QR Code</option>
												</Form.Select>
											}
											{contentForm?.field === "feed" &&
												<Form.Control
													className={"mb-2"}
													onChange={handleFieldContentChange}
													type={"number"}
													name={"size"}
													placeholder={t('fields.ticketPreview.lines.field.size')}
												/>
											}
											<Row className="gx-2">
												<Col>
													<Button
														variant="primary"
														size="sm"
														className="d-block w-100"
														onClick={() => {
															if (contentForm.field)
																insert(values?.ticket?.elements?.length, contentForm)
															setContentForm({
																key: values?.ticket?.elements?.length + 1,
																field: "",
																align: "",
																text: "",
																type: ""
															})
															setContentFormOpen(false)
														}}
													>
														{t('add', {ns: "common"})}
													</Button>
												</Col>
												<Col>
													<Button
														variant="outline-secondary"
														size="sm"
														className="d-block w-100 border-400"
														type="button"
														onClick={() => setContentFormOpen(false)}
													>
														{t('cancel', {ns: "common"})}
													</Button>
												</Col>
											</Row>
										</>
									}
									{!contentFormOpen && (
										<IconButton
											variant="secondary"
											className="d-block w-100 border-400 bg-400"
											icon="plus"
											iconClassName="me-1"
											onClick={() => setContentFormOpen(true)}
										>
											{t('fields.ticketPreview.lines.add')}
										</IconButton>
									)}
								</>
							)}
						</FieldArray>
					</div>
				)}
			</StrictModeDroppable>
		</DragDropContext>
	);
};

export default TicketContentsFields;
