import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import InputField from '../InputField';
import { api } from '../../../utils/api';
import useQuery from '../../../hooks/useQuery';
import Select from 'react-select';
import FormError from '../../errors/FormError';
import CSRFToken from '../../../helpers/CSRFToken';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import Flex from '../../../components/common/Flex';

const EducationForm = ({ getUser }) => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [formData, setFormData] = useState({
    school: '',
    degree: '',
    field: '',
    date_from: '',
    date_to: ''
  });

  let query = useQuery();

  const getSchools = async () => {
    query.set('page_size', '50');
    query.set('page', page.toString());
    await api
      .get(`/school/?${query.toString()}`)
      .then(res => setSchools([...schools, ...res?.data?.results]));
  };

  useEffect(() => {
    getSchools();
    // eslint-disable-next-line
    }, [page]);

  let schoolOptions = schools?.map(school => ({
    label: school?.name,
    value: school?.id
  }));

  schoolOptions = [
    { label: '--------------------------', value: '' },
    ...schoolOptions
  ];

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    await api
      .post('/carrier/education/', formData)
      .then(res => {
        toast.success('Education successfully added.', { theme: 'colored' });
        getUser();
        setFormData({});
        setSelectedSchool(null);
        setDateFrom(null);
        setDateTo(null);
      })
      .catch(err => {
        setErrors(err?.response?.data);
        toast.error('An error has occurred', { theme: 'colored' });
      });
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={'border'} variant={'primary'} />
    </Flex>
  ) : (
    <Form>
      <CSRFToken />
      <Form.Group as={Row} className="mb-3" controlId={'school'}>
        <Form.Label column sm={3} className="text-lg-end">
          School
        </Form.Label>
        <Col sm={9} md={7}>
          <Select
            options={schoolOptions}
            size="sm"
            name={'school'}
            onChange={newValue => {
              setSelectedSchool(newValue);
              setFormData({ ...formData, school: newValue.value });
            }}
            classNamePrefix={'react-select'}
            value={selectedSchool}
            onMenuScrollToBottom={event => {
              setPage(prevState => prevState + 1);
            }}
            onInputChange={newValue => {
              query.set('search', newValue);
              getSchools();
            }}
          />
        </Col>
        <FormError error={errors?.school} />
      </Form.Group>
      <InputField
        value={formData.degree}
        label="Degree"
        name="degree"
        handleChange={handleChange}
      />
      <FormError error={errors?.degree} />
      <InputField
        value={formData.field}
        label="Field"
        name="field"
        handleChange={handleChange}
      />
      <FormError error={errors?.field} />

      <Form.Group as={Row} className="mb-3" controlId={'delegation'}>
        <Form.Label column sm={3} className="text-lg-end">
          From
        </Form.Label>
        <Col sm={9} md={7}>
          <DatePicker
            selected={dateFrom}
            onChange={date => {
              setDateFrom(date);
              setFormData({
                ...formData,
                date_from: date.toISOString().slice(0, 10)
              });
            }}
            className="form-control form-control-sm"
            placeholderText="Select Date"
          />
        </Col>
        <FormError error={errors?.date_from} />
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId={'delegation'}>
        <Form.Label column sm={3} className="text-lg-end">
          To
        </Form.Label>
        <Col sm={9} md={7}>
          <DatePicker
            selected={dateTo}
            onChange={date => {
              setDateTo(date);
              setFormData({
                ...formData,
                date_to: date.toISOString().slice(0, 10)
              });
            }}
            className="form-control form-control-sm"
            placeholderText="Select Date"
          />
        </Col>
        <FormError error={errors?.date_to} />
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 10, offset: 3 }}>
          <Button onClick={handleSubmit} type="submit">
            Save
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default EducationForm;
