import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const Agreement = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="agreement" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Agreement</h5>
			</Card.Header>
			<Card.Body>
				<h6>Restrictions</h6>
				<p className="fs-9 mb-0 fw-semibold">
					You agree not to, and you will not permit others to:
				</p>
				<ul type="1">
					<li>
						License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise
						commercially exploit the website or make the platform available to any third party.
					</li>
					<li>
						Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part
						of the website.
					</li>
					<li>
						Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of
						nexameet or its affiliates, partners, suppliers or the licensors of the website.
					</li>
				</ul>
				<hr className="my-4"/>
				<h6>Payment</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					If you register to any of our recurring payment plans, you agree to pay all fees or charges to your account
					for
					the Service in accordance with the fees, charges and billing terms in effect at the time that each fee or
					charge is due and payable. Unless otherwise indicated in an order form, you must provide nexameet with a
					valid credit card (Visa, MasterCard, or any other issuer accepted by us) (“Payment Provider”) as a condition
					to signing up for the Premium plan. Your Payment Provider agreement governs your use of the designated
					credit card account, and you must refer to that agreement and not these Terms to determine your rights and
					liabilities with respect to your Payment Provider. By providing nexameet with your credit card number and
					associated payment information, you agree that nexameet is authorized to verify information immediately,
					and subsequently invoice your account for all fees and charges due and payable to nexameet hereunder and
					that no additional notice or consent is required. You agree to immediately notify nexameet of any change in
					your billing address or the credit card used for payment hereunder. nexameet reserves the right at any time
					to change its prices and billing methods, either immediately upon posting on our Site or by e-mail delivery to
					your organization’s administrator(s).
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					Any attorney fees, court costs, or other costs incurred in collection of delinquent undisputed amounts shall
					be the responsibility of and paid for by you.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					No contract will exist between you and nexameet for the Service until nexameet accepts your order by a
					confirmatory e-mail, SMS/MMS message, or other appropriate means of communication.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					You are responsible for any third-party fees that you may incur when using the Service
				</p>
				<hr className="my-4"/>
				<h6>Return and Refund Policy</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Thanks for shopping at nexameet. We appreciate the fact that you like to buy the stuff we build. We also
					want to make sure you have a rewarding experience while you’re exploring, evaluating, and purchasing our
					products.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					As with any shopping experience, there are terms and conditions that apply to transactions at nexameet.
					We’ll be as brief as our attorneys will allow. The main thing to remember is that by placing an order or
					making a purchase at nexameet, you agree to the terms along with nexameet’s Privacy Policy.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate
					to
					contact us and we will discuss any of the issues you are going through with our product.
				</p>
			</Card.Body>
		</Card>
	)
})

export default Agreement