import {Card, Col, Form, Row} from "react-bootstrap";
import {Field} from "formik";
import React, {useState} from "react";
import TicketContentsFields from "./TicketContentsFields";
import {Ticket} from "../Ticket";

const TicketPreviewCard = ({errors, setFieldValue, values, t}) => {
    const [background, setBackground] = useState(values?.ticket_options?.background_url);

    return (
        <Card className={"mt-3"}>
            <Card.Header>
                <h5>{t('fields.ticketPreview.title')} <span className={"text-danger"}>*</span></h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col lg={5}>
                        <Form.Group>
                            <Form.Label>{t('fields.ticketPreview.ticketTitle')}: <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Field name={"ticket.title"}>
                                {({field}) => (
                                    <Form.Control
                                        label={"Title"}
                                        name={"ticket.title"}
                                        errors={errors}
                                        formGroupProps={{
                                            className: 'mb-3'
                                        }}
                                        onChange={e =>
                                            setFieldValue(field.name, e.target.value)
                                        }
                                        placeholder={t('fields.ticketPreview.ticketTitle')}
                                        value={field.value}
                                    />
                                )}
                            </Field>
                        </Form.Group>
                    </Col>
                    <Col lg={1}>
                        <Form.Group className={"end-0 float-end"}>
                            <Form.Label>{t('fields.ticketPreview.color')}: </Form.Label>
                            <Field name={"ticket.color"}>
                                {({field}) => (
                                    <Form.Control
                                        type={"color"}
                                        label={"Color"}
                                        name={"ticket.color"}
                                        errors={errors}
                                        formGroupProps={{
                                            className: 'mb-3'
                                        }}
                                        onChange={e =>
                                            setFieldValue(field.name, e.target.value)
                                        }
                                        value={field.value}
                                    />
                                )}
                            </Field>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className={"end-0 float-end"}>
                            <Form.Label>{t('fields.ticketPreview.background')}: </Form.Label>
                            <Field name={"ticket.background"}>
                                {({field}) => (
                                    <Form.Control
                                        type={"file"}
                                        name={"ticket.background"}
                                        errors={errors}
                                        formGroupProps={{
                                            className: 'mb-3'
                                        }}
                                        onChange={e => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    setFieldValue(field.name, reader.result)
                                                    setBackground(reader.result)
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                )}
                            </Field>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={5}>
                        <Row>
                            <Col className={"mt-3"}>
                                <TicketContentsFields setFieldValue={setFieldValue} values={values} errors={errors}
                                                      t={t}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={7}>
                        <h6>{t('fields.ticketPreview.preview')}:</h6>
                        <Ticket ticket={values?.ticket} background={background} elements={values?.ticket?.elements} preview={true}
                                values={values} t={t}/>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default TicketPreviewCard