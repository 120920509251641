import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Image } from 'react-bootstrap';

const Process = ({
  isFirst,
  title,
  description,
  image,
  inverse,
  index,
  children,
  t,
  i18n
}) => {
  return (
    <Row
      className={classNames('flex-between-center flex-wrap', {
        'mt-7': !isFirst,
        'mt-8': isFirst
      })}
    >
      <Col
        md={{ order: inverse ? 2 : 0, span: 6 }}
        lg={5}
        xl={4}
        className={classNames({ 'pe-lg-6': inverse, 'ps-lg-6': !inverse })}
      >
        <div
          className={'rounded-circle'}
          style={{
            width: '323px',
            height: '323px',
            background: '#EAF2FC',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            className={'rounded-circle'}
            style={{
              width: '234px',
              height: '234px',
              background: '#C0D7F7'
            }}
          >
            <Image
              fluid
              className="px-6 px-md-0 pt-4"
              style={{ transform: 'scale(1.5)' }}
              src={image}
              alt=""
            />
          </div>
        </div>
      </Col>
      <Col md lg={5} xl={4} className="mt-4 mt-md-0">
        <h2
          style={{
            width: '110px',
            height: '61px',
            color: '#EAF2FC',
            lineHeight: '61px',
            letterSpacing: '0.15em',
            fontFamily: 'Baloo Chettan 2',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '96px'
          }}
          className={'mb-3'}
        >
          0{index + 1}
        </h2>
        <h2
          style={{
            color: '#12315C',
            paddingTop: '65px',
            paddingBottom: '65px',
            letterSpacing: '0.15em',
            fontFamily: 'Baloo Chettan 2',
            fontSize: '24px'
          }}
          className={'fw-bold'}
        >
          {t(title)}
        </h2>
        <p
          className={'fw-semi-bold text-dark'}
          style={{
            color: '#12315C',
            letterSpacing: '0.15em',
            fontFamily: 'Baloo Chettan 2',
            fontSize: '20px'
          }}
        >
          {t(description)}
        </p>
        {children}
      </Col>
    </Row>
  );
};

Process.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  iconText: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  inverse: PropTypes.bool,
  children: PropTypes.node
};

export default Process;
