import {useEffect, useState} from "react";
import {api} from "../../../../utils/api";
import useQuery from "../../../../hooks/useQuery";
import Select from "react-select";

const ActivityForm = ({formData, setFormData, selectedActivity, setSelectedActivity}) => {
	const [activities, setActivities] = useState([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);

	let query = useQuery()

	const fetchActivities = async (q) => {
		setLoading(true)
		await api.get(`/activity/activity/?${q.toString()}`)
			.then(res => setActivities(res?.data?.results))
		setLoading(false)
	}

	useEffect(() => {
		query.set("page_size", "50")
		query.set("page", page.toString())
		fetchActivities(query)
		// eslint-disable-next-line
	}, [page]);

	let activityOptions = activities?.map(activity => ({label: activity?.name, value: activity?.id}));

	activityOptions = [
		{value: '', label: '------------------------'},
		...activityOptions,
	]

	return (
		<Select
			isLoading={loading}
			options={activityOptions}
			value={selectedActivity}
			onMenuScrollToBottom={() => setPage(prevState => prevState + 1)}
			onInputChange={newValue => {
				query.set("search", newValue);
				fetchActivities(query)
			}}
			onChange={newValue => {
				setSelectedActivity(newValue)
				setFormData({...formData, activity: newValue.value})
			}}
			classNamePrefix={"react-select"}
		/>
	)
}

export default ActivityForm