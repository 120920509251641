import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {api} from "../../../../utils/api";
import {useAppContext} from "../../../../providers/AppProvider";

const SingleItem = ({page, count}) => {
	return (
		<div
			className="bg-transparent text-white px-3 py-1"
			style={{borderBottom: '1px solid rgba(255, 255, 255, 0.05)'}}
		>
			<Flex justifyContent="between">
				<p className="mb-0">{page}</p>
				<p className="mb-0">{count}</p>
			</Flex>
		</div>
	);
};

SingleItem.propTypes = {
	page: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired
};

const RealTimeUsers = () => {
	const [userCount, setUserCount] = useState(0);
	const [mostActivePages, setMostActivePages] = useState([])
	const {
		setConfig
	} = useAppContext()

	const getOnlineUsers = async () => {
		await api.get("/analytics/realtimeusers/users-online-right-now/").then(res => setUserCount(res?.data?.count))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
	}

	const getMostActivePages = async () => {
		await api.get("/analytics/realtimeusers/most-active-pages/").then(res => setMostActivePages(res?.data?.pages))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
	}

	useEffect(() => {
		getOnlineUsers()
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getMostActivePages()
		// eslint-disable-next-line
	}, []);

	return (
		<Card className="h-100 bg-line-chart-gradient">
			<Card.Header className="bg-transparent light">
				<h5 className="text-white">Users Online Right Now</h5>
				<div className="real-time-user display-1 fw-normal text-white">
					{userCount}
				</div>
			</Card.Header>

			<Card.Body className="text-white fs--1 light pb-0">
				<div
					className="mt-4 rounded-2"
					style={{border: '1px solid rgba(255, 255, 255, 0.15)'}}
				>
					<div
						className="bg-transparent text-white px-3 py-1"
						style={{borderBottom: '1px solid rgba(255, 255, 255, 0.15)'}}
					>
						<Flex justifyContent="between">
							<p className="mb-0">Most Active Pages</p>
							<p className="mb-0">Count</p>
						</Flex>
					</div>

					{mostActivePages?.map(item => (
						<SingleItem key={item.route_visited} page={item.route_visited} count={item.count}/>
					))}
				</div>
			</Card.Body>
			<Card.Footer className="bg-transparent text-end light">
				<Link to="/activities/user" className="text-white">
					Real-time Data
				</Link>

				<FontAwesomeIcon
					icon="chevron-right"
					transform="shrink-4 down-1"
					className="me-1 text-white"
				/>
			</Card.Footer>
		</Card>
	);
};

export default RealTimeUsers;
