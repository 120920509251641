import React, {Fragment, useEffect, useState} from 'react';
import {Card, Form, Spinner} from 'react-bootstrap';
import Flex from '../../../../components/common/Flex';
import {useNavigate} from 'react-router-dom';
import useQuery from '../../../../hooks/useQuery';
import {getPermissions} from '../../actions/GroupsPermissions';
import {api} from '../../../../utils/api';
import {toast} from 'react-toastify';
import FormError from '../../../errors/FormError';
import Select from 'react-select';
import IconButton from '../../../../components/common/IconButton';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import CSRFToken from '../../../../helpers/CSRFToken';
import {withPermission} from '../../../../helpers/utils';
import {withTranslation} from "react-i18next";

const NewGroup = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState(null);
    const [page, setPage] = useState(1)
    const [formData, setFormData] = useState({
        name: '',
        permissions: []
    });
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    const query = useQuery();

    useEffect(() => {
        setLoading(true);
        query.set("page_size", "50")
        query.set("page", page.toString())
        getPermissions(query)
            .then(res => setPermissions([...permissions, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [page]);

    const changeFieldValue = e => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    let permissionOptions = permissions.map(permission => ({
        label: permission.name,
        value: permission.id
    }));

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        let fd = new FormData();
        fd.append('name', formData.name);
        if (selectedPermissions) {
            selectedPermissions.forEach(permission =>
                fd.append('permissions', permission.value)
            );
        }
        await api
            .post('/account/group/', fd, {
                headers: {
                    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                }
            })
            .then(() => {
                toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: 'colored'});
                navigate('/accounts/group');
            })
            .catch(err => {
                setErrors(err?.response?.data);
                toast.error(`${t('error', {ns: "common"})}`, {theme: 'colored'});
            });

        setLoading(false);
    };

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header>
                    <h5 className="mb-0 text-muted">{t('groupSettings')}</h5>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <CSRFToken/>

                        <Form.Group>
                            <Form.Label>{t('fields.name')}: <span className={"text-danger"}>*</span></Form.Label>
                            <Form.Control
                                name={'name'}
                                value={formData.name}
                                onChange={changeFieldValue}
                                placeholder={t('fields.name')}
                            />
                            <FormError error={errors.name}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t('fields.permissions')}:</Form.Label>
                            <Select
                                options={permissionOptions}
                                placeholder={`${t("select", {ns: "common"})} ${t("fields.permissions")}`}
                                isMulti
                                classNamePrefix="react-select"
                                value={selectedPermissions}
                                onChange={selectedOptions => {
                                    setSelectedPermissions(selectedOptions);
                                }}
                                onMenuScrollToBottom={event => {
                                    setPage(prevState => prevState + 1)
                                }}
                                onInputChange={newValue => {
                                    query.set("search", newValue)
                                    getPermissions(query).then(res => setPermissions(res?.results)).catch(() => {
                                    });
                                }}
                            />
                        </Form.Group>
                        <FormError error={errors.permissions}/>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                        <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                        <IconButton icon={faSave} onClick={e => handleSubmit(e)}>
                            <span className="d-none d-sm-inline-block ms-1">{t('save', {ns: "common"})}</span>
                        </IconButton>
                    </Flex>
                </Card.Footer>
            </Card>
        </Fragment>
    );
};

export default withPermission(withTranslation(["groups", "common"])(NewGroup), "auth.add_group");
