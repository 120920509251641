import React, { useCallback, useEffect, useState } from 'react';
import useQuery from '../../../hooks/useQuery';
import { api } from '../../../utils/api';
import { debounce } from 'lodash';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import AdvanceTableSearchBox from '../../../components/common/advance-table/AdvanceTableSearchBox';
import Flex from '../../../components/common/Flex';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import Experience from '../Experience';
import IconButton from '../../../components/common/IconButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ExperienceForm from '../settings/ExperienceForm';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import {withPermission} from "../../../helpers/utils";

const Experiences = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  let query = useQuery();

  const getExperiences = async q => {
    setLoading(true);
    await api.get(`/carrier/experience/?${q?.toString()}`).then(res => {
      setExperiences(res?.data?.results);
      setCount(res?.data?.count);
    });

    setLoading(false);
  };

  const handleDelete = async (e, id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
    if (isConfirmed) {
      e.preventDefault();
      setLoading(true);
      await api.delete(`/carrier/experience/${id}/`).then(() => {
        toast.success(`Experience successfully deleted.`, {
          theme: 'colored'
        });
        getExperiences(query);
      });
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedLoadItems = useCallback(debounce(getExperiences, 600), []);

  useEffect(() => {
    getExperiences(query);
    // eslint-disable-next-line
    }, []);

  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="align-items-center g-1">
          <Col className="pe-0">
            <h5 className="mb-0">Experiences ({count}) </h5>
          </Col>
          <Col>
            <Row className="g-2">
              <Col>
                <AdvanceTableSearchBox fetch={delayedLoadItems} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
        <ExperienceForm
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          getUser={getExperiences}
        />
        {loading ? (
          <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'} />
          </Flex>
        ) : (
          experiences?.map((experience, index) => (
            <Flex
              justifyContent={'between'}
              alignItems={'center'}
              wrap={'wrap'}
            >
              <Experience
                key={experience.id}
                experience={experience}
                isLast={index === experiences.length - 1}
              />
              <div>
                <IconButton
                  icon={faTrash}
                  transform={'shrink-3'}
                  size={'sm'}
                  iconClassName={'text-danger'}
                  title={'Remove'}
                  className={'bg-transparent border-0'}
                  onClick={e => handleDelete(e, experience?.id)}
                />
              </div>
            </Flex>
          ))
        )}
      </Card.Body>
      <Card.Footer>
        <AdvanceTablePagination
          count={count}
          fetch={getExperiences}
          query={query}
          length={experiences?.length}
          itemsPerPage={25}
        />
      </Card.Footer>
    </Card>
  );
};

export default withPermission(Experiences, "carrier.view_experience");
