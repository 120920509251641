import {formatDateTime, withPermission} from "../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {Card, ListGroup, Spinner} from "react-bootstrap";
import Flex from "../../components/common/Flex";
import {useParams} from "react-router-dom";
import {api} from "../../utils/api";

const BugDetail = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [bug, setBug] = useState({});

    const {id} = useParams();

    const fetchBugDetails = async () => {
        setLoading(true)
        await api.get(`/bug/${id}/`).then((res) => {
            setBug(res?.data)
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchBugDetails()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header>
                    <h5>{bug?.title}</h5>
                </Card.Header>
                <Card.Body>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <strong>{t('fields.user')}:</strong> {bug?.user_full_name || t('N/A')}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>{t('fields.where')}:</strong> {t(`fields.whereOptions.${bug?.where}` || 'N/A')}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>{t('fields.description')}:</strong> <div dangerouslySetInnerHTML={{__html: bug?.description}}></div>
                        </ListGroup.Item>
                        {bug?.file && (
                            <ListGroup.Item>
                                <strong>{t('fields.file')}:</strong> <a href={bug.file} target="_blank" rel="noopener noreferrer">{t('see', {ns: "common"})} {t('fields.file')}</a>
                            </ListGroup.Item>
                        )}
                        <ListGroup.Item>
                            <strong>{t('fields.created_at', {ns: "common"})}:</strong> {formatDateTime(bug?.created_at)}
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default withPermission(withTranslation(["bug", "common"])(BugDetail), "bug.view_bug")