import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const Contact = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="contact" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Contact Us</h5>
			</Card.Header>
			<Card.Body>
				<p className="fs-9 mb-0 fw-semibold">
					Don't hesitate to contact us if you have any questions.
				</p>
				<ul type="1">
					<li>
						 Via Email: <a href={"mailto:support@nexameet.com"}>support@nexameet.com</a>
					</li>
				</ul>
			</Card.Body>
		</Card>
)
})

export default Contact