import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Avatar, {AvatarGroup} from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Button, Spinner} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {api} from "../../../utils/api";
import {useNavigate} from "react-router-dom";
import {formatMonth} from "../../../helpers/utils";
import paths from "../../../routes/paths";
import {useAppContext} from "../../../providers/AppProvider";

const Discussion = () => {
	const [schedule, setSchedule] = useState({})
	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()

	const {
		setConfig
	} = useAppContext()

	const getSchedule = async () => {
		setLoading(true)
		await api.get("/dashboard/schedule/").then(res => setSchedule(res?.data))
			.catch(err => {
				if (err?.response.status === 404) setSchedule({title: err?.response?.data?.detail})
			})
			.catch(err => {
				if (err?.response.status === 403) setConfig("isAuthenticated", false)
			})
		setLoading(false)
	}

	useEffect(() => {
		getSchedule()
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Card className="h-100">
			<Card.Header className="pb-0">
				<Flex justifyContent="between">
					<div>
						<p className="mb-1 fs--2 text-500">Upcoming schedule</p>
						<h5 className="text-primary fs-0">{schedule?.title}</h5>
					</div>
					{schedule?.start &&
						<div
							className="bg-soft-primary px-3 py-3 rounded-circle text-center"
							style={{width: '60px', height: '60px'}}
						>
							<h5 className="text-primary mb-0 d-flex flex-column mt-n1">
								<span>{new Date(schedule?.start).getDate()}</span>
								<small className="text-primary fs--2 lh-1">{formatMonth(schedule?.start)}</small>
							</h5>
						</div>
					}
				</Flex>
			</Card.Header>

			<Card.Body as={Flex} alignItems="end">
				<Row className="g-3 justify-content-between">

					<Col xs={10} className="mt-0">
						{schedule?.description &&
							<p className="fs--1 text-600 mb-0">
								{schedule?.description}
							</p>
						}
					</Col>

					<Col xs="auto">
						<Button variant="success" className="w-100 fs--1" onClick={(e) => {
							schedule?.link ? window.open(schedule?.link, "_blank") : navigate(paths.calendar)
						}}>
							<FontAwesomeIcon icon="video" className="me-2"/>
							{schedule?.link ? "Join meeting" : "Create a Schedule"}
						</Button>
					</Col>

					<Col xs="auto">
						{schedule?.registration?.length > 0 &&
							<AvatarGroup dense>
								{schedule?.registration?.map(({avatar, full_name, id}) => {
									return (
										<Avatar
											src={avatar && avatar}
											key={id}
											size={'xl'}
											name={full_name && full_name}
											isExact
											className="border border-3 rounded-circle border-light"
										/>
									);
								})}
							</AvatarGroup>
						}
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

Discussion.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			img: PropTypes.string,
			name: PropTypes.string,
			size: PropTypes.string
		})
	)
};

export default Discussion;
