import {withPermission} from "../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import CSRFToken from "../../helpers/CSRFToken";
import Flex from "../../components/common/Flex";
import IconButton from "../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {api} from "../../utils/api";
import {toast} from "react-toastify";
import FormError from "../errors/FormError";
import TinymceEditor from "../../components/common/TinymceEditor";

const BugCreate = ({t, i18n}) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        where: "",
        description: "<p>List the steps that occurred before the bug happened:</p><ol>" +
            "<li></li>" +
            "<li></li>" +
            "<li></li>" +
            "</ol>" +
            "<p>Expected Result:\n</p>" +
            "<p>Actual Result:</p>",
        file: ""
    })

    const handleFieldChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        await api.post("/bug/", formData, {
            headers: {"Content-Type": "multipart/form-data"},
        })
            .then(res => {
                toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: "colored"})
            })
            .catch(err => {
                toast.error(t('error', {ns: "common"}), {theme: "colored"})
                setErrors(err?.response?.data);
            })
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card>
                <Card.Header>
                    <h5>{t('title')}</h5>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <CSRFToken/>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>{t('fields.where')} <span
                                        className={"text-danger"}>*</span></Form.Label>
                                    <Form.Select
                                        name={"where"}
                                        onChange={handleFieldChange}
                                    >
                                        <option value={""}>-------------------------------</option>
                                        <option value={"dashboard"}>{t('fields.whereOptions.dashboard')}</option>
                                        <option
                                            value={"financial_report"}>{t('fields.whereOptions.financial_report')}</option>
                                        <option
                                            value={"event_management"}>{t('fields.whereOptions.event_management')}</option>
                                        <option
                                            value={"member_management"}>{t('fields.whereOptions.member_management')}</option>
                                        <option
                                            value={"activity_management"}>{t('fields.whereOptions.activity_management')}</option>
                                        <option
                                            value={"user_authentication"}>{t('fields.whereOptions.user_authentication')}</option>
                                        <option
                                            value={"profile_settings"}>{t('fields.whereOptions.profile_settings')}</option>
                                        <option
                                            value={"notifications"}>{t('fields.whereOptions.notifications')}</option>
                                        <option value={"file_upload"}>{t('fields.whereOptions.file_upload')}</option>
                                        <option
                                            value={"api_endpoints"}>{t('fields.whereOptions.api_endpoints')}</option>
                                        <option value={"other"}>{t('fields.whereOptions.other')}</option>
                                    </Form.Select>
                                    <FormError error={errors?.where}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>{t('fields.title')} <span
                                        className={"text-danger"}>*</span></Form.Label>
                                    <Form.Control
                                        name={"title"}
                                        placeholder={t('fields.title')}
                                        onChange={handleFieldChange}
                                    />
                                    <FormError error={errors?.title}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label>{t('fields.description')} <span
                                    className={"text-danger"}>*</span></Form.Label>
                                <TinymceEditor
                                    value={formData.description}
                                    handleChange={newValue => setFormData({...formData, description: newValue})}
                                />
                                <FormError error={errors?.description}/>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label>{t('fields.file')}</Form.Label>
                                <Form.Control
                                    name={"file"}
                                    type={"file"}
                                    onChange={e => {
                                        setFormData({...formData, file: e.target.files[0]})
                                    }}
                                />
                                <FormError error={errors?.file}/>
                            </Form.Group>
                        </Row>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                        <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                        <IconButton
                            icon={faSave}
                            onClick={e => handleSubmit(e)}
                        >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                        </IconButton>
                    </Flex>
                </Card.Footer>
            </Card>
        </Fragment>
    )
}

export default withPermission(withTranslation(["bug", "common"])(BugCreate), "bug.add_bug")