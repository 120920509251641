import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import RegistrationForm from 'pages/authentication/RegistrationForm';
import bgImg from 'assets/img/generic/15.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import useQuery from "../../../hooks/useQuery";
import paths from "../../../routes/paths";
import {withTranslation} from "react-i18next";

const Registration = ({t, i18n}) => {
  let query = useQuery()
  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>{t('register.title')}</h3>
        <p className="mb-0 fs--1">
          <span className="fw-semi-bold">{t('register.alreadyUser')} </span>
          <Link to={`${paths.splitLogin}?${query.toString()}`}>{t('login.title')}</Link>
        </p>
      </Flex>
      <RegistrationForm layout="split" hasLabel t={t} i18n={i18n} />
    </AuthSplitLayout>
  );
};

export default withTranslation(['authentication', 'common'])(Registration);
