import Flex from "../../../components/common/Flex";
import VerifiedBadge from "../../../components/common/VerifiedBadge";
import SoftBadge from "../../../components/common/SoftBadge";
import {formatDateTime} from "../../../helpers/utils";
import IconButton from "../../../components/common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {ListGroup} from "react-bootstrap";
import React from "react";

const ConnectionItem = ({item, handleDelete, showDate}) => {
    const data = item?.extra_data
    return (
        <ListGroup.Item>
            <Flex justifyContent="between" alignItems="center">
                <div>
                    {item?.provider} - {data?.email} {data?.verified_email &&
                    <VerifiedBadge/>}
                </div>
                <div>
                    {showDate && <SoftBadge pill bg='primary'
                                            className='me-2'>{formatDateTime(item?.last_login)}</SoftBadge>}
                    <IconButton
                        icon={faTrash}
                        transform={"shrink-3"}
                        size={"sm"}
                        iconClassName={"text-danger"}
                        title={"Remove"}
                        className={"bg-transparent border-0"}
                        onClick={(e) => handleDelete(e, item?.id)}
                    />
                </div>
            </Flex>
        </ListGroup.Item>
    )
}

export default ConnectionItem