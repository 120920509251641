import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import Flex from '../../../../components/common/Flex';
import useQuery from '../../../../hooks/useQuery';
import {useNavigate, useParams} from 'react-router-dom';
import {getCities, getCountries, getDelegations} from '../../actions/Address';
import FormError from '../../../errors/FormError';
import Select from 'react-select';
import CardHeader from 'react-bootstrap/CardHeader';
import IconButton from '../../../../components/common/IconButton';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {api} from '../../../../utils/api';
import {toast} from 'react-toastify';
import CSRFToken from '../../../../helpers/CSRFToken';
import {withPermission} from '../../../../helpers/utils';
import paths from "../../../../routes/paths";
import {useAppContext} from "../../../../providers/AppProvider";
import {withTranslation} from "react-i18next";

const EditAddress = ({t, i18n}) => {
	const [loading, setLoading] = useState(false);
	const [countries, setCountries] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [pageCountry, setPageCountry] = useState(1);
	const [pageDelegation, setPageDelegation] = useState(1);
	const [pageCity, setPageCity] = useState(1);
	const [delegations, setDelegations] = useState([]);
	const [selectedDelegation, setSelectedDelegation] = useState(null);
	const [cities, setCities] = useState([]);
	const [selectedCity, setSelectedCity] = useState(null);
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState({
		address: '',
		country: '',
		delegation: '',
		city: ''
	});

	const {
		setConfig
	} = useAppContext()

	const query = useQuery();
	const navigate = useNavigate();
	const {id} = useParams();

	useEffect(() => {
		query.set('page_size', '50');
		query.set('page', pageCountry.toString());
		getCountries(query)
			.then(res => setCountries([...countries, ...res?.results]))
			.catch(() => {
			});
		// eslint-disable-next-line
	}, [pageCountry]);

	useEffect(() => {
		query.set('page_size', '50');
		query.set('page', pageDelegation.toString());
		getDelegations(query)
			.then(res => setDelegations([...delegations, ...res?.results]))
			.catch(() => {
			});
		// eslint-disable-next-line
	}, [pageDelegation]);

	useEffect(() => {
		query.set('page_size', '50');
		query.set('page', pageCity.toString());
		getCities(query)
			.then(res => setCities([...cities, ...res?.results]))
			.catch(() => {
			});
		// eslint-disable-next-line
	}, [pageCity]);

	const getAddress = async () => {
		return (await api.get(`/address/address/${id}/`)
				.catch((error) => {
					if (error?.response?.status === 404) navigate(paths.error404);
					if (error?.response?.status === 500) navigate(paths.error500);
					if (error?.response?.status === 403)
						setConfig("isAuthenticated", false);
				})
		).data;
	};

	useEffect(() => {
		getAddress().then(res => {
			setFormData(res);
			setSelectedCountry({value: res?.country, label: res?.country_name});
			setSelectedDelegation({
				value: res?.delegation,
				label: res?.delegation_name
			});
			setSelectedCity({value: res?.city, label: res?.city_name});
		});
		// eslint-disable-next-line
	}, []);

	let countryOptions = countries?.map(country => ({
		value: country.id,
		label: country.country
	}));

	countryOptions = [
		{value: '', label: '----------------------------'},
		...countryOptions
	];

	let delegationOptions = delegations?.map(state => ({
		value: state.id,
		label: state.name
	}));

	delegationOptions = [
		{value: '', label: '----------------------------'},
		...delegationOptions
	];

	let cityOptions = cities?.map(city => ({value: city.id, label: city.name}));

	cityOptions = [
		{value: '', label: '----------------------------'},
		...cityOptions
	];

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		await api
			.patch(`/address/address/${id}/`, formData)
			.then(res => {
				toast.success(`${t('title')} ${res?.data.address} ${t('editSuccess', {ns: "common"})}`, {theme: "colored"});
				navigate('/address');
			})
			.catch(error => {
				setErrors(error?.response?.data);
				toast.error(t('error', {ns: "common"}), {theme: "colored"});
			});
		setLoading(false);
	};

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Fragment>
			<Card>
				<Card.Header>
					<h5 className="mb-0 text-muted">{t('fields.basic')}</h5>
				</Card.Header>
				<Card.Body>
					<Form>
						<CSRFToken/>
						<Form.Label>{t('fields.address')}: <span className={"text-danger"}>*</span></Form.Label>
						<Form.Group>
							<Form.Control
								label="Address"
								name="address"
								errors={errors}
								formGroupProps={{
									className: 'mb-3'
								}}
								onChange={e => {
									setFormData({...formData, address: e.target.value});
								}}
								placeholder={t('fields.address')}
								value={formData.address}
							/>
							<FormError error={errors.address}/>
						</Form.Group>
						<Row>
							<Col md={4}>
								<Form.Label>{t('fields.country')}:</Form.Label>
								<Form.Group>
									<Select
										options={countryOptions}
										value={selectedCountry}
										classNamePrefix="react-select"
										onChange={value => {
											setSelectedCountry(value);
											setFormData({...formData, country: value.value});
											query.set('country_id', value.value);
											getDelegations(query).then(r =>
												setDelegations(r?.results)
											);
										}}
										placeholder={`${t('select', {ns: "common"})} ${t('fields.country')}`}
										onMenuScrollToBottom={() => {
											setPageCountry(page => page + 1);
										}}
										onInputChange={e => {
											query.set('search', e);
											getCountries(query).then(r => setCountries(r?.results));
										}}
									/>
									<FormError error={errors.country}/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Label>{t('fields.state')}:</Form.Label>
								<Form.Group>
									<Select
										options={delegationOptions}
										value={selectedDelegation}
										classNamePrefix="react-select"
										onChange={value => {
											setSelectedDelegation(value);
											setFormData({...formData, delegation: value.value});
											query.set('state_id', value.value);
											getCities(query).then(r => setCities(r?.results));
										}}
										placeholder={`${t('select', {ns: "common"})} ${t('fields.state')}`}
										onMenuScrollToBottom={() => {
											setPageDelegation(page => page + 1);
										}}
										onInputChange={e => {
											query.set('search', e);
											getDelegations(query).then(r =>
												setDelegations(r?.results)
											);
										}}
									/>
									<FormError error={errors.delegation}/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Label>{t('fields.city')}:</Form.Label>
								<Form.Group>
									<Select
										options={cityOptions}
										value={selectedCity}
										classNamePrefix="react-select"
										onChange={value => {
											setSelectedCity(value);
											setFormData({...formData, city: value.value});
										}}
										placeholder={`${t('select', {ns: "common"})} ${t('fields.city')}`}
										onMenuScrollToBottom={() => {
											setPageCity(page => page + 1);
										}}
										onInputChange={e => {
											query.set('search', e);
											getCities(query).then(r => setCities(r?.results));
										}}
									/>
									<FormError error={errors.city}/>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>
			<Card className={'mt-3'}>
				<CardHeader>
					<Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
						<p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
						<IconButton icon={faSave} onClick={e => handleSubmit(e)}>
							<span className="d-none d-sm-inline-block ms-1">{t('save', {ns: "common"})}</span>
						</IconButton>
					</Flex>
				</CardHeader>
			</Card>
		</Fragment>
	);
};

export default withPermission(withTranslation(["address", "common"])(EditAddress), "address.change_address");
