import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';

const ContactInfo = ({data}) => {
  return (
    <Card>
      <FalconCardHeader
        title="Contact Information"
        titleTag="h6"
        className="py-2 bg-body-tertiary"
      />
      <Card.Body>
        <Row className="g-0 border-bottom pb-x1 mb-x1 align-items-sm-center align-items-xl-start">
          <Col xs={12} sm="auto" xl={12} className="me-sm-3 me-xl-0">
            <Avatar src={data?.client_avatar} size="3xl" />
          </Col>
          <Col xs={12} sm="auto" xl={12}>
            <p className="fw-semibold text-800 mb-0">{data?.client_full_name}</p>
            <Link
              to={paths.usersDetail.replace(":id", data?.client)}
              className="btn btn-link btn-sm p-0 fe-medium fs-10"
            >
              View more details
            </Link>
          </Col>
        </Row>
        <Row className="g-0 justify-content-lg-between">
          <Col xs="auto" md={6} lg="auto">
            <Row>
              <Col md="auto" className="mb-4 mb-md-0 mb-xl-4">
                <h6 className="mb-1">Email</h6>
                <a href={`mailto:${data?.client_email}`} className="fs-10">
                  {data?.client_email}
                </a>
              </Col>
              <Col md="auto" className="mb-4 mb-md-0 mb-xl-4">
                <h6 className="mb-1">Phone Number</h6>
                <a href={`tel:${data?.client_number}`} className="fs-10">
                  {data?.client_number}
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ContactInfo;
