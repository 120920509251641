import React, {useEffect, useState} from 'react';
import eventBanner from 'assets/img/generic/13.jpg';
import {Card, Row, Col, Button} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import {Link, useNavigate, useParams} from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import {convertPrice, formatMonth, formatNumberCurrency} from '../../../helpers/utils';
import paths from "../../../routes/paths";
import EventRegistrationModal from "./EventRegistrationModal";
import EventShareModal from "./EventShareModal";
import {usePermissions} from "../../../providers/PermissionsProvider";
import Swal from "sweetalert2";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import EventTicketModal from "./EventTicketModal";
import {useAppContext} from "../../../providers/AppProvider";
import {faCoins, faTasks} from "@fortawesome/free-solid-svg-icons";

const EventDetailHeader = ({event, getEvent, setLoading, t}) => {
    const [registrationModalOpen, setRegistrationModalOpen] = useState(false)
    const [ticketModalOpen, setTicketModalOpen] = useState(false)
    const [shareModalOpen, setShareModalOpen] = useState(false)
    const [currencyData, setCurrencyData] = useState({})
    const [isEventLiked, setIsEventLiked] = useState(false)
    const [isEventInterested, setIsEventInterested] = useState(false)
    const [eventInfo, setEventInfo] = useState({
        title: '',
        eventDate: {day: '', month: ''},
        author: '',
        regFee: ''
    });

    const {
        config: {currency, isCompany, company}
    } = useAppContext()

    const {hasPermission, hasPermissions} = usePermissions()
    const navigate = useNavigate();
    const {id} = useParams();

    const getCurrency = async () => {
        await api.get(`/currency/${currency}/`).then(res => setCurrencyData(res?.data))
    }

    useEffect(() => {
        getCurrency()
        // eslint-disable-next-line
    }, [currency]);

    useEffect(() => {
        if (event?.is_liked){
            setIsEventLiked(true)
        }
        if (event?.is_interested){
            setIsEventInterested(true)
        }
    }, [event]);

    useEffect(() => {
        const fetchEventInfo = async () => {
            try {
                const regFees = await Promise.all(event?.ticket_options?.option_set?.map(async (option) => {
                    const convertedPrice = await convertPrice(option?.price, option?.currency, currency);
                    return formatNumberCurrency(convertedPrice, currencyData);
                }));
                setEventInfo({
                    title: event.title,
                    eventDate: {
                        day: new Date(event.start_date).getDate(),
                        month: formatMonth(event.start_date)
                    },
                    author: event.activity_name,
                    regFee: regFees?.join(' - ')
                });
            } catch (e) {

            }
        };

        fetchEventInfo();
        // eslint-disable-next-line
    }, [event, currencyData]);

    const handleDelete = async e => {
        const {isConfirmed} = await Swal.fire({
            title: t('sure', {ns: "common"}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
            cancelButtonText: t('cancel', {ns: "common"})
        });
        if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/event/event/${id}/`).then(() => {
                toast.success(`${t('title')} ${event?.title} ${t('deleteSuccess', {ns: "common"})}`, {
                    theme: 'colored'
                });
                navigate(paths.events)
            });
            setLoading(false);
        }
    }

    const like = async (e) => {
        setLoading(true)
        await api.post("/event/like/", {event: id, company: company})
            .then(res => {
                toast.success("Event Liked successfully.", {theme: "colored"})
                setIsEventLiked(true)
            })
            .catch(err => {

            })
        setLoading(false)
    }

    const interest = async (e) => {
        setLoading(true)
        await api.post("/event/interest/", {event: id, company: company})
            .then(res => {
                toast.success("Event set to interested successfully.", {theme: "colored"})
                setIsEventInterested(true)
            })
            .catch(err => {

            })
        setLoading(false)
    }

    return (
        <Card className="p-0 mb-3">
            {!!eventBanner && (
                <img
                    className="card-img-top"
                    src={event?.cover_url ? event?.cover_url : eventBanner}
                    alt=""
                />
            )}
            <Card.Body className="overflow-hidden">
                <Row className="flex-center">
                    <Col>
                        <Flex>
                            <Calendar {...eventInfo.eventDate} />
                            <div className="fs--1 ms-2 flex-1">
                                <h5 className="fs-0 text-capitalize">{eventInfo.title}</h5>
                                <p className="mb-0 text-capitalize">
                                    {t('by')}
                                    <Link className=" ms-1 " to={paths.activityDetail.replace(":id", event?.activity)}>
                                        {eventInfo.author}
                                    </Link>
                                </p>
                                <span className="fs-0 text-warning fw-semi-bold">
                  {eventInfo.regFee}
                </span>
                            </div>
                        </Flex>
                    </Col>
                    <Col md="auto" className="mt-4 mt-md-0">
                        <Flex wrap={"wrap"}>
                            {isCompany && (
                                <>
                                    <IconButton
                                        className="me-2"
                                        variant={`falcon-${isEventLiked ? 'primary' : 'default'}`}
                                        size="sm"
                                        icon="thumbs-up"
                                        onClick={like}
                                    >
                                        <span
                                            className={"d-none d-sm-inline-block"}>Like</span>
                                    </IconButton>
                                    <IconButton
                                        className="me-2"
                                        variant={`falcon-${isEventInterested ? 'primary' : 'default'}`}
                                        size="sm"
                                        icon="star"
                                        onClick={interest}
                                    >
                                        <span
                                            className={"d-none d-sm-inline-block"}>Interested</span>
                                    </IconButton>
                                    <IconButton
                                        className="me-2"
                                        variant="falcon-default"
                                        size="sm"
                                        icon="phone"
                                        onClick={() => navigate(paths.activityDetail.replace(":id", event?.activity))}
                                    >
                                        <span
                                            className={"d-none d-sm-inline-block"}>Connect with Host</span>
                                    </IconButton>
                                </>
                            )}
                            {hasPermission('event.delete_event') && (
                                <IconButton
                                    className="me-2"
                                    variant="danger"
                                    size="sm"
                                    icon="trash"
                                    onClick={(e) => handleDelete(e)}
                                >
                                    <span className={"d-none d-sm-inline-block"}>{t('delete', {ns: "common"})}</span>
                                </IconButton>
                            )}
                            {hasPermission('attendance.view_attendance') && (
                                <IconButton
                                    className="me-2"
                                    variant="success"
                                    size="sm"
                                    icon="eye"
                                    onClick={() => navigate(paths.eventAttendanceTable + "?event=" + id)}
                                >
                                    <span className={"d-none d-sm-inline-block"}>{t('buttons.attendance')}</span>
                                </IconButton>
                            )}
                            {hasPermissions(['event.view_eventincome', 'event.view_eventexpense']) && (
                                <IconButton
                                    className="me-2"
                                    variant="primary"
                                    size="sm"
                                    icon={faCoins}
                                    onClick={() => navigate(paths.eventFinance.replace(":id", id))}
                                >
                                    <span className={"d-none d-sm-inline-block"}>{t('buttons.finance')}</span>
                                </IconButton>
                            )}
                            {hasPermission("task.view_task") && (
                                <IconButton
                                    className="me-2"
                                    variant="info"
                                    size="sm"
                                    icon={faTasks}
                                    onClick={() => navigate(paths.eventTasks.replace(":id", id))}
                                >
                                    <span className={"d-none d-sm-inline-block"}>{t('buttons.tasks')}</span>
                                </IconButton>
                            )}
                            {hasPermission('feed.add_post') &&
                                <IconButton
                                    className="me-2"
                                    variant="falcon-default"
                                    size="sm"
                                    icon="share-alt"
                                    onClick={() => setShareModalOpen(!shareModalOpen)}
                                >
                                    <span className={"d-none d-sm-inline-block"}>{t('buttons.share')}</span>
                                </IconButton>
                            }
                            {hasPermission('event.change_event') && (
                                <IconButton
                                    className="me-2"
                                    variant="falcon-default"
                                    size="sm"
                                    icon="edit"
                                    iconClassName="text-primary"
                                    onClick={() => navigate(`/events/${id}/edit`)}
                                >
                                    <span className={"d-none d-sm-inline-block"}>{t('edit', {ns: "common"})}</span>
                                </IconButton>
                            )}
                            {event?.is_registered ?
                                <Button
                                    variant="falcon-primary"
                                    size="sm"
                                    className="px-4 px-sm-5"
                                    onClick={() => navigate(paths.eventAttendanceDetail.replace(":id", event?.register_id))}
                                >
                                    {t('buttons.registered')}
                                </Button> : event?.registration_open &&
                                <Button
                                    variant="falcon-primary"
                                    size="sm"
                                    className="px-4 px-sm-5"
                                    onClick={() => setRegistrationModalOpen(!registrationModalOpen)}
                                >
                                    {t('buttons.register')}
                                </Button>
                            }
                        </Flex>
                    </Col>
                </Row>
            </Card.Body>
            <EventShareModal event={event} setOpen={setShareModalOpen} open={shareModalOpen} getEvent={getEvent} t={t}/>
            {event?.is_registered ? (
                <EventTicketModal open={ticketModalOpen} setOpen={setTicketModalOpen}
                                  ticket={event?.ticket_options} t={t}/>
            ) : event?.registration_open && (
                <EventRegistrationModal
                    open={registrationModalOpen}
                    setOpen={setRegistrationModalOpen}
                    ticket={event?.ticket_options}
                    registration={event?.registration_data}
                    providers={event?.activity_data?.providers}
                    event={event}
                    getEvent={getEvent}
                    t={t}
                />
            )}
        </Card>
    );
};

export default EventDetailHeader;
