import { Card, Col, Row, Table } from 'react-bootstrap';
import { formatDate, formatDateTime } from '../../../helpers/utils';
import SimpleBarReact from 'simplebar-react';
import { Link } from 'react-router-dom';
import React from 'react';

const MeetReport = ({ report }) => {
  return (
    <Card className={'event-report-card'}>
      <Card.Body>
        <Row className="align-items-center text-center mb-3">
          <Col sm={6} className="text-sm-start">
            <img src={report?.activity_logo} alt="invoice" width={150} />
          </Col>
          <Col className="text-sm-end mt-3 mt-sm-0">
            <h2 className="mb-3">Report</h2>
            <h5>{report?.activity_name}</h5>
            <p className="fs--1 mb-0">{report?.activity_address}</p>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-500">Report for</h6>
            <h5>{report?.subject}</h5>
            <p className="fs--1">{report?.location}</p>
          </Col>
          <Col sm="auto" className="ms-auto">
            <div className="table-responsive">
              <Table borderless size="sm" className="fs--1">
                <tbody>
                  <tr>
                    <th className="text-sm-end">Report No:</th>
                    <td>{report?.id}</td>
                  </tr>
                  <tr>
                    <th className="text-sm-end">Meeting Date:</th>
                    <td>{formatDateTime(report?.date)}</td>
                  </tr>
                  <tr>
                    <th className="text-sm-end">Report Date:</th>
                    <td>{formatDate(report?.created_at)}</td>
                  </tr>
                  <tr>
                    <th className="text-sm-end">Registrants:</th>
                    <td>{report?.members?.length}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Row className={'align-items-center'}>
          <Col>
            <div
              dangerouslySetInnerHTML={{
                __html: report?.description
              }}
            />
          </Col>
        </Row>

        <div className="mt-4 fs--1">
          <h6 className="text-500">Participants:</h6>
          <SimpleBarReact>
            <Table striped className="border-bottom">
              <thead className="light">
                <tr className="bg-primary text-white dark__bg-1000 row-head">
                  <th className="border-0">Name</th>
                </tr>
              </thead>
              <tbody>
                {report?.members?.map((info, index) => (
                  <tr>
                    <td className="align-middle">
                      <h6 className="mb-0 text-nowrap">
                        <Link to={`/members/member/${info}/edit`}>
                          {report?.members_names[index]}
                        </Link>
                      </h6>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBarReact>
        </div>

        <Row className="align-items-center">
          <Col>
            <h6 className="text-500">Agenda:</h6>
            <ol>
              {report?.agenda_set?.map(agenda => (
                <li>{agenda?.title}</li>
              ))}
            </ol>
          </Col>
          <Col sm="auto" className="ms-auto">
            <h6 className="text-500">Results:</h6>
            <ul className={'me-2'}>
              {report?.result_set?.map(result => (
                <li>{result?.name}</li>
              ))}
            </ul>
          </Col>
        </Row>

        <div className="mt-4 fs--1">
          <h6 className="text-500">Action Plans:</h6>
          <SimpleBarReact>
            <Table striped className="border-bottom">
              <thead className="light">
                <tr className="bg-primary text-white dark__bg-1000 row-head">
                  <th className="border-0">Action</th>
                  <th className="border-0 text-end">Assigned To</th>
                </tr>
              </thead>
              <tbody>
                {report?.actionplan_set?.map((info, index) => (
                  <tr>
                    <td>{info?.action}</td>
                    <td className="align-middle text-end">
                      <h6 className="mb-0 text-nowrap">
                        <Link to={`/members/member/${info?.assigned}/edit`}>
                          {info?.assinged_name}
                        </Link>
                      </h6>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBarReact>
        </div>
      </Card.Body>
      <Card.Footer className="bg-light">
        <p className="fs--1 mb-0">
          <strong>Notes: </strong> We really appreciate your business and if
          there’s anything else we can do, please let us know!
        </p>
      </Card.Footer>
      <style type="text/css" media="print">
        {`
          .event-report-card {
            background-color: #f5f5f5;
            color: #333;
            font-family: 'Arial', sans-serif;
          }

          .event-report-card h2 {
            color: #007bff; /* Blue color */
          }

          .event-report-card .row-head {
            background-color: #2c7be5;
          }
        `}
      </style>
    </Card>
  );
};

export default MeetReport;
