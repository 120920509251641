import React, {Fragment, useEffect, useState} from 'react';
import avatarImg from '../../../../assets/img/team/avatar.png';
import useQuery from '../../../../hooks/useQuery';
import {useNavigate, useParams} from 'react-router-dom';
import {api} from '../../../../utils/api';
import {
    getCities,
    getCountries,
    getDelegations
} from '../../../address/actions/Address';
import {toast} from 'react-toastify';
import Flex from '../../../../components/common/Flex';
import {Card, Col, Form, Row, Spinner, Table} from 'react-bootstrap';
import {Field, FieldArray, Formik} from 'formik';
import Avatar from '../../../../components/common/Avatar';
import {isIterableArray, withPermission} from '../../../../helpers/utils';
import FalconDropzone from '../../../../components/common/FalconDropzone';
import cloudUpload from '../../../../assets/img/icons/cloud-upload.svg';
import FormError from '../../../errors/FormError';
import Select from 'react-select';
import FormErrors from '../../../errors/FormErrors';
import IconButton from '../../../../components/common/IconButton';
import SimpleBarReact from 'simplebar-react';
import CardHeader from 'react-bootstrap/CardHeader';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import CSRFToken from '../../../../helpers/CSRFToken';
import paths from "../../../../routes/paths";
import {useAppContext} from "../../../../providers/AppProvider";
import {withTranslation} from "react-i18next";
import TinymceEditor from "../../../../components/common/TinymceEditor";
import {getActivities} from "../../actions/Activity";

const EditActivity = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        membership: {
            variable: true,
            renewable: false,
            options: [
                {
                    name: '',
                    price: '',
                    currency: '',
                    default: false
                }
            ]
        },
        questions: [
            {
                name: '',
                description: ''
            }
        ],
    });
    const [selectedType, setSelectedType] = useState(null);
    const [selectedMembershipType, setSelectedMembershipType] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [pageCountry, setPageCountry] = useState(1);
    const [pageDelegation, setPageDelegation] = useState(1);
    const [pageCity, setPageCity] = useState(1);
    const [delegations, setDelegations] = useState([]);
    const [selectedDelegation, setSelectedDelegation] = useState(null);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [companies, setCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [pageCompany, setPageCompany] = useState(1);
    const [avatar, setAvatar] = useState([{src: avatarImg}]);
    const [errors, setErrors] = useState({});
    const [intervals, setIntervals] = useState([])
    const [selectedInterval, setSelectedInterval] = useState(null)
    const [periodForm, setPeriodForm] = useState(false)
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [activities, setActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [pageActivity, setPageActivity] = useState(1);
    const [periodFormData, setPeriodFormData] = useState({
        every: "",
        period: ""
    })

    const {
        config: {isSuperUser},
        setConfig
    } = useAppContext()

    const query = useQuery();
    const navigate = useNavigate();
    const {id} = useParams();

    const getCurrencies = async () => {
        return (await api.get('/currency/')).data;
    };

    const getCompanies = async () => {
        query.set('page_size', '50');
        query.set('page', pageCompany.toString());
        await api.get(`/company/?${query.toString()}`).then((res) => {
            setCompanies(res?.data?.results)
        })
            .catch(() => {
            });
    }

    const getActivity = async () => {
        setLoading(true);
        await api.get(`/activity/activity/${id}/`).then(res => {
            if (isSuperUser || res?.data?.is_owned) {
                setFormData({
                    ...res?.data,
                    ticket: {
                        title: res?.data?.ticket?.title,
                        color: res?.data?.ticket?.color,
                        contents: res?.data?.ticket?.ticket_contents?.map(content => ({
                            align: content?.align,
                            data: content?.data,
                            field: content?.field,
                            key: content?.key,
                            size: content?.size,
                            text: content?.text,
                            type: content?.type
                        })),
                    },
                    membership: {
                        type: res?.data?.membership?.type,
                        variable: res?.data?.membership?.variable,
                        renewable: res?.data?.membership?.renewable,
                        period: res?.data?.membership?.period_dict?.id,
                        options: res?.data?.membership?.membership_options?.map(option => ({
                            currency: option?.currency,
                            default: option?.default,
                            name: option?.name,
                            price: option?.price
                        }))
                    },
                    questions: res?.data?.activity_questions?.map(question => ({
                        name: question?.name,
                        description: question?.description
                    }))
                });
                setAvatar([
                    {src: res?.data?.logo_url ? res?.data?.logo_url : avatarImg}
                ]);
                setSelectedActivity(res?.data?.parent_activity ? {
                    value: res?.data?.parent_activity,
                    label: res?.data?.parent_activity_name
                } : null)
                setSelectedInterval(res?.data?.membership?.period_dict ? {
                    value: res?.data?.membership?.period_dict?.id,
                    label: res?.data?.membership?.period_dict?.display_name
                } : null)
                setSelectedType({
                    value: res?.data?.type,
                    label: res?.data?.type_display
                });
                if (res?.data?.type === "com") {
                    setSelectedCompany({
                        value: res?.data?.company,
                        label: res?.data?.company_name
                    });
                }
                setSelectedMembershipType({
                    value: res?.data?.membership?.type,
                    label: res?.data?.membership?.type_display
                });
                setSelectedCountry({
                    value: res?.data?.country,
                    label: res?.data?.country_name
                });
                setSelectedDelegation({
                    value: res?.data?.delegation,
                    label: res?.data?.delegation_name
                });
                setSelectedCity({value: res?.data?.city, label: res?.data?.city_name});
            } else {
                navigate(paths.error403)
            }

        })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    };

    const getIntervals = async () => {
        await api.get("/utils/interval/").then(res => setIntervals(res?.data?.results))
            .catch(() => {
            })
    }

    useEffect(() => {
        getIntervals()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getCompanies()
        // eslint-disable-next-line
    }, [pageCompany]);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageActivity.toString());
        getActivities(query)
            .then(res => setActivities([...activities, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageActivity]);

    useEffect(() => {
        getCurrencies().then(res => setCurrencies(res?.results));
    }, []);

    useEffect(() => {
        getActivity();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageCountry.toString());
        getCountries(query)
            .then(res => setCountries([...countries, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageCountry]);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageDelegation.toString());
        getDelegations(query)
            .then(res => setDelegations([...delegations, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageDelegation]);

    useEffect(() => {
        query.set('page_size', '50');
        query.set('page', pageCity.toString());
        getCities(query)
            .then(res => setCities([...cities, ...res?.results]))
            .catch(() => {
            });
        // eslint-disable-next-line
    }, [pageCity]);

    const handleSubmit = async (e, values) => {
        e.preventDefault();
        setLoading(true);
        await api
            .put(`/activity/activity/${id}/`, values)
            .then(res => {
                toast.success(`${t('title')} ${res?.data?.name} ${t('updateSuccess', {ns: "common"})}`, {
                    theme: 'colored'
                });
                navigate(paths.activityList);
            })
            .catch(error => {
                setErrors(error?.response?.data);
                setFormData(values);
                toast.error(`${t('error', {ns: "common"})}`, {theme: 'colored'});
            });
        setLoading(false);
    };

    const noOptionsMessage = ({inputValue}) => {
        const handleAddNew = () => {
            setIsSelectOpen(false);
            setPeriodForm(true);
            setSelectedInterval('');
            setPeriodFormData({...periodFormData, every: inputValue});
        };

        return (
            <>
                <div onClick={handleAddNew} style={{cursor: 'pointer'}}>
                    {t('add', {ns: "common"})} {t('new', {ns: "common"})}
                </div>
            </>
        );
    };

    const activityTypes = [
        {value: 'club', label: t('fields.type.club')},
        {value: 'org', label: t('fields.type.organization')},
        {value: 'com', label: t('fields.type.company')}
    ];

    const membershipTypes = [
        {value: 'free', label: t('fields.membership.type.free')},
        {value: 'paid', label: t('fields.membership.type.paid')}
    ];

    let activityOptions = activities?.map(activity => ({
        value: activity.id,
        label: activity.name
    }));

    activityOptions = [
        {value: '', label: '----------------------------'},
        ...activityOptions
    ];

    let countryOptions = countries?.map(country => ({
        value: country.id,
        label: country.country
    }));

    countryOptions = [
        {value: '', label: '----------------------------'},
        ...countryOptions
    ];

    let delegationOptions = delegations?.map(state => ({
        value: state.id,
        label: state.name
    }));

    delegationOptions = [
        {value: '', label: '----------------------------'},
        ...delegationOptions
    ];

    let cityOptions = cities?.map(city => ({value: city.id, label: city.name}));

    cityOptions = [
        {value: '', label: '----------------------------'},
        ...cityOptions
    ];

    let companyOptions = companies?.map(company => ({value: company.id, label: company.name}));

    companyOptions = [
        {value: '', label: '----------------------------'},
        ...companyOptions
    ];

    let intervalOptions = intervals?.map(interval => ({label: interval?.display_name, value: interval?.id}))

    intervalOptions = [
        {label: "-------------------------", value: ""},
        ...intervalOptions
    ]

    return (
        <Fragment>
            {loading ? (
                <Flex
                    justifyContent="center"
                    alignItems={'center'}
                    className="p-2 mb-2"
                >
                    <Spinner animation={'border'} variant={'primary'}/>
                </Flex>
            ) : (
                <Formik
                    initialValues={formData}
                    onSubmit={values => console.log(values)}
                >
                    {({values, setFieldValue}) => (
                        <>
                            <Form>
                                <CSRFToken/>
                                <Row className={'g-3 mt-1 mb-3'}>
                                    <Col xxl={6} xl={12}>
                                        <Row className="g-3">
                                            <Col xs={12}>
                                                <Card>
                                                    <Card.Header>
                                                        <h5 className="mb-0 text-muted">
                                                            {t('fields.basic')}
                                                        </h5>
                                                    </Card.Header>
                                                    <Card.Body className="bg-light pb-0">
                                                        <Form.Group>
                                                            <Row className="mb-3">
                                                                <Col md="auto">
                                                                    <Avatar
                                                                        size="4xl"
                                                                        src={
                                                                            isIterableArray(avatar)
                                                                                ? avatar[0]?.base64 || avatar[0]?.src
                                                                                : ''
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md>
                                                                    <Field name="logo">
                                                                        {() => (
                                                                            <FalconDropzone
                                                                                files={avatar}
                                                                                onChange={files => {
                                                                                    setAvatar(files);
                                                                                    setFieldValue('logo', files[0]);
                                                                                }}
                                                                                multiple={false}
                                                                                accept="image/*"
                                                                                placeholder={
                                                                                    <>
                                                                                        <Flex justifyContent="center">
                                                                                            <img
                                                                                                src={cloudUpload}
                                                                                                alt=""
                                                                                                width={25}
                                                                                                className="me-2"
                                                                                            />
                                                                                            <p className="fs-0 mb-0 text-700">
                                                                                                {t('fields.upload')}
                                                                                            </p>
                                                                                        </Flex>
                                                                                        <p className="mb-0 w-75 mx-auto text-400">
                                                                                            {t('fields.uploadDesc')}
                                                                                        </p>
                                                                                    </>
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.logo}/>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t('fields.name')}: <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Field name="name">
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="name"
                                                                                placeholder={t('fields.name')}
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e => {
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                value={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.name}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t('fields.email')}: <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Field name="email">
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                type="email"
                                                                                label={t('fields.email')}
                                                                                name="email"
                                                                                placeholder={t('fields.email')}
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e => {
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                value={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.email}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t('fields.phone')}: <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Field name="phone">
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                type="number"
                                                                                label={t('fields.phone')}
                                                                                name="phone"
                                                                                placeholder={t('fields.phone')}
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e => {
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                value={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.phone}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t('fields.identifier')}:</Form.Label>
                                                                    <Field name="identifier">
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                label={t('fields.identifier')}
                                                                                name="identifier"
                                                                                placeholder={t('fields.identifier')}
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e => {
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                value={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.identifier}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t('fields.rib')}:</Form.Label>
                                                                    <Field name="rib">
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                label={t('fields.rib')}
                                                                                name="rib"
                                                                                placeholder={t('fields.rib')}
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e => {
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                value={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.rib}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t('fields.responsible')}:</Form.Label>
                                                                    <Field name="responsible">
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                label={t('fields.responsible')}
                                                                                name="responsible"
                                                                                placeholder={t('fields.responsible')}
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e => {
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                value={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.responsible}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t('fields.slug')}: <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Field name="slug">
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                type="text"
                                                                                label={t('fields.slug')}
                                                                                name="slug"
                                                                                placeholder={t('fields.slug')}
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e => {
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                value={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <p className={'text-muted fs--1'}>
                                                                        {t('fields.slugDesc')}
                                                                    </p>
                                                                    <FormError error={errors.slug}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t('fields.type.title')}: <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Field name="type">
                                                                        {({field}) => (
                                                                            <Select
                                                                                options={activityTypes}
                                                                                value={selectedType}
                                                                                classNamePrefix="react-select"
                                                                                onChange={value => {
                                                                                    setSelectedType(value);
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        value.value
                                                                                    );
                                                                                }}
                                                                                placeholder={`${t('select', {ns: "common"})} ${t('fields.type.title')}`}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.type}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        {values?.type === "com" &&
                                                            <Row className={"mb-3"}>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label>{t('fields.company')}: <span
                                                                            className={"text-danger"}>*</span></Form.Label>
                                                                        <Field name="company">
                                                                            {({field}) => (
                                                                                <Select
                                                                                    options={companyOptions}
                                                                                    value={selectedCompany}
                                                                                    classNamePrefix="react-select"
                                                                                    onChange={value => {
                                                                                        setSelectedCompany(value);
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            value.value
                                                                                        );
                                                                                    }}
                                                                                    onMenuScrollToBottom={event => setPageCompany(prevState => prevState + 1)}
                                                                                    placeholder={`${t('select', {ns: "common"})} ${t('fields.company')}`}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors.company}/>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                                <Card className={'pb-3 mt-3'}>
                                                    <Card.Header>
                                                        <h5 className="mb-0 text-muted">
                                                            {t('fields.membership.title')}
                                                        </h5>
                                                    </Card.Header>
                                                    <Card.Body className="bg-light pb-0">
                                                        <Form.Group>
                                                            <Form.Label>{t('fields.membership.type.title')}: <span
                                                                className={"text-danger"}>*</span></Form.Label>
                                                            <Field name="membership.type">
                                                                {({field}) => (
                                                                    <Select
                                                                        options={membershipTypes}
                                                                        value={selectedMembershipType}
                                                                        classNamePrefix="react-select"
                                                                        onChange={value => {
                                                                            setSelectedMembershipType(value);
                                                                            setFieldValue(field.name, value.value);
                                                                        }}
                                                                        placeholder={`${t('select', {ns: "common"})} ${t('fields.membership.type.title')}`}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.membership?.type}/>
                                                        </Form.Group>
                                                        <Form.Group className={'mt-3'}>
                                                            <Field name="membership.variable">
                                                                {({field}) => (
                                                                    <Form.Switch
                                                                        label={t('fields.membership.variable')}
                                                                        name="membership.variable"
                                                                        errors={errors}
                                                                        formGroupProps={{
                                                                            className: 'mb-3'
                                                                        }}
                                                                        onChange={e =>
                                                                            setFieldValue(
                                                                                field.name,
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.membership?.variable}/>
                                                        </Form.Group>
                                                        <Form.Group className={'mt-3'}>
                                                            <Field name="membership.renewable">
                                                                {({field}) => (
                                                                    <Form.Switch
                                                                        label={t('fields.membership.renewable')}
                                                                        name="membership.renewable"
                                                                        errors={errors}
                                                                        formGroupProps={{
                                                                            className: 'mb-3'
                                                                        }}
                                                                        onChange={e =>
                                                                            setFieldValue(
                                                                                field.name,
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.membership?.renewable}/>
                                                        </Form.Group>
                                                        {values?.membership?.renewable && (
                                                            <Row className={"mb-3"}>
                                                                <Col lg={12}>
                                                                    <Form.Group>
                                                                        <Field name={"membership.period"}>
                                                                            {({field}) => (
                                                                                <Select
                                                                                    noOptionsMessage={noOptionsMessage}
                                                                                    onMenuOpen={() => setIsSelectOpen(true)}
                                                                                    onMenuClose={() => setIsSelectOpen(false)}
                                                                                    menuIsOpen={isSelectOpen}
                                                                                    options={intervalOptions}
                                                                                    value={selectedInterval}
                                                                                    onChange={newValue => {
                                                                                        setSelectedInterval(newValue)
                                                                                        setFieldValue(field.name, newValue.value)
                                                                                    }}
                                                                                    classNamePrefix={"react-select"}
                                                                                    placeholder={`${t('select', {ns: "common"})} ${t('fields.membership.period')}`}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors?.membership?.period}/>
                                                                    </Form.Group>
                                                                </Col>
                                                                {periodForm &&
                                                                    <>
                                                                        <Col lg={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>Every</Form.Label>
                                                                                <Field
                                                                                    name={"membership.period_data.every"}>
                                                                                    {({field}) => (
                                                                                        <Form.Control
                                                                                            name={"membership.period_data.every"}
                                                                                            type={"number"}
                                                                                            placeholder={"Every"}
                                                                                            value={field.value}
                                                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>Period</Form.Label>
                                                                                <Field
                                                                                    name={"membership.period_data.period"}>
                                                                                    {({field}) => (
                                                                                        <Form.Select
                                                                                            name={"membership.period_data.period"}
                                                                                            value={field.value}
                                                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                                        >
                                                                                            <option value={""}>Select
                                                                                                Period
                                                                                            </option>
                                                                                            <option
                                                                                                value={"days"}>Days
                                                                                            </option>
                                                                                            <option
                                                                                                value={"hours"}>Hours
                                                                                            </option>
                                                                                            <option
                                                                                                value={"minutes"}>Minutes
                                                                                            </option>
                                                                                            <option
                                                                                                value={"seconds"}>Seconds
                                                                                            </option>
                                                                                            <option
                                                                                                value={"microseconds"}>Microseconds
                                                                                            </option>
                                                                                        </Form.Select>
                                                                                    )}
                                                                                </Field>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </>
                                                                }
                                                            </Row>
                                                        )}
                                                        <Table
                                                            responsive
                                                            className="border-bottom"
                                                            size={'sm'}
                                                        >
                                                            <thead>
                                                            <tr>
                                                                <td>{t('fields.membership.name')} <span
                                                                    className={"text-danger"}>*</span></td>
                                                                <td>{t('fields.membership.price')} <span
                                                                    className={"text-danger"}>*</span></td>
                                                                <td>{t('fields.membership.currency')}</td>
                                                                <td>{t('fields.membership.default')}</td>
                                                                <td className={'p-0'}></td>
                                                            </tr>
                                                            </thead>
                                                            <FieldArray
                                                                name={'membership.options'}
                                                                render={arrayHelpers => (
                                                                    <>
                                                                        <tbody>
                                                                        {values?.membership &&
                                                                        values?.membership?.options?.length > 0
                                                                            ? values?.membership?.options?.map(
                                                                                (field, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>
                                                                                            <Field
                                                                                                name={`membership.options.${index}.name`}
                                                                                            >
                                                                                                {({field, form}) => (
                                                                                                    <Form.Control
                                                                                                        placeholder={t('fields.membership.name')}
                                                                                                        name={`membership.options.${index}.name`}
                                                                                                        errors={errors}
                                                                                                        formGroupProps={{
                                                                                                            className: 'mb-3'
                                                                                                        }}
                                                                                                        style={{width: 200}}
                                                                                                        onChange={e => {
                                                                                                            setFieldValue(
                                                                                                                field.name,
                                                                                                                e.target.value
                                                                                                            );
                                                                                                        }}
                                                                                                        value={field.value}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                            <FormErrors
                                                                                                errors={
                                                                                                    errors?.membership
                                                                                                        ?.options
                                                                                                }
                                                                                                error={'name'}
                                                                                                index={index}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Field
                                                                                                name={`membership.options.${index}.price`}
                                                                                            >
                                                                                                {({field, form}) => (
                                                                                                    <Form.Control
                                                                                                        type={'number'}
                                                                                                        placeholder={t('fields.membership.price')}
                                                                                                        name={`membership.options.${index}.price`}
                                                                                                        errors={errors}
                                                                                                        style={{width: 200}}
                                                                                                        formGroupProps={{
                                                                                                            className: 'mb-3'
                                                                                                        }}
                                                                                                        onChange={e => {
                                                                                                            setFieldValue(
                                                                                                                field.name,
                                                                                                                e.target.value
                                                                                                            );
                                                                                                        }}
                                                                                                        value={field.value}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                            <FormErrors
                                                                                                errors={
                                                                                                    errors?.membership
                                                                                                        ?.options
                                                                                                }
                                                                                                error={'price'}
                                                                                                index={index}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Field
                                                                                                name={`membership.options.${index}.currency`}
                                                                                            >
                                                                                                {({field}) => (
                                                                                                    <Form.Select
                                                                                                        value={field.value}
                                                                                                        classNamePrefix="react-select"
                                                                                                        onChange={e => {
                                                                                                            setFieldValue(
                                                                                                                field.name,
                                                                                                                e.target.value
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <option
                                                                                                            value={''}>
                                                                                                            {t('select', {ns: "common"})} {t('fields.membership.currency')}
                                                                                                        </option>
                                                                                                        {currencies?.map(
                                                                                                            currency => (
                                                                                                                <option
                                                                                                                    value={
                                                                                                                        currency.code
                                                                                                                    }
                                                                                                                >
                                                                                                                    {currency.name}
                                                                                                                </option>
                                                                                                            )
                                                                                                        )}
                                                                                                    </Form.Select>
                                                                                                )}
                                                                                            </Field>
                                                                                            <FormErrors
                                                                                                errors={
                                                                                                    errors?.membership
                                                                                                        ?.options
                                                                                                }
                                                                                                error={'currency'}
                                                                                                index={index}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Flex
                                                                                                justifyContent={'center'}
                                                                                                alignItems={'center'}
                                                                                                className={'mt-2'}
                                                                                            >
                                                                                                <Field
                                                                                                    name={`membership.options.${index}.default`}
                                                                                                >
                                                                                                    {({field}) => (
                                                                                                        <Form.Switch
                                                                                                            name={`membership.options.${index}.default`}
                                                                                                            errors={errors}
                                                                                                            formGroupProps={{
                                                                                                                className: 'mb-3'
                                                                                                            }}
                                                                                                            onChange={e =>
                                                                                                                setFieldValue(
                                                                                                                    field.name,
                                                                                                                    e.target.checked
                                                                                                                )
                                                                                                            }
                                                                                                            checked={field.value}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <FormErrors
                                                                                                    errors={
                                                                                                        errors?.membership
                                                                                                            ?.options
                                                                                                    }
                                                                                                    error={'default'}
                                                                                                    index={index}
                                                                                                />
                                                                                            </Flex>
                                                                                        </td>
                                                                                        <td className={'me-0 pe-0'}>
                                                                                            <Flex
                                                                                                justifyContent={'center'}
                                                                                                alignItems={'center'}
                                                                                                className={'mt-1'}
                                                                                            >
                                                                                                <IconButton
                                                                                                    variant="falcon-default"
                                                                                                    icon="trash"
                                                                                                    size={'sm'}
                                                                                                    iconClassName={'ms-1'}
                                                                                                    onClick={() => {
                                                                                                        arrayHelpers.remove(
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {' '}
                                                                                                </IconButton>
                                                                                            </Flex>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )
                                                                            : ''}
                                                                        </tbody>
                                                                        {values?.membership?.variable && (
                                                                            <tfoot>
                                                                            <tr>
                                                                                <td>
                                                                                    <IconButton
                                                                                        variant="falcon-default"
                                                                                        size="sm"
                                                                                        icon="plus"
                                                                                        transform="shrink-3"
                                                                                        onClick={() => {
                                                                                            arrayHelpers.insert(
                                                                                                values?.membership?.options?.length,
                                                                                                ''
                                                                                            );
                                                                                        }}
                                                                                    >
                                              <span className="d-none d-sm-inline-block ms-1">
                                                {t('addItem', {ns: "common"})}
                                              </span>
                                                                                    </IconButton>
                                                                                </td>
                                                                            </tr>
                                                                            </tfoot>
                                                                        )}
                                                                    </>
                                                                )}
                                                            />
                                                        </Table>

                                                        <FormErrors errors={errors.membership?.options}/>
                                                    </Card.Body>
                                                </Card>
                                                <Card className={'pb-3 mt-3'}>
                                                    <Card.Header>
                                                        <h5 className="mb-0 text-muted">{t('fields.questions.title')}</h5>
                                                        <div className="form-text mt-0">
                                                            {t('fields.questions.desc')}
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body className="bg-light pb-0">
                                                        <SimpleBarReact>
                                                            <Table
                                                                responsive
                                                                className="border-bottom"
                                                                size={'sm'}
                                                            >
                                                                <thead>
                                                                <tr>
                                                                    <td>{t('fields.questions.name')} <span
                                                                        className={"text-danger"}>*</span></td>
                                                                    <td>{t('fields.questions.description')} <span
                                                                        className={"text-danger"}>*</span></td>
                                                                    <td className={'p-0'}></td>
                                                                </tr>
                                                                </thead>
                                                                <FieldArray
                                                                    name={'questions'}
                                                                    render={arrayHelpers => (
                                                                        <>
                                                                            <tbody>
                                                                            {values && values?.questions.length > 0
                                                                                ? values?.questions?.map(
                                                                                    (field, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <Field
                                                                                                    name={`questions.${index}.name`}
                                                                                                >
                                                                                                    {({
                                                                                                          field,
                                                                                                          form
                                                                                                      }) => (
                                                                                                        <Form.Control
                                                                                                            placeholder={t('fields.questions.name')}
                                                                                                            name={`questions.${index}.name`}
                                                                                                            errors={errors}
                                                                                                            formGroupProps={{
                                                                                                                className: 'mb-3'
                                                                                                            }}
                                                                                                            onChange={e => {
                                                                                                                setFieldValue(
                                                                                                                    field.name,
                                                                                                                    e.target.value
                                                                                                                );
                                                                                                            }}
                                                                                                            value={field.value}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <FormErrors
                                                                                                    errors={errors?.questions}
                                                                                                    error={'name'}
                                                                                                    index={index}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <Field
                                                                                                    name={`questions.${index}.description`}
                                                                                                >
                                                                                                    {({
                                                                                                          field,
                                                                                                          form
                                                                                                      }) => (
                                                                                                        <Form.Control
                                                                                                            placeholder={
                                                                                                                t('fields.questions.description')
                                                                                                            }
                                                                                                            name={`questions.${index}.description`}
                                                                                                            errors={errors}
                                                                                                            formGroupProps={{
                                                                                                                className: 'mb-3'
                                                                                                            }}
                                                                                                            style={{
                                                                                                                width: '100%'
                                                                                                            }}
                                                                                                            onChange={e => {
                                                                                                                setFieldValue(
                                                                                                                    field.name,
                                                                                                                    e.target.value
                                                                                                                );
                                                                                                            }}
                                                                                                            value={field.value}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <FormErrors
                                                                                                    errors={errors?.questions}
                                                                                                    error={'description'}
                                                                                                    index={index}
                                                                                                />
                                                                                            </td>
                                                                                            <td className={'me-0 pe-0'}>
                                                                                                <Flex
                                                                                                    justifyContent={'center'}
                                                                                                    alignItems={'center'}
                                                                                                    className={'mt-1'}
                                                                                                >
                                                                                                    <IconButton
                                                                                                        variant="falcon-default"
                                                                                                        icon="trash"
                                                                                                        size={'sm'}
                                                                                                        iconClassName={'ms-1'}
                                                                                                        onClick={() => {
                                                                                                            arrayHelpers.remove(
                                                                                                                index
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                    </IconButton>
                                                                                                </Flex>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                )
                                                                                : ''}
                                                                            </tbody>
                                                                            <tfoot>
                                                                            <tr>
                                                                                <td>
                                                                                    <IconButton
                                                                                        variant="falcon-default"
                                                                                        size="sm"
                                                                                        icon="plus"
                                                                                        transform="shrink-3"
                                                                                        onClick={() => {
                                                                                            arrayHelpers.insert(
                                                                                                values?.questions.length,
                                                                                                ''
                                                                                            );
                                                                                        }}
                                                                                    >
                                              <span className="d-none d-sm-inline-block ms-1">
                                                {t('addItem', {ns: "common"})}
                                              </span>
                                                                                    </IconButton>
                                                                                </td>
                                                                            </tr>
                                                                            </tfoot>
                                                                        </>
                                                                    )}
                                                                />
                                                            </Table>
                                                            <FormErrors errors={errors.questions}/>
                                                        </SimpleBarReact>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xxl={6} xl={12}>
                                        <Card className={'mb-3'}>
                                            <CardHeader>
                                                <h5 className="mb-0 text-muted">{t('fields.status')}</h5>
                                            </CardHeader>
                                            <Card.Body>
                                                <Form.Group>
                                                    <Field name="active">
                                                        {({field}) => (
                                                            <Form.Switch
                                                                label={t('fields.active')}
                                                                name="active"
                                                                errors={errors}
                                                                formGroupProps={{
                                                                    className: 'mb-3'
                                                                }}
                                                                onChange={e =>
                                                                    setFieldValue(field.name, e.target.checked)
                                                                }
                                                                checked={field.value}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors.active}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.activity')}:</Form.Label>
                                                    <Field name="parent_activity">
                                                        {({field}) => (
                                                            <Select
                                                                options={activityOptions}
                                                                value={selectedActivity}
                                                                classNamePrefix="react-select"
                                                                onChange={value => {
                                                                    setSelectedActivity(value);
                                                                    setFieldValue(field.name, value.value);
                                                                }}
                                                                placeholder={`${t('select', {ns: "common"})} ${t('fields.activity')}`}
                                                                onMenuScrollToBottom={() => {
                                                                    setPageActivity(page => page + 1);
                                                                }}
                                                                onInputChange={e => {
                                                                    query.set('search', e);
                                                                    getActivities(query).then(r =>
                                                                        setActivities(r?.results)
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Form.Text>
                                                        {t('fields.activityText')}
                                                    </Form.Text>
                                                    <FormError error={errors.parent_activity}/>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <div className={'sticky-sidebar'}>
                                            <Card className={'mb-3'}>
                                                <CardHeader>
                                                    <h5 className="mb-0 text-muted">{t('fields.address')}</h5>
                                                </CardHeader>
                                                <Card.Body>
                                                    <Form.Label>{t('fields.address')}:</Form.Label>
                                                    <Form.Group>
                                                        <Field name="address">
                                                            {({field}) => (
                                                                <Form.Control
                                                                    label={t('fields.address')}
                                                                    name="address"
                                                                    errors={errors}
                                                                    formGroupProps={{
                                                                        className: 'mb-3'
                                                                    }}
                                                                    onChange={e =>
                                                                        setFieldValue(field.name, e.target.value)
                                                                    }
                                                                    placeholder={t('fields.address')}
                                                                    value={field.value}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors.address}/>
                                                    </Form.Group>
                                                    <Row>
                                                        <Col md={4}>
                                                            <Form.Group>
                                                                <Form.Label>{t('fields.country')}:</Form.Label>
                                                                <Field name="country">
                                                                    {({field}) => (
                                                                        <Select
                                                                            options={countryOptions}
                                                                            value={selectedCountry}
                                                                            classNamePrefix="react-select"
                                                                            onChange={value => {
                                                                                setSelectedCountry(value);
                                                                                setFieldValue(field.name, value.value);
                                                                                query.set('country_id', value.value);
                                                                                getDelegations(query).then(r =>
                                                                                    setDelegations(r?.results)
                                                                                );
                                                                            }}
                                                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.country')}`}
                                                                            onMenuScrollToBottom={() => {
                                                                                setPageCountry(page => page + 1);
                                                                            }}
                                                                            onInputChange={e => {
                                                                                query.set('search', e);
                                                                                getCountries(query).then(r =>
                                                                                    setCountries(r?.results)
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <FormError error={errors.country}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Form.Group>
                                                                <Form.Label>{t('fields.state')}:</Form.Label>
                                                                <Field name="delegation">
                                                                    {({field}) => (
                                                                        <Select
                                                                            options={delegationOptions}
                                                                            value={selectedDelegation}
                                                                            classNamePrefix="react-select"
                                                                            onChange={value => {
                                                                                setSelectedDelegation(value);
                                                                                setFieldValue(field.name, value.value);
                                                                                query.set('state_id', value.value);
                                                                                getCities(query).then(r =>
                                                                                    setCities(r?.results)
                                                                                );
                                                                            }}
                                                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.state')}`}
                                                                            onMenuScrollToBottom={() => {
                                                                                setPageDelegation(page => page + 1);
                                                                            }}
                                                                            onInputChange={e => {
                                                                                query.set('search', e);
                                                                                getDelegations(query).then(r =>
                                                                                    setDelegations(r?.results)
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <FormError error={errors.delegation}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Form.Group>
                                                                <Form.Label>{t('fields.city')}:</Form.Label>
                                                                <Field name="city">
                                                                    {({field}) => (
                                                                        <Select
                                                                            options={cityOptions}
                                                                            value={selectedCity}
                                                                            classNamePrefix="react-select"
                                                                            onChange={value => {
                                                                                setSelectedCity(value);
                                                                                setFieldValue(field.name, value.value);
                                                                            }}
                                                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.city')}`}
                                                                            onMenuScrollToBottom={() => {
                                                                                setPageCity(page => page + 1);
                                                                            }}
                                                                            onInputChange={e => {
                                                                                query.set('search', e);
                                                                                getCities(query).then(r =>
                                                                                    setCities(r?.results)
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <FormError error={errors.delegation}/>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                            <Card className={'pb-3'}>
                                                <Card.Header>
                                                    <h5 className="mb-0 text-muted">{t('fields.description')} <span
                                                        className={"text-danger"}>*</span></h5>
                                                </Card.Header>
                                                <Card.Body className="bg-light pb-0">
                                                    <Form.Group>
                                                        <Field name="description">
                                                            {({field}) => (
                                                                <TinymceEditor
                                                                    value={field.value}
                                                                    handleChange={newValue => setFieldValue(field.name, newValue)}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors.description}/>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>

                            <Card>
                                <CardHeader>
                                    <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                        <p className={"text-danger"}>* Mandatory field</p>
                                        <IconButton
                                            icon={faSave}
                                            onClick={e => handleSubmit(e, values)}
                                        >
                      <span className="d-none d-sm-inline-block ms-1">
                        {t('save', {ns: "common"})}
                      </span>
                                        </IconButton>
                                    </Flex>
                                </CardHeader>
                            </Card>
                        </>
                    )}
                </Formik>
            )}
        </Fragment>
    );
};

export default withPermission(withTranslation(['activity', 'common'])(EditActivity), "activity.change_activity");
