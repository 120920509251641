import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar, {AvatarGroup} from 'components/common/Avatar';
import {Link} from 'react-router-dom';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import {formatDateTime} from "../../../helpers/utils";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import paths from "../../../routes/paths";

const columns = [
	{
		accessor: 'title',
		Header: 'Event',
		Cell: rowData => {
			const {title, id} = rowData.row.original;
			return (
				<Flex alignItems="center" className="position-relative">
					<Avatar
						name={title}
						width="60"
						alt=""
					/>
					<div className="flex-1 ms-3">
						<h6 className="mb-0 fw-semi-bold">
							<Link className="text-dark stretched-link" to={paths.eventDetail.replace(":id", id)}>
								{title}
							</Link>
						</h6>
					</div>
				</Flex>
			);
		}
	},
	{
		accessor: 'duration',
		Header: 'Duration',
		cellProps: {
			className: 'text-center fw-semi-bold fs--1'
		},
		headerProps: {
			className: 'text-center'
		}
	},
	{
		accessor: 'created_at',
		Header: 'Date',
		cellProps: {
			className: 'text-center fw-semi-bold fs--1'
		},
		headerProps: {
			className: 'text-center'
		},
		Cell: rowData => formatDateTime(rowData.row.original.created_at),
	},
	{
		accessor: 'members',
		Header: 'Members',
		disableSortBy: true,
		headerProps: {
			className: 'text-end'
		},
		Cell: rowData => {
			return (
				<AvatarGroup className="justify-content-end">
					{rowData.row.original.members?.map(({img, name, id}) => {
						return (
							<Avatar
								src={img && img}
								key={id}
								name={name && name}
								isExact
								className="border border-3 rounded-circle border-light"
							/>
						);
					})}
				</AvatarGroup>
			);
		}
	}
];

const RunningProjects = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [count, setCount] = useState(0)

	let query = useQuery()
	query.set("page_size", "6")

	const fetchData = async (q) => {
		setLoading(true)
		await api.get(`/dashboard/runningevents/?${q.toString()}`).then(res => {
			setData(res?.data?.results)
			setCount(res?.data?.count)
		})
		setLoading(false)
	}

	useEffect(() => {
		fetchData(query)
		// eslint-disable-next-line
	}, []);

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={data}
			sortable
			pagination
			perPage={10}
		>
			<Card className="h-100">
				<FalconCardHeader title="Upcoming Events" titleTag="h6"/>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							table
							headerClassName="bg-light text-800 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								borderless: true,
								className: 'fs--2 mb-0 overflow-hidden'
							}}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						count={count}
						query={query}
						itemsPerPage={6}
						fetch={fetchData}
						length={data?.length}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
};

export default RunningProjects;
