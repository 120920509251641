import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, Form, ListGroup, Spinner} from 'react-bootstrap';
import Flex from '../../../components/common/Flex';
import CSRFToken from '../../../helpers/CSRFToken';
import VerifiedBadge from '../../../components/common/VerifiedBadge';
import SoftBadge from '../../../components/common/SoftBadge';
import IconButton from '../../../components/common/IconButton';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import useQuery from '../../../hooks/useQuery';
import {api} from '../../../utils/api';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import FormError from '../../errors/FormError';
import {withPermission} from "../../../helpers/utils";

const Emails = () => {
    const [emails, setEmails] = useState([]);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    let query = useQuery();

    const getEmails = async q => {
        setLoading(true);
        await api.get(`/account/email/?${q.toString()}`).then(res => {
            setEmails(res?.data?.results);
            setCount(res?.data?.count);
        });
        setLoading(false);
    };

    const makePrimary = async (e, id) => {
        e.preventDefault();
        setLoading(true);
        await api.post(`/account/email/${id}/make-primary/`).then(() => {
            toast.success(`Email address set as primary.`, {
                theme: 'colored'
            });
            getEmails(query);
        });
        setLoading(false);
    };

    const verifyMail = async (e, id) => {
        e.preventDefault();
        setLoading(true);
        await api.post(`/account/email/${id}/send-confirmation/`).then(() => {
            toast.success(`Email verification sent.`, {
                theme: 'colored'
            });
            getEmails(query);
        });
        setLoading(false);
    };

    const addNewEmail = async e => {
        e.preventDefault();
        setLoading(true);
        await api
            .post(`/account/email/`, {email: email})
            .then(() => {
                toast.success(`Email successfully added.`, {
                    theme: 'colored'
                });
                getEmails(query);
            })
            .catch(err => {
                toast.error(`An error has occurred.`, {
                    theme: 'colored'
                });
                setErrors(err?.response?.data);
            });
        setLoading(false);
    };

    const handleDelete = async (e, id) => {
        const {isConfirmed} = await Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/account/email/${id}/`).then(() => {
                toast.success(`Email successfully deleted.`, {
                    theme: 'colored'
                });
                getEmails(query);
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        getEmails(query);
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                        <h5>Emails</h5>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="sync-alt"
                            onClick={(e) => getEmails(query)}
                        />
                    </Flex>
                </Card.Header>
                <Card.Body>
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <ListGroup>
                            <CSRFToken/>
                            {emails?.map(email => {
                                return (
                                    <ListGroup.Item>
                                        <Flex justifyContent="between" alignItems="center">
                                            <div>
                                                {email?.email} {email?.verified && <VerifiedBadge/>}
                                            </div>
                                            <div>
                                                {email?.primary ? (
                                                    <SoftBadge pill bg="primary" className="me-2">
                                                        {email?.primary ? 'Primary' : ''}
                                                    </SoftBadge>
                                                ) : (
                                                    <Button
                                                        className={'btn-sm me-2'}
                                                        onClick={e => makePrimary(e, email?.id)}
                                                    >
                                                        Make primary
                                                    </Button>
                                                )}

                                                {email?.verified ? (
                                                    <SoftBadge pill bg="success" className="me-2">
                                                        {email?.verified ? 'Verified' : ''}
                                                    </SoftBadge>
                                                ) : (
                                                    <Button
                                                        className={'btn-sm me-2'}
                                                        onClick={e => verifyMail(e, email?.id)}
                                                    >
                                                        Verify email
                                                    </Button>
                                                )}

                                                <IconButton
                                                    icon={faTrash}
                                                    transform={'shrink-3'}
                                                    size={'sm'}
                                                    iconClassName={'text-danger'}
                                                    title={'Remove'}
                                                    className={'bg-transparent border-0'}
                                                    onClick={e => handleDelete(e, email?.id)}
                                                />
                                            </div>
                                        </Flex>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        count={count}
                        fetch={getEmails}
                        itemsPerPage={10}
                        query={query}
                        length={emails?.length}
                    />
                </Card.Footer>
            </Card>
            <Card className={'mt-3'}>
                <Form>
                    <Card.Header>
                        <h5>Add E-mail Address</h5>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control
                                type={'email'}
                                placeholder={'E-mail Address'}
                                name={'email'}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <FormError error={errors?.email}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <Button size={'sm'} variant={'primary'} onClick={addNewEmail}>
                            Add E-mail
                        </Button>
                    </Card.Footer>
                </Form>
            </Card>
        </Fragment>
    );
};

export default withPermission(Emails, "account.view_emailaddress");
