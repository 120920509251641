import React from 'react';
import {Card} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import EventScheduleItem from './EventScheduleItem';
import {FieldArray} from "formik";
import FormErrors from "../../errors/FormErrors";
import FalconCardHeader from "../../../components/common/FalconCardHeader";

const EventSchedule = ({values, setFieldValue, errors, t, handleRemove}) => {

    return (
        <Card className={"mt-3"}>
            <FalconCardHeader
                light
                title={t('fields.schedule.title')}
                titleTag="h5"
                className="py-2"
                endEl={<IconButton
                    variant="falcon-default"
                    icon="trash"
                    size={'sm'}
                    iconClassName={'ms-1 text-danger'}
                    onClick={() => handleRemove("schedules")}
                >
                    {' '}
                </IconButton>}
            />
            <Card.Body className="bg-light">
                <FieldArray name={"schedules"}
                            render={arrayHelpers => (
                                <>
                                    {values.schedules && values.schedules.length > 0 ? (
                                        values.schedules.map((field, index) => (
                                            <EventScheduleItem
                                                {...field}
                                                setFieldValue={setFieldValue}
                                                name={field.name}
                                                index={index}
                                                id={index}
                                                values={values}
                                                t={t}
                                                handleRemove={() => arrayHelpers.remove(index)}
                                            />
                                        ))
                                    ) : ""}
                                    <IconButton
                                        onClick={() => arrayHelpers.insert(values.schedules.length, '')}
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                    >
                                        {t('addItem', {ns: "common"})}
                                    </IconButton>
                                </>
                            )}
                />
                <FormErrors error={errors.schedules} index={0}/>
            </Card.Body>
        </Card>
    );
};

export default EventSchedule;
