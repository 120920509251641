import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import {api} from "../../../../utils/api";
import useQuery from "../../../../hooks/useQuery";
import IconButton from "../../../../components/common/IconButton";
import {Link} from "react-router-dom";
import CSRFToken from "../../../../helpers/CSRFToken";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MaskedInput from "react-text-mask/dist/reactTextMask";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import FormError from "../../../errors/FormError";
import SoftBadge from "../../../../components/common/SoftBadge";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

const PaymentMethodsContent = () => {
	const [loading, setLoading] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [addresses, setAddresses] = useState([])
	const [paymentMethodModal, setPaymentMethodModal] = useState(false);
	const [selectedMethod, setSelectedMethod] = useState(null)
	const [errors, setErrors] = useState({})
	const [formData, setFormData] = useState({
		address: "",
		number: "",
		expiry_month: "",
		expiry_year: "",
		cvv: "",
	})

	let query = useQuery()
	const {t} = useTranslation("common")

	const fetchPaymentMethods = async () => {
		setLoading(true)
		await api.get(`/payment/?${query.toString()}`).then(res => {
			setPaymentMethods(res?.data?.results)
			setSelectedMethod(res?.data?.results[0]?.id)
		})
		setLoading(false)
	}

	const fetchAddresses = async () => {
		setLoading(true)
		await api.get(`/address/address/?${query.toString()}`).then(res => {
			setAddresses(res?.data?.results)
		})
		setLoading(false)
	}

	const handleFieldChange = e => {
		setFormData({...formData, [e.target.name]: e.target.value})
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		await api.post("/payment/", formData)
			.then(res => {
				toast.success("Payment methods added successfully!", {theme: "colored"})
				setPaymentMethodModal(false)
				setFormData({})
				fetchPaymentMethods()
			})
			.catch(errors => {
				toast.error("An error has occurred.", {theme: "colored"});
				setErrors(errors?.response?.data)
			})
	}

	const verifyMethod = async (e) => {
		e.preventDefault()
		setLoading(true)
		await api.post("/payment/verify/", {id: selectedMethod})
			.then(res => {
				toast.success("Payment method successfully verified", {theme: "colored"})
				fetchPaymentMethods()
			})
			.catch(err => {
				toast.error("An error has occurred.", {theme: "colored"})
			})
		setLoading(false)
	}

	const handleDelete = async (e) => {
		const {isConfirmed} = await Swal.fire({
			title: t('sure', {ns: "common"}),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: t('cancel', {ns: "common"}),
			confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`
		});
		if (isConfirmed) {
			e.preventDefault();
			setLoading(true);
			await api.delete(`/payment/${selectedMethod}/`).then(() => {
				toast.success(`Method successfully deleted.`, {
					theme: 'colored'
				});
				fetchPaymentMethods()
			});
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchPaymentMethods()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		fetchAddresses()
		// eslint-disable-next-line
	}, [])

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Fragment>
			<Card>
				<Card.Header className="d-flex justify-content-end">
					<IconButton
						variant="falcon-default"
						size="sm"
						icon="trash-alt"
						transform="shrink-2"
						iconAlign="middle"
						disabled={!selectedMethod}
						onClick={handleDelete}
					>
						<span className="d-none d-md-inline-block ms-1">Delete</span>
					</IconButton>
					<IconButton
						variant="falcon-default"
						size="sm"
						icon="check"
						transform="shrink-2"
						iconAlign="middle"
						className="mx-2"
						disabled={!selectedMethod}
						onClick={verifyMethod}
					>
						<span className="d-none d-md-inline-block ms-1">Verify</span>
					</IconButton>
					<IconButton
						variant="falcon-default"
						size="sm"
						icon="plus"
						transform="shrink-2"
						iconAlign="middle"
						onClick={() => setPaymentMethodModal(true)}
					>
						<span className="d-none d-md-inline-block ms-1">New</span>
					</IconButton>
				</Card.Header>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						{paymentMethods?.map((item, index) => (
							<Col key={item.id} md={6} className="mb-3 mb-md-0">
								<Form.Check
									type="radio"
									id={`method-${item.id}`}
									className="mb-0 form-check radio-select"
								>
									<Form.Check.Input
										type="radio"
										name="method"
										defaultChecked={selectedMethod === item?.id}
										onChange={(e) => setSelectedMethod(item?.id)}
									/>
									<Form.Check.Label className="mb-0 fw-bold d-block">
										{"XXXX XXXX XXXX " + item?.number?.substring(15, 19)}
										<span className="radio-select-content">
                    <span>
	                    {item?.full_address} <br/>
	                    {item.expiry_month}/{item.expiry_year} <br/> XXX
	                    <span className="d-block mb-0 pt-2">
		                    <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
			                    <SoftBadge className="mr-2" bg={item.is_active ? "success" : "danger"} pill>
			                      {item.is_active ? "Active" : "Inactive"}
		                      </SoftBadge>
			                    <SoftBadge className="mr-2" bg={item.verified ? "success" : "danger"} pill>
			                      {item.verified ? "Verified" : "Not verified"}
		                      </SoftBadge>
		                    </Flex>
											</span>
                    </span>
                  </span>
									</Form.Check.Label>
								</Form.Check>
							</Col>
						))}
					</Row>
				</Card.Body>
			</Card>
			<Modal centered size={"lg"} onHide={() => setPaymentMethodModal(!paymentMethodModal)} show={paymentMethodModal}>
				<Modal.Header closeButton>
					<h5>Add a payment method</h5>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<CSRFToken/>
						<Form.Group className={"mb-3"}>
							<Form.Label className={"ls text-uppercase text-600 fw-semibold"}>Billing address</Form.Label>
							<Row>
								{addresses?.map((item, index) => (
									<Col key={item.id} md={6} className="mb-3 mb-md-0">
										<Form.Check
											type="radio"
											id={item?.id}
											className="mb-0 form-check radio-select"
										>
											<Form.Check.Input
												type="radio"
												name="address"
												value={item?.id}
												onChange={handleFieldChange}
												defaultChecked={index === 0}
											/>
											<Form.Check.Label className="mb-0 fw-bold d-block">
												{item.name}
												<span className="radio-select-content">
                    <span>
                      {item.address}, <br/> {item.city_name}, <br/> {item.delegation_name},{' '}
	                    {item.country_name}{' '}
                    </span>
                  </span>
											</Form.Check.Label>
										</Form.Check>
									</Col>
								))}
							</Row>
							<FormError error={errors?.address}/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label className="ls text-uppercase text-600 fw-semibold mb-0">
								Cardholder Name
							</Form.Label>
							<Form.Control
								type="text"
								onChange={handleFieldChange}
								name={"name"}
								placeholder={"Cardholder Name"}
							/>
							<FormError error={errors?.name}/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label className="ls text-uppercase text-600 fw-semibold mb-0">
								Card Number
							</Form.Label>
							<MaskedInput
								mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
								className="form-control"
								guide={false}
								placeholder="XXXX XXXX XXXX XXXX"
								name={"number"}
								onChange={handleFieldChange}
							/>
							<FormError error={errors?.number}/>
						</Form.Group>
						<Row className="align-items-center">
							<Col xs={6}>
								<Form.Label className="ls text-uppercase text-600 fw-semibold mb-0">
									Exp Date
								</Form.Label>
								<Flex>
									<MaskedInput
										mask={[/\d/, /\d/]}
										className="form-control me-3"
										guide={false}
										placeholder="MM"
										name={"expiry_month"}
										onChange={handleFieldChange}
									/>
									<MaskedInput
										mask={[/\d/, /\d/, /\d/, /\d/]}
										className="form-control"
										guide={false}
										placeholder="YYYY"
										name={"expiry_year"}
										onChange={handleFieldChange}
									/>
								</Flex>
								<Flex>
									<FormError error={errors?.expiry_month}/>
									<FormError error={errors?.expiry_year}/>
								</Flex>
							</Col>
							<Col xs={6}>
								<Form.Label className="ls text-uppercase text-600 fw-semibold mb-0">
									CVV{''}
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip style={{position: 'fixed'}} id="cvvinfo">
												Card verification value
											</Tooltip>
										}
									>
										<Link to="#!">
											<FontAwesomeIcon
												icon="question-circle"
												className="ms-2"
											/>
										</Link>
									</OverlayTrigger>
								</Form.Label>
								<MaskedInput
									mask={[/\d/, /\d/, /\d/]}
									className="form-control"
									guide={false}
									placeholder="123"
									name={"cvv"}
									onChange={handleFieldChange}
								/>
								<FormError error={errors?.cvv}/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setPaymentMethodModal(!paymentMethodModal)}>
						Close
					</Button>
					<Button variant="primary" onClick={handleSubmit}>
						Save <FontAwesomeIcon icon={faSave} className="ms-2"/>
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	)
}

export default PaymentMethodsContent