import {withPermission} from "../../../helpers/utils";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../../BaseTableHeader";
import Flex from "../../../components/common/Flex";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";

const Currencies = ({t, i18n}) => {
    const [currencies, setCurrencies] = useState([])
    const [loading, setLoading] = useState(false)
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

    const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();
	const {hasPermission} = usePermissions()

	const navigate = useNavigate();

    const fetchCurrencies = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);

		// Cancel the previous request if it exists
		await api.get(`/currency/?${q.toString()}`)
			.then(res => {
				setCurrencies(res?.data?.results);
				setCount(res?.data?.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLoading(false);
	};

    // eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchCurrencies, 600), []);

	useEffect(() => {
		fetchCurrencies(query);
		setLength(currencies.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

    const columns = [
		{
			accessor: 'name',
			Header: 'Name',
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'name' && sortingDirection,
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
            Cell: rowData => {
                const {code, name} = rowData.row.original;
                return <Link  to={paths.currencyEdit.replace(":code", code)}>{name}</Link>
            }
		},
        {
			accessor: 'symbol',
			Header: 'Symbol',
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'symbol' && sortingDirection,
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
		},
		{
			accessor: 'is_active',
			Header: "Active",
			canSort: true,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 fs-0'
			},
			sortingDirection: sortingField === 'is_active' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {is_active} = rowData.row.original;
				return (
					<SoftBadge
						pill
						bg={classNames({
							success: is_active,
							warning: !is_active
						})}
						className="d-flex flex-center"
					>
						<p className="mb-0">
							{is_active && 'Active'}
							{!is_active && 'Inactive'}
						</p>
						<FontAwesomeIcon
							icon={classNames({
								check: is_active,
								ban: !is_active
							})}
							transform="shrink-2"
							className="ms-1"
						/>
					</SoftBadge>
				);
			}
		},
        {
			accessor: 'is_base',
			Header: "Base",
			canSort: true,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 fs-0'
			},
			sortingDirection: sortingField === 'is_base' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {is_base} = rowData.row.original;
				return (
					<SoftBadge
						pill
						bg={classNames({
							success: is_base,
							warning: !is_base
						})}
						className="d-flex flex-center"
					>
						<p className="mb-0">
							{is_base && 'Base'}
							{!is_base && 'Not base'}
						</p>
						<FontAwesomeIcon
							icon={classNames({
								check: is_base,
								ban: !is_base
							})}
							transform="shrink-2"
							className="ms-1"
						/>
					</SoftBadge>
				);
			}
		},
        {
			accessor: 'is_default',
			Header: "Default",
			canSort: true,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 fs-0'
			},
			sortingDirection: sortingField === 'is_default' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {is_default} = rowData.row.original;
				return (
					<SoftBadge
						pill
						bg={classNames({
							success: is_default,
							warning: !is_default
						})}
						className="d-flex flex-center"
					>
						<p className="mb-0">
							{is_default && 'Default'}
							{!is_default && 'Not default'}
						</p>
						<FontAwesomeIcon
							icon={classNames({
								check: is_default,
								ban: !is_default
							})}
							transform="shrink-2"
							className="ms-1"
						/>
					</SoftBadge>
				);
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {code, name} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: t('sure', {ns: "common"}),
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						cancelButtonText: t('cancel', {ns: "common"}),
						confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/currency/${code}/`).then(() => {
							toast.success(`Currency ${name} ${t('deleteSuccess', {ns: "common"})}`, {
								theme: 'colored'
							});
							fetchCurrencies(query)
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("currency.change_currency") &&
								<Dropdown.Item
									onClick={() => navigate(paths.currencyEdit.replace(":code", code))}
								>
									{t('edit', {ns: "common"})}
								</Dropdown.Item>
							}
							{hasPermission("currency.delete_currency") &&
								<>
									<Dropdown.Divider as="div"/>
									<Dropdown.Item
										onClick={e => handleDelete(e)}
										className="text-danger"
									>
										{t('delete', {ns: "common"})}
									</Dropdown.Item>
								</>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	];

    const currencyList = currencies?.map(currency => ({
		code: currency.code,
		name: currency.name,
		is_active: currency.is_active,
		is_base: currency.is_base,
		is_default: currency.is_default,
		symbol: currency.symbol,
	}));

    const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

    return (
		<AdvanceTableWrapper
			columns={columns}
			data={currencyList}
			selection
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"currency.add_currency"}
						creationPath={paths.currencyCreate}
						title={"currency"}
						deletePermission={"currency.delete_currency"}
						exportPermission={"currency.add_currencyexportfile"}
						data={currencyList}
						setLoading={setLoading}
						deleteLink={"/currency/delete/currency/"}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchCurrencies}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
}

export default withPermission(withTranslation(["common"])(Currencies), "currency.view_currency")