import {formatDateTime, withPermission} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../../utils/api";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import IconButton from "../../../../components/common/IconButton";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import {usePermissions} from "../../../../providers/PermissionsProvider";
import paths from "../../../../routes/paths";
import Avatar from "../../../../components/common/Avatar";
import MemberPaymentModal from "./MemberPaymentModal";

const MemberDetail = ({t, i18n}) => {
    const [member, setMember] = useState({});
    const [loading, setLoading] = useState(false);
    const [payMembershipModal, setPayMembershipModal] = useState(false)

    const {id} = useParams();
    const navigate = useNavigate()
    const {hasPermission} = usePermissions()

    const getMember = async () => {
        setLoading(true)
        await api.get(`/member/member/${id}/`).then((response) => {
            setMember(response?.data)
        })
        setLoading(false)
    }

    useEffect(() => {
        getMember()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className={"d-print-none"}>
                <Card.Header className="d-flex flex-between-center">
                    <Flex alignItems={"center"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                        <h5 className={"ms-2"}>{member?.full_name}</h5>
                    </Flex>
                    <Flex alignItems={"center"} wrap={"wrap"}>
                        {hasPermission("accounts.view_user") &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="eye"
                                className={"ms-2"}
                                transform="shrink-3"
                                onClick={() => navigate(paths.usersDetail.replace(":id", member?.user))}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Profile</span>
                            </IconButton>
                        }
                        {!member?.paid &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon={faCreditCard}
                                className={"ms-2"}
                                transform="shrink-3"
                                onClick={() => setPayMembershipModal(!payMembershipModal)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Pay Membership</span>
                            </IconButton>
                        }
                        {hasPermission("member.change_member") &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="pencil-alt"
                                className={"ms-2"}
                                transform="shrink-3"
                                onClick={() => navigate(paths.membersEdit.replace(":id", member?.id))}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Edit</span>
                            </IconButton>
                        }
                        {hasPermission("member.delete_member") &&
                            <IconButton
                                variant="danger"
                                size="sm"
                                icon="trash-alt"
                                className={"ms-2"}
                                transform="shrink-3"
                                onClick={() => {
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Delete</span>
                            </IconButton>
                        }
                    </Flex>
                </Card.Header>
            </Card>
            <Row className={"g-3"}>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5>Member Information</h5>
                        </Card.Header>
                        <Card.Body>
                            <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                <div className={"d-flex justify-content-center"}>
                                    <Avatar src={member?.avatar} size={"4xl"} name={member?.full_name}/>
                                </div>
                                <div>
                                    <Card.Text>
                                        <strong>Name:</strong> {member?.full_name}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Role:</strong> {member?.role_name}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Date joined:</strong> {formatDateTime(member?.joined_at)}
                                    </Card.Text>
                                </div>
                            </Flex>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5>{member?.activity_data?.type_display} Information</h5>
                        </Card.Header>
                        <Card.Body>
                            <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                <div>
                                    <Card.Text>
                                        <strong>Name:</strong> {member?.activity_name}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Email:</strong> {member?.activity_data?.email}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Address:</strong> {member?.activity_data?.address}
                                    </Card.Text>
                                </div>
                                <div className={"d-flex justify-content-center"}>
                                    <Avatar src={member?.activity_data?.logo_url} size={"4xl"} name={member?.activity_name}/>
                                </div>
                            </Flex>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <MemberPaymentModal
                show={payMembershipModal}
                setShow={setPayMembershipModal}
                membership={member?.activity_data?.membership}
                providers={member?.activity_data?.providers}
            />
        </Fragment>
    )
}

export default withPermission(withTranslation(["members", "roles", "common"])(MemberDetail), "member.view_member")