import CustomDateInput from 'components/common/CustomDateInput';
import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, OverlayTrigger, Row, Spinner, Tooltip} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from "react-select";
import FormError from "../../errors/FormError";
import {capitalize} from "../../../helpers/utils";
import {Autocomplete} from "@react-google-maps/api";
import GoogleMap from "../../../components/map/GoogleMap";
import Flex from "../../../components/common/Flex";
import zoom from "assets/img/logos/zoom.png"
import gMeet from "assets/img/logos/google-meet.png"
// import webex from "assets/img/logos/webex.png"
// import eventbrite from "assets/img/logos/eventbrite.png"
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import TinymceEditor from "../../../components/common/TinymceEditor";

const EventDetails = ({values, setFieldValue, errors, t, setErrors}) => {
    const [loading, setLoading] = useState(false)
    const [selectedVenue, setSelectedVenue] = useState(values?.venue ? {
        value: values?.venue,
        label: capitalize(values?.venue)
    } : null)
    const [startDate, setStartDate] = useState(values?.start_date ? new Date(values?.start_date) : null)
    const [endDate, setEndDate] = useState(values?.end_date ? new Date(values?.end_date) : null)
    const [map, setMap] = useState(null);
    const [autoComplete, setAutoComplete] = useState(null)
    const [position, setPosition] = useState(values?.address?.geometry ? values?.address?.geometry?.location : {
        lat: -33.8688,
        lng: 151.2195
    })
    const [zoomData, setZoomData] = useState(null)
    const [googleMeetData, setGoogleMeetData] = useState(null)

    const handleChange = (name, value) => {
        setFieldValue(name, value);
    };

    const venueTypes = [
        {value: "", label: "--------------------------"},
        {value: "venue", label: t('fields.details.venue.venue')},
        {value: "online", label: t('fields.details.venue.online')},
    ]

    const onLoad = (map) => {
        setMap(map);
    };

    const onPlaceChanged = () => {
        if (map && autoComplete) {
            let markers = [];
            const place = autoComplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                console.error('Invalid place');
                return;
            }

            const icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25),
            };

            setPosition({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });

            markers.push(
                new window.google.maps.Marker({
                    map,
                    icon,
                    title: place.name,
                    position: place.geometry.location,
                }),
            );

            const bounds = new window.google.maps.LatLngBounds();
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            map.fitBounds(bounds);

            setFieldValue("address", place)
        }
    };

    const handleZoomClick = async (e) => {
        e.preventDefault()
        setLoading(true)
        await api.post('/integrations/zoom/create_zoom_meeting/', {
            title: values?.title,
            start_date: values?.start_date,
            duration: "",
            description: values?.description,
        })
            .then(res => {
                toast.success("Zoom meeting created.", {theme: "colored"})
                setZoomData(res?.data)
                setFieldValue("zoom_integration", res?.data?.id)
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
                setErrors(err?.response?.data)
            })
        setLoading(false)
    };

    const handleGoogleClick = async (e) => {
        e.preventDefault()
        setLoading(true)
        await api.get("/schedule/generate_meeting_link/").then(async res => {
            await api.post("/integrations/google/", {
                meeting_id: res?.data?.meet_id,
                join_url: res?.data?.link,
                start_time: values?.start_date,
                integration_type: "Google Meet",
                duration: new Date(values?.end_date) - new Date(values?.start_date),
            })
                .then(response => {
                    toast.success("Google meeting created.", {theme: "colored"})
                    setGoogleMeetData(response?.data)
                    setFieldValue("google_meet_integration", response?.data?.id)
                })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
                setErrors(err?.response?.data)
            })
        setLoading(false)
    };

    useEffect(() => {
        if (values?.google_meet_integration) {
            setGoogleMeetData(values?.google_meet_integration_data)
        }
        if (values?.zoom_integration) {
            setZoomData(values?.zoom_integration_data)
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Card className="mb-3">
            <Card.Header as="h5">{t('fields.details.title')}</Card.Header>
            <Card.Body className="bg-light">
                <Row className="gx-2 gy-3">
                    <Col md="6">
                        <Form.Group controlId="title">
                            <Form.Label>{t('fields.details.eventTitle')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder={t('fields.details.eventTitle')}
                                value={values.title}
                                onChange={e => handleChange('title', e.target.value)}
                            />
                        </Form.Group>
                        <FormError error={errors.title}/>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="title">
                            <Form.Label>{t('fields.details.subtitle')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="subtitle"
                                placeholder={t('fields.details.subtitle')}
                                value={values.subtitle}
                                onChange={e => handleChange('subtitle', e.target.value)}
                            />
                        </Form.Group>
                        <FormError error={errors.subtitle}/>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="start_date">
                            <Form.Label>{t('fields.details.start_date')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Row>
                                <DatePicker
                                    selected={startDate}
                                    onChange={newDate => {
                                        setStartDate(newDate)
                                        handleChange('start_date', new Date(newDate).toISOString());
                                    }}
                                    showTimeSelect
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    fixedHeight
                                    className='form-control'
                                    customInput={
                                        <CustomDateInput
                                            formControlProps={{
                                                placeholder: 'MM/dd/yyyy h:mm aa',
                                            }}
                                        />
                                    }
                                />
                            </Row>
                        </Form.Group>
                        <FormError error={errors.start_date}/>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="end_date">
                            <Form.Label>{t('fields.details.end_date')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Row>
                                <DatePicker
                                    selected={endDate}
                                    onChange={newDate => {
                                        setEndDate(newDate)
                                        handleChange('end_date', new Date(newDate).toISOString());
                                    }}
                                    showTimeSelect
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    fixedHeight
                                    className='form-control'
                                    customInput={
                                        <CustomDateInput
                                            formControlProps={{
                                                placeholder: 'MM/dd/yyyy h:mm aa',
                                                name: 'end_date',
                                            }}
                                        />
                                    }
                                />
                            </Row>
                        </Form.Group>
                        <FormError error={errors.end_date}/>
                    </Col>
                    <Col md="12">
                        <Form.Group controlId="venue">
                            <Form.Label>{t('fields.details.venue.venue')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Select
                                options={venueTypes}
                                type="text"
                                placeholder={t('fields.details.venue.venue')}
                                name="venue"
                                classNamePrefix={"react-select"}
                                value={selectedVenue}
                                onChange={(newValue) => {
                                    setSelectedVenue(newValue)
                                    handleChange("venue", newValue.value)
                                }}
                            />
                        </Form.Group>
                        <FormError error={errors.venue}/>
                    </Col>

                    <Col md="12">
                        <div className="border-dashed border-bottom"></div>
                    </Col>
                    {values?.venue === "online" && (
                        <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                            {loading ? (
                                <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
                                    <Spinner animation={'border'} variant={'primary'}/>
                                </Flex>
                            ) : (
                                <>
                                    <OverlayTrigger
                                        key="left"
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip style={{position: "fixed"}} id="ThemeColor">
                                                Zoom
                                            </Tooltip>
                                        }
                                    >
                                        <Button variant="outline-primary" size={"sm"} onClick={handleZoomClick}>
                                            <img src={zoom} alt={"zoom logo"} width={"50"} height={"50"}/>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        key="left"
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip style={{position: "fixed"}} id="ThemeColor">
                                                Google Meet
                                            </Tooltip>
                                        }
                                    >
                                        <Button variant="outline-success" onClick={handleGoogleClick}>
                                            <img src={gMeet} alt={"google meet logo"} width={"50"} height={"50"}/>
                                        </Button>
                                    </OverlayTrigger>
                                    {/*<OverlayTrigger*/}
                                    {/*    key="left"*/}
                                    {/*    placement={"bottom"}*/}
                                    {/*    overlay={*/}
                                    {/*        <Tooltip style={{position: "fixed"}} id="ThemeColor">*/}
                                    {/*            Cisco Webex*/}
                                    {/*        </Tooltip>*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    <Button variant="outline-primary">*/}
                                    {/*        <img src={webex} alt={"cisco webex logo"} height={"50"}/>*/}
                                    {/*    </Button>*/}
                                    {/*</OverlayTrigger>*/}
                                    {/*<OverlayTrigger*/}
                                    {/*    key="left"*/}
                                    {/*    placement={"bottom"}*/}
                                    {/*    overlay={*/}
                                    {/*        <Tooltip style={{position: "fixed"}} id="ThemeColor">*/}
                                    {/*            Eventbrite*/}
                                    {/*        </Tooltip>*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    <Button variant="outline-warning">*/}
                                    {/*        <img src={eventbrite} alt={"eventbrite logo"} height={"50"}/>*/}
                                    {/*    </Button>*/}
                                    {/*</OverlayTrigger>*/}
                                </>
                            )}
                        </Flex>
                    )}
                    {zoomData && (
                        <div>
                            <h5>{zoomData?.integration_type}</h5>
                            <p>Meeting ID: {zoomData?.meeting_id}</p>
                            <p>Join Link: <a href={zoomData?.join_url} target={"_blank"} rel={"noreferrer"}>Click here
                                to join</a></p>
                        </div>
                    )}
                    {googleMeetData && (
                        <div>
                            <h5>{googleMeetData?.integration_type}</h5>
                            <p>Meeting ID: {googleMeetData?.meeting_id}</p>
                            <p>Meeting Link: <a href={googleMeetData?.join_url} target={"_blank"} rel={"noreferrer"}>Click
                                here
                                to join</a>
                            </p>
                        </div>
                    )}
                    <FormError error={errors?.message}/>
                    {values?.venue === "venue" && (
                        <>
                            <Col md={12}>
                                <GoogleMap
                                    initialCenter={position}
                                    onLoad={onLoad}
                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '100vh'
                                    }}
                                    extras={
                                        <Autocomplete
                                            onLoad={autocomplete => {
                                                setAutoComplete(autocomplete)
                                            }}
                                            onPlaceChanged={onPlaceChanged}
                                        >
                                            <input
                                                type="text"
                                                placeholder={`${t('location')} *`}
                                                style={{
                                                    boxSizing: `border-box`,
                                                    border: `1px solid transparent`,
                                                    width: `240px`,
                                                    height: `32px`,
                                                    padding: `0 12px`,
                                                    borderRadius: `3px`,
                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    fontSize: `14px`,
                                                    outline: `none`,
                                                    textOverflow: `ellipses`,
                                                    position: "absolute",
                                                    left: "50%",
                                                    marginLeft: "-120px"
                                                }}
                                            />
                                        </Autocomplete>
                                    }
                                >
                                </GoogleMap>
                            </Col>
                        </>
                    )}
                    <Col md="12">
                        <Form.Group controlId="description">
                            <Form.Label>{t('fields.details.description')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <TinymceEditor
                                value={values?.description}
                                handleChange={newValue => setFieldValue("description", newValue)}
                            />
                        </Form.Group>
                        <FormError error={errors.description}/>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
export default EventDetails;
