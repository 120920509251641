import {formatDateTime, withPermission} from "../../../../../helpers/utils";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../../../utils/api";
import {Card, Col, Row, Spinner, Form, Table} from "react-bootstrap";
import Flex from "../../../../../components/common/Flex";
import IconButton from "../../../../../components/common/IconButton";
import paths from "../../../../../routes/paths";

const SponsorDetail = () => {
    const [sponsorData, setSponsorData] = useState({});
    const [loading, setLoading] = useState(false);
    const [roi, setROI] = useState(null);

    const {id} = useParams();
    const navigate = useNavigate()

    const calculateROI = (data) => {
        const {amount, eventmetric_set} = data;
        const investmentCost = parseFloat(amount);

        // Calculate total benefits from metrics
        let totalBenefits = 0;
        eventmetric_set?.forEach(metric => {
            totalBenefits += metric?.value || 0;
        });

        const netProfit = totalBenefits - investmentCost;
        const roiValue = (netProfit / investmentCost) * 100;
        setROI(roiValue.toFixed(2));
    };

    const fetchSponsorData = async () => {
        setLoading(true)
        await api.get(`/sponsor/sponsor/${id}/`).then(response => {
            setSponsorData(response?.data)
            calculateROI(response.data);
        })
        setLoading(false)
    };

    useEffect(() => {
        fetchSponsorData()
        // eslint-disable-next-line
    }, []);

    const {
        company_name,
        activity_name,
        event_title,
        note,
        amount,
        currency,
        level_display,
        type_display,
        start_date,
        end_date,
        material_set,
        right_set,
        eventmetric_set
    } = sponsorData;

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className={"d-print-none mb-3"}>
                <Card.Header className="d-flex flex-between-center">
                    <Flex alignItems={"center"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                    </Flex>
                    <Flex alignItems={"center"}>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="pencil-alt"
                            className={"ms-2"}
                            transform="shrink-3"
                            onClick={() => navigate(paths.editSponsors.replace(":id", id))}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Edit</span>
                        </IconButton>
                    </Flex>
                </Card.Header>
            </Card>
            <Row>
                <Col lg={6}>
                    <div className={"sticky-sidebar"}>
                        <Card className="mb-3">
                            <Card.Header as="h5">Sponsor Information</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="company">
                                            <Form.Label>Company</Form.Label>
                                            <Form.Control type="text" readOnly value={company_name}/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="activity">
                                            <Form.Label>Activity</Form.Label>
                                            <Form.Control type="text" readOnly value={activity_name}/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="event">
                                            <Form.Label>Event</Form.Label>
                                            <Form.Control type="text" readOnly value={event_title}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Header as="h5">Notes</Card.Header>
                            <Card.Body>
                                <Form.Control as="textarea" rows={3} readOnly value={note}/>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Header as="h5">ROI Metrics</Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Metric</th>
                                        <th>Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {eventmetric_set?.map(metric => (
                                        <tr key={metric?.id}>
                                            <td>{metric?.metric_name_display}</td>
                                            <td>{metric?.value}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                <h5 className="mt-3">ROI: {roi}%</h5>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col lg={6}>
                    <Card className="mb-3">
                        <Card.Header as="h5">Sponsorship Information</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="type">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control type="text" readOnly value={type_display}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="level">
                                        <Form.Label>Level</Form.Label>
                                        <Form.Control type="text" readOnly value={level_display}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="amount">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control type="text" readOnly value={amount}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="start_date">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control type="text" readOnly value={formatDateTime(start_date)}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="end_date">
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control type="text" readOnly value={formatDateTime(end_date)}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="currency">
                                        <Form.Label>Currency</Form.Label>
                                        <Form.Control type="text" readOnly value={currency}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Header as="h5">Materials</Card.Header>
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>File</th>
                                </tr>
                                </thead>
                                <tbody>
                                {material_set?.map(material => (
                                    <tr key={material?.id}>
                                        <td>{material?.name}</td>
                                        <td>{material?.upload_date}</td>
                                        <td>{material?.material_type_display}</td>
                                        <td>
                                            <a href={material?.file_url} target="_blank" rel="noopener noreferrer">
                                                Download
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Header as="h5">Rights</Card.Header>
                        <Card.Body>
                            <ul>
                                {right_set?.map(right => (
                                    <li key={right?.id}>{right?.name}</li>
                                ))}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default withPermission(SponsorDetail, "sponsor.view_sponsor")