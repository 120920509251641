import React, {useEffect, useRef, useState} from 'react';
import KanbanColumn from './KanbanColumn';
import AddAnotherForm from './AddAnotherForm';
import {DragDropContext} from 'react-beautiful-dnd';
import IconButton from 'components/common/IconButton';
import is from 'is_js';
import {api} from "../../../utils/api";
import {Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import useQuery from "../../../hooks/useQuery";
import CSRFToken from "../../../helpers/CSRFToken";
import {useNavigate} from "react-router-dom";

const KanbanContainer = ({event}) => {
    const [kanbanItems, setKanbanItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [kanbanModal, setKanbanModal] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [errors, setErrors] = useState({})
    const containerRef = useRef(null);

    const query = useQuery()
    const navigate = useNavigate()

    const getKanbanStatus = async (q) => {
        setLoading(true)
        await api.get(`/task/status/?${q.toString()}`).then(res => setKanbanItems(res?.data.results))
        setLoading(false)
    }

    useEffect(() => {
        query.set("event", event?.id)
        getKanbanStatus(query)
        navigate(`?${query.toString()}`)
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async listData => {
        await api.post("/task/status/", {name: listData.title, event: event?.id})
            .catch(error => {
                setErrors(error?.response?.data)
            })
        const isEmpty = !Object.keys(listData).length;

        if (!isEmpty) {
            getKanbanStatus(query)
            setShowForm(false);
        }
    };

    useEffect(() => {
        if (is.ipad()) {
            containerRef.current.classList.add('ipad');
        }

        if (is.mobile()) {
            containerRef.current.classList.add('mobile');
            if (is.safari()) {
                containerRef.current.classList.add('safari');
            }
            if (is.chrome()) {
                containerRef.current.classList.add('chrome');
            }
        }
    }, []);

    const getColumn = id => {
        return kanbanItems?.find(item => item.id === Number(id));
    };

    const reorderArray = (array, fromIndex, toIndex) => {
        const newArr = [...array];

        const chosenItem = newArr.splice(fromIndex, 1)[0];
        newArr.splice(toIndex, 0, chosenItem);

        newArr.forEach((item, index) => {
            item.order = index;
            item.labels = item.label_set;
            item.organizers = item.organizer_set;
        });

        return newArr;
    };

    const move = (source, destination) => {
        const sourceItemsClone = [...getColumn(source.droppableId).task_set];
        const destItemsClone = [...getColumn(destination.droppableId).task_set];

        const [removedItem] = sourceItemsClone.splice(source.index, 1);
        destItemsClone.splice(destination.index, 0, removedItem);

        sourceItemsClone.forEach((item, index) => {
            item.order = index;
            item.status = source.droppableId
        });

        destItemsClone.forEach((item, index) => {
            item.order = index;
            item.status = destination.droppableId
        });

        return {
            updatedDestItems: destItemsClone,
            updatedSourceItems: sourceItemsClone
        };
    };

    const handleDragEnd = async result => {
        const {source, destination} = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = getColumn(source.droppableId).task_set;
            const reorderedItems = reorderArray(
                items,
                source.index,
                destination.index
            );

            for (const item of reorderedItems) {
                await api.patch(`/task/tasks/${item.id}/`, {order: item.order})
            }
            await getKanbanStatus(query)
        } else {
            const movedItems = move(source, destination);

            for (const item of movedItems.updatedSourceItems) {
                await api.patch(`/task/tasks/${item.id}/`, {order: item.order, status: source.droppableId})
            }

            for (const item of movedItems.updatedDestItems) {
                await api.patch(`/task/tasks/${item.id}/`, {order: item.order, status: destination.droppableId})
            }

            await getKanbanStatus(query)
        }
    };

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
            <CSRFToken/>
            <div className="kanban-container me-n3 scrollbar" ref={containerRef}>
                {kanbanItems?.map(kanbanColumnItem => (
                    <KanbanColumn
                        setKanbanModal={setKanbanModal}
                        kanbanModal={kanbanModal}
                        getKanbanStatus={getKanbanStatus}
                        key={kanbanColumnItem.id}
                        kanbanColumnItem={kanbanColumnItem}
                    />
                ))}
                <div className="kanban-column">
                    <AddAnotherForm
                        type="list"
                        onSubmit={handleSubmit}
                        showForm={showForm}
                        setShowForm={setShowForm}
                        errors={errors}
                    />
                    {!showForm && (
                        <IconButton
                            variant="secondary"
                            className="d-block w-100 border-400 bg-400"
                            icon="plus"
                            iconClassName="me-1"
                            onClick={() => setShowForm(true)}
                        >
                            Add another list
                        </IconButton>
                    )}
                </div>
            </div>
        </DragDropContext>
    );
};

export default KanbanContainer;
