import React, {forwardRef} from 'react';
import {Card} from 'react-bootstrap';

const Liability = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="liability" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Liability</h5>
			</Card.Header>
			<Card.Body>
				<h6>Your Consent</h6>
				<p className="mb-0 ps-3">
					We've updated our Privacy Policy to provide you with complete transparency into what is being set when you
					visit our site and how it's being used. By using our nexameet, registering an account, or making a purchase,
					you hereby consent to our Privacy Policy and agree to its terms.
				</p>
				<hr className="my-4"/>
				<h6>Links to Other Websites</h6>
				<p className="mb-0 ps-3">
					This Privacy Policy applies only to the Services. The Services may contain links to other websites not
					operated or controlled by nexameet. We are not responsible for the content, accuracy or opinions expressed
					in such websites, and such websites are not investigated, monitored or checked for accuracy or
					completeness by us. Please remember that when you use a link to go from the Services to another website,
					our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those
					that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may
					use their own cookies or other methods to collect information about you.
				</p>
				<hr className="my-4"/>
				<h6>Cookies</h6>
				<p className="mb-0 ps-3">
					Nexameet uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece
					of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the
					performance and functionality of our website but are non-essential to their use. However, without these
					cookies, certain functionality like videos may become unavailable or you would be required to enter your
					login details every time you visit the website as we would not be able to remember that you had logged in
					previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies,
					you may not be able to access functionality on our website correctly or at all. We never place Personally
					Identifiable Information in Cookies.
				</p>
				<hr className="my-4"/>
				<h6>Blocking and disabling cookies and similar technologies</h6>
				<p className="mb-0 ps-3">
					Wherever you're located you may also set your browser to block cookies and similar technologies, but this
					action may block our essential cookies and prevent our website from functioning properly, and you may not
					be able to fully utilize all of its features and services. You should also be aware that you may also lose
					some
					saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different
					browsers make different controls available to you. Disabling a cookie or category of cookie does not delete
					the cookie from your browser, you will need to do this yourself from within your browser, you should visit
					your
					browser's help menu for more information.
				</p>
				<hr className="my-4"/>
				<h6>Payment Details</h6>
				<p className="mb-0 ps-3">
					In respect to any credit card or other payment processing details you have provided us, we commit that this
					confidential information will be stored in the most secure manner possible.
				</p>
				<hr className="my-4"/>
				<h6>Kids' Privacy</h6>
				<p className="mb-0 ps-3">
					We do not address anyone under the age of 13. We do not knowingly collect personally identifiable
					information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your
					child has provided Us with Personal Data, please contact Us. If We become aware that We have collected
					Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to
					remove that information from Our servers.
				</p>
				<hr className="my-4"/>
				<h6>Changes To Our Privacy Policy</h6>
				<p className="mb-0 ps-3">
					We may change our Service and policies, and we may need to make changes to this Privacy Policy so that
					they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for
					example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to
					review them before they go into effect. Then, if you continue to use the Service, you will be bound by the
					updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete
					your account.
				</p>
				<hr className="my-4"/>
				<h6>Third-Party Services</h6>
				<p className="mb-0 ps-3">
					We may display, include or make available third-party content (including data, information, applications and
					other products services) or provide links to third-party websites or services ("Third- Party Services").
				</p>
				<p className="mb-0 ps-3 mt-3">
					You acknowledge and agree that nexameet shall not be responsible for any Third-Party Services, including
					their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any
					other aspect thereof. Nexameet does not assume and shall not have any liability or responsibility to you or
					any other person or entity for any Third-Party Services.
				</p>
				<p className="mb-0 ps-3 mt-3">
					Third-Party Services and links thereto are provided solely as a convenience to you and you access and use
					them entirely at your own risk and subject to such third parties' terms and conditions.
				</p>
				<hr className="my-4"/>
				<h6>Tracking Technologies</h6>
				<ul type="1">
					<li>
						Google Maps API:
						<p className="mb-0 ps-3 mt-3">
							Google Maps API is a robust tool that can be used to create a custom map, a searchable map, check-in
							functions, display live data syncing with location, plan routes, or create a mashup just to name a few.
						</p>
						<p className="mb-0 ps-3 mt-3">
							Google Maps API may collect information from You and from Your Device for security purposes.
						</p>
						<p className="mb-0 ps-3 mt-3">
							Google Maps API collects information that is held in accordance with its Privacy Policy
						</p>
					</li>
					<li>
						Cookies
						<p className="mb-0 ps-3 mt-3">
							We use Cookies to enhance the performance and functionality of our platform but are non-essential to
							their use. However, without these cookies, certain functionality like videos may become unavailable or
							you would be required to enter your login details every time you visit the platform as we would not be
							able to remember that you had logged in previously.
						</p>
					</li>
					<li>
						Local Storage
						<p className="mb-0 ps-3 mt-3">
							Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for
							storing client-side data. Web storage supports persistent data storage, similar to cookies but with a
							greatly enhanced capacity and no information stored in the HTTP request header.
						</p>
					</li>
					<li>
						Sessions
						<p className="mb-0 ps-3 mt-3">
							We use "Sessions" to identify the areas of our platform that you have visited. A Session is a small piece
							of data stored on your computer or mobile device by your web browser.
						</p>
					</li>
				</ul>
				<hr className="my-4"/>
				<h6>Indemnity</h6>
				<p className="mb-0 ps-3">
					You shall defend, indemnify and hold us harmless against claims,
					actions, proceedings, losses, damages, expenses and costs (including
					without limitation court costs and reasonable legal fees) arising out
					of or in connection with your use of the Service (or us taking any
					action in relation to the Service at your direction), including any
					claim or action from a recipient of any Content sent by means of the
					Service.
				</p>
				<hr className="my-4"/>
				<p className="fs-9 mb-0 fw-semibold">
					Our responsibility for loss or damage if you are a business:
				</p>
				<ol type="1">
					<li>arising under or in connection with these Terms;</li>
					<li>in respect of any use made by you of the Service; and</li>
					<li>
						in respect of any representation, statement or tortious act or
						omission (including negligence) arising under or in connection with
						these Terms.
					</li>
				</ol>
				<hr className="my-4"/>
				<p className="fs-9 mb-0 fw-semibold">
					Except as expressly and specifically provided in these Terms:
				</p>
				<ol type="1">
					<li>
						you assume sole responsibility for results obtained from your use of
						the Service, and for conclusions drawn from such use. We shall have
						no liability for any damage caused by errors or omissions in any
						information, instructions or scripts provided to us by you in
						connection with the Service, or any actions taken by us at your
						direction;
					</li>
					<li>
						all warranties, representations, conditions and all other terms of
						any kind whatsoever implied by statute or common law are, to the
						fullest extent permitted by applicable law, excluded from these
						Terms; and
					</li>
					<li>the Service is provided to you on an “as is” basis.</li>
				</ol>
				<hr className="my-4"/>
				<p className="fs-9 mb-0 fw-semibold">
					Nothing in these Terms excludes our liability:
				</p>
				<ol type="1">
					<li>for death or personal injury caused by our negligence; or</li>
					<li>for fraud or fraudulent misrepresentation.</li>
				</ol>
				<hr className="my-4"/>
				<p className="fs-9 mb-0 fw-semibold">Force majeur</p>
				<p className="mb-0 ps-3">
					We won’t be held liable for any delays or failure in performance of
					any part of the Service, from any cause beyond our control. This
					includes, but is not limited to, changes to law or regulations,
					embargoes, fires, earthquakes, floods, strikes, power blackouts,
					unusually severe weather conditions, and acts of hackers or
					third-party internet service providers.
				</p>
			</Card.Body>
		</Card>
	);
});

export default Liability;
