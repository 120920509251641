import React from 'react';
import bgImg from 'assets/img/generic/20.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import PasswordResetForm from "../PasswordResetForm";
import {withTranslation} from "react-i18next";

const PasswordReset = ({t, i18n}) => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <h3>{t('reset.title')}</h3>
      </div>
      <PasswordResetForm layout="split" hasLabel t={t} i18n={i18n} />
    </AuthSplitLayout>
  );
};

export default withTranslation(["authentication", "common"])(PasswordReset);
