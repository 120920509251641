import Members from './Members';
import Events from './Events';
import {Col, Row, Spinner} from 'react-bootstrap';
import ProfileIntro from './ProfileIntro';
import ProfileBanner from './Banner';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {api} from '../../../../utils/api';
import Flex from '../../../../components/common/Flex';
import {withPermission} from '../../../../helpers/utils';
import paths from "../../../../routes/paths";
import {useAppContext} from "../../../../providers/AppProvider";
import useQuery from "../../../../hooks/useQuery";
import {useTranslation} from "react-i18next";
import {getActivity} from "../../actions/Activity";

const DetailActivity = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [join, setJoin] = useState({});
	const [members, setMembers] = useState([])

	const {
		setConfig
	} = useAppContext()
	const navigate = useNavigate()
	const {id} = useParams();
	let query = useQuery()

	const { t } = useTranslation("common")

	const fetchActivity = async () => {
		setLoading(true);
		getActivity(id).then(res => setData(res))
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", false);
			});
		setLoading(false);
	};

	const getMembers = async () => {
		query.set("activity", id)
		await api.get(`/member/member/?${query.toString()}`).then(res => setMembers(res?.data?.results))
			.catch(() => {})
	}

	const createJoinRequest = async () => {
		setLoading(true);
		query.set("activity", id)
		await api
			.get(`/join/join/?${query.toString()}`)
			.then(res => setJoin(res?.data?.results[0]))
			.catch(() => {})
		setLoading(false);
	};
	useEffect(() => {
		fetchActivity();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		createJoinRequest();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getMembers();
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<>
			<ProfileBanner data={data} join={join}/>
			<Row className="g-3 mb-3">
				<Col lg={8}>
					<ProfileIntro data={data}/>
				</Col>
				<Col lg={4}>
					<div className="sticky-sidebar">
						<Events
							className="mb-3"
							cardTitle={t('titles.event')}
							events={data?.events?.slice(2, 5)}
							id={data?.id}
						/>
					</div>
				</Col>
			</Row>
			<Members
				totalMembers={data?.count_members ? data?.count_members : 0}
				members={members?.slice(0, 12)}
			/>
		</>
	);
};

export default withPermission(DetailActivity, "activity.view_activity");
