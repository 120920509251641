import {Button, Card, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import IconButton from "../../../components/common/IconButton";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";
import SimpleBarReact from "simplebar-react";
import {formatDate, getRandomColor, rgbaColor, tooltipFormatter} from "../../../helpers/utils";
import {useAppContext} from "../../../providers/AppProvider";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from 'echarts/core';
import CSRFToken from "../../../helpers/CSRFToken";
import {format} from "date-fns";
import DatePicker from 'react-datepicker';
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import FormError from "../../errors/FormError";
import InvoiceUploadModal from "./InvoiceUploadModal";

const EventFinance = () => {
    const [event, setEvent] = useState({});
    const [loading, setLoading] = useState(false);
    const [incomes, setIncomes] = useState([])
    const [expenses, setExpenses] = useState([])
    const [expenseIncomeModal, setExpenseIncomeModal] = useState(false)
    const [type, setType] = useState("income");
    const [currencies, setCurrencies] = useState([])
    const [date, setDate] = useState(null);
    const [modalLoading, setModalLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false)
    const [modalInvoiceLoading, setModalInvoiceLoading] = useState(false);
    const [expenseChartData, setExpenseChartData] = useState([])
    const [incomeChartData, setIncomeChartData] = useState([])
    const [formData, setFormData] = useState({
        date: "",
        amount: "",
        description: "",
        currency: "",
        category: "",
    })

    const {getThemeColor} = useAppContext();
    const navigate = useNavigate();
    const {id} = useParams()
    const chartRef = useRef(null)
    let query = useQuery()
    const {
        config: {currency}
    } = useAppContext()

    const fetchEvent = async () => {
        setLoading(true);
        await api.get(`/event/event/${id}/`).then(res => setEvent(res?.data))
        setLoading(false)
    }

    const fetchCurrencies = async () => {
        await api.get('/currency/').then(res => setCurrencies(res?.data?.results))
    }

    query.set("event", id)

    const fetchIncomes = async () => {
        setLoading(true);
        await api.get(`/event/income/?${query.toString()}`).then(res => setIncomes(res?.data?.results))
        setLoading(false)
    }

    const fetchIncomesChart = async () => {
        setLoading(true);
        await api.get(`/event/income/chart/?${query.toString()}`).then(res => setIncomeChartData(res?.data))
        setLoading(false)
    }

    const fetchExpenses = async () => {
        setLoading(true);
        await api.get(`/event/expense/?${query.toString()}`).then(res => setExpenses(res?.data?.results))
        setLoading(false)
    }

    const fetchExpensesChart = async () => {
        setLoading(true);
        await api.get(`/event/expense/chart/?${query.toString()}`).then(res => setExpenseChartData(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        fetchEvent()
        // eslint-disable-next-line
    }, [currency]);


    useEffect(() => {
        fetchIncomes()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchIncomesChart()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchExpenses()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchExpensesChart()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchCurrencies()
        // eslint-disable-next-line
    }, [currency]);

    const getOption = () => ({
        tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            backgroundColor: getThemeColor('gray-100'),
            borderColor: getThemeColor('gray-300'),
            textStyle: {color: getThemeColor('gray-1100')},
            borderWidth: 1,
            formatter: tooltipFormatter,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        },
        xAxis: {
            type: 'category',
            data: ['Income', 'Expenses'],
            axisLine: {
                lineStyle: {
                    color: getThemeColor('gray-300'),
                    type: 'solid'
                }
            },
            axisTick: {show: false},
            axisLabel: {
                color: getThemeColor('gray-400'),
                formatter: value => value.substring(0, 3),
                margin: 15
            },
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                show: true,
                color: getThemeColor('gray-400'),
                margin: 15
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: getThemeColor('gray-200')
                }
            },
            axisTick: {show: false},
            axisLine: {show: false},
            min: 600
        },
        series: [
            {
                type: 'bar',
                name: 'Total',
                data: [event?.total_income, event?.total_expenses],
                lineStyle: {color: getThemeColor('primary')},
                itemStyle: {
                    color: getThemeColor('primary'),
                    borderRadius: [3, 3, 0, 0]
                },
                showSymbol: false,
                symbol: 'circle',
                smooth: false,
                emphasis: {
                    scale: true
                }
            }
        ],
        grid: {right: '3%', left: '10%', bottom: '10%', top: '5%'}
    });

    const getExpenseOption = () => ({
        legend: {
            left: 'left',
            textStyle: {
                color: getThemeColor('gray-600')
            }
        },
        series: [
            {
                type: 'pie',
                radius: window.innerWidth < 530 ? '45%' : '60%',
                label: {
                    color: getThemeColor('gray-700')
                },
                center: ['50%', '55%'],
                data: expenseChartData?.filter(expense => expense?.amount !== null)?.map((expense) => ({
                    value: expense?.amount,
                    name: expense?.category,
                    itemStyle: {
                        color: getRandomColor()
                    }
                })),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: rgbaColor(getThemeColor('gray-600'), 0.5)
                    }
                }
            }
        ],
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getThemeColor('gray-100'),
            borderColor: getThemeColor('gray-300'),
            textStyle: {color: getThemeColor('gray-1100')},
            borderWidth: 1,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        }
    });

    const getIncomeOption = () => ({
        legend: {
            left: 'left',
            textStyle: {
                color: getThemeColor('gray-600')
            }
        },
        series: [
            {
                type: 'pie',
                radius: window.innerWidth < 530 ? '45%' : '60%',
                label: {
                    color: getThemeColor('gray-700')
                },
                center: ['50%', '55%'],
                data: incomeChartData?.filter(expense => expense?.amount !== null)?.map((income) => ({
                    value: income?.amount,
                    name: income?.category,
                    itemStyle: {
                        color: getRandomColor()
                    }
                })),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: rgbaColor(getThemeColor('gray-600'), 0.5)
                    }
                }
            }
        ],
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getThemeColor('gray-100'),
            borderColor: getThemeColor('gray-300'),
            textStyle: {color: getThemeColor('gray-1100')},
            borderWidth: 1,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        }
    });

    const updateDimensions = () => {
        if (window.innerWidth < 530) {
            chartRef.current.getEchartsInstance().setOption({
                series: [{radius: '45%'}]
            });
        } else
            chartRef.current.getEchartsInstance().setOption({
                series: [{radius: '60%'}]
            });
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const handleExport = async e => {
        setLoading(true)
        await api.post("/event/export-finance-report/", {id: id}, {
            responseType: "blob"
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `finance_report_event_${id}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.error('Error exporting finance report:', err);
            })
        setLoading(false)
    }

    const handleFieldChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async e => {
        setModalLoading(true)
        await api.post(`/event/${type}/`, {...formData, event: id})
            .then(res => {
                toast.success(`${type} successfully added.`, {theme: "colored"})
                setExpenseIncomeModal(false)
                setFormData({
                    date: "",
                    amount: "",
                    description: "",
                    currency: "",
                    category: "",
                    event: id
                })
                setDate(null)
                fetchEvent()
                fetchIncomes()
                fetchExpenses()
            })
            .catch(error => {
                toast.error("An error has occurred.", {theme: "colored"})
                setErrors(error?.response?.data)
            })
        setModalLoading(false)
    }

    const onUpload = async (file, entryId, type) => {
        setModalInvoiceLoading(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', entryId);
        formData.append('type', type);
        await api.post("/event/invoice-upload/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(res => {
                toast.success("You have successfully uploaded the invoice", {theme: "colored"})
                setOpenInvoiceModal(false)
                fetchExpenses()
                fetchIncomes()
            })
            .catch(error => {
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setModalInvoiceLoading(false)
    }

    const openInvoiceUploadModal = (id, type) => {
        setType(type)
        setOpenInvoiceModal(id)
    };

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className={"d-print-none"}>
                <Card.Header className="d-flex flex-between-center">
                    <Flex alignItems={"center"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                        <h5 className={"ms-2"}>Financial Report</h5>
                    </Flex>
                    <Flex alignItems={"center"}>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            className={"ms-2"}
                            transform="shrink-3"
                            onClick={() => {
                                setExpenseIncomeModal(true)
                                setType("income")
                            }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Income</span>
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            className={"ms-2"}
                            transform="shrink-3"
                            onClick={() => {
                                setExpenseIncomeModal(true)
                                setType("expense")
                            }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Expense</span>
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            className={"ms-2"}
                            transform="shrink-3"
                            onClick={() => window.print()}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Print</span>
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="external-link-alt"
                            className={"ms-2"}
                            transform="shrink-3"
                            onClick={handleExport}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Export</span>
                        </IconButton>
                    </Flex>
                </Card.Header>
            </Card>
            <Row>
                <Col lg={3}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5>Total Income</h5>
                        </Card.Header>
                        <Card.Body>
                            <h3 className={"text-success"}>{event?.total_income} {currency}</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5>Total Expenses</h5>
                        </Card.Header>
                        <Card.Body>
                            <h3 className={"text-danger"}>{event?.total_expenses} {currency}</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5>Sponsorship Income</h5>
                        </Card.Header>
                        <Card.Body>
                            <h3 className={"text-warning"}>{event?.sponsorship_income} {currency}</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h5>Net Profit</h5>
                        </Card.Header>
                        <Card.Body>
                            <h3 className={"text-primary"}>{event?.net_profit} {currency}</h3>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h4>Income Details</h4>
                        </Card.Header>
                        <Card.Body>
                            <SimpleBarReact>
                                <Table striped className="border-bottom">
                                    <thead data-bs-theme="light">
                                    <tr className="bg-primary dark__bg-1000 alert alert-primary">
                                        <th className="text-white border-0 text-white-emphasis">Date</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">
                                            Category
                                        </th>
                                        <th className="text-white border-0 text-end text-white-emphasis">Amount</th>
                                        <th className="text-white border-0 text-end text-white-emphasis">Currency</th>
                                        <th className="text-white border-0 text-end text-white-emphasis"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {incomes?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">{formatDate(item?.date)}</td>
                                            <td className="align-middle text-center">{item?.category_display}</td>
                                            <td className="align-middle text-end">{item?.amount}</td>
                                            <td className="align-middle text-end">{item?.currency}</td>
                                            <td className="align-middle text-end">
                                                {item?.invoice ?
                                                    <a href={item?.invoice} target="_blank"
                                                       rel="noopener noreferrer">View Invoice</a> :
                                                    <Button variant={"outline-primary"} size={"sm"}
                                                            onClick={() => openInvoiceUploadModal(item?.id, "income")}>
                                                        Upload Invoice
                                                    </Button>
                                                }
                                            </td>
                                            <InvoiceUploadModal onUpload={onUpload} type={type} entryId={item?.id}
                                                                isOpen={openInvoiceModal} loading={modalInvoiceLoading}
                                                                onClose={() => setOpenInvoiceModal(!openInvoiceModal)}/>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h4>Expenses Details</h4>
                        </Card.Header>
                        <Card.Body>
                            <SimpleBarReact>
                                <Table striped className="border-bottom">
                                    <thead data-bs-theme="light">
                                    <tr className="bg-primary dark__bg-1000 alert alert-primary">
                                        <th className="text-white border-0 text-white-emphasis">Date</th>
                                        <th className="text-white border-0 text-center text-white-emphasis">
                                            Category
                                        </th>
                                        <th className="text-white border-0 text-end text-white-emphasis">Amount</th>
                                        <th className="text-white border-0 text-end text-white-emphasis">Currency</th>
                                        <th className="text-white border-0 text-end text-white-emphasis"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {expenses?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">{formatDate(item?.date)}</td>
                                            <td className="align-middle text-center">{item?.category_display}</td>
                                            <td className="align-middle text-end">{item?.amount}</td>
                                            <td className="align-middle text-end">{item?.currency}</td>
                                            <td className="align-middle text-end">
                                                {item?.invoice ?
                                                    <a href={item?.invoice} target="_blank"
                                                       rel="noopener noreferrer">View Invoice</a> :
                                                    <Button variant={"outline-primary"} size={"sm"}
                                                            onClick={() => openInvoiceUploadModal(item?.id, "expense")}>
                                                        Upload Invoice
                                                    </Button>
                                                }
                                            </td>
                                            <InvoiceUploadModal onUpload={onUpload} type={type} entryId={item?.id}
                                                                isOpen={openInvoiceModal} loading={modalInvoiceLoading}
                                                                onClose={() => setOpenInvoiceModal(!openInvoiceModal)}/>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h4>Income vs Expenses</h4>
                        </Card.Header>
                        <Card.Body>
                            <ReactEChartsCore
                                echarts={echarts}
                                option={getOption()}
                                style={{height: '20rem'}}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h4>Expense Breakdown</h4>
                        </Card.Header>
                        <Card.Body>
                            <ReactEChartsCore
                                echarts={echarts}
                                option={getExpenseOption()}
                                ref={chartRef}
                                style={{height: '20rem'}}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className={"mt-3"}>
                        <Card.Header>
                            <h4>Income Sources</h4>
                        </Card.Header>
                        <Card.Body>
                            <ReactEChartsCore
                                echarts={echarts}
                                option={getIncomeOption()}
                                ref={chartRef}
                                style={{height: '20rem'}}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal size={"lg"} centered show={expenseIncomeModal}
                   onHide={() => setExpenseIncomeModal(!expenseIncomeModal)}>
                <Modal.Header closeButton>
                    <h5>Add {type}</h5>
                </Modal.Header>
                <Modal.Body>
                    {modalLoading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <Form>
                            <CSRFToken/>
                            <Flex alignItems={"center"} justifyContent={"between"} wrap={"wrap"}>
                                <Form.Check label={"Income"} value={"income"} type={"radio"} name={"type"}
                                            checked={type === "income"}
                                            onChange={(e) => setType(e.target.value)}/>
                                <Form.Check label={"Expense"} value={"expense"} type={"radio"} name={"type"}
                                            checked={type === "expense"}
                                            onChange={(e) => setType(e.target.value)}/>
                            </Flex>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control
                                            type={"number"}
                                            value={formData.amount}
                                            onChange={handleFieldChange}
                                            placeholder={"Amount"}
                                            name={"amount"}
                                        />
                                        <FormError error={errors?.amount}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Currency</Form.Label>
                                        <Form.Select
                                            value={formData.currency}
                                            onChange={handleFieldChange}
                                            name={"currency"}
                                        >
                                            <option>Select currency</option>
                                            {currencies?.map(c => (
                                                <option value={c?.code}>{c?.name}</option>
                                            ))}
                                        </Form.Select>
                                        <FormError error={errors?.currency}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Date</Form.Label>
                                        <Row>
                                            <DatePicker
                                                selected={date}
                                                onChange={date => {
                                                    setDate(date);
                                                    setFormData({
                                                        ...formData,
                                                        date: format(date, 'yyyy-MM-dd')
                                                    });
                                                }}
                                                formatWeekDay={day => day.slice(0, 3)}
                                                className="form-control"
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="MM-DD-YYYY"
                                            />
                                        </Row>
                                        <FormError error={errors?.date}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Select
                                            value={formData.category}
                                            onChange={handleFieldChange}
                                            name={"category"}
                                        >
                                            <option>Select category</option>
                                            {type === "income" ? (
                                                <>
                                                    <option value={"ticket_sales"}>Ticket Sales</option>
                                                    <option value={"sponsorship"}>Sponsorship</option>
                                                    <option value={"merchandise_sales"}>Merchandise Sales</option>
                                                    <option value={"concessions"}>Concessions</option>
                                                    <option value={"grants"}>Grants</option>
                                                    <option value={"vendor_fees"}>Vendor Fees</option>
                                                    <option value={"advertising"}>Advertising</option>
                                                    <option value={"donations"}>Donations</option>
                                                    <option value={"other_income"}>Other Income</option>
                                                </>
                                            ) : (
                                                <>
                                                    <option value={"venue"}>Venue</option>
                                                    <option value={"marketing"}>Marketing</option>
                                                    <option value={"staffing"}>Staffing</option>
                                                    <option value={"entertainment"}>Entertainment</option>
                                                    <option value={"equipment_rental"}>Equipment Rental</option>
                                                    <option value={"catering"}>Catering</option>
                                                    <option value={"decorations"}>Decorations</option>
                                                    <option value={"security"}>Security</option>
                                                    <option value={"insurance"}>Insurance</option>
                                                    <option value={"travel"}>Travel</option>
                                                    <option value={"permits_licenses"}>Permits and Licenses</option>
                                                    <option value={"printing"}>Printing</option>
                                                    <option value={"miscellaneous"}>Miscellaneous</option>
                                                </>
                                            )}
                                        </Form.Select>
                                        <FormError error={errors?.category}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as={"textarea"}
                                            rows={3}
                                            name={"description"}
                                            onChange={handleFieldChange}
                                        />
                                        <FormError error={errors?.description}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton
                        icon={faSave}
                        onClick={e => handleSubmit(e)}
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                          Save
                        </span>
                    </IconButton>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default EventFinance