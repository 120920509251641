import classNames from "classnames";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	RedditIcon, RedditShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";

const EventShareButtons = ({className, event, ...rest}) => {
	const shareUrl = window.location;
	return (
		<div className={classNames('icon-group', className)} {...rest}>
			<FacebookShareButton
				hashtag={""}
				quote={event?.title}
				url={shareUrl}
				className={"me-2"}
			>
				<FacebookIcon size={40} round={true}/>
			</FacebookShareButton>
			<WhatsappShareButton
				url={shareUrl}
				quote={event?.title}
				hashtag={'#portfolio...'}
				className={"me-2"}
			>
				<WhatsappIcon size={40} round={true}/>
			</WhatsappShareButton>
			<TwitterShareButton
				url={shareUrl}
				quote={event?.title}
				hashtag={'#portfolio...'}
				className={"me-2"}
			>
				<TwitterIcon size={40} round={true}/>
			</TwitterShareButton>
			<TelegramShareButton
				url={shareUrl}
				quote={event?.title}
				hashtag={'#portfolio...'}
				className={"me-2"}
			>
				<TelegramIcon size={40} round={true}/>
			</TelegramShareButton>
			<EmailShareButton
				url={shareUrl}
				quote={event?.title}
				hashtag={'#portfolio...'}
				className={"me-2"}
			>
				<EmailIcon size={40} round={true}/>
			</EmailShareButton>
			<LinkedinShareButton
				url={shareUrl}
				quote={event?.title}
				hashtag={'#portfolio...'}
				className={"me-2"}
			>
				<LinkedinIcon size={40} round={true}/>
			</LinkedinShareButton>
			<RedditShareButton
				url={shareUrl}
				quote={event?.title}
				hashtag={'#portfolio...'}
				className={"me-2"}
			>
				<RedditIcon size={40} round={true}/>
			</RedditShareButton>
		</div>
	)
}

export default EventShareButtons