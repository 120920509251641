import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, FormControl, Modal, Row, Spinner} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Flex from '../../components/common/Flex';
import {api} from '../../utils/api';
import {toast} from 'react-toastify';
import FormError from '../errors/FormError';
import CSRFToken from '../../helpers/CSRFToken';
import {useAppContext} from '../../providers/AppProvider';
import {faLink} from "@fortawesome/free-solid-svg-icons";

const AddScheduleModal = ({
	                          setIsOpenScheduleModal,
	                          isOpenScheduleModal,
	                          scheduleStartDate,
	                          setScheduleStartDate,
	                          scheduleEndDate,
	                          setScheduleEndDate,
	                          setEvents
                          }) => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [link, setLink] = useState("")

	const {
		config: {isDark}
	} = useAppContext();

	const [formData, setFormData] = useState({});

	const handleClose = () => {
		setFormData({})
		setErrors({})
		setLink("")
		setIsOpenScheduleModal(!isOpenScheduleModal);
	};

	const handleChange = ({target}) => {
		let name = target.name;
		let value = name === 'allDay' ? target.checked : target.value;
		setFormData({...formData, [name]: value});
	};

	const handleGenerateLink = async () => {
		setLoading(true)
		await api.get("/schedule/generate_meeting_link/").then(res => {
			setLink(res?.data?.link)
			setFormData({...formData, link: res?.data?.link})
		})
			.catch(reason => {
				setErrors(reason?.response?.data)
			})
		setLoading(false)
	}

	const handleSubmit = e => {
		setLoading(true);
		e.preventDefault();
		api
			.post('/schedule/schedule/', formData)
			.then(r => {
				toast.success('Schedule successfully added.', {theme: 'colored'});
				setEvents(prevState => [
					...prevState,
					{
						...r?.data,
						schedule: true,
						url: r?.data?.link ? r?.data?.link : ''
					}
				]);
				setIsOpenScheduleModal(false);
			})
			.catch(err => {
				setErrors(err?.response?.data);
				toast.error('An error has occurred.', {theme: 'colored'});
			});
		setFormData({})
		setLink("")
		setLoading(false);
	};

	useEffect(() => {
		if (isOpenScheduleModal) {
			setFormData({
				...formData,
				start: new Date(scheduleStartDate).toISOString(),
				end: new Date(scheduleEndDate).toISOString()
			});
		} else {
			setScheduleStartDate(null);
			setScheduleEndDate(null);
		}
		// eslint-disable-next-line
	}, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

	return (
		<Modal
			show={isOpenScheduleModal}
			onHide={handleClose}
			contentClassName="border"
		>
			{loading ? (
				<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
					<Spinner animation={'border'} variant={'primary'}/>
				</Flex>
			) : (
				<Form onSubmit={handleSubmit}>
					<CSRFToken/>
					<Modal.Header
						closeButton
						closeVariant={isDark ? 'white' : undefined}
						className="bg-light px-x1 border-bottom-0"
					>
						<Modal.Title as="h5"> Create Schedule</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-x1">
						<Form.Group className="mb-3" controlId="titleInput">
							<Form.Label className="fs-0">Title</Form.Label>
							<Form.Control
								type="text"
								name="title"
								required
								onChange={handleChange}
							/>
						</Form.Group>
						<FormError error={errors.title}/>
						<Form.Group className="mb-3" controlId="startDate">
							<Form.Label className="fs-0">Start Date</Form.Label>
							<Row>
								<DatePicker
									selected={scheduleStartDate}
									onChange={date => {
										setScheduleStartDate(date);
										setFormData({
											...formData,
											start: new Date(date).toISOString()
										});
									}}
									className="form-control"
									placeholderText="MM-DD-YYYY H:M"
									dateFormat="MM-dd-yyyy h:mm aa"
									showTimeSelect
								/>
							</Row>
						</Form.Group>
						<FormError error={errors.start}/>
						<Form.Group className="mb-3" controlId="endDate">
							<Form.Label className="fs-0">End Date</Form.Label>
							<Row>
								<DatePicker
									selected={scheduleEndDate}
									onChange={date => {
										setScheduleEndDate(date);
										setFormData({...formData, end: new Date(date).toISOString()});
									}}
									className="form-control"
									placeholderText="MM-DD-YYYY H:M"
									dateFormat="MM-dd-yyyy h:mm aa"
									showTimeSelect
								/>
							</Row>
						</Form.Group>
						<FormError error={errors.end}/>
						<Form.Group controlId="color">
							<Form.Control
								type="color"
								label="Color"
								name="color"
								onChange={handleChange}
							/>
						</Form.Group>
						<FormError error={errors.color}/>
						<Form.Group className="mb-3">
							<Flex alignItems="center" justifyContent="between">
								<Form.Label className="fs-0">Schedule Meeting</Form.Label>
								{link &&
									<Button
										size="sm"
										variant="link"
										as={Link}
										target={"_blank"}
										className="p-0"
										to={link}
									>
										Open <FontAwesomeIcon icon={faLink}/>
									</Button>
								}
							</Flex>
							{!link ? (
								<div>
									<Button
										as={Link}
										variant="link"
										// type="button"
										className="badge-soft-success fw-medium"
										size="sm"
										onClick={handleGenerateLink}
									>
										<FontAwesomeIcon icon="video" className="me-2"/>
										<span>Add video conference link</span>
									</Button>
									<FormError error={errors?.error} />
								</div>
							) : (
								<FormControl
									value={formData.link}
									type="text"
									name="link"
									required
									onChange={handleChange}
								/>
							)}
						</Form.Group>
						<FormError error={errors.link}/>

						<Form.Group className="mb-3" controlId="description">
							<Form.Label className="fs-0">Description</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								name="description"
								onChange={handleChange}
							/>
						</Form.Group>
						<FormError error={errors.description}/>
						<Form.Group className="mb-3" controlId="label">
							<Form.Label className="fs-0">Label</Form.Label>
							<Form.Select name="label" onChange={handleChange}>
								<option>None</option>
								<option value="business">Business</option>
								<option value="important">Important</option>
								<option value="personal">Personal</option>
								<option value="must_attend">Must Attend</option>
							</Form.Select>
						</Form.Group>
						<FormError error={errors.label}/>
					</Modal.Body>
					<Modal.Footer className="bg-light px-x1 border-top-0">
						<Link to="/events/new" className="me-3 text-600">
							More options
						</Link>
						<Button
							variant="primary"
							type="submit"
							onClick={handleSubmit}
							className="px-4 mx-0"
						>
							Save
						</Button>
					</Modal.Footer>
				</Form>
			)}
		</Modal>
	);
};

AddScheduleModal.propTypes = {
	setIsOpenScheduleModal: PropTypes.func.isRequired,
	isOpenScheduleModal: PropTypes.bool.isRequired,
	setInitialEvents: PropTypes.func,
	initialEvents: PropTypes.array,
	scheduleStartDate: PropTypes.instanceOf(Date),
	setScheduleStartDate: PropTypes.func.isRequired,
	scheduleEndDate: PropTypes.instanceOf(Date),
	setScheduleEndDate: PropTypes.func.isRequired
};

export default AddScheduleModal;
