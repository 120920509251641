import {useNavigate, useParams} from "react-router-dom";
import {useAppContext} from "../../../providers/AppProvider";
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {useEffect} from "react";

export const Callback = () => {
    const navigate = useNavigate();
    const {setConfig} = useAppContext();
    let query = useQuery();
    const {type} = useParams();

    const data = {
        code: query.get('code')
    };

    const zoomAuth = async () => {
        await api.get('/account/setcsrf/').then(res => {
            api.defaults.headers.common['X-CSRFToken'] = res?.data?.csrftoken;
        });
        await api
            .post(
                `/dj-rest-auth/zoom/${type === 'login' ? 'login' : 'connect'}/`,
                data
            )
            .then(async res => {
                setConfig('isAuthenticated', true);
                await api.get('/dj-rest-auth/user/').then(r => {
                    setConfig('group', r?.data?.groups_name);
                    setConfig('point', r?.data?.user_points);
                    setConfig('avatar', r?.data?.url_path);
                    setConfig('permissions', r?.data?.permissions);
                });
                navigate('/user/connections');
                toast.success(
                    `Successfully ${
                        type === 'login' ? 'logged in' : 'connected'
                    } with zoom`
                );
            })
            .catch(() => {
            });
    };

    useEffect(() => {
        if (query.get('code')) zoomAuth();
        // eslint-disable-next-line
    }, [query]);
}