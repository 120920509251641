import React, { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/icons/spot-illustrations/img.png';
import editing1 from 'assets/img/icons/spot-illustrations/img_1.png';

const Starter = () => {
  return (
    <Row>
      <Col lg={6}>
        <Card>
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="align-items-center justify-content-between">
              <Col lg={6}>
                <img src={editing} className="img-fluid" alt="" />
              </Col>
              <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
                <h3 className="text-primary">Join existing activity!</h3>
                <p className="lead">Be a member to an existing activity.</p>
                <Button
                  variant="falcon-primary"
                  as={Link}
                  to="/activities/activity"
                >
                  Getting started
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="align-items-center justify-content-between">
              <Col lg={6}>
                <img src={editing1} className="img-fluid" alt="" />
              </Col>
              <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
                <h3 className="text-primary">Create your own activity!</h3>
                <p className="lead">Be a manager to an activity you own.</p>
                <Button
                  variant="falcon-primary"
                  as={Link}
                  to="/activities/activity/new"
                >
                  Getting started
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Starter;
