import { Card, Col, Row, Table } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import React from 'react';
import {
  formatDate,
  formatDateTime,
  makeDateFromTime
} from '../../../helpers/utils';
import { Link } from 'react-router-dom';

const EventReport = ({ report }) => {
  return (
    <Card className={'event-report-card'}>
      <Card.Body>
        <Row className="align-items-center text-center mb-3">
          <Col sm={6} className="text-sm-start">
            <img src={report?.activity_logo} alt="invoice" width={150} />
          </Col>
          <Col className="text-sm-end mt-3 mt-sm-0">
            <h2 className="mb-3">Report</h2>
            <h5>{report?.activity_name}</h5>
            <p className="fs--1 mb-0">{report?.activity_address}</p>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-500">Report for</h6>
            <h5>{report?.event_name}</h5>
            <p className="fs--1">{report?.event_address}</p>
          </Col>
          <Col sm="auto" className="ms-auto">
            <div className="table-responsive">
              <Table borderless size="sm" className="fs--1">
                <tbody>
                  <tr>
                    <th className="text-sm-end">Report No:</th>
                    <td>{report?.id}</td>
                  </tr>
                  <tr>
                    <th className="text-sm-end">Event Number:</th>
                    <td>{report?.event}</td>
                  </tr>
                  <tr>
                    <th className="text-sm-end">Report Date:</th>
                    <td>{formatDate(report?.created_at)}</td>
                  </tr>
                  <tr>
                    <th className="text-sm-end">Registrants:</th>
                    <td>{report?.registration_count}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Row className={'align-items-center'}>
          <Col>
            <h6 className="text-500">Objectives:</h6>
            <ul>
              {report?.objective_set?.map(objective => (
                <li>{objective?.name}</li>
              ))}
            </ul>
          </Col>
        </Row>

        <div className="mt-4 fs--1">
          <h6 className="text-500">Expenses:</h6>
          <SimpleBarReact>
            <Table striped className="border-bottom">
              <thead className="light">
                <tr className="bg-primary text-white dark__bg-1000 row-head">
                  <th className="border-0">Name</th>
                  <th className="border-0 text-end">Amount</th>
                </tr>
              </thead>
              <tbody>
                {report?.expense_set?.map(info => (
                  <tr>
                    <td className="align-middle">
                      <h6 className="mb-0 text-nowrap">{info?.name}</h6>
                    </td>
                    <td className="align-middle text-end">
                      {info?.amount} USD
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBarReact>
        </div>

        <div className="mt-4 fs--1">
          <h6 className="text-500">Participants</h6>
          <SimpleBarReact>
            <Table striped className="border-bottom">
              <thead className="light">
                <tr className="bg-primary text-white dark__bg-1000 row-head">
                  <th className="border-0">Name</th>
                  <th className="border-0 text-end">Registered At</th>
                </tr>
              </thead>
              <tbody>
                {report?.register_set?.map(info => (
                  <tr>
                    <td className="align-middle">
                      <h6 className="mb-0 text-nowrap">
                        <Link to={`/accounts/user/${info?.user}/edit`}>
                          {info?.full_name}
                        </Link>
                      </h6>
                    </td>
                    <td className="align-middle text-end">
                      {formatDateTime(info?.registered_at)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBarReact>
        </div>

        <Row className={'align-items-center'}>
          <Col>
            <h6 className="text-500">Planning:</h6>
            <ol>
              {report?.planning_set?.map(plan => (
                <li>
                  {plan?.name} at{' '}
                  {makeDateFromTime(plan?.time).toISOString().slice(11, 16)} :{' '}
                  {plan?.description}
                </li>
              ))}
            </ol>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="bg-light">
        <p className="fs--1 mb-0">
          <strong>Notes: </strong> We really appreciate your business and if
          there’s anything else we can do, please let us know!
        </p>
      </Card.Footer>
      <style type="text/css" media="print">
        {`
          .event-report-card {
            background-color: #f5f5f5;
            color: #333;
            font-family: 'Arial', sans-serif;
          }

          .event-report-card h2 {
            color: #007bff; /* Blue color */
          }

          .event-report-card .row-head {
            background-color: #2c7be5;
          }
        `}
      </style>
    </Card>
  );
};

export default EventReport;
