import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from '../../../components/common/Flex';
import { api } from '../../../utils/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useAppContext } from '../../../providers/AppProvider';

const DangerZone = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const { setConfig } = useAppContext();

  const handleDeactivate = async e => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, deactivate my account!'
    });
    if (isConfirmed) {
      setLoading(true);
      e.preventDefault();
      await api
        .patch(`/account/user/${id}/`, { is_active: false })
        .then(res => {
          toast.success('Account successfully deactivated', {
            theme: 'colored'
          });
          setConfig('isAuthenticated', false);
        })
        .catch(() => {
          toast.error('An error has occurred.', { theme: 'colored' });
        });
      setLoading(false);
    }
  };

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={'border'} variant={'primary'} />
    </Flex>
  ) : (
    <Card>
      <FalconCardHeader title="Danger Zone" />
      <Card.Body className="bg-light">
        <h5 className="mb-0">Delete this account</h5>
        <p className="fs--1">
          Once you delete a account, there is no going back. Please be certain.
        </p>
        <Button
          as={Link}
          to="#!"
          variant="falcon-danger"
          className="w-100"
          onClick={handleDeactivate}
        >
          Deactivate Account
        </Button>
      </Card.Body>
    </Card>
  );
};

export default DangerZone;
