import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";

const PropertiesFilteringForm = ({data, setLoading, setData}) => {
    const [agents, setAgents] = useState([])
    const [formData, setFormData] = useState({
        type: data?.type,
        status: data?.status,
        priority: data?.priority,
        agent: data?.agent,
        tag: data?.tag,
    })

    const query = useQuery();

    const getAgents = async () => {
        query.set("is_staff", "true")
        await api.get(`/account/user/?${query.toString()}`).then(res => setAgents(res?.data?.results))
    }

    const handleFieldChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        getAgents()
        // eslint-disable-next-line
    }, []);

    const handleUpdate = async e => {
        setLoading(true)
        await api.patch(`/support/tickets/${data?.id}/`, formData)
            .then((res) => {
                toast.success("Ticket updated successfully.", {theme: "colored"})
                setData(res?.data)
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setLoading(false)
    }

    return (
        <Card>
            <Card.Header className="bg-body-tertiary">
                <h6 className="mb-0">Properties</h6>
            </Card.Header>
            <Card.Body>
                <Form>
                    <div className="mb-2 mt-n2">
                        <Form.Label className="mb-1 fs-10">Type</Form.Label>
                        <Form.Select size="sm" name={"type"} value={formData.type} onChange={handleFieldChange}>
                            <option>None</option>
                            <option value={"question"}>Question</option>
                            <option value={"incident"}>Incident</option>
                            <option value={"problem"}>Problem</option>
                            <option value={"feature"}>Feature Request</option>
                            <option value={"refund"}>Refund</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <Form.Label className="mb-1 mt-2 fs-10">Status</Form.Label>
                        <Form.Select size="sm" name={"status"} value={formData.status}
                                     onChange={handleFieldChange}>
                            <option>None</option>
                            <option value={"recent"}>Recent</option>
                            <option value={"overdue"}>Overdue</option>
                            <option value={"closed"}>Closed</option>
                            <option value={"remaining"}>Remaining</option>
                            <option value={"responded"}>Responded</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <Form.Label className="mb-1 mt-2 fs-10">Priority</Form.Label>
                        <Form.Select size="sm" name={"priority"} value={formData.priority}
                                     onChange={handleFieldChange}>
                            <option>None</option>
                            <option value={"urgent"}>Urgent</option>
                            <option value={"high"}>High</option>
                            <option value={"medium"}>Medium</option>
                            <option value={"low"}>Low</option>
                        </Form.Select>
                    </div>
                    <div className="mb-2">
                        <Form.Label className="mb-1 mt-2 fs-10">Agent</Form.Label>
                        <Form.Select size="sm" name={"agent"} value={formData.agent}
                                     onChange={handleFieldChange}>
                            <option>None</option>
                            {agents?.map(item => (
                                <option key={item?.id} value={item?.id}>{item?.full_name}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div>
                        <Form.Label className="mb-1 mt-2 fs-10">Tags</Form.Label>
                        <Form.Select size="sm" name={"tag"} value={formData.tag} onChange={handleFieldChange}>
                            <option>None</option>
                            <option value={"sales"}>Sales</option>
                            <option value={"request"}>Request</option>
                            <option value={"complaint"}>Complaint</option>
                            <option value={"feedback"}>Feedback</option>
                            <option value={"support"}>Support</option>
                        </Form.Select>
                    </div>
                </Form>
            </Card.Body>
            <Card.Footer className="border-top border-200 py-x1">
                <Button varient="primary" className="w-100" onClick={handleUpdate}>
                    Update
                </Button>
            </Card.Footer>
        </Card>
    );
};

export default PropertiesFilteringForm;
