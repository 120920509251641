import React, {useEffect, useState} from 'react';
import Flex from 'components/common/Flex';
import {Dropdown, Button, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAppContext} from 'providers/AppProvider';
import {api} from "../../../utils/api";
import SoftBadge from "../../../components/common/SoftBadge";
import useQuery from "../../../hooks/useQuery";

const ModalLabelContent = ({taskLabels, task, getTask, getKanbanStatus}) => {
    const [labels, setLabels] = useState([]);
    const [label, setLabel] = useState({
        name: "",
        type: ""
    })

    const {
        config: {isRTL}
    } = useAppContext();

    const query = useQuery()

    const getLabels = async () => {
        await api.get("/task/labels/").then((response) => {
            setLabels(response?.data?.results)
        })
    }

    useEffect(() => {
        getLabels()
        // eslint-disable-next-line
    }, []);

    const AddLabel = async (e, id) => {
        await api.patch(`/task/tasks/${task}/`, {label: id})
            .then(res => {
                getTask()
                getKanbanStatus(query)
            })
    };

    return (
        <Flex>
            {taskLabels?.map(label => (
                <SoftBadge bg={label.type} className="me-1 py-2" key={label.name}>
                    {label.name}
                </SoftBadge>
            ))}

            <Dropdown drop={isRTL ? 'start' : 'end'}>
                <Dropdown.Toggle
                    variant="secondary"
                    size="sm"
                    className="px-2 fsp-75 bg-400 border-400 dropdown-caret-none"
                >
                    <FontAwesomeIcon icon="plus"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <h6 className="dropdown-header py-0 px-3 mb-0">Select Label</h6>
                    <Dropdown.Divider/>
                    <div className="px-3">
                        {labels?.map(label => (
                            <Dropdown.Item
                                as="button"
                                className={`badge-subtle-${label.type} rounded-1 mb-2`}
                                key={label.name}
                                onClick={(e) => AddLabel(e, label?.id)}
                            >
                                {label.name}
                            </Dropdown.Item>
                        ))}
                    </div>
                    <Dropdown.Divider/>
                    <div className="px-3">
                        <Form.Group>
                            <Form.Control
                                type={"text"}
                                placeholder={"Label"}
                                className={"mb-2"}
                                value={label.name}
                                onChange={(e) => setLabel({...label, name: e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Select
                                className={"mb-2"}
                                value={label.type}
                                onChange={(e) => setLabel({...label, type: e.target.value})}
                            >
                                <option value={"success"}>Success</option>
                                <option value={"primary"}>Primary</option>
                                <option value={"info"}>Info</option>
                                <option value={"danger"}>Danger</option>
                                <option value={"secondary"}>Secondary</option>
                                <option value={"warning"}>Warning</option>
                            </Form.Select>
                        </Form.Group>
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            className="d-block w-100 border-400"
                            onClick={async (e) => {
                                await api.post("/task/labels/", label).then(res => {
                                    getLabels()
                                    getTask()
                                })
                            }}
                        >
                            Create label
                        </Button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </Flex>
    );
};

export default ModalLabelContent;
