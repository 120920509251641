import React from 'react';
import { Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Link, useNavigate } from 'react-router-dom';

const PageHeader = ({ report }) => {
  const navigate = useNavigate();
  return (
    <Card className="mb-3 d-print-none">
      <Card.Body className="d-flex gap-2 flex-wrap flex-between-center">
        <div>
          <h6 className="text-primary">
            {report?.event_name} by{' '}
            <Link to={`/activities/activity/${report?.activity}/detail`}>
              {report?.activity_name}
            </Link>
          </h6>
          <h5 className="mb-0">{report?.title}</h5>
        </div>
        <div>
          <IconButton
            variant="primary"
            size="sm"
            icon="print"
            iconClassName="me-sm-1"
            className="me-2"
            onClick={() => window.print()}
          >
            <span className="d-none d-sm-inline-block">Print</span>
          </IconButton>
          <IconButton
            variant="primary"
            size="sm"
            icon="edit"
            iconClassName="me-sm-1"
            className="me-2"
            onClick={() => navigate(`/reports/${report?.id}/edit`)}
          >
            <span className="d-none d-sm-inline-block">Edit</span>
          </IconButton>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PageHeader;
