import calendar from 'assets/img/icons/spot-illustrations/calendar.svg'
import billing from 'assets/img/icons/billing.png'
import report from 'assets/img/icons/report.png'
import scan from 'assets/img/icons/code-scan.png'
import paths from "../routes/paths";

const userGroupName = process.env.REACT_APP_USER_GROUP_NAME;
const activityGroupName = process.env.REACT_APP_ACTIVITY_GROUP_NAME;
const adminGroupName = process.env.REACT_APP_ADMIN_GROUP_NAME;

export const quickLinks = [
	{
		img: calendar,
		title: 'links.calendar',
		link: paths.calendar,
		groups: [adminGroupName, activityGroupName, userGroupName],
		permission: "calendars.view_schedule"
	},
	{
		img: billing,
		title: 'links.transactions',
		link: paths.transactions,
		groups: [adminGroupName, activityGroupName, userGroupName],
		permission: "transaction.view_transaction"
	},
	{
		img: report,
		title: 'links.reports',
		link: paths.reports,
		groups: [adminGroupName, activityGroupName],
		permission: "report.view_report"
	},
	{
		hr: true
	},
	{
		img: scan,
		title: 'links.scan',
		type: "scan",
		groups: [adminGroupName, activityGroupName],
		permission: "attendance.add_attendance"
	},
];
