import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import paths from "../../../routes/paths";

const EmailVerification = () => {
	let query = useQuery()
	const navigate = useNavigate()

	const verifyEmail = async () => {
		await api.get("/account/setcsrf/").then(res => {
			api.defaults.headers.common['X-CSRFToken'] = res?.data?.csrftoken
		})
		await api.post(`/dj-rest-auth/registration/verify-email/`, {key: query.get("key")})
			.then((res) => {
				toast.success("Email successfully verified.", {theme: "colored"})
				navigate(paths.userEmails)
			})
			.catch(err => {

			})
	}

	useEffect(() => {
		verifyEmail()
		// eslint-disable-next-line
	}, []);

}

export default EmailVerification