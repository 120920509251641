import React from 'react';
import Section from '../../components/common/Section';
import Flex from '../../components/common/Flex';
import ContactForm from './ContactForm';
import {Image} from "react-bootstrap";
import contact from 'assets/img/illustrations/Contact-us 1.png'

const Contact = ({t, i18n}) => {
  return (
    <Section
      className="py-0 overflow-hidden light"
      position="center"
      overlay
      style={{ zIndex: 9999 }}
    >
      <Flex
        justifyContent={'between'}
        alignItems={'center'}
        wrap={"wrap"}
        className="pt-5 pt-lg-8 pb-lg-6 pb-xl-0"
      >
        <div className="pb-7 pb-xl-9 text-center text-xl-start">
          <h1 className="text-primary fw-bold mt-2">{t("contact.title")}</h1>
          <p className="lead text-primary opacity-75 mt-3">
            {t('contact.description.line1')} <br />
            {t('contact.description.line2')} <br/>
            {t('contact.description.line3')}
          </p>
          <ContactForm t={t} i18n={i18n} />
        </div>
        <Image src={contact} className={"pb-7 pb-xl-9"} />
      </Flex>
    </Section>
  );
};

export default Contact;
