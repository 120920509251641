import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useAppContext } from '../../providers/AppProvider';
import {HeaderLanguages} from "../../components/navbar/top/HeaderLanguages";
import {useTranslation} from "react-i18next";

const LandingRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useAppContext();

  const navigate = useNavigate();
  const {t} = useTranslation("topbar")

  return (
    <Nav navbar className="ms-auto">
      <Nav.Item className={"mt-1"}>
        <HeaderLanguages />
      </Nav.Item>
      <Nav.Item as={'li'}>
        <Nav.Link
          className="pe-2 theme-switch-toggle text-primary me-2"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip
                style={{ position: 'fixed' }}
                id="ThemeColor"
                className="d-none d-lg-block"
              >
                {isDark ? t("themeSwitcher.light") : t("themeSwitcher.dark")}
              </Tooltip>
            }
          >
            <span>
              <div className="theme-switch-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="me-2"
                />
              </div>
              <p className="d-lg-none mb-0">
                {isDark ? t("themeSwitcher.light") : t("themeSwitcher.dark")}
              </p>
            </span>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link className={'text-primary me-2'} as={Link} to="#offers">
          {t('offers')}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link className={'text-primary me-2'} as={Link} to="#testimonials">
          {t('testimonials')}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link className={'text-primary me-2'} as={Link} to="#contact">
          {t('contact')}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Button}
          className={'bg-transparent btn-primary text-primary ms-2'}
          onClick={() => navigate('/authentication/split/login')}
        >
          {t('login')}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
