import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const GeneralTerms = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="general" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">General Terms</h5>
			</Card.Header>
			<Card.Body>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					By accessing and placing an order with nexameet, you confirm that you are in agreement with and bound by
					the terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire
					website and any email or other type of communication between you and nexameet.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					Under no circumstances shall nexameet team be liable for any direct, indirect, special, incidental or
					consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the
					inability to use, the materials on this site, even if nexameet team or an authorized representative has been
					advised of the possibility of such damages. If your use of materials from this site results in the need for
					servicing, repair or correction of equipment or data, you assume any costs thereof.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					Nexameet will not be responsible for any outcome that may occur during the course of usage of our
					resources. We reserve the rights to change prices and revise the resources usage policy in any moment.
				</p>
			</Card.Body>
		</Card>
	)
})

export default GeneralTerms