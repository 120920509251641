import {formatDateTime, withPermission} from "../../../helpers/utils";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import Flex from "../../../components/common/Flex";
import Avatar from "../../../components/common/Avatar";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import {getCompanies} from "./actions/Company";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import BaseTableHeader from "../../BaseTableHeader";

const Companies = () => {
	const [companies, setCompanies] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

	const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();

	const {hasPermission} = usePermissions()

	const navigate = useNavigate();

	const fetchCompanies = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);
		await api.get(`/company/?${q.toString()}`)
			.then(res => {
				setCompanies(res?.data?.results);
				setCount(res?.data?.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLength(companies.length);
		setLoading(false);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchCompanies, 600), []);

	useEffect(() => {
		fetchCompanies(query);
		setLength(companies.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

	const columns = [
		{
			accessor: 'name',
			Header: 'Name',
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'name' && sortingDirection,
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const {id, name, logo} = rowData.row.original;
				return (
					<>
						<Link to={`/management/companies/${id}/edit`}>
							<Flex alignItems="center">
								{logo ? (
									<Avatar
										src={logo}
										rounded={'circle'}
										size="4xl"
										className="me-2"
									/>
								) : (
									<Avatar
										size="4xl"
										name={name}
										rounded={'circle'}
										className="me-2"
									/>
								)}
								<div className="flex-1">
									<h6
										className="mb-0 fs-0"
										style={{
											width: '200px',
											wordWrap: 'break-word',
											wordBreak: 'break-all',
											whiteSpace: 'pre-wrap'
										}}
									>
										{name}
									</h6>
								</div>
							</Flex>
						</Link>
					</>
				);
			}
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'phone',
			Header: 'Phone',
			canSort: true,
			sortingDirection: sortingField === 'phone' && sortingDirection,
			isSorted: false,
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'email',
			Header: 'Email',
			canSort: true,
			sortingDirection: sortingField === 'email' && sortingDirection,
			isSorted: false,
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'website',
			Header: 'Website',
			canSort: true,
			sortingDirection: sortingField === 'website' && sortingDirection,
			isSorted: false,
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'full_address',
			Header: 'Address',
			canSort: true,
			sortingDirection: sortingField === 'address' && sortingDirection,
			isSorted: false,
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'created_at',
			Header: 'Date Created',
			canSort: true,
			sortingDirection: sortingField === 'created_at' && sortingDirection,
			isSorted: false,
			Cell: rowData => {
				const {created_at} = rowData.row.original;
				return <>{formatDateTime(created_at)}</>;
			}
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {id, name} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: 'Are you sure?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, delete it!'
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/company/${id}/`).then(() => {
							toast.success(
								`Company ${name} successfully deleted.`,
								{
									theme: 'colored'
								}
							);
							getCompanies(query)
								.then(res => {
									setCompanies(res?.results);
									setCount(res?.count);
								})
								.catch(() => {
								});
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("company.change_company") &&
								<Dropdown.Item onClick={() => navigate(paths.editCompany.replace(":id", id))}>
									Edit
								</Dropdown.Item>
							}
							<Dropdown.Divider as="div"/>
							{hasPermission("company.delete_company") &&
								<Dropdown.Item
									onClick={e => handleDelete(e)}
									className="text-danger"
								>
									Delete
								</Dropdown.Item>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	];

	const companyList = companies?.map(company => ({
		id: company.id,
		name: company.name,
		logo: company.logo_url,
		full_address: company.full_address,
		created_at: company.created_at,
		email: company.email,
		phone: company.phone,
		website: company.website,
	}));

	const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={companyList}
			selection
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"company.add_company"}
						creationPath={paths.createCompany}
						title={"company"}
						deletePermission={"company.delete_company"}
						exportPermission={"company.add_companyexportfile"}
						data={companyList}
						setLoading={setLoading}
						deleteLink={"/company/delete/company/"}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchCompanies}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
}

export default withPermission(Companies, "company.view_company")