import React, {useEffect, useState} from "react";
import {useAppContext} from "../../../../providers/AppProvider";
import {useNavigate} from "react-router-dom";
import {api} from "../../../../utils/api";
import paths from "../../../../routes/paths";
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import BillingHeader from "./BillingHeader";
import TransactionsHistory from "./TransactionsHistory";

const PlanManagementContent = () => {
	const [subscription, setSubscription] = useState({});
	const [loading, setLoading] = useState(false);

	const {
		setConfig
	} = useAppContext()

	const navigate = useNavigate();

	const getCurrentSubscription = async () => {
		setLoading(true);
		await api
			.get('/membership/current_activity_subscription/')
			.then(res => setSubscription(res?.data))
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", false);
			});
		setLoading(false);
	};

	useEffect(() => {
		getCurrentSubscription();
		// eslint-disable-next-line
	}, []);

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<>
			<BillingHeader
				className="mb-3"
				membership_details={subscription?.membership?.membership_details}
			/>
			<Row className={'g-3 mb-3'}>
				<Col>
					<Card className="h-100">
						<Card.Header>
							<Flex
								justifyContent={'between'}
								alignItems={'center'}
								wrap={'wrap'}
							>
								<h5 className="mb-0">Billing History</h5>
								<Button
									variant={'outline-primary'}
									size={'sm'}
									onClick={() => navigate(paths.billingList)}
								>
									Show All
								</Button>
							</Flex>
						</Card.Header>
						<Card.Body className="bg-light">
							<TransactionsHistory/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>

	)
}

export default PlanManagementContent