import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import coverSrc from 'assets/img/generic/4.jpg';
import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ProfileBanner from '../ProfileBanner';
import IconButton from '../../../../components/common/IconButton';
import paths from "../../../../routes/paths";
import {usePermissions} from "../../../../providers/PermissionsProvider";
import {api} from "../../../../utils/api";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../providers/AppProvider";
import CodeOfConductCard from "../../../management/rule/cards/CodeOfConductCard";
import CSRFToken from "../../../../helpers/CSRFToken";
import {toast} from "react-toastify";
import FormError from "../../../errors/FormError";

const Banner = ({data, join}) => {
	const [sponsors, setSponsors] = useState(data?.companies_sponsored);
	const [rule, setRule] = useState({})
	const [joinModalOpen, setJoinModalOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState({})
	const {id} = useParams()
	const [formData, setFormData] = useState({
		activity: id,
		status: "draft",
		acknowledgment: false
	})

	const {
		config: {isSuperUser}
	} = useAppContext()
	const navigate = useNavigate();

	const {hasPermission} = usePermissions()
	const {t} = useTranslation(["activity", "common"])

	const getSponsors = async () => {
		await api.get(`/activity/activity/${id}/`).then(res => setSponsors(res?.data?.companies_sponsored))
	}

	const getRule = async (rule) => {
		setLoading(true)
		await api.get(`/rule/codeofconduct/${rule}/`).then(res => {
			setRule(res?.data)
		})
		setLoading(false)
	}

	useEffect(() => {
		getSponsors()
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (data?.rules?.length > 0) {
			data?.rules?.forEach(rule => {
				if (rule.rule_type === "code_of_conduct") {
					getRule(rule?.id)
				}
			})
		}
	}, [data?.rules]);

	const saveJoinRequest = async (e) => {
		e.preventDefault();
		setLoading(true)
		await api.post(`/join/join/`, formData)
			.then(res => {
				toast.success("Request successfully saved.", {theme: "colored"})
				navigate(paths.requestDetail.replace(":id", res?.data?.id))
			})
			.catch((error) => {
				toast.error("An error has occurred.", {theme: "colored"});
				setErrors(error?.response?.data)
			})
		setLoading(false)
	}

	return (
		<ProfileBanner>
			<ProfileBanner.Header avatar={data?.logo_url} coverSrc={coverSrc}/>
			<ProfileBanner.Body>
				<Row>
					<Col lg={8}>
						<h4 className="mb-1">
							{data?.name} {data?.active && <VerifiedBadge/>}
						</h4>
						<h5 className="fs-0 fw-normal">{data?.type_display}</h5>
						<p className="text-500">
							{data?.delegation_name}, {data?.country_name}
						</p>
						{(hasPermission("join.add_join") && !data?.is_approved) &&
							<Button
								variant="falcon-primary"
								size="sm"
								className="px-3 me-2"
								onClick={() =>
									!data?.is_joined
										? setJoinModalOpen(true)
										: navigate(paths.requestDetail.replace(":id", join?.id))
								}
							>
								{data?.is_joined
									? t('requested')
									: data?.is_approved
										? t('joined')
										: t('join')}
							</Button>
						}
						{((hasPermission("activity.change_activity")) || isSuperUser) &&
							<IconButton
								icon={'edit'}
								variant={'primary'}
								size={'sm'}
								onClick={() => navigate(`/activities/activity/${id}/edit`)}
							>
								<span className={'d-none d-sm-inline-block ms-1'}>{t('edit', {ns: "common"})}</span>
							</IconButton>
						}
						<div className="border-dashed border-bottom my-4 d-lg-none"/>
					</Col>
					<Col className="ps-2 ps-lg-3">
						<Link to={paths.activityMembers.replace(":id", id)}>
							<Flex alignItems="center" className="mb-2">
								<FontAwesomeIcon
									icon="user-circle"
									className="me-2 text-700"
									style={{height: '30px', width: '30px'}}
								/>
								<div className="flex-1">
									<h6 className="mb-0">
										{t('see', {ns: "common"})} {t('titles.member', {ns: "common"})} ({data?.count_members ? data?.count_members : 0}
										)
									</h6>
								</div>
							</Flex>
						</Link>
						{sponsors?.map(item => (
							<Link to={paths.editCompany.replace(":id", item?.company)} key={item?.company}>
								<Flex alignItems="center" className="mb-2">
									<img
										src={item?.company_logo}
										alt={item?.company_name}
										width={30}
										className="me-2 rounded-circle"
									/>
									<div className="flex-1">
										<h6 className="mb-0">{item?.company_name}</h6>
									</div>
								</Flex>
							</Link>
						))}
					</Col>
				</Row>
				<Modal show={joinModalOpen} onHide={() => setJoinModalOpen(!joinModalOpen)} centered size={"lg"}>
					<Modal.Header closeButton>
						<h5 className="mb-1">You're about to join {data?.name}</h5>
					</Modal.Header>
					<Modal.Body>
						{loading ? (
							<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
								<Spinner animation={'border'} variant={'primary'}/>
							</Flex>
						) : (
							rule ? (
								<>
									{rule?.acknowledgment_required && <h6>You must accept this code of conduct</h6>}
									<CodeOfConductCard data={rule} activity={data?.name}/>
									<Form>
										<CSRFToken/>
										<Form.Check
											className={"mt-3"}
											label={"I have read and accept the rules of this code"}
											checked={formData.acknowledgment}
											onChange={(e) => setFormData({
												...formData, acknowledgment: e.target.checked, activity: id
											})}
										/>
										<FormError error={errors?.acknowledgment} />
									</Form>
								</>
							) : ""
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={saveJoinRequest}>Proceed</Button>
					</Modal.Footer>
				</Modal>
			</ProfileBanner.Body>
		</ProfileBanner>
	);
};

export default Banner;
