import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLink from 'components/common/FalconLink';
import React, {useEffect, useState} from 'react';
import {Card, ProgressBar, Spinner} from 'react-bootstrap';
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import Flex from "../../../components/common/Flex";
import {getPastDates} from "../../../helpers/utils";
import paths from "../../../routes/paths";
import {useAppContext} from "../../../providers/AppProvider";

const TeamProgress = ({t}) => {
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)

    const {
        setConfig
    } = useAppContext()

    let query = useQuery()

    query.set("time_range", "last_month")

    const getProgress = async () => {
        setLoading(true)
        await api.get(`/dashboard/team/?${query.toString()}`).then(res => setProgress(res?.data?.progress))
          .catch(err => {
              if (err?.response.status === 403) setConfig("isAuthenticated", false)
          })
        setLoading(false)
    }

    useEffect(() => {
        getProgress()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Card className="h-100">
            <FalconCardHeader
                title={t('progress.title')}
                endEl={<FalconLink to={paths.reports} title={t('progress.report')} className="px-0"/>}
            />
            <Card.Body>
                <p className="fs--1 text-600">
                    {t('progress.text')}
                </p>

                <ProgressBar
                    now={progress}
                    key={1}
                    style={{height: '6px'}}
                    className="rounded-pill mb-3"
                />

                <p className="mb-0 text-primary"> {progress}% {t('progress.completed')}</p>
                <p className="mb-0 fs--2 text-500">{getPastDates(30).at(0).toLocaleString()} - {getPastDates(30).at(29).toLocaleString()}</p>
            </Card.Body>
        </Card>
    );
};

export default TeamProgress;
