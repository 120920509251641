import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import React, {useState} from "react";
import {Field, FieldArray} from "formik";
import FormError from "../../errors/FormError";
import CustomDateInput from "../../../components/common/CustomDateInput";
import DatePicker from 'react-datepicker';
import SimpleBarReact from "simplebar-react";
import IconButton from "../../../components/common/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const RegistrationOptions = ({values, setFieldValue, errors, t}) => {
	const [startDate, setStartDate] = useState(values?.start_date ? new Date(values?.registration?.start_date) : null)
	const [endDate, setEndDate] = useState(values?.end_date ? new Date(values?.registration?.end_date) : null)
	const [menuEnabled, setMenuEnabled] = useState(values?.registration?.menus?.length > 0)
	const [questionsEnabled, setQuestionsEnabled] = useState(values?.registration?.questions?.length > 0)

	const handleChange = (name, value) => {
		setFieldValue(name, value);
	};

	return (
		<>
			<Card className={"mt-3"}>
				<Card.Header as="h5">{t('fields.registration.title')} <span className={"text-danger"}>*</span></Card.Header>
				<Card.Body className="bg-light">
				<Row>
						<Col lg={6}>
							<Field name={`registration.type`}>
								{({field}) => (
									<Form.Check
										label={t('fields.registration.standard.title')}
										type="radio"
										name={`registration.type`}
										value={"standard"}
										checked={values?.registration?.type === "standard"}
										onChange={({target}) =>
											setFieldValue(field.name, target.value)
										}
									/>
								)}
							</Field>
						</Col>
						<Col lg={6}>
							<Field name={`registration.type`}>
								{({field}) => (
									<Form.Check
										name={`registration.type`}
										label={t('fields.registration.external.title')}
										type="radio"
										value={"external"}
										checked={values?.registration?.type === "external"}
										onChange={({target}) =>
											setFieldValue(field.name, target.value)
										}
									/>
								)}
							</Field>
						</Col>
						<FormError error={errors?.registration?.type}/>
					</Row>
					{values?.registration?.type === "standard" ? (
						<>
							<Row>
								<Col md="6">
									<Form.Group controlId="registration.start_date">
										<Form.Label>{t('fields.registration.standard.start_date')} <span className={"text-danger"}>*</span></Form.Label>
										<Row>
											<DatePicker
												selected={startDate}
												onChange={newDate => {
													setStartDate(newDate)
													handleChange('registration.start_date', new Date(newDate).toISOString());
												}}
												showTimeSelect
												dateFormat="MM/dd/yyyy h:mm aa"
												fixedHeight
												className='form-control'
												customInput={
													<CustomDateInput
														formControlProps={{
															placeholder: 'MM/dd/yyyy h:mm aa',
														}}
													/>
												}
											/>
										</Row>
									</Form.Group>
									<FormError error={errors.registration?.start_date}/>
								</Col>
								<Col md="6">
									<Form.Group controlId="registration.end_date">
										<Form.Label>{t('fields.registration.standard.end_date')} <span className={"text-danger"}>*</span></Form.Label>
										<Row>
											<DatePicker
												selected={endDate}
												onChange={newDate => {
													setEndDate(newDate)
													handleChange('registration.end_date', new Date(newDate).toISOString());
												}}
												showTimeSelect
												dateFormat="MM/dd/yyyy h:mm aa"
												fixedHeight
												className='form-control'
												customInput={
													<CustomDateInput
														formControlProps={{
															placeholder: 'MM/dd/yyyy h:mm aa',
															name: 'registration.end_date',
														}}
													/>
												}
											/>
										</Row>
									</Form.Group>
									<FormError error={errors.registration?.end_date}/>
								</Col>
							</Row>
							<Form.Group controlId="registration.maximum_registrants">
								<Form.Label>{t('fields.registration.standard.max')} <span className={"text-danger"}>*</span></Form.Label>
								<Form.Control
									type="number"
									name="maximum_registrants"
									placeholder="Maximum Registrants"
									value={values.registration.maximum_registrants}
									onChange={e => handleChange('registration.maximum_registrants', e.target.value)}
								/>
								<FormError error={errors?.registration?.maximum_registrants}/>
							</Form.Group>
							<Form.Group controlId="menu" className={"mt-2"}>
								<Form.Switch
									label={t('fields.registration.standard.menuEnable')}
									name="registration.menu"
									checked={menuEnabled}
									onChange={e => setMenuEnabled(!menuEnabled)}
								/>
							</Form.Group>
							<Form.Group controlId="question" className={"mt-2"}>
								<Form.Switch
									label={t('fields.registration.standard.questionEnable')}
									name="registration.question"
									checked={questionsEnabled}
									onChange={e => setQuestionsEnabled(!questionsEnabled)}
								/>
							</Form.Group>
						</>
					) : values?.registration?.type === "external" ? (
						<>
							<Form.Group controlId="registration.url">
								<Form.Label>{t('fields.registration.external.link')} <span className={"text-danger"}>*</span></Form.Label>
								<Form.Control
									type="url"
									name="url"
									placeholder={t('fields.registration.external.link')}
									value={values.registration.url}
									onChange={e => handleChange('registration.url', e.target.value)}
								/>
								<FormError error={errors?.registration?.url}/>
							</Form.Group>
						</>
					) : (
						<></>
					)}
				</Card.Body>
			</Card>
			{(menuEnabled && values?.registration?.type === "standard") && (
				<Card className={"mt-3"}>
					<Card.Header as="h5">{t('fields.registration.menu.title')}</Card.Header>
					<Card.Body>
						<FieldArray
							name={'registration.menus'}
							render={arrayHelpers => (
								<>
									<SimpleBarReact>
										<Table className="bg-white mb-2 dark__bg-1100 mt-3" bordered>
											<thead>
											<tr className="fs--1">
												<th scope="col">{t('fields.registration.menu.name')}</th>
												<th></th>
											</tr>
											</thead>
											<tbody className="event-ticket-body">
											{values.registration.menus && values.registration.menus.length > 0
												? values.registration.menus.map((field, index) => (
													<>
														<tr>
															<td>
																<Field name={`registration.menus.${index}.name`}>
																	{({field}) => (
																		<Form.Control
																			size="sm"
																			type="text"
																			placeholder={t('fields.registration.menu.name')}
																			value={field.value}
																			onChange={({target}) => handleChange(`registration.menus.${index}.name`, target.value)}
																		/>
																	)}
																</Field>
															</td>
															<td className="text-center align-middle">
																<Button variant="link" size="sm" onClick={() => arrayHelpers.remove(index)}>
																	<FontAwesomeIcon className="text-danger" icon="times-circle"/>
																</Button>
															</td>
														</tr>
													</>
												))
												: ''}
											</tbody>
										</Table>
									</SimpleBarReact>
									<IconButton
										onClick={() =>
											arrayHelpers.insert(values.registration.menus.length, '')
										}
										variant="falcon-default"
										size="sm"
										icon="plus"
										transform="shrink-3"
									>
										{t('addItem', {ns: "common"})}
									</IconButton>
								</>
							)}
						/>
					</Card.Body>
				</Card>
			)}
			{(questionsEnabled && values?.registration?.type === "standard") && (
				<Card className={"mt-3"}>
					<Card.Header as="h5">{t('fields.registration.questions.title')}</Card.Header>
					<Card.Body>
						<FieldArray
							name={'registration.questions'}
							render={arrayHelpers => (
								<>
									{values.registration.questions && values.registration.questions.length > 0
										? values.registration.questions.map((field, index) => (
											<div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
												<div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
													<Button
														size="sm"
														variant="link"
														className="p-0"
														onClick={() => arrayHelpers.remove(index)}
													>
														<FontAwesomeIcon className="text-danger" icon="times-circle"/>
													</Button>
												</div>
												<Row className="gx-2 gy-3">
													<Col md={8}>
														<Form.Group>
															<Form.Label>{t('fields.registration.questions.question')}</Form.Label>
															<Field name={`registration.questions.${index}.name`}>
																{({field}) => (
																	<Form.Control
																		type="text"
																		name={`registration.questions.${index}.name`}
																		placeholder={t('fields.registration.questions.question')}
																		value={field.value}
																		onChange={(e) => setFieldValue(field.name, e.target.value)}
																	/>
																)}
															</Field>
														</Form.Group>
													</Col>
													<Col md={4}>
														<Form.Group>
															<Field name={`registration.questions.${index}.required`}>
																{({field}) => (
																	<Form.Switch
																		name={`registration.questions.${index}.required`}
																		label={t('fields.registration.questions.required')}
																		value={field.value}
																		onChange={(e) => setFieldValue(field.name, e.target.checked)}
																	/>
																)}
															</Field>
															<Field name={`registration.questions.${index}.editable`}>
																{({field}) => (
																	<Form.Switch
																		name={`registration.questions.${index}.editable`}
																		label={t('fields.registration.questions.editable')}
																		value={field.value}
																		onChange={(e) => setFieldValue(field.name, e.target.checked)}
																	/>
																)}
															</Field>
														</Form.Group>
													</Col>
												</Row>
												<Row>
													<Col>
														<Form.Group>
															<Form.Label>{t('fields.registration.questions.type.title')}</Form.Label>
															<Field name={`registration.questions.${index}.type`}>
																{({field}) => (
																	<Form.Select
																		name={`registration.questions.${index}.type`}
																		value={field.value}
																		onChange={(e) => setFieldValue(field.name, e.target.value)}
																	>
																		<option value={""}>{t('select', {ns: "common"})} {t('fields.registration.questions.type.title')}</option>
																		<option value="text">{t('fields.registration.questions.type.text')}</option>
																		<option value="number">{t('fields.registration.questions.type.number')}</option>
																		<option value="email">{t('fields.registration.questions.type.email')}</option>
																		<option value="password">{t('fields.registration.questions.type.password')}</option>
																		<option value="date">{t('fields.registration.questions.type.date')}</option>
																		<option value="time">{t('fields.registration.questions.type.time')}</option>
																		<option value="url">{t('fields.registration.questions.type.url')}</option>
																		<option value="checkbox">{t('fields.registration.questions.type.checkbox')}</option>
																		<option value="radio">{t('fields.registration.questions.type.radio')}</option>
																		<option value="textarea">{t('fields.registration.questions.type.textarea')}</option>
																		<option value="select">{t('fields.registration.questions.type.select')}</option>
																		<option value="file">{t('fields.registration.questions.type.file')}</option>
																	</Form.Select>
																)}
															</Field>
														</Form.Group>
													</Col>
												</Row>
												{(field?.type === "radio" || field?.type === "checkbox" || field?.type === "select") &&
													<Row>
														<Col>
															<Form.Group>
																<Form.Label>Options</Form.Label>
																<Field name={`registration.questions.${index}.options`}>
																	{({field}) => (
																		<textarea
																			name={`registration.questions.${index}.options`}
																			className={"form-control"}
																			rows={4}
																			value={field.value}
																			onChange={(e) => setFieldValue(field.name, e.target.value)}
																		>
																		</textarea>
																	)}
																</Field>
																<Form.Text className="text-warning fs-10">
																	* {t('fields.registration.questions.seperate')}
																</Form.Text>
															</Form.Group>
														</Col>
													</Row>
												}
											</div>
										))
										: ''}
									<IconButton
										onClick={() =>
											arrayHelpers.insert(values.registration.questions.length, '')
										}
										variant="falcon-default"
										size="sm"
										icon="plus"
										transform="shrink-3"
									>
										{t('addItem', {ns: "common"})}
									</IconButton>
								</>
							)}
						/>
					</Card.Body>
				</Card>
			)}
		</>
	)
}

export default RegistrationOptions