import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import classNames from 'classnames';

const EventCustomFieldItem = ({
	                              name,
	                              type,
	                              options,
	                              index,
	                              onChange,
	                              required,
	                              id,
	                              value
                              }) => {
	const [date, setDate] = useState(null);
	const [time, setTime] = useState(null);

	// eslint-disable-next-line no-lone-blocks
	{
		switch (type) {
			case 'number':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<Form.Control
							type="number"
							name={value}
							onChange={onChange}
							placeholder={`Enter ${name} ...`}
						/>
					</Form.Group>
				);
			case 'password':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<Form.Control
							type="password"
							name={value}
							onChange={onChange}
							placeholder={`Enter ${name} ...`}
						/>
					</Form.Group>
				);

			case 'email':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<Form.Control
							type="email"
							name={value}
							onChange={onChange}
							placeholder={`Enter ${name} ...`}
						/>
					</Form.Group>
				);

			case 'checkboxes':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>

						{options.map((option, key) => (
							<Form.Check key={key} name={value} onChange={onChange}>
								<Form.Check.Input
									name={value}
									type="checkbox"
								/>
								<Form.Check.Label className="mb-0">{option}</Form.Check.Label>
							</Form.Check>
						))}
					</Form.Group>
				);
			case 'radio':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>

						{options.map((option, key) => (
							<Form.Check key={key} name={value} onChange={onChange}>
								<Form.Check.Input
									name={value}
									type="radio"
								/>
								<Form.Check.Label className="mb-0">{option}</Form.Check.Label>
							</Form.Check>
						))}
					</Form.Group>
				);

			case 'select':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<Form.Select
							onChange={onChange}
							aria-label="Default select example"
							name={value}
						>
							{options?.map((option, key) => (
								<option key={key} value={option}>
									{option}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				);

			case 'textarea':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
						controlId="ControlTextarea"
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<Form.Control
							as="textarea"
							onChange={onChange}
							rows={3}
							placeholder={`Enter ${name} ...`}
							name={value}
						/>
					</Form.Group>
				);

			case 'file':
				return (
					<Form.Group
						controlId="formFileSm"
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<Form.Control type="file" name={value} onChange={onChange} />
					</Form.Group>
				);

			case 'time':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
						controlId="startDate"
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<DatePicker
							selected={time}
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={15}
							timeCaption="Time"
							dateFormat="h:mm"
							onChange={newDate => {
								setTime(newDate);
								onChange(newDate)
							}}
							customInput={
								<CustomDateInput
									formControlProps={{
										placeholder: 'H:i'
									}}
								/>
							}
						/>
					</Form.Group>
				);
			case 'date':
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
						controlId="startDate"
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<DatePicker
							selected={date}
							onChange={newDate => {
								setDate(newDate);
								onChange(newDate)
							}}
							customInput={
								<CustomDateInput
									formControlProps={{
										placeholder: 'd/m/y'
									}}
								/>
							}
						/>
					</Form.Group>
				);

			default:
				return (
					<Form.Group
						className={classNames('position-relative hover-actions-trigger', {
							'mt-3': index !== 0
						})}
					>
						<Form.Label>{name} {required && <span className={"text-danger"}>*</span>}</Form.Label>
						<Form.Control
							onChange={onChange}
							type="text"
							name={value}
							placeholder={`Enter ${name} ...`}
						/>
					</Form.Group>
				);
		}
	}
};

EventCustomFieldItem.propTypes = {
	name: PropTypes.string,
	type: PropTypes.string,
	options: PropTypes.array,
	index: PropTypes.number,
	register: PropTypes.func,
	setValue: PropTypes.func,
	id: PropTypes.string,
	handleRemove: PropTypes.func
};

export default EventCustomFieldItem;
