import {Button, Card, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Field, FieldArray} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "../../../components/common/IconButton";
import CustomDateInput from "../../../components/common/CustomDateInput";
import DatePicker from 'react-datepicker';
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";
import Select from "react-select";
import FalconCardHeader from "../../../components/common/FalconCardHeader";

const EventSession = ({values, t, setFieldValue, errors, handleRemove}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null)
    const [speakers, setSpeakers] = useState([])
    const [selectedSpeakers, setSelectedSpeakers] = useState(null)
    const [page, setPage] = useState(1)

    const query = useQuery()

    query.set("page", page.toString())
    query.set("page_size", "50")

    const getUsers = async (q) => {
        return (await api.get(`/account/user/?${q.toString()}`)).data
    }

    useEffect(() => {
        getUsers(query).then(res => setSpeakers([...speakers, ...res?.results]))
            .catch(() => {
            })
        // eslint-disable-next-line
    }, [page]);

    const speakerOptions = speakers?.map(speaker => (
        {label: speaker?.full_name, value: speaker?.id}
    ))

    return (
        <Card className={"mt-3"}>
            <FalconCardHeader
                light
                title="Sessions and Activities"
                titleTag="h5"
                className="py-2"
                endEl={<IconButton
                    variant="falcon-default"
                    icon="trash"
                    size={'sm'}
                    iconClassName={'ms-1 text-danger'}
                    onClick={() => handleRemove("sessions")}
                >
                    {' '}
                </IconButton>}
            />
            <Card.Body>
                <FieldArray name={"sessions"}
                            render={arrayHelpers => (
                                <>
                                    {values?.sessions?.length > 0 ? (
                                        values?.sessions?.map((field, index) => (
                                                <div
                                                    className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
                                                    <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
                                                        <Button
                                                            size="sm"
                                                            variant="link"
                                                            className="p-0"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            <FontAwesomeIcon className="text-danger" icon="times-circle"/>
                                                        </Button>
                                                    </div>

                                                    <Row className="gx-2 gy-3">
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>Name <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Field name={`sessions.${index}.name`}>
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            type="text"
                                                                            name={`sessions.${index}.name`}
                                                                            placeholder={"Name"}
                                                                            value={field.value}
                                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>Location </Form.Label>
                                                                <Field name={`sessions.${index}.location`}>
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            type="text"
                                                                            name={`sessions.${index}.location`}
                                                                            placeholder={"Location"}
                                                                            value={field.value}
                                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>Start Date <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Row>
                                                                    <Field name={`sessions.${index}.start_date`}>
                                                                        {({field}) => (
                                                                            <DatePicker
                                                                                selected={values?.sessions[index]?.start_date ? new Date(values?.sessions[index]?.start_date) : startDate}
                                                                                defaultValue=""
                                                                                name={`schedules.${index}.start_date`}
                                                                                onChange={newDate => {
                                                                                    setStartDate(newDate)
                                                                                    setFieldValue(field.name, new Date(newDate).toISOString())
                                                                                }}
                                                                                showTimeSelect
                                                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                                                fixedHeight
                                                                                className='form-control'
                                                                                customInput={
                                                                                    <CustomDateInput
                                                                                        formControlProps={{
                                                                                            placeholder: 'MM/dd/yyyy h:mm aa',
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>End Date <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Row>
                                                                    <Field name={`sessions.${index}.end_date`}>
                                                                        {({field}) => (
                                                                            <DatePicker
                                                                                selected={values?.sessions[index]?.end_date ? new Date(values?.sessions[index]?.end_date) : endDate}
                                                                                defaultValue=""
                                                                                name={`schedules.${index}.end_date`}
                                                                                onChange={newDate => {
                                                                                    setEndDate(newDate)
                                                                                    setFieldValue(field.name, new Date(newDate).toISOString())
                                                                                }}
                                                                                showTimeSelect
                                                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                                                fixedHeight
                                                                                className='form-control'
                                                                                customInput={
                                                                                    <CustomDateInput
                                                                                        formControlProps={{
                                                                                            placeholder: 'MM/dd/yyyy h:mm aa',
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>Capacity </Form.Label>
                                                                <Field name={`sessions.${index}.capacity`}>
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            type="number"
                                                                            name={`sessions.${index}.capacity`}
                                                                            placeholder={"Capacity"}
                                                                            value={field.value}
                                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6">
                                                            <Form.Group>
                                                                <Form.Label>Speakers </Form.Label>
                                                                <Field name={`sessions.${index}.speakers`}>
                                                                    {({field}) => (
                                                                        <Select
                                                                            options={speakerOptions}
                                                                            placeholder={"Select Speakers"}
                                                                            value={selectedSpeakers}
                                                                            isMulti
                                                                            onChange={(newValue) => {
                                                                                setSelectedSpeakers(newValue)
                                                                                setFieldValue(field.name, newValue.map(v => v.value))
                                                                            }}
                                                                            classNamePrefix={"react-select"}
                                                                            onMenuScrollToBottom={() => {
                                                                                setPage(prevState => prevState + 1)
                                                                            }}
                                                                            onInputChange={newValue => {
                                                                                query.set("search", newValue)
                                                                                getUsers(query).then(res => setSpeakers(res?.results))
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Description <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Field name={`sessions.${index}.description`}>
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            name={`sessions.${index}.description`}
                                                                            placeholder={"Description"}
                                                                            as={"textarea"}
                                                                            className={"form-control"}
                                                                            value={field.value}
                                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        )) : (
                                        <></>
                                    )}
                                    <IconButton
                                        onClick={() => arrayHelpers.insert(values.schedules.length, '')}
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                    >
                                        {t('addItem', {ns: "common"})}
                                    </IconButton>
                                </>
                            )}
                />
            </Card.Body>
        </Card>
    )
}

export default EventSession