import {useNavigate} from "react-router-dom";
import {Card} from "react-bootstrap";
import IconButton from "../../components/common/IconButton";
import React from "react";
import Flex from "../../components/common/Flex";

const EventHeader = ({event}) => {
	const navigate = useNavigate();
	return (
		<Card>
			<Card.Header className="d-flex flex-between-center">
				<Flex alignItems={"center"}>
					<IconButton
						onClick={() => navigate(-1)}
						variant="falcon-default"
						size="sm"
						icon="arrow-left"
					/>
					<h5 className={"ms-2"}>{event?.title}</h5>
				</Flex>

			</Card.Header>
		</Card>
	);
}

export default EventHeader