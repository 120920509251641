import React, {useState} from "react";
import {Button, Form, Modal, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import CSRFToken from "../../../../helpers/CSRFToken";
import CustomDateInput from "../../../../components/common/CustomDateInput";
import FormError from "../../../errors/FormError";
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";

const OfficerTermModal = ({open, setOpen, id, fetch, name}) => {
    const [loading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(null)
    const [errors, setErrors] = useState({})
    const [officerFormData, setOfficerFormData] = useState({
        term_end_date: ""
    })

    const handleUpdateOfficer = async e => {
        setLoading(true)
        await api.patch(`/officer/officer/${id}/`, officerFormData)
            .then(res => {
                toast.success("Officer successfully updated.", {theme: "colored"})
                fetch()
                setOpen(!open)
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"});
                setErrors(err?.response?.data)
            })
        setLoading(false)
    }

    return (
        <Modal show={open === id} onHide={() => setOpen(!open)} centered size={"lg"}
               aria-labelledby="form-dialog-title">
            <Modal.Header closeButton>
                <h5 className="mb-1">{name}</h5>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <Form>
                        <CSRFToken/>
                        <Form.Group>
                            <Form.Label>Term End Date</Form.Label>
                            <Row>
                                <DatePicker
                                    selected={endDate}
                                    onChange={newDate => {
                                        setEndDate(newDate)
                                        setOfficerFormData({
                                            ...officerFormData,
                                            term_end_date: new Date(newDate).toISOString()
                                        })
                                    }}
                                    showTimeSelect
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    fixedHeight
                                    className='form-control'
                                    customInput={
                                        <CustomDateInput
                                            formControlProps={{
                                                placeholder: 'MM/dd/yyyy h:mm aa',
                                            }}
                                        />
                                    }
                                />
                            </Row>
                            <FormError error={errors?.term_end_date}/>
                        </Form.Group>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpen(!open)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleUpdateOfficer}>
                    Save <FontAwesomeIcon icon={faSave} className="ms-2"/>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OfficerTermModal