import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import CSRFToken from '../../helpers/CSRFToken';
import { useState } from 'react';

const ContactForm = ({t, i18n}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {};

  return (
    <Form>
      <CSRFToken />
      <Row>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{t('contact.fields.fullName')}</Form.Label>
            <Form.Control
              type={'text'}
              placeholder={t('contact.fields.fullName')}
              name={'name'}
              value={formData.name}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{t('contact.fields.email')}</Form.Label>
            <Form.Control
              type={'email'}
              placeholder={t('contact.fields.email')}
              name={'email'}
              value={formData.email}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>{t('contact.fields.message')}</Form.Label>
            <textarea
              placeholder={t('contact.fields.message')}
              className={'form-control'}
              rows={7}
              name={'email'}
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Button className={'mt-3'} onClick={handleSubmit}>
            {t('contact.submitBtn')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
