import {withPermission} from "../../../helpers/utils";
import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import EventHeader from "../EventHeader";
import TableView from "./TableView";
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";

const Attendance = () => {
	const [event, setEvent] = useState({});

	let query = useQuery();

	const getEvent = async () => {
		await api.get(`/event/event/${query.get("event")}/`).then(res => setEvent(res?.data))
	}

	useEffect(() => {
		getEvent()
		// eslint-disable-next-line
	}, []);

	return (
		<Row className={"g-3"}>
			<Col xxl={12} xl={12}>
				<EventHeader event={event}/>
			</Col>
			<Col>
				<TableView event={event}/>
			</Col>
		</Row>
	)
}

export default withPermission(Attendance, "attendance.view_attendance")