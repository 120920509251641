import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import {Link, useParams} from 'react-router-dom';
import Follower from 'components/app/social/followers/Follower';
import paths from "../../../../routes/paths";
import Member from "./Member";
import {useTranslation} from "react-i18next";

const Members = ({
  totalMembers,
  members,
  colBreakpoints = { xs: 6, md: 4, lg: 3, xxl: 2 }
}) => {
  const {id} = useParams()
  const {t} = useTranslation("common")
  return (
    <Card className="p-0">
      <Card.Header className="bg-light">
        <Flex justifyContent="between">
          <h5 className="mb-0">{t('titles.member')} ({totalMembers}) </h5>
          <Link to={paths.activityMembers.replace(":id", id)} className="font-sans-serif">
            {t('all')} {t('titles.member')}
          </Link>
        </Flex>
      </Card.Header>
      <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
        <Row className="gx-0 gy-1 text-center">
          {members?.map(member => (
            <Col key={member.id} {...colBreakpoints}>
              <Member member={member} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

Members.propTypes = {
  totalFollowers: PropTypes.number,
  followers: PropTypes.arrayOf(Follower.propTypes.follower),
  colBreakpoints: PropTypes.object
};

export default Members;
