import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import Flex from "../../../components/common/Flex";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../../BaseTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";

const Memberships = () => {
	const [memberships, setMemberships] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('name'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

	const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();

	const {hasPermission} = usePermissions()

	const navigate = useNavigate();

	const fetchMemberships = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);
		await api.get(`/membership/membership/?${q.toString()}`)
			.then(res => {
				setMemberships(res?.data?.results);
				setCount(res?.data?.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLength(memberships.length);
		setLoading(false);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchMemberships, 600), []);

	useEffect(() => {
		fetchMemberships(query);
		setLength(memberships.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

	const columns = [
		{
			accessor: 'name',
			Header: 'Name',
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'name' && sortingDirection,
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const {id, name} = rowData.row.original;
				return (
					<>
						<Link to={`/management/membership/${id}/edit`}>
							<Flex alignItems="center">
								<div className="flex-1">
									<h6
										className="mb-0 fs-0"
										style={{
											width: '200px',
											wordWrap: 'break-word',
											wordBreak: 'break-all',
											whiteSpace: 'pre-wrap'
										}}
									>
										{name}
									</h6>
								</div>
							</Flex>
						</Link>
					</>
				);
			}
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'membership_type',
			Header: 'Type',
			canSort: true,
			sortingDirection: sortingField === 'membership_type' && sortingDirection,
			isSorted: false,
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'monthly_price',
			Header: 'Monthly price',
			canSort: true,
			sortingDirection: sortingField === 'monthly_price' && sortingDirection,
			isSorted: false,
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'yearly_price',
			Header: 'Yearly price',
			canSort: true,
			sortingDirection: sortingField === 'yearly_price' && sortingDirection,
			isSorted: false,
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'yearly_saving',
			Header: 'Yearly saving',
			canSort: true,
			sortingDirection: sortingField === 'yearly_saving' && sortingDirection,
			isSorted: false,
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {id, name} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: 'Are you sure?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, delete it!'
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/membership/membership/${id}/`).then(() => {
							toast.success(
								`Membership ${name} successfully deleted.`,
								{
									theme: 'colored'
								}
							);
							fetchMemberships(query)
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("membership.change_membership") &&
								<Dropdown.Item onClick={() => navigate(paths.membershipEdit.replace(":id", id))}>
									Edit
								</Dropdown.Item>
							}
							<Dropdown.Divider as="div"/>
							{hasPermission("membership.delete_membership") &&
								<Dropdown.Item
									onClick={e => handleDelete(e)}
									className="text-danger"
								>
									Delete
								</Dropdown.Item>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	];

	const membershipList = memberships?.map(membership => ({
		id: membership.id,
		name: membership.name,
		monthly_price: membership.monthly_price,
		yearly_price: membership.yearly_price,
		membership_type: membership.membership_type,
		yearly_saving: membership.yearly_saving,
	}));

	const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={membershipList}
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"membership.add_membership"}
						creationPath={paths.membershipCreate}
						title={"membership"}
						deletePermission={"membership.delete_membership"}
						setLoading={setLoading}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchMemberships}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
}

export default Memberships