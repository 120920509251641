import React, {useEffect, useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import OrderSummary from './OrderSummary';
import PaymentMethod from './PaymentMethod';
import {useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import Flex from "../../../components/common/Flex";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {convertPrice} from "../../../helpers/utils";
import CSRFToken from "../../../helpers/CSRFToken";

const Checkout = () => {
    const [loading, setLoading] = useState(true);
    const [membership, setMembership] = useState({});
    const [currencyData, setCurrencyData] = useState({})
    const [payableTotal, setPayableTotal] = useState(0)
    const [plan, setPlan] = useState("");
    const [affiliates, setAffiliate] = useState(null)

    const {
        config: {currency, activity, affiliate}
    } = useAppContext()

    const {id} = useParams();
    let query = useQuery()

    const getAffiliate = async () => {
        setLoading(true)
        query.set("activity", activity)
        await api.get(`/affiliate/affiliate/${affiliate}/`).then(res => setAffiliate(res?.data))
            .catch(() => {})
        setLoading(false)
    }

    const getCurrencyData = async () => {
        await api.get(`/currency/${currency}/`).then(res => setCurrencyData(res?.data))
    }

    const getMembership = async () => {
        setLoading(true)
        await api.get(`/membership/membership/${id}/`)
            .then(res => setMembership(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        getAffiliate()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getMembership()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getCurrencyData()
        // eslint-disable-next-line
    }, [currency]);

    useEffect(() => {
        const fetchInfo = async () => {
            const convertedPrice = await convertPrice(plan === "month" ?
                affiliates ? membership?.monthly_price_with_taxes - (membership?.monthly_price_with_taxes * affiliates?.total_commission) / 100 : membership?.monthly_price_with_taxes :
                affiliates ? membership?.yearly_price_with_taxes - (membership?.yearly_price_with_taxes * affiliates?.total_commission) / 100 : membership?.yearly_price_with_taxes, membership?.currency, currency)
            setPayableTotal(convertedPrice)
        };

        fetchInfo();
        // eslint-disable-next-line
    }, [membership, currencyData]);

    useEffect(() => {
        setPlan(query.get("plan"))
    }, [query]);

    return loading ? (
        <Flex justifyContent="center" alignItems={"center"} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Row className="g-3">
            <CSRFToken />
            <Col xl={{span: 4, order: 1}}>
                <OrderSummary
                    className="mb-3"
                    membership={membership}
                    payableTotal={payableTotal}
                    currencyData={currencyData}
                    promo={affiliates?.total_commission}
                />
            </Col>
            <Col xl={8}>
                <PaymentMethod membership={membership} payableTotal={payableTotal} currencyData={currencyData}/>
            </Col>
        </Row>
    );
};

export default Checkout;
