import React, { Fragment, useEffect, useState } from 'react';
import Flex from '../../../components/common/Flex';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import FormError from '../../errors/FormError';
import useQuery from '../../../hooks/useQuery';
import { getActivities } from '../../activity/actions/Activity';
import Select from 'react-select';
import EventReport from './EventReport';
import MeetingReport from './MeetingReport';
import CardHeader from 'react-bootstrap/CardHeader';
import IconButton from '../../../components/common/IconButton';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { api } from '../../../utils/api';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import CSRFToken from '../../../helpers/CSRFToken';
import {withPermission} from '../../../helpers/utils';
import { useAppContext } from '../../../providers/AppProvider';

const ReportAddEdit = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    title: '',
    type: '',
    activity: '',
    subject: [],
    date: '',
    location: '',
    description: '',
    event: '',
    members: [],
    plannings: [],
    objectives: [],
    expenses: [],
    actionplans: [],
    results: [],
    agendas: []
  });
  const {
    config: { group },
    setConfig
  } = useAppContext();

  const { id } = useParams();
  const activityQuery = useQuery();
  const navigate = useNavigate();

  const getReport = async () => {
    setLoading(true);
    await api
      .get(`/report/report/${id}/`)
      .then(async res => {
        setSelectedActivity({
          value: res?.data?.activity,
          label: res?.data?.activity_name
        });
        setFormData({
          ...res?.data
        });
        if (res?.data?.type === 'event') {
          await api.get(`/report/eventreport/${id}/`).then(report => {
            const updatedExpenses = report?.data?.expense_set?.map(expense => ({
              name: expense?.name,
              amount: expense?.amount
            }));
            const updatedObjectives = report?.data?.objective_set?.map(
              objective => ({
                name: objective?.name
              })
            );
            const updatedPlannings = report?.data?.planning_set?.map(
              planning => ({
                name: planning?.name,
                time: planning?.time,
                description: planning?.description
              })
            );
            setFormData({
              ...report?.data,
              expenses: updatedExpenses,
              objectives: updatedObjectives,
              plannings: updatedPlannings
            });
          });
        } else if (res?.data?.type === 'meeting') {
          await api.get(`/report/meetreport/${id}/`).then(report => {
            const updatedActionPlans = report?.data?.actionplan_set?.map(
              action => ({
                action: action?.action,
                assigned: action?.assigned
              })
            );
            const updatedAgendas = report?.data?.agenda_set?.map(agenda => ({
              title: agenda?.title
            }));
            const updatedResults = report?.data?.result_set?.map(result => ({
              name: result?.name
            }));
            setFormData({
              ...report?.data,
              actionplans: updatedActionPlans,
              agendas: updatedAgendas,
              results: updatedResults
            });
          });
        }
      })
      .catch(err => {
        if (err?.response?.status === 404) navigate('/errors/404');
      });
    setLoading(false);
  };

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', true);
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    setLoading(true);
    activityQuery.set('page_size', '50');
    activityQuery.set('page', currentPage);
    getActivities(activityQuery)
      // eslint-disable-next-line no-unsafe-optional-chaining
      .then(res => setActivities([...activities, ...res?.results]))
      .catch(() => {});
    setLoading(false);
    // eslint-disable-next-line
    }, [currentPage]);

  useEffect(() => {
    if (id) getReport();
    // eslint-disable-next-line
    }, []);

  let activityOptions = activities.map(activity => ({
    label: activity.name,
    value: activity.id
  }));

  activityOptions = [
    { label: '---------------------------', value: '' },
    ...activityOptions
  ];

  const handleSubmit = (e, values) => {
    setLoading(true);
    e.preventDefault();
    console.log(values);
    if (values.type === 'event')
      if (id)
        api
          .patch(`/report/eventreport/${id}/`, values)
          .then(res => {
            toast.success(`Report ${res?.data?.title} updated successfully.`);
            navigate(`/reports/${id}/detail`);
          })
          .catch(err => {
            setFormData(values);
            toast.error(`An error has occurred.`);
            setErrors(err?.response?.data);
          });
      else
        api
          .post('/report/eventreport/', values)
          .then(res => {
            toast.success(`Report ${res?.data?.title} created successfully.`);
            navigate(`/reports/${res?.data?.id}/detail`);
          })
          .catch(err => {
            setFormData(values);
            toast.error(`An error has occurred.`);
            setErrors(err?.response?.data);
          });
    else if (values?.type === 'meeting')
      if (id)
        api
          .patch(`/report/meetreport/${id}/`, values)
          .then(res => {
            toast.success(`Report ${res?.data?.title} updated successfully.`);
            navigate(`/reports/${id}/detail`);
          })
          .catch(err => {
            setFormData(values);
            toast.error(`An error has occurred.`);
            setErrors(err?.response?.data);
          });
      else
        api
          .post('/report/meetreport/', values)
          .then(res => {
            toast.success(`Report ${res?.data?.title} created successfully.`);
            navigate(`/reports/${res?.data?.id}/detail`);
          })
          .catch(err => {
            setFormData(values);
            toast.error(`An error has occurred.`);
            setErrors(err?.response?.data);
          });
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
      <Spinner animation={'border'} variant={'primary'} />
    </Flex>
  ) : (
    <Formik initialValues={formData} onSubmit={values => console.log(values)}>
      {({ values, setFieldValue }) => (
        <>
          <Form>
            <CSRFToken />
            <Row className={'g-3 mt-1 mb-3'}>
              <Col xxl={12} xl={12}>
                <Row className="g-3">
                  <Col xs={12}>
                    <Card>
                      <Card.Header>
                        <h5 className="mb-0 text-muted">Basic Information</h5>
                      </Card.Header>
                      <Card.Body>
                        {group.includes(
                          process.env.REACT_APP_ADMIN_GROUP_NAME
                        ) && (
                          <Form.Group>
                            <Form.Label>Activity:</Form.Label>
                            <Field name="activity">
                              {({ field }) => (
                                <Select
                                  name="activity"
                                  options={activityOptions}
                                  onMenuScrollToBottom={e => {
                                    console.log(e);
                                    setCurrentPage(page => page + 1);
                                  }}
                                  onInputChange={e => {
                                    activityQuery.set('search', e);
                                    getActivities(activityQuery).catch(
                                      () => {}
                                    );
                                  }}
                                  placeholder="Select activity"
                                  classNamePrefix="react-select"
                                  value={selectedActivity}
                                  onChange={value => {
                                    setFieldValue(field.name, value.value);
                                    setSelectedActivity(value);
                                  }}
                                />
                              )}
                            </Field>
                            <FormError error={errors.activity} />
                          </Form.Group>
                        )}
                        <Row>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label>Title:</Form.Label>
                              <Field name="title">
                                {({ field }) => (
                                  <Form.Control
                                    type="text"
                                    name="title"
                                    placeholder="Title"
                                    errors={errors}
                                    formGroupProps={{
                                      className: 'mb-3'
                                    }}
                                    onChange={e => {
                                      setFieldValue(field.name, e.target.value);
                                    }}
                                    value={field.value}
                                  />
                                )}
                              </Field>
                              <FormError error={errors.title} />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label>Type:</Form.Label>
                              <Field name="type">
                                {({ field }) => (
                                  <Form.Select
                                    name="type"
                                    formGroupProps={{
                                      className: 'mb-3'
                                    }}
                                    onChange={e => {
                                      setFieldValue(field.name, e.target.value);
                                    }}
                                    value={field.value}
                                  >
                                    <option value={''}>
                                      Select report type
                                    </option>
                                    <option value={'event'}>Event</option>
                                    <option value={'meeting'}>Meeting</option>
                                  </Form.Select>
                                )}
                              </Field>
                              <FormError error={errors.type} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  {values.type === 'event' ? (
                    <EventReport
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  ) : values.type === 'meeting' ? (
                    <MeetingReport
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
          </Form>
          <Card>
            <CardHeader>
              <Flex justifyContent={'end'} wrap={'wrap'}>
                <IconButton
                  icon={faSave}
                  onClick={e => handleSubmit(e, values)}
                >
                  <span className="d-none d-sm-inline-block ms-1">Save</span>
                </IconButton>
              </Flex>
            </CardHeader>
          </Card>
        </>
      )}
    </Formik>
  );
};

export default withPermission(ReportAddEdit, "report.add_report");
