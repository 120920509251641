import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const GDPR = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="gdpr" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">General Data Protection Regulation</h5>
			</Card.Header>
			<Card.Body>
				<h6>Information about General Data Protection Regulation (GDPR)</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We may be collecting and using information from you if you are from the European Economic Area (EEA),
					and in this section of our Privacy Policy we are going to explain exactly how and why is this data collected,
					and how we maintain this data under protection from being replicated or used in the wrong way.
				</p>
				<hr className="my-4"/>
				<h6>What is GDPR?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is protected by
					companies and enhances the control the EU residents have, over their personal data.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					The GDPR is relevant to any globally operating company and not just the EU-based businesses and EU
					residents. Our customers’ data is important irrespective of where they are located, which is why we have
					implemented GDPR controls as our baseline standard for all our operations worldwide.
				</p>
				<hr className="my-4"/>
				<h6>What is personal data?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of information
					that could be used on its own, or in combination with other pieces of information, to identify a person.
					Personal data extends beyond a person’s name or email address. Some examples include financial
					information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual
					orientation, and ethnicity.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					The Data Protection Principles include requirements such as:
				</p>
				<ul type="1">
					<li>
						Personal data collected must be processed in a fair, legal, and transparent way and should only be
						used in a way that a person would reasonably expect.
					</li>
					<li>
						Personal data should only be collected to fulfil a specific purpose and it should only be used for that
						purpose. Organizations must specify why they need the personal data when they collect it.
					</li>
					<li>
						Personal data should be held no longer than necessary to fulfil its purpose.
					</li>
					<li>
						People covered by the GDPR have the right to access their own personal data. They can also request a
						copy of their data, and that their data be updated, deleted, restricted, or moved to another organization.
					</li>
				</ul>
				<hr className="my-4"/>
				<h6>Why is GDPR important?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					GDPR adds some new requirements regarding how companies should protect individuals' personal data that
					they collect and process. It also raises the stakes for compliance by increasing enforcement and imposing
					greater fines for breach. Beyond these facts it's simply the right thing to do. At nexameet we strongly
					believe
					that your data privacy is very important and we already have solid security and privacy practices in place
					that
					go beyond the requirements of this new regulation.
				</p>
				<hr className="my-4"/>
				<h6>Individual Data Subject's Rights - Data Access, Portability and Deletion</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We are committed to helping our customers meet the data subject rights requirements of GDPR. Nexameet
					processes or stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation
					and personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data in
					accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than 60 days.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					We are aware that if you are working with EU customers, you need to be able to provide them with the ability
					to access, update, retrieve and remove personal data. We got you! We've been set up as self service from
					the start and have always given you access to your data and your customers data. Our customer support
					team is here for you to answer any questions you might have about working with the API.
				</p>
			</Card.Body>
		</Card>
	)
})

export default GDPR