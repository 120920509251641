import React from "react";
import App from "App";
import paths, {rootPaths} from "./paths";
import {Navigate, createBrowserRouter} from "react-router-dom";

import AuthSimpleLayout from "../layouts/AuthSimpleLayout";
import MainLayout from "../layouts/MainLayout";
import ErrorLayout from "../layouts/ErrorLayout";

import WizardAuth from "pages/authentication/wizard/WizardAuth";
import Landing from "../pages/landing/Landing";

import EventList from "../pages/events/EventList";
import {EventList as EventLanding} from "../pages/landing/EventList";
import {EventDetail as EventDetailLanding} from "../pages/landing/DetailEvent/EventDetail";
import EventDetail from "../pages/events/DetailEvent/EventDetail";
import ProjectManagement from "pages/dashboard/project-management";

import Error404 from "../pages/errors/Error404";
import Error500 from "../pages/errors/Error500";

import SimpleLogin from "pages/authentication/simple/Login";
import SimpleLogout from "pages/authentication/simple/Logout";
import SimpleRegistration from "pages/authentication/simple/Registration";
import SimpleForgetPassword from "pages/authentication/simple/ForgetPassword";
import SimplePasswordReset from "pages/authentication/simple/PasswordReset";
import SimpleConfirmMail from "pages/authentication/simple/ConfirmMail";
import SimpleVerifyMail from "pages/authentication/simple/VerifyMail";
import SimpleVerifyAccount from "pages/authentication/simple/VerifyAccount";
import SimpleLockScreen from "pages/authentication/simple/LockScreen";

import CardLogin from "pages/authentication/card/Login";
import Logout from "pages/authentication/card/Logout";
import CardRegistration from "pages/authentication/card/Registration";
import CardForgetPassword from "pages/authentication/card/ForgetPassword";
import CardConfirmMail from "pages/authentication/card/ConfirmMail";
import CardVerifyMail from "pages/authentication/card/VerifyMail";
import CardVerifyAccount from "pages/authentication/card/VerifyAccount";
import CardPasswordReset from "pages/authentication/card/PasswordReset";
import CardLockScreen from "pages/authentication/card/LockScreen";

import SplitLogin from "pages/authentication/split/Login";
import SplitLogout from "pages/authentication/split/Logout";
import SplitRegistration from "pages/authentication/split/Registration";
import SplitForgetPassword from "pages/authentication/split/ForgetPassword";
import SplitPasswordReset from "pages/authentication/split/PasswordReset";
import SplitConfirmMail from "pages/authentication/split/ConfirmMail";
import SplitVerifyMail from "pages/authentication/split/VerifyMail";
import SplitVerifyAccount from "pages/authentication/split/VerifyAccount";
import SplitLockScreen from "pages/authentication/split/LockScreen";

import NewEvent from "../pages/events/NewEditEvent/NewEvent";
import Calendar from "../pages/calendar/Calendar";
import Analytics from "../pages/dashboard/analytics";
import Starter from "../pages/Starter";
import Feed from "../pages/feed/Feed";

import Settings from "../pages/account/settings/Settings";
import Profile from "../pages/account/profile/Profile";
import Notifications from "../pages/notifications/Notifications";
import Followers from "../pages/account/followers/Followers";
import EditEvent from "../pages/events/NewEditEvent/EditEvent";
import Connections from "../pages/authentication/connections/Connections";
import Emails from "../pages/account/emails/Emails";
import Experiences from "../pages/account/experiences/Experiences";
import Educations from "../pages/account/educations/Educations";
import Users from "../pages/account/users/Users";
import NewUser from "../pages/account/users/NewUser/NewUser";
import EditUser from "../pages/account/users/EditUser/EditUser";
import Activities from "../pages/activity/activities/Activities";
import Members from "../pages/member/members/Members";
import Roles from "../pages/member/roles/Roles";
import NewMember from "../pages/member/members/NewMember/NewMember";
import ChangeLog from "../pages/timeline/ChangeLog";
import EditMember from "../pages/member/members/EditMember/EditMember";
import NewEditRole from "../pages/member/roles/NewEditRole/NewEditRole";
import BillingList from "../pages/billing/BillingList";
import Sponsors from "../pages/management/sponsors/Sponsor/Sponsors";
import SponsorsAddEdit from "../pages/management/sponsors/Sponsor/SponsorsAddEdit/SponsorsAddEdit";
import Organizers from "../pages/management/organizers/Organizers";
import OrganizersAddEdit from "../pages/management/organizers/OrganizersAddEdit/OrganizersAddEdit";
import Groups from "../pages/account/groups/Groups";
import NewGroup from "../pages/account/groups/NewGroup/NewGroup";
import EditGroup from "../pages/account/groups/EditGroup/EditGroup";
import Address from "../pages/address/addresses/Address";
import NewAddress from "../pages/address/addresses/NewAddress/NewAddress";
import EditAddress from "../pages/address/addresses/EditAddress/EditAddress";
import NewActivity from "../pages/activity/activities/NewActivity/NewActivity";
import EditActivity from "../pages/activity/activities/EditActivity/EditActivity";
import DetailActivity from "../pages/activity/activities/DetailActivity/DetailActivity";
import Reports from "../pages/reports/Reports";
import ReportAddEdit from "../pages/reports/ReportAddEdit/ReportAddEdit";
import ReportDetail from "../pages/reports/ReportDetail/ReportDetail";
import Transactions from "../pages/transaction/Transactions";
import Requests from "../pages/management/requests/Requests";
import RequestDetail from "../pages/management/requests/RequestDetail/RequestDetail";
import {Callback as Calendly} from "../pages/authentication/calendly/Callback";
import {Callback as Google} from "../pages/authentication/google/Callback";
import {Callback as Zoom} from "../pages/authentication/zoom/Callback";
import Companies from "../pages/management/companies/Companies";
import CompaniesAddEdit from "../pages/management/companies/CompaniesAddEdit/CompaniesAddEdit";
import KonnectWebhook from "../pages/billing/actions/konnect/KonnectWebhook";
import ActivityMembers from "../pages/activity/activities/ActivityMembers/ActivityMembers";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "../pages/terms-conditions/TermsAndConditions";
import LandingLayout from "../layouts/LandingLayout";
import EmailVerification from "../pages/account/emails/EmailVerification";
import Rules from "../pages/management/rule/Rules";
import RuleAddEdit from "../pages/management/rule/RuleAddEdit/RuleAddEdit";
import RuleDetail from "../pages/management/rule/RuleDetail/RuleDetail";
import Error403 from "../pages/errors/Error403";
import Attendance from "../pages/events/EventAttendance/Attendance";
import Contracts from "../pages/management/contract/Contracts";
import ContractAddEdit from "../pages/management/contract/ContractAddEdit/ContractAddEdit";
import Contract from "../pages/management/contract/Contract";
import UserExports from "../pages/account/users/UserExports";
import GroupExports from "../pages/account/groups/GroupExports";
import MemberExports from "../pages/member/members/MemberExports";
import RoleExports from "../pages/member/roles/RoleExports";
import AddressExports from "../pages/address/addresses/AddressExports";
import JoinExports from "../pages/management/requests/JoinExports";
import BillingExports from "../pages/billing/BillingExports";
import ActivityExports from "../pages/activity/activities/ActivityExports";
import ActivitySettings from "../pages/activity/settings/Settings";
import PricingDefault from "../pages/pricing-default/PricingDefault";
import Memberships from "../pages/management/memberships/Memberships";
import MembershipAddEdit from "../pages/management/memberships/MembershipAddEdit/MemershipAddEdit";
import Checkout from "../pages/pricing-default/checkout/Checkout";
import BillingDetail from "../pages/billing/BillingDetail/BillingDetail";
import Payment from "../pages/pricing-default/payment/Payment";
import Cancelled from "../pages/pricing-default/payment/Cancelled";
import Saas from "../pages/dashboard/saas";
import NewTimeline from "pages/timeline/NewTimeline";
import EventImporter from "../pages/events/import/EventImporter";
import ImportFiles from "../pages/events/import/ImportFiles";
import ImportFileDetail from "../pages/events/import/detail/ImportFileDetail";
import EventFinance from "../pages/events/EventFinance/EventFinance";
import Posts from "../pages/management/blog/Posts";
import PostAddEdit from "../pages/management/blog/PostAddEdit/PostAddEdit";
import BlogDetail from "../pages/landing/BlogDetail/BlogDetail";
import Testimonials from "../pages/management/testimonial/Testimonials";
import TestimonialAddEdit from "../pages/management/testimonial/TestimonialAddEdit/TestimonialAddEdit";
import MemberImportFiles from "../pages/member/members/import/MemberImportFiles";
import MemberImporter from "../pages/member/members/import/MemberImporter";
import ImportMemberDetail from "../pages/member/members/import/detail/ImportMemberDetail";
import PeriodicTasks from "../pages/management/periodic-tasks/PeriodicTasks";
import PeriodicTaskAddEdit from "../pages/management/periodic-tasks/PeriodicTaskAddEdit/PeriodicTaskAddEdit";
import Bugs from "../pages/bug/Bugs";
import BugCreate from "../pages/bug/BugCreate";
import BugDetail from "../pages/bug/BugDetail";
import Tutorials from "../pages/tutorial/Tutorials";
import TutorialAddEdit from "../pages/tutorial/TutorialAddEdit/TutorialAddEdit";
import TutorialDetail from "../pages/tutorial/TutorialDetail/TutorialDetail";
import Newsletters from "../pages/management/newsletter/Newsletters";
import NewsletterAddEdit from "../pages/management/newsletter/NewsletterAddEdit/NewsletterAddEdit";
import UserHistory from "../pages/account/history/UserHistory";
import EventTasks from "../pages/events/EventTasks/EventTasks";
import OrganizerDetail from "../pages/management/organizers/OrganizerDetail/OrganizerDetail";
import SponsorDetail from "../pages/management/sponsors/Sponsor/SponsorDetail/SponsorDetail";
import Providers from "../pages/provider/Providers";
import ProviderAddEdit from "../pages/provider/ProviderAddEdit/ProviderAddEdit";
import MemberDetail from "../pages/member/members/MemberDetail/MemberDetail";
import TransactionDetail from "../pages/transaction/TransactionDetail/TransactionDetail";
import TransactionKonnectWebhook from "../pages/member/members/MemberDetail/payment/TransactionKonnectWebhook";
import TransactionCancelled from "../pages/member/members/MemberDetail/payment/Cancelled";
import TransactionPayment from "../pages/member/members/MemberDetail/payment/Payment";
import AttendanceDetail from "../pages/events/EventAttendance/AttendanceDetail/AttendanceDetail";
import TableView from "../pages/support-desk/tickets-layout/TableView";
import CardView from "../pages/support-desk/tickets-layout/CardView";
import TicketsPreview from "../pages/support-desk/tickets-preview/TicketsPreview";
import TicketCreate from "../pages/support-desk/new-ticket/TicketCreate";
import Currencies from "../pages/management/currency/Currencies";
import CurrencyAddEdit from "../pages/management/currency/CurrencyAddEdit/CurrencyAddEdit";
import Enotices from "../pages/emails/Enotices";
import EnoticeAddEdit from "../pages/emails/EnoticeAddEdit/EnoticeAddEdit";
import EnoticeDetail from "../pages/emails/EnoticeDetail/EnoticeDetail";

const routes = [
    {
        element: <App/>,
        children: [
            {
                path: rootPaths.root,
                element: <LandingLayout/>,
                children: [
                    {
                        path: paths.landing,
                        element: <Landing/>
                    },
                    {
                        path: paths.privacyPolicy,
                        element: <PrivacyPolicy/>
                    },
                    {
                        path: paths.termsConditions,
                        element: <TermsAndConditions/>
                    },
                    {
                        path: paths.eventsLanding,
                        element: <EventLanding/>
                    },
                    {
                        path: paths.eventDetailLanding,
                        element: <EventDetailLanding/>
                    },
                    {
                        path: paths.blogLandingDetail,
                        element: <BlogDetail/>
                    },
                ]
            },
            {
                path: rootPaths.errorsRoot,
                element: <ErrorLayout/>,
                children: [
                    {
                        path: paths.error404,
                        element: <Error404/>,
                    },
                    {
                        path: paths.error403,
                        element: <Error403/>,
                    },
                    {
                        path: paths.error500,
                        element: <Error500/>,
                    },
                ],
            },
            {
                path: rootPaths.authRoot,
                children: [
                    {
                        path: paths.callbackCalendly,
                        element: <Calendly/>,
                    },
                    {
                        path: paths.callbackGoogle,
                        element: <Google/>,
                    },
                    {
                        path: paths.callbackZoom,
                        element: <Zoom/>,
                    },
                    {
                        path: rootPaths.authSimpleRoot,
                        element: <AuthSimpleLayout/>,
                        children: [
                            {
                                path: paths.simpleLogin,
                                element: <SimpleLogin/>,
                            },
                            {
                                path: paths.simpleRegister,
                                element: <SimpleRegistration/>,
                            },
                            {
                                path: paths.simpleLogout,
                                element: <SimpleLogout/>,
                            },
                            {
                                path: paths.simpleForgotPassword,
                                element: <SimpleForgetPassword/>,
                            },
                            {
                                path: paths.simpleResetPassword,
                                element: <SimplePasswordReset/>,
                            },
                            {
                                path: paths.simpleConfirmMail,
                                element: <SimpleConfirmMail/>,
                            },
                            {
                                path: paths.simpleVerifyMail,
                                element: <SimpleVerifyMail/>,
                            },
                            {
                                path: paths.simpleVerify,
                                element: <SimpleVerifyAccount/>,
                            },
                            {
                                path: paths.simpleLockScreen,
                                element: <SimpleLockScreen/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.authCardRoot,
                        children: [
                            {
                                path: paths.cardLogin,
                                element: <CardLogin/>,
                            },
                            {
                                path: paths.cardRegister,
                                element: <CardRegistration/>,
                            },
                            {
                                path: paths.cardLogout,
                                element: <Logout/>,
                            },
                            {
                                path: paths.cardForgotPassword,
                                element: <CardForgetPassword/>,
                            },
                            {
                                path: paths.cardResetPassword,
                                element: <CardPasswordReset/>,
                            },
                            {
                                path: paths.cardConfirmMail,
                                element: <CardConfirmMail/>,
                            },
                            {
                                path: paths.cardVerifyMail,
                                element: <CardVerifyMail/>,
                            },
                            {
                                path: paths.cardVerify,
                                element: <CardVerifyAccount/>,
                            },
                            {
                                path: paths.cardLockScreen,
                                element: <CardLockScreen/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.authSplitRoot,
                        children: [
                            {
                                path: paths.splitLogin,
                                element: <SplitLogin/>,
                            },
                            {
                                path: paths.splitRegister,
                                element: <SplitRegistration/>,
                            },
                            {
                                path: paths.splitLogout,
                                element: <SplitLogout/>,
                            },
                            {
                                path: paths.splitForgotPassword,
                                element: <SplitForgetPassword/>,
                            },
                            {
                                path: paths.splitResetPassword,
                                element: <SplitPasswordReset/>,
                            },
                            {
                                path: paths.splitConfirmMail,
                                element: <SplitConfirmMail/>,
                            },
                            {
                                path: paths.splitVerifyMail,
                                element: <SplitVerifyMail/>,
                            },
                            {
                                path: paths.splitVerify,
                                element: <SplitVerifyAccount/>,
                            },
                            {
                                path: paths.splitLockScreen,
                                element: <SplitLockScreen/>,
                            },
                        ],
                    },
                    {
                        path: paths.authWizard,
                        element: <WizardAuth/>,
                    },
                ],
            },
            {
                path: rootPaths.root,
                element: <MainLayout/>,
                children: [
                    {
                        path: rootPaths.dashboardRoot,
                        element: <ProjectManagement/>,
                    },
                    {
                        path: paths.analytics,
                        element: <Analytics/>,
                    },
                    {
                        path: paths.dashboardFinance,
                        element: <Saas/>,
                    },
                    {
                        path: paths.pricingList,
                        element: <PricingDefault/>,
                    },
                    {
                        path: rootPaths.appsRoot,
                        children: [
                            {
                                path: paths.calendar,
                                element: <Calendar/>,
                            },
                            {
                                path: paths.reports,
                                element: <Reports/>,
                            },
                            {
                                path: paths.reportsCreate,
                                element: <ReportAddEdit/>,
                            },
                            {
                                path: paths.reportsEdit,
                                element: <ReportAddEdit/>,
                            },
                            {
                                path: paths.reportsDetail,
                                element: <ReportDetail/>,
                            },
                            {
                                path: paths.transactions,
                                element: <Transactions/>,
                            },
                            {
                                path: paths.transactionDetail,
                                element: <TransactionDetail/>,
                            },
                            {
                                path: paths.transactionKonnectWebhook,
                                element: <TransactionKonnectWebhook/>,
                            },
                            {
                                path: paths.transactionPayment,
                                element: <TransactionPayment/>
                            },
                            {
                                path: paths.transactionPaymentCancelled,
                                element: <TransactionCancelled/>
                            },
                        ],
                    },
                    {
                        path: rootPaths.userRoot,
                        children: [
                            {
                                path: paths.userProfile,
                                element: <Settings/>,
                            },
                            {
                                path: paths.userConnections,
                                element: <Connections showSocialButtons={true}/>,
                            },
                            {
                                path: paths.userFollowers,
                                element: <Followers/>,
                            },
                            {
                                path: paths.userEmails,
                                element: <Emails/>,
                            },
                            {
                                path: paths.userEmailVerification,
                                element: <EmailVerification/>,
                            },
                            {
                                path: paths.userExperiences,
                                element: <Experiences/>,
                            },
                            {
                                path: paths.userEducations,
                                element: <Educations/>,
                            }
                        ],
                    },
                    {
                        path: rootPaths.socialRoot,
                        children: [
                            {
                                path: paths.feed,
                                element: <Feed/>,
                            },
                            {
                                path: paths.notifications,
                                element: <Notifications/>,
                            },
                            {
                                path: paths.followers,
                                element: <Followers/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.pagesRoot,
                        children: [
                            {
                                path: paths.starter,
                                element: <Starter/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.accountRoot,
                        children: [
                            {
                                path: paths.users,
                                element: <Users/>,
                            },
                            {
                                path: paths.usersAdd,
                                element: <NewUser/>,
                            },
                            {
                                path: paths.usersEdit,
                                element: <EditUser/>,
                            },
                            {
                                path: paths.usersDetail,
                                element: <Profile/>,
                            },
                            {
                                path: paths.usersHistory,
                                element: <UserHistory/>,
                            },
                            {
                                path: paths.usersFollowers,
                                element: <Followers/>,
                            },
                            {
                                path: paths.groups,
                                element: <Groups/>,
                            },
                            {
                                path: paths.groupsAdd,
                                element: <NewGroup/>,
                            },
                            {
                                path: paths.groupsEdit,
                                element: <EditGroup/>,
                            },
                            {
                                path: paths.usersExport,
                                element: <UserExports/>
                            },
                            {
                                path: paths.groupsExport,
                                element: <GroupExports/>
                            }
                        ],
                    },
                    {
                        path: rootPaths.activitiesRoot,
                        children: [
                            {
                                path: paths.activityList,
                                element: <Activities/>,
                            },
                            {
                                path: paths.activityCreate,
                                element: <NewActivity/>,
                            },
                            {
                                path: paths.activityEdit,
                                element: <EditActivity/>,
                            },
                            {
                                path: paths.activityDetail,
                                element: <DetailActivity/>,
                            },
                            {
                                path: paths.activityMembers,
                                element: <ActivityMembers/>,
                            },
                            {
                                path: paths.activityExports,
                                element: <ActivityExports/>
                            },
                            {
                                path: paths.activitySettings,
                                element: <ActivitySettings/>
                            }
                        ],
                    },
                    {
                        path: rootPaths.membersRoot,
                        children: [
                            {
                                path: paths.members,
                                element: <Members/>,
                            },
                            {
                                path: paths.membersAdd,
                                element: <NewMember/>,
                            },
                            {
                                path: paths.membersEdit,
                                element: <EditMember/>,
                            },
                            {
                                path: paths.membersDetail,
                                element: <MemberDetail/>,
                            },
                            {
                                path: paths.membersExports,
                                element: <MemberExports/>,
                            },
                            {
                                path: paths.membersImports,
                                element: <MemberImportFiles/>,
                            },
                            {
                                path: paths.importMember,
                                element: <MemberImporter/>,
                            },
                            {
                                path: paths.membersImportDetail,
                                element: <ImportMemberDetail/>,
                            },
                            {
                                path: paths.roles,
                                element: <Roles/>,
                            },
                            {
                                path: paths.rolesAdd,
                                element: <NewEditRole/>,
                            },
                            {
                                path: paths.rolesEdit,
                                element: <NewEditRole/>,
                            },
                            {
                                path: paths.rolesExports,
                                element: <RoleExports/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.enoticeRoot,
                        children: [
                            {
                                path: paths.enotice,
                                element: <Enotices/>
                            },
                            {
                                path: paths.enoticeCreate,
                                element: <EnoticeAddEdit/>
                            },
                            {
                                path: paths.enoticeEdit,
                                element: <EnoticeAddEdit/>
                            },
                            {
                                path: paths.enoticeDetail,
                                element: <EnoticeDetail/>
                            },
                        ]
                    },
                    {
                        path: rootPaths.eventsRoot,
                        children: [
                            {
                                path: paths.events,
                                element: <EventList/>,
                            },
                            {
                                path: paths.createEvent,
                                element: <NewEvent/>,
                            },
                            {
                                path: paths.editEvent,
                                element: <EditEvent/>,
                            },
                            {
                                path: paths.eventDetail,
                                element: <EventDetail/>,
                            },
                            {
                                path: paths.eventTasks,
                                element: <EventTasks/>,
                            },
                            {
                                path: paths.eventAttendanceTable,
                                element: <Attendance/>,
                            },
                            {
                                path: paths.eventAttendanceDetail,
                                element: <AttendanceDetail/>,
                            },
                            {
                                path: paths.eventFinance,
                                element: <EventFinance/>,
                            },
                            {
                                path: paths.importEvent,
                                element: <EventImporter/>,
                            },
                            {
                                path: paths.importList,
                                element: <ImportFiles/>,
                            },
                            {
                                path: paths.importDetail,
                                element: <ImportFileDetail/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.timelineRoot,
                        children: [
                            {
                                path: paths.timeline,
                                element: <ChangeLog/>,
                            },
                            {
                                path: paths.timelineCreate,
                                element: <NewTimeline/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.bugRoot,
                        children: [
                            {
                                path: paths.bug,
                                element: <Bugs/>,
                            },
                            {
                                path: paths.bugCreate,
                                element: <BugCreate/>,
                            },
                            {
                                path: paths.bugDetail,
                                element: <BugDetail/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.tutorialRoot,
                        children: [
                            {
                                path: paths.tutorial,
                                element: <Tutorials/>,
                            },
                            {
                                path: paths.tutorialCreate,
                                element: <TutorialAddEdit/>,
                            },
                            {
                                path: paths.tutorialEdit,
                                element: <TutorialAddEdit/>,
                            },
                            {
                                path: paths.tutorialDetail,
                                element: <TutorialDetail/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.billingRoot,
                        children: [
                            {
                                path: paths.billingList,
                                element: <BillingList/>,
                            },
                            {
                                path: paths.billingExports,
                                element: <BillingExports/>,
                            },
                            {
                                path: paths.billingDetail,
                                element: <BillingDetail/>
                            },
                            {
                                path: paths.konnectWebhook,
                                element: <KonnectWebhook/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.managementRoot,
                        children: [
                            {
                                path: paths.blog,
                                element: <Posts/>,
                            },
                            {
                                path: paths.blogCreate,
                                element: <PostAddEdit/>,
                            },
                            {
                                path: paths.blogEdit,
                                element: <PostAddEdit/>,
                            },
                            {
                                path: paths.testimonial,
                                element: <Testimonials/>,
                            },
                            {
                                path: paths.testimonialCreate,
                                element: <TestimonialAddEdit/>,
                            },
                            {
                                path: paths.testimonialEdit,
                                element: <TestimonialAddEdit/>,
                            },
                            {
                                path: paths.periodic,
                                element: <PeriodicTasks/>,
                            },
                            {
                                path: paths.periodicCreate,
                                element: <PeriodicTaskAddEdit/>,
                            },
                            {
                                path: paths.periodicEdit,
                                element: <PeriodicTaskAddEdit/>,
                            },
                            {
                                path: paths.sponsors,
                                element: <Sponsors/>,
                            },
                            {
                                path: paths.createSponsors,
                                element: <SponsorsAddEdit/>,
                            },
                            {
                                path: paths.editSponsors,
                                element: <SponsorsAddEdit/>,
                            },
                            {
                                path: paths.detailSponsors,
                                element: <SponsorDetail/>,
                            },
                            {
                                path: paths.organizers,
                                element: <Organizers/>,
                            },
                            {
                                path: paths.createOrganizers,
                                element: <OrganizersAddEdit/>,
                            },
                            {
                                path: paths.editOrganizers,
                                element: <OrganizersAddEdit/>,
                            },
                            {
                                path: paths.detailOrganizers,
                                element: <OrganizerDetail/>,
                            },
                            {
                                path: paths.companies,
                                element: <Companies/>,
                            },
                            {
                                path: paths.createCompany,
                                element: <CompaniesAddEdit/>,
                            },
                            {
                                path: paths.editCompany,
                                element: <CompaniesAddEdit/>,
                            },
                            {
                                path: paths.request,
                                element: <Requests/>,
                            },
                            {
                                path: paths.requestDetail,
                                element: <RequestDetail/>,
                            },
                            {
                                path: paths.requestExports,
                                element: <JoinExports/>
                            },
                            {
                                path: paths.rules,
                                element: <Rules/>
                            },
                            {
                                path: paths.ruleCreate,
                                element: <RuleAddEdit/>
                            },
                            {
                                path: paths.ruleEdit,
                                element: <RuleAddEdit/>
                            },
                            {
                                path: paths.ruleDetail,
                                element: <RuleDetail/>
                            },
                            {
                                path: paths.contracts,
                                element: <Contracts/>
                            },
                            {
                                path: paths.contractCreate,
                                element: <ContractAddEdit/>
                            },
                            {
                                path: paths.contractEdit,
                                element: <ContractAddEdit/>
                            },
                            {
                                path: paths.contractDetail,
                                element: <Contract/>
                            },
                            {
                                path: paths.membershipList,
                                element: <Memberships/>
                            },
                            {
                                path: paths.membershipCreate,
                                element: <MembershipAddEdit/>
                            },
                            {
                                path: paths.membershipEdit,
                                element: <MembershipAddEdit/>
                            },
                            {
                                path: paths.membershipCheckout,
                                element: <Checkout/>
                            },
                            {
                                path: paths.membershipPayment,
                                element: <Payment/>
                            },
                            {
                                path: paths.membershipPaymentCancelled,
                                element: <Cancelled/>
                            },
                            {
                                path: paths.newsletter,
                                element: <Newsletters/>
                            },
                            {
                                path: paths.newsletterCreate,
                                element: <NewsletterAddEdit/>
                            },
                            {
                                path: paths.newsletterEdit,
                                element: <NewsletterAddEdit/>
                            },
                            {
                                path: paths.currencyList,
                                element: <Currencies/>
                            },
                            {
                                path: paths.currencyCreate,
                                element: <CurrencyAddEdit/>
                            },
                            {
                                path: paths.currencyEdit,
                                element: <CurrencyAddEdit/>
                            },
                        ],
                    },
                    {
                        path: rootPaths.supportRoot,
                        children: [
                            {
                                path: paths.supportTable,
                                element: <TableView/>
                            },
                            {
                                path: paths.supportCard,
                                element: <CardView/>
                            },
                            {
                                path: paths.detailSupportTicket,
                                element: <TicketsPreview/>
                            },
                            {
                                path: paths.createSupportTicket,
                                element: <TicketCreate/>
                            }
                        ]
                    },
                    {
                        path: rootPaths.addressRoot,
                        children: [
                            {
                                path: paths.address,
                                element: <Address/>,
                            },
                            {
                                path: paths.createAddress,
                                element: <NewAddress/>,
                            },
                            {
                                path: paths.editAddress,
                                element: <EditAddress/>,
                            },
                            {
                                path: paths.addressExports,
                                element: <AddressExports/>
                            }
                        ],
                    },
                    {
                        path: rootPaths.providerRoot,
                        children: [
                            {
                                path: paths.provider,
                                element: <Providers/>,
                            },
                            {
                                path: paths.providerCreate,
                                element: <ProviderAddEdit/>,
                            },
                            {
                                path: paths.providerEdit,
                                element: <ProviderAddEdit/>,
                            },
                        ],
                    },
                    {
                        path: rootPaths.pagesRoot,
                        children: [
                            {
                                path: paths.starter,
                                element: <Starter/>,
                            },
                        ],
                    },
                ],
            },
            {
                path: "*",
                element: <Navigate to={paths.error404} replace/>,
            },
        ],
    },
];

export const router = createBrowserRouter(routes, {
    basename: process.env.PUBLIC_URL,
});

export default routes;
