import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";

export const AgentSelect = ({agent, className = '', setLoading, fetch, id}) => {
    const [agents, setAgents] = useState([])

    const query = useQuery();

    const getAgents = async () => {
        query.set("is_staff", "true")
        await api.get(`/account/user/?${query.toString()}`).then(res => setAgents(res?.data?.results))
    }

    useEffect(() => {
        getAgents()
        // eslint-disable-next-line
    }, []);

    const handleChange = async e => {
        setLoading(true)
        await api.patch(`/support/tickets/${id}/`, {agent: e.target.value})
            .then(res => {
                fetch(query)
            })
            .catch(err => {})
        setLoading(false)
    }

    return (
        <Form.Select
            style={{width: '14.375rem'}}
            className={className}
            size="sm"
            value={agent}
            onChange={handleChange}
        >
            <option value={""}>Select Agent</option>
            {agents?.map(item => (
                <option key={item?.id} value={item?.id}>{item?.full_name}</option>
            ))}
        </Form.Select>
    );
};

AgentSelect.propTypes = {
    agent: PropTypes.string,
    className: PropTypes.string
};

export default AgentSelect;
