import React from 'react';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';
import {formatDateTime} from "../../../helpers/utils";

const ModalActivityContent = ({taskActivities, getTask, task}) => {
  return (
    <>
      {taskActivities?.map((activity, index) => (
        <Flex
          key={activity.id}
          className={!(taskActivities?.length - 1 === index) && 'mb-3'}
        >
          <Link to={paths.usersDetail.replace(":id", activity?.user)}>
            <Avatar src={activity?.user_avatar} size="l" />
          </Link>
          <div className="flex-1 ms-2 fs-10">
            <p className="mb-0">
              <Link to={paths.usersDetail.replace(":id", activity?.user)} className="fw-semibold">
                {activity?.user_full_name}{' '}
              </Link>
              {activity?.activity}
            </p>
            <div className="fs-11">{formatDateTime(activity?.created_at)}</div>
          </div>
        </Flex>
      ))}
    </>
  );
};

export default ModalActivityContent;
