import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React from 'react';
import {Card} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import paths from "../../../routes/paths";
import {usePermissions} from "../../../providers/PermissionsProvider";

const TicketsPreviewHeader = ({data, setLoading, setData}) => {
    const navigate = useNavigate();

    const {hasPermission} = usePermissions()

    const closeTicket = async e => {
        setLoading(true)
        await api.patch(`/support/tickets/${data?.id}/`, {status: "closed"})
            .then(res => {
                toast.success("Ticket successfully closed.", {theme: "colored"})
                setData(res?.data)
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setLoading(false)
    }

    const handleDelete = async e => {
        const {isConfirmed} = await Swal.fire({
            title: "Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, Delete it`,
            cancelButtonText: "Cancel"
        });
        if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/support/tickets/${data?.id}/`).then(() => {
                toast.success(`Ticket successfully deleted.`, {
                    theme: 'colored'
                });
                navigate(paths.supportTable)
            });
            setLoading(false);
        }
    }

    return (
        <Card>
            <Card.Header className="d-flex flex-between-center">
                <IconButton
                    onClick={() => navigate(-1)}
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                />
                <Flex>
                    {data?.status !== "closed" &&
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="check"
                            transform="shrink-2"
                            iconAlign="middle"
                            className="ms-2"
                            onClick={closeTicket}
                        >
                            <span className="d-none d-md-inline-block ms-1">Close</span>
                        </IconButton>
                    }
                    {hasPermission('support.delete_supportticket') &&
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="trash-alt"
                            transform="shrink-2"
                            iconAlign="middle"
                            className="ms-2 d-none d-sm-block"
                            onClick={handleDelete}
                        >
                            <span className="d-none d-md-inline-block ms-1">Delete</span>
                        </IconButton>
                    }
                </Flex>
            </Card.Header>
        </Card>
    );
};

export default TicketsPreviewHeader;
