import React, {useEffect, useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import GroupMember from './GroupMember';
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";

const KanbanHeader = ({event}) => {
  const [members, setMembers] = useState([]);

  const query = useQuery()

  const getMembers = async (q) => {
    await api.get(`/organizer/organizer/?${q.toString()}`).then((response) => {
      setMembers(response?.data?.results)
    })
  }

  useEffect(() => {
    getMembers(query)
    // eslint-disable-next-line
  }, []);

  return (
    <Row className="gx-0 gap-2 kanban-header rounded-2 px-x1 py-2 mt-2 mb-3">
      <Col className="d-flex align-items-center">
        <h5 className="mb-0">{event?.title}</h5>
        <div className="vertical-line vertical-line-400 position-relative h-100 mx-3 d-none d-sm-flex"></div>
        <GroupMember
          avatarSize="l"
          users={members}
          showMember={4}
          className="d-none d-sm-block"
        />
      </Col>

    </Row>
  );
};

export default KanbanHeader;
