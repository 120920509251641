import { api } from '../../../utils/api';
import axios from "axios";
let cancelTokenSource = null;
export const getActivities = async query => {
  return (await api.get(`/activity/activity/?${query.toString()}`)).data;
};


export const getActivity = async id => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request triggered');
  }

  // Create a new cancel token source
  cancelTokenSource = axios.CancelToken.source();
  return (
    await api.get(`/activity/activity/${id}/`, {
      cancelToken: cancelTokenSource.token
    })
  ).data;
};