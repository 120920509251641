import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import LeafletMap from './LeafletMap';
import {api} from "../../../../utils/api";
import Flex from "../../../../components/common/Flex";
import useQuery from "../../../../hooks/useQuery";

const ProjectLocation = () => {
	const [data, setData] = useState([null]);
	const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState("last_7_days");

  let query = useQuery()

	const getData = async () => {
		setLoading(true)
		await api.get(`/dashboard/eventlocations/eventlocations/?${query.toString()}`).then(res => setData(res?.data))
		setLoading(false)
	}

	useEffect(() => {
		getData()
    // eslint-disable-next-line
	}, []);

	return (
		<Card className="h-100">
			<FalconCardHeader
				title="Event Locations"
				titleTag="h5"
				light={true}
			/>
			<Card.Body className="h-100 p-0" dir="ltr">
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<LeafletMap
						data={data}
						className="h-100 bg-white"
						style={{minHeight: '300px'}}
					/>
				)}
			</Card.Body>
			<Card.Footer className="bg-light">
				<Row className="justify-content-between">
					<Col xs="auto">
						<Form.Select size="sm" defaultValue="week" value={duration} onChange={(e) => {
              setDuration(e.target.value)
              query.set("time_range", e.target.value)
              getData()
            }}>
							<option value="last_7_days">Last 7 days</option>
							<option value="last_month">Last month</option>
							<option value="last_year">Last Year</option>
						</Form.Select>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	);
};

ProjectLocation.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object)
};

export default ProjectLocation;
