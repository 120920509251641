import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PerfomanceTable from './PerfomanceTable';
import EventsPerfomanceChart from './EventsPerfomanceChart';
import {getColor} from 'helpers/utils';
import useQuery from "../../../../hooks/useQuery";
import {api} from "../../../../utils/api";
import Flex from "../../../../components/common/Flex";
import {useAppContext} from "../../../../providers/AppProvider";

const EventsPerfomance = () => {
	const [performanceChart, setPerformanceChart] = useState({})
	const [performanceTable, setPerformanceTable] = useState([])
	const [duration, setDuration] = useState("last_7_days")
	const [loading, setLoading] = useState(false)

	const {
		setConfig
	} = useAppContext()

	let query = useQuery()

	query.set("time_range", duration)

	const getPerformanceChart = async () => {
		setLoading(true)
		await api.get(`/analytics/eventsperformance/chart/?${query.toString()}`).then(res => setPerformanceChart(res?.data))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		setLoading(false)
	}

	const getPerformanceTable = async () => {
		setLoading(true)
		await api.get(`/analytics/eventsperformance/eventtable/?${query.toString()}`).then(res => setPerformanceTable(res?.data))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		setLoading(false)
	}

	useEffect(() => {
		getPerformanceChart()
		getPerformanceTable()
		// eslint-disable-next-line
	}, [duration]);

	return (
		<Card className="h-100">
			<FalconCardHeader
				title="Events perfomance"
				titleTag="h6"
				className="py-2"
				light
			/>
			<Card.Body className="pb-0">
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<>
						<Row>
							<Col xs={6}>
								<h6 className="text-700">Revenue</h6>
								<h3 className="fw-normal text-700">{performanceChart.total_revenue}</h3>
								<EventsPerfomanceChart
									color={getColor('warning')}
									data={performanceChart.revenue}
								/>
							</Col>
							<Col xs={6}>
								<h6 className="text-700">Clicks</h6>
								<h3 className="fw-normal text-700">{performanceChart.total_clicks}</h3>
								<EventsPerfomanceChart
									color={getColor('primary')}
									data={performanceChart.clicks}
								/>
							</Col>
						</Row>
						<div className="mx-ncard">
							<PerfomanceTable data={performanceTable}/>
						</div>
					</>
				)}
			</Card.Body>

			<Card.Footer className="bg-light py-2">
				<Row className="g-0 flex-between-center">
					<Col xs="auto">
						<Form.Select size="sm" className="me-2" value={duration} onChange={(e) => setDuration(e.target.value)}>
							<option value={"last_7_days"}>Last 7 days</option>
							<option value={"last_month"}>Last Month</option>
							<option value={"last_year"}>Last Year</option>
						</Form.Select>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	);
};

export default EventsPerfomance;
