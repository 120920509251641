import {formatDateTime, withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {usePermissions} from "../../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../../BaseTableHeader";
import Flex from "../../../components/common/Flex";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Posts = ({t, i18n}) => {
    const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

    const {
		config: {isAuthenticated},
		setConfig
	} = useAppContext();

	const {hasPermission} = usePermissions()

	const navigate = useNavigate();

    const fetchPosts = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);
		await api.get(`/blog/post/?${q.toString()}`)
			.then(res => {
				setPosts(res?.data?.results);
				setCount(res?.data?.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", !isAuthenticated);
			});
		setLength(posts.length);
		setLoading(false);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchPosts, 600), []);

	useEffect(() => {
		fetchPosts(query);
		setLength(posts.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

	const columns = [
		{
			accessor: 'title',
			Header: t('fields.title'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'title' && sortingDirection,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 text-center'
			},
			Cell: rowData => <Link to={paths.blogEdit.replace(":id", rowData.row.original.id)}>{rowData.row.original.title}</Link>
		},
		{
			accessor: 'category_name',
			Header: t('fields.category'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'category' && sortingDirection,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 text-center'
			},
		},
		{
			accessor: 'date_posted',
			Header: t('fields.date_posted'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'date_posted' && sortingDirection,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 text-center'
			},
			Cell: rowData => formatDateTime(rowData.row.original.date_posted)
		},
		{
			accessor: 'status',
			Header: t('fields.status'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'status' && sortingDirection,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 text-center'
			},
			Cell: (rowData) => {
				const {status, status_display} = rowData.row.original;
				return (
					<>
						<SoftBadge
							pill
							bg={classNames({
								success: status === "published",
								secondary: status === "draft",
							})}
							className="d-flex flex-center"
						>
							<p className="mb-0">{status_display}</p>
							<FontAwesomeIcon
								icon={classNames({
									check: status === "published",
									clock: status === "draft",
								})}
								transform="shrink-2"
								className="ms-1"
							/>
						</SoftBadge>
					</>
				);
			},
		},
		{
			accessor: 'created_at',
			Header: t('fields.created_at'),
			canSort: true,
			isSorted: true,
			sortingDirection: sortingField === 'created_at' && sortingDirection,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 text-center'
			},
			Cell: rowData => formatDateTime(rowData.row.original.created_at)
		},
		{
			accessor: 'none',
			Header: '',
			disableSortBy: true,
			cellProps: {
				className: 'text-end'
			},
			Cell: rowData => {
				const {id, title} = rowData.row.original;
				const handleDelete = async e => {
					const {isConfirmed} = await Swal.fire({
						title: t('sure', {ns: "common"}),
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
						cancelButtonText: t('cancel', {ns: "common"})
					});
					if (isConfirmed) {
						e.preventDefault();
						setLoading(true);
						await api.delete(`/blog/post/${id}/`).then(() => {
							toast.success(
								`${t('title')} ${title} ${t('deleteSuccess', {ns: "common"})}`,
								{
									theme: 'colored'
								}
							);
							fetchPosts(query)
						});
						setLoading(false);
					}
				};
				return (
					<CardDropdown>
						<div className="py-2">
							{hasPermission("blog.change_blogpost") &&
								<Dropdown.Item onClick={() => navigate(paths.blogEdit.replace(":id", id))}>
									{t('edit', {ns: "common"})}
								</Dropdown.Item>
							}
							<Dropdown.Divider as="div"/>
							{hasPermission("blog.delete_blogpost") &&
								<Dropdown.Item
									onClick={e => handleDelete(e)}
									className="text-danger"
								>
									{t('delete', {ns: "common"})}
								</Dropdown.Item>
							}
						</div>
					</CardDropdown>
				);
			}
		}
	]

	const postList = posts?.map(post => ({
		id: post.id,
		title: post.title,
		category: post.category,
		category_name: post.category_name,
		status: post.status,
		status_display: post.status_display,
		created_at: post.created_at,
		date_posted: post.date_posted,
	}));

	const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={postList}
			selection
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						count={count}
						addPermission={"blog.add_blogpost"}
						creationPath={paths.blogCreate}
						title={"blog"}
						deletePermission={"blog.delete_blogpost"}
						exportPermission={"blog.add_blogexportfile"}
						deleteLink={"/blog/delete/post/"}
						data={postList}
						setLoading={setLoading}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchPosts}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
}

export default withPermission(withTranslation(["posts", "common"])(Posts), "blog.view_blogpost");