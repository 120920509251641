import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import SponsorContributionsChart from './SponsorContributionsChart';
import {api} from "../../../../utils/api";
import useQuery from "../../../../hooks/useQuery";
import Flex from "../../../../components/common/Flex";
import {formatDate} from "../../../../helpers/utils";
import {useAppContext} from "../../../../providers/AppProvider";

const SponsorContributions = ({...rest}) => {
	const [sponsorContributions, setSponsorContributions] = useState({})
	const [loading, setLoading] = useState(false)
	const [duration, setDuration] = useState("last_month")

	const {
		setConfig
	} = useAppContext()

	let query = useQuery()

	const getSponsorContributions = async () => {
		setLoading(true)
		query.set("time_range", duration)
		await api.get(`/analytics/sponsorcontributions/?${query.toString()}`).then(res => setSponsorContributions(res?.data))
			.catch(error => {
				if (error?.response?.status === 403)
					setConfig('isAuthenticated', false);
			});
		setLoading(false)
	}

	useEffect(() => {
		getSponsorContributions()
		// eslint-disable-next-line
	}, [duration]);

	return (
		<Card {...rest}>
			<Card.Header>
				<h5 className="text-900 fs-0 mb-2">Sponsor Contributions</h5>
				<h6
					className="mb-0 fs--2 text-500">{formatDate(sponsorContributions?.start_date)}–{formatDate(sponsorContributions?.end_date)}</h6>
			</Card.Header>
			<Card.Body>
				{loading ? (
					<Flex justifyContent="center" className="p-2 mb-2">
						<Spinner animation={'border'} variant={'primary'}/>
					</Flex>
				) : (
					<SponsorContributionsChart data={sponsorContributions?.data} duration={duration}/>
				)}
			</Card.Body>
			<Card.Footer className="bg-light py-2">
				<Row className="g-0 flex-between-center">
					<Col xs="auto">
						<Form.Select size="sm" className="me-2" value={duration}
						             onChange={(e) => setDuration(e.target.value)}>
							<option value={"last_month"}>Last Month</option>
							<option value={"last_year"}>Last Year</option>
						</Form.Select>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	);
};

export default SponsorContributions;
