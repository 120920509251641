import {useAppContext} from "../../../providers/AppProvider";
import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import CSRFToken from "../../../helpers/CSRFToken";
import {api} from "../../../utils/api";

const CurrencySelect = () => {
    const [currencies, setCurrencies] = useState([]);
    const {
        config: {currency},
        setConfig
    } = useAppContext()

    const [activeCurrency, setActiveCurrency] = useState(currency)

    const getCurrencies = async () => {
        await api.get("/currency/").then(res => setCurrencies(res?.data?.results))
    }

    useEffect(() => {
        getCurrencies()
    }, []);

    useEffect(() => {
        setActiveCurrency(currency)
    }, [currency])

    const handleChange = (e) => {
        setConfig("currency", e.target.value)
    };

    return (
        <Form className={"d-none d-sm-inline-block"}>
            <CSRFToken />
            <Form.Select value={activeCurrency} onChange={handleChange}>
                {currencies?.map((item) => (
                    <option key={item?.code} value={item?.code}>{item?.name}</option>
                ))}
            </Form.Select>
        </Form>
    )
}

export default CurrencySelect