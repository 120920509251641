import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {debounce} from "lodash";
import {Button, Card, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import useQuery from "../hooks/useQuery";
import {useAppContext} from "../providers/AppProvider";
import {api} from "../utils/api";
import paths from "../routes/paths";
import {formatDateTime} from "../helpers/utils";
import AdvanceTableWrapper from "../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "./BaseTableHeader";
import Flex from "../components/common/Flex";
import AdvanceTable from "../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../components/common/advance-table/AdvanceTablePagination";

const BaseExportTable = ({t, i18n, url, title, addPermission, creationPath, detailsPath}) => {
	const [exports, setExports] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
	const [sortingDirection, setSortingDirection] = useState('desc');
	const [count, setCount] = useState(0);
	const [length, setLength] = useState(0);
	const query = useQuery();

	const {
		setConfig
	} = useAppContext()

	const navigate = useNavigate();

	const fetchExports = async q => {
		setLoading(true);
		const sortingParam = `${
			sortingDirection === 'desc' ? '-' : ''
		}${sortingField}`;

		query.set('ordering', sortingParam);
		await api.get(`${url}?${q.toString()}`)
			.then(res => {
				setExports(res?.data?.results);
				setCount(res?.data?.count);
			})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", false);
			});
		setLength(exports.length);
		setLoading(false);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedLoadItems = useCallback(debounce(fetchExports, 600), []);

	useEffect(() => {
		fetchExports(query);
		setLength(exports.length);
		navigate(`?${query.toString()}`);
		// eslint-disable-next-line
	}, [sortingField, sortingDirection]);

	const columns = [
		{
			accessor: 'status',
			Header: t('fields.status'),
			headerProps: {className: 'pe-1'},
			sortingDirection: sortingField === 'status' && sortingDirection,
			isSorted: false,
			canSort: true,
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const {id, status} = rowData.row.original;
				return detailsPath ? (
					<Link to={detailsPath.replace(":id", id)}>{status}</Link>
				) : (
					<>{status}</>
				)
			}
		},
		{
			accessor: 'percentage',
			Header: t('fields.percentage'),
			canSort: true,
			cellProps: {
				className: 'py-2'
			},
			sortingDirection: sortingField === 'percentage' && sortingDirection,
			isSorted: false,
			headerProps: {className: 'pe-3'},
			Cell: rowData => <>{rowData.row.original.percentage}%</>
		},
		{
			accessor: 'duration',
			Header: t('fields.duration'),
			canSort: true,
			sortingDirection: sortingField === 'duration' && sortingDirection,
			isSorted: false,
			cellProps: {
				className: 'py-2'
			},
			headerProps: {className: 'pe-3'},
			Cell: rowData => <>{rowData.row.original.duration}s</>
		},
		{
			headerProps: {className: 'pe-1'},
			cellProps: {
				className: 'py-2'
			},
			accessor: 'error',
			Header: t('fields.error'),
			canSort: true,
			sortingDirection: sortingField === 'error' && sortingDirection,
			isSorted: false,
		},
		{
			accessor: 'file',
			Header: t('fields.file'),
			canSort: true,
			headerProps: {className: 'pe-1 text-center'},
			cellProps: {
				className: 'py-2 fs-0 text-center'
			},
			sortingDirection: sortingField === 'file' && sortingDirection,
			isSorted: false,
			Cell: rowData => rowData.row.original.file &&
				<Button variant={"outline-success"} href={rowData.row.original.file}>
					<FontAwesomeIcon icon={faFileExcel}/>
				</Button>
		},
		{
			accessor: 'created_at',
			Header: t('fields.created_at'),
			canSort: true,
			sortingDirection: sortingField === 'created_at' && sortingDirection,
			isSorted: false,
			headerProps: {
				className: 'text-center pe-3'
			},
			cellProps: {
				className: 'text-center fs-0 py-2'
			},
			Cell: rowData => `${formatDateTime(rowData.row.original.created_at)}`
		}
	];

	const exportList = exports?.map(exports => ({
		id: exports.id,
		status: exports.status,
		percentage: exports.percentage,
		error: exports.error || exports.errors,
		created_at: exports.created_at,
		file: exports.file || exports.file_url,
		duration: exports.duration,
	}))

	const handleSort = column => {
		if (column.canSort) {
			const {id} = column;
			const isDescending = sortingField === id && sortingDirection === 'desc';

			// Update the sorting field and direction
			setSortingField(id);
			setSortingDirection(isDescending ? 'asc' : 'desc');

			// Modify the sorting properties of the column
			column.isSorted = true;
			column.isSortedDesc = isDescending;
			column.sortingDirection = isDescending ? 'asc' : 'desc';
		}
	};

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={exportList}
			pagination
			perPage={20}
		>
			<Card className="mb-3">
				<Card.Header>
					<BaseTableHeader
						table
						fetchData={delayedLoadItems}
						data={exportList}
						count={count}
						title={title}
						addPermission={addPermission}
						creationPath={creationPath}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{loading ? (
						<Flex justifyContent="center" className="p-2 mb-2">
							<Spinner animation={'border'} variant={'primary'}/>
						</Flex>
					) : (
						<AdvanceTable
							handleSort={handleSort}
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								size: 'sm',
								striped: true,
								className: 'fs--1 mb-0 overflow-hidden'
							}}
							headers={columns}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTablePagination
						query={query}
						fetch={fetchExports}
						count={count}
						length={length}
						itemsPerPage={20}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
	);
}

export default BaseExportTable