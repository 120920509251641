import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTicketAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import SimpleBarReact from "simplebar-react";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Flex from "../../../components/common/Flex";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {useAppContext} from "../../../providers/AppProvider";
import CSRFToken from "../../../helpers/CSRFToken";
import {Field, Formik} from "formik";
import FormError from "../../errors/FormError";
import EventCustomFieldItem from "./EventCustomFieldItem";
import FormErrors from "../../errors/FormErrors";
import {useTranslation} from "react-i18next";
import konnect from "../../../assets/img/logos/logo_konnect.svg";
import paymee from "../../../assets/img/logos/paymee-logo.png";
import shield from "../../../assets/img/icons/shield.png";
import paths from "../../../routes/paths";

const EventRegistrationModal = ({open, setOpen, ticket, getEvent, registration, providers, event}) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [method, setMethod] = useState("konnect")
    const [payableTotal, setPayableTotal] = useState(0)
    const [transactionFormData, setTransactionFormData] = useState({
        option: [],
        activity: event?.activity,
        status: "pending",
        full_refund_if_not_received: false,
        full_or_partial_refund_if_not_as_described: false,
        provider: process.env.DEFAULT_KONNECT_PROVIDER,
        path: "transaction"
    })

    const {id} = useParams()
    const {t} = useTranslation(["events", "common"])
    const {
        config: {currency}
    } = useAppContext()

    const [formData, setFormData] = useState({
        event: id,
        option: [],
        answers: [],
        menus: []
    })

    const {
        config: {isDark}
    } = useAppContext();

    const handleChange = (e, type, name, id) => {
        switch (type) {

            default:
                setFormData({
                    ...formData,
                    answers: [
                        ...formData.answers,
                        {
                            answer: e.target.value.toString(),
                            question: id,
                        }
                    ]
                })
        }
    }

    const handleRegister = async (e) => {
        setLoading(true)
        await api.post("/event/register/", formData)
            .then(async res => {
                if (method === "konnect")
                    await api.post("/konnect/transaction/", {...transactionFormData, event: res?.data?.event})
                        .then(res => {
                            toast.success("Payment successfully processed!", {theme: "colored"})
                            window.open(res?.data?.pay_url)
                        })
                        .catch(err => {
                            toast.error("An error has occurred.", {theme: "colored"})
                            setErrors(err?.response?.data)
                        })
                else if (method === "paymee")
                    await api.post("/paymee/transaction/", {...transactionFormData, event: res?.data?.event})
                        .then(res => {
                            toast.success("Payment successfully processed!", {theme: "colored"})
                            window.open(res?.data?.payment_url)
                        })
                        .catch(err => {
                            toast.error("An error has occurred.", {theme: "colored"})
                        })
                toast.success("You have successfully registered to this event.", {theme: "colored"})
                setOpen(!open)
                getEvent()
            })
            .catch(error => {
                setFormData(formData)
                toast.error(t('error', {ns: "common"}), {theme: "colored"})
                setErrors(error?.response?.data)
            })
        setLoading(false)
    }

    const removeDuplicatesAndSetLastValues = (arr) => {
        const map = {};
        arr.forEach(item => {
            map[item.question] = item;
        });
        return Object.values(map);
    };

    const handleCheckboxChange = e => {
        setTransactionFormData({...transactionFormData, [e.target.name]: e.target.checked})
    }

    useEffect(() => {
        setTransactionFormData({...transactionFormData, amount: payableTotal, currency: currency})
        // eslint-disable-next-line
    }, [payableTotal, currency]);

    useEffect(() => {
        setFormData({
            ...formData,
            answers: registration?.questions_data?.map(r => ({question: r?.id, answer: ""}))
        })
        // eslint-disable-next-line
    }, [registration?.questions_data]);

    useEffect(() => {
        setFormData({
            ...formData,
            answers: removeDuplicatesAndSetLastValues(formData.answers)
        })
    }, [formData]);

    return (
        <Modal centered size={"xl"} fullscreen={registration?.type === "external"} show={open}
               onHide={() => setOpen(!open)}>
            <Modal.Header
                closeButton
                closeVariant={isDark ? 'white' : undefined}
                className="bg-light px-x1 border-bottom-0"
            >
                <Flex justifyContent={"center"} alignItems={"center"}>
                    <div>
                        <h5>
                            <FontAwesomeIcon
                                icon={faTicketAlt}
                                className={classNames("me-2", {
                                    "text-warning": ticket?.type === "paid",
                                    "text-primary": ticket?.type === "free",
                                    "text-success": ticket?.type === "donation",
                                })}
                            />
                            {ticket?.type_display}
                        </h5>
                    </div>
                    <SoftBadge pill bg={ticket?.variable ? "primary" : "success"} className={"ms-2"}>
                        {ticket?.variable ? "Variable" : "Not variable"}
                    </SoftBadge>
                </Flex>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                        {({values, setFieldValue}) => (
                            <>
                                <div className="mt-4 fs-10">
                                    <SimpleBarReact>
                                        <Table striped className="border-bottom">
                                            <thead data-bs-theme="light">
                                            <tr className="bg-primary dark__bg-1000">
                                                <th className="text-white border-0 text-end"></th>
                                                <th className="text-white border-0">Option Name</th>
                                                <th className="text-white border-0 text-center">Price</th>
                                                <th className="text-white border-0 text-center">Default</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {ticket?.option_set?.map((option, index) => (
                                                <tr key={index}>
                                                    <td className="align-middle text-center">
                                                        <Field name={`option.${index}`}>
                                                            {({field}) => (
                                                                <Form.Switch
                                                                    type="checkbox"
                                                                    name={`option.${index}`}
                                                                    value={option?.id}
                                                                    checked={formData.option.includes(option?.id)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setFormData({
                                                                                ...formData,
                                                                                option: [...formData.option, parseInt(e.target.value)]
                                                                            })
                                                                            setPayableTotal(prevState => prevState + option?.amount)
                                                                        } else {
                                                                            setFormData({
                                                                                ...formData,
                                                                                option: formData.option.filter(o => o !== parseInt(e.target.value))
                                                                            })
                                                                            setPayableTotal(prevState => prevState - option?.amount)
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td className="align-middle">
                                                        <h6 className="mb-0 text-nowrap">{option?.name}</h6>
                                                        <p
                                                            className="mb-0 fs--1">{option?.checked && "This is a default option and you must check it."}</p>
                                                    </td>
                                                    <td className="align-middle text-center">{option?.amount} {currency}</td>
                                                    <td className="align-middle text-center">
                                                        {option?.checked ? <FontAwesomeIcon icon={faCheck}/> :
                                                            <FontAwesomeIcon icon={faTimes}/>}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </SimpleBarReact>
                                    <FormError error={errors?.option}/>
                                </div>
                                {registration?.type === "standard" ? (
                                    <Row>
                                        <Col lg={6}>
                                            <h6>Questions:</h6>
                                            <Form>
                                                <CSRFToken/>
                                                {registration?.questions_data?.map((question, index) => (
                                                    <>
                                                        <EventCustomFieldItem
                                                            type={question?.type}
                                                            name={question?.name}
                                                            options={question?.options?.split(',')}
                                                            id={question?.id}
                                                            required={question?.required}
                                                            index={index}
                                                            onChange={(e) => handleChange(e, question?.type, question?.name, question?.id)}
                                                        />
                                                        <FormErrors errors={errors?.answers} error={"answer"}
                                                                    index={index}/>
                                                    </>
                                                ))}
                                                <FormErrors errors={errors?.answers}/>
                                            </Form>
                                        </Col>
                                        <Col lg={6}>
                                            <h6>Menu:</h6>
                                            <Form>
                                                <CSRFToken/>
                                                {registration?.menus_data?.map((menu, index) => (
                                                    <>
                                                        <Form.Switch
                                                            label={menu?.name}
                                                            type="checkbox"
                                                            value={menu?.id}
                                                            className={"mb-2"}
                                                            onChange={(e) => {
                                                                if (e.target.checked)
                                                                    setFormData({
                                                                        ...formData,
                                                                        menus: [...formData.menus, {menu: e.target.value}]
                                                                    })
                                                                else
                                                                    setFormData({
                                                                        ...formData,
                                                                        menus: formData.menus.filter(o => o.menu !== e.target.value)
                                                                    })
                                                            }}
                                                        />
                                                    </>
                                                ))}
                                                <FormError error={errors?.menus}/>
                                            </Form>
                                        </Col>
                                    </Row>
                                ) : registration?.type === "external" ? (
                                    <>
                                        <p></p>
                                        <iframe title={registration?.type} src={registration?.url} width={"100%"}
                                                height={"100%"}/>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {providers?.map((provider, index) => (
                                    <Form.Check
                                        type="radio"
                                        key={index}
                                        id={provider?.provider}
                                        onChange={e => {
                                            setMethod(e.target.id)
                                            setTransactionFormData({...transactionFormData, provider: provider?.id})
                                        }}
                                        className="d-flex align-items-center mt-3"
                                    >
                                        <Form.Check.Input
                                            type="radio"
                                            id={provider?.provider}
                                            checked={method === provider?.provider}
                                            onChange={e => {
                                                setMethod(e.target.id)
                                                setTransactionFormData({...transactionFormData, provider: provider?.id})
                                            }}
                                            name="payment-method"
                                        />
                                        <Form.Check.Label className="mb-0 ms-2">
                                            <img src={provider?.provider === "konnect" ? konnect : paymee} height={50}
                                                 alt="card payment options"/>
                                        </Form.Check.Label>
                                    </Form.Check>
                                ))}
                                <div className="border-dashed border-bottom my-5"></div>
                                <Row>
                                    <Col
                                        md={7}
                                        xl={12}
                                        xxl={7}
                                        className="px-md-3 mb-xxl-0 position-relative"
                                    >
                                        <div className="d-flex">
                                            <img
                                                src={shield}
                                                alt="protection"
                                                width="60"
                                                height="60"
                                                className="me-3"
                                            />
                                            <div className="flex-1">
                                                <h5 className="mb-2">Buyer Protection</h5>
                                                <Form.Check id="full-refund" className="mb-0 form-check">
                                                    <Form.Check.Input
                                                        name={"full_refund_if_not_received"}
                                                        onChange={handleCheckboxChange}
                                                        className="mb-0"
                                                        type="checkbox"
                                                        defaultChecked
                                                        checked={formData.full_refund_if_not_received}
                                                    />
                                                    <Form.Check.Label className="mb-0">
                                                        <strong>Full Refund</strong> If you don't{' '}
                                                        <br className="d-none d-md-block d-lg-none"/> receive your
                                                        order
                                                    </Form.Check.Label>
                                                </Form.Check>
                                                <Form.Check id="partial-refund" className="form-check">
                                                    <Form.Check.Input
                                                        name={"full_or_partial_refund_if_not_as_described"}
                                                        onChange={handleCheckboxChange}
                                                        className="mb-0"
                                                        type="checkbox"
                                                        checked={formData.full_or_partial_refund_if_not_as_described}
                                                        defaultChecked
                                                    />
                                                    <Form.Check.Label className="mb-0">
                                                        <strong>Full or Partial Refund,</strong> If the product is
                                                        not as described in details
                                                    </Form.Check.Label>
                                                </Form.Check>
                                                <Link to="#!" className="fs-10 ms-3 ps-2">
                                                    Learn More
                                                    <FontAwesomeIcon
                                                        icon="caret-right"
                                                        className="ms-1"
                                                        transform="down-2"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="vertical-line d-none d-md-block d-xl-none d-xxl-block"></div>
                                    </Col>
                                    <Col
                                        md={5}
                                        xl={12}
                                        xxl={5}
                                        className="ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start"
                                    >
                                        <div
                                            className="border-dashed border-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>
                                        <div className="fs-2 fw-semibold">
                                            All Total: <span
                                            className="text-primary">{payableTotal} {currency}</span>
                                        </div>
                                        <p className="fs-10 mt-3 mb-0">
                                            By clicking <strong>Register </strong> button you agree
                                            to the <Link to={paths.privacyPolicy}>Terms</Link> &amp; <Link
                                            to={paths.termsConditions}>Conditions</Link>
                                        </p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Formik>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    as={Link}
                    size="sm"
                    onClick={handleRegister}
                    variant="outline-primary"
                >
                    Register
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EventRegistrationModal;
