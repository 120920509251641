import AuthCardLayout from "../../../layouts/AuthCardLayout";
import React from "react";
import VerifyAccountContent from "../VerifyAccountContent";

const VerifyAccount = () => (
  <AuthCardLayout>
    <div className="text-center">
      <VerifyAccountContent layout="card" email="xyz@abc.com" titleTag="h3" />
    </div>
  </AuthCardLayout>
);

export default VerifyAccount;