import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import paths from "../../routes/paths";

const Definitions = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="definitions" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Definitions and key terms</h5>
			</Card.Header>
			<Card.Body>
				<p className="fs-9 mb-0 fw-semibold">
					To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are
					referenced, are strictly defined as:
				</p>
				<ol type="1">
					<li>
						Cookie: small amount of data generated by a website and saved by your web browser. It is used to
						identify your browser, provide analytics, remember information about you such as your language
						preference or login information.
					</li>
					<li>
						Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to nexameet, that is
						responsible for your information under this Privacy Policy.
					</li>
					<li>
						Country: where nexameet or the owners/founders of nexameet are based, in this case is Tunisia
					</li>
					<li>
						Customer: refers to the company, organization or person that signs up to use the nexameet Service to
						manage the relationships with your consumers or service users.
					</li>
					<li>
						Device: any internet connected device such as a phone, tablet, computer or any other device that can
						be used to visit nexameet and use the services.
					</li>
					<li>
						IP address: Every device connected to the Internet is assigned a number known as an Internet protocol
						(IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be
						used to identify the location from which a device is connecting to the Internet.
					</li>
					<li>
						Personnel: refers to those individuals who are employed by nexameet or are under contract to perform
						a service on behalf of one of the parties.
					</li>
					<li>
						Personal Data: any information that directly, indirectly, or in connection with other information —
						including a personal identification number — allows for the identification or identifiability of a natural
						person.
					</li>
					<li>
						Service: refers to the service provided by nexameet as described in the relative terms (if available) and
						on this platform.
					</li>
					<li>
						Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and
						others who provide our content or whose products or services we think may interest you.
					</li>
					<li>
						Website: nexameet’s site, which can be accessed via this URL: <Link to={paths.landing}>{window.location.host}</Link>
					</li>
					<li>
						You: a person or entity that is registered with nexameet to use the Services.
					</li>
				</ol>
			</Card.Body>
		</Card>
	)
})

export default Definitions