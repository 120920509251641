import React, {useEffect, useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import StatisticsCard from 'components/dashboards/saas/stats-cards/StatisticsCard';
import {api} from "../../../../utils/api";
import Flex from "../../../../components/common/Flex";
import {useAppContext} from "../../../../providers/AppProvider";

const Statistics = () => {
	const [loading, setLoading] = useState(false)
	const [statsData, setStatsData] = useState([])
	const [eventStats, setEventStats] = useState({})
	const [memberStats, setMemberStats] = useState({})

	const {
		setConfig
	} = useAppContext()

	const getStatsData = async () => {
		setLoading(true)
		await api.get("/dashboard/statistics/events/").then(res => setEventStats(res?.data))
			.catch(err => {
				if (err?.response.status === 403) setConfig("isAuthenticated", false)
			})
		await api.get("/dashboard/statistics/members/").then(res => setMemberStats(res?.data))
			.catch(err => {
				if (err?.response.status === 403) setConfig("isAuthenticated", false)
			})
		setLoading(false)
	}

	useEffect(() => {
		getStatsData()
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setStatsData([eventStats, memberStats])
	}, [eventStats, memberStats]);

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Row className="g-3 mb-3">
			{statsData?.slice(0, 2)?.map(stat => (
				<Col key={stat.title} sm={6}>
					<StatisticsCard stat={stat} style={{minWidth: '12rem'}}/>
				</Col>
			))}
		</Row>
	);
};

Statistics.propTypes = {};

export default Statistics;
