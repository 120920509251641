import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const Disclaimer = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="disclaimer" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Disclaimer</h5>
			</Card.Header>
			<Card.Body>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Nexameet is not responsible for any content, code or any other imprecision.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					Nexameet does not provide warranties or guarantees.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					In no event shall nexameet be liable for any special, direct, indirect, consequential, or incidental damages
					or
					any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in
					connection with the use of the Service or the contents of the Service. The Company reserves the right to
					make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					The nexameet Service and its contents are provided "as is" and "as available" without any warranty or
					representations of any kind, whether express or implied. nexameet is a distributor and not a publisher of the
					content supplied by third parties; as such, nexameet exercises no editorial control over such content and
					makes no warranty or representation as to the accuracy, reliability or currency of any information, content,
					service or merchandise provided through or accessible via the nexameet Service. Without limiting the
					foregoing, nexameet specifically disclaims all warranties and representations in any content transmitted on or
					in connection with the nexameet Service or on sites that may appear as links on the nexameet Service, or in
					the products provided as a part of, or otherwise in connection with, the nexameet Service, including without
					limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third
					party
					rights. No oral advice or written information given by nexameet or any of its affiliates, employees, officers,
					directors, agents, or the like will create a warranty. Price and availability information is subject to change
					without notice. Without limiting the foregoing, nexameet does not warrant that the nexameet Service will be
					uninterrupted, uncorrupted, timely, or error-free.
				</p>
			</Card.Body>
		</Card>
	)
})

export default Disclaimer