import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../hooks/useQuery";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../utils/api";
import {debounce} from "lodash";
import Flex from "../../components/common/Flex";
import Avatar from "../../components/common/Avatar";
import {formatDateTime, withPermission} from "../../helpers/utils";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";
import {useAppContext} from "../../providers/AppProvider";
import paths from "../../routes/paths";
import BaseTableHeader from "../BaseTableHeader";
import SoftBadge from "../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortingField, setSortingField] = useState("date"); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState("desc");
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig,
    } = useAppContext();

    const navigate = useNavigate();

    const fetchTransactions = async (query) => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === "desc" ? "-" : ""
        }${sortingField}`;

        query.set("ordering", sortingParam);
        await api
            .get(`/transaction/transaction/?${query.toString()}`)
            .then((res) => {
                setTransactions(res?.data?.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchTransactions, 600), []);

    useEffect(() => {
        fetchTransactions(query);
        setLength(transactions?.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: "code",
            Header: "Code",
            canSort: true,
            headerProps: {className: "pe-2 text-center"},
            cellProps: {
                className: "py-2 fs-0 text-center",
            },
            sortingDirection: sortingField === "code" && sortingDirection,
            isSorted: false,
        },
        {
            accessor: "user",
            Header: "User",
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === "user" && sortingDirection,
            headerProps: {className: "pe-1"},
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const {user_full_name, user_avatar, user} = rowData.row.original;
                return (
                    <>
                        <Link to={`/accounts/user/${user}/edit`}>
                            <Flex alignItems="center">
                                {user_avatar ? (
                                    <Avatar
                                        src={user_avatar}
                                        rounded={"circle"}
                                        size="4xl"
                                        className="me-2"
                                    />
                                ) : (
                                    <Avatar
                                        size="4xl"
                                        name={user_full_name}
                                        rounded={"circle"}
                                        className="me-2"
                                    />
                                )}
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: "200px",
                                            wordWrap: "break-word",
                                            wordBreak: "break-all",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    >
                                        {user_full_name}
                                    </h6>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            },
        },
        {
            accessor: "amount",
            Header: "Amount",
            canSort: true,
            headerProps: {className: "pe-1 text-center"},
            cellProps: {
                className: "py-2 fs-0 text-center",
            },
            sortingDirection: sortingField === "amount" && sortingDirection,
            isSorted: false,
        },
        {
            accessor: 'status',
            Header: 'Status',
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'status' && sortingDirection,
            headerProps: {
                className: 'text-center pe-3'
            },
            cellProps: {
                className: 'text-center fs-0 py-2'
            },
            Cell: rowData => {
                const {status} = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: status === 'approved',
                            warning: status === 'pending',
                            danger: status === 'declined'
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {status === 'approved' && 'Approved'}
                            {status === 'pending' && 'Pending'}
                            {status === 'declined' && 'Declined'}
                        </p>
                        <FontAwesomeIcon
                            icon={classNames({
                                check: status === 'approved',
                                ban: status === 'declined',
                                clock: status === 'pending'
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }
        },
        {
            accessor: "date",
            Header: "Date",
            canSort: true,
            headerProps: {className: "pe-1 text-center"},
            cellProps: {
                className: "py-2 fs-0 text-center",
            },
            sortingDirection: sortingField === "date" && sortingDirection,
            isSorted: false,
            Cell: (rowData) => <>{formatDateTime(rowData.row.original.date)}</>,
        },
        {
            accessor: "none",
            Header: "",
            disableSortBy: true,
            cellProps: {
                className: "text-end",
            },
            Cell: (rowData) => {
                const {id} = rowData.row.original;
                const handleDelete = async (e) => {
                    const {isConfirmed} = await Swal.fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/transaction/transaction/${id}/`).then(() => {
                            toast.success(`Transaction successfully deleted.`, {
                                theme: "colored",
                            });
                            fetchTransactions(query)
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            <Dropdown.Item
                                onClick={(e) => navigate(paths.transactionDetail.replace(":id", id))}
                            >
                                Detail
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item
                                onClick={(e) => handleDelete(e)}
                                className="text-danger"
                            >
                                Delete
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            },
        },
    ];

    const transactionList = transactions.map((transaction) => ({
        id: transaction.id,
        user_full_name: transaction.user_full_name,
        event_name: transaction.event_name,
        event: transaction.event,
        activity: transaction.activity,
        activity_name: transaction.activity_name,
        code: transaction.code,
        amount: transaction.amount,
        status: transaction?.status,
        user_avatar: transaction.user_avatar,
        date: transaction.date,
        user: transaction.user,
    }));

    const handleSort = (column) => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === "desc";

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? "asc" : "desc");

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? "asc" : "desc";
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={transactionList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"transaction.add_transaction"}
                        title={"transaction"}
                        deletePermission={"transaction.delete_transaction"}
                        deleteLink={"transaction/delete/transaction/"}
                        setLoading={setLoading}
                        data={transactionList}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={"border"} variant={"primary"}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchTransactions}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default withPermission(Transactions, "transaction.view_transaction");
