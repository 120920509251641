import illustration1 from 'assets/img/icons/spot-illustrations/50.png';
import illustration2 from 'assets/img/icons/spot-illustrations/49.png';
import illustration3 from 'assets/img/icons/spot-illustrations/48.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'PLAN',
    color: 'danger',
    title: 'offers.elements.0.title',
    description: 'offers.elements.0.description',
    image: illustration1,
    inverse: true
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'BUILD',
    color: 'info',
    title: 'offers.elements.1.title',
    description: 'offers.elements.1.description',
    image: illustration2,
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'DEPLOY',
    color: 'success',
    title: 'offers.elements.2.title',
    description: 'offers.elements.2.description',
    image: illustration3,
    inverse: true
  }
];
