import React, { createContext, useContext } from 'react';
import {useAppContext} from "./AppProvider";

const PermissionsContext = createContext({});

export const PermissionsProvider = ({ children }) => {
  const {
    config: { permissions }
  } = useAppContext()

  const hasPermission = (permission) => {
    return permissions?.includes(permission);
  };

  const hasPermissions = (perms) => {
    return perms?.some(name => permissions?.includes(name))
  }

  return (
    <PermissionsContext.Provider value={{ hasPermission, hasPermissions, permissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
