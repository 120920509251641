import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import React from "react";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import NineDotMenu from "./NineDotMenu";
import { useAppContext } from "../../../providers/AppProvider";
import {useTranslation} from "react-i18next";
import CurrencySelect from "./CurrencySelect";
import {HeaderLanguages} from "./HeaderLanguages";

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig,
  } = useAppContext();
  const {t} = useTranslation("topbar")

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <CurrencySelect />
      <HeaderLanguages />
      <Nav.Item as={"li"} className={"d-none d-sm-inline-block"}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig("isDark", !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={
              <Tooltip style={{ position: "fixed" }} id="ThemeColor">
                {isDark ? t("themeSwitcher.light") : t("themeSwitcher.dark")}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? "sun" : "moon"}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <NineDotMenu />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
