import React from 'react';
import AuthCardLayout from 'layouts/AuthCardLayout';
import VerifyMailContent from "../VerifyMailContent";

const VerifyMail = () => (
  <AuthCardLayout>
    <div className="text-center">
      <VerifyMailContent layout="card" email="xyz@abc.com" titleTag="h3" />
    </div>
  </AuthCardLayout>
);

export default VerifyMail;
