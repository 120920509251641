import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatMonth, formatTime } from '../../helpers/utils';
import {useTranslation} from "react-i18next";

const Event = ({ details, isLast, link}) => {
  const {
    start_date,
    title,
    id,
    activity_name,
    activity,
    badge = {},
    location,
    duration,
    interested
  } = details;

  const {t} = useTranslation(["events", "common"])

  const address = details?.address
  return (
    <Flex>
      <Calendar
        day={new Date(start_date).getDate()}
        month={formatMonth(start_date)}
      />
      <div className="flex-1 position-relative ps-3">
        <h6 className="fs-0 mb-0">
          <Link to={link ? link : `/events/${id}/detail`}>
            <span className="me-1">{title}</span>
            {badge.title && (
              <span className={`badge badge-${badge.type} rounded-pill `}>
                {badge.title}
              </span>
            )}
          </Link>
        </h6>
        <p className="mb-1">
          {t('OrganizedBy')}
          <Link
            to={`/activities/activity/${activity}/detail`}
            className="text-700 ps-1"
          >
            {activity_name}
          </Link>
        </p>
        <p className="text-1000 mb-0">{t('time')}: {formatTime(start_date)}</p>

        {duration && <p className="text-1000 mb-0">{t('duration')}: {duration}</p>}
        {interested && <p className="text-1000 mb-0">{interested}</p>}
        {location && <p className="text-1000 mb-0">{t('location')}: {location}</p>}

        <p className="mb-0">{t('place')}: {address?.formatted_address}</p>
        {!isLast && <div className="border-dashed border-bottom my-3"></div>}
      </div>
    </Flex>
  );
};

Event.propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    location: PropTypes.string,
    duration: PropTypes.string,
    interested: PropTypes.string,
    badge: PropTypes.object
  }),
  isLast: PropTypes.bool
};

export default Event;
