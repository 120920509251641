import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';

const Photos = ({photos, t}) => {
  return (
    <Card className="mt-3">
      <FalconCardHeader title={t('photos', {ns: "common"})} light />
      <Card.Body>
        <FalconLightBoxGallery images={photos}>
          {setImgIndex => (
            <Row className="g-2">
              <Col xs={6}>
                <Image
                  className="cursor-pointer"
                  src={photos && photos[0]?.photo_url}
                  fluid
                  rounded
                  onClick={() => setImgIndex(0)}
                />
              </Col>
              <Col xs={6}>
                <Image
                  className="cursor-pointer"
                  src={photos && photos[1]?.photo_url}
                  fluid
                  rounded
                  onClick={() => setImgIndex(1)}
                />
              </Col>
              <Col xs={4}>
                <Image
                  className="cursor-pointer"
                  src={photos && photos[2]?.photo_url}
                  fluid
                  rounded
                  onClick={() => setImgIndex(2)}
                />
              </Col>
              <Col xs={4}>
                <Image
                  className="cursor-pointer"
                  src={photos && photos[3]?.photo_url}
                  fluid
                  rounded
                  onClick={() => setImgIndex(3)}
                />
              </Col>
              <Col xs={4}>
                <Image
                  className="cursor-pointer"
                  src={photos && photos[4]?.photo_url}
                  fluid
                  rounded
                  onClick={() => setImgIndex(4)}
                />
              </Col>
            </Row>
          )}
        </FalconLightBoxGallery>
      </Card.Body>
    </Card>
  );
};

export default Photos;
