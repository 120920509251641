import { api } from '../../utils/api';

export const getNotifications = async query => {
  return (await api.get(`/notification/notification/?${query.toString()}`))
    .data;
};

export const markAllAsRead = async () => {
  return (await api.get('/notification/make_all_as_read/')).data;
};
