import React from 'react';
import { Card, Table } from 'react-bootstrap';

const AnsweredQuestions = ({answers}) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Question</th>
                <th className="border-0 text-center">Answer</th>
              </tr>
            </thead>
            <tbody>
              {answers?.map(answer => (
                <tr key={answer?.id} className="border-200">
                  <td className="align-middle">
                    <h6 className="mb-0 text-nowrap">{answer?.question_name}</h6>
                    <p className="mb-0">{answer?.question_description}</p>
                  </td>
                  <td className="align-middle text-center">
                    {answer?.answer}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

AnsweredQuestions.propTypes = {};

export default AnsweredQuestions;
