import {Outlet} from "react-router-dom";
import React from "react";
import NavbarStandard from "../pages/landing/NavbarStandard";
import FooterStandard from "../pages/landing/FooterStandard";
import {useAppContext} from "../providers/AppProvider";

const LandingLayout = () => {
	const {
		config: {isFluid}
	} = useAppContext();
	return (
		<div className={isFluid ? 'container-fluid' : 'container'}>
				<NavbarStandard/>
				{/*------ Main Routes ------*/}
				<Outlet/>
				<FooterStandard/>
		</div>
	)
}

export default LandingLayout