import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import paths from 'routes/paths';
import Priority from './Priority';
import AgentSelect from './AgentSelect';
import Avatar from 'components/common/Avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import {formatDateTime} from "../../../helpers/utils";

const CardLayout = ({table, data}) => {

    return (
        <div className="d-flex flex-column gap-3">
            {data?.map((row, index) => {
                const {
                    id,
                    client,
                    client_full_name,
                    created_at,
                    status,
                    status_display,
                    subject,
                    client_avatar,
                    agent,
                    priority,
                    priority_display
                } =
                    row;

                return (
                    <div
                        key={index}
                        className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100"
                    >
                        <div className="d-flex align-items-start align-items-sm-center">
                            <Link to={paths.usersDetail.replace(":id", client)} className="d-none d-sm-block">
                                {client_avatar ? (
                                    <Avatar src={client_avatar} size="3xl"/>
                                ) : (
                                    <Avatar size="3xl" name={client_full_name}/>
                                )}
                            </Link>
                            <div className="ms-1 ms-sm-3">
                                <p className="fw-semibold mb-3 mb-sm-2">
                                    <Link to={paths.detailSupportTicket.replace(":id", id)}>{subject}</Link>
                                </p>
                                <Row className="align-items-center gx-0 gy-2">
                                    <Col xs="auto" className="me-2">
                                        <h6 className="mb-0">
                                            <Link
                                                to={paths.usersDetail.replace(":id", client)}
                                                className="text-800 d-flex align-items-center gap-1"
                                            >
                                                <FontAwesomeIcon
                                                    icon="user"
                                                    transform="shrink-3 up-1"
                                                />
                                                <span>{client_full_name}</span>
                                            </Link>
                                        </h6>
                                    </Col>
                                    <Col xs="auto" className="lh-1 me-3">
                                        <SoftBadge bg={classNames({
                                            success: status === "recent",
                                            danger: status === "overdue",
                                            warning: status === "remaining",
                                            info: status === "responded",
                                            secondary: status === "closed",
                                        })}>{status_display}</SoftBadge>
                                    </Col>
                                    <Col xs="auto">
                                        <h6 className="mb-0 text-500">{formatDateTime(created_at)}</h6>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="border-bottom mt-4 mb-x1"></div>
                        <div className="d-flex justify-content-between ms-auto">
                            <Priority
                                title={priority_display}
                                color={classNames({
                                    "#e63757": priority === "urgent",
                                    "#F68F57": priority === "high",
                                    "#2A7BE4": priority === "medium",
                                    "#00D27B": priority === "low",
                                })}
                                data={classNames({
                                    "100": priority === "urgent",
                                    "75": priority === "high",
                                    "50": priority === "medium",
                                    "25": priority === "low",
                                })}
                            />
                            <AgentSelect agent={agent}/>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CardLayout;
