import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Form, Row, Spinner } from 'react-bootstrap';
import InputField from '../InputField';
import { api } from '../../../utils/api';
import useQuery from '../../../hooks/useQuery';
import Select from 'react-select';
import CSRFToken from '../../../helpers/CSRFToken';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import FormError from '../../errors/FormError';

const ExperienceForm = ({ collapsed, setCollapsed, getUser }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [delegations, setDelegations] = useState([]);
  const [selectedDelegation, setSelectedDelegation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [delegationPage, setDelegationPage] = useState(1);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    company: '',
    position: '',
    delegation: '',
    description: '',
    current: false,
    date_from: '',
    date_to: ''
  });

  let query = useQuery();
  let delegationQuery = useQuery();

  const getCompanies = async () => {
    query.set('page_size', '50');
    query.set('page', page.toString());
    await api
      .get(`/company/?${query.toString()}`)
      .then(res => setCompanies([...companies, ...res?.data?.results]))
      .catch(() => {});
  };

  const getDelegations = async () => {
    delegationQuery.set('page_size', '50');
    delegationQuery.set('page', delegationPage.toString());
    await api
      .get(`/address/delegation/?${delegationQuery.toString()}`)
      .then(res => setDelegations([...delegations, ...res?.data?.results]))
      .catch(() => {});
  };

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line
    }, [page]);

  useEffect(() => {
    getDelegations();
    // eslint-disable-next-line
    }, [delegationPage]);

  let companyOptions = companies?.map(company => ({
    label: company?.name,
    value: company?.id
  }));

  companyOptions = [
    { label: '-------------------------------', value: '' },
    ...companyOptions
  ];

  let delegationOptions = delegations?.map(delegation => ({
    label: delegation?.name,
    value: delegation?.id
  }));

  delegationOptions = [
    { label: '-------------------------------', value: '' },
    ...delegationOptions
  ];

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    api
      .post('/carrier/experience/', formData)
      .then(res => {
        toast.success('Experience successfully added.', { theme: 'colored' });
        getUser();
        setFormData({});
        setSelectedCompany(null);
        setDateFrom(null);
        setDateTo(null);
      })
      .catch(err => {
        setErrors(err?.response?.data);
        toast.error('An error has occurred', { theme: 'colored' });
      });
    setLoading(false);
  };

  return loading ? (
    <Flex justifyContent="center" className="p-2 mb-2">
      <Spinner animation={'border'} variant={'primary'} />
    </Flex>
  ) : (
    <div>
      <Flex
        alignItems="center"
        className="mb-4 text-primary cursor-pointer fs-0"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <span className="circle-dashed">
          <FontAwesomeIcon icon="plus" />
        </span>
        <span className="ms-3">Add new experience</span>
      </Flex>
      <Collapse in={!collapsed}>
        <div className={'overflow-hidden'}>
          <Form>
            <CSRFToken />
            <Form.Group as={Row} className="mb-3" controlId={'company'}>
              <Form.Label column sm={3} className="text-lg-end">
                Company
              </Form.Label>
              <Col sm={9} md={7}>
                <Select
                  options={companyOptions}
                  size="sm"
                  name={'company'}
                  onChange={newValue => {
                    setSelectedCompany(newValue);
                    setFormData({ ...formData, company: newValue.value });
                  }}
                  classNamePrefix={'react-select'}
                  value={selectedCompany}
                  onMenuScrollToBottom={event => {
                    setPage(prevState => prevState + 1);
                  }}
                  onInputChange={newValue => {
                    query.set('search', newValue);
                    getCompanies();
                  }}
                />
              </Col>
              <FormError error={errors?.company} />
            </Form.Group>
            <InputField
              value={formData.position}
              label="Position"
              name="position"
              handleChange={handleChange}
            />
            <FormError error={errors?.position} />
            <Form.Group as={Row} className="mb-3" controlId={'delegation'}>
              <Form.Label column sm={3} className="text-lg-end">
                Delegation
              </Form.Label>
              <Col sm={9} md={7}>
                <Select
                  options={delegationOptions}
                  size="sm"
                  name={'delegation'}
                  onChange={newValue => {
                    setSelectedDelegation(newValue);
                    setFormData({ ...formData, delegation: newValue.value });
                  }}
                  value={selectedDelegation}
                  onMenuScrollToBottom={event => {
                    setDelegationPage(prevState => prevState + 1);
                  }}
                  classNamePrefix={'react-select'}
                  onInputChange={newValue => {
                    delegationQuery.set('search', newValue);
                    getDelegations();
                  }}
                />
              </Col>
              <FormError error={errors?.delegation} />
            </Form.Group>
            <InputField
              value={formData.description}
              label="Description"
              name="description"
              handleChange={handleChange}
              as="textarea"
              rows={3}
            />
            <FormError error={errors?.description} />

            <Form.Group as={Row} className="mb-3" controlId="current">
              <Col sm={{ span: 10, offset: 3 }}>
                <Form.Check
                  type="checkbox"
                  label="I currently work here"
                  checked={formData.current}
                  onChange={({ target }) =>
                    setFormData({ ...formData, current: target.checked })
                  }
                />
              </Col>
              <FormError error={errors?.current} />
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId={'delegation'}>
              <Form.Label column sm={3} className="text-lg-end">
                From
              </Form.Label>
              <Col sm={9} md={7}>
                <DatePicker
                  selected={dateFrom}
                  onChange={date => {
                    setDateFrom(date);
                    setFormData({
                      ...formData,
                      date_from: date.toISOString().slice(0, 10)
                    });
                  }}
                  className="form-control form-control-sm"
                  placeholderText="Select Date"
                />
              </Col>
              <FormError error={errors?.date_from} />
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId={'delegation'}>
              <Form.Label column sm={3} className="text-lg-end">
                To
              </Form.Label>
              <Col sm={9} md={7}>
                <DatePicker
                  selected={dateTo}
                  onChange={date => {
                    setDateTo(date);
                    setFormData({
                      ...formData,
                      date_to: date.toISOString().slice(0, 10)
                    });
                  }}
                  className="form-control form-control-sm"
                  placeholderText="Select Date"
                />
              </Col>
              <FormError error={errors?.date_to} />
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 3 }}>
                <Button type="submit" onClick={handleSubmit}>
                  Save
                </Button>
              </Col>
            </Form.Group>
          </Form>

          <div className="border-dashed border-bottom my-3" />
        </div>
      </Collapse>
    </div>
  );
};

ExperienceForm.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired
};

export default ExperienceForm;
