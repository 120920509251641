import React, {useEffect, useState} from 'react';
import KanbanHeader from './KanbanHeader';
import KanbanContainer from './KanbanContainer';
import {useAppContext} from 'providers/AppProvider';
import {useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import {Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";

const EventTasks = () => {
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(false);

    const {id} = useParams()
    const {
        config: {isFluid, isNavbarVerticalCollapsed},
        setConfig
    } = useAppContext();
    const [kanbanIsFluid] = useState(isFluid);
    const [kanbanIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);

    const getEvent = async () => {
        setLoading(true);
        await api.get(`/event/event/${id}/`).then(res => setEvent(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        getEvent()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setConfig('isFluid', true);
        setConfig('isNavbarVerticalCollapsed', true);

        return () => {
            setConfig('isFluid', kanbanIsFluid);
            setConfig('isNavbarVerticalCollapsed', kanbanIsNavbarVerticalCollapsed);
        };
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <>
            <KanbanHeader event={event}/>
            <KanbanContainer event={event}/>
        </>
    );
};

export default EventTasks;
