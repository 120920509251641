import React, {Fragment, useEffect, useRef, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import {useNavigate, useParams} from "react-router-dom";
import useQuery from "../../../../hooks/useQuery";
import {getActivities} from "../../../activity/actions/Activity";
import {getPermissions} from "../../../account/actions/GroupsPermissions";
import Select, {components} from "react-select";
import IconButton from "../../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import CardHeader from "react-bootstrap/CardHeader";
import {api} from "../../../../utils/api";
import FormError from "../../../errors/FormError";
import {toast} from "react-toastify";
import {getRole} from "../../actions/Role";
import {withPermission} from "../../../../helpers/utils";
import paths from "../../../../routes/paths";
import {useAppContext} from "../../../../providers/AppProvider";
import {withTranslation} from "react-i18next";
import TinymceEditor from "../../../../components/common/TinymceEditor";

const NewEditRole = ({t, i18n}) => {
    const [loading, setLoading] = useState(false)
    const [activities, setActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState(null)
    const [activityPage, setActivityPage] = useState(1)
    const [selectInput, setSelectInput] = useState("");
    const [permissions, setPermissions] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const [permissionsPage, setPermissionsPage] = useState(1)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        name: "",
        activity: "",
        description: "",
        moderator: false,
        permissions: []
    })

    const {
        config: {isActivity},
        setConfig
    } = useAppContext()

    const {id} = useParams()
    const navigate = useNavigate()
    const query = useQuery()
    const isAllSelected = useRef(false);
    const selectAllLabel = useRef("Select all");
    const allOption = {value: "*", label: selectAllLabel.current};

    const fetchActivities = () => {
        setLoading(true)
        query.set("page_size", "50")
        query.set("page", activityPage.toString())
        getActivities(query)
            .then(res => setActivities([...activities, ...res?.results]))
            .catch(() => {
            })
        setLoading(false)
    }

    const fetchPermissions = () => {
        setLoading(true)
        query.set("page_size", "50")
        query.set("page", permissionsPage.toString())
        getPermissions(query)
            .then(res => setPermissions([...permissions, ...res?.results]))
            .catch(() => {
            })
        setLoading(false)
    }

    const handleFieldChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const filterOptions = (options, input) =>
        options?.filter(({label}) =>
            label.toLowerCase().includes(input.toLowerCase())
        );

    const comparator = (v1, v2) => (v1.value) - (v2.value);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        let fd = new FormData()
        fd.append("name", formData.name)
        fd.append("moderator", formData.moderator)
        fd.append("activity", formData.activity)
        fd.append("description", formData.description)
        selectedPermissions?.map(permission => fd.append("permissions", permission.value))
        if (!id)
            await api.post('/member/role/', fd, {
                headers: {
                    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                }
            })
                .then(() => {
                    toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: "colored"})
                    navigate("/members/role")
                })
                .catch(err => {
                    setErrors(err?.response?.data)
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"})
                })
        else
            await api.patch(`/member/role/${id}/`, fd, {
                headers: {
                    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                }
            })
                .then(() => {
                    toast.success(`${t('title')} ${t('editSuccess', {ns: "common"})}`, {theme: "colored"})
                    navigate("/members/role")
                })
                .catch(err => {
                    setErrors(err?.response?.data)
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"})
                })
        setLoading(false)
    }

    useEffect(() => {
        if (id)
            getRole(id)
                .then(res => {
                    setFormData(res)
                    setSelectedActivity({label: res?.activity_name, value: res?.activity})
                    setSelectedPermissions(
                        res?.permissions?.map((group, index) => ({
                            label: res?.permission_names[index],
                            value: group
                        }))
                    );
                })
                .catch((error) => {
                    if (error?.response?.status === 404) navigate(paths.error404);
                    if (error?.response?.status === 500) navigate(paths.error500);
                    if (error?.response?.status === 403)
                        setConfig("isAuthenticated", false);
                });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchActivities()
        // eslint-disable-next-line
    }, [activityPage]);

    useEffect(() => {
        fetchPermissions()
        // eslint-disable-next-line
    }, [permissionsPage]);

    let activityOptions = activities?.map(activity => (
        {label: activity?.name, value: activity?.id}
    ))

    activityOptions = [
        {label: "-----------------------", value: ""},
        ...activityOptions
    ]

    let permissionsOptions = permissions?.map(permission => (
        {label: permission?.name, value: permission?.id}
    ))

    let filteredOptions = filterOptions(permissionsOptions, selectInput);
    let filteredSelectedOptions = filterOptions(selectedPermissions, selectInput);

    const Option = (props) => (
        <components.Option {...props}>
            {props.value === "*" &&
            !isAllSelected.current &&
            filteredSelectedOptions?.length > 0 ? (
                <input
                    key={props.value}
                    type="checkbox"
                    ref={(input) => {
                        if (input) input.indeterminate = true;
                    }}
                />
            ) : (
                <input
                    key={props.value}
                    type="checkbox"
                    checked={props.isSelected || isAllSelected.current}
                    onChange={() => {
                    }}
                />
            )}
            <label style={{marginLeft: "5px"}}>{props.label}</label>
        </components.Option>
    );

    const Input = (props) => (
        <>
            {selectInput.length === 0 ? (
                <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                    {props.children}
                </components.Input>
            ) : (
                <div style={{border: "1px dotted gray"}}>
                    <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                        {props.children}
                    </components.Input>
                </div>
            )}
        </>
    );

    const customFilterOption = ({value, label}, input) =>
        (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
        (value === "*" && filteredOptions?.length > 0);

    const onInputChange = (
        inputValue,
        event
    ) => {
        if (event.action === "input-change") {
            setSelectInput(inputValue)
            query.set("search", inputValue)
            getPermissions(query).then(r => setPermissions(r?.results))
                .catch(() => {
                })
        } else if (event.action === "menu-close" && selectInput !== "")
            setSelectInput("");
    };

    const onKeyDown = (e) => {
        if ((e.key === " " || e.key === "Enter") && !selectInput)
            e.preventDefault();
    };

    const handleChange = (selected) => {
        if (
            selected.length > 0 &&
            !isAllSelected.current &&
            (selected[selected.length - 1].value === allOption.value ||
                JSON.stringify(filteredOptions) ===
                JSON.stringify(selected.sort(comparator)))
        )
            return setSelectedPermissions(
                [
                    ...(selectedPermissions ?? []),
                    ...permissionsOptions.filter(
                        ({label}) =>
                            label.toLowerCase().includes(selectInput?.toLowerCase()) &&
                            (selectedPermissions ?? []).filter((opt) => opt.label === label)
                                .length === 0
                    ),
                ].sort(comparator)
            );
        else if (
            selected.length > 0 &&
            selected[selected.length - 1].value !== allOption.value &&
            JSON.stringify(selected.sort(comparator)) !==
            JSON.stringify(filteredOptions)
        )
            return setSelectedPermissions(selected);
        else
            return setSelectedPermissions([
                ...selectedPermissions?.filter(
                    ({label}) =>
                        !label.toLowerCase().includes(selectInput?.toLowerCase())
                ),
            ]);
    };

    const customStyles = {
        option: (styles, {isSelected, isFocused}) => {
            return {
                ...styles,
                backgroundColor:
                    isSelected && !isFocused
                        ? null
                        : isFocused && !isSelected
                            ? styles.backgroundColor
                            : isFocused && isSelected
                                ? "#DEEBFF"
                                : null,
                color: isSelected ? null : null,
            };
        },
        menu: (def) => ({...def, zIndex: 9999}),
    };

    return (
        loading ?
            <Flex justifyContent="center" className="p-2 mb-2">
                <Spinner animation={'border'} variant={'primary'}/>
            </Flex> :
            <Fragment>
                <Row className={'g-3 mt-1 mb-3'}>
                    <Col xxl={6} xl={12}>
                        <Row className="g-3">
                            <Col xs={12}>
                                <Card>
                                    <Card.Header>
                                        <h5 className="mb-0 text-muted">
                                            {t('fields.basic')}
                                        </h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.name')}: <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        placeholder={t('fields.name')}
                                                        formGroupProps={{
                                                            className:
                                                                'mb-3'
                                                        }}
                                                        onChange={handleFieldChange}
                                                        value={formData.name}
                                                    />
                                                </Form.Group>
                                                <FormError error={errors.name}/>
                                            </Col>
                                            {!isActivity &&
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>{t('fields.activity')}:</Form.Label>
                                                        <Select
                                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.activity')}`}
                                                            options={activityOptions}
                                                            value={selectedActivity}
                                                            classNamePrefix={"react-select"}
                                                            onChange={value => {
                                                                setSelectedActivity(value)
                                                                setFormData({...formData, activity: value.value})
                                                            }}
                                                            onInputChange={value => {
                                                                query.set("search", value)
                                                                getActivities(query).then(r => setActivities(r?.results))
                                                                    .catch(() => {
                                                                    })
                                                            }}
                                                            onMenuScrollToBottom={() => {
                                                                setActivityPage(prevState => prevState + 1)
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <FormError error={errors.activity}/>
                                                </Col>
                                            }
                                        </Row>
                                        <Form.Group className={"mt-3"}>
                                            <Form.Switch
                                                checked={formData.moderator}
                                                label={t('fields.moderator')}
                                                onChange={(e) => {
                                                    setFormData({...formData, moderator: e.target.checked})
                                                }}
                                                name={"moderator"}
                                            />
                                        </Form.Group>
                                        <FormError error={errors.moderator}/>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5 className="mb-0 text-muted">
                                            {t('fields.permissions')} <span className={"text-danger"}>*</span>
                                        </h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Select
                                            inputValue={selectInput}
                                            onInputChange={onInputChange}
                                            filterOption={customFilterOption}
                                            components={{
                                                Option: Option,
                                                Input: Input,
                                            }}
                                            styles={customStyles}
                                            closeMenuOnSelect={false}
                                            tabSelectsValue={false}
                                            backspaceRemovesValue={false}
                                            hideSelectedOptions={false}
                                            blurInputOnSelect={false}
                                            options={[allOption, ...permissionsOptions]}
                                            value={selectedPermissions}
                                            onChange={handleChange}
                                            onKeyDown={onKeyDown}
                                            onMenuScrollToBottom={() => {
                                                setPermissionsPage(prevState => prevState + 1)
                                            }}
                                            classNamePrefix={"react-select"}
                                            isMulti
                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.permissions')}`}
                                        />
                                        <FormError error={errors.permissions}/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={6} xl={12}>
                        <Card>
                            <Card.Header>
                                <h5 className="mb-0 text-muted">
                                    {t('fields.description')} <span className={"text-danger"}>*</span>
                                </h5>
                            </Card.Header>
                            <Card.Body className="bg-light pb-0">
                                <Form.Group>
                                    <TinymceEditor
                                        value={formData.description}
                                        handleChange={newValue => setFormData({...formData, description: newValue})}
                                    />
                                </Form.Group>
                                <FormError error={errors.description}/>
                            </Card.Body>
                            <Card.Footer className={"mt-2"}></Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Card className={"mt-3"}>
                    <CardHeader>
                        <Flex
                            justifyContent={'between'}
                            alignItems={"center"}
                            wrap={'wrap'}
                        >
                            <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                            <IconButton
                                icon={faSave}
                                onClick={e =>
                                    handleSubmit(e)
                                }
                            >
                                                <span className="d-none d-sm-inline-block ms-1">
                                                    {t('save', {ns: "common"})}
                                                </span>
                            </IconButton>
                        </Flex>
                    </CardHeader>
                </Card>
            </Fragment>
    )
}

export default withPermission(withTranslation(["roles", "common"])(NewEditRole), ["member.add_role", "member.change_role"])