// eslint-disable-next-line import/no-anonymous-default-export
import {faCalendarWeek, faComments, faUserFriends, faUserPlus} from "@fortawesome/free-solid-svg-icons";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        media: {icon: faUserPlus, color: 'info', className: 'fs-2'},
        title: `features.elements.0.title`,
        description: `features.elements.0.description`
    },
    {
        media: {icon: faUserFriends, color: 'warning', className: 'fs-2'},
        title: `features.elements.1.title`,
        description: `features.elements.1.description`
    },
    {
        media: {icon: faCalendarWeek, color: 'success', className: 'fs-2'},
        title: `features.elements.2.title`,
        description: `features.elements.2.description`
    },
    {
        media: {icon: faComments, color: 'danger', className: 'fs-2'},
        title: `features.elements.3.title`,
        description: `features.elements.3.description`
    }
];
