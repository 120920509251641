import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import KanbanColumnHeader from './KanbanColumnHeader';
import TaskCard from './TaskCard';
import AddAnotherForm from './AddAnotherForm';
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';
import StrictModeDroppable from './StrictModeDroppable';
import {api} from "../../../utils/api";
import {Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import useQuery from "../../../hooks/useQuery";

const KanbanColumn = ({kanbanColumnItem, getKanbanStatus, kanbanModal, setKanbanModal}) => {
    const {id, name, task_set} = kanbanColumnItem;
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const formViewRef = useRef(null);

    const query = useQuery()

    const handleSubmit = async cardData => {
        setLoading(true)
        const newCard = {
            title: cardData.title,
            status: id
        };
        const isEmpty = !Object.keys(cardData)?.length;

        if (!isEmpty) {
            await api.post("/task/tasks/", newCard)
                .then(res => {
                    getKanbanStatus(query)
                })
                .catch(err => setErrors(err?.response?.data))
            setShowForm(false);
        }
        setLoading(false)
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            formViewRef.current.scrollIntoView({behavior: 'smooth'});
        }, 500);

        return clearTimeout(timeout);
    }, [showForm]);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <div className={classNames('kanban-column', {'form-added': showForm})}>
            <KanbanColumnHeader id={id} title={name} itemCount={task_set?.length} getKanbanStatus={getKanbanStatus}/>
            <StrictModeDroppable droppableId={`${id}`} type="KANBAN">
                {provided => (
                    <>
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            id={`container-${id}`}
                            className="kanban-items-container scrollbar"
                        >
                            {task_set?.map((task, index) => (
                                <TaskCard key={task.id} index={index} task={task} getKanbanStatus={getKanbanStatus}
                                    kanbanModal={kanbanModal} setKanbanModal={setKanbanModal}
                                />
                            ))}
                            {
                                <AddAnotherForm
                                    onSubmit={handleSubmit}
                                    type="card"
                                    showForm={showForm}
                                    setShowForm={setShowForm}
                                    errors={errors}
                                />
                            }
                            {provided.placeholder}
                            <div ref={formViewRef}></div>
                        </div>
                        {!showForm && (
                            <div className="kanban-column-footer">
                                <IconButton
                                    size="sm"
                                    variant="link"
                                    className="d-block w-100 btn-add-card text-decoration-none text-600"
                                    icon="plus"
                                    iconClassName="me-2"
                                    onClick={() => setShowForm(true)}
                                >
                                    Add another card
                                </IconButton>
                            </div>
                        )}
                    </>
                )}
            </StrictModeDroppable>
        </div>
    );
};

KanbanColumn.propTypes = {
    kanbanColumnItem: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        name: PropTypes.string,
        task_set: PropTypes.arrayOf(TaskCard.propTypes.task)
    })
};

export default KanbanColumn;
