import React, {useEffect, useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import Conversation from './Conversation';
import PropertiesFilteringForm from './PropertiesFilteringForm';
import TicketsPreviewHeader from './TicketsPreviewHeader';
import ContactInfo from './ContactInfo';
import {useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import Flex from "../../../components/common/Flex";

const TicketsPreview = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const {id} = useParams()

    const getTicket = async () => {
        setLoading(true)
        await api.get(`/support/tickets/${id}/`).then(res => {
            setData(res?.data)
        })
        setLoading(false)
    }

    useEffect(() => {
        getTicket()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Row className="g-3">
            <Col xxl={9} xl={8}>
                <TicketsPreviewHeader data={data} setLoading={setLoading} setData={setData}/>
                <Conversation data={data} setLoading={setLoading} fetch={getTicket} />
            </Col>
            <Col xxl={3} xl={4}>
                <Row className="g-3 position-sticky top-0">
                    <Col md={6} xl={12}>
                        <PropertiesFilteringForm data={data} setLoading={setLoading} setData={setData} />
                    </Col>
                    <Col xs={12}>
                        <ContactInfo data={data}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default TicketsPreview;
