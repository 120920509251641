import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import computer from 'assets/img/computer_pc_PNG17485.png';
import Section from 'components/common/Section';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';
import Services from './Services';
import Flex from '../../components/common/Flex';
import paths from "../../routes/paths";

const Hero = ({t, i18n}) => {
  return (
    <Section className="py-0 overflow-hidden light" position="center" overlay fluid>
      <div className={'ellipse'}></div>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        wrap={'wrap'}
        className="pt-5 pt-lg-8 pb-lg-6 pb-xl-0"
      >
        <div className="pb-7 pe-4 pb-xl-9 text-center text-xl-start">
          <h1 className="text-primary text-wrap fw-bold mt-2">
            {t('hero.title.line1')} <br />
            {t('hero.title.line2')}{' '}
          </h1>
          <p className="lead text-primary text-wrap opacity-75 mt-3">
            {t('hero.description.line1')} <br />
            {t('hero.description.line2')} <br />
            {t('hero.description.line3')}
          </p>
          <Button
            as={Link}
            variant="primary"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to={`${paths.splitRegister}?next=${paths.starter}`}
          >
            {t('hero.btnText')}
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </div>
        <Link to="/" className={'d-flex justify-content-center'}>
          <img className="img-fluid computer" src={computer} alt="" />
        </Link>
      </Flex>

      <Services t={t} i18n={i18n} />
    </Section>
  );
};

export default Hero;
