import React, {forwardRef} from 'react';
import {Card} from 'react-bootstrap';

const Liability = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="liability" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Liability</h5>
			</Card.Header>
			<Card.Body>
				<h6>Term and Termination</h6>
				<p className="mb-0 ps-3">
					This Agreement shall remain in effect until terminated by you or nexameet.
				</p>
				<p className="mb-0 ps-3 mt-3">
					Nexameet may, in its sole discretion, at any time and for any or no reason, suspend or terminate this
					Agreement with or without prior notice.
				</p>
				<p className="mb-0 ps-3 mt-3">
					This Agreement will terminate immediately, without prior notice from nexameet, in the event that you fail to
					comply with any provision of this Agreement. You may also terminate this Agreement by deleting the website
					and all copies thereof from your computer.
				</p>
				<p className="mb-0 ps-3 mt-3">
					Upon termination of this Agreement, you shall cease all use of the website and delete all copies of the
					website from your computer.
				</p>
				<p className="mb-0 ps-3 mt-3">
					Termination of this Agreement will not limit any of nexameet's rights or remedies at law or in equity in case
					of
					breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.
				</p>
				<hr className="my-4"/>
				<h6>Copyright Infringement Notice</h6>
				<p className="mb-0 ps-3">
					If you are a copyright owner or such owner’s agent and believe any material on our website constitutes an
					infringement on your copyright, please contact us setting forth the following information: (a) a physical or
					electronic signature of the copyright owner or a person authorized to act on his behalf; (b) identification of
					the
					material that is claimed to be infringing; (c) your contact information, including your address, telephone
					number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not
					authorized by the copyright owners; and (e) the a statement that the information in the notification is
					accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.
				</p>
				<hr className="my-4"/>
				<h6>Indemnification</h6>
				<p className="mb-0 ps-3">
					You agree to indemnify and hold nexameet and its parents, subsidiaries, affiliates, officers, employees,
					agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys'
					fees, due to or arising out of your: (a) use of the website; (b) violation of this Agreement or any law or
					regulation; or (c) violation of any right of a third party.
				</p>
				<hr className="my-4"/>
				<h6>No Warranties</h6>
				<p className="mb-0 ps-3">
					The website is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty
					of any kind. To the maximum extent permitted under applicable law, nexameet, on its own behalf and on
					behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all
					warranties, whether express, implied, statutory or otherwise, with respect to the website, including all
					implied
					warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties
					that
					may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the
					foregoing, nexameet provides no warranty or undertaking, and makes no representation of any kind that the
					website will meet your requirements, achieve any intended results, be compatible or work with any other
					software, websites, systems or services, operate without interruption, meet any performance or reliability
					standards or be error free or that any errors or defects can or will be corrected.
				</p>
				<p className="mb-0 ps-3 mt-3">
					Without limiting the foregoing, neither nexameet nor any nexameet's provider makes any representation or
					warranty of any kind, express or implied: (i) as to the operation or availability of the website, or the
					information, content, and materials or products included thereon; (ii) that the website will be uninterrupted
					or
					error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through
					the
					website; or (iv) that the website, its servers, the content, or e-mails sent from or on behalf of nexameet are
					free of viruses, scripts, trojan horses, worms, malware, time bombs or other harmful components.
				</p>
				<p className="mb-0 ps-3 mt-3">
					Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on
					the
					applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not
					apply to you.
				</p>
				<hr className="my-4"/>
				<h6>Limitation of Liability</h6>
				<p className="mb-0 ps-3">
					Notwithstanding any damages that you might incur, the entire liability of nexameet and any of its suppliers
					under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to
					the amount actually paid by you for the website.
				</p>
				<p className="mb-0 ps-3 mt-3">
					To the maximum extent permitted by applicable law, in no event shall nexameet or its suppliers be liable for
					any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to,
					damages for loss of profits, for loss of data or other information, for business interruption, for personal
					injury,
					for loss of privacy arising out of or in any way related to the use of or inability to use the website,
					third-party
					software and/or third-party hardware used with the website, or otherwise in connection with any provision of
					this Agreement), even if nexameet or any supplier has been advised of the possibility of such damages and
					even if the remedy fails of its essential purpose.
				</p>
				<p className="mb-0 ps-3 mt-3">
					Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so
					the above limitation or exclusion may not apply to you.
				</p>
				<hr className="my-4"/>
				<h6>Severability</h6>
				<p className="mb-0 ps-3">
					If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and
					interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
					law and the remaining provisions will continue in full force and effect.
				</p>
				<p className="mb-0 ps-3 mt-3">
					This Agreement, together with the Privacy Policy and any other legal notices published by nexameet on the
					Services, shall constitute the entire agreement between you and nexameet concerning the Services. If any
					provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such
					provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in
					full
					force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of
					such term or any other term, and nexameet’s failure to assert any right or provision under this Agreement
					shall not constitute a waiver of such right or provision. YOU AND nexameet AGREE THAT ANY CAUSE OF
					ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1)
					YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
					PERMANENTLY BARRED.
				</p>
				<hr className="my-4"/>
				<h6>Waiver</h6>
				<p className="mb-0 ps-3">
					Except as provided herein, the failure to exercise a right or to require performance of an obligation under
					this
					Agreement shall not effect a party's ability to exercise such right or require such performance at any time
					thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.
				</p>
				<p className="mb-0 ps-3 mt-3">
					No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under
					this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of
					any
					right or power under this Agreement preclude further exercise of that or any other right granted herein. In
					the
					event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this
					Agreement shall govern.
				</p>
				<hr className="my-4"/>
				<h6>Amendments to this Agreement</h6>
				<p className="mb-0 ps-3">
					Nexameet reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a
					revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What
					constitutes a material change will be determined at our sole discretion.
				</p>
				<p className="mb-0 ps-3 mt-3">
					By continuing to access or use our website after any revisions become effective, you agree to be bound by
					the revised terms. If you do not agree to the new terms, you are no longer authorized to use nexameet.
				</p>
				<hr className="my-4"/>
				<h6>Entire Agreement</h6>
				<p className="mb-0 ps-3">
					The Agreement constitutes the entire agreement between you and nexameet regarding your use of the
					website and supersedes all prior and contemporaneous written or oral agreements between you and
					nexameet.
				</p>
				<p className="mb-0 ps-3 mt-3">
					You may be subject to additional terms and conditions that apply when you use or purchase other
					nexameet's services, which nexameet will provide to you at the time of such use or purchase.
				</p>
				<hr className="my-4"/>
				<h6>Updates to Our Terms</h6>
				<p className="mb-0 ps-3">
					We may change our Service and policies, and we may need to make changes to these Terms so that they
					accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for
					example,
					through our Service) before we make changes to these Terms and give you an opportunity to review them
					before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms.
					If you do not want to agree to these or any updated Terms, you can delete your account.
				</p>
				<hr className="my-4"/>
				<h6>Intellectual Property</h6>
				<p className="mb-0 ps-3">
					The website and its entire contents, features and functionality (including but not limited to all information,
					software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are
					owned by nexameet, its licensors or other providers of such material and are protected by Tunisia and
					international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights
					laws. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole
					or in part, without the express prior written permission of nexameet, unless and except as is expressly
					provided in these Terms & Conditions. Any unauthorized use of the material is prohibited.
				</p>
				<hr className="my-4"/>
				<h6>Agreement to Arbitrate</h6>
				<p className="mb-0 ps-3">
					This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE RELATING TO CLAIMS
					FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR
					OR nexameet’s INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute, action, or
					other controversy between you and nexameet concerning the Services or this agreement, whether in
					contract, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute”
					will be
					given the broadest possible meaning allowable under law.
				</p>
				<hr className="my-4"/>
				<h6>Notice of Dispute</h6>
				<p className="mb-0 ps-3">
					In the event of a dispute, you or nexameet must give the other a Notice of Dispute, which is a written
					statement that sets forth the name, address, and contact information of the party giving it, the facts giving
					rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to:
					support@nexameet.com. nexameet will send any Notice of Dispute to you by mail to your address if we have
					it, or otherwise to your email address. You and nexameet will attempt to resolve any dispute through informal
					negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or
					nexameet may commence arbitration.
				</p>
				<hr className="my-4"/>
				<h6>Binding Arbitration</h6>
				<p className="mb-0 ps-3">
					If you and nexameet don’t resolve any dispute by informal negotiation, any other effort to resolve the dispute
					will be conducted exclusively by binding arbitration as described in this section. You are giving up the right
					to
					litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The
					dispute
					shall be settled by binding arbitration in accordance with the commercial arbitration rules of the American
					Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of
					competent jurisdiction, as necessary to protect the party’s rights or property pending the completion of
					arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing
					party shall be borne by the non-prevailing party.
				</p>
				<hr className="my-4"/>
				<h6>Submissions and Privacy</h6>
				<p className="mb-0 ps-3">
					In the event that you submit or post any ideas, creative suggestions, designs, photographs, information,
					advertisements, data or proposals, including ideas for new or improved products, services, features,
					technologies or promotions, you expressly agree that such submissions will automatically be treated as
					non-confidential and non-proprietary and will become the sole property of nexameet without any
					compensation or credit to you whatsoever. nexameet and its affiliates shall have no obligations with respect
					to such submissions or posts and may use the ideas contained in such submissions or posts for any
					purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing
					products and services using such ideas.
				</p>
				<hr className="my-4"/>
				<h6>Promotions</h6>
				<p className="mb-0 ps-3">
					Nexameet may, from time to time, include contests, promotions, sweepstakes, or other activities
					(“Promotions”) that require you to submit material or information concerning yourself. Please note that all
					Promotions may be governed by separate rules that may contain certain eligibility requirements, such as
					restrictions as to age and geographic location. You are responsible to read all Promotions rules to determine
					whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and to
					comply with all Promotions Rules.
				</p>
				<p className="mb-0 ps-3 mt-3">
					Additional terms and conditions may apply to purchases of goods or services on or through the Services,
					which terms and conditions are made a part of this Agreement by this reference.
				</p>
				<hr className="my-4"/>
				<h6>Typographical Errors</h6>
				<p className="mb-0 ps-3">
					In the event a product and/or service is listed at an incorrect price or with incorrect information due to
					typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or
					service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or
					not
					the order has been confirmed and your credit card charged. If your credit card has already been charged for
					the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or
					other payment account in the amount of the charge.
				</p>
				<hr className="my-4"/>
				<h6>Miscellaneous</h6>
				<p className="mb-0 ps-3">
					If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions
					to be unenforceable, the remainder of these Terms & Conditions will continue in full force and effect. Any
					waiver of any provision of these Terms & Conditions will be effective only if in writing and signed by an
					authorized representative of nexameet. nexameet will be entitled to injunctive or other equitable relief
					(without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by
					you. nexameet operates and controls the nexameet Service from its offices in Tunisia. The Service is not
					intended for distribution to or use by any person or entity in any jurisdiction or country where such
					distribution
					or use would be contrary to law or regulation. Accordingly, those persons who choose to access the
					nexameet Service from other locations do so on their own initiative and are solely responsible for compliance
					with local laws, if and to the extent local laws are applicable. These Terms & Conditions (which include and
					incorporate the nexameet Privacy Policy) contains the entire understanding, and supersedes all prior
					understandings, between you and nexameet concerning its subject matter, and cannot be changed or
					modified by you. The section headings used in this Agreement are for convenience only and will not be given
					any legal import.
				</p>
			</Card.Body>
		</Card>
	);
});

export default Liability;
