import React, {useCallback, useEffect, useState} from 'react';
import {Card, Col, Offcanvas, Row, Spinner} from 'react-bootstrap';
import AllTicketsHeader from './AllTicketsHeader';
import TicketFilteringForm from './TicketFilteringForm';
import {useBreakpoints} from 'hooks/useBreakpoints';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CardLayout from './CardLayout';
import useQuery from "../../../hooks/useQuery";
import {useAppContext} from "../../../providers/AppProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {debounce} from "lodash";
import Flex from "../../../components/common/Flex";
import Avatar from "../../../components/common/Avatar";
import SoftBadge from "../../../components/common/SoftBadge";
import Priority from "../../../components/app/support-desk/tickets-layout/Priority";
import AgentSelect from "../../../components/app/support-desk/tickets-layout/AgentSelect";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {withPermission} from "../../../helpers/utils";

const CardView = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);

    const query = useQuery();
    const {breakpoints} = useBreakpoints();
    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchTickets = async q => {
        setLoading(true);
        await api.get(`/support/tickets/?${q.toString()}`)
            .then(res => {
                setData(res?.data?.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchTickets, 600), []);

    useEffect(() => {
        fetchTickets(query);
        setLength(data.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, []);

    const columns = [
        {
            accessor: 'name',
            header: 'Client',
            meta: {
                headerProps: {className: 'ps-2 text-900', style: {height: '46px'}},
                cellProps: {
                    className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
                }
            },
            cell: ({row: {original}}) => {
                const {name, avatar} = original;
                return (
                    <Flex alignItems="center" className="position-relative py-1">
                        {avatar.img ? (
                            <Avatar src={avatar.img} size="xl" className="me-2"/>
                        ) : (
                            <Avatar size="xl" name={avatar.name} className="me-2"/>
                        )}
                        <h6 className="mb-0">
                            <Link to={paths.contactDetails} className="stretched-link text-900">
                                {name}
                            </Link>
                        </h6>
                    </Flex>
                );
            }
        },
        {
            accessor: 'subject',
            header: 'Subject',
            meta: {
                headerProps: {
                    style: {minWidth: '14.625rem'},
                    className: 'text-900'
                },
                cellProps: {
                    className: 'py-2 pe-4'
                }
            },
            cell: ({row: {original}}) => {
                const {subject} = original;
                return (
                    <Link to={paths.ticketsPreview} className="fw-semibold">
                        {subject}
                    </Link>
                );
            }
        },
        {
            accessor: 'status',
            header: 'Status',
            meta: {
                headerProps: {className: 'text-900'},
                cellProps: {
                    className: 'fs-9 pe-4'
                }
            },
            cell: ({row: {original}}) => {
                const {status} = original;
                return (
                    <SoftBadge bg={status.type} className="me-2">
                        {status.content}
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'priority',
            header: 'Priority',
            meta: {
                headerProps: {className: 'text-900'},
                cellProps: {
                    className: 'pe-4'
                }
            },
            cell: ({row: {original}}) => {
                const {priority} = original;
                return (
                    <Priority
                        title={priority.title}
                        color={priority.color}
                        data={priority.data}
                    />
                );
            }
        },
        {
            accessor: 'agent',
            header: 'Agent',
            meta: {
                headerProps: {className: 'text-end text-900'}
            },
            cell: ({row: {original}}) => {
                const {agent} = original;
                return <AgentSelect agent={agent} className="w-auto ms-auto"/>;
            }
        }
    ];

    const ticketList = data?.map(ticket => ({
        id: ticket.id,
        client: ticket.client,
        client_full_name: ticket.client_full_name,
        client_avatar: ticket.client_avatar,
        subject: ticket.subject,
        status: ticket.status,
        status_display: ticket.status_display,
        priority: ticket.priority,
        priority_display: ticket.priority_display,
        agent: ticket.agent,
        agent_name: ticket.agent_name,
        created_at: ticket.created_at,
    }));

    return (
        <Row className="gx-3">
            <Col xxl={10} xl={9}>
                <AdvanceTableWrapper
                    columns={columns}
                    data={ticketList}
                    selection
                    pagination
                    perPage={20}
                >
                    <Card>
                        <Card.Header className="border-bottom border-200 px-0">
                            <AllTicketsHeader table layout="card-view" handleShow={handleShow} fetchData={delayedLoadItems}/>
                        </Card.Header>
                        <Card.Body className="bg-body-tertiary">
                            {loading ? (
                                <Flex justifyContent="center" className="p-2 mb-2">
                                    <Spinner animation={'border'} variant={'primary'}/>
                                </Flex>
                            ) : (
                                <CardLayout table data={ticketList} />
                            )}
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-center">
                            <AdvanceTablePagination
                                count={count}
                                fetch={fetchTickets}
                                query={query}
                                length={length}
                                itemsPerPage={20}
                            />
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            </Col>
            <Col xxl={2} xl={3}>
                {breakpoints.down('xl') ? (
                    <Offcanvas
                        show={show}
                        onHide={handleClose}
                        placement="end"
                        className="dark__bg-card-dark"
                    >
                        <Offcanvas.Header closeButton className="bg-body-tertiary">
                            <h6 className="fs-9 mb-0 fw-semibold">Filter</h6>
                        </Offcanvas.Header>
                        <TicketFilteringForm/>
                    </Offcanvas>
                ) : (
                    <TicketFilteringForm/>
                )}
            </Col>
        </Row>
    );
};

export default withPermission(CardView, "support.view_supportticket");
