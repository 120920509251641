import React, {Fragment, useEffect, useState} from "react";
import {api} from "../../../utils/api";
import {useNavigate, useParams} from "react-router-dom";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import SoftBadge from "../../../components/common/SoftBadge";
import classNames from "classnames";
import IconButton from "../../../components/common/IconButton";
import {convertPrice, formatDateTime, formatNumberCurrency, withPermission} from "../../../helpers/utils";
import SimpleBarReact from "simplebar-react";
import {toast} from "react-toastify";
import CSRFToken from "../../../helpers/CSRFToken";
import {useAppContext} from "../../../providers/AppProvider";
import Invoice from "./Invoice";

const BillingDetail = () => {
    const [loading, setLoading] = useState(false);
    const [billing, setBilling] = useState({});
    const [monthlyPrice, setMonthlyPrice] = useState(0);
    const [yearlyPrice, setYearlyPrice] = useState(0);
    const [amount, setAmount] = useState(0);
    const [currencyData, setCurrencyData] = useState({})

    const {
        config: {currency}
    } = useAppContext()
    const {id} = useParams();
    const navigate = useNavigate();

    const fetchBilling = async () => {
        setLoading(true)
        await api.get(`/billing/billing/${id}/`).then(async res => {
            if (res?.data?.type === 'card') {
                await api.get(`/checkout/${id}/`)
                    .then(response => setBilling(response?.data))
            } else if (res?.data?.type === 'provider' && res?.data?.provider_data?.provider === "paymee") {
                await api.get(`/paymee/${id}/`)
                    .then(response => setBilling(response?.data))
            } else if (res?.data?.type === 'provider' && res?.data?.provider_data?.provider === "konnect") {
                await api.get(`/konnect/konnect/${id}/`)
                    .then(response => setBilling(response?.data))
            } else {
                setBilling(res?.data)
            }
        })
        setLoading(false)
    }

    const getCurrencyData = async () => {
        await api.get(`/currency/${currency}/`).then(res => setCurrencyData(res?.data))
    }

    const reprocessPayment = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (billing?.type === "card") {
            await api.post("/checkout/reprocess_payment/", {id: id})
                .then(res => {
                    toast.success("Payment successfully reprocessed!", {theme: "colored"})
                })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                })
        }
        fetchBilling()
        setLoading(false)
    }

    useEffect(() => {
        fetchBilling()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getCurrencyData()
        // eslint-disable-next-line
    }, [currency]);

    useEffect(() => {
        const fetchInfo = async () => {
            const convertedMonthlyPrice = await convertPrice(billing?.membership_details?.monthly_price, billing?.membership_details?.currency, currency)
            const convertedYearlyPrice = await convertPrice(billing?.membership_details?.yearly_price, billing?.membership_details?.currency, currency)
            const convertedAmount = await convertPrice(billing?.amount, billing?.currency, currency)
            setMonthlyPrice(formatNumberCurrency(convertedMonthlyPrice, currencyData))
            setYearlyPrice(formatNumberCurrency(convertedYearlyPrice, currencyData))
            setAmount(formatNumberCurrency(convertedAmount, currencyData))
        };

        fetchInfo();
        // eslint-disable-next-line
    }, [billing, currencyData]);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className={"d-print-none"}>
                <Card.Header>
                    <Flex justifyContent={"between"} alignItems={"center"}>
                        <Flex justifyContent={"between"} alignItems={"center"}>
                            <IconButton
                                onClick={() => navigate(-1)}
                                variant="falcon-default"
                                size="sm"
                                icon="arrow-left"
                            />
                            <h5 className={"ms-2"}>#{billing?.code}</h5>
                        </Flex>
                        <Flex justifyContent={"between"} alignItems={"center"}>
                            <CSRFToken/>
                            <SoftBadge
                                pill
                                className={"me-2"}
                                bg={classNames({
                                    warning: billing?.status === "pending",
                                    danger: billing?.status === "rejected",
                                    success: billing?.status === "approved",
                                })}
                            >
                                {billing.status_display}
                            </SoftBadge>
                            {billing?.status === "rejected" &&
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="sync-alt"
                                    iconAlign="middle"
                                    className="d-none d-sm-block"
                                    onClick={reprocessPayment}
                                >
								<span className="d-none d-xl-inline-block ms-1">
              Reprocess payment
            </span>
                                </IconButton>
                            }
                            {/*<IconButton*/}
                            {/*    variant="falcon-default"*/}
                            {/*    size="sm"*/}
                            {/*    icon="arrow-down"*/}
                            {/*    className="me-1 mb-2 mb-sm-0"*/}
                            {/*    iconClassName="me-1"*/}
                            {/*>*/}
                            {/*    Download (.pdf)*/}
                            {/*</IconButton>*/}
                            {billing?.status === "approved" &&
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="print"
                                    iconClassName="me-1"
                                    className="ms-2 me-1 mb-2 mb-sm-0"
                                    onClick={() => window.print()}
                                >
                                    Print
                                </IconButton>
                            }
                            {/*<IconButton*/}
                            {/*    variant="falcon-success"*/}
                            {/*    size="sm"*/}
                            {/*    icon="dollar-sign"*/}
                            {/*    className="mb-2 mb-sm-0"*/}
                            {/*>*/}
                            {/*    Receive Payment*/}
                            {/*</IconButton>*/}
                        </Flex>
                    </Flex>
                </Card.Header>
            </Card>
            <Row className={"mt-3"}>
                <Col lg={8}>
                    <Card className={"d-print-none"}>
                        <Card.Header>
                            <h5 className={"text-500 text-muted"}>Billing information</h5>
                        </Card.Header>
                        <Card.Body>
                            <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                <p>{billing?.description}</p>
                                <p>Date: {formatDateTime(billing?.date)}</p>
                            </Flex>
                            <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                <p>Amount: {amount}</p>
                                <p>By: {billing?.type_display}</p>
                            </Flex>
                            {billing?.type === "card" &&
                                <div>
                                    <h6>Card information</h6>
                                    <p>Address: {billing?.method_details?.full_address}</p>
                                    <p>Name: {billing?.method_details?.name}</p>
                                    <Flex justifyContent={"between"} alignItems={"center"}>
                                        <p>{billing?.method_details?.number}</p>
                                        <p>{billing?.method_details?.expiry_month}/{billing?.method_details?.expiry_year}</p>
                                        <p>{billing?.method_details?.cvv}</p>
                                    </Flex>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className={"d-print-none"}>
                        <Card.Header>
                            <h5 className={"text-500 text-muted"}>Membership information</h5>
                        </Card.Header>
                        <Card.Body>
                            <p>{billing?.membership_details?.name}</p>
                            <p>{billing?.membership_details?.desc}</p>
                            <p>Monthly
                                amount: {monthlyPrice}</p>
                            <p>Annual amount: {yearlyPrice}</p>
                            <p>Yearly saving: {billing?.membership_details?.yearly_saving}%</p>
                        </Card.Body>
                    </Card>
                </Col>
                {billing?.errors?.length > 0 ?
                    billing?.type === "provider" ? (
                        <Col>
                            <Card className={"mt-3 d-print-none"}>
                                <Card.Header>
                                    <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                        <h5 className={"text-500 text-muted"}>Errors information</h5>
                                        <SoftBadge pill bg={"danger"}>
                                            {billing?.status_code}
                                        </SoftBadge>
                                    </Flex>
                                </Card.Header>
                                <Card.Body>
                                    <SimpleBarReact>
                                        <Table responsive className="p-2 mb-2">
                                            <thead>
                                            <tr>
                                                <th className={"text-center"}>Amount</th>
                                                <th className={"text-center"}>Token</th>
                                                <th className={"text-center"}>From</th>
                                                <th className={"text-center"}>Method</th>
                                                <th className={"text-center"}>Type</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {billing?.errors?.map((error, index) => (
                                                <tr key={index}>
                                                    <td className={"text-center"}>{error?.amount / 1000}</td>
                                                    <td className={"text-center"}>{error?.token}</td>
                                                    <td className={"text-center"}>{error?.from}</td>
                                                    <td className={"text-center"}>{error?.method}</td>
                                                    <td className={"text-center"}>{error?.type}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </SimpleBarReact>
                                </Card.Body>
                            </Card>
                        </Col>
                    ) : (
                        <Col>
                            <Card className={"mt-3 d-print-none"}>
                                <Card.Header>
                                    <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                        <h5 className={"text-500 text-muted"}>Errors information</h5>
                                        <SoftBadge pill bg={"danger"}>
                                            {billing?.status_code}
                                        </SoftBadge>
                                    </Flex>
                                </Card.Header>
                                <Card.Body>
                                    <SimpleBarReact>
                                        <Table responsive className="p-2 mb-2">
                                            <thead>
                                            <tr>
                                                <th className={"text-center"}>Code</th>
                                                <th className={"text-center"}>Type</th>
                                                <th className={"text-center"}>Request</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {billing?.errors?.map((error, index) => (
                                                <tr key={index}>
                                                    <td className={"text-center"}>{error?.error_codes}</td>
                                                    <td className={"text-center"}>{error?.error_type}</td>
                                                    <td className={"text-center"}>{error?.request_id}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </SimpleBarReact>
                                </Card.Body>
                            </Card>
                        </Col>
                    ) : ""

                }
                {billing?.status === "approved" &&
                    <Col lg={12}>
                        <Invoice billing={billing} amount={amount} currencyData={currencyData} currency={currency}/>
                    </Col>
                }
            </Row>
        </Fragment>
    )

}

export default withPermission(BillingDetail, "billing.view_billing");