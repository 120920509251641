import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Experience from '../Experience';

const Experiences = ({experiences, t}) => {
  return (
    <Card className="mb-3">
      <FalconCardHeader title={t('experience', {ns: "common"})} light />
      <Card.Body className="fs--1">
        {experiences?.map((experience, index) => (
          <Experience
            key={experience.id}
            experience={experience}
            isLast={index === experiences?.length - 1}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default Experiences;
