import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Form,
    FormControl, FormSelect,
    InputGroup,
    Table
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import {Field, FieldArray} from 'formik';
import {api} from "../../../utils/api";

const TicketRow = ({
                       checked = false,
                       id,
                       setFieldValue,
                       handleRemove,
                       t
                   }) => {
    const [currencies, setCurrencies] = useState([])

    const getCurrencies = async () => {
        await api.get('/currency/').then(res => setCurrencies(res?.data?.results))
    }

    useEffect(() => {
        getCurrencies()
    }, []);

    return (
        <tr>
            <td>
                <Field name={`ticket.options.${id}.name`}>
                    {({field}) => (
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder={t('fields.ticketPrice.options.name')}
                            value={field.value}
                            onChange={({target}) => setFieldValue(field.name, target.value)}
                        />
                    )}
                </Field>
            </td>
            <td>
                <Field name={`ticket.options.${id}.price`}>
                    {({field}) => (
                        <InputGroup size="sm">
                            <InputGroup.Text className="d-none d-sm-block">$</InputGroup.Text>
                            <FormControl
                                type="number"
                                placeholder={t('fields.ticketPrice.options.price')}
                                value={field.value}
                                aria-label="Dollar amount (with dot and two decimal places)"
                                step="0.01"
                                onChange={({target}) =>
                                    setFieldValue(field.name, target.value)
                                }
                            />
                        </InputGroup>
                    )}
                </Field>
            </td>
            <td>
                <Field name={`ticket.options.${id}.currency`}>
                    {({field}) => (
                        <FormSelect
                            value={field.value}
                            onChange={({target}) =>
                                setFieldValue(field.name, target.value)
                            }
                        >
                            <option>{t('select', {ns: "common"})} {t('fields.ticketPrice.options.currency')}</option>
                            {currencies?.map(currency => (
                                <option value={currency?.code} selected={currency?.is_default}>{currency?.name}</option>
                            ))}
                        </FormSelect>
                    )}
                </Field>
            </td>
            <td className="text-center align-middle">
                <Field name={`ticket.options.${id}.checked`}>
                    {({field}) => (
                        <Form.Check
                            type="radio"
                            id={`ticketChecked${id + 1}`}
                            checked={checked}
                            onChange={({target}) =>
                                setFieldValue(field.name, target.checked)
                            }
                        />
                    )}
                </Field>
            </td>
            <td className="text-center align-middle">
                <Button variant="link" size="sm" onClick={() => handleRemove(id)}>
                    <FontAwesomeIcon className="text-danger" icon="times-circle"/>
                </Button>
            </td>
        </tr>
    );
};

const EventTicket = ({values, setFieldValue, errors, t}) => {
    const [type, setType] = useState('paid');
    // Change Ticket
    const changeTicket = (id, name, value) => {
        const updatedTickets =
            name === 'checked'
                ? values?.ticket?.options?.map(ticket => ({
                    ...ticket,
                    checked: false
                }))
                : [...values?.ticket?.options];
        const updatedTicket = {...values?.ticket?.options[id], [name]: value};

        setFieldValue('ticket.options', [
            ...updatedTickets.slice(0, id),
            updatedTicket,
            ...updatedTickets.slice(id + 1)
        ]);
    };

    const changeTicketType = (e, type) => {
        setType(type);
        setFieldValue('ticket.type', type);
    };

    return (
        <Card className="mb-3">
            <Card.Header as="h5">{t('fields.ticketPrice.title')}</Card.Header>
            <Card.Body className="bg-light">
                <Button
                    variant={`falcon-${type === 'free' ? 'primary' : 'default'}`}
                    size="sm"
                    onClick={e => changeTicketType(e, 'free')}
                >
                    {t('fields.ticketPrice.free')}
                </Button>
                <Button
                    variant={`falcon-${type === 'paid' ? 'primary' : 'default'}`}
                    className="ms-2"
                    size="sm"
                    onClick={e => changeTicketType(e, 'paid')}
                >
                    {t('fields.ticketPrice.paid')}
                </Button>
                <Button
                    variant={`falcon-${type === 'donation' ? 'primary' : 'default'}`}
                    className="ms-2"
                    size="sm"
                    onClick={e => changeTicketType(e, 'donation')}
                >
                    {t('fields.ticketPrice.donation')}
                </Button>

                <hr/>
                <h6 className="fs-0">{t('fields.ticketPrice.options.title')}:</h6>
                <Field name={'ticket.variable'}>
                    {({field}) => (
                        <Form.Check
                            type="checkbox"
                            id="variablePricingCheckbox"
                            label={t('fields.ticketPrice.options.variable')}
                            defaultChecked
                            checked={field.value}
                            name={'ticket.variable'}
                            onChange={e => {
                                setFieldValue(field.name, e.target.checked);
                            }}
                        />
                    )}
                </Field>

                <FieldArray
                    name={'ticket.options'}
                    render={arrayHelpers => (
                        <>
                            <SimpleBarReact>
                                <Table className="bg-white mb-2 dark__bg-1100 mt-3" bordered>
                                    <thead>
                                    <tr className="fs--1">
                                        <th scope="col">{t('fields.ticketPrice.options.name')} <span
                                            className={"text-danger"}>*</span></th>
                                        <th scope="col">{t('fields.ticketPrice.options.price')} <span
                                            className={"text-danger"}>*</span></th>
                                        <th scope="col">{t('fields.ticketPrice.options.currency')} <span
                                            className={"text-danger"}>*</span></th>
                                        <th scope="col">{t('fields.ticketPrice.options.default')}</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody className="event-ticket-body">
                                    {values?.ticket?.options && values?.ticket?.options?.length > 0
                                        ? values?.ticket?.options?.map((field, index) => (
                                            <>
                                                <TicketRow
                                                    {...field}
                                                    handleChange={changeTicket}
                                                    name={field.name}
                                                    id={index}
                                                    setFieldValue={setFieldValue}
                                                    t={t}
                                                    handleRemove={() => arrayHelpers.remove(index)}
                                                />
                                            </>
                                        ))
                                        : ''}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                            <IconButton
                                onClick={() =>
                                    arrayHelpers.insert(values?.ticket?.options?.length, '')
                                }
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                            >
                                {t('addItem', {ns: "common"})}
                            </IconButton>
                        </>
                    )}
                />
            </Card.Body>
        </Card>
    );
};

TicketRow.propTypes = {
    name: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    checked: PropTypes.bool,
    id: PropTypes.number.isRequired,
    handleRemove: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired
};

export default EventTicket;
