import React, {Fragment, useEffect, useState} from "react";
import {api} from "../../../../utils/api";
import Flex from "../../../../components/common/Flex";
import {Card, Form, Spinner, Table} from "react-bootstrap";
import paths from "../../../../routes/paths";
import {useAppContext} from "../../../../providers/AppProvider";
import {useNavigate} from "react-router-dom";
import {Field, FieldArray, Formik} from "formik";
import Select from "react-select";
import FormError from "../../../errors/FormError";
import FormErrors from "../../../errors/FormErrors";
import IconButton from "../../../../components/common/IconButton";
import {withTranslation} from "react-i18next";
import CSRFToken from "../../../../helpers/CSRFToken";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const MembershipFeesContent = ({t, i18n}) => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [selectedMembershipType, setSelectedMembershipType] = useState(null);
	const [currencies, setCurrencies] = useState([]);
	const [membership, setMembership] = useState({});
	const [formData, setFormData] = useState({
		type: "",
		variable: true,
		options: [
			{
				name: '',
				price: '',
				currency: '',
				default: false
			}
		]
	});

	const {
		setConfig
	} = useAppContext()
	const navigate = useNavigate();

	const getCurrencies = async () => {
		return (await api.get('/currency/')).data;
	};

	const fetchMembership = async () => {
		setLoading(true)
		await api.get("/activity/membership/").then((res) => {
			const data = res?.data?.results[0]
			setMembership(data)
			setFormData({
				type: data?.type,
				variable: data?.variable,
				options: data?.membership_options?.map(option => ({
					currency: option?.currency,
					default: option?.default,
					name: option?.name,
					price: option?.price
				}))
			});
			setSelectedMembershipType({
				value: data?.type,
				label: data?.type_display
			});
		})
			.catch((error) => {
				if (error?.response?.status === 404) navigate(paths.error404);
				if (error?.response?.status === 500) navigate(paths.error500);
				if (error?.response?.status === 403)
					setConfig("isAuthenticated", false);
			});
		setLoading(false)
	}

	const handleSubmit = async (e, values) => {
		e.preventDefault()
		setLoading(true)
		await api.patch(`/activity/membership/${membership?.id}/`, values)
			.then(res => {
				toast.success("Membership updated successfully!", {theme: "colored"});
				const data = res?.data
				setMembership(data)
				setFormData({
					type: data?.type,
					variable: data?.variable,
					options: data?.membership_options?.map(option => ({
						currency: option?.currency,
						default: option?.default,
						name: option?.name,
						price: option?.price
					}))
				});
				setSelectedMembershipType({
					value: data?.type,
					label: data?.type_display
				});
			})
			.catch(err => {
				toast.error("An error has occurred.", {theme: "colored"})
				setErrors(err?.response?.data)
			})
		setLoading(false)
	}

	useEffect(() => {
		getCurrencies().then(res => setCurrencies(res?.results));
	}, []);

	useEffect(() => {
		fetchMembership()
		// eslint-disable-next-line
	}, []);

	const membershipTypes = [
		{value: 'free', label: t('fields.membership.type.free')},
		{value: 'paid', label: t('fields.membership.type.paid')}
	];

	return loading ? (
		<Flex justifyContent="center" className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Fragment>
			<Formik
				initialValues={formData}
				onSubmit={values => console.log(values)}
			>
				{({values, setFieldValue}) => (
					<>
						<Form>
							<CSRFToken/>
							<Card className={'pb-3 mt-3'}>
								<Card.Header>
									<h5 className="mb-0 text-muted">
										{t('fields.membership.title')}
									</h5>
								</Card.Header>
								<Card.Body className="bg-light pb-0">
									<Form.Group>
										<Form.Label>{t('fields.membership.type.title')}:</Form.Label>
										<Field name="type">
											{({field}) => (
												<Select
													options={membershipTypes}
													value={selectedMembershipType}
													classNamePrefix="react-select"
													onChange={value => {
														setSelectedMembershipType(value);
														setFieldValue(field.name, value.value);
													}}
													placeholder={`${t('select', {ns: "common"})} ${t('fields.membership.type.title')}`}
												/>
											)}
										</Field>
										<FormError error={errors?.type}/>
									</Form.Group>
									<Form.Group className={'mt-3'}>
										<Field name="variable">
											{({field}) => (
												<Form.Switch
													label={t('fields.membership.variable')}
													name="variable"
													errors={errors}
													formGroupProps={{
														className: 'mb-3'
													}}
													onChange={e =>
														setFieldValue(
															field.name,
															e.target.checked
														)
													}
													checked={field.value}
												/>
											)}
										</Field>
										<FormError error={errors?.variable}/>
									</Form.Group>
									<Table
										responsive
										className="border-bottom"
										size={'sm'}
									>
										<thead>
										<tr>
											<td>{t('fields.membership.name')}</td>
											<td>{t('fields.membership.price')}</td>
											<td>{t('fields.membership.currency')}</td>
											<td>{t('fields.membership.default')}</td>
											<td className={'p-0'}></td>
										</tr>
										</thead>
										<FieldArray
											name={'options'}
											render={arrayHelpers => (
												<>
													<tbody>
													{values &&
													values?.options?.length > 0
														? values?.options?.map(
															(field, index) => (
																<tr key={index}>
																	<td>
																		<Field
																			name={`options.${index}.name`}
																		>
																			{({field, form}) => (
																				<Form.Control
																					placeholder={t('fields.membership.name')}
																					name={`options.${index}.name`}
																					errors={errors}
																					formGroupProps={{
																						className: 'mb-3'
																					}}
																					style={{width: 200}}
																					onChange={e => {
																						setFieldValue(
																							field.name,
																							e.target.value
																						);
																					}}
																					value={field.value}
																				/>
																			)}
																		</Field>
																		<FormErrors
																			errors={
																				errors?.options
																			}
																			error={'name'}
																			index={index}
																		/>
																	</td>
																	<td>
																		<Field
																			name={`options.${index}.price`}
																		>
																			{({field, form}) => (
																				<Form.Control
																					type={'number'}
																					placeholder={t('fields.membership.price')}
																					name={`options.${index}.price`}
																					errors={errors}
																					style={{width: 200}}
																					formGroupProps={{
																						className: 'mb-3'
																					}}
																					onChange={e => {
																						setFieldValue(
																							field.name,
																							e.target.value
																						);
																					}}
																					value={field.value}
																				/>
																			)}
																		</Field>
																		<FormErrors
																			errors={
																				errors?.options
																			}
																			error={'price'}
																			index={index}
																		/>
																	</td>
																	<td>
																		<Field
																			name={`options.${index}.currency`}
																		>
																			{({field}) => (
																				<Form.Select
																					value={field.value}
																					classNamePrefix="react-select"
																					onChange={e => {
																						setFieldValue(
																							field.name,
																							e.target.value
																						);
																					}}
																				>
																					<option value={''}>
																						{t('select', {ns: "common"})} {t('fields.membership.currency')}
																					</option>
																					{currencies?.map(
																						currency => (
																							<option
																								value={
																									currency.code
																								}
																								selected={currency?.is_default}
																							>
																								{currency.name}
																							</option>
																						)
																					)}
																				</Form.Select>
																			)}
																		</Field>
																		<FormErrors
																			errors={
																				errors?.options
																			}
																			error={'currency'}
																			index={index}
																		/>
																	</td>
																	<td>
																		<Flex
																			justifyContent={'center'}
																			alignItems={'center'}
																			className={'mt-2'}
																		>
																			<Field
																				name={`options.${index}.default`}
																			>
																				{({field}) => (
																					<Form.Switch
																						name={`options.${index}.default`}
																						errors={errors}
																						formGroupProps={{
																							className: 'mb-3'
																						}}
																						onChange={e =>
																							setFieldValue(
																								field.name,
																								e.target.checked
																							)
																						}
																						checked={field.value}
																					/>
																				)}
																			</Field>
																			<FormErrors
																				errors={
																					errors?.options
																				}
																				error={'default'}
																				index={index}
																			/>
																		</Flex>
																	</td>
																	<td className={'me-0 pe-0'}>
																		<Flex
																			justifyContent={'center'}
																			alignItems={'center'}
																			className={'mt-1'}
																		>
																			<IconButton
																				variant="falcon-default"
																				icon="trash"
																				size={'sm'}
																				iconClassName={'ms-1'}
																				onClick={() => {
																					arrayHelpers.remove(
																						index
																					);
																				}}
																			>
																				{' '}
																			</IconButton>
																		</Flex>
																	</td>
																</tr>
															)
														)
														: ''}
													</tbody>
													{values?.variable && (
														<tfoot>
														<tr>
															<td>
																<IconButton
																	variant="falcon-default"
																	size="sm"
																	icon="plus"
																	transform="shrink-3"
																	onClick={() => {
																		arrayHelpers.insert(
																			values?.options
																				.length,
																			{
																				name: '',
																				price: '',
																				currency: '',
																				default: false
																			}
																		);
																	}}
																>
                                              <span className="d-none d-sm-inline-block ms-1">
                                                {t('addItem', {ns: "common"})}
                                              </span>
																</IconButton>
															</td>
														</tr>
														</tfoot>
													)}
												</>
											)}
										/>
									</Table>

									<FormErrors errors={errors?.options}/>
								</Card.Body>
								<Card.Footer>
									<Flex justifyContent={'end'} wrap={'wrap'}>
										<IconButton
											icon={faSave}
											onClick={e => handleSubmit(e, values)}
										>
                      <span className="d-none d-sm-inline-block ms-1">
                        {t('save', {ns: "common"})}
                      </span>
										</IconButton>
									</Flex>
								</Card.Footer>
							</Card>
						</Form>
					</>
				)}
			</Formik>
		</Fragment>
	)
}

export default withTranslation(['activity', 'common'])(MembershipFeesContent)