import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Background from 'components/common/Background';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Avatar, {AvatarGroup} from 'components/common/Avatar';
import {Draggable} from 'react-beautiful-dnd';
import {useAppContext} from 'providers/AppProvider';
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";
import KanbanModal from "./KanbanModal";
import SoftBadge from "../../../components/common/SoftBadge";

const TaskDropMenu = ({id, getKanbanStatus}) => {
    const {
        config: {isRTL}
    } = useAppContext();

    const query = useQuery()

    const handleRemoveTaskCard = async () => {
        await api.delete(`/task/tasks/${id}/`).then(res => getKanbanStatus(query))
    };

    return (
        <Dropdown
            onClick={e => e.stopPropagation()}
            align="end"
            className="font-sans-serif"
        >
            <Dropdown.Toggle
                variant="falcon-default"
                size="sm"
                className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
            >
                <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2"/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0" align={isRTL ? 'start' : 'end'}>
                <Dropdown.Item onClick={handleRemoveTaskCard} className="text-danger">
                    Remove
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const TaskCard = ({
                      task: {id, title, organizer_set, attachment_set, label_set, checklist},
                      index,
                      getKanbanStatus,
                      setKanbanModal,
                      kanbanModal
                  }) => {
    const [currentUser, setCurrentUser] = useState({})
    const image = attachment_set && attachment_set?.find(item => item.type === 'image');

    const getCurrentUser = async () => {
        await api.get(`/account/current_user/`).then(res => setCurrentUser(res?.data))
    }

    const handleModalOpen = () => {
        setKanbanModal(id)
    };

    // styles we need to apply on draggable
    const getItemStyle = isDragging => ({
        cursor: isDragging ? 'grabbing' : 'pointer',
        transform: isDragging ? 'rotate(-2deg)' : ''
    });

    useEffect(() => {
        getCurrentUser()
        // eslint-disable-next-line
    }, []);

    return (
        <Draggable draggableId={`task${id}`} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    className="kanban-item"
                >
                    <Card
                        style={getItemStyle(snapshot.isDragging)}
                        className="kanban-item-card hover-actions-trigger"
                        onClick={handleModalOpen}
                    >
                        {image && (
                            <div
                                className={`position-relative rounded-top-lg overflow-hidden ${image.className}`}
                            >
                                <Background image={image.url}/>
                            </div>
                        )}
                        <Card.Body>
                            <div className="position-relative">
                                <TaskDropMenu id={id} getKanbanStatus={getKanbanStatus}/>
                            </div>
                            {label_set && (
                                <div className="mb-2">
                                    {label_set?.map(label => (
                                        <SoftBadge
                                            key={label.name}
                                            bg={label.type}
                                            className="py-1 me-1 mb-1"
                                        >
                                            {label.name}
                                        </SoftBadge>
                                    ))}
                                </div>
                            )}
                            <p
                                className="mb-0 fw-medium font-sans-serif stretched-link"
                                dangerouslySetInnerHTML={{__html: title}}
                            />
                            {(organizer_set || attachment_set || checklist) && (
                                <div className="kanban-item-footer cursor-default">
                                    <div className="text-500 z-index-2">
                                        {organizer_set &&
                                            organizer_set?.find(
                                                member => member.name === currentUser.name
                                            ) && (
                                                <span className="me-2">
                          <OverlayTrigger
                              placement="top"
                              overlay={
                                  <Tooltip style={{position: 'fixed'}}>
                                      You're assigned in this card
                                  </Tooltip>
                              }
                          >
                            <span>
                              <FontAwesomeIcon icon="eye"/>
                            </span>
                          </OverlayTrigger>
                        </span>
                                            )}

                                        {attachment_set && (
                                            <span className="me-2">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{position: 'fixed'}}>
                                    Attachments
                                </Tooltip>
                            }
                        >
                          <span>
                            <FontAwesomeIcon
                                icon="paperclip"
                                className="me-1"
                            />
                          </span>
                        </OverlayTrigger>
                        <span>{attachment_set?.length}</span>
                      </span>
                                        )}

                                        {checklist && (
                                            <span className="me-2">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{position: 'fixed'}}>
                                    Attachments
                                </Tooltip>
                            }
                        >
                          <span>
                            <FontAwesomeIcon icon="check" className="me-1"/>
                          </span>
                        </OverlayTrigger>
                        <span>{`${checklist.completed}/${checklist.totalCount}`}</span>
                      </span>
                                        )}
                                    </div>
                                    <div className="z-index-2">
                                        {organizer_set && (
                                            <AvatarGroup>
                                                {organizer_set?.map(member => (
                                                    <OverlayTrigger
                                                        key={member.user_fullname}
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip style={{position: 'fixed'}}>
                                                                {member.user_fullname}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div>
                                                            <Avatar
                                                                size="l"
                                                                src={member.avatar}
                                                                className="ms-n1"
                                                            />
                                                        </div>
                                                    </OverlayTrigger>
                                                ))}
                                            </AvatarGroup>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                    <KanbanModal show={kanbanModal} setShow={setKanbanModal} id={id} getKanbanStatus={getKanbanStatus}/>
                </div>
            )}
        </Draggable>
    );
};

TaskDropMenu.propTypes = {
    id: PropTypes.number.isRequired
};

TaskCard.propTypes = {
    task: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        members: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string
            })
        ),
        attachments: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                url: PropTypes.string
            })
        ),
        labels: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                text: PropTypes.string
            })
        ),
        checklist: PropTypes.shape({
            totalCount: PropTypes.number,
            completed: PropTypes.number
        })
    }),
    index: PropTypes.number
};

export default TaskCard;
