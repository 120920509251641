import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card } from 'react-bootstrap';
import createMarkup from 'helpers/createMarkup';
import SubtleBadge from 'components/common/SubtleBadge';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Logs = ({
  version,
  warning,
  title,
  badgeTitle,
  publish,
  note,
  children,
  index: currentIndex
}) => (
  <Card className="mb-3">
    <Card.Header>
      <h5>
        <code className="fw-bold fs-1">v{version}</code> - {title}{' '}
        {badgeTitle && (
          <SubtleBadge pill bg="warning" className="me-2">
            {badgeTitle}
          </SubtleBadge>
        )}
      </h5>
      <p className="mb-0">{publish}</p>
    </Card.Header>
    <Card.Body className="bg-body-tertiary">
      {warning && Object.keys(warning).length && (
        <Alert variant="warning" className="p-card">
          <Flex>
            <FontAwesomeIcon icon="exclamation-triangle" className="fs-6"/>
            <div className="ms-3 flex-1">
              <h4 className="alert-heading">{warning.title}</h4>
              {warning.text}{' '}
              {warning.link && (
                <span>
                  click{' '}
                  <a target="_blank" rel="noreferrer" href={warning.link}>
                    here.
                  </a>
                </span>
              )}
            </div>
          </Flex>
        </Alert>
      )}
      {children}
      <div dangerouslySetInnerHTML={createMarkup(note)}>

      </div>
    </Card.Body>
  </Card>
);

Logs.propTypes = {
  version: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  badgeTitle: PropTypes.string,
  publish: PropTypes.string.isRequired,
  logs: PropTypes.object.isRequired,
  children: PropTypes.node,
  index: PropTypes.number,
  warning: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string
  })
};

Logs.defaultProps = { logs: {} };

export default Logs;
