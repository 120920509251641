import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import LinePaymentChart from './LinePaymentChart';
import {api} from "../../../../utils/api";
import {useAppContext} from "../../../../providers/AppProvider";

const LinePayment = () => {
    const chartRef = useRef(null);
    const [paymentStatus, setPaymentStatus] = useState('successful');
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);

    const {
        config: {currency}
    } = useAppContext()

    const getData = async () => {
        setLoading(true);
        await api.get("/finance/linepayment/").then(res => setData(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [currency]);

    return (
        <Card className="rounded-3 overflow-hidden h-100 shadow-none">
            <Card.Body
                className="bg-line-chart-gradient"
                as={Flex}
                justifyContent="between"
                direction="column"
            >
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    <>
                        <Row className="align-items-center g-0">
                            <Col>
                                <h4 className="text-white mb-0">Today {data?.today ? data?.today + " " + currency : 0}</h4>
                                <p className="fs-10 fw-semibold text-white">
                                    Yesterday <span className="opacity-50">{data?.yesterday ? data?.yesterday + " " + currency : 0}</span>
                                </p>
                            </Col>
                            <Col xs="auto" className="d-none d-sm-block">
                                <Form.Select
                                    size="sm"
                                    className="mb-3"
                                    value={paymentStatus}
                                    onChange={e => setPaymentStatus(e.target.value)}
                                >
                                    <option value="all">All Payments</option>
                                    <option value="successful">Successful Payments</option>
                                    <option value="failed">Failed Payments</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <LinePaymentChart
                            ref={chartRef}
                            currency={currency}
                            data={data}
                            paymentStatus={paymentStatus}
                            style={{height: '200px'}}
                        />
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

LinePayment.propTypes = {
    data: PropTypes.shape({
        all: PropTypes.array,
        successful: PropTypes.array,
        failed: PropTypes.array
    })
};

export default LinePayment;
