import {withTranslation} from "react-i18next";
import {withPermission} from "../../../helpers/utils";
import React, {Fragment, useEffect, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Field, Formik} from "formik";
import CSRFToken from "../../../helpers/CSRFToken";
import {useNavigate, useParams} from "react-router-dom";
import {useAppContext} from "../../../providers/AppProvider";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {toast} from "react-toastify";
import FormError from "../../errors/FormError";
import IconButton from "../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import TinymceEditor from "../../../components/common/TinymceEditor";

const TutorialAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        file: "",
        link: ""
    });

    const navigate = useNavigate();
    const {id} = useParams();
    const {
        setConfig
    } = useAppContext()

    const getTutorial = async () => {
        setLoading(true)
        await api.get(`/tutorial/${id}/`)
            .then(res => {
                setFormData({
                    ...res?.data,
                });
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    }

    useEffect(() => {
        if (id) getTutorial();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e, values) => {
        setLoading(true);
        e.preventDefault();
        if (id) {
            await api
                .patch(`/tutorial/${id}/`, values, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    toast.success(`${t('title')} ${t('updateSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.tutorial);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(t('error', {ns: "common"}), {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        } else {
            await api
                .post(`/tutorial/`, values, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.tutorial);
                })
                .catch(error => {
                    setFormData(values);
                    toast.error(t('error', {ns: "common"}), {theme: 'colored'});
                    setErrors(error?.response?.data);
                });
        }
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <Form>
                        <CSRFToken/>
                        <Card>
                            <Card.Header>
                                <h5>{id ? t('edit', {ns: "common"}) : t('add', {ns: "common"})} {t('title')}</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('fields.name')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"name"}>
                                                {({field}) => (
                                                    <Form.Control
                                                        name={"name"}
                                                        type={'text'}
                                                        placeholder={t('fields.name')}
                                                        value={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.name}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('fields.file')}</Form.Label>
                                            <Field name={"file"}>
                                                {({field}) => (
                                                    <Form.Control
                                                        name={"file"}
                                                        type={'file'}
                                                        placeholder={t('fields.file')}
                                                        value={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.file}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('fields.link')}</Form.Label>
                                            <Field name={"link"}>
                                                {({field}) => (
                                                    <Form.Control
                                                        name={"link"}
                                                        type={'url'}
                                                        placeholder={t('fields.link')}
                                                        value={field.value}
                                                        onChange={e => {
                                                            setFieldValue(field.name, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.link}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('fields.description')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name="description">
                                                {({field}) => (
                                                    <TinymceEditor
                                                        value={field.value}
                                                        handleChange={newValue => setFieldValue(field.name, newValue)}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors.description}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                    <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                                    <IconButton
                                        icon={faSave}
                                        onClick={e => handleSubmit(e, values)}
                                    >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                                    </IconButton>
                                </Flex>
                            </Card.Footer>
                        </Card>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default withTranslation(["tutorials", "common"])(withPermission(TutorialAddEdit, ["tutorials.add_tutorial", "tutorials.change_tutorial"]))