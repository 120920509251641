import {formatDateTime, getColor} from "../helpers/utils";
import {useAppContext} from "../providers/AppProvider";
import {Col} from "react-bootstrap";
import Avatar from "../components/common/Avatar";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";

const padStartWithZero = (num = 0) => {
	return num.toString().padStart(7, "0");
};

export const Ticket = ({ticket, values, preview, data, elements, user, t, background}) => {
	const {
		config: {isDark}
	} = useAppContext()

	const secondaryColor = getColor("secondary");
	const ticketNo = padStartWithZero(data?.id);

	return (
		<div className="d-flex">
			<div
				id="left"
				style={{
					backgroundColor: ticket?.color,
					color: isDark ? "black" : "white",
					width: "100%",
					backgroundImage: `url('${background}')`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
				}}
				className="rounded-l-lg border-r-0 pt-4 pb-4 row"
			>
				{elements?.map((item, index) => (
					preview ? (
						<>
							{item?.field === "component" && (
								item?.type === "row" ? (
									<div style={{backgroundColor: item?.color}} className={"px-6 py-1"}>
										<div
											style={{
												color: !isDark ? "white" : "black",
											}}
											className="text-xl mb-2 mt-3"
										>
											{item?.text} ${`{${item?.data}}`}
										</div>
									</div>
								) : (
									item?.type === "column" && (
										<Col style={{backgroundColor: item?.color}} className={"px-6 py-1"}>
											<div
												style={{
													color: !isDark ? "white" : "black",
													textAlign: item?.align
												}}
												className="text-xl mb-2 mt-3"
											>
												{item?.text} ${`{${item?.data}}`}
											</div>
										</Col>
									)
								)
							)}
							{item?.field === "text" &&
								<div
									style={{
										color: !isDark ? "white" : "black",
										textAlign: item?.align
									}}
									className="text-xl mb-2 mt-3"
								>
									{item?.text} ${`{${item?.data}}`}
								</div>
							}
							{item?.field === "feed" && <div className={`my-${item?.size}`}></div>}
							{item?.field === "barcode" &&
								<div
									style={{
										color: !isDark ? "white" : "black",
										textAlign: item?.align
									}}
									className="text-xl px-6 mb-2 mt-3"
								>
									{item?.text} ${`{${item?.data}}`}
								</div>
							}
						</>
					) : (
						<>
							{item?.field === "component" && (
								item?.type === "row" ? (
									<div style={{backgroundColor: item?.color}} className={"px-6 py-1"}>
										<div
											style={{
												color: !isDark ? "white" : "black",
											}}
											className="text-xl mb-2 mt-3"
										>
											{item?.text}{' '}
											{item?.data === "event_id" ? (
												<>{padStartWithZero(data[item?.data])}</>
											) : item?.data === "event_start_date" || item?.data === "event_end_date" || item?.data === "registered_at" ? (
												<>
													{formatDateTime(data[item?.data])}
												</>
											) : item?.data === "activity_logo" ? (
												<>
													<Avatar src={data[item?.data]} className="mr-4" size={"4xl"}/>
												</>
											) : (
												<>
													{data[item?.data]}
												</>
											)}
										</div>
									</div>
								) : (
									item?.type === "column" && (
										<Col style={{backgroundColor: item?.color}} className={"px-6 py-1"}>
											<div
												style={{
													color: !isDark ? "white" : "black",
													textAlign: item?.align
												}}
												className="text-xl mb-2 mt-3"
											>
												{item?.text}{' '}
												{item?.data === "event_id" ? (
													<>{padStartWithZero(data[item?.data])}</>
												) : item?.data === "event_start_date" || item?.data === "event_end_date" || item?.data === "registered_at" ? (
													<>
														{formatDateTime(data[item?.data])}
													</>
												) : item?.data === "activity_logo" ? (
													<>
														<Avatar src={data[item?.data]} className="mr-4" size={"4xl"}/>
													</>
												) : (
													<>
														{data[item?.data]}
													</>
												)}
											</div>
										</Col>
									)
								)
							)}
							{item?.field === "text" &&
								<div
									style={{
										color: !isDark ? "white" : "black",
										textAlign: item?.align
									}}
									className="text-xl mb-2 mt-3"
								>
									{item?.text}{' '}
									{item?.data === "event_id" ? (
										<>{padStartWithZero(data[item?.data])}</>
									) : item?.data === "event_start_date" || item?.data === "event_end_date" || item?.data === "registered_at" ? (
										<>
											{formatDateTime(data[item?.data])}
										</>
									) : item?.data === "activity_logo" ? (
										<>
											<Avatar src={data[item?.data]} className="mr-4" size={"4xl"}/>
										</>
									) : (
										<>
											{data[item?.data]}
										</>
									)}
								</div>
							}
							{item?.field === "feed" && <div className={`my-${item?.size}`}></div>}
							{item?.field === "barcode" &&
								<div
									style={{
										color: !isDark ? "white" : "black",
										textAlign: item?.align
									}}
									className="text-xl px-6 mb-2 mt-3"
								>
									{item?.type === "qr_code" ? (
										<>
											<QRCode
												size={100}
												style={{height: "auto"}}
												value={JSON.stringify({
													event: ticket?.event,
													user: user?.id
												})}
												viewBox={`0 0 256 256`}
											/>
										</>
									) : (
										<>
											<Barcode
												value={JSON.stringify({
													event: ticket?.event,
													user: user?.id
												})}
												renderer={"svg"}
												width={3}
												background={"transparent"}
												displayValue={false}
												textAlign={"center"}
											/>
										</>
									)}
								</div>
							}
						</>
					)
				))}
			</div>
			<div
				style={{
					borderLeft: `8px dashed ${ticket?.color}`,
					writingMode: "vertical-rl",
					textOrientation: "mixed"
				}}
				className="bg-white py-8 px-4 text-black text-center [writing-mode:vertical-rl] [text-orientation:mixed]"
			>
				<div className="text-xs">{t('fields.ticketPreview.number')}</div>
				<span
					style={{
						borderColor: secondaryColor,
					}}
					className={`border-l-4 text-2xl`}
				>
            #{ticketNo}
          </span>
			</div>
		</div>
	);
};