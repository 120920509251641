import React from 'react';
import LogoutContent from 'pages/authentication/LogoutContent';
import bgImg from 'assets/img/generic/19.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import {withTranslation} from "react-i18next";

const Logout = ({t, i18n}) => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <LogoutContent layout="split" titleTag="h3" t={t} i18n={i18n} />
      </div>
    </AuthSplitLayout>
  );
};

export default withTranslation(["authentication"])(Logout);
