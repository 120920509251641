import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const InformationCollection = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="information" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Information Collection</h5>
			</Card.Header>
			<Card.Body>
				<h6>What Information Do We Collect?</h6>
				<p className="fs-9 mb-0 fw-semibold">
					We collect information from you when you visit our website, register on our site, place an order, subscribe to
					our newsletter, respond to a survey or fill out a form.
				</p>
				<ul type="1">
					<li>
						Name / Username
					</li>
					<li>
						Phone Number
					</li>
					<li>
						Email Addresses
					</li>
					<li>
						Mailing Addresses
					</li>
					<li>
						Billing Addresses
					</li>
					<li>Age</li>
				</ul>
				<hr className="my-4"/>
				<h6>When does nexameet use end user information from third parties?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Nexameet will collect End User Data necessary to provide the nexameet services to our customers. End
					users may voluntarily provide us with information they have made available on social media websites. If you
					provide us with any such information, we may collect publicly available information from the social media
					websites you have indicated. You can control how much of your information social media websites make
					public by visiting these websites and changing your privacy settings.
				</p>
				<hr className="my-4"/>
				<h6>When does nexameet use customer information from third parties?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We receive some information from the third parties when you contact us. For example, when you submit your
					email address to us to show interest in becoming a nexameet customer, we receive information from a third
					party that provides automated fraud detection services to nexameet. We also occasionally collect information
					that is made publicly available on social media websites. You can control how much of your information
					social media websites make public by visiting these websites and changing your privacy settings.
				</p>
				<hr className="my-4"/>
				<h6>Do we share the information we collect with third parties?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We may share the information that we collect, both personal and non-personal, with third parties such as
					advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or
					whose products or services we think may interest you. We may also share it with our current and future
					affiliated companies and business partners, and if we are involved in a merger, asset sale or other business
					reorganization, we may also share or transfer your personal and non-personal information to our
					successors-in-interest.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					We may engage trusted third party service providers to perform functions and provide services to us, such as
					hosting and maintaining our servers and the website, database storage and management, e-mail
					management, storage marketing, credit card processing, customer service and fulfilling orders for products
					and services you may purchase through the website. We will likely share your personal information, and
					possibly some non-personal information, with these third parties to enable them to perform these services for
					us and for you.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					We may share portions of our log file data, including IP addresses, for analytics purposes with third parties
					such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may
					be used to estimate general location and other technographics such as connection speed, whether you have
					visited the website in a shared location, and type of the device used to visit the website. They may aggregate
					information about our advertising and what you see on the website and then provide auditing, research and
					reporting for us and our advertisers.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					We may also disclose personal and non-personal information about you to government or law enforcement
					officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to
					respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a
					third party, the
					safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity,
					or to otherwise comply with applicable court orders, laws, rules and regulations.
				</p>
				<hr className="my-4"/>
				<h6>Where and when is information collected from customers and end users?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Nexameet will collect personal information that you submit to us. We may also receive personal information
					about you from third parties as described above.
				</p>
				<hr className="my-4"/>
				<h6>How Do We Use The Information We Collect?</h6>
				<p className="fs-9 mb-0 fw-semibold">
					Any of the information we collect from you may be used in one of the following ways:
				</p>
				<ul type="1">
					<li>
						To personalize your experience (your information helps us to better respond to your individual needs)
					</li>
					<li>
						To improve our website (we continually strive to improve our website offerings based on the information
						and feedback we receive from you)
					</li>
					<li>
						To improve customer service (your information helps us to more effectively respond to your customer
						service requests and support needs)
					</li>
					<li>
						To process transactions
					</li>
					<li>
						To administer a contest, promotion, survey or other site feature
					</li>
					<li>To send periodic emails</li>
				</ul>
				<hr className="my-4"/>
				<h6>How Do We Use Your Email Address?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					By submitting your email address on this website, you agree to receive emails from us. You can cancel your
					participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe
					option
					that is included in the respective email. We only send emails to people who have authorized us to contact
					them, either directly, or through a third party. We do not send unsolicited commercial emails, because we
					hate spam as much as you do. By submitting your email address, you also agree to allow us to use your
					email address for customer audience targeting on sites like Facebook, where we display custom advertising
					to specific people who have opted-in to receive communications from us. Email addresses submitted only
					through the order processing page will be used for the sole purpose of sending you information and updates
					pertaining to your order. If, however, you have provided the same email to us through another method, we
					may use it for any of the purposes stated in this Policy. Note: If at any time you would like to unsubscribe
					from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
				</p>
				<hr className="my-4"/>
				<h6>How Long Do We Keep Your Information?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We keep your information only so long as we need it to provide nexameet to you and fulfill the purposes
					described in this policy. This is also the case for anyone that we share your information with and who carries
					out services on our behalf. When we no longer need to use your information and there is no need for us to
					keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or
					depersonalize it so that we can't identify you.
				</p>
				<hr className="my-4"/>
				<h6>How Do We Protect Your Information?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We implement a variety of security measures to maintain the safety of your personal information when you
					place an order or enter, submit, or access your personal information. We offer the use of a secure server. All
					supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then
					encrypted into our Payment gateway providers database only to be accessible by those authorized with
					special access rights to such systems, and are required to keep the information confidential. After a
					transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept
					on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to
					nexameet or guarantee that your information on the Service may not be accessed, disclosed, altered, or
					destroyed by a breach of any of our physical, technical, or managerial safeguards.
				</p>
				<hr className="my-4"/>
				<h6>Could my information be transferred to other countries?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Nexameet is incorporated in Tunisia. Information collected via our website, through direct interactions with
					you, or from use of our help services may be transferred from time to time to our offices or personnel, or to
					third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including
					countries that may not have laws of general applicability regulating the use and transfer of such data. To the
					fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the
					trans-border transfer and hosting of such information.
				</p>
				<hr className="my-4"/>
				<h6>Is the information collected through the nexameet Service secure?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We take precautions to protect the security of your information. We have physical, electronic, and managerial
					procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your
					information. However, neither people nor security systems are foolproof, including encryption systems. In
					addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we
					use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If
					applicable law imposes any non-disclaimable duty to protect your personal information, you agree that
					intentional misconduct will be the standards used to measure our compliance with that duty.
				</p>
				<hr className="my-4"/>
				<h6>Can I update or correct my information?</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					The rights you have to request updates or corrections to the information nexameet collects depend on your
					relationship with nexameet. Personnel may update or correct their information as detailed in our internal
					company employment policies.
				</p>
				<p className="fs-9 mb-0 fw-semibold mt-3 ps-3">
					Customers have the right to request the restriction of certain uses and disclosures of personally identifiable
					information as follows. You can contact us in order to update or correct your personally identifiable
					information, change your preferences with respect to communications and other information you receive
					from us, or delete the personally identifiable information maintained about you on our systems (subject to
					the following paragraph), by cancelling your account. Such updates, corrections, changes and deletions will
					have no effect on other information that we maintain, or information that we have provided to third parties in
					accordance with this Privacy Policy prior to such update, correction, change or deletion. To protect your
					privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your
					identity before granting you profile access or making corrections. You are responsible for maintaining the
					secrecy of your unique password and account information at all times.
				</p>
				<p className="fs-9 mb-0 fw-semibold mt-3 ps-3">
					You should be aware that it is not technologically possible to remove each and every record of the
					information you have provided to us from our system. The need to back up our systems to protect
					information from inadvertent loss means that a copy of your information may exist in a non-erasable form
					that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal
					information stored in databases we actively use, and other readily searchable media will be updated,
					corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically
					practicable.
				</p>
				<p className="fs-9 mb-0 fw-semibold mt-3 ps-3">
					If you are an end user and wish to update, delete, or receive any information we have about you, you may do
					so by contacting the organization of which you are a customer.
				</p>
			</Card.Body>
		</Card>
	)
})

export default InformationCollection