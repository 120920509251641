import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Avatar, {AvatarGroup} from 'components/common/Avatar';
import {Dropdown, Nav} from 'react-bootstrap';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import {Link} from 'react-router-dom';
import {useAppContext} from 'providers/AppProvider';
import paths from 'routes/paths';
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";

const GroupMember = ({
                         avatarSize = 'l',
                         users,
                         showMember = 4,
                         addMember,
                         className,
                         task,
                         getTask,
    getKanbanStatus
                     }) => {
    const [organizers, setOrganizers] = useState([]);

    const {
        config: {isRTL}
    } = useAppContext();
    const query = useQuery()

    const getOrganizers = async (q) => {
        await api.get(`/organizer/organizer/?${q.toString()}`).then(res => setOrganizers(res?.data?.results))
    }

    useEffect(() => {
        getOrganizers(query)
        // eslint-disable-next-line
    }, []);

    const AddOrganizer = async (e, id) => {
        await api.patch(`/task/tasks/${task}/`, {organizer: id})
            .then(res => {
                getTask()
                getKanbanStatus(query)
            })
    };

    return (
        <div className={className}>
            <AvatarGroup>
                {users?.slice(0, showMember)?.map((user, index) => (
                    <Dropdown key={user.id}>
                        <Dropdown.Toggle
                            as={Nav.Link}
                            className={classNames('dropdown-caret-none p-0', {
                                'ms-n1': index > 0
                            })}
                        >
                            <Avatar src={user.avatar} size={avatarSize}/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                            align={isRTL ? 'end' : 'start'}
                            className="dropdown-md px-0 py-3"
                        >
                            <Flex alignItems="center" className="px-3">
                                <Link to={paths.userProfile}>
                                    <Avatar src={user.avatar} className="me-2" size="2xl"/>
                                </Link>
                                <div className="flex-1">
                                    <h6 className="mb-0">
                                        <Link className="text-900" to={paths.userProfile}>
                                            {user.user_fullname}
                                        </Link>
                                    </h6>
                                    <p className="fs-11 mb-0 text-500">{user.role}</p>
                                </div>
                            </Flex>
                        </Dropdown.Menu>
                    </Dropdown>
                ))}
                {(users?.length > showMember || addMember) && (
                    <Dropdown>
                        <Dropdown.Toggle
                            as={Nav.Link}
                            className="dropdown-caret-none p-0 ms-n1"
                        >
                            {!addMember ? (
                                <Avatar
                                    name={`${users.length - showMember}+`}
                                    size={avatarSize}
                                    isExact
                                    mediaClass="avatar-button"
                                />
                            ) : (
                                <Avatar
                                    icon="plus"
                                    size={avatarSize}
                                    mediaClass="avatar-button flex-center"
                                />
                            )}
                        </Dropdown.Toggle>

                        {addMember && (
                            <Dropdown.Menu>
                                {organizers?.map((organizer, index) => (
                                    <Dropdown.Item key={index} onClick={(e) => AddOrganizer(e, organizer?.id)}>
                                        <Flex alignItems="center" className="px-3">
                                            <Link to={paths.editOrganizers.replace(":id", organizer?.id)}>
                                                <Avatar src={organizer.avatar} className="me-2" size="2xl"/>
                                            </Link>
                                            <div className="flex-1">
                                                <h6 className="mb-0">
                                                    <Link className="text-900"
                                                          to={paths.membersEdit.replace(":id", organizer?.member)}>
                                                        {organizer?.user_fullname}
                                                    </Link>
                                                </h6>
                                                <p className="fs-11 mb-0 text-500">{organizer?.role}</p>
                                            </div>
                                        </Flex>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        )}

                    </Dropdown>
                )}
            </AvatarGroup>
        </div>
    );
};

GroupMember.propTypes = {
    avatarSize: PropTypes.string,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            img: PropTypes.string,
            role: PropTypes.string
        })
    ).isRequired,
    showMember: PropTypes.number,
    addMember: PropTypes.bool,
    className: PropTypes.string
};

export default GroupMember;
