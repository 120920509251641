import React, {forwardRef} from "react";
import {Card} from "react-bootstrap";

const Service = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="service" ref={ref}>
			<Card.Header className="bg-body-tertiary">
				<h5 className="mb-0 text-primary">Services</h5>
			</Card.Header>
			<Card.Body>
				<h6>Your Suggestions</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you
					to nexameet with respect to the website shall remain the sole and exclusive property of nexameet.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					Nexameet shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in
					any way without any credit or any compensation to you.
				</p>
				<hr className="my-4"/>
				<h6>Your Consent</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We've updated our Terms & Conditions to provide you with complete transparency into what is being set
					when you visit our site and how it's being used. By using our website, registering an account, or making a
					purchase, you hereby consent to our Terms & Conditions.
				</p>
				<hr className="my-4"/>
				<h6>Links to Other Websites</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					This Terms & Conditions applies only to the Services. The Services may contain links to other websites not
					operated or controlled by nexameet. We are not responsible for the content, accuracy or opinions expressed
					in such websites, and such websites are not investigated, monitored or checked for accuracy or
					completeness by us. Please remember that when you use a link to go from the Services to another website,
					our Terms & Conditions are no longer in effect. Your browsing and interaction on any other website,
					including those that have a link on our platform, is subject to that website’s own rules and policies. Such
					third parties may use their own cookies or other methods to collect information about you.
				</p>
				<hr className="my-4"/>
				<h6>Cookies</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					Nexameet uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece
					of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the
					performance and functionality of our website but are non-essential to their use. However, without these
					cookies, certain functionality like videos may become unavailable or you would be required to enter your
					login details every time you visit the website as we would not be able to remember that you had logged in
					previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies,
					you may not be able to access functionality on our website correctly or at all. We never place Personally
					Identifiable Information in Cookies.
				</p>
				<hr className="my-4"/>
				<h6>Third-Party Services</h6>
				<p className="fs-9 mb-0 fw-semibold ps-3">
					We may display, include or make available third-party content (including data, information, applications and
					other products services) or provide links to third-party websites or services ("Third- Party Services").
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					You acknowledge and agree that nexameet shall not be responsible for any Third-Party Services, including
					their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any
					other aspect thereof. nexameet does not assume and shall not have any liability or responsibility to you or
					any other person or entity for any Third-Party Services.
				</p>
				<p className="fs-9 mb-0 fw-semibold ps-3 mt-3">
					Third-Party Services and links thereto are provided solely as a convenience to you and you access and use
					them entirely at your own risk and subject to such third parties' terms and conditions.
				</p>
			</Card.Body>
		</Card>
	)
})

export default Service