import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Tab, Nav, Form, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AudienceChart from './AudienceChart';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import useQuery from '../../../../hooks/useQuery';
import { api } from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../providers/AppProvider';

const TabTitle = ({ title, value, percentage, progress }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs--2 text-nowrap">{title}</h6>
    <h5 className="text-800">{value}</h5>
    <Flex alignItems="center">
      <FontAwesomeIcon
        icon={progress ? 'caret-up' : 'caret-down'}
        className={progress ? 'text-success' : 'text-warning'}
      />
      <h6
        className={`fs--2 mb-0 ms-2 ${
          progress ? 'text-success' : 'text-warning'
        }`}
      >
        {percentage}
      </h6>
    </Flex>
  </div>
);

const Audience = ({ className, group }) => {
  const [duration, setDuration] = useState('last_7_days');
  const [userAudienceData, setUserAudienceData] = useState({});
  const [userAudienceLoading, setUserAudienceLoading] = useState(false);
  const [sessionAudienceData, setSessionAudienceData] = useState({});
  const [sessionAudienceLoading, setSessionAudienceLoading] = useState(false);
  const [bounceRateAudienceData, setBounceRateAudienceData] = useState({});
  const [bounceRateAudienceLoading, setBounceRateAudienceLoading] =
    useState(false);
  const [memberAudienceData, setMemberAudienceData] = useState({});
  const [memberAudienceLoading, setMemberAudienceLoading] = useState(false);
  const [requestsAudienceData, setRequestsAudienceData] = useState({});
  const [requestsAudienceLoading, setRequestsAudienceLoading] = useState(false);
  const [eventsAudienceData, setEventsAudienceData] = useState({});
  const [eventsAudienceLoading, setEventsAudienceLoading] = useState(false);

  let query = useQuery();
  const navigate = useNavigate();
  const {
    config: { isAuthenticated },
    setConfig
  } = useAppContext();

  query.set('time_range', duration);

  const getUserAudienceData = async () => {
    setUserAudienceLoading(true);
    await api
      .get(`/analytics/audience/users/?${query.toString()}`)
      .then(res => setUserAudienceData(res?.data))
      .catch(error => {
        if (error?.response?.status === 404) navigate('/errors/404');
        if (error?.response?.status === 403)
          setConfig('isAuthenticated', !isAuthenticated);
      });
    setUserAudienceLoading(false);
  };

  const getMembersAudienceData = async () => {
    setMemberAudienceLoading(true);
    await api
      .get(`/analytics/audience/members/?${query.toString()}`)
      .then(res => setMemberAudienceData(res?.data))
      .catch(error => {
        if (error?.response?.status === 404) navigate('/errors/404');
        if (error?.response?.status === 403)
          setConfig('isAuthenticated', !isAuthenticated);
      });
    setMemberAudienceLoading(false);
  };

  const getRequestsAudienceData = async () => {
    setRequestsAudienceLoading(true);
    await api
      .get(`/analytics/audience/requests/?${query.toString()}`)
      .then(res => setRequestsAudienceData(res?.data))
      .catch(error => {
        if (error?.response?.status === 404) navigate('/errors/404');
        if (error?.response?.status === 403)
          setConfig('isAuthenticated', !isAuthenticated);
      });
    setRequestsAudienceLoading(false);
  };

  const getEventsAudienceData = async () => {
    setEventsAudienceLoading(true);
    await api
      .get(`/analytics/audience/events/?${query.toString()}`)
      .then(res => setEventsAudienceData(res?.data))
      .catch(error => {
        if (error?.response?.status === 404) navigate('/errors/404');
        if (error?.response?.status === 403)
          setConfig('isAuthenticated', !isAuthenticated);
      });
    setEventsAudienceLoading(false);
  };

  const getSessionAudienceData = async () => {
    setSessionAudienceLoading(true);
    await api
      .get(`/analytics/audience/sessions/?${query.toString()}`)
      .then(res => setSessionAudienceData(res?.data))
      .catch(error => {
        if (error?.response?.status === 404) navigate('/errors/404');
        if (error?.response?.status === 403)
          setConfig('isAuthenticated', !isAuthenticated);
      });
    setSessionAudienceLoading(false);
  };

  const getBounceRateAudienceData = async () => {
    setBounceRateAudienceLoading(true);
    await api
      .get(`/analytics/audience/bounces/?${query.toString()}`)
      .then(res => setBounceRateAudienceData(res?.data))
      .catch(error => {
        if (error?.response?.status === 404) navigate('/errors/404');
        if (error?.response?.status === 403)
          setConfig('isAuthenticated', !isAuthenticated);
      });
    setBounceRateAudienceLoading(false);
  };

  useEffect(() => {
    getUserAudienceData();
    getSessionAudienceData();
    getBounceRateAudienceData();
    getMembersAudienceData();
    getRequestsAudienceData();
    getEventsAudienceData();
    // eslint-disable-next-line
    }, [duration]);

  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Tab.Container id="audience-tab" defaultActiveKey="users">
        <SimpleBarReact>
          <Card.Header className="p-0 bg-light">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              {userAudienceLoading ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <Spinner animation={'border'} variant={'primary'} />
                </Flex>
              ) : (
                <Nav.Item>
                  <Nav.Link className="mb-0" eventKey="users">
                    <TabTitle
                      title="Users"
                      value={userAudienceData?.count1}
                      progress={userAudienceData?.progress}
                      percentage={userAudienceData?.percentage + '%'}
                    />
                  </Nav.Link>
                </Nav.Item>
              )}
              {memberAudienceLoading ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <Spinner animation={'border'} variant={'primary'} />
                </Flex>
              ) : (
                <Nav.Item>
                  <Nav.Link className="mb-0" eventKey="members">
                    <TabTitle
                      title="Members"
                      value={memberAudienceData?.count1}
                      progress={memberAudienceData?.progress}
                      percentage={memberAudienceData?.percentage + '%'}
                    />
                  </Nav.Link>
                </Nav.Item>
              )}
              {requestsAudienceLoading ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <Spinner animation={'border'} variant={'primary'} />
                </Flex>
              ) : (
                <Nav.Item>
                  <Nav.Link className="mb-0" eventKey="requests">
                    <TabTitle
                      title="Requests"
                      value={requestsAudienceData?.count1}
                      progress={requestsAudienceData?.progress}
                      percentage={requestsAudienceData?.percentage + '%'}
                    />
                  </Nav.Link>
                </Nav.Item>
              )}
              {eventsAudienceLoading ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <Spinner animation={'border'} variant={'primary'} />
                </Flex>
              ) : (
                <Nav.Item>
                  <Nav.Link className="mb-0" eventKey="events">
                    <TabTitle
                      title="Events"
                      value={eventsAudienceData?.count1}
                      progress={eventsAudienceData?.progress}
                      percentage={eventsAudienceData?.percentage + '%'}
                    />
                  </Nav.Link>
                </Nav.Item>
              )}
              {sessionAudienceLoading ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <Spinner animation={'border'} variant={'primary'} />
                </Flex>
              ) : (
                <Nav.Item>
                  <Nav.Link className="mb-0" eventKey="sessions">
                    <TabTitle
                      title="Sessions"
                      value={sessionAudienceData?.count1}
                      progress={sessionAudienceData?.progress}
                      percentage={sessionAudienceData?.percentage + '%'}
                    />
                  </Nav.Link>
                </Nav.Item>
              )}
              {bounceRateAudienceLoading ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <Spinner animation={'border'} variant={'primary'} />
                </Flex>
              ) : (
                group.indexOf('admin') !== -1 && (
                  <Nav.Item>
                    <Nav.Link className="mb-0" eventKey="bounceRate">
                      <TabTitle
                        title="Bounce Rate"
                        value={bounceRateAudienceData?.count1}
                        progress={bounceRateAudienceData?.progress}
                        percentage={bounceRateAudienceData?.percentage + '%'}
                      />
                    </Nav.Link>
                  </Nav.Item>
                )
              )}
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body>
          <Tab.Content>
            {userAudienceLoading ||
            sessionAudienceLoading ||
            bounceRateAudienceLoading ||
            memberAudienceLoading ||
            requestsAudienceLoading ||
            eventsAudienceLoading ? (
              <Flex justifyContent="center" className="p-2 mb-2">
                <Spinner animation={'border'} variant={'primary'} />
              </Flex>
            ) : (
              <>
                <Tab.Pane unmountOnExit eventKey="users">
                  <AudienceChart
                    data={userAudienceData?.series}
                    duration={duration}
                  />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="members">
                  <AudienceChart
                    data={memberAudienceData?.series}
                    duration={duration}
                  />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="requests">
                  <AudienceChart
                    data={requestsAudienceData?.series}
                    duration={duration}
                  />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="events">
                  <AudienceChart
                    data={eventsAudienceData?.series}
                    duration={duration}
                  />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="sessions">
                  <AudienceChart
                    data={sessionAudienceData?.series}
                    duration={duration}
                  />
                </Tab.Pane>
                {group.indexOf('admin') !== -1 && (
                  <Tab.Pane unmountOnExit eventKey="bounceRate">
                    <AudienceChart
                      data={bounceRateAudienceData?.series}
                      duration={duration}
                    />
                  </Tab.Pane>
                )}
              </>
            )}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Form.Select
              size="sm"
              className="me-2"
              name={'time_range'}
              onChange={e => {
                setDuration(e.target.value);
              }}
            >
              <option value={'last_7_days'}>Last 7 days</option>
              <option value={'last_month'}>Last Month</option>
              <option value={'last_year'}>Last Year</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  progress: PropTypes.bool
};

Audience.propTypes = {
  className: PropTypes.string.isRequired
};

export default Audience;
