import {withPermission} from "../../../../helpers/utils";
import React, {useEffect, useState} from "react";
import {useAppContext} from "../../../../providers/AppProvider";
import {useNavigate, useParams} from "react-router-dom";
import useQuery from "../../../../hooks/useQuery";
import {getActivities} from "../../../activity/actions/Activity";
import Flex from "../../../../components/common/Flex";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import {Field, Formik} from "formik";
import CardHeader from "react-bootstrap/CardHeader";
import IconButton from "../../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import CSRFToken from "../../../../helpers/CSRFToken";
import Select from "react-select";
import FormError from "../../../errors/FormError";
import CodeOfConductContent from "../contents/CodeOfConductContent";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";
import paths from "../../../../routes/paths";
import CodeOfConductCard from "../cards/CodeOfConductCard";

const RuleAddEdit = () => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [activities, setActivities] = useState([]);
	const [selectedActivity, setSelectedActivity] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [formData, setFormData] = useState({
		title: '',
		rule_type: '',
		activity: '',
		status: '',
		content: "",
		effective_date: "",
		scope: "",
		purpose: "",
		acknowledgment_required: false,
		enforcementprocedure: {
			disciplinaryactions: [],
			investigationprocess: [],
			reportingmechanism: [],
		},
		documents: [],
	});

	const {
		config: {group}
	} = useAppContext();

	const {id} = useParams();
	const activityQuery = useQuery();
	const navigate = useNavigate();

	const getRule = async () => {
		setLoading(true);
		await api
			.get(`/rule/rule/${id}/`)
			.then(async res => {
				setSelectedActivity({
					value: res?.data?.activity,
					label: res?.data?.activity_name
				});
				setFormData({
					...res?.data
				});
				if (res?.data?.rule_type === 'code_of_conduct') {
					await api.get(`/rule/codeofconduct/${id}/`).then(rule => {
						const updatedDocuments = rule?.data?.document_set?.map(document => ({
							link: document?.link,
							type: document?.type,
							document_url: document?.document_url
						}));
						const updatedActions = rule?.data?.enforcement?.actions?.map(action => ({
							name: action?.name,
						}))
						const updatedReportings = rule?.data?.enforcement?.reportings?.map(reporting => ({
							name: reporting?.name,
						}))
						const updatedInvestigations = rule?.data?.enforcement?.investigations?.map(investigation => ({
							name: investigation?.name,
						}))
						setFormData({
							...rule?.data,
							documents: updatedDocuments,
							enforcementprocedure: {
								investigationprocess: updatedInvestigations,
								reportingmechanism: updatedReportings,
								disciplinaryactions: updatedActions
							}
						});
					});
				}
			})
			.catch(err => {
				if (err?.response?.status === 404) navigate('/errors/404');
			});
		setLoading(false);
	};

	const handleSubmit = async (e, values) => {
		setLoading(true);
		e.preventDefault();
		console.log(values)
		if (values.rule_type === 'code_of_conduct')
			if (id)
				api
					.patch(`/rule/codeofconduct/${id}/`, values)
					.then(res => {
						toast.success(`Rule ${res?.data?.title} updated successfully.`);
						navigate(paths.ruleDetail.replace(":id", res?.data?.id));
					})
					.catch(err => {
						setFormData(values);
						toast.error(`An error has occurred.`);
						setErrors(err?.response?.data);
					});
			else
				api
					.post('/rule/codeofconduct/', values)
					.then(res => {
						toast.success(`Rule ${res?.data?.title} created successfully.`);
						navigate(paths.ruleDetail.replace(':id', res?.data?.id));
					})
					.catch(err => {
						setFormData(values);
						toast.error(`An error has occurred.`);
						setErrors(err?.response?.data);
					});
		setLoading(false);
	}


	useEffect(() => {
		setLoading(true);
		activityQuery.set('page_size', '50');
		activityQuery.set('page', currentPage.toString());
		getActivities(activityQuery)
			// eslint-disable-next-line no-unsafe-optional-chaining
			.then(res => setActivities([...activities, ...res?.results]))
			.catch(() => {
			});
		setLoading(false);
		// eslint-disable-next-line
	}, [currentPage]);

	useEffect(() => {
		if (id) getRule();
		// eslint-disable-next-line
	}, []);

	let activityOptions = activities.map(activity => ({
		label: activity.name,
		value: activity.id
	}));

	activityOptions = [
		{label: '---------------------------', value: ''},
		...activityOptions
	];

	return loading ? (
		<Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
			<Spinner animation={'border'} variant={'primary'}/>
		</Flex>
	) : (
		<Formik initialValues={formData} onSubmit={values => console.log(values)}>
			{({values, setFieldValue}) => (
				<>
					<Form>
						<CSRFToken/>
						<Row className={'g-3 mt-1 mb-3'}>
							<Col xxl={12} xl={12}>
								<Row className="g-3">
									<Col xs={12}>
										<Card>
											<Card.Header>
												<h5 className="mb-0 text-muted">Basic Information</h5>
											</Card.Header>
											<Card.Body>
												{group.includes(
													process.env.REACT_APP_ADMIN_GROUP_NAME
												) && (
													<Form.Group>
														<Form.Label>Activity:</Form.Label>
														<Field name="activity">
															{({field}) => (
																<Select
																	name="activity"
																	options={activityOptions}
																	onMenuScrollToBottom={e => {
																		console.log(e);
																		setCurrentPage(page => page + 1);
																	}}
																	onInputChange={e => {
																		activityQuery.set('search', e);
																		getActivities(activityQuery).catch(
																			() => {
																			}
																		);
																	}}
																	placeholder="Select activity"
																	classNamePrefix="react-select"
																	value={selectedActivity}
																	onChange={value => {
																		setFieldValue(field.name, value.value);
																		setSelectedActivity(value);
																	}}
																/>
															)}
														</Field>
														<FormError error={errors.activity}/>
													</Form.Group>
												)}
												<Row>
													<Col lg={4}>
														<Form.Group>
															<Form.Label>Title:</Form.Label>
															<Field name="title">
																{({field}) => (
																	<Form.Control
																		type="text"
																		name="title"
																		placeholder="Title"
																		errors={errors}
																		formGroupProps={{
																			className: 'mb-3'
																		}}
																		onChange={e => {
																			setFieldValue(field.name, e.target.value);
																		}}
																		value={field.value}
																	/>
																)}
															</Field>
															<FormError error={errors.title}/>
														</Form.Group>
													</Col>
													<Col lg={4}>
														<Form.Group>
															<Form.Label>Status:</Form.Label>
															<Field name="status">
																{({field}) => (
																	<Form.Select
																		name="status"
																		formGroupProps={{
																			className: 'mb-3'
																		}}
																		onChange={e => {
																			setFieldValue(field.name, e.target.value);
																		}}
																		value={field.value}
																	>
																		<option value={''}>
																			Select status
																		</option>
																		<option value={'draft'}>Draft</option>
																		<option value={'active'}>Active</option>
																		<option value={'archived'}>Archived</option>
																	</Form.Select>
																)}
															</Field>
															<FormError error={errors.status}/>
														</Form.Group>
													</Col>
													<Col lg={4}>
														<Form.Group>
															<Form.Label>Type:</Form.Label>
															<Field name="rule_type">
																{({field}) => (
																	<Form.Select
																		name="rule_type"
																		formGroupProps={{
																			className: 'mb-3'
																		}}
																		onChange={e => {
																			setFieldValue(field.name, e.target.value);
																		}}
																		value={field.value}
																	>
																		<option value={''}>
																			Select rule type
																		</option>
																		<option value={'code_of_conduct'}>Code of Conduct</option>
																	</Form.Select>
																)}
															</Field>
															<FormError error={errors.type}/>
														</Form.Group>
													</Col>
												</Row>
											</Card.Body>
										</Card>
									</Col>
									{values?.rule_type === "code_of_conduct" ? (
										<>
											<Col lg={7}>
												<CodeOfConductContent errors={errors} values={values} setFieldValue={setFieldValue}/>
											</Col>
											<Col lg={5}>
												<CodeOfConductCard data={values} activity={selectedActivity?.label} />
											</Col>
										</>
									) : (
										<></>
									)}
								</Row>
							</Col>
						</Row>
					</Form>
					<Card>
						<CardHeader>
							<Flex justifyContent={'end'} wrap={'wrap'}>
								<IconButton
									icon={faSave}
									onClick={e => handleSubmit(e, values)}
								>
									<span className="d-none d-sm-inline-block ms-1">Save</span>
								</IconButton>
							</Flex>
						</CardHeader>
					</Card>
				</>
			)}
		</Formik>
	)
}

export default withPermission(RuleAddEdit, "rule.add_rule")