import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../../../utils/api";
import {Button, Card, Col, ProgressBar, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../../../components/common/Flex";
import IconButton from "../../../../../components/common/IconButton";
import {formatDateTime, withPermission} from "../../../../../helpers/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const ImportMemberDetail = () => {
    const [data, setData] = useState({})
    const [mappings, setMappings] = useState([])
    const [loading, setLoading] = useState(false);

    const {id} = useParams();
    const navigate = useNavigate()

    const ws = useMemo(() => new WebSocket(window.location.protocol === "https:" ? "wss://api.nexameet.com/ws/import_member/" : "ws://localhost:8000/ws/import_member/"), []);

    const fetchImport = async () => {
        setLoading(true);
        await api.get(`/member/import-member/${id}/`).then(res => {
            setData(res?.data)
            setMappings(res?.data?.mappings)
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchImport()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        ws.onmessage = function (event) {
            setData(JSON.parse(event.data));
        };
    }, [ws]);

    const reprocessTask = async (e) => {
        setLoading(true)
        await api.post(`/member/reprocess-import/`, {id: id})
            .then(res => {
                toast.success("Import reprocessed successfully.", {theme: "colored"})
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setLoading(false)
    };

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className={"mb-3"}>
                <Card.Header className="d-flex flex-between-center">
                    <Flex alignItems={"center"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                        <h5 className={"ms-2"}>Import Details</h5>
                    </Flex>
                    <Flex alignItems={"center"}>
                        <IconButton
                            onClick={(e) => reprocessTask(e)}
                            variant="falcon-default"
                            size="sm"
                            icon="sync-alt"
                        >
                            <span className="d-none d-sm-inline-block ms-1">Reprocess import</span>
                        </IconButton>
                    </Flex>
                </Card.Header>
            </Card>
            <Row className="mb-3">
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5>Import Status</h5>
                        </Card.Header>
                        <Card.Body>
                            <h4>Status: {data?.status}</h4>
                            <ProgressBar now={data?.percentage} label={`${data?.percentage}%`}/>
                            <p>{data?.percentage}% Complete</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5>File Details</h5>
                        </Card.Header>
                        <Card.Body>
                            <p><strong>File:</strong>
                                <Button variant={"outline-success"} href={data?.file_url} className={"ms-2"}>
                                    <FontAwesomeIcon icon={faFileExcel}/>
                                </Button>
                            </p>
                            <p><strong>Upload Date:</strong> {formatDateTime(data?.created_at)}</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5>Import Duration</h5>
                        </Card.Header>
                        <Card.Body>
                            <p><strong>Start Time:</strong> {formatDateTime(data?.started_at)}</p>
                            <p><strong>End Time:</strong> {formatDateTime(data?.finished_at)}</p>
                            <p><strong>Total Duration:</strong> {data?.duration}s</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5>Import Errors</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Error Message</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.errors?.map((error, index) => (
                                    <tr key={index}>
                                        <td>{error}</td>
                                    </tr>
                                ))}
                                </tbody>
                                {data?.line_number ?
                                    <tfoot>
                                    <tr>
                                        <td>{`At line ${data?.line_number}`}</td>
                                    </tr>
                                    </tfoot> : ""
                                }
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5>Column Mappings</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Excel Column</th>
                                    <th>Member Field</th>
                                </tr>
                                </thead>
                                <tbody>
                                {mappings?.map((mapping, index) => (
                                    <tr key={index}>
                                        <td>{mapping?.header_field}</td>
                                        <td>{mapping?.member_field}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default withPermission(ImportMemberDetail, "event.view_eventimportfile")