import React from 'react';
import PasswordResetForm from "../PasswordResetForm";

const PasswordReset = () => (
  <div className="text-center">
    <h5>Reset new password</h5>
    <PasswordResetForm />
  </div>
);

export default PasswordReset;
