import logoInvoice from 'assets/img/nexameet-favicon-color.png';
import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Table} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import {convertPrice, formatDate, formatNumberCurrency} from "../../../helpers/utils";

const Invoice = ({billing, currencyData, amount, currency}) => {
    const [price, setPrice] = useState(0);
    const [taxes, setTaxes] = useState([]);

    useEffect(() => {
        const fetchTaxes = async () => {
            const taxPromises = billing?.membership_details?.taxes_data?.map(async tax => {
                const convertedPrice = tax?.is_flat ? await convertPrice(tax?.value, tax?.currency, currency) : 0;
                return {...tax, convertedPrice};
            });
            const resolvedTaxes = await Promise.all(taxPromises);
            setTaxes(resolvedTaxes);
        };

        if (billing?.membership_details?.taxes_data) {
            fetchTaxes();
        }
    }, [billing, currency]);

    useEffect(() => {
        const fetchInfo = async () => {
            const convertedPrice = await convertPrice(billing?.plan === "monthly" ?
                billing?.membership_details?.monthly_price : billing?.membership_details?.yearly_price, billing?.membership_details?.currency, currency)
            setPrice(formatNumberCurrency(convertedPrice, currencyData))
        };

        fetchInfo();
        // eslint-disable-next-line
    }, [billing, currencyData]);

    return (
        <>
            <Card className={"mt-3"}>
                <Card.Body>
                    <Row className="align-items-center text-center mb-3">
                        <Col sm={6} className="text-sm-start">
                            <img src={logoInvoice} alt="invoice" width={150}/>
                        </Col>
                        <Col className="text-sm-end mt-3 mt-sm-0">
                            <h2 className="mb-3">Receipt</h2>
                            <h5>Nexameet</h5>
                        </Col>
                        <Col xs={12}>
                            <hr/>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col>
                            <h6 className="text-500">Receipt to</h6>
                            <h5>{billing?.user_data?.activity_name}</h5>
                            <p className="fs-10">
                                {billing?.method_details?.full_address}
                            </p>
                        </Col>
                        <Col sm="auto" className="ms-auto">
                            <div className="table-responsive">
                                <Table borderless size="sm" className="fs-10">
                                    <tbody>
                                    <tr>
                                        <th className="text-sm-end">Receipt No:</th>
                                        <td>{billing?.id}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-sm-end">Order Number:</th>
                                        <td>{billing?.code}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-sm-end">Receipt Date:</th>
                                        <td>{formatDate(billing?.date)}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-sm-end">Payment Due:</th>
                                        <td>Upon receipt</td>
                                    </tr>
                                    <tr className="alert alert-success fw-bold">
                                        <th className="text-success-emphasis text-sm-end">
                                            Amount Due:
                                        </th>
                                        <td className="text-success-emphasis">{amount}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>

                    <div className="mt-4 fs-10">
                        <SimpleBarReact>
                            <Table striped className="border-bottom">
                                <thead data-bs-theme="light">
                                <tr className="bg-primary dark__bg-1000 alert alert-primary">
                                    <th className="text-white border-0 text-white-emphasis">Products</th>
                                    <th className="text-white border-0 text-center text-white-emphasis">
                                        Quantity
                                    </th>
                                    <th className="text-white border-0 text-end text-white-emphasis">Rate</th>
                                    <th className="text-white border-0 text-end text-white-emphasis">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle">
                                        <h6 className="mb-0 text-nowrap">
                                            {billing?.membership_details?.name} x {billing?.plan}
                                        </h6>
                                        <p className="mb-0">{billing?.membership_details?.feature_set?.map(feature => feature.name).join(', ')}</p>
                                    </td>
                                    <td className="align-middle text-center">1</td>
                                    <td className="align-middle text-end">{price}</td>
                                    <td className="align-middle text-end">{price}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </div>

                    <Row className="justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs-10 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">Subtotal:</th>
                                    <td className="fw-semibold">{price}</td>
                                </tr>
                                {taxes &&
                                    taxes?.map(tax => (
                                        <tr key={tax.id}>
                                            <th className="text-900">{tax.name}</th>
                                            <td className="fw-semibold">
                                                {tax.is_flat ? formatNumberCurrency(tax.convertedPrice, currencyData) : `${tax.percentage}%`}
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr className="border-top">
                                    <th className="text-900">Total:</th>
                                    <td className="fw-semibold">{amount}</td>
                                </tr>
                                <tr className="border-top border-top-2 fw-bolder text-900">
                                    <th className="text-900">Amount Due:</th>
                                    <td className="text-900">{amount}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="bg-body-tertiary">
                    <p className="fs-10 mb-0">
                        <strong>Notes: </strong> We really appreciate your business and if
                        there’s anything else we can do, please let us know!
                    </p>
                </Card.Footer>
            </Card>
        </>
    );
};

export default Invoice;
