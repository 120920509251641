import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, Collapse} from 'react-bootstrap';
import {useTranslation} from "react-i18next";

const ProfileIntro = ({data}) => {
    const [collapsed, setCollapsed] = useState(false);

    const { t } = useTranslation("common")
    return (
        <Card className="mb-3">
            <Card.Header className="bg-light">
                <h5 className="mb-0">{t('intro')}</h5>
            </Card.Header>

            <Card.Body className="text-justify text-1000">
                <div
                    dangerouslySetInnerHTML={{
                        __html: data?.description?.slice(0, 1000)
                    }}
                />
                <Collapse in={collapsed}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data?.description?.slice(1000)
                        }}
                    />
                </Collapse>
            </Card.Body>

            <Card.Footer className="bg-light p-0 border-top d-grid">
                <Button
                    variant="link"
                    // className="me-2 mb-1"
                    onClick={() => setCollapsed(!collapsed)}
                >
                  {t('show')} {collapsed ? t('less') : t('more')}
                    <FontAwesomeIcon
                        icon="chevron-down"
                        className="ms-2 fs--2"
                        transform={collapsed ? 'rotate-180' : ''}
                    />
                </Button>
            </Card.Footer>
        </Card>
    );
};

export default ProfileIntro;
