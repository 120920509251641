import {Card, Dropdown} from "react-bootstrap";
import {Field} from "formik";
import Flex from "../../../components/common/Flex";
import cloudUpload from "../../../assets/img/icons/cloud-upload.svg";
import {getSize} from "../../../helpers/utils";
import CardDropdown from "../../../components/common/CardDropdown";
import React, {useState} from "react";
import {useDropzone} from "react-dropzone";
import {v4 as uuid} from "uuid";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const File = ({icon, title, className}) => {
    return (
        <div
            className={classNames(
                'border p-2 rounded-3 d-flex bg-white dark__bg-1000 fs-10',
                className
            )}
        >
            <FontAwesomeIcon icon={icon} className="fs-1"/>
            <span className="ms-2">{title}</span>
        </div>
    );
};

const EnoticeAttachments = ({setFieldValue, values, errors}) => {
    const [files, setFiles] = useState(values?.attachments || []);

    const {getRootProps, getInputProps} = useDropzone({
        multiple: true,
        onDrop: acceptedFiles => {
            const fileReaders = acceptedFiles.map(file => {
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    reader.onloadend = () => {
                        resolve({
                            id: uuid(),
                            file: {
                                base64: reader.result,
                                path: file.path,
                                size: file.size,
                                preview: URL.createObjectURL(file),
                            },
                            data: {
                                base64: reader.result,
                                path: file.path,
                                size: file.size,
                                preview: URL.createObjectURL(file),
                            }
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(fileReaders).then(newFiles => {
                setFiles(prevFiles => {
                    const updatedFiles = [...prevFiles, ...newFiles];
                    setFieldValue('content.attachments', updatedFiles);
                    return updatedFiles;
                });
            }).catch(error => {
                console.error("Error reading files: ", error);
            });
        }
    })

    const handleRemove = path => {
        setFiles(prevFiles => {
            return prevFiles.filter(file => file?.data?.path !== path);
        });
    };

    return (
        <Card className={"mt-3"}>
            <Card.Header>
                <h5>Attachments</h5>
            </Card.Header>
            <Card.Body className="bg-body-tertiary">
                <div {...getRootProps({className: 'dropzone-area py-6'})}>
                    <Field name={"content.attachments"}>
                        {({field}) => (
                            <input {...getInputProps()} />
                        )}
                    </Field>
                    <Flex justifyContent="center">
                        <img src={cloudUpload} alt="" width={25} className="me-2"/>
                        <p className="fs-9 mb-0 text-700">Drop your files here</p>
                    </Flex>
                </div>
                <div>
                    {files?.map(file => (
                        <Flex
                            alignItems="center"
                            className="py-3 border-bottom btn-reveal-trigger"
                            key={file?.data?.path}
                        >
                            <File
                                action={"Preview"}
                                title={file?.data?.name}
                                actionIcon="eye"
                                className="mb-2"
                                icon={file?.data?.type?.includes("image") ? ['far', 'image'] : "file-archive"}
                                file={file?.file_url}
                            />

                            <Flex
                                justifyContent="between"
                                alignItems="center"
                                className="ms-3 flex-1"
                            >
                                <div>
                                    <h6>{file?.data?.path}</h6>
                                    <Flex className="position-relative" alignItems="center">
                                        <p className="mb-0 fs-10 text-400 line-height-1">
                                            <strong>{getSize(file?.data?.size)}</strong>
                                        </p>
                                    </Flex>
                                </div>
                            </Flex>
                            <CardDropdown>
                                <div className="py-2">
                                    <Dropdown.Item
                                        className="text-danger"
                                        onClick={() => handleRemove(file?.data?.path)}
                                    >
                                        Remove
                                    </Dropdown.Item>
                                </div>
                            </CardDropdown>
                        </Flex>
                    ))}
                </div>
            </Card.Body>
        </Card>
    )
}

export default EnoticeAttachments