import {Col, Form, Row} from "react-bootstrap";
import * as XLSX from 'xlsx';

const FileForm = ({formData, setFormData, setFileColumns, file, setFile}) => {

	const extractColumns = (file) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			const data = event.target.result;
			const workbook = XLSX.read(data, {type: 'binary'});
			const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
			const jsonData = XLSX.utils.sheet_to_json(firstWorksheet, {header: 1});
			const columns = jsonData[0]; // Assuming the first row of your Excel file contains the column names
			setFileColumns(columns);
		};
		reader.readAsBinaryString(file);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setFile(file);
				setFormData({...formData, file: reader.result});
				extractColumns(file)
			};
			reader.readAsDataURL(file);  // Read the file as Data URL to get the base64 encoded string
		}
	};

	return (
		<Row>
			<Col xs={6}>
				<Form.Control
					type={"file"}
					onChange={handleFileChange}
					file={file}
				/>
			</Col>
			<Col>
				<Form.Select name={"type"} onChange={e => setFormData({...formData, type: e.target.value})}
					value={formData.type}
				>
					<option>Select file type</option>
					<option value={"excel"} selected>Excel</option>
					<option value={"csv"}>CSV</option>
				</Form.Select>
			</Col>
		</Row>
	)
}

export default FileForm