import React, {useEffect, useState} from 'react';
import Flex from '../../../components/common/Flex';
import {Button, Card, Col, Form, Row, Spinner, Table} from 'react-bootstrap';
import {getEvents} from '../../events/actions/Events';
import useQuery from '../../../hooks/useQuery';
import {Field, FieldArray} from 'formik';
import Select from 'react-select';
import FormError from '../../errors/FormError';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormErrors from '../../errors/FormErrors';
import DatePicker from 'react-datepicker';
import IconButton from '../../../components/common/IconButton';
import TinymceEditor from "../../../components/common/TinymceEditor";

const EventReport = ({values, setFieldValue, errors}) => {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(
        values?.event
            ? {
                value: values?.event,
                label: values?.event_name
            }
            : null
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [dates, setDates] = useState(
        values?.plannings?.length > 0
            ? values?.plannings?.map(plan => {
                // Assuming 'time' is a field in your API response
                const apiTime = plan.time;

                // Assuming apiTime is in the format "HH:mm:ss"
                const [hours, minutes, seconds] = apiTime.split(':');

                // Create a new Date object and set the time part
                const newDate = new Date();
                newDate.setHours(parseInt(hours, 10));
                newDate.setMinutes(parseInt(minutes, 10));
                newDate.setSeconds(parseInt(seconds, 10));

                return newDate;
            })
            : []
    );
    const [times, setTimes] = useState(
        values?.plannings?.length > 0
            ? values?.plannings?.map(plan => plan?.time)
            : []
    );

    const query = useQuery();

    console.log(dates);

    const fetchEvents = () => {
        setLoading(true);
        query.set('activity', values?.activity);
        getEvents(query).then(res => setEvents([...events, ...res?.results]));
        setLoading(false);
    };

    useEffect(() => {
        fetchEvents();
        // eslint-disable-next-line
    }, [currentPage]);

    let eventOptions = events?.map(event => ({
        label: event?.title,
        value: event?.id
    }));

    eventOptions = [
        {label: '---------------------------', value: ''},
        ...eventOptions
    ];

    return loading ? (
        <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <>
            <Col xs={4}>
                <div className={'sticky-sidebar'}>
                    <Card loading={'lazy'}>
                        <Card.Header>
                            <h5 className="mb-0 text-muted">Event Details</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>Event:</Form.Label>
                                <Field name="event">
                                    {({field}) => (
                                        <Select
                                            options={eventOptions}
                                            onMenuScrollToBottom={e => {
                                                console.log(e);
                                                setCurrentPage(page => page + 1);
                                            }}
                                            onInputChange={e => {
                                                query.set('search', e);
                                                getEvents(query).catch(() => {
                                                });
                                            }}
                                            placeholder="Select event"
                                            classNamePrefix="react-select"
                                            value={selectedEvent}
                                            onChange={value => {
                                                setFieldValue(field.name, value.value);
                                                setSelectedEvent(value);
                                            }}
                                        />
                                    )}
                                </Field>
                                <FormError error={errors.event}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description:</Form.Label>
                                <Field name="description">
                                    {({field}) => (
                                        <TinymceEditor
                                            value={field.value}
                                            handleChange={newValue => setFieldValue(field.name, newValue)}
                                        />
                                    )}
                                </Field>
                                <FormError error={errors.description}/>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
            <Col xs={4}>
                <div className={'sticky-sidebar'}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0 text-muted">Planning</h5>
                        </Card.Header>
                        <Card.Body>
                            <FieldArray
                                name={'plannings'}
                                render={arrayHelpers => (
                                    <>
                                        {values.plannings && values.plannings.length > 0
                                            ? values.plannings.map((field, index) => (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2"
                                                    >
                                                        <div
                                                            className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
                                                            <Button
                                                                size="sm"
                                                                variant="link"
                                                                className="p-0"
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="text-danger"
                                                                    icon="times-circle"
                                                                />
                                                            </Button>
                                                        </div>

                                                        <Row className="gx-2 gy-3">
                                                            <Col md="6">
                                                                <Form.Group>
                                                                    <Form.Label>Name</Form.Label>
                                                                    <Field name={`plannings.${index}.name`}>
                                                                        {({field}) => (
                                                                            <Form.Control
                                                                                type="text"
                                                                                name={`plannings.${index}.name`}
                                                                                placeholder="Name"
                                                                                value={field.value}
                                                                                onChange={e =>
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Form.Group>
                                                                <FormErrors
                                                                    errors={errors?.plannings}
                                                                    error={'name'}
                                                                    index={index}
                                                                />
                                                            </Col>
                                                            <Col md="6">
                                                                <Form.Group>
                                                                    <Form.Label>Time</Form.Label>
                                                                    <Row>
                                                                        <Field name={`plannings.${index}.time`}>
                                                                            {({field}) => (
                                                                                <DatePicker
                                                                                    selected={
                                                                                        dates[index] ? dates[index] : null
                                                                                    }
                                                                                    defaultValue=""
                                                                                    name={`plannings.${index}.time`}
                                                                                    onChange={newDate => {
                                                                                        setDates([...dates, newDate]);
                                                                                        setTimes([
                                                                                            ...times,
                                                                                            new Date(newDate)
                                                                                                .toISOString()
                                                                                                .slice(11, 26)
                                                                                        ]);
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            new Date(newDate)
                                                                                                .toISOString()
                                                                                                .slice(11, 26)
                                                                                        );
                                                                                    }}
                                                                                    placeholderText="Select Time"
                                                                                    timeIntervals={5}
                                                                                    dateFormat="hh:mm aa"
                                                                                    className="form-control"
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </Row>
                                                                </Form.Group>
                                                                <FormErrors
                                                                    errors={errors?.plannings}
                                                                    error={'time'}
                                                                    index={index}
                                                                />
                                                            </Col>
                                                            <Col md="12">
                                                                <Form.Group>
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Field
                                                                        name={`plannings.${index}.description`}
                                                                    >
                                                                        {({field}) => (
                                                                            <textarea
                                                                                name={`plannings.${index}.description`}
                                                                                className={'form-control'}
                                                                                value={field.value}
                                                                                onChange={e => {
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Form.Group>
                                                                <FormErrors
                                                                    errors={errors?.plannings}
                                                                    error={'description'}
                                                                    index={index}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                            ))
                                            : ''}
                                        <IconButton
                                            onClick={() =>
                                                arrayHelpers.insert(values.plannings.length, '')
                                            }
                                            variant="falcon-default"
                                            size="sm"
                                            icon="plus"
                                            transform="shrink-3"
                                            className={'mt-3'}
                                        >
                                            Add Item
                                        </IconButton>
                                    </>
                                )}
                            />
                            <FormError error={errors.plannings}/>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
            <Col xs={4}>
                <div className={'sticky-sidebar'}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0 text-muted">Expenses</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive className="border-bottom" size={'sm'}>
                                <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Amount</td>
                                    <td className={'p-0'}></td>
                                </tr>
                                </thead>
                                <FieldArray
                                    name={'expenses'}
                                    render={arrayHelpers => (
                                        <>
                                            <tbody>
                                            {values?.expenses && values?.expenses.length > 0
                                                ? values?.expenses?.map((field, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Field name={`expenses.${index}.name`}>
                                                                {({field, form}) => (
                                                                    <Form.Control
                                                                        placeholder={'Name'}
                                                                        name={`expenses.${index}.name`}
                                                                        errors={errors}
                                                                        formGroupProps={{className: 'mb-3'}}
                                                                        onChange={e => {
                                                                            setFieldValue(
                                                                                field.name,
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        value={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormErrors
                                                                errors={errors?.expenses}
                                                                error={'name'}
                                                                index={index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field name={`expenses.${index}.amount`}>
                                                                {({field, form}) => (
                                                                    <Form.Control
                                                                        placeholder={'Amount'}
                                                                        type={'number'}
                                                                        name={`expenses.${index}.amount`}
                                                                        errors={errors}
                                                                        formGroupProps={{className: 'mb-3'}}
                                                                        onChange={e => {
                                                                            setFieldValue(
                                                                                field.name,
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        value={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormErrors
                                                                errors={errors?.expenses}
                                                                error={'amount'}
                                                                index={index}
                                                            />
                                                        </td>
                                                        <td className={'me-0 pe-0'}>
                                                            <Flex
                                                                justifyContent={'center'}
                                                                alignItems={'center'}
                                                                className={'mt-1'}
                                                            >
                                                                <IconButton
                                                                    variant="falcon-default"
                                                                    icon="trash"
                                                                    size={'sm'}
                                                                    iconClassName={'ms-1 text-danger'}
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                >
                                                                    {' '}
                                                                </IconButton>
                                                            </Flex>
                                                        </td>
                                                    </tr>
                                                ))
                                                : ''}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td>
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                        transform="shrink-3"
                                                        onClick={() => {
                                                            arrayHelpers.insert(
                                                                values?.expenses.length,
                                                                ''
                                                            );
                                                        }}
                                                    >
                              <span className="d-none d-sm-inline-block ms-1">
                                Add item
                              </span>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </>
                                    )}
                                />
                            </Table>
                            <FormError error={errors.expenses}/>
                        </Card.Body>
                    </Card>
                    <Card className={'mt-4'}>
                        <Card.Header>
                            <h5 className="mb-0 text-muted">Objectives</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive className="border-bottom" size={'sm'}>
                                <thead>
                                <tr>
                                    <td>Name</td>
                                    <td className={'p-0'}></td>
                                </tr>
                                </thead>
                                <FieldArray
                                    name={'objectives'}
                                    render={arrayHelpers => (
                                        <>
                                            <tbody>
                                            {values?.objectives && values?.objectives.length > 0
                                                ? values?.objectives?.map((field, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Field name={`objectives.${index}.name`}>
                                                                {({field, form}) => (
                                                                    <Form.Control
                                                                        placeholder={'Name'}
                                                                        name={`objectives.${index}.name`}
                                                                        errors={errors}
                                                                        formGroupProps={{className: 'mb-3'}}
                                                                        onChange={e => {
                                                                            setFieldValue(
                                                                                field.name,
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        value={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormErrors
                                                                errors={errors?.objectives}
                                                                error={'name'}
                                                                index={index}
                                                            />
                                                        </td>
                                                        <td className={'me-0 pe-0'}>
                                                            <Flex
                                                                justifyContent={'center'}
                                                                alignItems={'center'}
                                                                className={'mt-1'}
                                                            >
                                                                <IconButton
                                                                    variant="falcon-default"
                                                                    icon="trash"
                                                                    size={'sm'}
                                                                    iconClassName={'ms-1 text-danger'}
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                >
                                                                    {' '}
                                                                </IconButton>
                                                            </Flex>
                                                        </td>
                                                    </tr>
                                                ))
                                                : ''}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td>
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                        transform="shrink-3"
                                                        onClick={() => {
                                                            arrayHelpers.insert(
                                                                values?.objectives.length,
                                                                ''
                                                            );
                                                        }}
                                                    >
                              <span className="d-none d-sm-inline-block ms-1">
                                Add item
                              </span>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </>
                                    )}
                                />
                            </Table>
                            <FormError error={errors.objectives}/>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </>
    );
};

export default EventReport;
